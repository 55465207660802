<div *ngIf="this.announcementService.announcements.length>0">
	<div *ngFor="let announcement of this.announcementService.announcements; let itemindex=index">
	  <mdb-card
	  	id="closeablecard"
	    [style.background-image]="announcement.imageurl"
	    class="card-image mb-3 w-100 mt-4"
	  >
			<div *ngIf="announcement.canread=='1'" class="card-header bg-transparent border-bottom-0">
	      <button type="button" class="close" aria-label="Close" (click)="this.announcementService.markRead(itemindex)">
	        <span aria-hidden="true">&times;</span>
	      </button>
	    </div>
	    <div class="text-white text-left align-items-left py-5 px-5 waves-light" [class.mt-n5]="announcement.canread=='1'"	style="background-color: rgb(173, 196, 219)">
	      <div>
	        <h3 class="card-title mb-0"><strong>New Message: {{ announcement.title }}</strong></h3>
	        <p class="card-title pt-1"><strong>{{ announcement.date | date:'MMM d, yyyy' }}</strong></p>
	        <p style=" white-space: pre-line">{{ announcement.body }}</p>
	        <p class="card-title mb-0"><strong>{{ announcement.author }}</strong></p>
		      <ng-container *ngIf="announcement.imgurl">
			      <div class="row my-4">  
			        <div class="col text-center">
			          <img style="max-width: 100%" [src]="announcement.imageurl">
			        </div>
			      </div>
					</ng-container>
	        <a *ngIf="announcement.url!=='' && announcement.url!==null" mdbBtn color="primary" href="{{ announcement.url }}" target="_blank" class="waves-light mt-4" mdbWavesEffect>
	          <i class="fas fa-external-link-alt"></i>
	          Open Link</a>
	      </div>
	    </div>
	  </mdb-card>
	</div>
	<br>
</div>