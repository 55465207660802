<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.aCS.editmode">Add Contact</h2>
				<h2 *ngIf="this.aCS.editmode">Edit Contact</h2>
			</div>
			<div class="col-auto mr-4 ml-3" *ngIf="!this.aCS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/contacts">View Contacts</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Contact</button>
			</div>
			<div class="col-auto mr-4 ml-3" *ngIf="this.aCS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/contacts">View Contacts</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Contact</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.aCS.editmode">
    			<mat-label>Contact Name</mat-label>
			    <mat-select placeholder="Contact Name" [(ngModel)]="this.aCS.idcontact" (keydown.tab)="this.aCS.loadForEdit()">
			      <mat-option *ngFor="let contact of this.dDDS.dropdown.contactList; let contactIndex=index;" [class.archived]="contact.health=='archived'" #prod (click)="this.aCS.loadForEdit()" [value]="contact.idcontact">{{ contact.fullname }} [{{ contact.companyname }}]</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <br *ngIf="this.aCS.editmode">
			  
			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Company Name</mat-label>
			    <mat-select placeholder="Company Name" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.company">
			      <mat-option *ngFor="let producer of this.dDDS.dropdown.companyList" #prod [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>First Name</mat-label>
			    <input matInput placeholder="First Name" maxlength="45" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.firstname">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Last Name</mat-label>
			    <input matInput placeholder="Last Name" maxlength="45" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.lastname">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Telephone Number</mat-label>
			    <input matInput placeholder="Telephone Number" maxlength="45" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.telephone">
			  </mat-form-field>

			  <form [formGroup]="form" *ngIf="editSelected()">
				  <mat-form-field>
	    			<mat-label>Email Address</mat-label>
				    <input matInput placeholder="Email Address" maxlength="45" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.email" formControlName="email">
			      <mat-error *ngIf="form.get('email').errors?.forbiddenString">This email address already exists</mat-error>
			      <mat-error *ngIf="form.get('email').errors?.required">Email address required</mat-error>
				  </mat-form-field>
				</form>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Birthday</mat-label>
			    <input matInput [matDatepicker]="bdate" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.birthday">
					<mat-datepicker-toggle matIconSuffix [for]="bdate"></mat-datepicker-toggle>
			    <mat-datepicker #bdate></mat-datepicker>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Shirt Size</mat-label>
			    <input matInput placeholder="Shirt Size" maxlength="45" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.shirtsize">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.note"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.aCS.editmode && !this.aCS.archived" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && !this.aCS.archived" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.aCS.saveChanges()">Update</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && !this.aCS.archived" mdbBtn class="btn btn-info" (click)="onDelete('archive')">Archive</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && !this.aCS.archived" mdbBtn class="btn btn-danger" (click)="onDelete('delete')">Delete</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && this.aCS.archived" mdbBtn class="btn btn-info" (click)="onDelete('unarchive')">Unarchive</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>