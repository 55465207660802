<div class="container">
  <div class="row justify-content-between">


    <div class="col">
      <h3>Pallet Actions [ {{ ( this.tableService.selectedRows.length>1 ? this.tableService.selectedRows.length + ' Pallets' : '1 Pallet' ) }} Selected ]</h3>
    </div>
  </div>
  <br>

  <div class="col-6 px-0" style="display: inline-block;">
    <div class="form-container">
      <mat-form-field>
        <mat-label>Cracking Destination</mat-label>
        <mat-select placeholder="Storage" [(ngModel)]="this.destination">
          <ng-container *ngFor="let storage of this.dDS.dropdown.storageListWNIS">
            <mat-option value="{{ storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
 
  <br>
  <div class="row justify-content-between">
    <div class="col-xs-6 ml-3"></div>
    <div class="mr-4 ms-0">
      <button mdbBtn class="btn btn-info" [disabled]="destination==undefined" (click)="groupEdit('to crack')">Crack Selected</button>
    </div>
  </div>
  <br><br>
</div>