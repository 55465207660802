<div class="container">
  <div class="row justify-content-between">
    <div class="col">
      <h3>Picking List [ {{ ( this.tableService.selectedRows.length>1 ? this.tableService.selectedRows.length + ' Pallets' : '1 Pallet' ) }} Selected ]</h3>
    </div>
  </div>
  <br>
  <table class="table col-sm">
    <tbody>
      <tr class="tableseperator"><td></td><td></td><td></td></tr>
      <tr>
        <td>Order Assignment</td>
        <td class="pb-0">
          <mat-form-field class="inline">
            <mat-select [(ngModel)]="this.tableService.order">
              <mat-option value="">None</mat-option>
              <mat-option *ngFor="let order of this.tableService.orderdetails; let i=index" value="{{ i }}">{{ order['orderref'] }} [{{ order['orderstatus'] | titlecase }}] - {{ order['styles'] }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td class="buttoncell"><button mdbBtn class="button btn btn-info" (click)="editStock()">Assign</button></td>
      </tr>
      <ng-container *ngIf="this.tableService.order!=''">
        <ng-container *ngFor="let product of this.tableService.orderdetails[this.tableService.order]['products']">
          <tr class="tableseperator"><td></td><td></td><td></td></tr>
          <tr>
            <td>Product Requested</td>
            <td>{{ product['fullstyle'] }}
              {{ ( product['minsize']==0 ? '[' : '[ ' + product['minsize'] + 'mm <' ) }}
              {{ ( product['maxsize']>0 || product['minsize']>0 ? ' X ' : '') }}
              {{ ( product['maxsize']==0 ? ']' : '< ' + product['maxsize'] + 'mm ]' ) }}
              {{ ( product['minskr']==0 ? '' : ', SKR > ' ) }}{{ ( product['minskr']==0 ? '' : product['minskr'] ) | percent:'1.1-1' }}
              {{ ( product['maxuskr']==0 ? '' : ', USKR < ' ) }}{{ ( product['maxuskr']==0 ? '' : product['maxuskr'] ) | percent:'1.1-1' }}
              {{ ( product['cultivars']=='' ? '' : ', ' + product['cultivars'] ) }}
            </td>
            <td class="buttoncell"></td>
          </tr>
          <tr>
            <td>Quantity Required</td>
            <td>{{ product['quantity'] | number:'1.0-0' }}</td>
            <td class="buttoncell"></td>
          </tr>
          <tr>
            <td>Quantity Assigned</td>
            <td>{{ product['invquant'] | number:'1.0-0' }}</td>
            <td class="buttoncell"></td>
          </tr>
          <tr>
            <td>Quantity Remaining</td>
            <td>{{ product['quantity'] - product['invquant'] | number:'1.0-0' }}</td>
            <td class="buttoncell"></td>
          </tr>
        </ng-container> 
      </ng-container>
      <tr class="tableseperator"><td></td><td></td><td></td></tr>
    </tbody>
  </table>
  <br>
  <table class="table col-sm">
    <tbody>
      <h4>Weighted Average Quality</h4>
      <tr class="tableseperator"><td></td><td></td><td></td></tr>
      <tr>
        <td>PKR Weighted Average</td>
        <td>{{ this.calWavg('pkr','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr>
        <td>USKR Weighted Average</td>
        <td>{{ this.calWavg('uskr','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr class="tableseperator"><td></td><td></td><td></td></tr>
      <tr>
        <td>Nut Count / kg</td>
        <td>{{ this.calWavg('nutsperkg','totalkg') | number:'1.0-0' }}</td>
        <td></td>
      </tr>
      <tr class="tableseperator"><td></td><td></td><td></td></tr>
      <tr>
        <td>Above 18mm</td>
        <td>{{ this.calWavg('a18P','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr>
        <td>Above 20mm</td>
        <td>{{ this.calWavg('a20P','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr>
        <td>Above 22mm</td>
        <td>{{ this.calWavg('a22P','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr>
        <td>Above 24mm</td>
        <td>{{ this.calWavg('a24P','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr>
        <td>Above 25mm</td>
        <td>{{ this.calWavg('a25P','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>
      <tr>
        <td>Above 26mm</td>
        <td>{{ this.calWavg('a26P','totalkg') | percent:'1.2' }}</td>
        <td></td>
      </tr>      
      <tr class="tableseperator"><td></td><td></td><td></td></tr>
    </tbody>
  </table>
  <br>
  <div class="row justify-content-between">
    <div class="col">
      <button mdbBtn class="btn btn-secondary ms-0" (click)="dispatchSelected()">Dispatch to Order [ {{ ( this.tableService.selectedRows.length>1 ? this.tableService.selectedRows.length + ' Pallets' : '1 Pallet' ) }} Selected ]</button>
    </div>
  </div>
  <br><br>
</div>