<div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
    <div class="row justify-content-between">
      <div class="col-xs-6 main-header">
        <h2>Quality Certificate</h2>
      </div>
      <div class="ml-3 col-auto">
        <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
      </div>
    </div>
    <br>
  
    <div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
      <svg id="qualitycert"
       viewBox="0 0 550 800"
       xmlns="http://www.w3.org/2000/svg" 
       xmlns:xlink="http://www.w3.org/1999/xlink"  
       version="1.2" 
       baseProfile="tiny">
        <defs>
          <style>
            .cls-1 {
              fill: gray;
            }

            .cls-2 {
              font-size: 9.96px;
            }

            .cls-10, .cls-19, .cls-2, .cls-30, .cls-31, .cls-33 {
              font-family: Arial-Bold, Arial;
              font-weight: 700;
            }

            .cls-10, .cls-19, .cls-3, .cls-8, .cls-33 {
              font-size: 7.8px;
            }

            .cls-3, .cls-8 {
              font-family: Arial, Arial;
            }

            .cls-4 {
              letter-spacing: 9.76em;
            }

            .cls-5 {
              letter-spacing: 8.64em;
            }

            .cls-6 {
              letter-spacing: 6.69em;
            }

            .cls-7 {
              letter-spacing: 0em;
            }

            .cls-8, .cls-9 {
              fill: #0563c1;
            }

            .cls-10 {
              fill: #fff;
            }

            .cls-11 {
              letter-spacing: 8.1em;
            }

            .cls-12 {
              letter-spacing: 7.11em;
            }

            .cls-13 {
              letter-spacing: 2.38em;
            }

            .cls-14 {
              letter-spacing: 5.76em;
            }

            .cls-15 {
              letter-spacing: 7.39em;
            }

            .cls-16 {
              letter-spacing: 2.77em;
            }

            .cls-17 {
              letter-spacing: 9.68em;
            }

            .cls-18 {
              letter-spacing: 4.13em;
            }

            .cls-20 {
              letter-spacing: 3.55em;
            }

            .cls-21 {
              letter-spacing: 9.97em;
            }

            .cls-22 {
              letter-spacing: 7.16em;
            }

            .cls-23 {
              letter-spacing: 3.07em;
            }

            .cls-24 {
              letter-spacing: 0em;
            }

            .cls-25 {
              letter-spacing: 5.31em;
            }

            .cls-26 {
              letter-spacing: 8.72em;
            }

            .cls-27 {
              letter-spacing: 9.27em;
            }

            .cls-28 {
              letter-spacing: 6.28em;
            }

            .cls-29 {
              letter-spacing: 0em;
            }

            .cls-30 {
              font-size: 14.22px;
            }

            .cls-31 {
              font-size: 19.86px;
              fill: #c00000;
            }

            .cls-32 {
              fill: #ff0;
            }

            .cls-33 {
              fill: #f00;
            }

            .cls-34 {
              font-family: Arial, Arial;
              font-size: 7px;
            }

          </style>
        </defs>
        <title>Quality Certificate</title>

        <g>
          <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='wide'" [attr.xlink:href]="this.pS.invoicelogo" x="70px" y="10" height="223px" width="455px"/>
          <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='square'" [attr.xlink:href]="this.pS.invoicelogo" transform="scale(0.4)" x="550px" y="40" height="223px" width="255px"/>
        </g>

        <g transform="translate(0,130)">
          <g transform="translate(0 -60)">
            <text class="cls-30" transform="translate(2.46 11.6)">Quality Certificate</text>
            <text class="cls-31" transform="translate(420 15)">{{ this.tS['orders'][this.tS.clickedIndex['orders']].original ? 'ORIGINAL' : 'COPY' }}</text>
            <g>
              <text class="cls-2" transform="translate(2.46 35.9)">Exporter</text>
              <text class="cls-3" transform="translate(2.46 46.58)">{{ this.pS.portalMod()['companyname'] }}</text>
              <text class="cls-3" transform="translate(2.46 56.78)">{{ this.pS.portalMod()['address'] }}</text>
              <text class="cls-3" transform="translate(2.46 66.98)">{{ this.pS.portalMod()['area'] }} {{ this.pS.portalMod()['postcode'] }}</text>
              <text class="cls-3" transform="translate(2.46 77.18)">{{ this.pS.portalMod()['state'] }}</text>
              <text class="cls-3" transform="translate(2.46 87.38)">{{ this.pS.portalMod()['country'] }}</text>
              <text class="cls-3" transform="translate(2.46 97.58)">RN {{ this.pS.portalMod()['regno'] }}</text>
              <text class="cls-3" transform="translate(2.46 107.78)">VAT {{ this.pS.portalMod()['vat'] }}</text>
            </g>
          </g>
          <g transform="translate(50 -60)">
            <text class="cls-3" transform="translate(330 35.9)">Invoice Number:</text>
            <text class="cls-3" transform="translate(410 35.9)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}</text>
            <text class="cls-3" transform="translate(330 46.6)">Invoice Issue Date:</text>
            <text class="cls-3" transform="translate(410 46.6)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.exploadingdate | date:'MMM d, y' }}</text>
            <text class="cls-3" transform="translate(330 67)">Buyer&apos;s Contract Reference:</text>
            <text class="cls-3" transform="translate(330 77.7)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.ordernumber }}</text>
          </g>
          <g transform="translate(-200 -120)">
            <text class="cls-2" transform="translate(420 130.22)">Sales Support</text>
            <text class="cls-3" transform="translate(420 140.9)">Hilde de Jager</text>
            <text class="cls-3" transform="translate(420 151.1)">+27 72 998 9434</text>
            <text class="cls-8" transform="translate(420 161.3)">logistics@ambermacs.com</text>
          </g>
          <g transform="translate(0 -60)">
            <text class="cls-2" transform="translate(2.46 130.22)">Buyer</text>
            <text class="cls-3" transform="translate(2.46 140.09)">Company:</text>
            <text transform="translate(40 140.9)" class="cls-3">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyer }}</text>
            <text class="cls-3" transform="translate(2.46 150.29)">Addr:</text>
            <text class="cls-3" transform="translate(40 150.29)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyeraddr }}{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerarea!=null ? ', ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerarea + (this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerpostcode!=null ? ' ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerpostcode : '') : '') }}{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerstate!=null ? ', ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerstate : '') }}{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercountry!=null ? ', ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercountry : '') }}</text>             
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.buyervat!=null">
              <g [attr.transform]="transcontact('b','vat')">
                <text class="cls-3" transform="translate(2.46 140.09)">{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercountry=='China' ? 'USCI' : 'Tax ID' ) }}:</text>
                <text class="cls-3" transform="translate(40 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyervat }}</text>
              </g>
            </ng-container>
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercon!=null">
              <g [attr.transform]="transcontact('b','con')">
                <text class="cls-3" transform="translate(2.46 140.09)">Contact:</text>
                <text class="cls-3" transform="translate(40 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercon }}</text>
              </g>
            </ng-container>
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.buyertel!=null">
              <g [attr.transform]="transcontact('b','tel')">
                <text class="cls-3" transform="translate(2.46 140.09)">Tel:</text>
                <text class="cls-3" transform="translate(40 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyertel }}</text>
              </g>
            </ng-container>
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.buyeremail!=null">
              <g [attr.transform]="transcontact('b','email')">
                <text class="cls-3" transform="translate(2.46 140.09)">Email:</text>
                <text class="cls-3" transform="translate(40 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyeremail }}</text>
              </g>
            </ng-container>
          </g>
          <g transform="translate(-213.84 20)">
            <text class="cls-2" transform="translate(215.76 130.22)">Consignee</text>
            <text class="cls-3" transform="translate(215.76 140.09)">Company:</text>
            <text transform="translate(254.08 140.9)" class="cls-3">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consignee }}</text>
            <text class="cls-3" transform="translate(215.76 150.29)">Addr:</text>
            <text class="cls-3" transform="translate(254.08 150.29)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeaddr }}{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneearea!=null ? ', ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneearea + (this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneepostcode!=null ? ' ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneepostcode : '') : '') }}{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneestate!=null ? ', ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneestate : '') }}{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecountry!=null ? ', ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecountry : '') }}</text>            
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneevat!=null">
              <g [attr.transform]="transcontact('c','vat')">
                <text class="cls-3" transform="translate(215.76 140.09)">{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecountry=='China' ? 'USCI' : 'Tax ID' ) }}:</text>
                <text class="cls-3" transform="translate(254.08 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneevat }}</text>
              </g>
            </ng-container>
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecon!=null">
              <g [attr.transform]="transcontact('c','con')">
                <text class="cls-3" transform="translate(215.76 140.09)">Contact:</text>
                <text class="cls-3" transform="translate(254.08 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecon }}</text>
              </g>
            </ng-container>
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneetel!=null">
              <g [attr.transform]="transcontact('c','tel')">
                <text class="cls-3" transform="translate(215.76 140.09)">Tel:</text>
                <text class="cls-3" transform="translate(254.08 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneetel }}</text>
              </g>
            </ng-container>
            <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeemail!=null">
              <g [attr.transform]="transcontact('c','email')">
                <text class="cls-3" transform="translate(215.76 140.09)">Email:</text>
                <text class="cls-3" transform="translate(254.08 140.09)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeemail }}</text>
              </g>
            </ng-container>
          </g>
          <g [attr.transform]="transpage()">

            <g transform="translate(-25 -70)">
              <text transform="matrix(1 0 0 1 31.0801 326.3398)" class="cls-3">Product Specifications: Macadamia Nut in Shell</text>
              <text transform="matrix(1 0 0 1 31.0801 339.1792)" class="cls-3">Production Year: 2024</text>
              <text transform="matrix(1 0 0 1 31.0801 352.019)" class="cls-3">Country of origin: South Africa</text>
              <text transform="matrix(1 0 0 1 31.0801 364.8584)" class="cls-3">{{ this.tS['orders'][this.tS.clickedIndex['orders']]['tunit'] | number:'1.0' }} White Polywoven Bags</text>
              <g>
              <text transform="matrix(1 0 0 1 265 385.7998)" class="cls-2">Quality Report</text>
                <text transform="matrix(1 0 0 1 75 404.1597)" class="cls-3" text-anchor="middle">Size</text>
                <text transform="matrix(1 0 0 1 155 404.1597)" class="cls-3" text-anchor="middle">SKR</text>
                <text transform="matrix(1 0 0 1 225 403.8599)" class="cls-3" text-anchor="middle">USKR</text>
                <text transform="matrix(1 0 0 1 300 404.1597)" class="cls-3" text-anchor="middle">Moisture</text>
                <text transform="matrix(1 0 0 1 380 400.1597)" class="cls-3" text-anchor="middle">Number of</text>
                <text transform="matrix(1 0 0 1 380 409.1597)" class="cls-3" text-anchor="middle">Nuts per kg</text>
                <text transform="matrix(1 0 0 1 460 404.1597)" class="cls-3" text-anchor="middle">Quantity Bags</text>
                <text transform="matrix(1 0 0 1 530 404.1597)" class="cls-3" text-anchor="middle">KG</text>
              </g>
              <g>
                <rect x="29.4" y="376.1" width="0.6" height="38.6"/>
                <rect x="120" y="389.5" width="0.6" height="24.6"/>
                <rect x="190" y="389.5" width="0.6" height="24.6"/>
                <rect x="260" y="389.5" width="0.6" height="24.6"/>
                <rect x="340" y="389.5" width="0.6" height="24.6"/>
                <rect x="420" y="389.5" width="0.6" height="24.6"/>
                <rect x="500" y="389.5" width="0.6" height="24.6"/>
                <rect x="560" y="376.1" width="0.6" height="38.6"/>
                <rect x="30" y="376.1" width="530.5" height="0.6"/>
                <rect x="30" y="388.9" width="530.5" height="0.6"/>
                <rect x="30" y="414.2" width="530.5" height="0.6"/>
              </g>
              <ng-container *ngFor="let size of this.tS.quality['pallets']; let i=index;">
                <g [attr.transform]="transsize(i)">
                  <g>
                    <text transform="matrix(1 0 0 1 75 423.4199)" class="cls-3" text-anchor="middle">{{ size['size'] }}</text>
                    <text transform="matrix(1 0 0 1 155 423.4199)" class="cls-3" text-anchor="middle">{{ size['skr'] | percent:'1.1' }}</text>
                    <text transform="matrix(1 0 0 1 225 423.1201)" class="cls-3" text-anchor="middle">{{ size['uskr'] | percent:'1.1' }}</text>
                    <text transform="matrix(1 0 0 1 300 423.4199)" class="cls-3" text-anchor="middle">{{ size['moisture'] | percent:'1.1' }}</text>
                    <text transform="matrix(1 0 0 1 380 423.4199)" class="cls-3" text-anchor="middle">{{ size['nutsperkg'] | number:'1.0-0' }}</text>
                    <text transform="matrix(1 0 0 1 460 423.4199)" class="cls-3" text-anchor="middle">{{ size['tunit'] | number:'1.0-0' }}</text>
                    <text transform="matrix(1 0 0 1 530 423.4199)" class="cls-3" text-anchor="middle">{{ size['tweight'] | number:'1.0-0' }}</text>
                  </g>
                  <g>
                    <rect x="29.4" y="415" width="0.6" height="12.5"/>
                    <rect x="120" y="415" width="0.6" height="12.5"/>
                    <rect x="190" y="415" width="0.6" height="12.5"/>
                    <rect x="260" y="415" width="0.6" height="12.5"/>
                    <rect x="340" y="415" width="0.6" height="12.5"/>
                    <rect x="420" y="415" width="0.6" height="12.5"/>
                    <rect x="500" y="415" width="0.6" height="12.5"/>
                    <rect x="560" y="415" width="0.6" height="12.5"/>
                    <rect x="30" y="427.1" width="530.5" height="0.6"/>
                  </g>              
                </g>
              </ng-container>
              <g [attr.transform]="transgrouped()">
                <text transform="matrix(1 0 0 1 70.02 488.04)" class="cls-3">Sizes</text>
                <text transform="matrix(1 0 0 1 146 487.4395)" class="cls-3">%</text>
                <rect x="29.4" y="478.4" width="0.6" height="13.5"/>
                <rect x="126.7" y="478.4" width="0.6" height="13.5"/>
                <rect x="173.4" y="478.4" width="0.6" height="13.5"/>
                <rect x="30" y="478.4" width="144" height="0.6"/>
                <rect x="30" y="491.3" width="144" height="0.6"/>

                <ng-container *ngIf="this.tS.quality['sizes']['<20mm']['split']>0">
                  <text transform="matrix(1 0 0 1 65 500.46)" class="cls-34">18-20mm</text>
                  <text transform="matrix(1 0 0 1 142 500.46)" class="cls-34">{{ this.tS.quality['sizes']['<20mm']['split']
                   | percent:'1.1-1' }}</text>
                  <rect x="29.4" y="491.2" width="0.6" height="13.5"/>
                  <rect x="126.7" y="491.2" width="0.6" height="13.5"/>
                  <rect x="173.4" y="491.2" width="0.6" height="13.5"/>
                  <rect x="30" y="504.1" width="144" height="0.6"/>
                </ng-container>

                <g [attr.transform]="this.tS.quality['sizes']['<20mm']['split']>0 ? 'translate(0 0)' : 'translate(0 -12.8)' ">
                
                  <ng-container *ngIf="this.tS.quality['sizes']['20-22mm']['split']>0">
                    <text transform="matrix(1 0 0 1 65 513.2998)" class="cls-34">20-22mm</text>
                    <text transform="matrix(1 0 0 1 142 513.2998)" class="cls-34">{{ this.tS.quality['sizes']['20-22mm']['split']
                     | percent:'1.1-1' }}</text>
                    <rect x="29.4" y="504" width="0.6" height="13.5"/>
                    <rect x="126.7" y="504" width="0.6" height="13.5"/>
                    <rect x="173.4" y="504" width="0.6" height="13.5"/>
                    <rect x="30" y="517" width="144" height="0.6"/>
                  </ng-container>

                  <g [attr.transform]="this.tS.quality['sizes']['20-22mm']['split']>0 ? 'translate(0 0)' : 'translate(0 -12.8)' ">

                    <ng-container *ngIf="this.tS.quality['sizes']['22-26mm']['split']>0">
                      <text transform="matrix(1 0 0 1 65 526.1396)" class="cls-34">22-26mm</text>
                      <text transform="matrix(1 0 0 1 142 526.1396)" class="cls-34">{{ this.tS.quality['sizes']['22-26mm']['split']
                       | percent:'1.1-1' }}</text>
                      <rect x="29.4" y="516.8" width="0.6" height="13.5"/>
                      <rect x="126.7" y="516.8" width="0.6" height="13.5"/>
                      <rect x="173.4" y="516.8" width="0.6" height="13.5"/>
                      <rect x="30" y="529.8" width="144" height="0.6"/>
                    </ng-container>

                    <g [attr.transform]="this.tS.quality['sizes']['22-26mm']['split']>0 ? 'translate(0 0)' : 'translate(0 -12.8)' ">

                      <ng-container *ngIf="this.tS.quality['sizes']['>26mm']['split']>0">
                        <text transform="matrix(1 0 0 1 68 538.9795)" class="cls-34">&gt;26mm</text>
                        <text transform="matrix(1 0 0 1 142 538.9795)" class="cls-34">{{ this.tS.quality['sizes']['>26mm']['split']
                         | percent:'1.1-1' }}</text>
                        <rect x="29.4" y="529.6" width="0.6" height="13.5"/>
                        <rect x="126.7" y="529.6" width="0.6" height="13.5"/>
                        <rect x="173.4" y="529.6" width="0.6" height="13.5"/>
                        <rect x="30" y="542.6" width="144" height="0.6"/>
                      </ng-container>
                      
                    </g>
                  </g>
                </g>

              </g>
            </g>
            <g [attr.transform]="transgrouped(1)">
              <g transform="translate(3 40)">
                <text class="cls-2" transform="translate(1.92 425.9)">Container Information</text>
                <text class="cls-3" transform="translate(1.68 436.58)">Container Type &amp; Size</text>
                <text class="cls-3" transform="translate(114.29 436.58)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.containertype }}</text>
                <text class="cls-3" transform="translate(1.68 446.78)">Container No.</text>
                <text class="cls-3" transform="translate(114.29 446.78)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.containerno }}</text>
                <text class="cls-3" transform="translate(1.68 456.98)">Seal No.</text>
                <text class="cls-3" transform="translate(114.29 456.98)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.sealno }}</text>
                <text class="cls-3" transform="translate(1.68 466.98)">Unpalletized Load</text>
              </g>
              <g transform="translate(3 -45)">
                <text class="cls-3" transform="translate(1.68 574.22)">The sizes provided are determined based on the average diameter obtained through mechanical sieving, utilizing screens with round holes.</text>
                <text class="cls-3" transform="translate(1.68 586.22)">SKR% and USKR% are calculated based on the average results derived from a representative sample.</text>
                <text class="cls-3" transform="translate(1.68 598.22)">Moisture content is determined from the representative sample at the time of packing.</text>
              </g>
            </g>

          </g>

        </g>

      </svg>
    </div>     

    <br>

    <div class="col px-0">  
      <div class="form-container">  

        <div *ngFor="let item of this.tS.quality['pallets']; let itemindex=index">

          <hr *ngIf="itemindex>0">

          <h3>Pallets: {{ this.tS.quality['pallets'][itemindex]['size'] }}</h3>

          <div class="row justify-content-between px-2">

            <div class="col-2 ps-0">
              <mat-form-field>
                <mat-label>SKR</mat-label>
                <input matInput #skr placeholder="SKR" [ngModel]="+this.tS.quality['pallets'][itemindex]['skr'] | percent:'1.1-1'" (blur)="changeValue('pallets','skr',itemindex,skr.value)">
              </mat-form-field>
            </div>

            <div class="col-2">
              <mat-form-field>
                <mat-label>USKR</mat-label>
                <input matInput #uskr placeholder="USKR" [ngModel]="+this.tS.quality['pallets'][itemindex]['uskr'] | percent:'1.1-1'" (blur)="changeValue('pallets','uskr',itemindex,uskr.value)">
              </mat-form-field>
            </div>

            <div class="col-2">
              <mat-form-field>
                <mat-label>Moisture</mat-label>
                <input matInput #mositure placeholder="Moisture" [ngModel]="+this.tS.quality['pallets'][itemindex]['moisture'] | percent:'1.1-1'" (blur)="changeValue('pallets','moisture',itemindex,mositure.value)">
              </mat-form-field>
            </div>

            <div class="col-2">
              <mat-form-field>
                <mat-label>No of Nuts per kg</mat-label>
                <input matInput type="number" step="1" #nutsperkg placeholder="Number of Nuts per kg" [ngModel]="+this.tS.quality['pallets'][itemindex]['nutsperkg'] | number:'1.0-0'" (blur)="changeValue('pallets','nutsperkg',itemindex,nutsperkg.value)" (input)="changeValue('pallets','nutsperkg',itemindex,nutsperkg.value)">
              </mat-form-field>
            </div>

            <div class="col-2">
              <mat-form-field>
                <mat-label>Quantity of Bags</mat-label>
                <input matInput type="number" step="1" placeholder="Quantity of Bags" [(ngModel)]="this.tS.quality['pallets'][itemindex]['tunit']">
              </mat-form-field>
            </div>

            <div class="col-2 pe-0">
              <mat-form-field>
                <mat-label>Total kg</mat-label>
                <input matInput type="number" step="1000" placeholder="Total kg" [(ngModel)]="+this.tS.quality['pallets'][itemindex]['tweight']">
              </mat-form-field>
            </div>

          </div>

          <hr>

        </div>

      </div>
    </div>   

    <br>

    <div class="col px-0">  
      <div class="form-container">  

        <div *ngFor="let item of this.tS.quality['sized']; let itemindex=index">

          <hr *ngIf="itemindex>0">

          <div class="row justify-content-between px-2">          

            <h3 class="ps-0">Sample Results: {{ this.tS.quality['sized'][itemindex]['size'] }}</h3>

            <div class="ps-0">
              <mat-form-field>
                <mat-label>Proportion</mat-label>
                <input matInput #split placeholder="Proportion" [ngModel]="+this.tS.quality['sized'][itemindex]['split'] | percent:'1.1-1'" (blur)="changeValue('sized','split',itemindex,split.value)">
              </mat-form-field>
            </div>

          </div>

        </div>

      </div>
    </div>

    <br>
    
  </div>
</div>