import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PortalService } from '../../../auth/portal.service';

declare var require: any;

@Injectable()
export class BuyingLoginService{
	private postBody: {};
	private username: string;
	private passwordHash: string;
	public httpRoot = (this.portalService.mode=='dev' ?  'api/' + this.portalService.dev + 'jwt-buying/' : this.portalService.root + 'api/jwt-buying/index.php');
	httpRes: any;
	private d: Date;
	private timeNow: Date;
	public season: number = 2020;
	public seasons: number [] = [];
	expiration = -1;
	uname: string;
	email: string;
	selectedaccess: string;
	selectedmember: string;
	selectedmembername: string;
	memberships: [];
	public dropdown: any[any] = [];
	public data: any[any] = [];
	public columns: any[any] = [];

	constructor(
		private http: HttpClient,
		private router: Router,
		private portalService: PortalService,
    private messageService: MessageService) {}

	loginRequest(username: string, password: string) {
		if (username!==null) {
	    var sha512 = require('js-sha512');
	    this.passwordHash = sha512(password); 
			this.postBody = {
				"name":"generateToken",
				"param":{
					"email": username.toLowerCase(),
					"pass": this.passwordHash
				}
			};
			return this.http.post(
				this.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': ' token'
					})
				})
				.subscribe(
	        (response) => {
	        	try {
	        		if (response['response']['status']==200) {
			        	if (this.setSession(response)) {
				        	this.setSeasons(response['response']['result']['season']);
				        	this.firstMembership();
				          this.router.navigate(['/buying/home']);
				          this.httpRes = response;
			        	}
			        } else {
			        	this.toast('Login Error',response['response']['result'],'warn');
			        }
		        } catch (e) {
			        this.toast('Login Error',response['error']['message'],'warn');
		        }
	        },
	        (error) => this.toast('Login Error',"Unable to connect to server",'warn')
	      );
		}
	}

	setSession(authResult) {
		if (authResult != undefined) {
	    if (authResult.response.result.token != null) {
	    	this.d = new Date();
		    this.expiration = Math.floor((+authResult.response.result.exp - +authResult.response.result.iat) * 1000 + this.d.getTime());
		 		this.uname = (authResult.response.result.uname == null ? this.uname : authResult.response.result.uname );
		    this.email = (authResult.response.result.email == null ? this.email : authResult.response.result.email );
		    localStorage.setItem('buying_id_token', authResult.response.result.token);
		    localStorage.setItem('buying_memberships', JSON.stringify(authResult.response.result.ms));

		  	return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

  setSeasons(season) {
    for (var i = this.portalService.portalMod()['startyear']; i <= +season + 1; i++) {
      this.seasons[i-this.portalService.portalMod()['startyear']] = i;
    }
  }

	logOut() {
		localStorage.clear();
		this.memberships = null;
		this.selectedaccess = null;
		this.selectedmember = null;
		this.selectedmembername = null;
		this.expiration = -1;
		this.router.navigate(['/buying/login']);
	}

	public isAuthenticated() {
		if (typeof this.selectedmember !== 'undefined') {
			return this.getExpiration() > 0;
		}
		return false;
	}

	getExpiration() {
		this.timeNow = new Date();
		const timeLeft = this.expiration - this.timeNow.getTime();
		return timeLeft;
	}

	firstMembership(i = 0) {
		if (localStorage.getItem('buying_memberships') != '[]') {
			this.memberships = JSON.parse(localStorage.getItem('buying_memberships'));
			this.selectedmember = JSON.parse(localStorage.getItem('buying_memberships'))[i].membership;
			this.selectedmembername = JSON.parse(localStorage.getItem('buying_memberships'))[i].name;
			return this.selectedmember;
		} else {
			this.toast('Login Error','No active memberships assigned to this user.','warn');
		}
		this.logOut();
	}

	selectMembership(membership, season = this.season) {
		this.season = season;
		for (var i = 0; i < this.memberships.length; i++) {
			if (this.memberships[i]['membership']==membership) {
				this.firstMembership(i);
				return membership;
			}
		}
	}

	toast(summary, detail, severity = 'success', life = 2000, key = 'tc') {
		var sticky = (life==0 ? true : false);	
    this.messageService.add({key: key, severity:severity, life:life, summary:summary, detail:detail, sticky:sticky});
	}

  async confirm(message, acceptLabel = 'Yes', rejectLabel = 'No'): Promise<boolean> {
		return await this.portalService.confirmBool(message, acceptLabel, rejectLabel); 		
	}

  async dataRequest(api: string, param) {
		this.postBody = {
			"name": api,
			"param": param
		};
		//console.log(this.postBody);
		let response = await fetch(
			this.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('buying_id_token')
				}
			}
		)
		return response.json();
	}

	async loadData(api: string, param = null) {
  	if (param==null) {
  		param = {
  			"season": this.season,
  			"producer": this.selectedmember
  		};
  	}
		const response = await this.dataRequest(api, param);
    this.setSession(response);
		return response['response']['result']['reply'];
	}

	storeResult(rawData, dataGroup) {
		switch (dataGroup) {
			default:
				this.dropdown[dataGroup.slice(0)] = rawData.response.result.reply;
		}
	}


	getData(dataGroup: string, p = {}, dataName = null) {
		this.postBody = {
			"name": dataGroup,
			"param": p
		};
		return this.http.post(
			this.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('buying_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.setSession(response);
        	if (dataName) {
        		this[dataName.slice(0)][dataGroup.slice(0)] = response['response']['result']['reply'];        		
        	} else {
        		this.data[dataGroup] = response['response']['result']['reply'];
        	}
        },
        (error) => console.log(error)
      );
	}

	getDropdown(dataGroup) {
		this.getData(dataGroup, {"location": this.selectedmember} ,'dropdown');
	}

	portalUpdate() {
		this.postBody = {
			"name":"portalUpdate",
			"param":{
					"confirm": this.portalService.portalMod()['confirm']
			}
		};
		return this.http.post(
			this.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': ' token'
				})
			})
			.subscribe(
        (response) => {
        	try {
        		if (response['response']['status']==200) {
		        	this.portalService.updatePortal(response['response']['result']);
		        }
	        } catch (e) {	        }
        },
        (error) => console.log(error)
      );
	}

	public isAuthorised(permission: string) {
		if (localStorage.getItem('team_memberships') != '[]') {
			var memberships = JSON.parse(localStorage.getItem('logistics_memberships'));
			if (memberships!=null) {
				for (var i = 0; i < memberships.length; i++) {
					if (memberships[i].membership == permission) {
						return true;
					}
				}
			}
		}
		return false;
	}


}