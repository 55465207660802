import { Injectable } from '@angular/core';
import { Country } from '../interfaces/dropdown.interface';

@Injectable()
export class CountryService{
	public country: Country = {"id": 159};
	countryid: number;
	public delcountry: Country = {"id": null};
	public expcountry: Country = {"id": 159};
	delcountryid: number;
	public states: any;
	public state: string;
	public ports: any;
	public expports: any = [
 		"Durban",
		"East London",
		"Port Elizabeth",
		"Cape Town",
		"Richards Bay",
		"Ngqura"
	];
	public port: string;
	public expport: string = "Durban";
	public US: boolean = false;
	public EU: boolean = false;
	public countries = [
			{
		   "id": 0,
		   "EU": false,
		   "continent": "",
		   "country": "Afghanistan",
		   "states": [
		      "Badakhshan",
		      "Badghis",
		      "Baghlan",
		      "Balkh",
		      "Bamian",
		      "Daykondi",
		      "Farah",
		      "Faryab",
		      "Ghazni",
		      "Ghowr",
		      "Helmand",
		      "Herat",
		      "Jowzjan",
		      "Kabul",
		      "Kandahar",
		      "Kapisa",
		      "Khost",
		      "Konar",
		      "Kondoz",
		      "Laghman",
		      "Lowgar",
		      "Nangarhar",
		      "Nimruz",
		      "Nurestan",
		      "Oruzgan",
		      "Paktia",
		      "Paktika",
		      "Panjshir",
		      "Parvan",
		      "Samangan",
		      "Sar-e Pol",
		      "Takhar",
		      "Vardak",
		      "Zabol"
		   ]
		},
		 {
		   "id": 1,
		   "EU": false,
		   "continent": "",
		   "country": "Albania",
		   "states": [
		      "Berat",
		      "Dibres",
		      "Durres",
		      "Elbasan",
		      "Fier",
		      "Gjirokastre",
		      "Korce",
		      "Kukes",
		      "Lezhe",
		      "Shkoder",
		      "Tirane",
		      "Vlore"
		   ],
		   "ports": [
		   		"Durres"
		   ]
		},
		 {
		   "id": 2,
		   "EU": false,
		   "continent": "",
		   "country": "Algeria",
		   "states": [
		      "Adrar",
		      "Ain Defla",
		      "Ain Temouchent",
		      "Alger",
		      "Annaba",
		      "Batna",
		      "Bechar",
		      "Bejaia",
		      "Biskra",
		      "Blida",
		      "Bordj Bou Arreridj",
		      "Bouira",
		      "Boumerdes",
		      "Chlef",
		      "Constantine",
		      "Djelfa",
		      "El Bayadh",
		      "El Oued",
		      "El Tarf",
		      "Ghardaia",
		      "Guelma",
		      "Illizi",
		      "Jijel",
		      "Khenchela",
		      "Laghouat",
		      "Muaskar",
		      "Medea",
		      "Mila",
		      "Mostaganem",
		      "M'Sila",
		      "Naama",
		      "Oran",
		      "Ouargla",
		      "Oum el Bouaghi",
		      "Relizane",
		      "Saida",
		      "Setif",
		      "Sidi Bel Abbes",
		      "Skikda",
		      "Souk Ahras",
		      "Tamanghasset",
		      "Tebessa",
		      "Tiaret",
		      "Tindouf",
		      "Tipaza",
		      "Tissemsilt",
		      "Tizi Ouzou",
		      "Tlemcen"
		   ],
		   "ports": [
		   		"Algiers",
					"Djen Djen"
		   ]
		},
		 {
		   "id": 3,
		   "EU": false,
		   "continent": "",
		   "country": "Andorra",
		   "states": [
		      "Andorra la Vella",
		      "Canillo",
		      "Encamp",
		      "Escaldes-Engordany",
		      "La Massana",
		      "Ordino",
		      "Sant Julia de Loria"
		   ]
		},
		 {
		   "id": 4,
		   "EU": false,
		   "continent": "",
		   "country": "Angola",
		   "states": [
		      "Bengo",
		      "Benguela",
		      "Bie",
		      "Cabinda",
		      "Cuando Cubango",
		      "Cuanza Norte",
		      "Cuanza Sul",
		      "Cunene",
		      "Huambo",
		      "Huila",
		      "Luanda",
		      "Lunda Norte",
		      "Lunda Sul",
		      "Malanje",
		      "Moxico",
		      "Namibe",
		      "Uige",
		      "Zaire"
		   ],
		   "ports": [
			  	"Amboim",
					"Cabinda",
					"Lobito",
					"Luanda",
					"Namibe",
					"Soyo"
			]
		},
		 {
		   "id": 5,
		   "EU": false,
		   "continent": "",
		   "country": "Antarctica",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 6,
		   "EU": false,
		   "continent": "",
		   "country": "Antigua and Barbuda",
		   "states": [
		      "Barbuda",
		      "Redonda",
		      "Saint George",
		      "Saint John",
		      "Saint Mary",
		      "Saint Paul",
		      "Saint Peter",
		      "Saint Philip"
		   ]
		},
		 {
		   "id": 7,
		   "EU": false,
		   "continent": "",
		   "country": "Argentina",
		   "states": [
		      "Buenos Aires",
		      "Buenos Aires Capital",
		      "Catamarca",
		      "Chaco",
		      "Chubut",
		      "Cordoba",
		      "Corrientes",
		      "Entre Rios",
		      "Formosa",
		      "Jujuy",
		      "La Pampa",
		      "La Rioja",
		      "Mendoza",
		      "Misiones",
		      "Neuquen",
		      "Rio Negro",
		      "Salta",
		      "San Juan",
		      "San Luis",
		      "Santa Cruz",
		      "Santa Fe",
		      "Santiago del Estero",
		      "Tierra del Fuego",
		      "Tucuman"
		   ],
		   "ports": [
			  	"Buenos Aires"
			]
		},
		 {
		   "id": 8,
		   "EU": false,
		   "continent": "",
		   "country": "Armenia",
		   "states": [
		      "Aragatsotn",
		      "Ararat",
		      "Armavir",
		      "Geghark'unik'",
		      "Kotayk'",
		      "Lorri",
		      "Shirak",
		      "Syunik'",
		      "Tavush",
		      "Vayots' Dzor",
		      "Yerevan"
		   ]
		},
		 {
		   "id": 9,
		   "EU": false,
		   "continent": "",
		   "country": "Australia",
		   "states": [
		      "New South Wales",
		      "Queensland",
		      "South Australia",
		      "Tasmania",
		      "Victoria",
		      "Western Australia"
		   ],
		   "ports": [
		   		"Port Botany (seaport), Sydney",
					"Brisbane",
					"Fremantle",
					"Melbourne",
					"Adelaide"
			]
		},
		 {
		   "id": 10,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Austria",
		   "states": [
		      "Burgenland",
		      "Kaernten",
		      "Niederoesterreich",
		      "Oberoesterreich",
		      "Salzburg",
		      "Steiermark",
		      "Tirol",
		      "Vorarlberg",
		      "Wien"
		   ]
		},
		 {
		   "id": 11,
		   "EU": false,
		   "continent": "",
		   "country": "Azerbaijan",
		   "states": [
		      "Abseron Rayonu",
		      "Agcabadi Rayonu",
		      "Agdam Rayonu",
		      "Agdas Rayonu",
		      "Agstafa Rayonu",
		      "Agsu Rayonu",
		      "Astara Rayonu",
		      "Balakan Rayonu",
		      "Barda Rayonu",
		      "Beylaqan Rayonu",
		      "Bilasuvar Rayonu",
		      "Cabrayil Rayonu",
		      "Calilabad Rayonu",
		      "Daskasan Rayonu",
		      "Davaci Rayonu",
		      "Fuzuli Rayonu",
		      "Gadabay Rayonu",
		      "Goranboy Rayonu",
		      "Goycay Rayonu",
		      "Haciqabul Rayonu",
		      "Imisli Rayonu",
		      "Ismayilli Rayonu",
		      "Kalbacar Rayonu",
		      "Kurdamir Rayonu",
		      "Lacin Rayonu",
		      "Lankaran Rayonu",
		      "Lerik Rayonu",
		      "Masalli Rayonu",
		      "Neftcala Rayonu",
		      "Oguz Rayonu",
		      "Qabala Rayonu",
		      "Qax Rayonu",
		      "Qazax Rayonu",
		      "Qobustan Rayonu",
		      "Quba Rayonu",
		      "Qubadli Rayonu",
		      "Qusar Rayonu",
		      "Saatli Rayonu",
		      "Sabirabad Rayonu",
		      "Saki Rayonu",
		      "Salyan Rayonu",
		      "Samaxi Rayonu",
		      "Samkir Rayonu",
		      "Samux Rayonu",
		      "Siyazan Rayonu",
		      "Susa Rayonu",
		      "Tartar Rayonu",
		      "Tovuz Rayonu",
		      "Ucar Rayonu",
		      "Xacmaz Rayonu",
		      "Xanlar Rayonu",
		      "Xizi Rayonu",
		      "Xocali Rayonu",
		      "Xocavand Rayonu",
		      "Yardimli Rayonu",
		      "Yevlax Rayonu",
		      "Zangilan Rayonu",
		      "Zaqatala Rayonu",
		      "Zardab Rayonu",
		      "Ali Bayramli Sahari",
		      "Baki Sahari",
		      "Ganca Sahari",
		      "Lankaran Sahari",
		      "Mingacevir Sahari",
		      "Naftalan Sahari",
		      "Saki Sahari",
		      "Sumqayit Sahari",
		      "Susa Sahari",
		      "Xankandi Sahari",
		      "Yevlax Sahari",
		      "Naxcivan Muxtar"
		   ]
		},
		 {
		   "id": 12,
		   "EU": false,
		   "continent": "",
		   "country": "Bahamas",
		   "states": [
		      "Acklins and Crooked Islands",
		      "Bimini",
		      "Cat Island",
		      "Exuma",
		      "Freeport",
		      "Fresh Creek",
		      "Governor's Harbour",
		      "Green Turtle Cay",
		      "Harbour Island",
		      "High Rock",
		      "Inagua",
		      "Kemps Bay",
		      "Long Island",
		      "Marsh Harbour",
		      "Mayaguana",
		      "New Providence",
		      "Nichollstown and Berry Islands",
		      "Ragged Island",
		      "Rock Sound",
		      "Sandy Point",
		      "San Salvador and Rum Cay"
		   ]
		},
		 {
		   "id": 13,
		   "EU": false,
		   "continent": "",
		   "country": "Bahrain",
		   "states": [
		      "Al Hadd",
		      "Al Manamah",
		      "Al Mintaqah al Gharbiyah",
		      "Al Mintaqah al Wusta",
		      "Al Mintaqah ash Shamaliyah",
		      "Al Muharraq",
		      "Ar Rifa' wa al Mintaqah al Janubiyah",
		      "Jidd Hafs",
		      "Madinat Hamad",
		      "Madinat 'Isa",
		      "Juzur Hawar",
		      "Sitrah"
		   ]
		},
		 {
		   "id": 14,
		   "EU": false,
		   "continent": "",
		   "country": "Bangladesh",
		   "states": [
		      "Barisal",
		      "Chittagong",
		      "Dhaka",
		      "Khulna",
		      "Rajshahi",
		      "Sylhet"
		   ]
		},
		 {
		   "id": 15,
		   "EU": false,
		   "continent": "",
		   "country": "Barbados",
		   "states": [
		      "Christ Church",
		      "Saint Andrew",
		      "Saint George",
		      "Saint James",
		      "Saint John",
		      "Saint Joseph",
		      "Saint Lucy",
		      "Saint Michael",
		      "Saint Peter",
		      "Saint Philip",
		      "Saint Thomas"
		   ]
		},
		 {
		   "id": 16,
		   "EU": false,
		   "continent": "",
		   "country": "Belarus",
		   "states": [
		      "Brest",
		      "Homyel",
		      "Horad Minsk",
		      "Hrodna",
		      "Mahilyow",
		      "Minsk",
		      "Vitsyebsk"
		   ]
		},
		 {
		   "id": 17,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Belgium",
		   "states": [
		      "Antwerpen",
		      "Brabant Wallon",
		      "Brussels",
		      "Flanders",
		      "Hainaut",
		      "Liege",
		      "Limburg",
		      "Luxembourg",
		      "Namur",
		      "Oost-Vlaanderen",
		      "Vlaams-Brabant",
		      "Wallonia",
		      "West-Vlaanderen"
		   ],
		   "ports": [
		  		"Antwerp",
					"Zeebrugge"
			]
		},
		 {
		   "id": 18,
		   "EU": false,
		   "continent": "",
		   "country": "Belize",
		   "states": [
		      "Belize",
		      "Cayo",
		      "Corozal",
		      "Orange Walk",
		      "Stann Creek",
		      "Toledo"
		   ]
		},
		 {
		   "id": 19,
		   "EU": false,
		   "continent": "",
		   "country": "Benin",
		   "states": [
		      "Alibori",
		      "Atakora",
		      "Atlantique",
		      "Borgou",
		      "Collines",
		      "Donga",
		      "Kouffo",
		      "Littoral",
		      "Mono",
		      "Oueme",
		      "Plateau",
		      "Zou"
		   ]
		},
		 {
		   "id": 20,
		   "EU": false,
		   "continent": "",
		   "country": "Bermuda",
		   "states": [
		      "Devonshire",
		      "Hamilton",
		      "Hamilton",
		      "Paget",
		      "Pembroke",
		      "Saint George",
		      "Saint George's",
		      "Sandys",
		      "Smith's",
		      "Southampton",
		      "Warwick"
		   ]
		},
		 {
		   "id": 21,
		   "EU": false,
		   "continent": "",
		   "country": "Bhutan",
		   "states": [
		      "Bumthang",
		      "Chukha",
		      "Dagana",
		      "Gasa",
		      "Haa",
		      "Lhuntse",
		      "Mongar",
		      "Paro",
		      "Pemagatshel",
		      "Punakha",
		      "Samdrup Jongkhar",
		      "Samtse",
		      "Sarpang",
		      "Thimphu",
		      "Trashigang",
		      "Trashiyangste",
		      "Trongsa",
		      "Tsirang",
		      "Wangdue Phodrang",
		      "Zhemgang"
		   ]
		},
		 {
		   "id": 22,
		   "EU": false,
		   "continent": "",
		   "country": "Bolivia",
		   "states": [
		      "Chuquisaca",
		      "Cochabamba",
		      "Beni",
		      "La Paz",
		      "Oruro",
		      "Pando",
		      "Potosi",
		      "Santa Cruz",
		      "Tarija"
		   ]
		},
		 {
		   "id": 23,
		   "EU": false,
		   "continent": "",
		   "country": "Bosnia and Herzegovina",
		   "states": [
		      "Una-Sana [Federation]",
		      "Posavina [Federation]",
		      "Tuzla [Federation]",
		      "Zenica-Doboj [Federation]",
		      "Bosnian Podrinje [Federation]",
		      "Central Bosnia [Federation]",
		      "Herzegovina-Neretva [Federation]",
		      "West Herzegovina [Federation]",
		      "Sarajevo [Federation]",
		      " West Bosnia [Federation]",
		      "Banja Luka [RS]",
		      "Bijeljina [RS]",
		      "Doboj [RS]",
		      "Fo?a [RS]",
		      "Sarajevo-Romanija [RS]",
		      "Trebinje [RS]",
		      "Vlasenica [RS]"
		   ]
		},
		 {
		   "id": 24,
		   "EU": false,
		   "continent": "",
		   "country": "Botswana",
		   "states": [
		      "Central",
		      "Ghanzi",
		      "Kgalagadi",
		      "Kgatleng",
		      "Kweneng",
		      "North East",
		      "North West",
		      "South East",
		      "Southern"
		   ]
		},
		 {
		   "id": 25,
		   "EU": false,
		   "continent": "",
		   "country": "Brazil",
		   "states": [
		      "Acre",
		      "Alagoas",
		      "Amapa",
		      "Amazonas",
		      "Bahia",
		      "Ceara",
		      "Distrito Federal",
		      "Espirito Santo",
		      "Goias",
		      "Maranhao",
		      "Mato Grosso",
		      "Mato Grosso do Sul",
		      "Minas Gerais",
		      "Para",
		      "Paraiba",
		      "Parana",
		      "Pernambuco",
		      "Piaui",
		      "Rio de Janeiro",
		      "Rio Grande do Norte",
		      "Rio Grande do Sul",
		      "Rondonia",
		      "Roraima",
		      "Santa Catarina",
		      "Sao Paulo",
		      "Sergipe",
		      "Tocantins"
		   ],
		   "ports": [
			   	"Santos",
					"Centro",
					"Paranaguá",
					"Sepetiba",
					"Rio de Janeiro",
					"Salvador",
					"Vitoria"
			]
		},
		 {
		   "id": 26,
		   "EU": false,
		   "continent": "",
		   "country": "Brunei",
		   "states": [
		      "Belait",
		      "Brunei and Muara",
		      "Temburong",
		      "Tutong"
		   ]
		},
		 {
		   "id": 27,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Bulgaria",
		   "states": [
		      "Blagoevgrad",
		      "Burgas",
		      "Dobrich",
		      "Gabrovo",
		      "Khaskovo",
		      "Kurdzhali",
		      "Kyustendil",
		      "Lovech",
		      "Montana",
		      "Pazardzhik",
		      "Pernik",
		      "Pleven",
		      "Plovdiv",
		      "Razgrad",
		      "Ruse",
		      "Shumen",
		      "Silistra",
		      "Sliven",
		      "Smolyan",
		      "Sofiya",
		      "Sofiya-Grad",
		      "Stara Zagora",
		      "Turgovishte",
		      "Varna",
		      "Veliko Turnovo",
		      "Vidin",
		      "Vratsa",
		      "Yambol"
		   ]
		},
		 {
		   "id": 28,
		   "EU": false,
		   "continent": "",
		   "country": "Burkina Faso",
		   "states": [
		      "Bale",
		      "Bam",
		      "Banwa",
		      "Bazega",
		      "Bougouriba",
		      "Boulgou",
		      "Boulkiemde",
		      "Comoe",
		      "Ganzourgou",
		      "Gnagna",
		      "Gourma",
		      "Houet",
		      "Ioba",
		      "Kadiogo",
		      "Kenedougou",
		      "Komondjari",
		      "Kompienga",
		      "Kossi",
		      "Koulpelogo",
		      "Kouritenga",
		      "Kourweogo",
		      "Leraba",
		      "Loroum",
		      "Mouhoun",
		      "Namentenga",
		      "Nahouri",
		      "Nayala",
		      "Noumbiel",
		      "Oubritenga",
		      "Oudalan",
		      "Passore",
		      "Poni",
		      "Sanguie",
		      "Sanmatenga",
		      "Seno",
		      "Sissili",
		      "Soum",
		      "Sourou",
		      "Tapoa",
		      "Tuy",
		      "Yagha",
		      "Yatenga",
		      "Ziro",
		      "Zondoma",
		      "Zoundweogo"
		   ]
		},
		 {
		   "id": 29,
		   "EU": false,
		   "continent": "",
		   "country": "Burma",
		   "states": [
		      "Ayeyarwady",
		      "Bago",
		      "Magway",
		      "Mandalay",
		      "Sagaing",
		      "Tanintharyi",
		      "Yangon",
		      "Chin State",
		      "Kachin State",
		      "Kayin State",
		      "Kayah State",
		      "Mon State",
		      "Rakhine State",
		      "Shan State"
		   ]
		},
		 {
		   "id": 30,
		   "EU": false,
		   "continent": "",
		   "country": "Burundi",
		   "states": [
		      "Bubanza",
		      "Bujumbura Mairie",
		      "Bujumbura Rural",
		      "Bururi",
		      "Cankuzo",
		      "Cibitoke",
		      "Gitega",
		      "Karuzi",
		      "Kayanza",
		      "Kirundo",
		      "Makamba",
		      "Muramvya",
		      "Muyinga",
		      "Mwaro",
		      "Ngozi",
		      "Rutana",
		      "Ruyigi"
		   ]
		},
		 {
		   "id": 31,
		   "EU": false,
		   "continent": "",
		   "country": "Cambodia",
		   "states": [
		      "Banteay Mean Chey",
		      "Batdambang",
		      "Kampong Cham",
		      "Kampong Chhnang",
		      "Kampong Spoe",
		      "Kampong Thum",
		      "Kampot",
		      "Kandal",
		      "Koh Kong",
		      "Kracheh",
		      "Mondol Kiri",
		      "Otdar Mean Chey",
		      "Pouthisat",
		      "Preah Vihear",
		      "Prey Veng",
		      "Rotanakir",
		      "Siem Reab",
		      "Stoeng Treng",
		      "Svay Rieng",
		      "Takao",
		      "Keb",
		      "Pailin",
		      "Phnom Penh",
		      "Preah Seihanu"
		   ]
		},
		 {
		   "id": 32,
		   "EU": false,
		   "continent": "",
		   "country": "Cameroon",
		   "states": [
		      "Adamaoua",
		      "Centre",
		      "Est",
		      "Extreme-Nord",
		      "Littoral",
		      "Nord",
		      "Nord-Ouest",
		      "Ouest",
		      "Sud",
		      "Sud-Ouest"
		   ]
		},
		 {
		   "id": 33,
		   "EU": false,
		   "continent": "",
		   "country": "Canada",
		   "states": [
		      "Alberta",
		      "British Columbia",
		      "Manitoba",
		      "New Brunswick",
		      "Newfoundland and Labrador",
		      "Northwest Territories",
		      "Nova Scotia",
		      "Nunavut",
		      "Ontario",
		      "Prince Edward Island",
		      "Quebec",
		      "Saskatchewan",
		      "Yukon Territory"
		   ],
		   "ports": [
		   		"Montreal",
					"Port Metro Vancouver",
					"Prince Rupert",
					"Saint John",
					"Halifax",
					"Nanaimo"
			]
		},
		 {
		   "id": 34,
		   "EU": false,
		   "continent": "",
		   "country": "Cape Verde",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 35,
		   "EU": false,
		   "continent": "",
		   "country": "Central African Republic",
		   "states": [
		      "Bamingui-Bangoran",
		      "Bangui",
		      "Basse-Kotto",
		      "Haute-Kotto",
		      "Haut-Mbomou",
		      "Kemo",
		      "Lobaye",
		      "Mambere-Kadei",
		      "Mbomou",
		      "Nana-Grebizi",
		      "Nana-Mambere",
		      "Ombella-Mpoko",
		      "Ouaka",
		      "Ouham",
		      "Ouham-Pende",
		      "Sangha-Mbaere",
		      "Vakaga"
		   ]
		},
		 {
		   "id": 36,
		   "EU": false,
		   "continent": "",
		   "country": "Chad",
		   "states": [
		      "Batha",
		      "Biltine",
		      "Borkou-Ennedi-Tibesti",
		      "Chari-Baguirmi",
		      "Guéra",
		      "Kanem",
		      "Lac",
		      "Logone Occidental",
		      "Logone Oriental",
		      "Mayo-Kebbi",
		      "Moyen-Chari",
		      "Ouaddaï",
		      "Salamat",
		      "Tandjile"
		   ]
		},
		 {
		   "id": 37,
		   "EU": false,
		   "continent": "",
		   "country": "Chile",
		   "states": [
		      "Aysen",
		      "Antofagasta",
		      "Araucania",
		      "Atacama",
		      "Bio-Bio",
		      "Coquimbo",
		      "O'Higgins",
		      "Los Lagos",
		      "Magallanes y la Antartica Chilena",
		      "Maule",
		      "Santiago Region Metropolitana",
		      "Tarapaca",
		      "Valparaiso"
		   ],
		   "ports": [
		   		"Valparaiso",
					"Antofagasta",
					"Iquique",
					"Arica",
					"San Vicente",
					"San Antonio",
					"Lirquen",
					"Coronel"
			]
		},
		 {
		   "id": 38,
		   "EU": false,
		   "continent": "",
		   "country": "China",
		   "states": [
		      "Anhui",
		      "Fujian",
		      "Gansu",
		      "Guangdong",
		      "Guizhou",
		      "Hainan",
		      "Hebei",
		      "Heilongjiang",
		      "Henan",
		      "Hubei",
		      "Hunan",
		      "Jiangsu",
		      "Jiangxi",
		      "Jilin",
		      "Liaoning",
		      "Qinghai",
		      "Shaanxi",
		      "Shandong",
		      "Shanxi",
		      "Sichuan",
		      "Yunnan",
		      "Zhejiang",
		      "Guangxi",
		      "Nei Mongol",
		      "Ningxia",
		      "Xinjiang",
		      "Xizang (Tibet)",
		      "Beijing",
		      "Chongqing",
		      "Shanghai",
		      "Tianjin"
		   ],
		   "ports": [
		   		"Dalian",
					"Foshan",
					"Guangzhou",
					"Hong Kong",
					"Lianyungang",
					"Ningbo",
					"Qingdao",
					"Shanghai",
					"Shenzhen",
					"Suzhou",
					"Tianjin",
					"Xiamen",
					"Yantai",
					"Yingkou"
		   ]
		},
		 {
		   "id": 39,
		   "EU": false,
		   "continent": "",
		   "country": "Colombia",
		   "states": [
		      "Amazonas",
		      "Antioquia",
		      "Arauca",
		      "Atlantico",
		      "Bogota District Capital",
		      "Bolivar",
		      "Boyaca",
		      "Caldas",
		      "Caqueta",
		      "Casanare",
		      "Cauca",
		      "Cesar",
		      "Choco",
		      "Cordoba",
		      "Cundinamarca",
		      "Guainia",
		      "Guaviare",
		      "Huila",
		      "La Guajira",
		      "Magdalena",
		      "Meta",
		      "Narino",
		      "Norte de Santander",
		      "Putumayo",
		      "Quindio",
		      "Risaralda",
		      "San Andres & Providencia",
		      "Santander",
		      "Sucre",
		      "Tolima",
		      "Valle del Cauca",
		      "Vaupes",
		      "Vichada"
		   ],
		   "ports": [
		   		"Barranquilla, Cartagena",
					"Buenaventura",
					"Santa Marta"
			]
		},
		 {
		   "id": 40,
		   "EU": false,
		   "continent": "",
		   "country": "Comoros",
		   "states": [
		      "Grande Comore (Njazidja)",
		      "Anjouan (Nzwani)",
		      "Moheli (Mwali)"
		   ]
		},
		 {
		   "id": 41,
		   "EU": false,
		   "continent": "",
		   "country": "Congo, Democratic Republic",
		   "states": [
		      "Bandundu",
		      "Bas-Congo",
		      "Equateur",
		      "Kasai-Occidental",
		      "Kasai-Oriental",
		      "Katanga",
		      "Kinshasa",
		      "Maniema",
		      "Nord-Kivu",
		      "Orientale",
		      "Sud-Kivu"
		   ]
		},
		 {
		   "id": 42,
		   "EU": false,
		   "continent": "",
		   "country": "Congo, Republic of the",
		   "states": [
		      "Bouenza",
		      "Brazzaville",
		      "Cuvette",
		      "Cuvette-Ouest",
		      "Kouilou",
		      "Lekoumou",
		      "Likouala",
		      "Niari",
		      "Plateaux",
		      "Pool",
		      "Sangha"
		   ]
		},
		 {
		   "id": 43,
		   "EU": false,
		   "continent": "",
		   "country": "Costa Rica",
		   "states": [
		      "Alajuela",
		      "Cartago",
		      "Guanacaste",
		      "Heredia",
		      "Limon",
		      "Puntarenas",
		      "San Jose"
		   ]
		},
		 {
		   "id": 44,
		   "EU": false,
		   "continent": "",
		   "country": "Cote d'Ivoire",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 45,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Croatia",
		   "states": [
		      "Bjelovarsko-Bilogorska",
		      "Brodsko-Posavska",
		      "Dubrovacko-Neretvanska",
		      "Istarska",
		      "Karlovacka",
		      "Koprivnicko-Krizevacka",
		      "Krapinsko-Zagorska",
		      "Licko-Senjska",
		      "Medimurska",
		      "Osjecko-Baranjska",
		      "Pozesko-Slavonska",
		      "Primorsko-Goranska",
		      "Sibensko-Kninska",
		      "Sisacko-Moslavacka",
		      "Splitsko-Dalmatinska",
		      "Varazdinska",
		      "Viroviticko-Podravska",
		      "Vukovarsko-Srijemska",
		      "Zadarska",
		      "Zagreb",
		      "Zagrebacka"
		   ],
		   "ports": [
		  	 	"Rijeka"
			]
		},
		 {
		   "id": 46,
		   "EU": false,
		   "continent": "",
		   "country": "Cuba",
		   "states": [
		      "Camaguey",
		      "Ciego de Avila",
		      "Cienfuegos",
		      "Ciudad de La Habana",
		      "Granma",
		      "Guantanamo",
		      "Holguin",
		      "Isla de la Juventud",
		      "La Habana",
		      "Las Tunas",
		      "Matanzas",
		      "Pinar del Rio",
		      "Sancti Spiritus",
		      "Santiago de Cuba",
		      "Villa Clara"
		   ]
		},
		 {
		   "id": 47,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Cyprus",
		   "states": [
		      "Famagusta",
		      "Kyrenia",
		      "Larnaca",
		      "Limassol",
		      "Nicosia",
		      "Paphos"
		   ],
		   "ports": [
		  	 	"Limassol"
			]
		},
		 {
		   "id": 48,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Czech Republic",
		   "states": [
		      "Jihocesky Kraj",
		      "Jihomoravsky Kraj",
		      "Karlovarsky Kraj",
		      "Kralovehradecky Kraj",
		      "Liberecky Kraj",
		      "Moravskoslezsky Kraj",
		      "Olomoucky Kraj",
		      "Pardubicky Kraj",
		      "Plzensky Kraj",
		      "Praha",
		      "Stredocesky Kraj",
		      "Ustecky Kraj",
		      "Vysocina",
		      "Zlinsky Kraj"
		   ]
		},
		 {
		   "id": 49,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Denmark",
		   "states": [
		      "Arhus",
		      "Bornholm",
		      "Frederiksberg",
		      "Frederiksborg",
		      "Fyn",
		      "Kobenhavn",
		      "Kobenhavns",
		      "Nordjylland",
		      "Ribe",
		      "Ringkobing",
		      "Roskilde",
		      "Sonderjylland",
		      "Storstrom",
		      "Vejle",
		      "Vestsjalland",
		      "Viborg"
		   ],
		   "ports": [
		   		"Aarhus",
					"Copenhagen Malmö Port",
					"Fredericia",
					"Kalundborg",
					"Aalborg"
		   ]
		},
		 {
		   "id": 50,
		   "EU": false,
		   "continent": "",
		   "country": "Djibouti",
		   "states": [
		      "Ali Sabih",
		      "Dikhil",
		      "Djibouti",
		      "Obock",
		      "Tadjoura"
		   ],
		   "ports": [
		   		"Djibouti"
			]
		},
		 {
		   "id": 51,
		   "EU": false,
		   "continent": "",
		   "country": "Dominica",
		   "states": [
		      "Saint Andrew",
		      "Saint David",
		      "Saint George",
		      "Saint John",
		      "Saint Joseph",
		      "Saint Luke",
		      "Saint Mark",
		      "Saint Patrick",
		      "Saint Paul",
		      "Saint Peter"
		   ]
		},
		 {
		   "id": 52,
		   "EU": false,
		   "continent": "",
		   "country": "Dominican Republic",
		   "states": [
		      "Azua",
		      "Baoruco",
		      "Barahona",
		      "Dajabon",
		      "Distrito Nacional",
		      "Duarte",
		      "Elias Pina",
		      "El Seibo",
		      "Espaillat",
		      "Hato Mayor",
		      "Independencia",
		      "La Altagracia",
		      "La Romana",
		      "La Vega",
		      "Maria Trinidad Sanchez",
		      "Monsenor Nouel",
		      "Monte Cristi",
		      "Monte Plata",
		      "Pedernales",
		      "Peravia",
		      "Puerto Plata",
		      "Salcedo",
		      "Samana",
		      "Sanchez Ramirez",
		      "San Cristobal",
		      "San Jose de Ocoa",
		      "San Juan",
		      "San Pedro de Macoris",
		      "Santiago",
		      "Santiago Rodriguez",
		      "Santo Domingo",
		      "Valverde"
		   ],
		   "ports": [
					"Multimodal Caucedo Port",
					"Rio Haina"
			]
		},
		 {
		   "id": 53,
		   "EU": false,
		   "continent": "",
		   "country": "East Timor",
		   "states": [
		      "Aileu",
		      "Ainaro",
		      "Baucau",
		      "Bobonaro",
		      "Cova-Lima",
		      "Dili",
		      "Ermera",
		      "Lautem",
		      "Liquica",
		      "Manatuto",
		      "Manufahi",
		      "Oecussi",
		      "Viqueque"
		   ]
		},
		 {
		   "id": 54,
		   "EU": false,
		   "continent": "",
		   "country": "Ecuador",
		   "states": [
		      "Azuay",
		      "Bolivar",
		      "Canar",
		      "Carchi",
		      "Chimborazo",
		      "Cotopaxi",
		      "El Oro",
		      "Esmeraldas",
		      "Galapagos",
		      "Guayas",
		      "Imbabura",
		      "Loja",
		      "Los Rios",
		      "Manabi",
		      "Morona-Santiago",
		      "Napo",
		      "Orellana",
		      "Pastaza",
		      "Pichincha",
		      "Sucumbios",
		      "Tungurahua",
		      "Zamora-Chinchipe"
		   ]
		},
		 {
		   "id": 55,
		   "EU": false,
		   "continent": "",
		   "country": "Egypt",
		   "states": [
		      "Ad Daqahliyah",
		      "Al Bahr al Ahmar",
		      "Al Buhayrah",
		      "Al Fayyum",
		      "Al Gharbiyah",
		      "Al Iskandariyah",
		      "Al Isma'iliyah",
		      "Al Jizah",
		      "Al Minufiyah",
		      "Al Minya",
		      "Al Qahirah",
		      "Al Qalyubiyah",
		      "Al Wadi al Jadid",
		      "Ash Sharqiyah",
		      "As Suways",
		      "Aswan",
		      "Asyut",
		      "Bani Suwayf",
		      "Bur Sa'id",
		      "Dumyat",
		      "Janub Sina'",
		      "Kafr ash Shaykh",
		      "Matruh",
		      "Qina",
		      "Shamal Sina'",
		      "Suhaj"
		   ],
		   "ports": [
		   		"Port Said",
					"West Port",
					"East Terminal",
					"Sokhna",
					"Alexandria Port",
					"El-Dekhila",
					"Damietta"
			]
		},
		 {
		   "id": 56,
		   "EU": false,
		   "continent": "",
		   "country": "El Salvador",
		   "states": [
		      "Ahuachapan",
		      "Cabanas",
		      "Chalatenango",
		      "Cuscatlan",
		      "La Libertad",
		      "La Paz",
		      "La Union",
		      "Morazan",
		      "San Miguel",
		      "San Salvador",
		      "Santa Ana",
		      "San Vicente",
		      "Sonsonate",
		      "Usulutan"
		   ]
		},
		 {
		   "id": 57,
		   "EU": false,
		   "continent": "",
		   "country": "Equatorial Guinea",
		   "states": [
		      "Annobon",
		      "Bioko Norte",
		      "Bioko Sur",
		      "Centro Sur",
		      "Kie-Ntem",
		      "Litoral",
		      "Wele-Nzas"
		   ]
		},
		 {
		   "id": 58,
		   "EU": false,
		   "continent": "",
		   "country": "Eritrea",
		   "states": [
		      "Anseba",
		      "Debub",
		      "Debubawi K'eyih Bahri",
		      "Gash Barka",
		      "Ma'akel",
		      "Semenawi Keyih Bahri"
		   ]
		},
		 {
		   "id": 59,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Estonia",
		   "states": [
		      "Harjumaa (Tallinn)",
		      "Hiiumaa (Kardla)",
		      "Ida-Virumaa (Johvi)",
		      "Jarvamaa (Paide)",
		      "Jogevamaa (Jogeva)",
		      "Laanemaa (Haapsalu)",
		      "Laane-Virumaa (Rakvere)",
		      "Parnumaa (Parnu)",
		      "Polvamaa (Polva)",
		      "Raplamaa (Rapla)",
		      "Saaremaa (Kuressaare)",
		      "Tartumaa (Tartu)",
		      "Valgamaa (Valga)",
		      "Viljandimaa (Viljandi)",
		      "Vorumaa (Voru)"
		   ],
		   "ports": [
		   		"Muuga",
					"Tallinn"
			]
		},
		 {
		   "id": 60,
		   "EU": false,
		   "continent": "",
		   "country": "Eswatini",
		   "states": [
		      "Hhohho",
		      "Lubombo",
		      "Manzini",
		      "Shiselweni"
		   ]
		},
		 {
		   "id": 61,
		   "EU": false,
		   "continent": "",
		   "country": "Ethiopia",
		   "states": [
		      "Addis Ababa",
		      "Afar",
		      "Amhara",
		      "Binshangul Gumuz",
		      "Dire Dawa",
		      "Gambela Hizboch",
		      "Harari",
		      "Oromia",
		      "Somali",
		      "Tigray",
		      "Southern Nations, Nationalities, and Peoples Region"
		   ]
		},
		 {
		   "id": 62,
		   "EU": false,
		   "continent": "",
		   "country": "Fiji",
		   "states": [
		      "Central (Suva)",
		      "Eastern (Levuka)",
		      "Northern (Labasa)",
		      "Rotuma",
		      "Western (Lautoka)"
		   ]
		},
		 {
		   "id": 63,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Finland",
		   "states": [
		      "Aland",
		      "Etela-Suomen Laani",
		      "Ita-Suomen Laani",
		      "Lansi-Suomen Laani",
		      "Lappi",
		      "Oulun Laani"
		   ],
		   "ports": [
		   		"HaminaKotka",
					"Helsinki",
					"Pori",
					"Rauma"
			]
		},
		 {
		   "id": 64,
		   "EU": true,
		   "continent": "Europe",
		   "country": "France",
		   "states": [
		      "Alsace",
		      "Aquitaine",
		      "Auvergne",
		      "Basse-Normandie",
		      "Bourgogne",
		      "Bretagne",
		      "Centre",
		      "Champagne-Ardenne",
		      "Corse",
		      "Franche-Comte",
		      "Haute-Normandie",
		      "Ile-de-France",
		      "Languedoc-Roussillon",
		      "Limousin",
		      "Lorraine",
		      "Midi-Pyrenees",
		      "Nord-Pas-de-Calais",
		      "Pays de la Loire",
		      "Picardie",
		      "Poitou-Charentes",
		      "Provence-Alpes-Cote d'Azur",
		      "Rhone-Alpes"
		   ],
		   "ports": [
		   		"Le Havre",
					"Marseille-Fos Port",
					"Dunkirk",
					"Nantes – Saint Nazaire Port"
			]
		},
		 {
		   "id": 65,
		   "EU": false,
		   "continent": "",
		   "country": "Gabon",
		   "states": [
		      "Estuaire",
		      "Haut-Ogooue",
		      "Moyen-Ogooue",
		      "Ngounie",
		      "Nyanga",
		      "Ogooue-Ivindo",
		      "Ogooue-Lolo",
		      "Ogooue-Maritime",
		      "Woleu-Ntem"
		   ]
		},
		 {
		   "id": 66,
		   "EU": false,
		   "continent": "",
		   "country": "Gambia",
		   "states": [
		      "Banjul",
		      "Central River",
		      "Lower River",
		      "North Bank",
		      "Upper River",
		      "Western"
		   ]
		},
		 {
		   "id": 67,
		   "EU": false,
		   "continent": "",
		   "country": "Georgia",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 68,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Germany",
		   "states": [
		      "Baden-Wuerttemberg",
		      "Bayern",
		      "Berlin",
		      "Brandenburg",
		      "Bremen",
		      "Hamburg",
		      "Hessen",
		      "Mecklenburg-Vorpommern",
		      "Niedersachsen",
		      "Nordrhein-Westfalen",
		      "Rheinland-Pfalz",
		      "Saarland",
		      "Sachsen",
		      "Sachsen-Anhalt",
		      "Schleswig-Holstein",
		      "Thueringen"
		   ],
		   "ports": [
		   		"Bremen/Bremerhaven",
					"Germersheim",
					"Hamburg",
					"Lübeck Hafen-Gesellschaft",
					"Rheinhafengesellschaft Weil am Rhein mbH"
			]
		},
		 {
		   "id": 69,
		   "EU": false,
		   "continent": "",
		   "country": "Ghana",
		   "states": [
		      "Ashanti",
		      "Brong-Ahafo",
		      "Central",
		      "Eastern",
		      "Greater Accra",
		      "Northern",
		      "Upper East",
		      "Upper West",
		      "Volta",
		      "Western"
		   ]
		},
		 {
		   "id": 70,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Greece",
		   "states": [
		      "Agion Oros",
		      "Achaia",
		      "Aitolia kai Akarmania",
		      "Argolis",
		      "Arkadia",
		      "Arta",
		      "Attiki",
		      "Chalkidiki",
		      "Chanion",
		      "Chios",
		      "Dodekanisos",
		      "Drama",
		      "Evros",
		      "Evrytania",
		      "Evvoia",
		      "Florina",
		      "Fokidos",
		      "Fthiotis",
		      "Grevena",
		      "Ileia",
		      "Imathia",
		      "Ioannina",
		      "Irakleion",
		      "Karditsa",
		      "Kastoria",
		      "Kavala",
		      "Kefallinia",
		      "Kerkyra",
		      "Kilkis",
		      "Korinthia",
		      "Kozani",
		      "Kyklades",
		      "Lakonia",
		      "Larisa",
		      "Lasithi",
		      "Lefkas",
		      "Lesvos",
		      "Magnisia",
		      "Messinia",
		      "Pella",
		      "Pieria",
		      "Preveza",
		      "Rethynnis",
		      "Rodopi",
		      "Samos",
		      "Serrai",
		      "Thesprotia",
		      "Thessaloniki",
		      "Trikala",
		      "Voiotia",
		      "Xanthi",
		      "Zakynthos"
		   ],
		   "ports": [
		   		"Piraeus, Athens",
					"Thessaloniki",
					"Volos",
					"Alexandroupoli",
					"Heraklion",
					"Kavala"
			]
		},
		 {
		   "id": 71,
		   "EU": false,
		   "continent": "",
		   "country": "Greenland",
		   "states": [
		      "Avannaa (Nordgronland)",
		      "Tunu (Ostgronland)",
		      "Kitaa (Vestgronland)"
		   ]
		},
		 {
		   "id": 72,
		   "EU": false,
		   "continent": "",
		   "country": "Grenada",
		   "states": [
		      "Carriacou and Petit Martinique",
		      "Saint Andrew",
		      "Saint David",
		      "Saint George",
		      "Saint John",
		      "Saint Mark",
		      "Saint Patrick"
		   ]
		},
		 {
		   "id": 73,
		   "EU": false,
		   "continent": "",
		   "country": "Guatemala",
		   "states": [
		      "Alta Verapaz",
		      "Baja Verapaz",
		      "Chimaltenango",
		      "Chiquimula",
		      "El Progreso",
		      "Escuintla",
		      "Guatemala",
		      "Huehuetenango",
		      "Izabal",
		      "Jalapa",
		      "Jutiapa",
		      "Peten",
		      "Quetzaltenango",
		      "Quiche",
		      "Retalhuleu",
		      "Sacatepequez",
		      "San Marcos",
		      "Santa Rosa",
		      "Solola",
		      "Suchitepequez",
		      "Totonicapan",
		      "Zacapa"
		   ]
		},
		 {
		   "id": 74,
		   "EU": false,
		   "continent": "",
		   "country": "Guinea",
		   "states": [
		      "Beyla",
		      "Boffa",
		      "Boke",
		      "Conakry",
		      "Coyah",
		      "Dabola",
		      "Dalaba",
		      "Dinguiraye",
		      "Dubreka",
		      "Faranah",
		      "Forecariah",
		      "Fria",
		      "Gaoual",
		      "Gueckedou",
		      "Kankan",
		      "Kerouane",
		      "Kindia",
		      "Kissidougou",
		      "Koubia",
		      "Koundara",
		      "Kouroussa",
		      "Labe",
		      "Lelouma",
		      "Lola",
		      "Macenta",
		      "Mali",
		      "Mamou",
		      "Mandiana",
		      "Nzerekore",
		      "Pita",
		      "Siguiri",
		      "Telimele",
		      "Tougue",
		      "Yomou"
		   ]
		},
		 {
		   "id": 75,
		   "EU": false,
		   "continent": "",
		   "country": "Guinea-Bissau",
		   "states": [
		      "Bafata",
		      "Biombo",
		      "Bissau",
		      "Bolama",
		      "Cacheu",
		      "Gabu",
		      "Oio",
		      "Quinara",
		      "Tombali"
		   ]
		},
		 {
		   "id": 76,
		   "EU": false,
		   "continent": "",
		   "country": "Guyana",
		   "states": [
		      "Barima-Waini",
		      "Cuyuni-Mazaruni",
		      "Demerara-Mahaica",
		      "East Berbice-Corentyne",
		      "Essequibo Islands-West Demerara",
		      "Mahaica-Berbice",
		      "Pomeroon-Supenaam",
		      "Potaro-Siparuni",
		      "Upper Demerara-Berbice",
		      "Upper Takutu-Upper Essequibo"
		   ]
		},
		 {
		   "id": 77,
		   "EU": false,
		   "continent": "",
		   "country": "Haiti",
		   "states": [
		      "Artibonite",
		      "Centre",
		      "Grand 'Anse",
		      "Nord",
		      "Nord-Est",
		      "Nord-Ouest",
		      "Ouest",
		      "Sud",
		      "Sud-Est"
		   ]
		},
		 {
		   "id": 78,
		   "EU": false,
		   "continent": "",
		   "country": "Honduras",
		   "states": [
		      "Atlantida",
		      "Choluteca",
		      "Colon",
		      "Comayagua",
		      "Copan",
		      "Cortes",
		      "El Paraiso",
		      "Francisco Morazan",
		      "Gracias a Dios",
		      "Intibuca",
		      "Islas de la Bahia",
		      "La Paz",
		      "Lempira",
		      "Ocotepeque",
		      "Olancho",
		      "Santa Barbara",
		      "Valle",
		      "Yoro"
		   ]
		},
		 {
		   "id": 79,
		   "EU": false,
		   "continent": "",
		   "country": "Hong Kong",
		   "states": [
		   
		   ]
		},
		 {
		   "id": 80,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Hungary",
		   "states": [
		      "Bacs-Kiskun",
		      "Baranya",
		      "Bekes",
		      "Borsod-Abauj-Zemplen",
		      "Csongrad",
		      "Fejer",
		      "Gyor-Moson-Sopron",
		      "Hajdu-Bihar",
		      "Heves",
		      "Jasz-Nagykun-Szolnok",
		      "Komarom-Esztergom",
		      "Nograd",
		      "Pest",
		      "Somogy",
		      "Szabolcs-Szatmar-Bereg",
		      "Tolna",
		      "Vas",
		      "Veszprem",
		      "Zala",
		      "Bekescsaba",
		      "Debrecen",
		      "Dunaujvaros",
		      "Eger",
		      "Gyor",
		      "Hodmezovasarhely",
		      "Kaposvar",
		      "Kecskemet",
		      "Miskolc",
		      "Nagykanizsa",
		      "Nyiregyhaza",
		      "Pecs",
		      "Sopron",
		      "Szeged",
		      "Szekesfehervar",
		      "Szolnok",
		      "Szombathely",
		      "Tatabanya",
		      "Veszprem",
		      "Zalaegerszeg"
		   ]
		},
		 {
		   "id": 81,
		   "EU": false,
		   "continent": "",
		   "country": "Iceland",
		   "states": [
		      "Austurland",
		      "Hofudhborgarsvaedhi",
		      "Nordhurland Eystra",
		      "Nordhurland Vestra",
		      "Sudhurland",
		      "Sudhurnes",
		      "Vestfirdhir",
		      "Vesturland"
		   ]
		},
		 {
		   "id": 82,
		   "EU": false,
		   "continent": "",
		   "country": "India",
		   "states": [
		      "Andaman and Nicobar Islands",
		      "Andhra Pradesh",
		      "Arunachal Pradesh",
		      "Assam",
		      "Bihar",
		      "Chandigarh",
		      "Chhattisgarh",
		      "Dadra and Nagar Haveli",
		      "Daman and Diu",
		      "Delhi",
		      "Goa",
		      "Gujarat",
		      "Haryana",
		      "Himachal Pradesh",
		      "Jammu and Kashmir",
		      "Jharkhand",
		      "Karnataka",
		      "Kerala",
		      "Lakshadweep",
		      "Madhya Pradesh",
		      "Maharashtra",
		      "Manipur",
		      "Meghalaya",
		      "Mizoram",
		      "Nagaland",
		      "Orissa",
		      "Pondicherry",
		      "Punjab",
		      "Rajasthan",
		      "Sikkim",
		      "Tamil Nadu",
		      "Tripura",
		      "Uttaranchal",
		      "Uttar Pradesh",
		      "West Bengal"
		   ],
		   "ports": [
		   		"Chennai",
					"Jawaharlal Nehru Port, Nhava Sheva",
					"Mundra Port",
					"Visakhapatnam Port",
					"Vizhinjam International Seaport, Trivandrum",
					"Kolkata port",
					"Haldia Port",
					"Kakinada port",
					"Hazira port",
					"Port Pipavav",
					"Krishnapatnam port",
					"Kochi"
			]
		},
		 {
		   "id": 83,
		   "EU": false,
		   "continent": "",
		   "country": "Indonesia",
		   "states": [
		      "Aceh",
		      "Bali",
		      "Banten",
		      "Bengkulu",
		      "Gorontalo",
		      "Irian Jaya Barat",
		      "Jakarta Raya",
		      "Jambi",
		      "Jawa Barat",
		      "Jawa Tengah",
		      "Jawa Timur",
		      "Kalimantan Barat",
		      "Kalimantan Selatan",
		      "Kalimantan Tengah",
		      "Kalimantan Timur",
		      "Kepulauan Bangka Belitung",
		      "Kepulauan Riau",
		      "Lampung",
		      "Maluku",
		      "Maluku Utara",
		      "Nusa Tenggara Barat",
		      "Nusa Tenggara Timur",
		      "Papua",
		      "Riau",
		      "Sulawesi Barat",
		      "Sulawesi Selatan",
		      "Sulawesi Tengah",
		      "Sulawesi Tenggara",
		      "Sulawesi Utara",
		      "Sumatera Barat",
		      "Sumatera Selatan",
		      "Sumatera Utara",
		      "Yogyakarta"
		   ],
		   "ports": [
		   		"Cirebon, Cirebon",
					"Tanjung Priok, Jakarta",
					"Belawan, Medan",
					"Makassar, Makassar",
					"Dwikora Harbour, Pontianak",
					"Trisakti Harbour, Banjarmasin",
					"Tenau Port, Kupang",
					"Tanjung Perak, Surabaya",
					"Tanjung Mas, Semarang",
					"Semayang Harbor, Balikpapan"
			]
		},
		 {
		   "id": 84,
		   "EU": false,
		   "continent": "",
		   "country": "Iran",
		   "states": [
		      "Ardabil",
		      "Azarbayjan-e Gharbi",
		      "Azarbayjan-e Sharqi",
		      "Bushehr",
		      "Chahar Mahall va Bakhtiari",
		      "Esfahan",
		      "Fars",
		      "Gilan",
		      "Golestan",
		      "Hamadan",
		      "Hormozgan",
		      "Ilam",
		      "Kerman",
		      "Kermanshah",
		      "Khorasan-e Janubi",
		      "Khorasan-e Razavi",
		      "Khorasan-e Shemali",
		      "Khuzestan",
		      "Kohgiluyeh va Buyer Ahmad",
		      "Kordestan",
		      "Lorestan",
		      "Markazi",
		      "Mazandaran",
		      "Qazvin",
		      "Qom",
		      "Semnan",
		      "Sistan va Baluchestan",
		      "Tehran",
		      "Yazd",
		      "Zanjan"
		   ],
		   "ports": [
		   		"Bandar-Abbas",
					"Bandar-kohemini"
			]
		},
		 {
		   "id": 85,
		   "EU": false,
		   "continent": "",
		   "country": "Iraq",
		   "states": [
		      "Al Anbar",
		      "Al Basrah",
		      "Al Muthanna",
		      "Al Qadisiyah",
		      "An Najaf",
		      "Arbil",
		      "As Sulaymaniyah",
		      "At Ta'mim",
		      "Babil",
		      "Baghdad",
		      "Dahuk",
		      "Dhi Qar",
		      "Diyala",
		      "Karbala'",
		      "Maysan",
		      "Ninawa",
		      "Salah ad Din",
		      "Wasit"
		   ]
		},
		 {
		   "id": 86,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Ireland",
		   "states": [
		      "Carlow",
		      "Cavan",
		      "Clare",
		      "Cork",
		      "Donegal",
		      "Dublin",
		      "Galway",
		      "Kerry",
		      "Kildare",
		      "Kilkenny",
		      "Laois",
		      "Leitrim",
		      "Limerick",
		      "Longford",
		      "Louth",
		      "Mayo",
		      "Meath",
		      "Monaghan",
		      "Offaly",
		      "Roscommon",
		      "Sligo",
		      "Tipperary",
		      "Waterford",
		      "Westmeath",
		      "Wexford",
		      "Wicklow"
		   ],
		   "ports": [
		   		"Dublin Port",
					"Cork"
			]
		},
		 {
		   "id": 87,
		   "EU": false,
		   "continent": "",
		   "country": "Israel",
		   "states": [
		      "Central",
		      "Haifa",
		      "Jerusalem",
		      "Northern",
		      "Southern",
		      "Tel Aviv"
		   ],
		   "ports": [
		   		"Haifa",
					"Ashdod",
					"Eilat"
			]
		},
		 {
		   "id": 88,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Italy",
		   "states": [
		      "Abruzzo",
		      "Basilicata",
		      "Calabria",
		      "Campania",
		      "Emilia-Romagna",
		      "Friuli-Venezia Giulia",
		      "Lazio",
		      "Liguria",
		      "Lombardia",
		      "Marche",
		      "Molise",
		      "Piemonte",
		      "Puglia",
		      "Sardegna",
		      "Sicilia",
		      "Toscana",
		      "Trentino-Alto Adige",
		      "Umbria",
		      "Valle d'Aosta",
		      "Veneto"
		   ],
		   "ports": [
		   		"Genoa",
					"Gioia Tauro",
					"La Spezia",
					"Trieste",
					"Marghera",
					"Taranto",
					"Palermo",
					"Naples",
					"Salerno"
		   ]
		},
		 {
		   "id": 89,
		   "EU": false,
		   "continent": "",
		   "country": "Jamaica",
		   "states": [
		      "Clarendon",
		      "Hanover",
		      "Kingston",
		      "Manchester",
		      "Portland",
		      "Saint Andrew",
		      "Saint Ann",
		      "Saint Catherine",
		      "Saint Elizabeth",
		      "Saint James",
		      "Saint Mary",
		      "Saint Thomas",
		      "Trelawny",
		      "Westmoreland"
		   ],
		   "ports": [
		   		"Kingston Container Terminal (Kingston), Kingston"
			]
		},
		 {
		   "id": 90,
		   "EU": false,
		   "continent": "",
		   "country": "Japan",
		   "states": [
		      "Aichi",
		      "Akita",
		      "Aomori",
		      "Chiba",
		      "Ehime",
		      "Fukui",
		      "Fukuoka",
		      "Fukushima",
		      "Gifu",
		      "Gumma",
		      "Hiroshima",
		      "Hokkaido",
		      "Hyogo",
		      "Ibaraki",
		      "Ishikawa",
		      "Iwate",
		      "Kagawa",
		      "Kagoshima",
		      "Kanagawa",
		      "Kochi",
		      "Kumamoto",
		      "Kyoto",
		      "Mie",
		      "Miyagi",
		      "Miyazaki",
		      "Nagano",
		      "Nagasaki",
		      "Nara",
		      "Niigata",
		      "Oita",
		      "Okayama",
		      "Okinawa",
		      "Osaka",
		      "Saga",
		      "Saitama",
		      "Shiga",
		      "Shimane",
		      "Shizuoka",
		      "Tochigi",
		      "Tokushima",
		      "Tokyo",
		      "Tottori",
		      "Toyama",
		      "Wakayama",
		      "Yamagata",
		      "Yamaguchi",
		      "Yamanashi"
		   ],
		   "ports": [
		   		"Nagoya",
					"Tokyo",
					"Yokohama",
					"Osaka",
					"Kobe",
					"Fukuyama",
					"Hiroshima",
					"Fukuoka"
			]
		},
		 {
		   "id": 91,
		   "EU": false,
		   "continent": "",
		   "country": "Jordan",
		   "states": [
		      "Ajlun",
		      "Al 'Aqabah",
		      "Al Balqa'",
		      "Al Karak",
		      "Al Mafraq",
		      "'Amman",
		      "At Tafilah",
		      "Az Zarqa'",
		      "Irbid",
		      "Jarash",
		      "Ma'an",
		      "Madaba"
		   ],
		   "ports": [
				   "Aqaba"
			]
		},
		 {
		   "id": 92,
		   "EU": false,
		   "continent": "",
		   "country": "Kazakhstan",
		   "states": [
		      "Almaty Oblysy",
		      "Almaty Qalasy",
		      "Aqmola Oblysy",
		      "Aqtobe Oblysy",
		      "Astana Qalasy",
		      "Atyrau Oblysy",
		      "Batys Qazaqstan Oblysy",
		      "Bayqongyr Qalasy",
		      "Mangghystau Oblysy",
		      "Ongtustik Qazaqstan Oblysy",
		      "Pavlodar Oblysy",
		      "Qaraghandy Oblysy",
		      "Qostanay Oblysy",
		      "Qyzylorda Oblysy",
		      "Shyghys Qazaqstan Oblysy",
		      "Soltustik Qazaqstan Oblysy",
		      "Zhambyl Oblysy"
		   ]
		},
		 {
		   "id": 93,
		   "EU": false,
		   "continent": "",
		   "country": "Kenya",
		   "states": [
		      "Central",
		      "Coast",
		      "Eastern",
		      "Nairobi Area",
		      "North Eastern",
		      "Nyanza",
		      "Rift Valley",
		      "Western"
		   ],
		   "ports": [
			   	"Mombasa"
			]
		},
		 {
		   "id": 94,
		   "EU": false,
		   "continent": "",
		   "country": "Kiribati",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 95,
		   "EU": false,
		   "continent": "",
		   "country": "Kuwait",
		   "states": [
		      "Al Ahmadi",
		      "Al Farwaniyah",
		      "Al Asimah",
		      "Al Jahra",
		      "Hawalli",
		      "Mubarak Al-Kabeer"
		   ]
		},
		 {
		   "id": 96,
		   "EU": false,
		   "continent": "",
		   "country": "Kyrgyzstan",
		   "states": [
		      "Batken Oblasty",
		      "Bishkek Shaary",
		      "Chuy Oblasty",
		      "Jalal-Abad Oblasty",
		      "Naryn Oblasty",
		      "Osh Oblasty",
		      "Talas Oblasty",
		      "Ysyk-Kol Oblasty"
		   ]
		},
		 {
		   "id": 97,
		   "EU": false,
		   "continent": "",
		   "country": "Laos",
		   "states": [
		      "Attapu",
		      "Bokeo",
		      "Bolikhamxai",
		      "Champasak",
		      "Houaphan",
		      "Khammouan",
		      "Louangnamtha",
		      "Louangphrabang",
		      "Oudomxai",
		      "Phongsali",
		      "Salavan",
		      "Savannakhet",
		      "Viangchan",
		      "Viangchan",
		      "Xaignabouli",
		      "Xaisomboun",
		      "Xekong",
		      "Xiangkhoang"
		   ]
		},
		 {
		   "id": 98,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Latvia",
		   "states": [
		      "Aizkraukles Rajons",
		      "Aluksnes Rajons",
		      "Balvu Rajons",
		      "Bauskas Rajons",
		      "Cesu Rajons",
		      "Daugavpils",
		      "Daugavpils Rajons",
		      "Dobeles Rajons",
		      "Gulbenes Rajons",
		      "Jekabpils Rajons",
		      "Jelgava",
		      "Jelgavas Rajons",
		      "Jurmala",
		      "Kraslavas Rajons",
		      "Kuldigas Rajons",
		      "Liepaja",
		      "Liepajas Rajons",
		      "Limbazu Rajons",
		      "Ludzas Rajons",
		      "Madonas Rajons",
		      "Ogres Rajons",
		      "Preilu Rajons",
		      "Rezekne",
		      "Rezeknes Rajons",
		      "Riga",
		      "Rigas Rajons",
		      "Saldus Rajons",
		      "Talsu Rajons",
		      "Tukuma Rajons",
		      "Valkas Rajons",
		      "Valmieras Rajons",
		      "Ventspils",
		      "Ventspils Rajons"
		   ],
		   "ports": [
		   		"Freeport of Riga"
			]
		},
		 {
		   "id": 99,
		   "EU": false,
		   "continent": "",
		   "country": "Lebanon",
		   "states": [
		      "Beyrouth",
		      "Beqaa",
		      "Liban-Nord",
		      "Liban-Sud",
		      "Mont-Liban",
		      "Nabatiye"
		   ],
		   "ports": [
			   	"Beirut"
			]
		},
		 {
		   "id": 100,
		   "EU": false,
		   "continent": "",
		   "country": "Lesotho",
		   "states": [
		      "Berea",
		      "Butha-Buthe",
		      "Leribe",
		      "Mafeteng",
		      "Maseru",
		      "Mohale's Hoek",
		      "Mokhotlong",
		      "Qacha's Nek",
		      "Quthing",
		      "Thaba-Tseka"
		   ]
		},
		 {
		   "id": 101,
		   "EU": false,
		   "continent": "",
		   "country": "Liberia",
		   "states": [
		      "Bomi",
		      "Bong",
		      "Gbarpolu",
		      "Grand Bassa",
		      "Grand Cape Mount",
		      "Grand Gedeh",
		      "Grand Kru",
		      "Lofa",
		      "Margibi",
		      "Maryland",
		      "Montserrado",
		      "Nimba",
		      "River Cess",
		      "River Gee",
		      "Sinoe"
		   ]
		},
		 {
		   "id": 102,
		   "EU": false,
		   "continent": "",
		   "country": "Libya",
		   "states": [
		      "Ajdabiya",
		      "Al 'Aziziyah",
		      "Al Fatih",
		      "Al Jabal al Akhdar",
		      "Al Jufrah",
		      "Al Khums",
		      "Al Kufrah",
		      "An Nuqat al Khams",
		      "Ash Shati'",
		      "Awbari",
		      "Az Zawiyah",
		      "Banghazi",
		      "Darnah",
		      "Ghadamis",
		      "Gharyan",
		      "Misratah",
		      "Murzuq",
		      "Sabha",
		      "Sawfajjin",
		      "Surt",
		      "Tarabulus",
		      "Tarhunah",
		      "Tubruq",
		      "Yafran",
		      "Zlitan"
		   ]
		},
		 {
		   "id": 103,
		   "EU": false,
		   "continent": "",
		   "country": "Liechtenstein",
		   "states": [
		      "Balzers",
		      "Eschen",
		      "Gamprin",
		      "Mauren",
		      "Planken",
		      "Ruggell",
		      "Schaan",
		      "Schellenberg",
		      "Triesen",
		      "Triesenberg",
		      "Vaduz"
		   ]
		},
		 {
		   "id": 104,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Lithuania",
		   "states": [
		      "Alytaus",
		      "Kauno",
		      "Klaipedos",
		      "Marijampoles",
		      "Panevezio",
		      "Siauliu",
		      "Taurages",
		      "Telsiu",
		      "Utenos",
		      "Vilniaus"
		   ],
		   "ports": [
			   	"Klaipėda"
			]
		},
		 {
		   "id": 105,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Luxembourg",
		   "states": [
		      "Diekirch",
		      "Grevenmacher",
		      "Luxembourg"
		   ]
		},
		 {
		   "id": 106,
		   "EU": false,
		   "continent": "",
		   "country": "Macedonia",
		   "states": [
		      "Aerodrom",
		      "Aracinovo",
		      "Berovo",
		      "Bitola",
		      "Bogdanci",
		      "Bogovinje",
		      "Bosilovo",
		      "Brvenica",
		      "Butel",
		      "Cair",
		      "Caska",
		      "Centar",
		      "Centar Zupa",
		      "Cesinovo",
		      "Cucer-Sandevo",
		      "Debar",
		      "Debartsa",
		      "Delcevo",
		      "Demir Hisar",
		      "Demir Kapija",
		      "Dojran",
		      "Dolneni",
		      "Drugovo",
		      "Gazi Baba",
		      "Gevgelija",
		      "Gjorce Petrov",
		      "Gostivar",
		      "Gradsko",
		      "Ilinden",
		      "Jegunovce",
		      "Karbinci",
		      "Karpos",
		      "Kavadarci",
		      "Kicevo",
		      "Kisela Voda",
		      "Kocani",
		      "Konce",
		      "Kratovo",
		      "Kriva Palanka",
		      "Krivogastani",
		      "Krusevo",
		      "Kumanovo",
		      "Lipkovo",
		      "Lozovo",
		      "Makedonska Kamenica",
		      "Makedonski Brod",
		      "Mavrovo i Rastusa",
		      "Mogila",
		      "Negotino",
		      "Novaci",
		      "Novo Selo",
		      "Ohrid",
		      "Oslomej",
		      "Pehcevo",
		      "Petrovec",
		      "Plasnica",
		      "Prilep",
		      "Probistip",
		      "Radovis",
		      "Rankovce",
		      "Resen",
		      "Rosoman",
		      "Saraj",
		      "Skopje",
		      "Sopiste",
		      "Staro Nagoricane",
		      "Stip",
		      "Struga",
		      "Strumica",
		      "Studenicani",
		      "Suto Orizari",
		      "Sveti Nikole",
		      "Tearce",
		      "Tetovo",
		      "Valandovo",
		      "Vasilevo",
		      "Veles",
		      "Vevcani",
		      "Vinica",
		      "Vranestica",
		      "Vrapciste",
		      "Zajas",
		      "Zelenikovo",
		      "Zelino",
		      "Zrnovci"
		   ]
		},
		 {
		   "id": 107,
		   "EU": false,
		   "continent": "",
		   "country": "Madagascar",
		   "states": [
		      "Antananarivo",
		      "Antsiranana",
		      "Fianarantsoa",
		      "Mahajanga",
		      "Toamasina",
		      "Toliara"
		   ],
		   "ports": [
		   		"Toamasina Autonomous Port"
			]
		},
		 {
		   "id": 108,
		   "EU": false,
		   "continent": "",
		   "country": "Malawi",
		   "states": [
		      "Balaka",
		      "Blantyre",
		      "Chikwawa",
		      "Chiradzulu",
		      "Chitipa",
		      "Dedza",
		      "Dowa",
		      "Karonga",
		      "Kasungu",
		      "Likoma",
		      "Lilongwe",
		      "Machinga",
		      "Mangochi",
		      "Mchinji",
		      "Mulanje",
		      "Mwanza",
		      "Mzimba",
		      "Ntcheu",
		      "Nkhata Bay",
		      "Nkhotakota",
		      "Nsanje",
		      "Ntchisi",
		      "Phalombe",
		      "Rumphi",
		      "Salima",
		      "Thyolo",
		      "Zomba"
		   ]
		},
		 {
		   "id": 109,
		   "EU": false,
		   "continent": "",
		   "country": "Malaysia",
		   "states": [
		      "Johor",
		      "Kedah",
		      "Kelantan",
		      "Kuala Lumpur",
		      "Labuan",
		      "Malacca",
		      "Negeri Sembilan",
		      "Pahang",
		      "Perak",
		      "Perlis",
		      "Penang",
		      "Sabah",
		      "Sarawak",
		      "Selangor",
		      "Terengganu"
		   ],
		   "ports": [
		   		"Penang Port",
					"Port Klang",
					"Tanjung Pelepas",
					"Sapangar Container Port"
			]
		},
		 {
		   "id": 110,
		   "EU": false,
		   "continent": "",
		   "country": "Maldives",
		   "states": [
		      "Alifu",
		      "Baa",
		      "Dhaalu",
		      "Faafu",
		      "Gaafu Alifu",
		      "Gaafu Dhaalu",
		      "Gnaviyani",
		      "Haa Alifu",
		      "Haa Dhaalu",
		      "Kaafu",
		      "Laamu",
		      "Lhaviyani",
		      "Maale",
		      "Meemu",
		      "Noonu",
		      "Raa",
		      "Seenu",
		      "Shaviyani",
		      "Thaa",
		      "Vaavu"
		   ]
		},
		 {
		   "id": 111,
		   "EU": false,
		   "continent": "",
		   "country": "Mali",
		   "states": [
		      "Bamako (Capital)",
		      "Gao",
		      "Kayes",
		      "Kidal",
		      "Koulikoro",
		      "Mopti",
		      "Segou",
		      "Sikasso",
		      "Tombouctou"
		   ]
		},
		 {
		   "id": 112,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Malta",
		   "states": [
		      
		   ],
		   "ports": [
		   		"Malta Freeport, Birzebbuga"
			]
		},
		 {
		   "id": 113,
		   "EU": false,
		   "continent": "",
		   "country": "Marshall Islands",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 114,
		   "EU": false,
		   "continent": "",
		   "country": "Mauritania",
		   "states": [
		      "Adrar",
		      "Assaba",
		      "Brakna",
		      "Dakhlet Nouadhibou",
		      "Gorgol",
		      "Guidimaka",
		      "Hodh Ech Chargui",
		      "Hodh El Gharbi",
		      "Inchiri",
		      "Nouakchott",
		      "Tagant",
		      "Tiris Zemmour",
		      "Trarza"
		   ]
		},
		 {
		   "id": 115,
		   "EU": false,
		   "continent": "",
		   "country": "Mauritius",
		   "states": [
		      "Agalega Islands",
		      "Black River",
		      "Cargados Carajos Shoals",
		      "Flacq",
		      "Grand Port",
		      "Moka",
		      "Pamplemousses",
		      "Plaines Wilhems",
		      "Port Louis",
		      "Riviere du Rempart",
		      "Rodrigues",
		      "Savanne"
		   ]
		},
		 {
		   "id": 116,
		   "EU": false,
		   "continent": "",
		   "country": "Mexico",
		   "states": [
		      "Aguascalientes",
		      "Baja California",
		      "Baja California Sur",
		      "Campeche",
		      "Chiapas",
		      "Chihuahua",
		      "Coahuila de Zaragoza",
		      "Colima",
		      "Distrito Federal",
		      "Durango",
		      "Guanajuato",
		      "Guerrero",
		      "Hidalgo",
		      "Jalisco",
		      "Mexico",
		      "Michoacan de Ocampo",
		      "Morelos",
		      "Nayarit",
		      "Nuevo Leon",
		      "Oaxaca",
		      "Puebla",
		      "Queretaro de Arteaga",
		      "Quintana Roo",
		      "San Luis Potosi",
		      "Sinaloa",
		      "Sonora",
		      "Tabasco",
		      "Tamaulipas",
		      "Tlaxcala",
		      "Veracruz-Llave",
		      "Yucatan",
		      "Zacatecas"
		   ],
		   "ports": [
		   		"Port of Veracruz",
					"Lázaro Cárdenas",
					"Manzanillo",
					"Altamira",
					"Ensenada",
					"Mazatlan",
					"Progreso",
					"Morelos"
			]
		},
		 {
		   "id": 117,
		   "EU": false,
		   "continent": "",
		   "country": "Micronesia",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 118,
		   "EU": false,
		   "continent": "",
		   "country": "Moldova",
		   "states": [
		      "Anenii Noi",
		      "Basarabeasca",
		      "Briceni",
		      "Cahul",
		      "Cantemir",
		      "Calarasi",
		      "Causeni",
		      "Cimislia",
		      "Criuleni",
		      "Donduseni",
		      "Drochia",
		      "Dubasari",
		      "Edinet",
		      "Falesti",
		      "Floresti",
		      "Glodeni",
		      "Hincesti",
		      "Ialoveni",
		      "Leova",
		      "Nisporeni",
		      "Ocnita",
		      "Orhei",
		      "Rezina",
		      "Riscani",
		      "Singerei",
		      "Soldanesti",
		      "Soroca",
		      "Stefan-Voda",
		      "Straseni",
		      "Taraclia",
		      "Telenesti",
		      "Ungheni",
		      "Balti",
		      "Bender",
		      "Chisinau",
		      "Gagauzia",
		      "Stinga Nistrului"
		   ]
		},
		 {
		   "id": 119,
		   "EU": false,
		   "continent": "",
		   "country": "Mongolia",
		   "states": [
		      "Arhangay",
		      "Bayanhongor",
		      "Bayan-Olgiy",
		      "Bulgan",
		      "Darhan Uul",
		      "Dornod",
		      "Dornogovi",
		      "Dundgovi",
		      "Dzavhan",
		      "Govi-Altay",
		      "Govi-Sumber",
		      "Hentiy",
		      "Hovd",
		      "Hovsgol",
		      "Omnogovi",
		      "Orhon",
		      "Ovorhangay",
		      "Selenge",
		      "Suhbaatar",
		      "Tov",
		      "Ulaanbaatar",
		      "Uvs"
		   ]
		},
		 {
		   "id": 120,
		   "EU": false,
		   "continent": "",
		   "country": "Morocco",
		   "states": [
		      "Agadir",
		      "Al Hoceima",
		      "Azilal",
		      "Beni Mellal",
		      "Ben Slimane",
		      "Boulemane",
		      "Casablanca",
		      "Chaouen",
		      "El Jadida",
		      "El Kelaa des Sraghna",
		      "Er Rachidia",
		      "Essaouira",
		      "Fes",
		      "Figuig",
		      "Guelmim",
		      "Ifrane",
		      "Kenitra",
		      "Khemisset",
		      "Khenifra",
		      "Khouribga",
		      "Laayoune",
		      "Larache",
		      "Marrakech",
		      "Meknes",
		      "Nador",
		      "Ouarzazate",
		      "Oujda",
		      "Rabat-Sale",
		      "Safi",
		      "Settat",
		      "Sidi Kacem",
		      "Tangier",
		      "Tan-Tan",
		      "Taounate",
		      "Taroudannt",
		      "Tata",
		      "Taza",
		      "Tetouan",
		      "Tiznit"
		   ],
		   "ports": [
		   		"Agadir Port",
					"Casablanca Port",
					"Tanger-Med"
			]
		},
		 {
		   "id": 121,
		   "EU": false,
		   "continent": "",
		   "country": "Monaco",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 122,
		   "EU": false,
		   "continent": "",
		   "country": "Mozambique",
		   "states": [
		      "Cabo Delgado",
		      "Gaza",
		      "Inhambane",
		      "Manica",
		      "Maputo",
		      "Cidade de Maputo",
		      "Nampula",
		      "Niassa",
		      "Sofala",
		      "Tete",
		      "Zambezia"
		   ],
		   "ports": [
		   		"Maputo",
					"Beira"
			]
		},
		 {
		   "id": 123,
		   "EU": false,
		   "continent": "",
		   "country": "Namibia",
		   "states": [
		      "Caprivi",
		      "Erongo",
		      "Hardap",
		      "Karas",
		      "Khomas",
		      "Kunene",
		      "Ohangwena",
		      "Okavango",
		      "Omaheke",
		      "Omusati",
		      "Oshana",
		      "Oshikoto",
		      "Otjozondjupa"
		   ]
		},
		 {
		   "id": 124,
		   "EU": false,
		   "continent": "",
		   "country": "Nauru",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 125,
		   "EU": false,
		   "continent": "",
		   "country": "Nepal",
		   "states": [
		      "Bagmati",
		      "Bheri",
		      "Dhawalagiri",
		      "Gandaki",
		      "Janakpur",
		      "Karnali",
		      "Kosi",
		      "Lumbini",
		      "Mahakali",
		      "Mechi",
		      "Narayani",
		      "Rapti",
		      "Sagarmatha",
		      "Seti"
		   ]
		},
		 {
		   "id": 126,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Netherlands",
		   "states": [
		      "Drenthe",
		      "Flevoland",
		      "Friesland",
		      "Gelderland",
		      "Groningen",
		      "Limburg",
		      "Noord-Brabant",
		      "Noord-Holland",
		      "Overijssel",
		      "Utrecht",
		      "Zeeland",
		      "Zuid-Holland"
		   ],
		   "ports": [
		   		"Amsterdam",
					"Rotterdam"
			]
		},
		 {
		   "id": 127,
		   "EU": false,
		   "continent": "",
		   "country": "New Zealand",
		   "states": [
		      "Auckland",
		      "Bay of Plenty",
		      "Canterbury",
		      "Chatham Islands",
		      "Gisborne",
		      "Hawke's Bay",
		      "Manawatu-Wanganui",
		      "Marlborough",
		      "Nelson",
		      "Northland",
		      "Otago",
		      "Southland",
		      "Taranaki",
		      "Tasman",
		      "Waikato",
		      "Wellington",
		      "West Coast"
		   ],
		   "ports": [
		   		"Ports of Auckland",
					"CentrePort Wellington, Wellington Harbour",
					"Port Nelson",
					"Lyttleton Port, Lyttleton",
					"Port Chalmers, Dunedin",
					"Tauranga",
					"Napier Port"
			]
		},
		 {
		   "id": 128,
		   "EU": false,
		   "continent": "",
		   "country": "Nicaragua",
		   "states": [
		      "Atlantico Norte",
		      "Atlantico Sur",
		      "Boaco",
		      "Carazo",
		      "Chinandega",
		      "Chontales",
		      "Esteli",
		      "Granada",
		      "Jinotega",
		      "Leon",
		      "Madriz",
		      "Managua",
		      "Masaya",
		      "Matagalpa",
		      "Nueva Segovia",
		      "Rio San Juan",
		      "Rivas"
		   ]
		},
		 {
		   "id": 129,
		   "EU": false,
		   "continent": "",
		   "country": "Niger",
		   "states": [
		      "Agadez",
		      "Diffa",
		      "Dosso",
		      "Maradi",
		      "Niamey",
		      "Tahoua",
		      "Tillaberi",
		      "Zinder"
		   ]
		},
		 {
		   "id": 130,
		   "EU": false,
		   "continent": "",
		   "country": "Nigeria",
		   "states": [
		      "Abia",
		      "Abuja Federal Capital",
		      "Adamawa",
		      "Akwa Ibom",
		      "Anambra",
		      "Bauchi",
		      "Bayelsa",
		      "Benue",
		      "Borno",
		      "Cross River",
		      "Delta",
		      "Ebonyi",
		      "Edo",
		      "Ekiti",
		      "Enugu",
		      "Gombe",
		      "Imo",
		      "Jigawa",
		      "Kaduna",
		      "Kano",
		      "Katsina",
		      "Kebbi",
		      "Kogi",
		      "Kwara",
		      "Lagos",
		      "Nassarawa",
		      "Niger",
		      "Ogun",
		      "Ondo",
		      "Osun",
		      "Oyo",
		      "Plateau",
		      "Rivers",
		      "Sokoto",
		      "Taraba",
		      "Yobe",
		      "Zamfara"
		   ],
		   "ports": [
		   		"Apapa, Lagos",
					"Tincan, Lagos"
			]
		},
		 {
		   "id": 131,
		   "EU": false,
		   "continent": "",
		   "country": "North Korea",
		   "states": [
		      "Chagang",
		      "North Hamgyong",
		      "South Hamgyong",
		      "North Hwanghae",
		      "South Hwanghae",
		      "Kangwon",
		      "North P'yongan",
		      "South P'yongan",
		      "Yanggang",
		      "Kaesong",
		      "Najin",
		      "Namp'o",
		      "Pyongyang"
		   ]
		},
		 {
		   "id": 132,
		   "EU": false,
		   "continent": "",
		   "country": "Norway",
		   "states": [
		      "Akershus",
		      "Aust-Agder",
		      "Buskerud",
		      "Finnmark",
		      "Hedmark",
		      "Hordaland",
		      "More og Romsdal",
		      "Nordland",
		      "Nord-Trondelag",
		      "Oppland",
		      "Oslo",
		      "Ostfold",
		      "Rogaland",
		      "Sogn og Fjordane",
		      "Sor-Trondelag",
		      "Telemark",
		      "Troms",
		      "Vest-Agder",
		      "Vestfold"
		   ],
		   "ports": [
		   		"Bergen",
					"Oslo"
			]
		},
		 {
		   "id": 133,
		   "EU": false,
		   "continent": "",
		   "country": "Oman",
		   "states": [
		      "Ad Dakhiliyah",
		      "Al Batinah",
		      "Al Wusta",
		      "Ash Sharqiyah",
		      "Az Zahirah",
		      "Masqat",
		      "Musandam",
		      "Dhofar"
		   ],
		   "ports": [
		   		"Salalah",
					"Muscat"
			]
		},
		 {
		   "id": 134,
		   "EU": false,
		   "continent": "",
		   "country": "Pakistan",
		   "states": [
		      "Balochistan",
		      "North-West Frontier Province",
		      "Punjab",
		      "Sindh",
		      "Islamabad Capital Territory",
		      "Federally Administered Tribal Areas"
		   ],
		   "ports": [
		   		"Karachi Port",	
					"Port Qasim",	
					"Gwader Port",	
					"Qasim International container terminal",	
					"Karachi International container terminal",	
					"Pakistan International Container Terminal",	
					"South Asia Pakistan terminals",	
					"Qasim Freight Station",	
					"PEARL TCDT (First Transit Cargo Container Terminal in Karachi Pakistan)",	
					"PAKISTAN INTERNATIONAL BULK TERMINAL LIMITED"	
			]
		},
		 {
		   "id": 135,
		   "EU": false,
		   "continent": "",
		   "country": "Panama",
		   "states": [
		      "Bocas del Toro",
		      "Chiriqui",
		      "Cocle",
		      "Colon",
		      "Darien",
		      "Herrera",
		      "Los Santos",
		      "Panama",
		      "San Blas",
		      "Veraguas"
		   ],
		   "ports": [
		   		"Balboa",
					"Cristóbal, Colón",
					"Manzanillo"
			]
		},
		 {
		   "id": 136,
		   "EU": false,
		   "continent": "",
		   "country": "Papua New Guinea",
		   "states": [
		      "Bougainville",
		      "Central",
		      "Chimbu",
		      "Eastern Highlands",
		      "East New Britain",
		      "East Sepik",
		      "Enga",
		      "Gulf",
		      "Madang",
		      "Manus",
		      "Milne Bay",
		      "Morobe",
		      "National Capital",
		      "New Ireland",
		      "Northern",
		      "Sandaun",
		      "Southern Highlands",
		      "Western",
		      "Western Highlands",
		      "West New Britain"
		   ]
		},
		 {
		   "id": 137,
		   "EU": false,
		   "continent": "",
		   "country": "Paraguay",
		   "states": [
		      "Alto Paraguay",
		      "Alto Parana",
		      "Amambay",
		      "Asuncion",
		      "Boqueron",
		      "Caaguazu",
		      "Caazapa",
		      "Canindeyu",
		      "Central",
		      "Concepcion",
		      "Cordillera",
		      "Guaira",
		      "Itapua",
		      "Misiones",
		      "Neembucu",
		      "Paraguari",
		      "Presidente Hayes",
		      "San Pedro"
		   ]
		},
		 {
		   "id": 138,
		   "EU": false,
		   "continent": "",
		   "country": "Peru",
		   "states": [
		      "Amazonas",
		      "Ancash",
		      "Apurimac",
		      "Arequipa",
		      "Ayacucho",
		      "Cajamarca",
		      "Callao",
		      "Cusco",
		      "Huancavelica",
		      "Huanuco",
		      "Ica",
		      "Junin",
		      "La Libertad",
		      "Lambayeque",
		      "Lima",
		      "Loreto",
		      "Madre de Dios",
		      "Moquegua",
		      "Pasco",
		      "Piura",
		      "Puno",
		      "San Martin",
		      "Tacna",
		      "Tumbes",
		      "Ucayali"
		   ],
		   "ports": [
		   		"Callao, Lima",
					"Ilo",
					"Matarani"
			]
		},
		 {
		   "id": 139,
		   "EU": false,
		   "continent": "",
		   "country": "Philippines",
		   "states": [
		      "Abra",
		      "Agusan del Norte",
		      "Agusan del Sur",
		      "Aklan",
		      "Albay",
		      "Antique",
		      "Apayao",
		      "Aurora",
		      "Basilan",
		      "Bataan",
		      "Batanes",
		      "Batangas",
		      "Biliran",
		      "Benguet",
		      "Bohol",
		      "Bukidnon",
		      "Bulacan",
		      "Cagayan",
		      "Camarines Norte",
		      "Camarines Sur",
		      "Camiguin",
		      "Capiz",
		      "Catanduanes",
		      "Cavite",
		      "Cebu",
		      "Compostela",
		      "Davao del Norte",
		      "Davao del Sur",
		      "Davao Oriental",
		      "Eastern Samar",
		      "Guimaras",
		      "Ifugao",
		      "Ilocos Norte",
		      "Ilocos Sur",
		      "Iloilo",
		      "Isabela",
		      "Kalinga",
		      "Laguna",
		      "Lanao del Norte",
		      "Lanao del Sur",
		      "La Union",
		      "Leyte",
		      "Maguindanao",
		      "Marinduque",
		      "Masbate",
		      "Mindoro Occidental",
		      "Mindoro Oriental",
		      "Misamis Occidental",
		      "Misamis Oriental",
		      "Mountain Province",
		      "Negros Occidental",
		      "Negros Oriental",
		      "North Cotabato",
		      "Northern Samar",
		      "Nueva Ecija",
		      "Nueva Vizcaya",
		      "Palawan",
		      "Pampanga",
		      "Pangasinan",
		      "Quezon",
		      "Quirino",
		      "Rizal",
		      "Romblon",
		      "Samar",
		      "Sarangani",
		      "Siquijor",
		      "Sorsogon",
		      "South Cotabato",
		      "Southern Leyte",
		      "Sultan Kudarat",
		      "Sulu",
		      "Surigao del Norte",
		      "Surigao del Sur",
		      "Tarlac",
		      "Tawi-Tawi",
		      "Zambales",
		      "Zamboanga del Norte",
		      "Zamboanga del Sur",
		      "Zamboanga Sibugay"
		   ],
		   "ports": [
				  "Manila"
			]
		},
		 {
		   "id": 140,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Poland",
		   "states": [
		      "Greater Poland (Wielkopolskie)",
		      "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
		      "Lesser Poland (Malopolskie)",
		      "Lodz (Lodzkie)",
		      "Lower Silesian (Dolnoslaskie)",
		      "Lublin (Lubelskie)",
		      "Lubusz (Lubuskie)",
		      "Masovian (Mazowieckie)",
		      "Opole (Opolskie)",
		      "Podlasie (Podlaskie)",
		      "Pomeranian (Pomorskie)",
		      "Silesian (Slaskie)",
		      "Subcarpathian (Podkarpackie)",
		      "Swietokrzyskie (Swietokrzyskie)",
		      "Warmian-Masurian (Warminsko-Mazurskie)",
		      "West Pomeranian (Zachodniopomorskie)"
		   ],
		   "ports": [
		   		"DCT Gdańsk",
					"Gdynia"
			]
		},
		 {
		   "id": 141,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Portugal",
		   "states": [
		      "Aveiro",
		      "Acores",
		      "Beja",
		      "Braga",
		      "Braganca",
		      "Castelo Branco",
		      "Coimbra",
		      "Evora",
		      "Faro",
		      "Guarda",
		      "Leiria",
		      "Lisboa",
		      "Madeira",
		      "Portalegre",
		      "Porto",
		      "Santarem",
		      "Setubal",
		      "Viana do Castelo",
		      "Vila Real",
		      "Viseu"
		   ],
		   "ports": [
		   		"Setúbal, Setubal",
					"Alcântara, Lisbon",
					"Leixões, Porto",
					"Sines, Sines"
			]
		},
		 {
		   "id": 142,
		   "EU": false,
		   "continent": "",
		   "country": "Qatar",
		   "states": [
		      "Ad Dawhah",
		      "Al Ghuwayriyah",
		      "Al Jumayliyah",
		      "Al Khawr",
		      "Al Wakrah",
		      "Ar Rayyan",
		      "Jarayan al Batinah",
		      "Madinat ash Shamal",
		      "Umm Sa'id",
		      "Umm Salal"
		   ],
		   "ports": [
			   	"Hamad"
			]
		},
		 {
		   "id": 143,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Romania",
		   "states": [
		      "Alba",
		      "Arad",
		      "Arges",
		      "Bacau",
		      "Bihor",
		      "Bistrita-Nasaud",
		      "Botosani",
		      "Braila",
		      "Brasov",
		      "Bucuresti",
		      "Buzau",
		      "Calarasi",
		      "Caras-Severin",
		      "Cluj",
		      "Constanta",
		      "Covasna",
		      "Dimbovita",
		      "Dolj",
		      "Galati",
		      "Gorj",
		      "Giurgiu",
		      "Harghita",
		      "Hunedoara",
		      "Ialomita",
		      "Iasi",
		      "Ilfov",
		      "Maramures",
		      "Mehedinti",
		      "Mures",
		      "Neamt",
		      "Olt",
		      "Prahova",
		      "Salaj",
		      "Satu Mare",
		      "Sibiu",
		      "Suceava",
		      "Teleorman",
		      "Timis",
		      "Tulcea",
		      "Vaslui",
		      "Vilcea",
		      "Vrancea"
		   ],
		   "ports": [
		   		"Constanţa"
			]
		},
		 {
		   "id": 144,
		   "EU": false,
		   "continent": "",
		   "country": "Russia",
		   "states": [
		      "Amur",
		      "Arkhangel'sk",
		      "Astrakhan'",
		      "Belgorod",
		      "Bryansk",
		      "Chelyabinsk",
		      "Chita",
		      "Irkutsk",
		      "Ivanovo",
		      "Kaliningrad",
		      "Kaluga",
		      "Kamchatka",
		      "Kemerovo",
		      "Kirov",
		      "Kostroma",
		      "Kurgan",
		      "Kursk",
		      "Leningrad",
		      "Lipetsk",
		      "Magadan",
		      "Moscow",
		      "Murmansk",
		      "Nizhniy Novgorod",
		      "Novgorod",
		      "Novosibirsk",
		      "Omsk",
		      "Orenburg",
		      "Orel",
		      "Penza",
		      "Perm'",
		      "Pskov",
		      "Rostov",
		      "Ryazan'",
		      "Sakhalin",
		      "Samara",
		      "Saratov",
		      "Smolensk",
		      "Sverdlovsk",
		      "Tambov",
		      "Tomsk",
		      "Tula",
		      "Tver'",
		      "Tyumen'",
		      "Ul'yanovsk",
		      "Vladimir",
		      "Volgograd",
		      "Vologda",
		      "Voronezh",
		      "Yaroslavl'",
		      "Adygeya",
		      "Altay",
		      "Bashkortostan",
		      "Buryatiya",
		      "Chechnya",
		      "Chuvashiya",
		      "Dagestan",
		      "Ingushetiya",
		      "Kabardino-Balkariya",
		      "Kalmykiya",
		      "Karachayevo-Cherkesiya",
		      "Kareliya",
		      "Khakasiya",
		      "Komi",
		      "Mariy-El",
		      "Mordoviya",
		      "Sakha",
		      "North Ossetia",
		      "Tatarstan",
		      "Tyva",
		      "Udmurtiya",
		      "Aga Buryat",
		      "Chukotka",
		      "Evenk",
		      "Khanty-Mansi",
		      "Komi-Permyak",
		      "Koryak",
		      "Nenets",
		      "Taymyr",
		      "Ust'-Orda Buryat",
		      "Yamalo-Nenets",
		      "Altay",
		      "Khabarovsk",
		      "Krasnodar",
		      "Krasnoyarsk",
		      "Primorskiy",
		      "Stavropol'",
		      "Moscow",
		      "St. Petersburg",
		      "Yevrey"
		   ],
		   "ports": [
		   		"Vostochny Port, Vrangel",
					"Saint Petersburg",
					"Kaliningrad Sea Commercial Port",
					"Novorossiysk",
					"Vladivostok"
			]
		},
		 {
		   "id": 145,
		   "EU": false,
		   "continent": "",
		   "country": "Rwanda",
		   "states": [
		      "Butare",
		      "Byumba",
		      "Cyangugu",
		      "Gikongoro",
		      "Gisenyi",
		      "Gitarama",
		      "Kibungo",
		      "Kibuye",
		      "Kigali Rurale",
		      "Kigali-ville",
		      "Umutara",
		      "Ruhengeri"
		   ]
		},
		 {
		   "id": 146,
		   "EU": false,
		   "continent": "",
		   "country": "Samoa",
		   "states": [
		      "A'ana",
		      "Aiga-i-le-Tai",
		      "Atua",
		      "Fa'asaleleaga",
		      "Gaga'emauga",
		      "Gagaifomauga",
		      "Palauli",
		      "Satupa'itea",
		      "Tuamasaga",
		      "Va'a-o-Fonoti",
		      "Vaisigano"
		   ]
		},
		 {
		   "id": 147,
		   "EU": false,
		   "continent": "",
		   "country": "San Marino",
		   "states": [
		      "Acquaviva",
		      "Borgo Maggiore",
		      "Chiesanuova",
		      "Domagnano",
		      "Faetano",
		      "Fiorentino",
		      "Montegiardino",
		      "San Marino Citta",
		      "Serravalle"
		   ]
		},
		 {
		   "id": 148,
		   "EU": false,
		   "continent": "",
		   "country": "Sao Tome",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 149,
		   "EU": false,
		   "continent": "",
		   "country": "Saudi Arabia",
		   "states": [
		      "Al Bahah",
		      "Al Hudud ash Shamaliyah",
		      "Al Jawf",
		      "Al Madinah",
		      "Al Qasim",
		      "Ar Riyad",
		      "Ash Sharqiyah",
		      "'Asir",
		      "Ha'il",
		      "Jizan",
		      "Makkah",
		      "Najran",
		      "Tabuk"
		   ],
		   "ports": [
		   		"Jeddah Seaport",
					"King Abdul Aziz Sea Port, Dammam",
					"Jubail"
			]
		},
		 {
		   "id": 150,
		   "EU": false,
		   "continent": "",
		   "country": "Senegal",
		   "states": [
		      "Dakar",
		      "Diourbel",
		      "Fatick",
		      "Kaolack",
		      "Kolda",
		      "Louga",
		      "Matam",
		      "Saint-Louis",
		      "Tambacounda",
		      "Thies",
		      "Ziguinchor"
		   ],
		   "ports": [
		   		"Dakar"
			]
		},
		 {
		   "id": 151,
		   "EU": false,
		   "continent": "",
		   "country": "Serbia and Montenegro",
		   "states": [
		      "Kosovo",
		      "Montenegro",
		      "Serbia",
		      "Vojvodina"
		   ],
		   "ports": [
		   		"Bar"
			]
		},
		 {
		   "id": 152,
		   "EU": false,
		   "continent": "",
		   "country": "Seychelles",
		   "states": [
		      "Anse aux Pins",
		      "Anse Boileau",
		      "Anse Etoile",
		      "Anse Louis",
		      "Anse Royale",
		      "Baie Lazare",
		      "Baie Sainte Anne",
		      "Beau Vallon",
		      "Bel Air",
		      "Bel Ombre",
		      "Cascade",
		      "Glacis",
		      "Grand' Anse",
		      "Grand' Anse",
		      "La Digue",
		      "La Riviere Anglaise",
		      "Mont Buxton",
		      "Mont Fleuri",
		      "Plaisance",
		      "Pointe La Rue",
		      "Port Glaud",
		      "Saint Louis",
		      "Takamaka"
		   ]
		},
		 {
		   "id": 153,
		   "EU": false,
		   "continent": "",
		   "country": "Sierra Leone",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 154,
		   "EU": false,
		   "continent": "",
		   "country": "Singapore",
		   "states": [
		      
		   ],
		   "ports": [
		   		"Singapore"
			]
		},
		 {
		   "id": 155,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Slovakia",
		   "states": [
		      "Banskobystricky",
		      "Bratislavsky",
		      "Kosicky",
		      "Nitriansky",
		      "Presovsky",
		      "Trenciansky",
		      "Trnavsky",
		      "Zilinsky"
		   ]
		},
		 {
		   "id": 156,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Slovenia",
		   "states": [
		      "Ajdovscina",
		      "Beltinci",
		      "Benedikt",
		      "Bistrica ob Sotli",
		      "Bled",
		      "Bloke",
		      "Bohinj",
		      "Borovnica",
		      "Bovec",
		      "Braslovce",
		      "Brda",
		      "Brezice",
		      "Brezovica",
		      "Cankova",
		      "Celje",
		      "Cerklje na Gorenjskem",
		      "Cerknica",
		      "Cerkno",
		      "Cerkvenjak",
		      "Crensovci",
		      "Crna na Koroskem",
		      "Crnomelj",
		      "Destrnik",
		      "Divaca",
		      "Dobje",
		      "Dobrepolje",
		      "Dobrna",
		      "Dobrova-Horjul-Polhov Gradec",
		      "Dobrovnik-Dobronak",
		      "Dolenjske Toplice",
		      "Dol pri Ljubljani",
		      "Domzale",
		      "Dornava",
		      "Dravograd",
		      "Duplek",
		      "Gorenja Vas-Poljane",
		      "Gorisnica",
		      "Gornja Radgona",
		      "Gornji Grad",
		      "Gornji Petrovci",
		      "Grad",
		      "Grosuplje",
		      "Hajdina",
		      "Hoce-Slivnica",
		      "Hodos-Hodos",
		      "Horjul",
		      "Hrastnik",
		      "Hrpelje-Kozina",
		      "Idrija",
		      "Ig",
		      "Ilirska Bistrica",
		      "Ivancna Gorica",
		      "Izola-Isola",
		      "Jesenice",
		      "Jezersko",
		      "Jursinci",
		      "Kamnik",
		      "Kanal",
		      "Kidricevo",
		      "Kobarid",
		      "Kobilje",
		      "Kocevje",
		      "Komen",
		      "Komenda",
		      "Koper-Capodistria",
		      "Kostel",
		      "Kozje",
		      "Kranj",
		      "Kranjska Gora",
		      "Krizevci",
		      "Krsko",
		      "Kungota",
		      "Kuzma",
		      "Lasko",
		      "Lenart",
		      "Lendava-Lendva",
		      "Litija",
		      "Ljubljana",
		      "Ljubno",
		      "Ljutomer",
		      "Logatec",
		      "Loska Dolina",
		      "Loski Potok",
		      "Lovrenc na Pohorju",
		      "Luce",
		      "Lukovica",
		      "Majsperk",
		      "Maribor",
		      "Markovci",
		      "Medvode",
		      "Menges",
		      "Metlika",
		      "Mezica",
		      "Miklavz na Dravskem Polju",
		      "Miren-Kostanjevica",
		      "Mirna Pec",
		      "Mislinja",
		      "Moravce",
		      "Moravske Toplice",
		      "Mozirje",
		      "Murska Sobota",
		      "Muta",
		      "Naklo",
		      "Nazarje",
		      "Nova Gorica",
		      "Novo Mesto",
		      "Odranci",
		      "Oplotnica",
		      "Ormoz",
		      "Osilnica",
		      "Pesnica",
		      "Piran-Pirano",
		      "Pivka",
		      "Podcetrtek",
		      "Podlehnik",
		      "Podvelka",
		      "Polzela",
		      "Postojna",
		      "Prebold",
		      "Preddvor",
		      "Prevalje",
		      "Ptuj",
		      "Puconci",
		      "Race-Fram",
		      "Radece",
		      "Radenci",
		      "Radlje ob Dravi",
		      "Radovljica",
		      "Ravne na Koroskem",
		      "Razkrizje",
		      "Ribnica",
		      "Ribnica na Pohorju",
		      "Rogasovci",
		      "Rogaska Slatina",
		      "Rogatec",
		      "Ruse",
		      "Salovci",
		      "Selnica ob Dravi",
		      "Semic",
		      "Sempeter-Vrtojba",
		      "Sencur",
		      "Sentilj",
		      "Sentjernej",
		      "Sentjur pri Celju",
		      "Sevnica",
		      "Sezana",
		      "Skocjan",
		      "Skofja Loka",
		      "Skofljica",
		      "Slovenj Gradec",
		      "Slovenska Bistrica",
		      "Slovenske Konjice",
		      "Smarje pri Jelsah",
		      "Smartno ob Paki",
		      "Smartno pri Litiji",
		      "Sodrazica",
		      "Solcava",
		      "Sostanj",
		      "Starse",
		      "Store",
		      "Sveta Ana",
		      "Sveti Andraz v Slovenskih Goricah",
		      "Sveti Jurij",
		      "Tabor",
		      "Tisina",
		      "Tolmin",
		      "Trbovlje",
		      "Trebnje",
		      "Trnovska Vas",
		      "Trzic",
		      "Trzin",
		      "Turnisce",
		      "Velenje",
		      "Velika Polana",
		      "Velike Lasce",
		      "Verzej",
		      "Videm",
		      "Vipava",
		      "Vitanje",
		      "Vodice",
		      "Vojnik",
		      "Vransko",
		      "Vrhnika",
		      "Vuzenica",
		      "Zagorje ob Savi",
		      "Zalec",
		      "Zavrc",
		      "Zelezniki",
		      "Zetale",
		      "Ziri",
		      "Zirovnica",
		      "Zuzemberk",
		      "Zrece"
		   ],
		   "ports": [
		   		"Koper"
			]
		},
		 {
		   "id": 157,
		   "EU": false,
		   "continent": "",
		   "country": "Solomon Islands",
		   "states": [
		      "Central",
		      "Choiseul",
		      "Guadalcanal",
		      "Honiara",
		      "Isabel",
		      "Makira",
		      "Malaita",
		      "Rennell and Bellona",
		      "Temotu",
		      "Western"
		   ]
		},
		 {
		   "id": 158,
		   "EU": false,
		   "continent": "",
		   "country": "Somalia",
		   "states": [
		      "Awdal",
		      "Bakool",
		      "Banaadir",
		      "Bari",
		      "Bay",
		      "Galguduud",
		      "Gedo",
		      "Hiiraan",
		      "Jubbada Dhexe",
		      "Jubbada Hoose",
		      "Mudug",
		      "Nugaal",
		      "Sanaag",
		      "Shabeellaha Dhexe",
		      "Shabeellaha Hoose",
		      "Sool",
		      "Togdheer",
		      "Woqooyi Galbeed"
		   ]
		},
		 {
		   "id": 159,
		   "EU": false,
		   "continent": "",
		   "country": "South Africa",
		   "states": [
		      "Eastern Cape",
		      "Free State",
		      "Gauteng",
		      "KwaZulu-Natal",
		      "Limpopo",
		      "Mpumalanga",
		      "North-West",
		      "Northern Cape",
		      "Western Cape"
		   ],
		   "ports": [
		   		"Durban",
					"East London",
					"Port Elizabeth",
					"Cape Town",
					"Richards Bay",
					"Ngqura"
			]
		},
		 {
		   "id": 160,
		   "EU": false,
		   "continent": "",
		   "country": "South Korea",
		   "states": [
		      "Seoul",
		      "Busan City",
		      "Daegu City",
		      "Incheon City",
		      "Gwangju City",
		      "Daejeon City",
		      "Ulsan",
		      "Gyeonggi Province",
		      "Gangwon Province",
		      "North Chungcheong Province",
		      "South Chungcheong Province",
		      "North Jeolla Province",
		      "South Jeolla Province",
		      "North Gyeongsang Province",
		      "South Gyeongsang Province",
		      "Jeju"
		   ],
		   "ports": [
		   		"Busan",
					"Incheon"
			]
		},
		 {
		   "id": 161,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Spain",
		   "states": [
		      "Andalucia",
		      "Aragon",
		      "Asturias",
		      "Baleares",
		      "Ceuta",
		      "Canarias",
		      "Cantabria",
		      "Castilla-La Mancha",
		      "Castilla y Leon",
		      "Cataluna",
		      "Comunidad Valenciana",
		      "Extremadura",
		      "Galicia",
		      "La Rioja",
		      "Madrid",
		      "Melilla",
		      "Murcia",
		      "Navarra",
		      "Pais Vasco"
		   ],
		   "ports": [
		   		"Algeciras",
					"Barcelona",
					"Bilbao",
					"Las Palmas",
					"Tarragona",
					"Valencia",
					"Vigo"
			]
		},
		 {
		   "id": 162,
		   "EU": false,
		   "continent": "",
		   "country": "Sri Lanka",
		   "states": [
		      "Central",
		      "North Central",
		      "North Eastern",
		      "North Western",
		      "Sabaragamuwa",
		      "Southern",
		      "Uva",
		      "Western"
		   ],
		   "ports": [
			   	"Colombo"
			]
		},
		 {
		   "id": 163,
		   "EU": false,
		   "continent": "",
		   "country": "Sudan",
		   "states": [
		      "A'ali an Nil",
		      "Al Bahr al Ahmar",
		      "Al Buhayrat",
		      "Al Jazirah",
		      "Al Khartum",
		      "Al Qadarif",
		      "Al Wahdah",
		      "An Nil al Abyad",
		      "An Nil al Azraq",
		      "Ash Shamaliyah",
		      "Bahr al Jabal",
		      "Gharb al Istiwa'iyah",
		      "Gharb Bahr al Ghazal",
		      "Gharb Darfur",
		      "Gharb Kurdufan",
		      "Janub Darfur",
		      "Janub Kurdufan",
		      "Junqali",
		      "Kassala",
		      "Nahr an Nil",
		      "Shamal Bahr al Ghazal",
		      "Shamal Darfur",
		      "Shamal Kurdufan",
		      "Sharq al Istiwa'iyah",
		      "Sinnar",
		      "Warab"
		   ],
		   "ports": [
		   		"Port Sudan"
			]
		},
		 {
		   "id": 164,
		   "EU": false,
		   "continent": "",
		   "country": "Suriname",
		   "states": [
		      "Brokopondo",
		      "Commewijne",
		      "Coronie",
		      "Marowijne",
		      "Nickerie",
		      "Para",
		      "Paramaribo",
		      "Saramacca",
		      "Sipaliwini",
		      "Wanica"
		   ],
		   "ports": [
		   		"Paramaribo"
			]
		},
		 {
		   "id": 165,
		   "EU": true,
		   "continent": "Europe",
		   "country": "Sweden",
		   "states": [
		      "Blekinge",
		      "Dalarnas",
		      "Gavleborgs",
		      "Gotlands",
		      "Hallands",
		      "Jamtlands",
		      "Jonkopings",
		      "Kalmar",
		      "Kronobergs",
		      "Norrbottens",
		      "Orebro",
		      "Ostergotlands",
		      "Skane",
		      "Sodermanlands",
		      "Stockholms",
		      "Uppsala",
		      "Varmlands",
		      "Vasterbottens",
		      "Vasternorrlands",
		      "Vastmanlands",
		      "Vastra Gotalands"
		   ],
		   "ports": [
		   		"Ports of Stockholm",
					"Gothenburg",
					"Copenhagen Malmö Port"
			]
		},
		 {
		   "id": 166,
		   "EU": false,
		   "continent": "",
		   "country": "Switzerland",
		   "states": [
		      "Aargau",
		      "Appenzell Ausser-Rhoden",
		      "Appenzell Inner-Rhoden",
		      "Basel-Landschaft",
		      "Basel-Stadt",
		      "Bern",
		      "Fribourg",
		      "Geneve",
		      "Glarus",
		      "Graubunden",
		      "Jura",
		      "Luzern",
		      "Neuchatel",
		      "Nidwalden",
		      "Obwalden",
		      "Sankt Gallen",
		      "Schaffhausen",
		      "Schwyz",
		      "Solothurn",
		      "Thurgau",
		      "Ticino",
		      "Uri",
		      "Valais",
		      "Vaud",
		      "Zug",
		      "Zurich"
		   ]
		},
		 {
		   "id": 167,
		   "EU": false,
		   "continent": "",
		   "country": "Syria",
		   "states": [
		      "Al Hasakah",
		      "Al Ladhiqiyah",
		      "Al Qunaytirah",
		      "Ar Raqqah",
		      "As Suwayda'",
		      "Dar'a",
		      "Dayr az Zawr",
		      "Dimashq",
		      "Halab",
		      "Hamah",
		      "Hims",
		      "Idlib",
		      "Rif Dimashq",
		      "Tartus"
		   ],
		   "ports": [
					"Latakia",
					"Tartus"
			]
		},
		 {
		   "id": 168,
		   "EU": false,
		   "continent": "",
		   "country": "Taiwan",
		   "states": [
		      "Chang-hua",
		      "Chia-i",
		      "Hsin-chu",
		      "Hua-lien",
		      "I-lan",
		      "Kao-hsiung",
		      "Kin-men",
		      "Lien-chiang",
		      "Miao-li",
		      "Nan-t'ou",
		      "P'eng-hu",
		      "P'ing-tung",
		      "T'ai-chung",
		      "T'ai-nan",
		      "T'ai-pei",
		      "T'ai-tung",
		      "T'ao-yuan",
		      "Yun-lin",
		      "Chia-i",
		      "Chi-lung",
		      "Hsin-chu",
		      "T'ai-chung",
		      "T'ai-nan",
		      "Kao-hsiung city",
		      "T'ai-pei city"
		   ],
		   "ports": [
		   		"Kaohsiung",
					"Keelung"
			]
		},
		 {
		   "id": 169,
		   "EU": false,
		   "continent": "",
		   "country": "Tajikistan",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 170,
		   "EU": false,
		   "continent": "",
		   "country": "Tanzania",
		   "states": [
		      "Arusha",
		      "Dar es Salaam",
		      "Dodoma",
		      "Iringa",
		      "Kagera",
		      "Kigoma",
		      "Kilimanjaro",
		      "Lindi",
		      "Manyara",
		      "Mara",
		      "Mbeya",
		      "Morogoro",
		      "Mtwara",
		      "Mwanza",
		      "Pemba North",
		      "Pemba South",
		      "Pwani",
		      "Rukwa",
		      "Ruvuma",
		      "Shinyanga",
		      "Singida",
		      "Tabora",
		      "Tanga",
		      "Zanzibar Central/South",
		      "Zanzibar North",
		      "Zanzibar Urban/West"
		   ],
		   "ports": [
		   		"Dar Es Salaam"
			]
		},
		 {
		   "id": 171,
		   "EU": false,
		   "continent": "",
		   "country": "Thailand",
		   "states": [
		      "Amnat Charoen",
		      "Ang Thong",
		      "Buriram",
		      "Chachoengsao",
		      "Chai Nat",
		      "Chaiyaphum",
		      "Chanthaburi",
		      "Chiang Mai",
		      "Chiang Rai",
		      "Chon Buri",
		      "Chumphon",
		      "Kalasin",
		      "Kamphaeng Phet",
		      "Kanchanaburi",
		      "Khon Kaen",
		      "Krabi",
		      "Krung Thep Mahanakhon",
		      "Lampang",
		      "Lamphun",
		      "Loei",
		      "Lop Buri",
		      "Mae Hong Son",
		      "Maha Sarakham",
		      "Mukdahan",
		      "Nakhon Nayok",
		      "Nakhon Pathom",
		      "Nakhon Phanom",
		      "Nakhon Ratchasima",
		      "Nakhon Sawan",
		      "Nakhon Si Thammarat",
		      "Nan",
		      "Narathiwat",
		      "Nong Bua Lamphu",
		      "Nong Khai",
		      "Nonthaburi",
		      "Pathum Thani",
		      "Pattani",
		      "Phangnga",
		      "Phatthalung",
		      "Phayao",
		      "Phetchabun",
		      "Phetchaburi",
		      "Phichit",
		      "Phitsanulok",
		      "Phra Nakhon Si Ayutthaya",
		      "Phrae",
		      "Phuket",
		      "Prachin Buri",
		      "Prachuap Khiri Khan",
		      "Ranong",
		      "Ratchaburi",
		      "Rayong",
		      "Roi Et",
		      "Sa Kaeo",
		      "Sakon Nakhon",
		      "Samut Prakan",
		      "Samut Sakhon",
		      "Samut Songkhram",
		      "Sara Buri",
		      "Satun",
		      "Sing Buri",
		      "Sisaket",
		      "Songkhla",
		      "Sukhothai",
		      "Suphan Buri",
		      "Surat Thani",
		      "Surin",
		      "Tak",
		      "Trang",
		      "Trat",
		      "Ubon Ratchathani",
		      "Udon Thani",
		      "Uthai Thani",
		      "Uttaradit",
		      "Yala",
		      "Yasothon"
		   ],
		   "ports": [
		   		"Laem Chabang",
					"Bangkok"
			]
		},
		 {
		   "id": 172,
		   "EU": false,
		   "continent": "",
		   "country": "Togo",
		   "states": [
		      "Kara",
		      "Plateaux",
		      "Savanes",
		      "Centrale",
		      "Maritime"
		   ]
		},
		 {
		   "id": 173,
		   "EU": false,
		   "continent": "",
		   "country": "Tonga",
		   "states": [
		      
		   ]
		},
		 {
		   "id": 174,
		   "EU": false,
		   "continent": "",
		   "country": "Trinidad and Tobago",
		   "states": [
		      "Couva",
		      "Diego Martin",
		      "Mayaro",
		      "Penal",
		      "Princes Town",
		      "Sangre Grande",
		      "San Juan",
		      "Siparia",
		      "Tunapuna",
		      "Port-of-Spain",
		      "San Fernando",
		      "Arima",
		      "Point Fortin",
		      "Chaguanas",
		      "Tobago"
		   ]
		},
		 {
		   "id": 175,
		   "EU": false,
		   "continent": "",
		   "country": "Tunisia",
		   "states": [
		      "Ariana (Aryanah)",
		      "Beja (Bajah)",
		      "Ben Arous (Bin 'Arus)",
		      "Bizerte (Banzart)",
		      "Gabes (Qabis)",
		      "Gafsa (Qafsah)",
		      "Jendouba (Jundubah)",
		      "Kairouan (Al Qayrawan)",
		      "Kasserine (Al Qasrayn)",
		      "Kebili (Qibili)",
		      "Kef (Al Kaf)",
		      "Mahdia (Al Mahdiyah)",
		      "Manouba (Manubah)",
		      "Medenine (Madanin)",
		      "Monastir (Al Munastir)",
		      "Nabeul (Nabul)",
		      "Sfax (Safaqis)",
		      "Sidi Bou Zid (Sidi Bu Zayd)",
		      "Siliana (Silyanah)",
		      "Sousse (Susah)",
		      "Tataouine (Tatawin)",
		      "Tozeur (Tawzar)",
		      "Tunis",
		      "Zaghouan (Zaghwan)"
		   ]
		},
		 {
		   "id": 176,
		   "EU": false,
		   "continent": "",
		   "country": "Turkey",
		   "states": [
		      "Adana",
		      "Adiyaman",
		      "Afyonkarahisar",
		      "Agri",
		      "Aksaray",
		      "Amasya",
		      "Ankara",
		      "Antalya",
		      "Ardahan",
		      "Artvin",
		      "Aydin",
		      "Balikesir",
		      "Bartin",
		      "Batman",
		      "Bayburt",
		      "Bilecik",
		      "Bingol",
		      "Bitlis",
		      "Bolu",
		      "Burdur",
		      "Bursa",
		      "Canakkale",
		      "Cankiri",
		      "Corum",
		      "Denizli",
		      "Diyarbakir",
		      "Duzce",
		      "Edirne",
		      "Elazig",
		      "Erzincan",
		      "Erzurum",
		      "Eskisehir",
		      "Gaziantep",
		      "Giresun",
		      "Gumushane",
		      "Hakkari",
		      "Hatay",
		      "Igdir",
		      "Isparta",
		      "Istanbul",
		      "Izmir",
		      "Kahramanmaras",
		      "Karabuk",
		      "Karaman",
		      "Kars",
		      "Kastamonu",
		      "Kayseri",
		      "Kilis",
		      "Kirikkale",
		      "Kirklareli",
		      "Kirsehir",
		      "Kocaeli",
		      "Konya",
		      "Kutahya",
		      "Malatya",
		      "Manisa",
		      "Mardin",
		      "Mersin",
		      "Mugla",
		      "Mus",
		      "Nevsehir",
		      "Nigde",
		      "Ordu",
		      "Osmaniye",
		      "Rize",
		      "Sakarya",
		      "Samsun",
		      "Sanliurfa",
		      "Siirt",
		      "Sinop",
		      "Sirnak",
		      "Sivas",
		      "Tekirdag",
		      "Tokat",
		      "Trabzon",
		      "Tunceli",
		      "Usak",
		      "Van",
		      "Yalova",
		      "Yozgat",
		      "Zonguldak"
		   ],
		   "ports": [
		   		"Aliağa",
					"Ambarlı",
					"Antalya",
					"Gemlik",
					"İskenderun",
					"Istanbul",
					"İzmir",
					"Izmit",
					"Kocaeli",
					"Mersin",
					"Samsun",
					"Tekirdağ",
					"Yalova"
			]
		},
		 {
		   "id": 177,
		   "EU": false,
		   "continent": "",
		   "country": "Turkmenistan",
		   "states": [
		      "Ahal Welayaty (Ashgabat)",
		      "Balkan Welayaty (Balkanabat)",
		      "Dashoguz Welayaty",
		      "Lebap Welayaty (Turkmenabat)",
		      "Mary Welayaty"
		   ]
		},
		 {
		   "id": 178,
		   "EU": false,
		   "continent": "",
		   "country": "Uganda",
		   "states": [
		      "Adjumani",
		      "Apac",
		      "Arua",
		      "Bugiri",
		      "Bundibugyo",
		      "Bushenyi",
		      "Busia",
		      "Gulu",
		      "Hoima",
		      "Iganga",
		      "Jinja",
		      "Kabale",
		      "Kabarole",
		      "Kaberamaido",
		      "Kalangala",
		      "Kampala",
		      "Kamuli",
		      "Kamwenge",
		      "Kanungu",
		      "Kapchorwa",
		      "Kasese",
		      "Katakwi",
		      "Kayunga",
		      "Kibale",
		      "Kiboga",
		      "Kisoro",
		      "Kitgum",
		      "Kotido",
		      "Kumi",
		      "Kyenjojo",
		      "Lira",
		      "Luwero",
		      "Masaka",
		      "Masindi",
		      "Mayuge",
		      "Mbale",
		      "Mbarara",
		      "Moroto",
		      "Moyo",
		      "Mpigi",
		      "Mubende",
		      "Mukono",
		      "Nakapiripirit",
		      "Nakasongola",
		      "Nebbi",
		      "Ntungamo",
		      "Pader",
		      "Pallisa",
		      "Rakai",
		      "Rukungiri",
		      "Sembabule",
		      "Sironko",
		      "Soroti",
		      "Tororo",
		      "Wakiso",
		      "Yumbe"
		   ]
		},
		 {
		   "id": 179,
		   "EU": false,
		   "continent": "",
		   "country": "Ukraine",
		   "states": [
		      "Cherkasy",
		      "Chernihiv",
		      "Chernivtsi",
		      "Crimea",
		      "Dnipropetrovs'k",
		      "Donets'k",
		      "Ivano-Frankivs'k",
		      "Kharkiv",
		      "Kherson",
		      "Khmel'nyts'kyy",
		      "Kirovohrad",
		      "Kiev",
		      "Kyyiv",
		      "Luhans'k",
		      "L'viv",
		      "Mykolayiv",
		      "Odesa",
		      "Poltava",
		      "Rivne",
		      "Sevastopol'",
		      "Sumy",
		      "Ternopil'",
		      "Vinnytsya",
		      "Volyn'",
		      "Zakarpattya",
		      "Zaporizhzhya",
		      "Zhytomyr"
		   ],
		   "ports": [
		   		"Odessa"
			]
		},
		 {
		   "id": 180,
		   "EU": false,
		   "continent": "",
		   "country": "United Arab Emirates",
		   "states": [
		      "Abu Dhabi",
		      "'Ajman",
		      "Al Fujayrah",
		      "Sharjah",
		      "Dubai",
		      "Ra's al Khaymah",
		      "Umm al Qaywayn"
		   ],
		   "ports": [
		   		"Dubai",
					"Fujairah Port",
					"Khor Fakkan",
					"Sharjah",
					"Jebel Ali",
					"Abu Dhabi"
			]
		},
		 {
		   "id": 181,
		   "EU": false,
		   "continent": "",
		   "country": "United Kingdom",
		   "states": [
		      
		   ],
		   "ports": [
		   		"Felixstowe",
					"Southampton",
					"Tilbury, London",
					"London Gateway",
					"London Thamesport",
					"Seaforth Dock, Liverpool",
					"Belfast",
					"Portsmouth",
					"Grangemouth",
					"Immingham Container Terminal"
			]
		},
		 {
		   "id": 182,
		   "EU": false,
		   "continent": "",
		   "country": "United States",
		   "states": [
		      "Alabama",
		      "Alaska",
		      "Arizona",
		      "Arkansas",
		      "California",
		      "Colorado",
		      "Connecticut",
		      "Delaware",
		      "District of Columbia",
		      "Florida",
		      "Georgia",
		      "Hawaii",
		      "Idaho",
		      "Illinois",
		      "Indiana",
		      "Iowa",
		      "Kansas",
		      "Kentucky",
		      "Louisiana",
		      "Maine",
		      "Maryland",
		      "Massachusetts",
		      "Michigan",
		      "Minnesota",
		      "Mississippi",
		      "Missouri",
		      "Montana",
		      "Nebraska",
		      "Nevada",
		      "New Hampshire",
		      "New Jersey",
		      "New Mexico",
		      "New York",
		      "North Carolina",
		      "North Dakota",
		      "Ohio",
		      "Oklahoma",
		      "Oregon",
		      "Pennsylvania",
		      "Rhode Island",
		      "South Carolina",
		      "South Dakota",
		      "Tennessee",
		      "Texas",
		      "Utah",
		      "Vermont",
		      "Virginia",
		      "Washington",
		      "West Virginia",
		      "Wisconsin",
		      "Wyoming"
		   ],
		   "ports": [
		   		"Long Beach, California",
					"Los Angeles, California",
					"Oakland, California",
					"Seattle, Washington",
					"Tacoma, Washington",
					"PortMiami, Miami, Florida",
					"Port Everglades, Florida",
					"Tampa, Florida",
					"New Orleans, Louisiana",
					"Boston, Massachusetts",
					"Helen Delich Bentley Baltimore, Maryland",
					"Wilmington Marine Terminal, Delaware",
					"New York and New Jersey",
					"Howland Hook Marine Terminal, Staten Island, New York",
					"Port Jersey Marine Terminal, Jersey City, New Jersey",
					"Port Newark-Elizabeth Marine Terminal, New Jersey",
					"Red Hook Marine Terminal, Brooklyn, New York",
					"Savannah, Georgia",
					"Charleston, South Carolina",
					"Wilmington, North Carolina",
					"Virginia Port Authority, Virginia",
					"APM Terminals, Portsmouth, Virginia",
					"Newport News Marine Terminal, Newport News, Virginia",
					"Norfolk International Terminals, Norfolk, Virginia",
					"Virginia Inland Port, Front Royal, Virginia",
					"Houston, Texas",
					"Bayport Terminal, Houston, Texas",
					"Galveston, Texas",
					"Port Lavaca, Texas",
					"Mobile, Alabama",
					"Anchorage, Alaska",
					"Honolulu, Hawaii",
					"San Juan, Puerto Rico",
					"Louisiana International Gulf Transfer Terminal Regional Center pre-construction phase"
			]
		},
		 {
		   "id": 183,
		   "EU": false,
		   "continent": "",
		   "country": "Uruguay",
		   "states": [
		      "Artigas",
		      "Canelones",
		      "Cerro Largo",
		      "Colonia",
		      "Durazno",
		      "Flores",
		      "Florida",
		      "Lavalleja",
		      "Maldonado",
		      "Montevideo",
		      "Paysandu",
		      "Rio Negro",
		      "Rivera",
		      "Rocha",
		      "Salto",
		      "San Jose",
		      "Soriano",
		      "Tacuarembo",
		      "Treinta y Tres"
		   ],
		   "ports": [
		   		"Montevideo"
			]
		},
		 {
		   "id": 184,
		   "EU": false,
		   "continent": "",
		   "country": "Uzbekistan",
		   "states": [
		      "Andijon Viloyati",
		      "Buxoro Viloyati",
		      "Farg'ona Viloyati",
		      "Jizzax Viloyati",
		      "Namangan Viloyati",
		      "Navoiy Viloyati",
		      "Qashqadaryo Viloyati",
		      "Qaraqalpog'iston Respublikasi",
		      "Samarqand Viloyati",
		      "Sirdaryo Viloyati",
		      "Surxondaryo Viloyati",
		      "Toshkent Shahri",
		      "Toshkent Viloyati",
		      "Xorazm Viloyati"
		   ]
		},
		 {
		   "id": 185,
		   "EU": false,
		   "continent": "",
		   "country": "Vanuatu",
		   "states": [
		      "Malampa",
		      "Penama",
		      "Sanma",
		      "Shefa",
		      "Tafea",
		      "Torba"
		   ]
		},
		 {
		   "id": 186,
		   "EU": false,
		   "continent": "",
		   "country": "Venezuela",
		   "states": [
		      "Amazonas",
		      "Anzoategui",
		      "Apure",
		      "Aragua",
		      "Barinas",
		      "Bolivar",
		      "Carabobo",
		      "Cojedes",
		      "Delta Amacuro",
		      "Dependencias Federales",
		      "Distrito Federal",
		      "Falcon",
		      "Guarico",
		      "Lara",
		      "Merida",
		      "Miranda",
		      "Monagas",
		      "Nueva Esparta",
		      "Portuguesa",
		      "Sucre",
		      "Tachira",
		      "Trujillo",
		      "Vargas",
		      "Yaracuy",
		      "Zulia"
		   ]
		},
		 {
		   "id": 187,
		   "EU": false,
		   "continent": "",
		   "country": "Vietnam",
		   "states": [
		      "An Giang",
		      "Bac Giang",
		      "Bac Kan",
		      "Bac Lieu",
		      "Bac Ninh",
		      "Ba Ria-Vung Tau",
		      "Ben Tre",
		      "Binh Dinh",
		      "Binh Duong",
		      "Binh Phuoc",
		      "Binh Thuan",
		      "Ca Mau",
		      "Cao Bang",
		      "Dac Lak",
		      "Dac Nong",
		      "Dien Bien",
		      "Dong Nai",
		      "Dong Thap",
		      "Gia Lai",
		      "Ha Giang",
		      "Hai Duong",
		      "Ha Nam",
		      "Ha Tay",
		      "Ha Tinh",
		      "Hau Giang",
		      "Hoa Binh",
		      "Hung Yen",
		      "Khanh Hoa",
		      "Kien Giang",
		      "Kon Tum",
		      "Lai Chau",
		      "Lam Dong",
		      "Lang Son",
		      "Lao Cai",
		      "Long An",
		      "Nam Dinh",
		      "Nghe An",
		      "Ninh Binh",
		      "Ninh Thuan",
		      "Phu Tho",
		      "Phu Yen",
		      "Quang Binh",
		      "Quang Nam",
		      "Quang Ngai",
		      "Quang Ninh",
		      "Quang Tri",
		      "Qui Nhon",
		      "Soc Trang",
		      "Son La",
		      "Tay Ninh",
		      "Thai Binh",
		      "Thai Nguyen",
		      "Thanh Hoa",
		      "Thua Thien-Hue",
		      "Tien Giang",
		      "Tra Vinh",
		      "Tuyen Quang",
		      "Vinh Long",
		      "Vinh Phuc",
		      "Yen Bai",
		      "Can Tho",
		      "Da Nang",
		      "Hai Phong",
		      "Hanoi",
		      "Ho Chi Minh"
		   ],
		   "ports": [
		   		"Saigon Port (Ho Chi Minh City)",
		   		"Hai Phong"
			]
		},
		 {
		   "id": 188,
		   "EU": false,
		   "continent": "",
		   "country": "Yemen",
		   "states": [
		      "Abyan",
		      "'Adan",
		      "Ad Dali'",
		      "Al Bayda'",
		      "Al Hudaydah",
		      "Al Jawf",
		      "Al Mahrah",
		      "Al Mahwit",
		      "'Amran",
		      "Dhamar",
		      "Hadramawt",
		      "Hajjah",
		      "Ibb",
		      "Lahij",
		      "Ma'rib",
		      "Sa'dah",
		      "San'a'",
		      "Shabwah",
		      "Ta'izz"
		   ]
		},
		 {
		   "id": 189,
		   "EU": false,
		   "continent": "",
		   "country": "Zambia",
		   "states": [
		      "Central",
		      "Copperbelt",
		      "Eastern",
		      "Luapula",
		      "Lusaka",
		      "Northern",
		      "North-Western",
		      "Southern",
		      "Western"
		   ]
		},
		 {
		   "id": 190,
		   "EU": false,
		   "continent": "",
		   "country": "Zimbabwe",
		   "states": [
		      "Bulawayo",
		      "Harare",
		      "Manicaland",
		      "Mashonaland Central",
		      "Mashonaland East",
		      "Mashonaland West",
		      "Masvingo",
		      "Matabeleland North",
		      "Matabeleland South",
		      "Midlands"
		   ]
		}
	];
  
  constructor() { }

	public setState(id: number) {
			if (id !== undefined) {
			if (this.countries[id]['states'] !== undefined) {
				this.states = this.countries[id]['states'];
			} else {
				this.states = [];
			}
		}
	}

	public setPort(id: number, type = "dest") {
		var tports;
		if (id !== undefined) {
			if (this.countries[id]['ports'] !== undefined) {
				tports = this.countries[id]['ports'];
			} else {
				tports = [];
			}
			if (type=="dest") {
				this.ports = tports;
			} else {
				this.expports = tports;
			}
			if (this.delcountry['id']!==null) {
				this.US = this.delcountry.id==182;
				this.EU = (this.countries[this.delcountry.id]['EU']==undefined ? false : this.countries[this.delcountry.id]['EU']);
			}
		}
	}

	public getCountryId(country: string) {
		if (country == '') {
			return this.countries[159].id;
		}
		for (var i = 0; i < this.countries.length; i++) {
			if (this.countries[i].country == country) {
				return this.countries[i].id;
			}
		}
		return null;
	}

	public clear(fields) {
		switch (fields) {
			case 'expports':
				this.expports = [];
				this.expport = null;
				break;
			case 'ports':
				this.ports = [];
				this.port = null;
				break;
		}
	}

	public checkEU(country: string) {
		if (country==null) {
			return false;
		} else {
			for (var i = 0; i < this.countries.length; i++) {
				if (this.countries[i]['country']==country) {
					return this.countries[i]['EU'];
				}
			}
			return false;
		}
	}

}
