<div *ngIf="isAuthenticated()" class="container-fluid px-4">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<div class="row justify-content-between">
					<div class="col main-header">
						<h2>{{ this.tname | titlecase }}: {{ this.loginService.season }} Season</h2>
					</div>
		      <div class="dropdown col-auto" dropdown>
		        <h5>
		          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>{{ this.loginService.selectedmembername }}<span class="caret"></span></a>
		          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
		            <button 
		              class="dropdown-item waves-light py-3" 
		              mdbWavesEffect 
		              (click)="onMemberSelected(mship['membership'], mship['companyname'])"
		              *ngFor='let mship of this.loginService.memberships'>{{ mship['companyname'] }}</button>
		          </ul>
		        </h5>
		      </div>
				</div>
			</div>
			<br>
			<br>
			<div class="table-container">
			  <p-table 
			  	id="datatable" #dt1
			  	(onFilter)="onFilter($event, dt1)"
			  	[value]="this.tableService[this.tablename.toLowerCase()]" 
			  	[columns]="this.loginService.dropdown['tableColumns'][this.tablename.toLowerCase()]" 
			  	[loading]="this.tableService.loading" 
			  	[style]="{width:'100%', 'min-height':'400px'}" 
			  	[scrollable]="true" scrollHeight="flex" 
			  	[resizableColumns]="true" columnResizeMode="expand" 
			  	[reorderableColumns]="true"
			  	[(selection)]="this.tableService.selectedRows"
			  	dataKey="id"
			  	exportFilename="{{ exportname }}"
			  	stateStorage="session" stateKey="{{ this.tablename }}-session"
			  	styleClass="p-datatable-striped">
				  <ng-template pTemplate="caption">
		       	<div class="p-d-flex">
	            <button pButton class="p-button-outlined p-ml-2" style="padding-left: 8px !important;padding-right: 8px !important;" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
	            <span class="p-input-icon-left p-ml-3 p-mr-auto">
	              <i class="pi pi-search"></i>
	              <input pInputText type="text" class="col" [(ngModel)]="searchtext" (input)="dt1.filterGlobal($event.target['value'], 'contains')" placeholder="Search keyword" />
	            </span>
	            <button type="button" pButton pRipple (click)="this.tableService.loadData(this.tablename.toLowerCase())" class="p-mr-2 p-ml-3 p-button p-px-2" pTooltip="Refresh" tooltipPosition="bottom"><i class="pi pi-refresh" [class.pi-spin]="this.tableService.stillLoading"></i></button>
	            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="this.tableService.saveCSV(this.tablename.toLowerCase())" class="p-mr-2 p-ml-2 p-button" pTooltip="CSV" tooltipPosition="bottom"></button>
	            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="this.tableService.saveXLSX(this.tablename.toLowerCase())" class="p-mr-2 p-ml-2 p-button-success" pTooltip="XLSX" tooltipPosition="bottom"></button>
		        </div>
		    	</ng-template>
				  <ng-template pTemplate="colgroup" let-columns>
	          <colgroup>
	          	<ng-container *ngFor="let col of columns">
	            	<col *ngIf="col.pipe!='checkbox' || (col.pipe=='checkbox' && this.tableService.checkBoxes)" style="width: {{ col.width }}">
	            </ng-container>
	          </colgroup>
	        </ng-template>
			  	<ng-template pTemplate="header" let-columns>
			  		<tr>
              <ng-container *ngFor="let col of columns">
				  			<th *ngIf="col.pipe=='checkbox' && this.tableService.checkBoxes">
	                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				  			</th>
				  			<th 
				  				*ngIf="col.pipe!='checkbox'"
				  				pReorderableColumn 
				  				pResizableColumn
				  				[pSortableColumn]="col.field"
				  				[style.width]="col.width + ' !important'"
				  				[style.max-width]="col.width + ' !important'"
				  				>
				  				<div class="p-d-flex p-jc-between p-ai-center">
				  					{{ col.header }}
	                </div>
	                <p-columnFilter *ngIf="col.type!=='dropdown' && col.type!=='yesno'" type="{{ col.type }}" field="{{ col.field }}" display="menu" id="filter"></p-columnFilter>
	                <p-columnFilter *ngIf="col.type=='dropdown'" type="{{ col.type }}" field="{{ col.field }}" matchMode="equals" operator="or" display="menu" id="filter">
	                	<ng-template pTemplate="filter" let-value let-filter="filterCallback">
	                    <p-dropdown *ngIf="col.pipe!=='instalment' && col.pipe!=='titlecase' && col.pipe!=='date'" [ngModel]="value" [options]="this.tableService.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='titlecase'" [ngModel]="value" [options]="this.tableService.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | titlecase }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | titlecase }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='instalment'" [ngModel]="value" [options]="this.tableService.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | instalment }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | instalment }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='date'" [ngModel]="value" [options]="this.tableService.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | datemod:'MMM d, EEE' }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | date:'MMM d, EEE' }}
	                      </ng-template>
	                    </p-dropdown>
	                  </ng-template>
	                </p-columnFilter>
	                <p-columnFilter *ngIf="col.type=='yesno'" type="boolean" field="{{ col.field }}" display="menu"></p-columnFilter>
				  				<p-sortIcon [field]="col.field"></p-sortIcon>
				  			</th>
				  		</ng-container>
			  		</tr>
			  	</ng-template>
			  	<ng-template pTemplate="emptymessage" let-columns>
					  <tr>
					    <td [attr.colspan]="columns.length">
					       No records found - Check filters
					    </td>
					  </tr>
					</ng-template>
			  	<ng-template pTemplate="body" let-rowData let-columns="columns">
			  		<tr 
			  			[pSelectableRow]="rowData"
				  		[class.draft]="rowData['draft']"
				  		[class.complete]="rowData['complete']"
				  		[class.alternative]="rowData['alternative']"
				  		[class.actionrequired]="rowData['actionrequired']"
				  		[class.ordercomplete]="rowData['orderstatus']=='completed' || rowData['rowcolorgrey']"
				  		[class.dispatched]="rowData['orderstatus']=='dispatched' || rowData['rowcolorsand']"
				  		[class.paid]="rowData['orderstatus']=='paid' || rowData['paid']"
				  		[class.selected]="isSelected(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])"
				  		[class.concern]="rowData['concern']"
				  		[class.wasconcern]="rowData['wasconcern']"
				  		>
              <ng-container *ngFor="let col of columns">
	              <ng-container *ngIf="col.pipe=='checkbox' && this.tableService.checkBoxes">              	
	              	<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'">
	              		<p-tableCheckbox *ngIf="rowData[col.disabled]==0" [value]="rowData" (click)="this.tableService.boxChecked(this.tablename.toLowerCase(), this.dt1, rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])"></p-tableCheckbox>
	              	</td>
	              </ng-container>
	              <ng-container *ngIf="col.pipe!=='checkbox'">
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='delivery'">{{ rowData[col.field] | delivery }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='instalment'">{{ rowData[col.field] | instalment }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='kg'">{{ rowData[col.field] | number:'1.2-2' | dashblank : ' kg' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='g'">{{ rowData[col.field] | number:'1.0-0' | dashblank : ' g' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='g1dp'">{{ rowData[col.field] | number:'1.1-1' | dashblank : ' g' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='number'">{{ rowData[col.field] | number:'1.2-2' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='integer' || col.pipe=='boxes'">{{ rowData[col.field] | number | dashblank }}</td>	  				
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='currency'">{{ fixFx(rowData[col.field], rowData[col.fxrate], rowData[col.currency]) | currency:this.tableService.currency:'symbol-narrow' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='setcurrency'">{{ rowData[col.field] | currency:rowData[col.currency]:'symbol-narrow' | dashblank }}</td>			  				
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='date'">{{ rowData[col.field] | date:'MMM d, EEE' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='time'">{{ rowData[col.field] | date:'HH:mm' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='duration'">{{ rowData[col.field] | date:'H:mm' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dateshort'">{{ rowData[col.field] | date:'MMM d' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='titlecase'">{{ rowData[col.field] | titlecase }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='percent'">{{ rowData[col.field] | percent:'1.2' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='yesno'" class="p-text-center">
				  					<i class="pi" [ngClass]="{'true-icon pi-check-circle': rowData[col.field], 'false-icon pi-times-circle': !rowData[col.field]}"></i>
	                </td>
				  				<td (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dashblank'">{{ rowData[col.field] | dashblank }}</td>
				  				<td (click)="onSelectRow(rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='none'">{{ rowData[col.field] }}</td>
				  				<td *ngIf="col.pipe=='button'"><button mdbBtn class="btn {{col.btntype}} tblbtn" *ngIf="rowData[col.field]==1" (click)="buttonAction(col.btnaction,rowData[this.tableService.allData[this.tablename.toLowerCase()]['key']])">{{ rowData[col.btntext] }}</button></td>
				  			</ng-container>
			  			</ng-container>
			  		</tr>
			  	</ng-template>
			  	<ng-template pTemplate="footer" let-rowData let-columns>
		        <tr>
		        	<ng-container *ngFor="let col of columns; let cIndex=index">
		        		<ng-container *ngIf="col.pipe!='checkbox' || (col.pipe=='checkbox' && this.tableService.checkBoxes)">
			        		<td *ngIf="col.footer=='text'">{{ col.text }}</td>
			        		<td *ngIf="col.footer=='none'"></td>
			        		<ng-container *ngIf="col.footer!=='none' && col.footer!=='text'">
			        			<td *ngIf="col.pipe=='delivery'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}</td>
			        			<td *ngIf="col.pipe=='instalment'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | instalment }}</td>
					  				<td *ngIf="col.pipe=='kg'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.2-2' | dashblank : ' kg' }}</td>
					  				<td *ngIf="col.pipe=='g'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.0-0' | dashblank : ' g' }}</td>
					  				<td *ngIf="col.pipe=='g1dp'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.1-1' | dashblank : ' g' }}</td>
					  				<td *ngIf="col.pipe=='number'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.2-2' }}</td>
				  					<td *ngIf="col.pipe=='integer'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number | dashblank }}</td>
				  					<td *ngIf="col.pipe=='boxes'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number | dashblank }} Units</td>			  					
				  					<td *ngIf="col.pipe=='currency'">{{ (col.footer=='sum' ? +calTotal(col.field, 'fx') : (col.footer=='count' ? +calCount(col.field, 'fx') : +calWavg(col.field, 'fx') )) | currency:this.tableService.currency:'symbol-narrow' | dashblank }}</td>			  					
				  					<td *ngIf="col.pipe=='setcurrency'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | currency:col.currency:'symbol-narrow' | dashblank }}</td>  				
					  				<td *ngIf="col.pipe=='date'">{{ rowData[col.field] | date:'MMM d, EEE' }}</td>		
					  				<td *ngIf="col.pipe=='time'">{{ rowData[col.field] | date:'HH:mm' | dashblank }}</td>		
					  				<td *ngIf="col.pipe=='duration'">{{ rowData[col.field] | date:'H:mm' | dashblank }}</td>
				  					<td *ngIf="col.pipe=='dateshort'">{{ rowData[col.field] | date:'MMM d' }}</td>
					  				<td *ngIf="col.pipe=='titlecase'">{{ rowData[col.field] | titlecase }}</td>
					  				<td *ngIf="col.pipe=='percent'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | percent:'1.2' | dashblank }}</td>
					  				<td *ngIf="col.pipe=='yesno'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}
		                </td>
					  				<td *ngIf="col.pipe=='none'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}</td>
					  				<td *ngIf="col.pipe=='dashblank'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | dashblank }}</td>
					  			</ng-container>
		        		</ng-container>
		        	</ng-container>
		        </tr>
		    	</ng-template>
			  </p-table>
			</div>
			<div *ngIf="this.tableService.clickedIndex[this.tablename.toLowerCase()]!==null" class="container">
				<ng-container *ngIf="this.tableService.loadReady(this.tablename)">
					<br><br>
					<ng-container *ngIf="this.component=='delivery'"><app-delivery></app-delivery></ng-container>
					<ng-container *ngIf="this.component=='remittance'">
						<app-farmerremittance></app-farmerremittance>
						<br>
						<br>
						<div class="row justify-content-between">
							<div class="col main-header">
								<h2>Remittance Breakdown</h2>
							</div>
							<mat-slide-toggle class="slider-margin slider-label mt-2 col-auto" [(ngModel)]="remittanceDetails">
					      Show Details
					    </mat-slide-toggle>
						</div>
						<br>
						<br>
						<div *ngIf="remittanceDetails">
							<app-remittancedetail></app-remittancedetail>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-login></app-login>
</div>