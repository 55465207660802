<div class="col-xs-12 col-sm-10 col-xl-9 col-sm-offset-1 col-md-offset-2 pt-3 mx-auto" *ngIf="isAuthenticated()">
	<br>
	<div class="row justify-content-between">
		<div class="col-xl-6 col-sm-12 row justify-content-start">
			<div class="mt-2 ml-0">
				<h2 style="line-height: 1.3em">Welcome {{ this.loginService.uname }},</h2>
			</div>		
			<ng-container *ngIf="this.loginService.season!==null">	
				<h2>
					<div class="dropdown" dropdown>
		        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-0" mdbWavesEffect>
		        {{ this.loginService.season }} Season<span class="caret"></span></a>
		        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
		          <button 
		          	class="dropdown-item waves-light py-3" 
		          	mdbWavesEffect 
		          	(click)="onSeasonSelected(season)"
		          	*ngFor='let season of this.loginService.seasons'>{{ season }} Season</button>
		        </ul>
		      </div>
				</h2>
			</ng-container>
			<br>
		</div>

		<div *ngIf="isAuthenticated('mod-currency') && (this.calculatorService.overviewStore?.fx!==null || this.calculatorService.overviewStore?.fx12month!==null)" class="col-xl-6 col-sm-12 row justify-content-start" style="text-align: right">

			<div *ngIf="this.calculatorService.overviewStore?.fx!==null" class="col-6">
					<p class="mt-2 mb-0" style="font-weight: 600">Day Average</p><p class="mb-2 mt-0" style="font-weight: 600">ZAR/USD</p>
					<h2 class="mt-2 mb-2" style="font-weight: 500; color: green">{{ this.calculatorService.overviewStore?.fx | number:'1.2-2' }}</h2>
					<p>{{ this.calculatorService.overviewStore?.fxdate | date:'EEE, MMM d, yyyy' }}</p>
			</div>

			<div *ngIf="this.calculatorService.overviewStore?.fx12month!==null" class="col-6">
					<p class="mt-2 mb-0" style="font-weight: 600">12 Month Avg</p><p class="mb-2 mt-0" style="font-weight: 600">ZAR/USD</p>
					<h2 class="mt-2 mb-2" style="font-weight: 500; color: blue">{{ this.calculatorService.overviewStore?.fx12month | number:'1.2-2' }}</h2>
					<p>{{ this.calculatorService.overviewStore?.fx12monthdate | date:'EEE, MMM d, yyyy' }}</p>
			</div>

			<div class="col">
				<p class="text-right">Source: Bloomberg</p>
			</div>

		</div>
	</div>

	<br>
	<app-announcement></app-announcement>
	<app-article></app-article>
	
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3 mx-auto" *ngIf="!isAuthenticated()">
	<app-login></app-login>
</div>