<div class="container">
	<div class="row justify-content-between">
		<div class="col-xs-6 ml-3 main-header">
			<h3>Depot Delivery {{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.iddeldepot | delivery }} ({{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.status | titlecase }})</h3>
		</div>
		<div class="mr-4 ml-3 col-auto" *ngIf="this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.status=='depot' && isAuthorised('depot')">
			<button mdbBtn class="btn btn-secondary" (click)="editDepotDelivery()">Edit Delivery</button>
		</div>
		<div class="mr-4 ml-3 col-auto" *ngIf="this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.status!=='depot' && isAuthorised('general')">
			<button mdbBtn class="btn btn-secondary" (click)="editDelivery()">Edit Delivery</button>
		</div>
	</div>
	<br>
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Producer</td>
				<td><a (click)="viewCompany(this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.idproducer)">{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.companyname }}</a></td>
			</tr>
			<tr *ngIf="this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.idproducer!=this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.idparent">
				<td>Parent</td>
				<td><a (click)="viewCompany(this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.idparent)">{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.parent }}</a></td>
			</tr>
			<tr *ngIf="this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.farmid!=null">
				<td>Farm ID</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.farmid }}</td>
			</tr>
			<tr *ngIf="this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.iddelfarm!=null">
				<td>Farmer's Delivery Reference</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.iddelfarm }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Date Delivered</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.depotdate | date:'MMM d, EEE, y' }}</td>
			</tr>
			<tr>
				<td>Cultivar</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.cultivar }}</td>
			</tr>
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>Depot Dispatched WNIS</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.depotwnisd | number:'1.2-2' }} kg</td>
			</tr>
			<tr>
				<td>Depot Received WNIS</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.depotwnis | number:'1.2-2' }} kg</td>
			</tr>
			<tr>
				<td>Smalls delivered</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.depotsmalls | number:'1.2-2' }} kg</td>
			</tr>
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>Dehusking?</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.dehusking | yesno }}</td>
			</tr>
			<ng-container *ngIf="this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.dehusking==1">
				<tr>
					<td>Husk Mass</td>
					<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.huskmass | number:'1.2-2' }} kg</td>
				</tr>
				<tr>
					<td>Reject Husk Mass</td>
					<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.rejecthuskmass | number:'1.2-2' }} kg</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="this.deliveryService.tags.length>0">
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Tag Serial Numbers</td>
					<td>{{ this.deliveryService.tags[0]['serial'] }}</td>
				</tr>
				<ng-container *ngFor="let tag of this.deliveryService.tags; let tagIndex=index;">
					<tr *ngIf="tagIndex>0">
						<td></td>
						<td>{{ tag.serial }}</td>
					</tr>
				</ng-container>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Delivery Note</td>
				<td>{{ this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']]?.notes }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr><td></td><td></td></tr>
		</tbody>
	</table>
</div>