<div *ngIf="isAuthenticated()" class="container">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<div class="row justify-content-between">
					<div class="col">
						<h2>Inventory: Current Stock</h2>
					</div>
					<div class="mr-4 ml-3">
						<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
					</div>
				</div>
			</div>
			<br>
			<mat-form-field class="filter-wide">
			  <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter">
			</mat-form-field>
			<div class="example-container mat-elevation-z8">
				<div class="example-loading-shade"
			       *ngIf="isLoadingResults">
			    <mat-spinner *ngIf="isLoadingResults" color="accent"></mat-spinner>
			  </div>
				<div class="example-table-container">
				  <table mat-table matSort [dataSource]="datasrc" hover="true" class="example-table">
				  	<ng-container matColumnDef="style" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style </th>
				      <td mat-cell *matCellDef="let element"> {{ element.style }} </td>
				      <td mat-footer-cell *matFooterCellDef> Total </td>
				    </ng-container>
				    <ng-container matColumnDef="variety">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Variety </th>
				      <td mat-cell *matCellDef="let element"> {{ element.variety }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				   <ng-container matColumnDef="boxes">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Boxes </th>
				      <td mat-cell *matCellDef="let element"> {{ element.boxes }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('boxes') }} </td>
				    </ng-container>
				    <ng-container matColumnDef="grade">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Grade </th>
				      <td mat-cell *matCellDef="let element"> {{ element.grade }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				    <ng-container matColumnDef="location">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
				      <td mat-cell *matCellDef="let element"> {{ element.location }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				    <tr mat-row 
				    	*matRowDef="let row; let i = index; columns: displayedColumns;" 
				    	(click)="onSelectRow(i)"
				    	style="cursor: pointer"
				    	class="example-element-row"></tr>
	 					<tr mat-footer-row *matFooterRowDef="displayedColumns" class="example-first-footer-row"></tr>
				  </table>
				</div>
			</div>
			<br>
			<br>
			<div *ngIf="clickedIndexNull()">
				<app-tracebatch></app-tracebatch>
			</div>
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>