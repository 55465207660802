<div *ngIf="isAuthenticated()" class="container-fluid px-4">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<div class="row justify-content-between">
					<div class="col main-header">
						<h2 *ngIf="!curswitch">{{ this.tname | titlecase }}: {{ this.lS.season }} Season</h2>
						<h2 *ngIf="curswitch">
							<div class="dropdown" dropdown>
				        <a dropdownToggle mdbWavesEffect type="button" class="pl-0 nav-link dropdown-toggle waves-light" mdbWavesEffect>
				        {{ this.tname | titlecase }}: {{ this.lS.season }} Season ({{ this.tS.currency }})<span class="caret"></span></a>
				        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="onCurSelect('ZAR')">ZAR</button>
				          	<button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="onCurSelect('USD')">USD</button>
				        </ul>
				      </div>
						</h2>
					</div>
					<div class="mr-4 ml-3 col-auto" *ngIf="!isAuthorised('accountant')">
						<button mdbBtn class="btn btn-info" *ngIf="this.tS.bpanel && this.component=='remittance'" (click)="batchPanel()">Remittances Table</button>
						<button mdbBtn class="btn btn-info" *ngIf="!this.tS.bpanel && this.component=='remittance'" (click)="batchPanel()">Batch PDF</button>
						<button mdbBtn class="btn btn-secondary" *ngIf="this.tablename.toLowerCase()=='samples'" (click)="changeTable('samplesunassigned')">Unassigned Samples</button>
						<button mdbBtn class="btn btn-primary" *ngIf="this.tablename.toLowerCase()=='samplesunassigned'" (click)="changeTable('samples')">Assigned Samples</button>
						<button mdbBtn class="btn btn-info" *ngIf="this.entity!==''" routerLink="/team/{{ this.subdir.replace(' ','').toLowerCase() }}/{{ this.entity.replace(' ','-').toLowerCase() }}">Add {{ this.entity }}</button>
					</div>
				</div>
			</div>
			<br>
			<br>
			<ng-container *ngIf="this.tablename=='bookings'">
				<app-teambookings></app-teambookings>
				<br>
				<br>
			</ng-container>
			<div class="table-container" *ngIf="!this.tS.bpanel">
			  <p-table 
			  	id="datatable" #dt1
			  	(onFilter)="onFilter($event, dt1)"
			  	[value]="this.tS[this.tablename.toLowerCase()]" 
			  	[columns]="this.dDDS.columns[this.tablename.toLowerCase()]" 
			  	[loading]="this.tS.loading" 
			  	[style]="{width:'100%', 'min-height':'400px'}" 
			  	[scrollable]="true" scrollHeight="flex" 
			  	[resizableColumns]="true" columnResizeMode="expand" 
			  	[reorderableColumns]="true"
			  	[(selection)]="this.tS.selectedRows"
			  	dataKey="id"
			  	exportFilename="{{ exportname }}"
			  	stateStorage="session" stateKey="{{ this.tablename }}-session"
			  	styleClass="p-datatable-striped">
				  <ng-template pTemplate="caption">
		       	<div class="p-d-flex">
	            <button pButton class="p-button-outlined p-ml-2" style="padding-left: 8px !important;padding-right: 8px !important;" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
	            <span class="p-input-icon-left p-ml-3 p-mr-auto">
	              <i class="pi pi-search"></i>
	              <input pInputText type="text" class="col" [(ngModel)]="searchtext" (input)="dt1.filterGlobal($event.target['value'], 'contains')" placeholder="Search keyword" />
	            </span>
	            <button type="button" pButton pRipple (click)="this.tS.loadData(this.tablename.toLowerCase())" class="p-mr-2 p-ml-3 p-button p-px-2" pTooltip="Refresh" tooltipPosition="bottom"><i class="pi pi-refresh" [class.pi-spin]="this.tS.stillLoading"></i></button>
	            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="this.tS.saveCSV(this.tablename.toLowerCase())" class="p-mr-2 p-ml-2 p-button" pTooltip="CSV" tooltipPosition="bottom"></button>
	            <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="this.tS.saveXLSX(this.tablename.toLowerCase())" class="p-mr-2 p-ml-2 p-button-success" pTooltip="XLSX" tooltipPosition="bottom"></button>
	            <ng-container *ngIf="isAuthenticated('finance') && this.tablename.toLowerCase()=='payments' && this.tS.checkBoxesActive">
		            <mat-divider class="mx-2" [vertical]="true"></mat-divider>
		            <button type="button" pButton pRipple icon="pi pi-check-square" class="p-mr-2 p-ml-2 p-button p-button-secondary" [class.p-button-secondary]="!this.tS.checkBoxes" (click)="toggleCheckboxes()" pTooltip="Batch select" tooltipPosition="bottom"></button>
		            <button *ngIf="this.tS.checkBoxes" [disabled]="this.tS.selectedRows.length==0" type="button" pButton pRipple icon="pi pi-download" class="p-mr-2 p-ml-2 p-button" (click)="this.tS.bankExport()" pTooltip="Export for bank upload" tooltipPosition="bottom"></button>
		            <ng-container *ngIf="isAuthenticated('financeadmin') && this.tablename.toLowerCase()=='payments' && !this.tS.checkBoxes">
			            <mat-divider class="mx-2" [vertical]="true"></mat-divider>
			            <input id="csvupload" type="file" accept=".csv" hidden (change)="onUpload($event)" onclick="this.value=null" #fileUpload>
			            <button *ngIf="!csvloading" type="button" pButton pRipple icon="pi pi-upload" class="p-mr-2 p-ml-2 p-px-2 p-button" (click)="fileUpload.click()" pTooltip="Import bank CSV" tooltipPosition="bottom"></button>
			            <button *ngIf="csvloading" type="button" pButton pRipple class="p-mr-2 p-ml-2 p-button p-px-2" pTooltip="CSV Loading..." tooltipPosition="bottom"><i class="pi pi-refresh" [class.pi-spin]="csvloading"></i></button>
			          </ng-container>
		          </ng-container>
	            <ng-container *ngIf="this.tablename.toLowerCase()!=='payments' && this.tS.checkBoxesActive">
		            <mat-divider class="mx-2" [vertical]="true"></mat-divider>
		            <button type="button" pButton pRipple icon="pi pi-check-square" class="p-mr-2 p-ml-2 p-button p-button-secondary" [class.p-button-secondary]="!this.tS.checkBoxes" (click)="toggleCheckboxes()" pTooltip="Select multiple rows" tooltipPosition="bottom"></button>
		          </ng-container>
		        </div>
		    	</ng-template>
				  <ng-template pTemplate="colgroup" let-columns>
	          <colgroup>
	          	<ng-container *ngFor="let col of columns">
	            	<col *ngIf="col.pipe!='checkbox' || (col.pipe=='checkbox' && this.tS.checkBoxes)" style="width: {{ col.width }}">
	            </ng-container>
	          </colgroup>
	        </ng-template>
			  	<ng-template pTemplate="header" let-columns>
			  		<tr>
              <ng-container *ngFor="let col of columns">
				  			<th *ngIf="col.pipe=='checkbox' && this.tS.checkBoxes">
	                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				  			</th>
				  			<th 
				  				*ngIf="col.pipe!='checkbox'"
				  				pReorderableColumn 
				  				pResizableColumn
				  				[pSortableColumn]="col.field"
				  				[style.width]="col.width + ' !important'"
				  				[style.max-width]="col.width + ' !important'"
				  				>
				  				<div class="p-d-flex p-jc-between p-ai-center">
				  					{{ col.header }}
	                </div>
	                <p-columnFilter *ngIf="col.type!=='dropdown' && col.type!=='yesno'" type="{{ col.type }}" field="{{ col.field }}" display="menu" id="filter"></p-columnFilter>
	                <p-columnFilter *ngIf="col.type=='dropdown'" type="{{ col.type }}" field="{{ col.field }}" matchMode="equals" operator="or" display="menu" id="filter">
	                	<ng-template pTemplate="filter" let-value let-filter="filterCallback">
	                    <p-dropdown *ngIf="col.pipe!=='instalment' && col.pipe!=='titlecase' && col.pipe!=='date' && col.pipe!=='dateshort' && col.pipe!=='dateyear'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='titlecase'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | titlecase }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | titlecase }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='instalment'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | instalment }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | instalment }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='date'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | datemod:'MMM d, EEE' }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | date:'MMM d, EEE' }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='dateshort'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | datemod:'MMM d' }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | date:'MMM d' }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='dateyear'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | datemod:'MMM d, y' }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | date:'MMM d, y' }}
	                      </ng-template>
	                    </p-dropdown>
	                  </ng-template>
	                </p-columnFilter>
	                <p-columnFilter *ngIf="col.type=='yesno'" type="boolean" field="{{ col.field }}" display="menu"></p-columnFilter>
				  				<p-sortIcon [field]="col.field"></p-sortIcon>
				  			</th>
				  		</ng-container>
			  		</tr>
			  	</ng-template>
			  	<ng-template pTemplate="emptymessage" let-columns>
					  <tr>
					    <td [attr.colspan]="columns.length">
					       No records found - Check filters
					    </td>
					  </tr>
					</ng-template>
			  	<ng-template pTemplate="body" let-rowData let-columns="columns">
			  		<tr 
			  			[pSelectableRow]="rowData"
				  		[class.draft]="rowData['draft']"
				  		[class.complete]="rowData['complete']"
				  		[class.alternative]="rowData['alternative']"
				  		[class.actionrequired]="rowData['actionrequired']"
				  		[class.ordercomplete]="rowData['orderstatus']=='completed' || rowData['rowcolorgrey']"
				  		[class.dispatched]="rowData['orderstatus']=='dispatched' || rowData['rowcolorsand']"
				  		[class.paid]="rowData['orderstatus']=='paid' || rowData['paid']"
				  		[class.selected]="isSelected(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])"
				  		[class.concern]="rowData['concern']"
				  		[class.wasconcern]="rowData['wasconcern']"
				  		>
              <ng-container *ngFor="let col of columns">
	              <ng-container *ngIf="col.pipe=='checkbox' && this.tS.checkBoxes">              	
	              	<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'">
	              		<p-tableCheckbox *ngIf="rowData[col.disabled]==0" [value]="rowData" (click)="this.tS.boxChecked(this.tablename.toLowerCase(), this.dt1, rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])"></p-tableCheckbox>
	              		<ng-container *ngIf="this.tablename.toLowerCase()=='payments'">
	              			<i *ngIf="rowData[col.disabled]==0" class="pi pi-download iconhover ml-3" pTooltip="Click to select" tooltipPosition="bottom" (click)="this.tS.bankExport(rowData[col.field],rowData[col.disabled])"></i>
	              		</ng-container>
	              	</td>
	              </ng-container>
	              <ng-container *ngIf="col.pipe!=='checkbox'">
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='delivery'">{{ rowData[col.field] | delivery }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='instalment'">{{ rowData[col.field] | instalment }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='kg'">{{ rowData[col.field] | number:'1.2-2' | dashblank : ' kg' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='g'">{{ rowData[col.field] | number:'1.0-0' | dashblank : ' g' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='g1dp'">{{ rowData[col.field] | number:'1.1-1' | dashblank : ' g' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='number'">{{ rowData[col.field] | number:'1.2-2' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='integer' || col.pipe=='boxes'">{{ rowData[col.field] | number | dashblank }}</td>	  				
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='currency'">{{ fixFx(rowData[col.field], rowData[col.fxrate], rowData[col.currency]) | currency:this.tS.currency:'symbol-narrow' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='setcurrency'">{{ rowData[col.field] | currency:rowData[col.currency]:'symbol-narrow' | dashblank }}</td>			  				
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='date'">{{ rowData[col.field] | date:'MMM d, EEE' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='time'">{{ rowData[col.field] | date:'HH:mm' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='duration'">{{ rowData[col.field] | date:'H:mm' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dateshort'">{{ rowData[col.field] | date:'MMM d' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dateyear'">{{ rowData[col.field] | date:'MMM d, y' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='titlecase'">{{ rowData[col.field] | titlecase }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='percent'">{{ rowData[col.field] | percent:'1.2' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='alpha'">{{ rowData[col.field] | alpha }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='yesno'" class="p-text-center">
				  					<i class="pi" [ngClass]="{'true-icon pi-check-circle': rowData[col.field], 'false-icon pi-times-circle': !rowData[col.field]}"></i>
	                </td>
				  				<td (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dashblank'">{{ rowData[col.field] | dashblank }}</td>
				  				<td (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='none'">{{ rowData[col.field] }}</td>
				  				<td *ngIf="col.pipe=='button'"><button mdbBtn class="btn {{ rowData[col.btntype] }} tblbtn" *ngIf="rowData[col.field]==1" (click)="buttonAction(col.btnaction,rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])">{{ rowData[col.btntext] }}</button></td>
				  			</ng-container>
			  			</ng-container>
			  		</tr>
			  	</ng-template>
			  	<ng-template pTemplate="footer" let-rowData let-columns>
		        <tr>
		        	<ng-container *ngFor="let col of columns; let cIndex=index">
		        		<ng-container *ngIf="col.pipe!='checkbox' || (col.pipe=='checkbox' && this.tS.checkBoxes)">
			        		<td *ngIf="col.footer=='text'">{{ col.text }}</td>
			        		<td *ngIf="col.footer=='none'"></td>
			        		<ng-container *ngIf="col.footer!=='none' && col.footer!=='text'">
			        			<td *ngIf="col.pipe=='delivery'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}</td>
			        			<td *ngIf="col.pipe=='instalment'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | instalment }}</td>
					  				<td *ngIf="col.pipe=='kg'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.2-2' | dashblank : ' kg' }}</td>
					  				<td *ngIf="col.pipe=='g'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.0-0' | dashblank : ' g' }}</td>
					  				<td *ngIf="col.pipe=='g1dp'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.1-1' | dashblank : ' g' }}</td>
					  				<td *ngIf="col.pipe=='number'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.2-2' }}</td>
				  					<td *ngIf="col.pipe=='integer'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number | dashblank }}</td>
				  					<td *ngIf="col.pipe=='boxes'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number | dashblank }} Units</td>			  					
				  					<td *ngIf="col.pipe=='currency'">{{ (col.footer=='sum' ? +calTotal(col.field, 'fx') : (col.footer=='count' ? +calCount(col.field, 'fx') : +calWavg(col.field, 'fx') )) | currency:this.tS.currency:'symbol-narrow' | dashblank }}</td>			  					
				  					<td *ngIf="col.pipe=='setcurrency'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | currency:col.currency:'symbol-narrow' | dashblank }}</td>  				
					  				<td *ngIf="col.pipe=='date'">{{ rowData[col.field] | date:'MMM d, EEE' }}</td>		
					  				<td *ngIf="col.pipe=='time'">{{ rowData[col.field] | date:'HH:mm' | dashblank }}</td>		
					  				<td *ngIf="col.pipe=='duration'">{{ rowData[col.field] | date:'H:mm' | dashblank }}</td>
				  					<td *ngIf="col.pipe=='dateshort'">{{ rowData[col.field] | date:'MMM d' }}</td>
				  					<td *ngIf="col.pipe=='dateyear'">{{ rowData[col.field] | date:'MMM d, y' }}</td>
					  				<td *ngIf="col.pipe=='titlecase'">{{ rowData[col.field] | titlecase }}</td>
					  				<td *ngIf="col.pipe=='percent'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | percent:'1.2' | dashblank }}</td>
					  				<td *ngIf="col.pipe=='alpha'">{{ rowData[col.field] | alpha }}</td>
					  				<td *ngIf="col.pipe=='yesno'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}
		                </td>
					  				<td *ngIf="col.pipe=='none'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}</td>
					  				<td *ngIf="col.pipe=='dashblank'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | dashblank }}</td>
					  			</ng-container>
		        		</ng-container>
		        	</ng-container>
		        </tr>
		    	</ng-template>
			  </p-table>
			</div>
			<div *ngIf="this.tS.clickedIndex[this.tablename.toLowerCase()]!==null" class="container">
				<ng-container *ngIf="this.tS.loadReady(this.tablename)">
					<br><br>
					<ng-container *ngIf="this.component=='delivery'">
						<app-teamdelivery></app-teamdelivery>
						<div class="row justify-content-between">
							<div class="col-xs-6 ml-3"></div>
							<div class="mr-4 ml-3">
								<button mdbBtn class="btn btn-primary" (click)="validateDelivery()" *ngIf="this.tS[this.tablename.toLowerCase()][this.tS.clickedIndex[this.tablename.toLowerCase()]]?.status=='draft'">Validate Delivery</button>
							</div>
						</div>
						<br>
						<br>
					</ng-container>
					<ng-container *ngIf="this.component=='depotdelivery'"><app-depotdelivery></app-depotdelivery></ng-container>
					<ng-container *ngIf="this.component=='payment' && !this.tS.checkBoxes"><app-payment></app-payment></ng-container>
					<ng-container *ngIf="this.component=='company'"><app-teamcompany></app-teamcompany></ng-container>
					<ng-container *ngIf="this.component=='contact'"><app-teamcontact></app-teamcontact></ng-container>
					<ng-container *ngIf="this.component=='order'"><app-teamorder></app-teamorder></ng-container>
					<ng-container *ngIf="this.component=='booking'"><app-teambooking></app-teambooking></ng-container>
					<ng-container *ngIf="this.component=='stock'"><app-stock></app-stock></ng-container>
				</ng-container>
			</div>
			<div *ngIf="this.tS.selectedRows!=null" class="container">
				<ng-container *ngIf="this.tS.selectedRows.length>0">
					<ng-container *ngIf="this.component=='picking' && this.tS.checkBoxes">
						<br><br>
						<app-picking></app-picking>
					</ng-container>
					<ng-container *ngIf="this.component=='palletreview' && this.tS.checkBoxes">
						<br><br>
						<app-palletreview (reloadTable)="reloadTable($event)"></app-palletreview>
					</ng-container>
					<ng-container *ngIf="this.component=='palletaction' && this.tS.checkBoxes">
						<br><br>
						<app-palletaction (reloadTable)="reloadTable($event)"></app-palletaction>
					</ng-container>
					<ng-container *ngIf="isAuthorised('financeadmin')">
						<ng-container *ngIf="this.component=='payment' && this.tS.checkBoxes">
							<br><br>
							<app-batchpayment></app-batchpayment>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
			<ng-container *ngIf="this.component=='remittance'" [class.hidden]="this.tS.indexLink">
				<div *ngIf="this.tS.clickedIndex[this.tablename.toLowerCase()]!==null || this.tS.bpanel">
					<app-remittance></app-remittance>
				</div>
				<div *ngIf="this.tS.clickedIndex[this.tablename.toLowerCase()]!==null && !this.tS.bpanel">
					<br>
					<br>
					<div class="container">
						<div class="row justify-content-between">
							<div class="col main-header">
								<h2>Remittance Breakdown</h2>
							</div>
					    <mat-slide-toggle class="slider-margin slider-label mt-2 col-auto" [(ngModel)]="remittanceDetails">
					      Show Details
					    </mat-slide-toggle>
						</div>
					</div>
					<br>
					<br>
					<div *ngIf="remittanceDetails">
						<app-teamremittancedetail></app-teamremittancedetail>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>