import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Inventory, RootObject } from './traceinventory.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class TraceInventoryService{
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public clickedIndex: number;
  public batchClicked = true;
	public inventory: any;
	public totalboxes: number = 0;
	public iBatch: number = 0;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService
    ) {}

	getInventory(): Observable<RootObject[]> {
		this.postBody = {
			"name":"inventory",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}

	loadInventory() {
		this.postBody = {
			"name":"inventory",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

	loadInventoryGrouped() {
		this.postBody = {
			"name":"inventoryGrouped",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeGrouped(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.dataStore = rawData.response.result.reply;
	}

	storeGrouped(rawData) {
		this.inventory = rawData.response.result.reply;
		this.totalboxes = 0;
		for (var i = 0; i < this.inventory.length; i++) {
			this.totalboxes = this.totalboxes + +this.inventory[i]['boxes'];
		}
	}

	setBatch(idd: string) {
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (this.dataStore[i].batchcode == idd) {
				this.clickedIndex = i;
			}
		}
	}

}