<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6">
				<h2>Users Portal Access - User Activity</h2>
			</div>
		</div>
		<br>
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 col-9">
				<h3>
					<div class="dropdown" dropdown>
			      <a dropdownToggle mdbWavesEffect type="button" class="pl-0 nav-link dropdown-toggle waves-light" mdbWavesEffect>
			      {{ ( this.usersService.selectedContact=='0' ? 'All Users' : this.dropDownDataService.dropdown.internalContactList[this.usersService.cIndex]['firstname'] + ' ' + this.dropDownDataService.dropdown.internalContactList[this.usersService.cIndex]['lastname'] + ' [' + this.dropDownDataService.dropdown.internalContactList[this.usersService.cIndex]['companyname']  + ']' ) }}
			      <span class="caret"></span></a>
			      <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
			        <button
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.usersService.onSelected('0',0)">All Users</button>
			        <button *ngFor="let contact of this.dropDownDataService.dropdown.internalContactList; let cIndex=index"
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.usersService.onSelected(contact.idcontact,cIndex)">{{ contact.firstname }} {{ contact.lastname }} [{{ contact.companyname }}]</button>
			      </ul>
			    </div>
				</h3>
			</div>
			<div class="col-auto">
				<button mdbBtn class="btn btn-info" (click)="csv('activity')">CSV</button>
			</div>
		</div>
		

		<br>

		<div class="form-container" autocomplete="off">
		  <mat-form-field>
		    <input matInput placeholder="Search text" maxlength="45" autocomplete="random-search-text" id="search-text" [(ngModel)]="this.usersService.searchtext" (keyup)="this.usersService.filterTable()">
		  </mat-form-field>
		</div>

		<table class="table col" mdbTableScroll id="activity" [scrollY]="scroll" maxHeight="500" bordered="true">
			<tbody>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td></tr>
				<tr>
					<th>Table</th>
					<th>User</th>
					<th>Date</th>
					<th>Created Records</th>
					<th>Modified Records</th>
				</tr>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td></tr>
				<ng-container *ngIf="this.usersService.filteredData!==undefined">
					<ng-container *ngFor="let tables of this.usersService.filteredData['categories']; let ActivityIndex=index">
						<ng-container *ngIf="this.usersService.filteredData[tables['category']].length>0">
							<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td></tr>
							<tr>
								<td>{{ tables['category'] | titlecase }}</td>
								<td>{{ this.usersService.filteredData[tables['category']][0].name }}</td>
								<td>{{ this.usersService.filteredData[tables['category']][0].datemod | date:'EEE, MMM d, y' }}</td>		
								<td>{{ this.usersService.filteredData[tables['category']][0].count }}</td>			
								<td>{{ this.usersService.filteredData[tables['category']][0].countmod }}</td>
							</tr>
							<ng-container *ngFor="let table of this.usersService.filteredData[tables['category']]; let rowIndex=index">
								<tr *ngIf="rowIndex>0">		
									<td></td>
									<td>{{ table.name }}</td>
									<td>{{ table.datemod | date:'EEE, MMM d, y' }}</td>		
									<td>{{ table.count }}</td>			
									<td>{{ table.countmod }}</td>
								</tr>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td></tr>
			</tbody>
		</table>

		<br><br>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>