import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule, WavesModule, CardsModule } from 'angular-bootstrap-md';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
  	private loginService: LoginService,
    public portalService: PortalService
  	) { }

  ngOnInit() {
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

}
