import { Component, OnInit } from '@angular/core';

import { TeamLoginService } from './auth/login/teamlogin.service';

@Component({
  selector: 'teamapp-root',
  templateUrl: './teamapp.component.html',
  styleUrls: ['./teamapp.component.scss'],
  providers: []
})
export class TeamAppComponent {
  loadedFeature = 'login';

  constructor(
  	private loginService: TeamLoginService) {}

  ngOnInit() {
  }

  onNavigate(feature: any) {
  	this.loadedFeature = feature;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

}