import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { LoginService } from '../auth/login/login.service';
import { Announcement } from './announcement.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AnnouncementService{
	private postBody: {};
	httpRes: any;
	public loadedEntry: any;
	public announcements: Announcement[] = [];

	constructor(
		private http: HttpClient,
		private loginService: LoginService,
		private sanitizer: DomSanitizer) {}

	async getDropdown(dataGroup: string) {
		this.postBody = {
			"name": dataGroup,
			"param": {
				"producer": this.loginService.selectedmember,
				"season": +this.loginService.season,
				"email": this.loginService.email
			}
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('id_token')
				}
			}
		)
		return response.json();
	}

	getAnnouncement() {
		this.getDropdown('loadLastAnnouncement')
		  .then(response => {
		  	this.loginService.setSession(response);
				this.loadedEntry = response['response']['result']['reply'];
	  		this.loadForEdit();
	  	})
		  .catch(error => console.log(error));
	}

	loadForEdit(loadedEntry = this.loadedEntry) {
		for (var i = 0; i < loadedEntry.length; i++) {	
			loadedEntry[i].imgurl = false;
			if (loadedEntry[i].imageurl!==null) {
				loadedEntry[i].imgurl = true;
				loadedEntry[i].imageurl = this.sanitizer.bypassSecurityTrustResourceUrl(loadedEntry[i].imageurl);
			}
		}
		this.announcements = loadedEntry;
	}

	markRead(index) {
		this.logRead(index);
		this.announcements.splice(index,1);
	}

	logRead(index) {
		this.postBody = {
			"name": "markRead",
			"param":{
				"producer": this.loginService.selectedmember,
				"articleref": this.announcements[index]['articleref'],
				"email": this.loginService.email
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        } catch(e) {
	        	console.log("Message not successfully marked as read");
	        }
        },
        (error) => console.log(error)
      );
	}


}