import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { portalM } from '../../../auth/portal.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-palletreview',
  templateUrl: './palletreview.component.html',
  styleUrls: ['./palletreview.component.scss']
})
export class PalletReviewComponent implements OnInit {
	clickedIndex;
  dataStore;

  @Output() reloadTable: EventEmitter<String> = new EventEmitter();
  
  constructor(
    private loginService: TeamLoginService,
    public tableService: TableDataService,
    private router: Router,
    public dDS: DropDownDataService
    ) { }

  ngOnInit() {
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  async groupEdit(action) {
    var pallets = '';
    for (var i = 0; i < this.tableService.checkboxdata?.length; i++) {
      pallets = pallets + (pallets.length>0 ? ',' : '') + "'" + this.tableService.checkboxdata[i]['pallet'] + "'";
      pallets = pallets + (pallets.length>0 ? ',' : '') + "'" + portalM()['palletlines'][this.tableService.checkboxdata[i]['pallet'].substring(-1, 1)] + " " + this.tableService.checkboxdata[i]['pallet'].substring(0, -1) + "'";
    }
    var p = {
      "season": this.loginService.season,
      "pallets": pallets,
      "action":  action
    };
    var dataStore = await this.loginService.loadData('reviewPallets', p);
    this.reloadTable.emit('working');
    this.loginService.toast('Picking List', dataStore['message'], dataStore['messagetype'], 3000);
  }

}
