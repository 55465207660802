import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { CurrencyItem } from './add-currency.interface';


import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddCurrencyService{
	private postBody: {};
	httpRes: any;
	public d: Date = new Date(Date.now() - 864e5);
	public createform: boolean;
	public editmode: boolean = false;
	public loadedEntry: any;
	public date: string = this.d.toISOString().substring(0,10);
	public currency: string = "ZARUSD";
	public currencyedit: string = "ZARUSD";
	public currencydate: string;
	public note: string;
	public items: CurrencyItem[] = [
  	{"ratetype": "dayavg", "ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR", "rate": null},
  	{"ratetype": "12monthavg", "ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR", "rate": null}
	];

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dropDownDataService: DropDownDataService) {}

	addItem() {
		if (this.items.length<3) {
			this.items.push({
				"ratetype": "", 
				"ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR",
			 	"rate": null
			 });	
		}
	}

	removeItem(index: number) {
		this.items.splice(index, 1);
	}

	addCurrency() {
		this.fixDate();
		this.postBody = {
			"name": "addCurrency",
			"param":{
				"currency": this.currency,
				"season": this.loginService.season,
				"date": this.date,
				"items": this.items,		
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Currency log was not updated, please try update current entry instead",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	saveChanges() {
		this.fixDate();
		this.postBody = {
			"name": "updateCurrency",
			"param":{
				"currencydate": this.currencydate.slice(0,10),
				"currencyedit": this.currencyedit,
				"season": this.loginService.season,
				"date": this.date,
				"currency": this.currency,
				"items": this.items,
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Currency entry was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() { 
		this.postBody = {
			"name": "deleteCurrency",
			"param":{
				"currency": this.currency,
				"season": this.loginService.season,
				"date": this.date
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Currency entry was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  checkType(index,type) {
  	var ans = true;
		for (var i = 0; i < this.items.length; i++) {
			if (this.items[i]['ratetype']==type && index!==i) {
				ans = false;
			}
		}
		return ans;
	}

  resetForm(keepRef: boolean = false) {
    window.scroll(0,0);
    this.dropDownDataService.getDropdown("currencyList");
    this.date = this.d.toISOString().substring(0,10);
    this.currency = "ZARUSD";
		this.currencyedit = (keepRef ? this.currencyedit : "ZARUSD");
		this.currencydate = (keepRef ? this.currencydate : null);
    this.note = null;
    this.items = (keepRef ? [] : [
	  	{"ratetype": "dayavg", "ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR", "rate": null},
	  	{"ratetype": "12monthavg", "ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR", "rate": null}
		]);
  }

	async getDropdown(dataGroup: string, location: number = 1) {
		this.postBody = {
			"name": dataGroup,
			"param": {
				"location": location,
				"season": +this.loginService.season
			}
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				}
			}
		)
		return response.json();
	}

	getData() {
		this.getDropdown('currencyList')
		  .then(response => {
		  	this.loginService.setSession(response);
				if (response['response']['result']['reply'][this.currency]!==undefined) {
					this.loadedEntry = response['response']['result']['reply'];
		  		this.dropDownDataService.dropdown['currencyList'] = this.loadedEntry;
		  		this.currencydate = this.loadedEntry[this.currency][0]['date'];
		  		this.loadForEdit(this.loadedEntry);
		  	}
		  })
		  .catch(error => console.log(error));
	}

	loadForEdit(loadedEntry = this.loadedEntry) {
		this.currency = this.currencyedit;
		this.date = this.currencydate.substring(0,10);
		this.items = [];
		for (var i = 0; i < loadedEntry[this.currencyedit].length; i++) {
			if (loadedEntry[this.currencyedit][i]['date']==this.currencydate) {
				for (var j = 0; j < loadedEntry[this.currencyedit][i]['rates'].length; j++) {
					this.items.push({
						"ratetype": loadedEntry[this.currencyedit][i]['rates'][j]['ratetype'],
						"rate": +loadedEntry[this.currencyedit][i]['rates'][j]['rate'],
						"ratesource": loadedEntry[this.currencyedit][i]['rates'][j]['ratesource']
					 });
				}
			}
		}
		if (this.items == null) {
			this.items = [
		  	{"ratetype": "dayavg", "ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR", "rate": null},
		  	{"ratetype": "12monthavg", "ratesource": "https://www.bloomberg.com/quote/USDZAR:CUR", "rate": null}
			];
		}
	}

	fixDate() {
	  this.date = moment(this.date).format('YYYY-MM-DD');
	}



}