<div class="container">
	<div class="row justify-content-between">
		<div class="col-xs-6 main-header">
			<h3>Booking: {{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type | titlecase }} - {{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.companyname }} ({{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.status | titlecase }})</h3>
		</div>
		<div class="mr-4 ml-3 col-auto">
			<button mdbBtn class="btn btn-secondary" (click)="editBooking()">Edit Booking</button>
		</div>
	</div>
	<br>
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Producer</td>
				<td><a (click)="viewCompany(this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.idproducer)">{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.companyname }}</a></td>
			</tr>
			<tr>
				<td>Type</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type | titlecase }} - {{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.status | titlecase }}</td>
			</tr>
			<ng-container *ngIf="this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type=='collection'">
				<tr>
					<td>Vehicle</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.vehicle }}</td>
				</tr>
				<tr>
					<td>Driver</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.driver }}</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.notes!==null">
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Note</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.notes }}</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Date</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.date | date:'MMM d, EEE, y' }}</td>
			</tr>
			<tr>
				<td>Cultivar</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.cultivar }}</td>
			</tr>
			<tr>
				<td>Load</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.loadamount | number:'1.0-0' }}kg ({{ (this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.loadtype=='bigbag' ? 'Big Bags' : (this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.loadtype=='loose' ? 'Loose' : 'Small Bags' ) ) | titlecase }})</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td *ngIf="this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type=='drop-off'">Arrival Time</td>
				<td *ngIf="this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type=='collection'">Dispatch Time</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.date + ' ' + this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.time | date:'HH:mm' }}</td>
			</tr>
			<ng-container *ngIf="this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type=='collection'">
				<tr>
					<td>Arrival at Destination</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.date + ' ' + this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.loadtime | date:'HH:mm' }}</td>
				</tr>
				<tr>
					<td>Unloading</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.date + ' ' + this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.unloadtime | date:'HH:mm' }}</td>
				</tr>
			</ng-container>
			<tr>
				<td>Completion Time</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.date + ' ' + this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.completetime | date:'HH:mm' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngIf="this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.type=='collection'">
				<tr>
					<td>Exp. Return Travel Time</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.journeytime }}</td>
				</tr>
				<tr>
					<td>Exp. Time to Load</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.loadduration }}</td>
				</tr>
				<tr>
					<td>Exp. Time to Unload</td>
					<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.unloadduration }}</td>
				</tr>
			</ng-container>
			<tr>
				<td>Total Duration</td>
				<td>{{ this.tableService['bookings'][this.tableService.clickedIndex['bookings']]?.duration }}</td>
			</tr>			
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr><td></td><td></td></tr>
		</tbody>
	</table>
</div>