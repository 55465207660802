import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencypair'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: any): any {
    if (value === null) {
      return null;
    }
    return value.substring(0,3) + " / " + value.substring(3,6);
  }

}
