import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { DropDownDataService } from '../logging/dropdown.service';
import { Delivery, Contacts, RootObject } from '../../interfaces/deliveries.interface';
import { Tag } from '../../interfaces/dropdown.interface';
import { Cultivars } from '../../interfaces/companies.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class DepotDeliveryService{
	private postBody: {};
	httpRes: any;
	d: Date = new Date();
	public editmode: boolean = false;
	public dataStore: any;
	public clickedIndex: number = null;
	public overview: any = [];
	public totalDeliveries: number;
	public iddepot: number = 2;
	public iddeldepot: number;
	public idd: number;
	public iddelfarm: string;
	public iddeldepotTemp: number;
	public loadedEntry: Delivery;
	public thisCompanyContacts: Contacts[];
	public thisCompanyContact: string;
	public season: number;
	public depot: string = "";
	public delivery: number;
	public depotdate: string;
	public producer: string;
	public contact: string;
	public wnis: number;
	public cultivar: string;
	public dehusking: boolean = false;
	public huskmass: number;
	public rejecthuskmass: number;
	public depotsmalls: number;
	public depotwnis: number;
	public depotwnisd: number;
	public tags: Tag[] = [];
	public note: string; 
	public status: string = 'depot';
	public cultivars: Cultivars [] = [
  ];

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dropDownDataService: DropDownDataService) {}


	getDeliveries(): Observable<RootObject[]> {
		this.postBody = {
			"name":"depotDeliveries",
			"param":{
				"iddepot": +this.iddepot
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}

	loadDeliveries() {
		this.postBody = {
			"name":"depotDeliveries",
			"param":{
				"iddepot": +this.iddepot
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

	loadDepotOverview() {
		this.postBody = {
			"name":"loadDepotOverview",
			"param":{
				"iddepot": +this.iddepot
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.overview = response['response']['result']['reply'][0];
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.totalDeliveries = 0;
		this.dataStore = rawData.response.result.reply['depotdeliveries'];
		
		for (var i = 0; i < this.dataStore.length ; i++) {
			this.totalDeliveries = this.totalDeliveries + 1;
		}
	}

	addDelivery() {
		this.dropDownDataService.combineTags();
		if (true) {
			this.postBody = {
				"name": "addDepotDelivery",
				"param":{
					"iddepot": this.depot,
					"season": this.season,
					"iddelfarm": this.iddelfarm,
					"iddeldepot": this.delivery,
					"depotdate": this.depotdate,
					"producer": this.producer,
					"contact": this.thisCompanyContact,
					"cultivar": this.cultivar,
					"huskmass": this.huskmass,
					"rejecthuskmass": this.rejecthuskmass,
					"depotsmalls": (this.depotsmalls>0 ? this.depotsmalls : 0),
					"depotwnis": this.depotwnis,
					"depotwnisd": (this.depotwnisd==undefined ? 0 : this.depotwnisd),
					"dehusking": (this.dehusking ? 1 : 0),
					"tagserials": this.dropDownDataService.tagserials,
					"notes": (this.note == null ? "" : this.note)
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response) => {
	        	try {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Save Successful","Depot delivery " + this.delivery + " was successfully saved.");
		        	this.resetForm();
		        } catch(e) {
		        	this.loginService.toast("Save Unsuccessful","Depot delivery not saved!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
		}
	}
	
	loadForEdit() {
		this.postBody = {
			"name": "loadDepotDelivery",
			"param":{
				"iddeldepot": +this.iddeldepot
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply;
		this.idd = +this.loadedEntry.idd;
		this.iddelfarm = (this.loadedEntry.iddelfarm=='' ? null : this.loadedEntry.iddelfarm);
		this.season = +this.loadedEntry.season;
		this.depot = this.loadedEntry.iddepot;
		this.delivery = +this.loadedEntry.iddeldepot;
		this.depotdate = this.loadedEntry.depotdate;
		this.producer = this.loadedEntry.idproducer;
		this.thisCompanyContacts = this.loadedEntry.contacts;
		this.thisCompanyContact = this.loadedEntry.dcontact;
		this.cultivar = this.loadedEntry.dcultivar;
		this.dehusking = (+this.loadedEntry.dehusking == 1 ? true : false );
		this.huskmass = +this.loadedEntry.huskmass;
		this.rejecthuskmass = +this.loadedEntry.rejecthuskmass;
		this.depotsmalls = +this.loadedEntry.depotsmalls;
		this.depotwnis = +this.loadedEntry.depotwnis;
		this.depotwnisd = +this.loadedEntry.depotwnisd;
		this.dropDownDataService.tagserialsU = this.loadedEntry.tagserials;
		this.note = this.loadedEntry.note;
		this.cultivars = this.dropDownDataService.dropdown.cultivarProfileList[this.producer];
	  this.dropDownDataService.splitTags();
	}

	saveChanges() {
		this.dropDownDataService.combineTags();
		if (true) {
			this.iddeldepotTemp = this.iddeldepot;
			this.postBody = {
				"name": "updateDepotDelivery",
				"param":{
					"iddeldepot": +this.iddeldepot,
					"idd": +this.idd,
					"iddelfarm": this.iddelfarm,
					"iddepot": this.depot,
					"season": this.season,
					"depotdate": this.depotdate,
					"producer": this.producer,
					"contact": this.thisCompanyContact,
					"cultivar": this.cultivar,
					"dehusking": (this.dehusking ? 1 : 0),
					"huskmass": this.huskmass,
					"rejecthuskmass": this.rejecthuskmass,
					"depotsmalls": (this.depotsmalls>0 ? this.depotsmalls : 0),
					"depotwnis": this.depotwnis,
					"depotwnisd": (this.depotwnisd==undefined ? 0 : this.depotwnisd),
					"tagserials": this.dropDownDataService.tagserials,
					"notes": (this.note == null ? "" : this.note)
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	try {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Update Successful","Depot delivery number " + this.iddeldepotTemp + " was successfully updated.");
		        	this.editmode=false;
		        	this.resetForm();
		        } catch(e) {
		        	this.loginService.toast("Update Unsuccessful","Depot delivery " + this.iddeldepotTemp + " was not updated.",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
		}
	}

	deleteEntry() {
		this.postBody = {
			"name": "deleteDepotDelivery",
			"param":{
				"iddeldepot": +this.iddeldepot,
				"season": this.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful","Depot delivery number " + this.iddeldepot + " was successfully deleted.");
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Depot delivery " + this.iddeldepot + " was not deleted.",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
  	this.dropDownDataService.tags = [{"serial": ""}];
    this.dropDownDataService.getDropdown("newDepotDeliveryNumber",this.dropDownDataService.depot['idlocation']);
    this.dropDownDataService.getDropdown("depotList",this.dropDownDataService.depot['idlocation']);
    this.season = +this.d.getFullYear();
    this.iddelfarm = null;
    this.depotdate = this.d.toISOString().substring(0,10);
    this.producer = null;
    this.thisCompanyContacts = null;
    this.huskmass = null;
    this.rejecthuskmass = null;
    this.depotsmalls = null;
    this.depotwnis = null;
    this.depotwnisd = null;
    this.cultivar = null;
    this.dehusking = false;
    this.dropDownDataService.tagserials = "";
    this.dropDownDataService.tagserialsU = "";
    this.note = "";
    this.dropDownDataService.tags = [{"serial": ""}];
  }

	setDelivery(idd: number) {
		this.clickedIndex = null;
  	this.tags = [];
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (+this.dataStore[i].iddeldepot == idd) {
				this.clickedIndex = i;
				const serials = this.dataStore[i]['tagserials'].split(";");
		  	for (var j = 0; j < serials.length; j++) {
		  		if (serials[j].length !== 0) {
		  			this.tags.push({"serial": serials[j] });
		  		}
			  }
			}
		}
	}

	getFarmId(producer) {
		var id = null;
		if (this.dropDownDataService.dropdown.producerList!=undefined) {
			for (var i = 0; i < this.dropDownDataService.dropdown.producerList.length; i++) {
				if (this.dropDownDataService.dropdown.producerList[i]['idcompany']==producer) {
					id = (this.dropDownDataService.dropdown.producerList[i]['farmid']==null ? null : this.dropDownDataService.dropdown.producerList[i]['farmid']);
				}
			}
		}
		return id;
	}

}