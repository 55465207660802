<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ms-0 main-header">
				<h2 *ngIf="!aSS.editmode">Add Sample</h2>
				<h2 *ngIf="aSS.editmode">Edit Sample</h2>
			</div>
			<div class="mr-4 me-0 col-auto" *ngIf="!aSS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/samples">View Samples</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Sample</button>
			</div>
			<div class="mr-4 me-0 col-auto" *ngIf="aSS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/samples">View Samples</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Sample</button>
			</div>
		</div>
		<br>

		<div class="row justify-content-between">
			<div class="col-6" style="display: inline-block;">
			 	
				<div class="form-container">
					<!-- Assigned Sample -->
					<ng-container *ngIf="aSS.assignedSample">
						<!-- Edit Delivery Number -->
					  <mat-form-field *ngIf="aSS.editmode">
    					<mat-label>Delivery Number (selected)</mat-label>
					    <mat-select placeholder="Delivery Number (selected)" [(ngModel)]="this.aSS.iddelivery" (keydown.tab)="loadForEdit()">
					      <mat-option *ngFor="let delivery of this.dDDS.dropdown.sampleListComplete" #prod (click)="loadForEdit()" value="{{ delivery.iddelivery }}">{{ delivery.iddelivery | delivery }}</mat-option>
					    </mat-select>
					  </mat-form-field>
					</ng-container>

				  <ng-container *ngIf="!aSS.assignedSample">					  
						<!-- Edit Delivery Number -->
					  <mat-form-field *ngIf="aSS.editmode">
    					<mat-label>Saved Sample</mat-label>
					    <mat-select placeholder="Saved Sample" [(ngModel)]="this.aSS.ids" (keydown.tab)="loadForEdit()">
					      <mat-option *ngFor="let sample of this.dDDS.dropdown.sampleListUnassigned" (click)="loadForEdit()" [value]="sample.ids">{{ sample.sampleid }}</mat-option>
					    </mat-select>
					  </mat-form-field>
					</ng-container>

				</div>

			</div>
		</div>

		<hr *ngIf="aSS.editmode && editSelected()">

		<div class="row justify-content-between">
			<div class="col-6" style="display: inline-block;">
			 	
				<div class="form-container">
					<!-- Assigned Sample -->
					<ng-container *ngIf="aSS.assignedSample">

						<!-- Edit Delivery Type -->
					  <mat-form-field *ngIf="aSS.editmode && editSelected() && batchOn() && aSS.sampletype!='complete'">
    					<mat-label>Sample Type</mat-label>
					    <mat-select placeholder="Sample Type" [(ngModel)]="this.aSS.sampletype" (ngModelChange)="this.aSS.loadEntry()">
					      <mat-option *ngIf="+this.aSS.sampletypes['initial']" value="initial">Truck Sample</mat-option>
					      <mat-option *ngIf="+this.aSS.sampletypes['tested']" value="tested">Bulk Sample</mat-option>
					      <mat-option *ngIf="+this.aSS.sampletypes['production']" value="production">Batch Production</mat-option>
					    </mat-select>
					  </mat-form-field>

				  	<!-- Add Delivery Number ****** -->
					  <mat-form-field *ngIf="!aSS.editmode">
    					<mat-label>Delivery Number</mat-label>
					    <mat-select placeholder="Delivery Number" [(ngModel)]="this.aSS.addDelIndex" (ngModelChange)="this.aSS.setStatus()">
					    	<ng-container *ngIf="this.dDDS.dropdown.sampleListAdd!=undefined">
					      	<mat-option *ngFor="let sample of this.dDDS.dropdown.sampleListAdd['samples']; let i = index" value="{{ i }}">{{ sample.iddelivery | delivery }}</mat-option>
					      </ng-container>
					    </mat-select>
					  </mat-form-field>

					</ng-container>

				  <!-- Unassigned Sample -->
				  <ng-container *ngIf="!aSS.assignedSample">
				 	
					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Producer</mat-label>
					    <mat-select placeholder="Producer" [(ngModel)]="this.aSS.producer">
					      <mat-option *ngFor="let producer of this.dDDS.dropdown.producerList" #prod [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Cultivar</mat-label>
					    <mat-select placeholder="Cultivar" [(ngModel)]="this.aSS.cultivar">
					      <mat-option *ngFor="let cult of this.dDDS.dropdown.cultivarList" [value]="cult.idcultivar">{{ cult.cultivar }}</mat-option>
					    </mat-select>
					  </mat-form-field>

				  </ng-container>

					<!-- Add Delivery Type  ****** -->
					<ng-container *ngIf="!aSS.editmode && batchOn() && aSS.selectedNewDelivery!=undefined">
						<ng-container *ngIf="this.aSS.selectedNewDelivery['type']!=undefined">
						  <mat-form-field *ngIf="this.aSS.selectedNewDelivery['type']==1">
    						<mat-label>Sample Type</mat-label>
						    <mat-select placeholder="Sample Type" [(ngModel)]="this.aSS.sampletype">
						      <mat-option *ngIf="this.aSS.selectedNewDelivery['initial']==1" value="initial">Truck Sample</mat-option>
						      <mat-option *ngIf="this.aSS.selectedNewDelivery['tested']==1" value="tested">Bulk Sample</mat-option>
						      <mat-option *ngIf="this.aSS.selectedNewDelivery['production']==1 && this.aSS.selectedNewDelivery['tested']==0" value="production">Batch Production</mat-option>
						    </mat-select>
						  </mat-form-field>
						</ng-container>
					</ng-container>

				  <!-- Updated delivery number in EDIT -->
				  <mat-form-field *ngIf="aSS.editmode && editSelected() && aSS.assignedSample">
    				<mat-label>New Delivery Number</mat-label>
				    <mat-select placeholder="New Delivery Number" [(ngModel)]="this.aSS.iddeliveryTemp">
				      <mat-option value="{{ this.aSS.iddelivery }}">{{ this.aSS.iddelivery | delivery }}</mat-option>
				      <mat-option *ngFor="let sample of this.dDDS.dropdown.sampleList[this.aSS.sampletype]" value="{{ sample.iddelivery }}">{{ sample.iddelivery | delivery }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="editSelected()">
    				<mat-label>Date</mat-label>
				    <input matInput [matDatepicker]="date" [(ngModel)]="this.aSS.sampledate">
						<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
				    <mat-datepicker #date></mat-datepicker>
				  </mat-form-field>

				 </div>
			</div>

			<div class="col-6" style="display: inline-block;">

				<div *ngIf="editSelected()" class="d-flex justify-content-end" style="display: inline-block;height: 56px;margin-bottom: 22px;">
			  	<div class="row justify-content-between px-2">

						<p class="mat-form-field my-auto main-header">{{ this.aSS.assignedSample ? "Assigned Sample" : "Unassigned Sample" }}</p>

						<mat-slide-toggle class="slider-margin slider-label col-auto" [(ngModel)]="this.aSS.assignedSample"></mat-slide-toggle>

					</div>
				</div>

				<ng-container *ngIf="editSelected() && batchOn()">
					<mat-form-field style="width:100%">
    				<mat-label>Notes</mat-label>
					  <input type="text" matInput placeholder="Notes" [(ngModel)]="this.aSS.note">
					</mat-form-field>
				</ng-container>

			</div>

		</div>

		<ng-container *ngIf="aSS.assignedSample && editSelected()">			
			<div class="row justify-content-between">

				<div class="col-6" style="display: inline-block;">
					<div class="form-container">

					  <mat-form-field>
	  					<mat-label>Sample Verditct</mat-label>
					    <mat-select placeholder="Sample Verditct" [(ngModel)]="this.aSS.verdict" (keydown.tab)="loadForEdit()">
					      <mat-option value="accepted">Accepted</mat-option>
					      <mat-option value="rejected">Rejected</mat-option>
					    </mat-select>
					  </mat-form-field>

					</div>
				</div>

				<ng-container *ngIf="editSelected() && aSS.assignedSample && aSS.verdict=='rejected'">
					<div class="col-6" style="display: inline-block;">
						<div class="form-container">

						  <mat-form-field style="width:100%">
								<mat-label>Storage</mat-label>
						    <mat-select placeholder="Storage" [(ngModel)]="this.aSS.rejectdestination">
						    	<ng-container *ngFor="let storage of this.dDDS.dropdown.storageListWNIS">
						      	<mat-option value="{{ storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>

						</div>
					</div>
				</ng-container>
			
			</div>
		</ng-container>

		<ng-container *ngIf="editSelected() && ((batchOn() && this.aSS.sampletype!='production') || !batchOn())">

			<hr>

			<div>
				<div class="col-6" style="display: inline-block;">
				 	
					<div class="form-container">

					  <mat-form-field [class.errorcheck]="!checkwetdry() || this.aSS.swnis==null" *ngIf="this.aSS.sampletype=='initial'">
    					<mat-label>Sample Wet Mass (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Sample Wet Mass (g)" [(ngModel)]="this.aSS.swnis">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkwetdry()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
    					<mat-label>Sample Wet Mass (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Sample Wet Mass (g)" [(ngModel)]="this.aSS.swnis">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkwetdry() || !checktotalkernel()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'" [class.errorcheck]="this.aSS.sdnis==null">
    					<mat-label>Sample Dry Mass (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Sample Dry Mass (g)" [(ngModel)]="this.aSS.sdnis">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Wet Kernel Moisture (%)</mat-label>
					    <input [class.errorcheck]="!checkwetkernel()" matInput type="number" min="0" step="0.01" max="50" placeholder="Wet Kernel Moisture (%)" [(ngModel)]="this.aSS.wetkernel">
					  </mat-form-field>

					  <mat-form-field *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'" [class.errorcheck]="this.aSS.drykernel==null" [class.errorcheck]="!checkMoisture()">
    					<mat-label>Dry Kernel Moisture (%)</mat-label>
					    <input matInput type="number" min="0" step="0.01" max="50" placeholder="Dry Kernel Moisture (%)" [(ngModel)]="this.aSS.drykernel">
					  </mat-form-field>

					  <ng-container *ngIf="samplenissizes()">

						  <mat-form-field [class.errorcheck]="!checkdnis()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
	    					<mat-label>DNIS: 18-20mm (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="DNIS: 18-20mm (g)" [(ngModel)]="this.aSS.dnis18">
						  </mat-form-field>

						  <mat-form-field [class.errorcheck]="!checkdnis()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
	    					<mat-label>DNIS: 20-22mm (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="DNIS: 20-22mm (g)" [(ngModel)]="this.aSS.dnis20">
						  </mat-form-field>

						  <mat-form-field [class.errorcheck]="!checkdnis()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
	    					<mat-label *ngIf="validsize('24')">DNIS: 22-24mm (g)</mat-label>
	    					<mat-label *ngIf="!validsize('24') && validsize('25')">DNIS: 22-25mm (g)</mat-label>
	    					<mat-label *ngIf="!validsize('24') && !validsize('25')">DNIS: +22mm (g)</mat-label>
						    <input matInput type="number" min="0" [(ngModel)]="this.aSS.dnis22">
						  </mat-form-field>

						  <ng-container *ngIf="validsize('24')">
							  	<mat-form-field [class.errorcheck]="!checkdnis()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
		    					<mat-label>DNIS: +24mm (g)</mat-label>
							    <input matInput type="number" min="0" placeholder="DNIS: +24mm (g)" [(ngModel)]="this.aSS.dnis24">
							  </mat-form-field>
							</ng-container>

						  <ng-container *ngIf="validsize('25')">
							  <mat-form-field [class.errorcheck]="!checkdnis()" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
		    					<mat-label>DNIS: +25mm (g)</mat-label>
							    <input matInput type="number" min="0" placeholder="DNIS: +25mm (g)" [(ngModel)]="this.aSS.dnis25">
							  </mat-form-field>
							</ng-container>

						</ng-container>

					  <hr *ngIf="this.aSS.sampletype!='tested' && this.aSS.sampletype!='complete'" class="hr-spacer">

					  <mat-form-field [class.errorcheck]="!checktotalkernel() || this.aSS.shell==null" *ngIf="this.aSS.sampletype!='tested' && this.aSS.sampletype!='complete'" class="field-spacer">
    					<mat-label>Shells (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Shells (g)" [(ngModel)]="this.aSS.shell" (ngModelChange)="this.aSS.getTK()" (keyup)="this.aSS.getTK()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkstyles() || !checktotalkernel() || this.aSS.sk==null" *ngIf="this.aSS.sampletype!='tested' && this.aSS.sampletype!='complete'">
    					<mat-label>Sound Kernel (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Sound Kernel (g)" [(ngModel)]="this.aSS.sk" (ngModelChange)="this.aSS.getTK()" (keyup)="this.aSS.getTK()">
					  </mat-form-field>

					  <mat-form-field *ngIf="this.aSS.sampletype!='tested' && this.aSS.sampletype!='complete'">
    					<mat-label>Unsound Kernel (g)</mat-label>
					    <input matInput type="number" disabled placeholder="Unsound Kernel (g)" [ngModel]="this.aSS.uk">
					  </mat-form-field>

					  <mat-form-field *ngIf="this.aSS.sampletype!='tested' && this.aSS.sampletype!='complete'">
    					<mat-label>Darks & Onion Ring (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Darks & Onion Ring (g)" [(ngModel)]="this.aSS.dark" (ngModelChange)="this.aSS.getTK()" (keyup)="this.aSS.getTK()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checktotalkernel()" *ngIf="this.aSS.sampletype!='tested' && this.aSS.sampletype!='complete'">
    					<mat-label>"Total Kernel (g)</mat-label>
					    <input matInput type="number" disabled placeholder="Total Kernel (g)" [ngModel]="this.aSS.tk">
					  </mat-form-field>


					</div>
				</div>

				<div class="col-6" style="display: inline-block;">
					<div class="form-container">

					  <mat-form-field>
    					<mat-label>Old/Mould/Rotten (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Old/Mould/Rotten (g)" [(ngModel)]="this.aSS.mould" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Immature (sinkers) (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Immature (sinkers) (g)" [(ngModel)]="this.aSS.immature" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>791 Spot (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="791 Spot (g)" [(ngModel)]="this.aSS.spot791" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Early Stinkbug (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Early Stinkbug (g)" [(ngModel)]="this.aSS.earlystink" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Late Stinkbug (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Late Stinkbug (g)" [(ngModel)]="this.aSS.latestink" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Early Germination (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Early Germination (g)" [(ngModel)]="this.aSS.germination" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>FCM / Nut Borer (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="FCM / Nut Borer (g)" [(ngModel)]="this.aSS.nutborer" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Discolouration (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Discolouration (g)" [(ngModel)]="this.aSS.discolouration" (keyup)="this.aSS.getUK()" (ngModelChange)="this.aSS.getUK()">
					  </mat-form-field>   
					  
					</div>
				</div>

				<hr *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">

				<div class="col-6" style="display: inline-block;" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
					<div class="form-container">

					  <mat-form-field [class.errorcheck]="!checktotalkernel() ||  this.aSS.shell==null">
    					<mat-label>Shells (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Shells (g)" [(ngModel)]="this.aSS.shell" (ngModelChange)="this.aSS.getTK()" (keyup)="this.aSS.getTK()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkstyles() || !checktotalkernel() ||  this.aSS.sk==null">
    					<mat-label>Sound Kernel (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Sound Kernel (g)" [(ngModel)]="this.aSS.sk" (ngModelChange)="this.aSS.getTK()" (keyup)="this.aSS.getTK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Unsound Kernel (g)</mat-label>
					    <input matInput type="number" disabled placeholder="Unsound Kernel (g)" [ngModel]="this.aSS.uk">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Darks & Onion Ring (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Darks & Onion Ring (g)" [(ngModel)]="this.aSS.dark" (ngModelChange)="this.aSS.getTK()" (keyup)="this.aSS.getTK()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checktotalkernel()">
    					<mat-label>Total Kernel (g)</mat-label>
					    <input matInput type="number" disabled placeholder="Total Kernel (g)" [ngModel]="this.aSS.tk">
					  </mat-form-field>

					</div>
				</div>

				<div class="col-6" style="display: inline-block;" *ngIf="this.aSS.sampletype=='tested' || this.aSS.sampletype=='complete'">
					<div class="form-container">

					  <mat-form-field [class.errorcheck]="!checkstyles()">
    					<mat-label>Style 0 (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Style 0 (g)" [(ngModel)]="this.aSS.s0">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkstyles()">
    					<mat-label>Style 1 (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Style 1 (g)" [(ngModel)]="this.aSS.s1">
					  </mat-form-field>

					  <ng-container *ngIf="this.aSS.stylemode">

						  <mat-form-field [class.errorcheck]="!checkstyles()">
    						<mat-label>Style 1S (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="Style 1S (g)" [(ngModel)]="this.aSS.s1s">
						  </mat-form-field>

						  <!--
						  <mat-form-field [class.errorcheck]="!checkstyles()">
						    <input matInput type="number" min="0" placeholder="Style 2 (g)" [(ngModel)]="this.aSS.s2">
						  </mat-form-field>
							-->
							
						</ng-container>

					  <mat-form-field [class.errorcheck]="!checkstyles()">
    					<mat-label>Style 4L (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Style 4L (g)" [(ngModel)]="this.aSS.s4l">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkstyles()">
    					<mat-label>Style 4R (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Style 4R (g)" [(ngModel)]="this.aSS.s4r">
					  </mat-form-field>

					  <ng-container *ngIf="!this.aSS.stylemode">
						  <mat-form-field [class.errorcheck]="!checkstyles()">
    						<mat-label>Style 5,6,7 (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="Style 5,6,7 (g)" [(ngModel)]="this.aSS.s567">
						  </mat-form-field>
						</ng-container>

					  <ng-container *ngIf="this.aSS.stylemode">

						  <mat-form-field [class.errorcheck]="!checkstyles()">
    						<mat-label>Style 5 (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="Style 5 (g)" [(ngModel)]="this.aSS.s5">
						  </mat-form-field>

						  <mat-form-field [class.errorcheck]="!checkstyles()">
    						<mat-label>Style 6 (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="Style 6 (g)" [(ngModel)]="this.aSS.s6">
						  </mat-form-field>

						  <mat-form-field [class.errorcheck]="!checkstyles()">
    						<mat-label>Style 7 (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="Style 7 (g)" [(ngModel)]="this.aSS.s7">
						  </mat-form-field>

						  <mat-form-field [class.errorcheck]="!checkstyles()">
    						<mat-label>Style 8 (g)</mat-label>
						    <input matInput type="number" min="0" placeholder="Style 8 (g)" [(ngModel)]="this.aSS.s8">
						  </mat-form-field>

						</ng-container>

					</div>
				</div>

				<hr>
				
				<button *ngIf="!this.aSS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="saveSample()">Save</button>
				
				<button *ngIf="this.aSS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.aSS.saveChanges()">Update</button>
				
				<button *ngIf="this.aSS.editmode" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

				<br><br>
			</div>

		</ng-container>

		<ng-container *ngIf="editSelected() && batchOn() && this.aSS.sampletype=='production'">

			<hr>

			<div>
				<div class="col-md-6" style="display: inline-block;">
				 	
					<div class="form-container">

					  <mat-form-field [class.errorcheck]="!checkMoisture()">
    					<mat-label>Dry Kernel Moisture (%)</mat-label>
					    <input matInput type="number" step="0.01" min="0" max="50" placeholder="Dry Kernel Moisture (%)" [(ngModel)]="this.aSS.promoisture">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Total DNIS (kg)</mat-label>
					    <input matInput type="number" min="0" placeholder="Total DNIS (kg)" [(ngModel)]="this.aSS.prodnis" (ngModelChange)="this.aSS.getcracked()" (keyup)="this.aSS.getcracked()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Unsound (unusable kg)</mat-label>
					    <input matInput type="number" min="0" placeholder="Unsound (unusable kg)" [(ngModel)]="this.aSS.prous" (ngModelChange)="this.aSS.getcracked()" (keyup)="this.aSS.getcracked()">
					  </mat-form-field>

					</div>
				</div>

				<div class="col-md-6" style="display: inline-block;">
				 	
					<div class="form-container">

					  <mat-form-field>
    					<mat-label>No Cracks (kg)</mat-label>
					    <input matInput type="number" min="0" placeholder="No Cracks (kg)" [(ngModel)]="this.aSS.pronocrack" (ngModelChange)="this.aSS.getcracked()" (keyup)="this.aSS.getcracked()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Smalls (kg)</mat-label>
					    <input matInput type="number" min="0" placeholder="Smalls (kg)" [(ngModel)]="this.aSS.prosmalls" (ngModelChange)="this.aSS.getcracked()" (keyup)="this.aSS.getcracked()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Cracked DNIS (kg)</mat-label>
					    <input matInput type="number" disabled placeholder="Cracked DNIS (kg)" [(ngModel)]="this.aSS.procracked">
					  </mat-form-field>

					</div>
				</div>
				
				<hr>

	      <div *ngFor="let item of this.aSS.items; let itemindex=index">

					<div class="col-6" style="display: inline-block;">
						<div class="form-container">

						  <mat-form-field>
    						<mat-label>Style</mat-label>
						    <mat-select placeholder="Style" [(ngModel)]="this.aSS.items[itemindex]['style']" (ngModelChange)="this.aSS.setTotal(itemindex)">
						    	<ng-container *ngFor="let product of this.dDDS.dropdown['productList']">
						    		<mat-option *ngIf="this.aSS.checkStyle(itemindex,product.style)" value="{{ product.style }}">{{ product.style }}</mat-option>
						    	</ng-container>
						    	<!--
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S0')" value="S0">S0</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S1')" value="S1">S1</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S1S')" value="S1S">S1S</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S2')" value="S2">S2</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S4L')" value="S4L">S4L</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S4')" value="S4">S4</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S4R')" value="S4R">S4R</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S4AR')" value="S4AR">S4AR</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S4S')" value="S4S">S4S</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S5')" value="S5">S5</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S6')" value="S6">S6</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S7')" value="S7">S7</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'S8')" value="S8">S8</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C0')" value="C0">C0</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C1')" value="C1">C1</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C1S')" value="C1S">C1S</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C2')" value="C2">C2</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C4L')" value="C4L">C4L</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C4')" value="C4">C4</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C4R')" value="C4R">C4R</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C4S')" value="C4R">C4S</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C5')" value="C5">C5</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C6')" value="C6">C6</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C7')" value="C7">C7</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'C8')" value="C8">C8</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'COM')" value="COM">Commercial</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'OIA')" value="FEE">Feed Grade</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'OIA')" value="OIA">Oil Grade A</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'OIB')" value="OIB">Oil Grade B</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'OIM')" value="OIM">Oil Grade Meal</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'N18')" value="N18">Nut-in-Shell > 18mm</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'N20')" value="N20">Nut-in-Shell > 20mm</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'N22')" value="N22">Nut-in-Shell > 22mm</mat-option>
						      <mat-option *ngIf="this.aSS.checkStyle(itemindex,'N24')" value="N24">Nut-in-Shell > 24mm</mat-option>
						    -->
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field *ngIf="this.aSS.items[itemindex]['style'].slice(0,1)=='N'">
    						<mat-label>Sound Kernel > %</mat-label>
						    <mat-select placeholder="Sound Kernel > %" [(ngModel)]="this.aSS.items[itemindex]['skr']">
						      <mat-option value=0.26>26%</mat-option>
						      <mat-option value=0.27>27%</mat-option>
						      <mat-option value=0.28>28%</mat-option>
						      <mat-option value=0.29>29%</mat-option>
						      <mat-option value=0.30>30%</mat-option>
						      <mat-option value=0.31>31%</mat-option>
						      <mat-option value=0.32>32%</mat-option>
						      <mat-option value=0.33>33%</mat-option>
						      <mat-option value=0.34>34%</mat-option>
						      <mat-option value=0.35>35%</mat-option>
						      <mat-option value=0.36>36%</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Surplus Weight (kg)</mat-label>
						    <input matInput type="number" min="0" step="1" placeholder="Surplus Weight (kg)" [(ngModel)]="this.aSS.items[itemindex]['extra']" (ngModelChange)="this.aSS.setTotal(itemindex)">
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Total (kg)</mat-label>
						    <input matInput type="number" disabled placeholder="Total (kg)" [(ngModel)]="this.aSS.items[itemindex]['total']">
						  </mat-form-field>

						</div>
					</div>
						 
					<div class="col-6" style="display: inline-block;">
						<div class="form-container">

						  <mat-form-field>
    						<mat-label>Weight per item (kg)</mat-label>
						    <input matInput type="number" min="0" step="1" placeholder="Weight per item (kg)" [(ngModel)]="this.aSS.items[itemindex]['weight']" (ngModelChange)="this.aSS.setTotal(itemindex)" (keyup)="this.aSS.setTotal(itemindex)">
						  </mat-form-field>

						  <mat-form-field *ngIf="this.aSS.items[itemindex]['style'].slice(0,1)!='N'">
    						<mat-label>Quantity</mat-label>
						    <input matInput type="number" min="0" step="1" placeholder="Quantity" [(ngModel)]="this.aSS.items[itemindex]['quantity']" (ngModelChange)="this.aSS.setTotal(itemindex)" (keyup)="this.aSS.setTotal(itemindex)">
						  </mat-form-field>

						  <mat-form-field *ngIf="this.aSS.items[itemindex]['style'].slice(0,1)=='N'">
    						<mat-label>Unsound Kernel < %</mat-label>
						    <mat-select placeholder="Unsound Kernel < %" [(ngModel)]="this.aSS.items[itemindex]['uskr']">
						      <mat-option value=0.00>0%</mat-option>
						      <mat-option value=0.01>1%</mat-option>
						      <mat-option value=0.02>2%</mat-option>
						      <mat-option value=0.03>3%</mat-option>
						      <mat-option value=0.04>4%</mat-option>
						      <mat-option value=0.05>5%</mat-option>
						      <mat-option value=0.06>6%</mat-option>
						      <mat-option value=0.07>7%</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field *ngIf="this.aSS.items[itemindex]['style'].slice(0,1)=='N'">
    						<mat-label>Bags</mat-label>
						    <input matInput type="number" min="0" step="1" placeholder="Bags" [(ngModel)]="this.aSS.items[itemindex]['quantity']" (ngModelChange)="this.aSS.setTotal(itemindex)" (keyup)="this.aSS.setTotal(itemindex)">
						  </mat-form-field>

						  <div class="row">
							  <button mat-icon-button class="col" (click)="this.aSS.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
							  <button mat-icon-button class="col" *ngIf="this.aSS.items.length>1" (click)="this.aSS.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
							</div>

						</div>
					</div>

					<hr>

				</div>

				<button *ngIf="!this.aSS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="saveSample()">Save</button>
				
				<button *ngIf="this.aSS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.aSS.saveChanges()">Update</button>
				
				<button *ngIf="this.aSS.editmode" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

				<br><br>
			</div>
		</ng-container>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>