import { Component, VERSION, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { PortalService } from '../../../auth/portal.service';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { CsvService } from '../../../services/csv.service';
import { TeamQRService } from '../qr.service';
import { QRCode } from '../../../interfaces/qr.interface';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';

@Component({
  selector: 'app-qr-inspect',
  templateUrl: './inspect-qr.component.html',
  styleUrls: ['./inspect-qr.component.scss']
})
export class InspectQrComponent implements OnInit {
	///

  ngVersion = VERSION.full;
  @ViewChild('scanner', { static: true })
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  ///


  ///

  cover = "cover";
  torch = true;
  classState = '';
  logInState = '';
  codeData: QRCode;
	
  constructor(
    public pS: PortalService,
    public teamQRService: TeamQRService,
    public loginService: TeamLoginService,
    public dropDownDataService: DropDownDataService,
    public csvService: CsvService,
    ) { }

  ngOnInit() {
    this.teamQRService.mode = 'inspect';
    this.onLoad();
    this.teamQRService.reset();
  }

  ngOnDestroy() {
    this.teamQRService.reset();
    this.teamQRService.orderref = '';
    this.teamQRService.mode = 'add';
  }

  async onLoad() {
    this.dropDownDataService.dropdown["scanDestinations"] = await this.loginService.loadData('scanDestinations');
    this.dropDownDataService.dropdown["dispatchStyles"] = await this.loginService.loadData('dispatchStyles');
    this.dropDownDataService.dropdown["storageListWNIS"] = await this.loginService.loadData('storageListWNIS');
    this.dropDownDataService.dropdown["productList"] = await this.loginService.loadData('productList');
  }

  isAuthenticated() {
    this.logInState = (this.loginService.isAuthenticated() ? "loggedIn" : "loggedOut" );
    return this.loginService.isAuthenticated();
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  async handleQrCodeResult(resultString: string) {
    this.codeData = {code:''};
    this.qrResultString = resultString;
    this.codeData['code'] = resultString;
    await this.teamQRService.addCode(this.codeData);
    this.classState = (this.teamQRService.recall ? "codeRecall" : (this.teamQRService.save ? "codeSave" : "codeRead" ));
    setTimeout(() => {
      this.classState = "";
    }, 500);
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  setCameras(event) {
    this.availableDevices = event;
    this.currentDevice = this.availableDevices[0];
    for (const device of event) {
      if (/back|rear|environment/gi.test(device.label)) {
        this.scanner.device = device;
        this.currentDevice = device;
        break;
      }
    }
    this.scanner.hasDevices.subscribe((x: boolean) => this.hasDevices = x);
    this.scanner.scanComplete.subscribe((x: Result) => this.qrResult = x);
    this.scanner.permissionResponse.subscribe((x: boolean) => this.hasPermission = x);
  }

  downloadCSV() {
    this.csvService.rawCSV('QR Scanner report', Object.keys(this.teamQRService.qrCodes[0]), this.teamQRService.qrCodes);
  }

}
