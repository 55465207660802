import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {
  clickedIndex;
  dataStore;

  constructor(
    private loginService: TeamLoginService,
    public tableService: TableDataService,
    public dropDownDataService: DropDownDataService
    ) { }

  ngOnInit() {
    this.dropDownDataService.getDropdown("scanDestinations");
     if (this.tableService.clickedIndex['assignedinventory']!=undefined) {
      var p = {
        "name": 'orderRefList',
        "param": {
          "location": 1,
          "season": +this.loginService.season,
          "date": '',
          "style": this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]['style']
        }
      };
      this.dropDownDataService.getDropdown("orderRefList",1,'',p);
    } else {
      this.dropDownDataService.getDropdown("orderRefList");
    }
  }

  async editStock(action) {
    var update = true;
    if (action.toLowerCase()=='remove') {
      update = await this.loginService.confirm("Are you sure you want to remove all the selected stock?");
    }
    if (update) {
      var p = {
        "season": this.loginService.season,
        "action": action.toLowerCase(),
        "idinventory": this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']].idinventory,
        "destination": this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']].destination,
        "orderref": this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']].orderref,
      };
      var dataStore = await this.loginService.loadData('bulkStockEdit', p);
      this.tableService.clickedIndex['assignedinventory'] = null;
      this.tableService.clickedId = [];
      this.loginService.toast('Bulk Stock: ' + action, dataStore['message'], dataStore['messagetype'], 3000);
      this.tableService.loadData('assignedinventory');
    } else {
      this.loginService.toast('Bulk Stock: ' + action, 'No changes were made.', 'info', 3000);
    }
  }

  

}