import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { Inventory, RootObject } from './traceinventory.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TraceInventoryService } from './traceinventory.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-traceinventory',
  templateUrl: './traceinventory.component.html',
  styleUrls: ['./traceinventory.component.scss']
})
export class TraceInventoryComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [
    'style',
    'variety',
    'boxes',
    'grade',
    'location'
    ];

  inventoryDB: TraceInventoryService | null;
  dataSource: Inventory[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
  	public inventoryService: TraceInventoryService,
    public csvService: CsvService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
    this.updateTable();
  }

  ngOnInit() {
  	this.inventoryService.clickedIndex = null;
  	this.inventoryService.loadInventory();
    window.scrollTo(0,0);
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    this.inventoryService.loadInventory();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.inventoryService.clickedIndex = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
    this.updateTable();
  }

  onSelectRow(i: number) {
    this.inventoryService.batchClicked = false;
    this.inventoryService.iBatch = 0;
    this.clickedId = this.table['_data'][i]['variety'].slice(0,1) + this.table['_data'][i]['style'].slice(0,-1);
    this.inventoryService.setBatch(this.clickedId);
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.inventoryService.clickedIndex !== null;
  }

  updateTable() {
    this.inventoryDB = new TraceInventoryService(this.http, this.loginService);
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.inventoryDB!.getInventory();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply;
          this.datasrc = new MatTableDataSource(this.dataSource);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Inventory');
  }

  calTotal(field: string){ 
    var total = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + +this.datasrc.filteredData[i][field];
    }
    return total;
  }

}