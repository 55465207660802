import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { AddPaymentService } from '../../logging/add-payment/add-payment.service';

@Component({
  selector: 'app-batchpayment',
  templateUrl: './batchpayment.component.html',
  styleUrls: ['./batchpayment.component.scss']
})
export class TeamBatchPaymentComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
    private loginService: TeamLoginService,
    public tableService: TableDataService,
    private router: Router,
    private addPaymentService: AddPaymentService,
    public dropDownDataService: DropDownDataService
    ) { }

  ngOnInit() {

  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  changeAmountPaid($event) {
    this.tableService.batchpay['newpaid'] = $event.replace(/[^\/\d.]/g,'');
    this.tableService.boxChecked('payments','idpayment',true);
  }

  amountToPay() {
    var topay: number = 0;
    for (var i=0; i<this.tableService.selectedRows.length; i++) {
      topay += +this.tableService.selectedRows[i]['amountoutstanding'];
    }
    return topay;
  }

  amountDue() {
    var totaldue: number = 0;
    for (var i=0; i<this.tableService.selectedRows.length; i++) {
      totaldue += +this.tableService.selectedRows[i]['amountdue'];
    }
    return totaldue;
  }

  async updatePayment() {
    var p = {
      "paiddate": this.tableService.batchpay['paiddate'],
      "iddelivery": this.tableService.batchpay['iddelivery'],
      "instalment": this.tableService.batchpay['instalment'],
      "idpayment": this.tableService.batchpay['idpayment'],
      "newpaid": this.tableService.batchpay['newpaid'],
      "paycurrency": this.tableService.batchpay['paycurrency'],
      "fxrate": this.tableService.batchpay['fxrate']
    };
    var reply = await this.loginService.loadData('quickPaymentUpdate',p);
    this.loginService.toast("Payment Update", reply['message'], reply['type'], 3000);
    this.tableService.loadData('payments');
  }

  async updatePayments() {
    const res = await this.loginService.confirm("Are sure you want to update all the selected " + this.tableService.selectedRows.length + " payments?");
    if (res) {
      var p = {
        "paiddate": this.tableService.batchpay['paiddate'],
        "payments": this.tableService.selectedRows
      };
      var reply = await this.loginService.loadData('bulkPaymentsUpdate',p);
      this.loginService.toast("Bulk Payment Update", reply['message'], reply['type'], 3000);
      this.tableService.loadData('payments');
      this.tableService.selectedRows = null;
    }
  }

}
