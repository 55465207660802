<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.addCurrencyService.editmode">Log Currency</h2>
				<h2 *ngIf="this.addCurrencyService.editmode">Edit Currency</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.addCurrencyService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/overview/currency">View Currencies</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Currency</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.addCurrencyService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/overview/currency">View Currencies</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Log Currency</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.addCurrencyService.editmode">
    			<mat-label>Currency</mat-label>
			    <mat-select placeholder="Currency" [(ngModel)]="this.addCurrencyService.currencyedit" (keydown.tab)="this.addCurrencyService.getData()">
			      <mat-option *ngFor="let currency of this.dropDownDataService.dropdown.currencyList['currencies']" value="{{ currency.currency }}">{{ currency.currency | currencypair }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field *ngIf="this.addCurrencyService.editmode">
    			<mat-label>FX Date</mat-label>
			    <mat-select placeholder="FX Date" [(ngModel)]="this.addCurrencyService.currencydate" (keydown.tab)="this.addCurrencyService.loadForEdit()">
			      <mat-option *ngFor="let currency of this.dropDownDataService.dropdown.currencyList[this.addCurrencyService.currencyedit]" #prod (click)="this.addCurrencyService.loadForEdit()" value="{{ currency.date }}">{{ currency.date | date }}</mat-option>
			    </mat-select>
		  	</mat-form-field>

		  	<br *ngIf="this.addCurrencyService.editmode && editSelected()">

				<mat-form-field *ngIf="editSelected()">
    			<mat-label>Currency</mat-label>
			    <mat-select placeholder="Currency" [(ngModel)]="this.addCurrencyService.currency">
			      <mat-option value="ZARUSD">ZAR / USD</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Date</mat-label>
			    <input matInput [matDatepicker]="date" [(ngModel)]="this.addCurrencyService.date">
					<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
			    <mat-datepicker #date></mat-datepicker>
			  </mat-form-field>

			  <ng-container *ngIf="editSelected()">

				  <hr>

				  <div *ngFor="let item of this.addCurrencyService.items; let itemindex=index">

		      	<hr *ngIf="editSelected() && itemindex>0">

						<div class="col-6" style="display: inline-block;">
							<div class="form-container">
							  
							  <mat-form-field>
    							<mat-label>Rate</mat-label>
							    <input matInput type="number" step="0.01" placeholder="Rate" [(ngModel)]="this.addCurrencyService.items[itemindex]['rate']">
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-6" style="display: inline-block;">
							<div class="form-container">

							  <mat-form-field>
    							<mat-label>Rate Type</mat-label>
							    <mat-select placeholder="Rate Type" [(ngModel)]="this.addCurrencyService.items[itemindex]['ratetype']">
							      <mat-option *ngIf="this.addCurrencyService.checkType(itemindex,'dayavg')" value="dayavg">Day Average</mat-option>
							      <mat-option *ngIf="this.addCurrencyService.checkType(itemindex,'30dayavg')" value="30dayavg">30 Day Rolling Average</mat-option>
							      <mat-option *ngIf="this.addCurrencyService.checkType(itemindex,'12monthavg')" value="12monthavg">12 Month Rolling Average</mat-option>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>

						<div class="col-12" style="display: inline-block;">
							<div class="form-container">

							  <mat-form-field>
    							<mat-label>Source</mat-label>
							    <input matInput placeholder="Source" [(ngModel)]="this.addCurrencyService.items[itemindex]['ratesource']">
							  </mat-form-field>

							</div>
						</div>

						<div class="col-6" style="display: inline-block;">
							<div class="form-container">
						  
							  <a href="{{this.addCurrencyService.items[itemindex]['ratesource']}}" target="_blank">Source Link</a>

							</div>
						</div>

						<div class="col-6" style="display: inline-block;">
							<div class="form-container">

							  <div class="row">
								  <button mat-icon-button class="col" *ngIf="this.addCurrencyService.items.length<3" (click)="this.addCurrencyService.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
								  <button mat-icon-button class="col" *ngIf="this.addCurrencyService.items.length>1" (click)="this.addCurrencyService.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
								</div>

							</div>
						</div>

					</div>

		      <hr>

				  <mat-form-field>
    				<mat-label>Notes</mat-label>
				    <textarea matInput placeholder="Notes" [(ngModel)]="this.addCurrencyService.note"></textarea>
				  </mat-form-field>

				</ng-container>
			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.addCurrencyService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.addCurrencyService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.addCurrencyService.saveChanges()">Update</button>

			<button *ngIf="this.addCurrencyService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>