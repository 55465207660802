import { Component, VERSION, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TeamQRService } from '../../qr/qr.service';
import { QRCode } from '../../../interfaces/qr.interface';
import { DropDownDataService } from '../../logging/dropdown.service';
import { TraceabilityService } from '../traceability.service';
import { CsvService } from '../../../services/csv.service';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';

@Component({
  selector: 'app-tracescan',
  templateUrl: './tracescan.component.html',
  styleUrls: ['./tracescan.component.scss']
})
export class TraceScanComponent implements OnInit {
	///

  ngVersion = VERSION.full;
  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  ///


  ///

  cover = "cover";
  torch = true;
  classState = '';
  logInState = '';
  codeData: QRCode;
  showScanner: string = "loggedOut";
  showTrace: string = "loggedOut";
	
  constructor(
    public teamQRService: TeamQRService,
    public loginService: TeamLoginService,
    public traceabilityService: TraceabilityService,
    public dropDownDataService: DropDownDataService,
    public csvService: CsvService
    ) { }

  ngOnInit() {
    this.teamQRService.reset();
    this.dropDownDataService.getDropdown('traceOrderList');
  }

  ngOnDestroy() {
    this.teamQRService.previousref = '';
    this.teamQRService.orderref = '';
    this.traceabilityService.orderref = '';
    this.traceabilityService.batch = null;
    this.traceabilityService.farmers = null;
    this.traceabilityService.orderref = '';
    this.traceabilityService.selectedStyle = null;
    this.traceabilityService.dataStore = null;
  }

  isAuthenticated() {
    this.logInState = (this.loginService.isAuthenticated() ? "loggedIn" : "loggedOut" );
    return this.loginService.isAuthenticated();
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    // console.debug('Devices: ', cameras);
    this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
    this.codeData = {code:''};
    this.qrResultString = resultString;
    this.codeData['code'] = resultString;
    this.teamQRService.logCode(this.codeData);
    this.classState = (this.teamQRService.save ? "codeSave" : "codeRead" );
    setTimeout(() => {
      this.classState = "";
    }, 500);
  }

  onDeviceSelectChange(selected: string) {
    // console.debug('Selection changed: ', selected);
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }
    
  ///

  scanDetect(event) {
  	this.teamQRService.logCode(event);
    this.classState = (this.teamQRService.save ? "codeSave" : "codeRead" );
    setTimeout(() => {
      this.classState = "";
    }, 500);
  }

  scanError(error) {
  }

  scanNotFound() {
  }

  loadForLogging() {
    this.traceabilityService.traceOrder();
    this.showTrace = (this.traceabilityService.orderref !== "" ? "loggedIn" : "loggedOut");
  }

  selectBatch(i: number) {
    this.traceabilityService.batch = this.traceabilityService.dataStore.batches[i]['batch'];
    this.traceabilityService.farmers = this.traceabilityService.dataStore.batches[i]['farmers'];
  }

  async scanForTrace() {
    if (this.teamQRService.previousref !== '' && this.teamQRService.previousref !== this.teamQRService.orderref) {
      const res = await this.loginService.confirm("Do yobu want to change the active Order Reference?");
      this.teamQRService.orderref = (res ? this.teamQRService.orderref : this.teamQRService.previousref);
    }
    this.teamQRService.previousref = this.teamQRService.orderref;
    this.teamQRService.fullOrder(this.teamQRService.orderref);
    this.showScanner = (this.teamQRService.orderref !== "" ? "loggedIn" : "loggedOut");
  }

  reset() {
    this.teamQRService.previousref = '';
    this.teamQRService.orderref = '';
    this.teamQRService.reset();
    this.showScanner = (this.teamQRService.orderref !== "" ? "loggedIn" : "loggedOut");
  }

  csv(datatable) {
    var thistable=document.getElementById(datatable);
    var title = 'Traceability: Batches for ' + this.traceabilityService.orderref;
    this.csvService.csvSimple(thistable, title);
  }


}
