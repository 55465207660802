import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { Title } from '@angular/platform-browser';
import { PortalService } from './auth/portal.service';
import { LoginService } from './client/auth/login/login.service';
import { LogisticsLoginService } from './logistics/auth/login/login.service';
import { TeamAuthService } from './team/auth/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class AppComponent {
  loadedFeature = 'login';
  pathFound: boolean = false;

  constructor(
    public titleService: Title,
    public loginService: LoginService,
    public LogisticsLoginService: LogisticsLoginService,
    public portalService: PortalService,
    public tauthService: TeamAuthService,
    public routingModule: AppRoutingModule,
    public router: Router,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.titleService.setTitle(this.portalService.portalMod()['portal'] + ' Portal');
    window.scrollTo(0,0);
  }

  ngAfterViewChecked() {
    if (!this.pathFound) {
      var child = this.route.snapshot.firstChild;
      if (child!==null) {
        this.pathFound = true;
        var portal = child.routeConfig.path;
        this.routingModule.routerUpdate(portal);
      }
    }
  }

  authDialog() {
    return this.tauthService.dialog;
  }

  onNavigate(feature: string) {
  	this.loadedFeature = feature;
  }

}