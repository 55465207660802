import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { Inventory, RootObject } from './inventory.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { InventoryService } from './inventory.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [];
  displayedColumnsAll: string [] = [
    '_batchdate',
    's0',
    's1l',
    's1',
    's1s',
    's2',
    's4l',
    's4',
    's4r',
    's4s',
    's5',
    's6',
    's7',
    's8',
    'com',
    'c0',
    'c1l',
    'c1',
    'c1s',
    'c2',
    'c4l',
    'c4',
    'c4r',
    'c4s',
    'c5',
    'c6',
    'c7',
    'c8',
    'oil',
    'oia',
    'oib',
    'oic',
    'oim',
    'bch',
    'n18',
    'n20',
    'n22',
    'n24',
    'n25',
    'n26',
    'n18bags',
    'n20bags',
    'n22bags',
    'n24bags',
    'n25bags',
    'n26bags'
    ];

  inventoryDB: InventoryService | null;
  dataSource: Inventory[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
  	public inventoryService: InventoryService,
    public csvService: CsvService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
    this.updateTable();
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.updateColumns();
  }

  ngOnDestory() {
    this.inventoryService.clickedIndex = null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    this.inventoryService.loadStock();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.inventoryService.clickedIndex = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
    this.updateTable();
  }

  onSelectRow(i: number) {
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.inventoryService.clickedIndex !== null;
  }

  updateColumns() {
    if (this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a') {
      var tempColumns = this.displayedColumnsAll
    } else {
      var tempColumns = ['_batchdate'];
      tempColumns.push( (this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p' ? 'stotal' : ( this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c' ? 'ctotal' : ( this.inventoryService.grouptype.substr(0,1).toLowerCase()=='r' ? 'rtotal' : 'nistotal' ))));
      for (var i = 0; i < this.displayedColumnsAll.length; i++) {
        if (this.displayedColumnsAll[i].substr(0,1)==(this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p' ? 's' : (this.inventoryService.grouptype.substr(0,1).toLowerCase()=='r' ? 'b' : this.inventoryService.grouptype.substr(0,1).toLowerCase() ) ) ) {
          tempColumns.push(this.displayedColumnsAll[i]); 
        }
      }
    }
    this.displayedColumns = tempColumns;
  }

  setType(type) {
    this.inventoryService.grouptype = type;
    this.updateColumns();
    this.updateTable();
  }

  setInventory(type) {
    this.inventoryService.inventorytype = type;
    this.inventoryService.invtype = (this.inventoryService.inventorytype=='In Stock' ? 'instock' : (this.inventoryService.inventorytype=='Dispatched' ? 'dispatched' : 'todate'));
    this.updateColumns();
    this.updateTable();
  }

  updateTable() {
    this.inventoryDB = new InventoryService(this.http, this.loginService);
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.inventoryDB!.getStock();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply[this.inventoryService.invtype];
          this.datasrc = new MatTableDataSource(this.dataSource);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Inventory');
  }

  calTotal(field: string){ 
    var total = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + +this.datasrc.filteredData[i][field];
    }
    return total;
  }

}