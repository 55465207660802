<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2>Remittance Rebuilder</h2>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

			  <mat-form-field>
			    <mat-select placeholder="Location" [(ngModel)]="this.rebuildService.location">
			      <mat-option *ngFor="let location of this.dropDownDataService.dropdown.locationList" [value]="location.idlocation">{{ location.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
			    <mat-select placeholder="Season" [(ngModel)]="this.rebuildService.season">
			      <mat-option *ngFor="let season of this.loginService.seasons" value="{{ +season }}">{{ season }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Producer</mat-label>
			    <mat-select placeholder="Producer" [(ngModel)]="this.rebuildService.producer">
			    	<mat-option value="0">All Producers</mat-option>
			      <mat-option *ngFor="let producer of this.dropDownDataService.dropdown.producerList" [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field>
    			<mat-label>Price Model</mat-label>
			    <mat-select placeholder="Price Model" [(ngModel)]="this.rebuildService.pricemodel">
			    	<mat-option value="0">Any</mat-option>
			      <mat-option *ngFor="let price of this.dropDownDataService.dropdown.priceModelListAll" [value]="price.modelno">{{ price.model }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
  				<mat-label>From Delivery Date</mat-label>
			    <input matInput [matDatepicker]="delstartdate" [(ngModel)]="this.rebuildService.delstartdate" (ngModelChange)="this.rebuildService.fixDate('delstartdate')">
					<mat-datepicker-toggle matIconSuffix [for]="delstartdate"></mat-datepicker-toggle>
			    <mat-datepicker #delstartdate></mat-datepicker>
			  </mat-form-field>

			  <hr>

				<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.rebuildService.deliveries">
	        Specific Deliveries?
	      </mat-slide-toggle>

	      <ng-container *ngIf="this.rebuildService.deliveries">

		      <div *ngFor="let item of this.rebuildService.items; let itemindex=index">

		      	<hr *ngIf="itemindex>0">

						<div class="col-6" style="display: inline-block;">
							<div class="form-container">

							  <mat-form-field>
				    			<mat-label>Delivery Number</mat-label>
							    <mat-select placeholder="Delivery Number" [(ngModel)]="this.rebuildService.items[itemindex]['iddelivery']">
							    	<ng-container *ngFor="let delivery of this.dropDownDataService.dropdown.sampleListComplete; let i=index">
							      	<mat-option *ngIf="this.rebuildService.checkIdd(itemindex, delivery['iddelivery'], i)" [value]="delivery.iddelivery">{{ delivery.iddelivery | delivery }}</mat-option>
							      </ng-container>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-6" style="display: inline-block;">
							<div class="form-container">

							  <div class="row">
								  <button mat-icon-button class="col" (click)="this.rebuildService.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
								  <button mat-icon-button class="col" *ngIf="this.rebuildService.items.length>1" (click)="this.rebuildService.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
								</div>

							</div>
						</div>

					</div>

				</ng-container>

				<hr *ngIf="this.rebuildService.customfx || this.rebuildService.deliveries">

				<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.rebuildService.customfx">
	        Custom FX Rate?
	      </mat-slide-toggle>

	      <ng-container *ngIf="this.rebuildService.customfx">

				  <mat-form-field>
				    <mat-label>FX Rate (ZAR/USD)</mat-label>
			    	<input matInput type="number" step="0.01" placeholder="FX Rate (ZAR/USD)" [(ngModel)]="this.rebuildService.fxrate">
				  </mat-form-field>

				  <hr>
				  
	      </ng-container>

				<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.rebuildService.selectoutonly">
	        Selectouts Only?
	      </mat-slide-toggle>

				<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.rebuildService.sendemail">
	        Send Remittance Change Email?
	      </mat-slide-toggle>

		    <hr>

			  <mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.rebuildService.reassignPriceModel">
	        Reassign Price Model?
	      </mat-slide-toggle>

	      <ng-container *ngIf="this.rebuildService.reassignPriceModel">

					<mat-form-field>
	    			<mat-label>New Price Model to Assign</mat-label>
				    <mat-select placeholder="New Price Model to Assign" [(ngModel)]="this.rebuildService.newpricemodel">
				    	<mat-option value="0">No reassignement</mat-option>
				      <mat-option *ngFor="let price of this.dropDownDataService.dropdown.priceModelListAll" [value]="price.modelno">{{ price.model }}</mat-option>
				    </mat-select>
				  </mat-form-field>
				  
	      </ng-container>

				<hr>

			  <mat-form-field>
					<mat-label>Max number of deliveries</mat-label>
			    <input matInput type="number" step="1" placeholder="Max number of rebuilds" [(ngModel)]="this.rebuildService.maxrebuilds">
			  </mat-form-field>
			  
			  <hr>

			</div>

			<button *ngIf="!this.rebuildService.loading" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Rebuild</button>
			<button *ngIf="this.rebuildService.loading" mdbBtn class="btn btn-primary" pTooltip="CSV Loading..." tooltipPosition="bottom" disabled><i class="pi pi-refresh pi-spin"></i></button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>