<div *ngIf="isAuthenticated()" class="container">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<div class="row justify-content-between">
					<div class="col main-header">
						<h2>
							<div class="dropdown" dropdown>
				        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
				        Silos - {{ this.siloService.nisview | uppercase }}<span class="caret"></span></a>
				        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="this.siloService.reloadChart('wnis')">WNIS</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="this.siloService.reloadChart('dnis')">DNIS</button>
				        </ul>
				      </div>
						</h2>
					</div>
					<div class="mr-4 ml-3 col-auto">
						<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
					</div>
				</div>
			</div>

			<div id="chartdiv" style="width: 100%; height: 500px"></div>
			
			<br>
			<mat-form-field class="filter-wide">
			  <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter">
			</mat-form-field>
			<div class="example-container mat-elevation-z8">
				<div class="example-loading-shade"
			       *ngIf="isLoadingResults">
			    <mat-spinner *ngIf="isLoadingResults" color="accent"></mat-spinner>
			  </div>
				<div class="example-table-container">
				  <table mat-table matSort [dataSource]="datasrc" hover="true" class="example-table">
				    <ng-container matColumnDef="name" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header class="alignleft"> Storage </th>
				      <td mat-cell *matCellDef="let element" class="alignleft"> {{ element.name }} </td>
				      <td mat-footer-cell *matFooterCellDef> Total </td>
				    </ng-container>
				  	<ng-container matColumnDef="dwnis">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> WNIS </th>
				      <td mat-cell *matCellDef="let element"> {{ element.dwnis | number:'1.2-2' | dashblank:' kg' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('dwnis') | number:'1.2-2' }}kg </td>
				    </ng-container>
				  	<ng-container matColumnDef="ddnis">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> DNIS </th>
				      <td mat-cell *matCellDef="let element"> {{ element.ddnis | number:'1.2-2' | dashblank:' kg' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('ddnis') | number:'1.2-2' }}kg </td>
				    </ng-container>
				  	<ng-container matColumnDef="sk">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> SK </th>
				      <td mat-cell *matCellDef="let element"> {{ element.ddnis * element.sound | number:'1.2-2' | dashblank:' kg' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('ddnis') * calWAvg('sound') | number:'1.2-2' }}kg </td>
				    </ng-container>
				  	<ng-container matColumnDef="date">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> 1st Load Date </th>
				      <td mat-cell *matCellDef="let element"> {{ element.date | date:'MMM d, EEE' }} </td>
				      <td mat-footer-cell *matFooterCellDef> </td>
				    </ng-container>
				  	<ng-container matColumnDef="deliveries">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Deliveries </th>
				      <td mat-cell *matCellDef="let element"> {{ element.deliveries }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('deliveries') }} </td>
				    </ng-container>
				  	<ng-container matColumnDef="samplesComplete">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> % Sampled </th>
				      <td mat-cell *matCellDef="let element"> {{ element.stwnis / element.dwnis | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('stwnis') / calTotal('dwnis') | percent:'1.2' | dashblank }} </td>
				    </ng-container>
				    <ng-container matColumnDef="sound" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sound </th>
				      <td mat-cell *matCellDef="let element"> {{ element.sound | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('sound') | percent:'1.2' }} </td>
				    </ng-container>
				   <ng-container matColumnDef="unsound">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Unsound </th>
				      <td mat-cell *matCellDef="let element"> {{ element.unsound  | percent:'1.2' | dashblank }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('unsound') | percent:'1.2' }} </td>
				    </ng-container>
				   	<ng-container matColumnDef="dark">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dark </th>
				      <td mat-cell *matCellDef="let element"> {{ element.dark  | percent:'1.2' | dashblank }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('dark') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="above18" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Above 18mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.above18 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('above18') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="above20" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Above 20mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.above20 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('above20') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="above22" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Above 22mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.above22 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('above22') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="above24" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Above 24mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.above24 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('above24') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="s0" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style 0 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s0 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('s0') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="s1" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style 1 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s1 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('s1') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="s4l" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style 4l </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4l | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('s4l') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="s4r" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style 4r </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4r | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('s4r') | percent:'1.2' }} </td>
				    </ng-container>
				    <ng-container matColumnDef="s567" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style 5,6,7 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s567 | percent:'1.2' | dashblank }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calWAvg('s567') | percent:'1.2' }} </td>
				    </ng-container>
				    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				    <tr mat-row 
				    	*matRowDef="let row; let i = index; columns: displayedColumns;" 
				    	(click)="onSelectRow(i)"
				    	style="cursor: pointer"
				    	class="example-element-row"></tr>
	 					<tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="example-first-footer-row"></tr>
				  </table>
				</div>
			</div>
			<br>
			<br>
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>