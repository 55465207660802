import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { AddCompanyService } from '../../logging/add-company/add-company.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-teamcompany',
  templateUrl: './teamcompany.component.html',
  styleUrls: ['./teamcompany.component.scss']
})
export class TeamCompanyComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
  	public loginService: TeamLoginService,
    public tableService: TableDataService,
    private router: Router,
    private dropDownDataService: DropDownDataService,
    private addCompanyService: AddCompanyService
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  editCompany() {
    this.addCompanyService.editCompany(this.tableService['companies'][this.tableService.clickedIndex['companies']].idcompany);
    this.router.navigate(['/team/logging/company']);
  }

  viewContact(idcontact) {
    this.tableService.reset('contacts');
    this.tableService.setDelivery(idcontact, 'contacts');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/contacts']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  viewCompany(idcompany) {
    this.tableService.reset('companies');
    this.tableService.setDelivery(idcompany, 'companies');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  viewOrder(orderref, season) {
    this.loginService.season = season;
    this.tableService.reset('orders');
    this.tableService.setDelivery(orderref, 'orders');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/orders']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  async reassignAll() {
    var reassign = await this.loginService.confirm("Are you sure you want to re-assign all " + this.loginService.season + " deliveries to the company's default price model? All remittances for this season will be rebuilt.")
    if (reassign) {
      var p = {
        "season": this.loginService.season,
        "producer": this.tableService['companies'][this.tableService.clickedIndex['companies']]?.idcompany
      };
      var reply = await this.loginService.loadData('reassignPM', p);
      this.loginService.toast("Season deliveries change", reply['message'], 'success', 3000);
    } else {
      this.loginService.toast("Season deliveries change","No changes were made.","warn");
    }
  }

}
