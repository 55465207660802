import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class SplitTermsService{
	public message: string;

	constructor(
		) {}

	addItem(list: any[], fields: any) {
		const thislist: any[] = list;
		thislist.push(fields);
		return thislist;
	}

	removeItem(list: any[], index: number) {
		list.splice(index, 1);
		return list;
	}

	splitTerms(paypercentU: string, paydayU: string, estimate:boolean = false) {
		var list = [];
  	var percents = (paypercentU==null ? null : paypercentU.split(";"));
  	var paydays = (paydayU==null ? null : paydayU.split(";"));
  	if (percents!==null) {
  		for (var i = 0; i < percents.length; i++) {
	  		if (percents[i].length == 0) {
	  			percents.splice(i, 1);
	  		}
	  	}
	  	for (var i = 0; i < paydays.length; i++) {
	  		if (paydays[i].length == 0) {
	  			paydays.splice(i, 1);
	  		}
	  	}
	  	for (var i = 0; i < percents.length; i++) {
	  		var dateanchor = 'delivery';
	  		if (paydays[i].substr(0,1)=='s') {
	  			paydays[i] = paydays[i].substr(1);
	  			dateanchor = 'sample';
	  		}
	  		var type = paydays[i].substr(0,1);
				var timing = ''; 
				var timingDate = ''; 
				var position = '';
				var day = '';
				var mdate = '';
	  		if (type=='m' || type=='w') {
					timing = +paydays[i].substr(1, 2) + '';
					position = paydays[i].substr(3, 1);
					if (position=='x') {
						mdate = paydays[i].substr(4);
					} else {
						day = paydays[i].substr(4, 1);
					}
	  		} else if (type=='#') {
	  			timingDate = paydays[i].substr(1,4) + '-' + paydays[i].substr(5,2) + '-' + paydays[i].substr(7,2);
	  		} else {
	  			timing = +paydays[i].substr(1,3) + '';
					day = (paydays[i].length>4 ? +paydays[i].substr(5, 1) : 0) + '';
	  		}
	  		list.push({"estimate": (estimate ? 1 : 0), "type": type, "percent": +percents[i] * 100 + '', "timing": timing, "timingDate": timingDate, "weekday": day, "order": position, "mdate": mdate, "datetype": dateanchor });
  		}
  	}
  	return list;
  }

  combineTerms(list: any[]) {
  	var datetype = 'delivery';
  	var paypercent = '';
  	var payday = '';
  	var epaypercent = '';
  	var epayday = '';
  	var timing = '';
  	for (var i = 0; i < list.length; i++) {
  		if (list[i]['percent']>0 && list[i]['type']!==undefined) {
	  		if (list[i]['estimate']) {
		  		epaypercent = epaypercent + +list[i]['percent']/100 + ";";
		  		timing = (list[i]['type'] == '#' ? moment(list[i]['timingDate']).format('YYYYMMDD') : list[i]['timing'] );	
		  		var tempPayDay = list[i]['type'] + 
		  			((list[i]['type']=='b' || list[i]['type']=='d') && timing.toString().length<3 ? '0' : '') +
		  			(timing.toString().length==1 ? '0' + timing : timing) + 
		  			(list[i]['type']=='m' ? 
		  				list[i]['order'] + 
		  				(list[i]['order']=='x' ? list[i]['mdate'] : list[i]['weekday'] )
		  			 	: '' ) +
		  			(list[i]['type']=='w' ? 'x' + list[i]['weekday'] : '' ) +
		  			((list[i]['type']=='d' || list[i]['type']=='b') && list[i]['weekday']!='0' ? 'w' + list[i]['weekday'] : '' );
		  		epayday = epayday + tempPayDay + ";";
	  		} else {
		  		paypercent = paypercent + +list[i]['percent']/100 + ";";
		  		timing = (list[i]['type'] == '#' ? moment(list[i]['timingDate']).format('YYYYMMDD') : list[i]['timing'] );
		  		var tempPayDay = list[i]['type'] + 
		  			((list[i]['type']=='b' || list[i]['type']=='d') && timing.toString().length<3 ? '0' : '') +
		  			(timing.toString().length==1 ? '0' + timing : timing) + 
		  			(list[i]['type']=='m' ? 
		  				list[i]['order'] + 
		  				(list[i]['order']=='x' ? list[i]['mdate'] : list[i]['weekday'] )
		  			 	: '' ) +
		  			(list[i]['type']=='w' ? 'x' + list[i]['weekday'] : '' ) +
		  			((list[i]['type']=='d' || list[i]['type']=='b') && list[i]['weekday']!='0' ? 'w' + list[i]['weekday'] : '' );
		  		payday = payday + (list[i]['datetype']=='sample' && list[i]['type']!='#' ? 's' : '') + tempPayDay + ";";
		  	}
		  }
  	}
  	return {"paypercent": paypercent, "payday": payday, "epaypercent": epaypercent, "epayday": epayday};
  }

  checkTerms(list: [], modelset: boolean = true, alerts: boolean = true) {
  	this.message = null;
  	var tally = 0;
  	var etally = 0;
  	var timingsOk = true;
  	var percentOk = true;
  	for (var i = 0; i < list.length; i++) {
  		tally = tally + (list[i]['estimate'] ? 0 : +list[i]['percent']);
  		etally = etally + (list[i]['estimate'] ? +list[i]['percent'] : 0 );
  		if(+list[i]['percent'] <=  0 || +list[i]['percent'] >100) {
				percentOk = false;
  		}
  		if (list[i]['type'] == '') {
  			timingsOk = false;
  		}
  		if ((list[i]['type'] == 'b' || list[i]['type'] == 'd') && list[i]['timing']==undefined ) {
  			timingsOk = false;
  		}
  		if (list[i]['type'] == 'm' && ( list[i]['order'] === '' || list[i]['order'] == undefined ) ) {
  			if (list[i]['order']=='x' && ( list[i]['mdate'] === '' || list[i]['mdate'] == undefined ) ) {
					timingsOk = false;
				} else if (list[i]['weekday'] === '' || list[i]['weekday'] == undefined ) {
					timingsOk = false;
				}
  		}
  		if (list[i]['type'] == 'w') {
  			if (list[i]['weekday'] === '' || list[i]['weekday'] == undefined ) {
					timingsOk = false;
				}
  		}
  		if ((list[i]['type'] == '#' && (list[i]['timingDate'] === '' || list[i]['timingDate'] == undefined) ) || (list[i]['type'] != '#' && (list[i]['timing'] === ''  || list[i]['timing'] == undefined) ) ) {
  			timingsOk = false;
  		}
  	}
  	if (!percentOk) {
			if (alerts) {this.message = "One of the percentages paid isn't between 0% and 100%";}
		  return false;
  	} else {
	  	if (timingsOk) {
		  	if (((tally==0 && !modelset) || tally == 100) && (etally == 100 || etally == 0)) {
		  		return true;
		  	} else {
		  		if (alerts) {this.message = "Total payout does not equal 100%, please review before trying to save again.";}
		  		return false;
		  	}
		  } else {
		  	if (alerts) {this.message = "Payment schedules are incomplete.";}
		  	return false;
		  }
		}
  }

	transDay(paydayRaw) {
		var payday = [];
		var datetype = 'delivery';
		for (var i = 0; i < paydayRaw.length; i++) {
			if (paydayRaw[i].substr(0,1)=='s') {
				paydayRaw[i] = paydayRaw[i].substr(1);
				datetype = 'sample';
			}
			switch (paydayRaw[i].substr(0,1)) {
				case "#":
				// Date
					payday[i] = new Date(paydayRaw[i].substr(1,4), paydayRaw[i].substr(5,2) - 1, paydayRaw[i].substr(7,2)).toDateString();
					break;
				case "m":
					// Month
					var stringOffset = (paydayRaw[i].length > 4 ? 1 : 0);
					var month = paydayRaw[i].substr(1, 1 + stringOffset); 
					var position = paydayRaw[i].substr(2 + stringOffset, 1);
					var day = paydayRaw[i].substr(3 + stringOffset);
					switch (position) {
						case "x":
							pos = 'Day ' + +day;
							break;
						case "b":
							pos = +day + (day > 1 ? " business days" : " business day after " + datetype + " date");
							break;
						case "d":
							pos = +day + (day > 1 ? " days" : " day after " + datetype + " date");
							break;
						default:
							var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
							var weekday = weekdays[day - 1];
							switch (position) {
								case "l":
									var pos = "Last";
									break;
								case "f":
									var pos = "First";
									break;
								case "s":
									var pos = "Second";
									break;
								case "t":
									var pos = "Third";
									break;
								case "p":
									var pos = "Fourth";
									break;
							}
							pos = pos + ' ' + weekday;
							break;
						}
						payday[i] = pos + ', ' + (+month > 0 ? (+month > 1 ? +month + ' months' : 'the month') + ' after ' + datetype + ' date' : 'following ' + datetype + ' date');
						break;
				case "w":
					// Weeks
					var day = paydayRaw[i].substr(4);
					if (+day>0) {
						var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
						var weekday = weekdays[day];
						var pos = 'First ' + weekday + ' on/following ';
					} else {
						var pos = '';
					}
					payday[i] = pos + +paydayRaw[i].substr(1,2) + (+paydayRaw[i].substr(1,2) > 1 ? " weeks" : " week") + ' after ' + datetype + ' date';
					break;
				case "b":
					// Business days
					var day = paydayRaw[i].substr(4);
					if (+day>0) {
						var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
						var weekday = weekdays[day];
						var pos = 'First ' + weekday + ' on/following ';
					} else {
						var pos = '';
					}
					payday[i] = pos + +paydayRaw[i].substr(1,3) + (+paydayRaw[i].substr(1,3) > 1 ? " business days" : " business day") + ' after ' + datetype + ' date';
					break;
				case "d":
					// Days
					var day = paydayRaw[i].substr(4);
					if (+day>0) {
						var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
						var weekday = weekdays[day];
						var pos = 'First ' + weekday + ' on/following ';
					} else {
						var pos = '';
					}
					payday[i] = pos + +paydayRaw[i].substr(1,3) + (+paydayRaw[i].substr(1,3) > 1 ? " days" : " day") + ' after ' + datetype + ' date';
					break;
			}
		}
		return payday;
	}

}