<div class="row justify-content-between mx-0">
	<h4 class="col mt-2 pl-0">Deliveries</h4>
	<button type="button" pButton pRipple class="p-mr-2 p-ml-2 p-button p-px-2 mb-3 refresh-button" pTooltip="Loading..." tooltipPosition="bottom" (click)="this.refresh($event)"><i class="pi pi-refresh" [class.pi-spin]="this.remittancesService.loading"></i></button>
</div>
<table class="table col-sm">
	<tbody>
		<tr class="tableseperator"><td></td><td></td></tr>
		<tr>
			<td>Total WNIS Received (inc. Smalls)</td>
			<td>{{ +this.remittancesService.overviewStore?.totalWNIS + +this.remittancesService.overviewStore?.smalls | number:'1.0-0' }} kg</td>
		</tr>
		<tr>
			<td>Total WNIS Received (ex. Smalls)</td>
			<td>{{ this.remittancesService.overviewStore?.totalWNIS | number:'1.0-0' }} kg</td>
		</tr>
		<ng-container *ngIf="isAuthenticated('mod-depot')">
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>WNIS Received - Direct</td>
				<td>{{ this.remittancesService.overviewStore?.wnis | number:'1.0-0' }} kg</td>
			</tr>
			<tr>
				<td>WNIS Received - Depot</td>
				<td>{{ this.remittancesService.overviewStore?.depotwnisd | number:'1.0-0' }} kg</td>
			</tr>
			<tr>
				<td>WNIS Awaiting Collection - Depot</td>
				<td>{{ this.remittancesService.overviewStore?.depotcollection | number:'1.0-0' }} kg</td>
			</tr>
		</ng-container>
		<tr class="tableseperatorlight" *ngIf="+this.remittancesService.overviewStore?.smalls>0"><td></td><td></td></tr>
		<tr *ngIf="+this.remittancesService.overviewStore?.smalls>0">
			<td>WNIS Smalls</td>
			<td>{{ this.remittancesService.overviewStore?.smalls | number:'1.0-0' }} kg</td>
		</tr>
		<tr class="tableseperator"><td></td><td></td></tr>
		<tr>
			<td>Total Farms Delivered</td>
			<td>{{ +this.remittancesService.overviewStore?.farms | number:'1.0-0' }}</td>
		</tr>
		<tr class="tableseperator"><td></td><td></td></tr>
		<tr>
			<td>Total Deliveries Received</td>
			<td>{{ +this.remittancesService.overviewStore?.deliveries + +this.remittancesService.overviewStore?.depotDeliveries + +this.remittancesService.overviewStore?.awaitingCollection | number:'1.0-0' }}</td>
		</tr>
		<ng-container *ngIf="isAuthenticated('mod-depot')">
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>Deliveries Received - Direct</td>
				<td>{{ this.remittancesService.overviewStore?.deliveries | number:'1.0-0' }}</td>
			</tr>
			<tr>
				<td>Deliveries Received - Depot</td>
				<td>{{ this.remittancesService.overviewStore?.depotDeliveries | number:'1.0-0' }}</td>
			</tr>
			<tr>
				<td>Depot Deliveries Awaiting Collection</td>
				<td>{{ this.remittancesService.overviewStore?.awaitingCollection | number:'1.0-0' }}</td>
			</tr>
		</ng-container>
		<tr class="tableseperator"><td></td><td></td></tr>
		<tr>
			<td>Samples Outstanding</td>
			<td>{{ this.remittancesService.overviewStore?.samplesOutstanding | number:'1.0-0' }}</td>
		</tr>
		<tr>
			<td>Samples Complete</td>
			<td>{{ this.remittancesService.overviewStore?.samplesComplete | number:'1.0-0' }}</td>
		</tr>	
		<tr class="tableseperator"><td></td><td></td></tr>
	</tbody>
</table>

<ng-container *ngIf="this.remittancesService.overviewStore?.samplesComplete>0">

	<br>
	<h4>Samples & Styling</h4>
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>W.Avg Moisture</td>
				<td>{{ this.remittancesService.overviewStore?.Moisture | percent:'1.2' }}</td>
			</tr>
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>W.Avg SKR</td>
				<td>{{ this.remittancesService.overviewStore?.SKR | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>W.Avg USKR</td>
				<td>{{ this.remittancesService.overviewStore?.USKR | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>W.Avg Darks</td>
				<td>{{ this.remittancesService.overviewStore?.DKR | percent:'1.2' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>WNIS - Above 25mm</td>
				<td>{{ this.remittancesService.overviewStore?.w25 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>WNIS - Above 22mm</td>
				<td>{{ this.remittancesService.overviewStore?.w22 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>WNIS - Above 20mm</td>
				<td>{{ this.remittancesService.overviewStore?.w20 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>WNIS - Above 18mm</td>
				<td>{{ this.remittancesService.overviewStore?.w18 | percent:'1.2' }}</td>
			</tr>
			<!--
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr *ngIf="this.remittancesService.overviewStore?.a25!=undefined">
				<td>Lab DNIS - Above 25mm</td>
				<td>{{ this.remittancesService.overviewStore?.a25 | percent:'1.2' }}</td>
			</tr>
			<tr *ngIf="this.remittancesService.overviewStore?.a24!=undefined">
				<td>Lab DNIS - Above 24mm</td>
				<td>{{ this.remittancesService.overviewStore?.a24 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Lab DNIS - Above 22mm</td>
				<td>{{ this.remittancesService.overviewStore?.a22 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Lab DNIS - Above 20mm</td>
				<td>{{ this.remittancesService.overviewStore?.a20 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Lab DNIS - Above 18mm</td>
				<td>{{ this.remittancesService.overviewStore?.a18 | percent:'1.2' }}</td>
			</tr>
			-->
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Style 0</td>
				<td>{{ this.remittancesService.overviewStore?.s0 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Style 1</td>
				<td>{{ this.remittancesService.overviewStore?.s1 | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Style 1S</td>
				<td>{{ this.remittancesService.overviewStore?.s1s | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Style 4L</td>
				<td>{{ this.remittancesService.overviewStore?.s4l | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Style 4R</td>
				<td>{{ this.remittancesService.overviewStore?.s4r | percent:'1.2' }}</td>
			</tr>
			<ng-container *ngIf="this.remittancesService.overviewStore?.s5 + this.remittancesService.overviewStore?.s6 + this.remittancesService.overviewStore?.s78 > 0">
				<tr>
					<td>Style 5</td>
					<td>{{ this.remittancesService.overviewStore?.s5 | percent:'1.2' }}</td>
				</tr>
				<tr>
					<td>Style 6</td>
					<td>{{ this.remittancesService.overviewStore?.s6 | percent:'1.2' }}</td>
				</tr>
				<tr>
					<td>Style 7,8</td>
					<td>{{ this.remittancesService.overviewStore?.s78 | percent:'1.2' }}</td>
				</tr>
			</ng-container>
			<tr *ngIf="this.remittancesService.overviewStore?.s567>0">
				<td>Style 5,6,7</td>
				<td>{{ this.remittancesService.overviewStore?.s567 | percent:'1.2' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>