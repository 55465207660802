<div *ngIf="isAuthenticated()" class="container-fluid px-0">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<div class="row justify-content-between">
					<div class="col main-header">
						<h2>{{ this.tname | titlecase }}</h2>
					</div>
					<div class="ms-3 col-auto">
						<button mdbBtn class="btn btn-info" *ngIf="this.entity!==''" routerLink="/logistics/logging/{{ this.entity.replace(' ','').toLowerCase() }}">Add {{ this.entity }}</button>
					</div>
				</div>
			</div>
			<br>
			<br>
			<div class="table-container" *ngIf="!this.tS.bpanel">
			  <p-table 
			  	id="datatable" #dt1
			  	(onFilter)="onFilter($event, dt1)"
			  	[value]="this.tS[this.tablename.toLowerCase()]" 
			  	[columns]="this.lS['columns'][this.tablename.toLowerCase()]" 
			  	[loading]="this.tS.loading" 
			  	[style]="{'min-height':'400px'}" 
			  	[scrollable]="true" scrollHeight="flex" 
			  	[resizableColumns]="true" columnResizeMode="expand" 
			  	[reorderableColumns]="true"
			  	[(selection)]="this.tS.selectedRows"
			  	dataKey="id"
			  	exportFilename="{{ exportname }}"
			  	stateStorage="session" stateKey="{{ this.tablename }}-session"
			  	styleClass="p-datatable-striped">
				  <ng-template pTemplate="caption">
		       	<div class="d-flex">
	            <button pButton class="p-button-outlined ms-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
	            <span class="p-input-icon-left ms-3 me-auto">
	              <i class="pi pi-search"></i>
	              <input pInputText type="text" class="col" [(ngModel)]="searchtext" (input)="dt1.filterGlobal($event.target['value'], 'contains')" placeholder="Search keyword" />
	            </span>
	            <button type="button" pButton pRipple (click)="this.tS.loadData(this.tablename.toLowerCase())" class="me-2 ms-3 p-button px-2" pTooltip="Refresh" tooltipPosition="bottom"><i class="pi pi-refresh mx-auto" [class.pi-spin]="this.tS.stillLoading"></i></button>
	            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="this.tS.saveCSV(this.tablename.toLowerCase())" class="me-2 ms-2 p-button" pTooltip="CSV" tooltipPosition="bottom"></button>
		        </div>
		    	</ng-template>
				  <ng-template pTemplate="colgroup" let-columns>
	          <colgroup>
	          	<ng-container *ngFor="let col of columns">
	            	<col *ngIf="col.pipe!='checkbox' || (col.pipe=='checkbox' && this.tS.checkBoxes)" style="width: {{ col.width }}">
	            </ng-container>
	          </colgroup>
	        </ng-template>
			  	<ng-template pTemplate="header" let-columns>
			  		<tr>
              <ng-container *ngFor="let col of columns">
				  			<th *ngIf="col.pipe=='checkbox' && this.tS.checkBoxes">
	                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				  			</th>
				  			<th 
				  				*ngIf="col.pipe!='checkbox'"
				  				pReorderableColumn 
				  				pResizableColumn
				  				[pSortableColumn]="col.field"
				  				[style.width]="col.width + ' !important'"
				  				[style.max-width]="col.width + ' !important'"
				  				>
				  				<div class="p-d-flex p-jc-between p-ai-center">
				  					{{ col.header }}
	                </div>
	                <p-columnFilter *ngIf="col.type!=='dropdown' && col.type!=='yesno'" type="{{ col.type }}" field="{{ col.field }}" display="menu" id="filter"></p-columnFilter>
	                <p-columnFilter *ngIf="col.type=='dropdown'" type="{{ col.type }}" field="{{ col.field }}" matchMode="equals" operator="or" display="menu" id="filter">
	                	<ng-template pTemplate="filter" let-value let-filter="filterCallback">
	                    <p-dropdown *ngIf="col.pipe!=='titlecase' && col.pipe!=='date'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='titlecase'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | titlecase }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | titlecase }}
	                      </ng-template>
	                    </p-dropdown>
	                    <p-dropdown *ngIf="col.pipe=='date'" [ngModel]="value" [options]="this.tS.allData[this.tablename.toLowerCase()][col.field]" (onChange)="filter($event['value'][col.field])" placeholder="{{ (value==null ? 'Any' : value) | datemod:'MMM d, EEE' }}">
	                      <ng-template let-option pTemplate="item">
	                        {{ option[col.field] | date:'MMM d, EEE' }}
	                      </ng-template>
	                    </p-dropdown>
	                  </ng-template>
	                </p-columnFilter>
	                <p-columnFilter *ngIf="col.type=='yesno'" type="boolean" field="{{ col.field }}" display="menu"></p-columnFilter>
				  				<p-sortIcon [field]="col.field"></p-sortIcon>
				  			</th>
				  		</ng-container>
			  		</tr>
			  	</ng-template>
			  	<ng-template pTemplate="emptymessage" let-columns>
					  <tr>
					    <td [attr.colspan]="columns.length">
					       No records found - Check filters
					    </td>
					  </tr>
					</ng-template>
			  	<ng-template pTemplate="body" let-rowData let-columns="columns">
			  		<tr 
			  			[pSelectableRow]="rowData"
				  		[class.draft]="rowData['draft']"
				  		[class.concern]="rowData['concern']"
				  		[class.wasconcern]="rowData['wasconcern']"
				  		[class.complete]="rowData['complete']"
				  		[class.alternative]="rowData['alternative']"
				  		[class.paid]="rowData['orderstatus']=='paid'"
				  		[class.selected]="isSelected(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])">
              <ng-container *ngFor="let col of columns">
	              <ng-container *ngIf="col.pipe!=='checkbox'">
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='kg'">{{ rowData[col.field] | number:'1.2-2' | dashblank : ' kg' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='number'">{{ rowData[col.field] | number:'1.2-2' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='integer' || col.pipe=='boxes'">{{ rowData[col.field] | number | dashblank }}</td>	  				
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='currency'">{{ rowData[col.field] | currency:this.tS.currency:'symbol-narrow' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='setcurrency'">{{ rowData[col.field] | currency:rowData[col.currency]:'symbol-narrow' | dashblank }}</td>			  				
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='date'">{{ rowData[col.field] | date:'MMM d, EEE' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='time'">{{ rowData[col.field] | date:'HH:mm' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='duration'">{{ rowData[col.field] | date:'H:mm' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dateshort'">{{ rowData[col.field] | date:'MMM d' }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='titlecase'">{{ rowData[col.field] | titlecase }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='percent'">{{ rowData[col.field] | percent:'1.2' | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='yesno'" class="p-text-center">
				  					<i class="pi" [ngClass]="{'true-icon pi-check-circle': rowData[col.field], 'false-icon pi-times-circle': !rowData[col.field]}"></i>
	                </td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='dashblank'">{{ rowData[col.field] | dashblank }}</td>
				  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" (click)="onSelectRow(rowData[this.tS.allData[this.tablename.toLowerCase()]['key']])" *ngIf="col.pipe=='none'">{{ rowData[col.field] }}</td>
				  			</ng-container>
			  			</ng-container>
			  		</tr>
			  	</ng-template>
			  	<ng-template pTemplate="footer" let-rowData let-columns>
		        <tr>
		        	<ng-container *ngFor="let col of columns; let cIndex=index">
		        		<ng-container *ngIf="col.pipe!='checkbox'">
			        		<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.footer=='text'">{{ col.text }}</td>
			        		<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.footer=='none'"></td>
			        		<ng-container *ngIf="col.footer!=='none' && col.footer!=='text'">
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='kg'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.2-2' | dashblank : ' kg' }}</td>
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='number'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number:'1.2-2' }}</td>
				  					<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='integer'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number | dashblank }}</td>
				  					<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='boxes'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | number | dashblank }} Units</td>			  					
				  					<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='currency'">{{ (col.footer=='sum' ? +calTotal(col.field, 'fx') : (col.footer=='count' ? +calCount(col.field, 'fx') : +calWavg(col.field, 'fx') )) | currency:this.tS.currency:'symbol-narrow' | dashblank }}</td>			  					
				  					<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='setcurrency'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | currency:rowData[col.currency]:'symbol-narrow' | dashblank }}</td>  				
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='date'">{{ rowData[col.field] | date:'MMM d, EEE' }}</td>		
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='time'">{{ rowData[col.field] | date:'HH:mm' | dashblank }}</td>		
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='duration'">{{ rowData[col.field] | date:'H:mm' | dashblank }}</td>
				  					<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='dateshort'">{{ rowData[col.field] | date:'MMM d' }}</td>
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='titlecase'">{{ rowData[col.field] | titlecase }}</td>
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='percent'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | percent:'1.2' | dashblank }}</td>
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='yesno'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}
		                </td>
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='none'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) }}</td>
					  				<td [style.width]="col.width + ' !important'" [style.max-width]="col.width + ' !important'" *ngIf="col.pipe=='dashblank'">{{ (col.footer=='sum' ? calTotal(col.field) : (col.footer=='count' ? calCount(col.field) : calWavg(col.field) )) | dashblank }}</td>
					  			</ng-container>
		        		</ng-container>
		        	</ng-container>
		        </tr>
		    	</ng-template>
			  </p-table>
			</div>
			<div *ngIf="this.tS.clickedIndex[this.tablename.toLowerCase()]!==null" class="container">
				<ng-container *ngIf="this.tS.loadReady(this.tablename)">
					<br><br>
					<!--
					<ng-container *ngIf="this.component=='contact'"><app-contact></app-contact></ng-container>
					<ng-container *ngIf="this.component=='company'"><app-company></app-company></ng-container>
					<ng-container *ngIf="this.component=='trade'"><app-trade></app-trade></ng-container>
				-->
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-login></app-login>
</div>