import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../../overview/table/table.service';
import { AddBookingService } from '../../logging/add-booking/add-booking.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-teambooking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
  	private loginService: TeamLoginService,
    public tableService: TableDataService,
    private addBookingService: AddBookingService,
    private dropDownDataService: DropDownDataService,
    private router: Router
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  calcMins(time: string) {
    console.log(time + ' ' + time.substring(0,2) + ' ' + time.substring(2,2));
    return +time.substring(0,2) * 60 + +time.substring(2,2);
  }

  async editBooking() {
    this.addBookingService.idb = this.tableService['bookings'][this.tableService.clickedIndex['bookings']].idb.toString();
    this.addBookingService.editmode = true;
    await this.dropDownDataService.waitDD('bookingList');
    await this.dropDownDataService.waitDD('locationList');
    await this.dropDownDataService.waitDD('producerList');
    await this.dropDownDataService.waitDD('cultivarList');
    this.router.navigate(['/team/logging/booking']);
    this.addBookingService.loadForEdit();
  }

  viewCompany(idcompany) {
    if (this.loginService.isAuthorised('general')) {
      //this.companyService.setCompany(idcompany);
        //this.router.navigate(['/team/overview/companies']);
      this.tableService.reset('companies');
      this.tableService.setDelivery(idcompany, 'companies');
      this.tableService.indexLink = true;
      this.router.navigate(['/team/table/companies']);
      setTimeout(function() {
        window.scrollTo(0,750);
      }, 100);
    }
  }
  
}
