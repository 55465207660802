import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { Invoice } from './invoiceselector.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class FarmerInvoiceSelectorService {
	private postBody: {};
	httpRes: any;
	public invoicefull: Invoice [];
	public invoice: Invoice;
	public fees: any;
	public payments: any = [];
	public pmodel: any;
	public useChild: boolean = false;
	public iddelivery: string;
	public customref: string;
	public invoiceref: string;
	public ddate: string;
	public month: string = "April";
	numerals = ['one','two','three'] ;

	public idinvoice: any;

	constructor(
		private http: HttpClient,
		private portalService: PortalService,
		private loginService: LoginService) {}

	async loadForEdit() {
		if (this.idinvoice!=null) {
			var p = {};
			var type = this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['invoicetype'];
			if (type=='delivery') {
				p = {
					"producer":+this.loginService.selectedmember,
					"iddelivery": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['iddelivery'],
					"season": +this.loginService.season
				};
			} else {
				p = {
					"producer":+this.loginService.selectedmember,
					"iddelivery": 0,
					"invoicetype": this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['invoicetype'],
					"rank": this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['rank'],
					"modelno": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['modelno'],
					"year": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['year'],
					"month": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['month'],
					"week": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['week'],
					"group": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['group'],
					"mindel": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['mindel'],
					"maxdel": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['maxdel'],
					"mininst": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['mininst'],
					"maxinst": +this.loginService.dropdown.remittanceList['invoices'][this.idinvoice]['maxinst'],
					"season": +this.loginService.season
				};
			}
			var response = await this.loginService.loadData('loadInvoice', p);
			this.populateInvoice(response);
		}
	}

	populateInvoice(raw) {
		this.invoice = raw;
    this.invoiceref = this.invoice['detail'][0].ref;
    this.customref = this.invoiceref;
    this.invoice['detail'][0].subtotal = 0;
    this.invoice['detail'][0].vattotal = 0;
    this.invoice['detail'][0].totaldue = 0;
    for (var i = 0; i < this.invoice['invoices'].length; i++) {
			this.invoice['detail'][0].subtotal += +this.invoice['invoices'][i]['netprice'] * +this.invoice['detail'][0]['fxc'];
			this.invoice['detail'][0].vattotal += +this.invoice['invoices'][i]['vatpayable'] * +this.invoice['detail'][0]['fxc'];
			this.invoice['detail'][0].totaldue += +this.invoice['invoices'][i]['netvat'] * +this.invoice['detail'][0]['fxc'];
    }
	}

  textAdjust(id, textstr, textsize, textlength) {
    if (textstr!=null) {
    	if (textstr.length > textlength) {
	      textsize = textsize * textlength / textstr.length;
	      document.getElementById(id).style.fontSize = textsize + "px";
	    }
	  }
  }

  textAdjustments() {
    this.textAdjust('address', this.invoice.address, 9.96, 50);
    this.textAdjust('customref', this.customref, 11.04, 22);
    this.textAdjust('email', this.customref, 8.04, 25);
    this.textAdjust('regnumber', this.invoice.regnumber, 8.04, 25);
    this.textAdjust('iban', this.invoice.bankiban, 8.04, 20);
  }

  resetForm(keepRef: boolean = false) {
    window.scroll(0,0);
    this.idinvoice = null;
    this.invoice = null;
    this.invoicefull = null;
    this.customref = null;
    this.invoiceref = null;
  }
	

}