<div class="col">

  <div class="row justify-content-between">
    <div class="col main-header">
    	<div class="form-container">        
        <mat-form-field class="col">
          <mat-select placeholder="Bookings for Producer" [(ngModel)]="this.producer" (keyup)="refreshCal()" (ngModelChange)="refreshCal()">
          	<mat-option (click)="refreshCal()" value="0">All Producers</mat-option>
            <mat-option *ngFor="let producer of this.producerBookingList" (click)="refreshCal()" value="{{ producer.idcompany }}">{{ producer.companyname }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button mdbBtn class="btn btn-primary mr-4 ml-3 col-auto" (click)="csvDownload()">CSV</button>
  </div>

	<br>
	<br>
	
	<full-calendar [options]="calendarOptions"></full-calendar>

</div>