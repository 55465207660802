<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3">
				<h2>Client Portal Access</h2>
			</div>
		</div>
		<br>
		<br>
		<div class="col col">
		 	<h3>Portal Invitations</h3> 
		 	<br>
			<div class="form-container col col-md-6 pl-0">

				<mat-form-field>
    			<mat-label>Contact Name</mat-label>
			    <mat-select placeholder="Contact Name" [(ngModel)]="this.clientsService.idcontact">
			      <mat-option *ngFor="let contact of this.dropDownDataService.dropdown.contactList" #prod (click)="this.clientsService.selectedItem(contact.idcompany, contact.firstname, contact.lastname, contact.email, contact.companyname)" value="{{ contact.idcontact }}">{{ contact.firstname }} {{ contact.lastname }} [{{ contact.companyname }}]</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Membership</mat-label>
			    <mat-select placeholder="Membership" [(ngModel)]="this.clientsService.idMembership" (keydown.tab)="this.clientsService.confirmChange()">
			      <mat-option *ngFor="let producer of this.dropDownDataService.dropdown.producerList; let memberIndex=index" (click)="this.clientsService.confirmChange()" #prod [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Access Level</mat-label>
			    <mat-select placeholder="Access Level" [(ngModel)]="this.clientsService.access">
			      <mat-option value="full">Full</mat-option>
			      <mat-option value="basic">Basic (No financial information)</mat-option>
			    </mat-select>
			  </mat-form-field>
			  
			</div>

			<button mdbBtn class="btn btn-primary mt-3" (click)="this.clientsService.addItem()">Add</button>

			<br>
			<br>

			<table *ngIf="this.clientsService.invitations.length > 0" class="table col" mdbTableScroll [scrollY]="scroll" maxHeight="500" bordered="true">
				<tbody>
					<tr *ngIf="this.clientsService.invitations.length>0">
						<th>Contact</th>
						<th>Email</th>
						<th>Company</th>
						<th>Membership</th>
						<th>Access</th>
						<th>Remove</th>
					</tr>
					<tr *ngFor="let invite of this.clientsService.invitations; let itemindex=index">
						<td>{{ invite.firstname + ' ' + invite.lastname }}</td>
						<td>{{ invite.email }}</td>
						<td>{{ invite.companyname }}</td>
						<td>{{ invite.membership }}</td>
						<td>{{ invite.access | titlecase }}</td>
						<td>
						  <button mat-icon-button class="col" *ngIf="this.clientsService.invitations.length>0" (click)="this.clientsService.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
						</td>
					</tr>
				</tbody>
			</table>

			<button *ngIf="this.clientsService.invitations.length > 0" mdbBtn class="btn btn-primary my-4" (click)="onSave()">Send Invites</button>
			<button *ngIf="this.clientsService.invitations.length > 0" mdbBtn class="btn btn-warning my-4" (click)="this.clientsService.resetForm()">Clear</button>

			<hr>

		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>