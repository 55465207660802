import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';
import { FilesService } from '../../../services/files.service';

import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-fumigation',
  templateUrl: './fumigation.component.html',
  styleUrls: ['./fumigation.component.scss']
})
export class FumigationComponent implements OnInit {
  d: Date = new Date();
  sig: any;
  public certno: string = '00001';
  sigimage: string = 'fumsig';

  @ViewChild("sigImage") image: ElementRef;

  constructor(
    public fS: FilesService,
  	public lS: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tS: TableDataService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
    this.fS.getSig(this.lS.httpRoot, 'team_id_token', this.sigimage);
  }

  ngOnDestroy() {
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  async svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('fumigation');

		// create a new jsPDF instance
		const pdfTeam = await new jsPDF('p', 'pt', 'a4');
    var savename = this.pS.portalMod()['companyname'] + ' - Fumigation Certificate - ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref +'.pdf';
    this.setupPDF(pdfTeam, svgElement, savename);
	}

	async setupPDF(pdfTeam, svgElement, savename) {
		const pdf = await pdfTeam.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    });
    const pdfprint = await pdf.save(savename, {returnPromise: true});
	}

  transdetail(i) {
    return 'translate(0 ' + 10.2 * i + ')';
  }

  certChange($event) {
    this.certno  = $event.padStart(5, '0');
  }


}
