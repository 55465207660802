<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.addBookingService.editmode">Log Booking</h2>
				<h2 *ngIf="this.addBookingService.editmode">Edit Booking</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.addBookingService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/bookings">View Bookings</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Booking</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.addBookingService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/bookings">View Bookings</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Log Booking</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.addBookingService.editmode">
    			<mat-label>Booking</mat-label>
			    <mat-select placeholder="Booking" [(ngModel)]="this.addBookingService.idb" (ngModelChange)="this.addBookingService.loadForEdit()">
			      <mat-option *ngFor="let booking of this.dropDownDataService.dropdown.bookingList" value="{{ booking.idb }}">{{ booking.date | date:'MMM d, EEE' }} @ {{ booking.date + ' ' + booking.time | date:'HH:mm' }} [{{ (booking.type=='drop-off' ? 'Drop-off' : booking.vehicle + ', ' + booking.driver ) }}]: {{ booking.companyname }} - {{ booking.loadamount | number:'1.0-0' }}kg</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <ng-container *ngIf="editSelected()">

			  	<hr *ngIf="this.addBookingService.editmode">

				  <mat-form-field>
    				<mat-label>Location</mat-label>
				    <mat-select placeholder="Location" [(ngModel)]="this.dropDownDataService.location['idlocation']">
				      <mat-option *ngFor="let location of this.dropDownDataService.dropdown.locationList" [value]="location.idlocation">{{ location.name }}</mat-option>
				    </mat-select>
				  </mat-form-field>

					<mat-form-field>
    				<mat-label>Type</mat-label>
				    <mat-select placeholder="Type" [(ngModel)]="this.addBookingService.type" (click)="this.addBookingService.resetForm(true)" (ngModelChange)="this.addBookingService.resetForm(true)">
				      <mat-option value="collection">Collection</mat-option>
				      <mat-option value="drop-off">Drop-off</mat-option>
				    </mat-select>
				  </mat-form-field>
			
					<mat-form-field>
    				<mat-label>Status</mat-label>
				    <mat-select placeholder="Status" [(ngModel)]="this.addBookingService.status">
				      <mat-option value="requested">Requested</mat-option>
				      <mat-option value="confirmed">Confirmed</mat-option>
				      <mat-option value="complete">Complete</mat-option>
				      <mat-option value="cancelled">Cancelled</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Date</mat-label>
				    <input matInput [matDatepicker]="date" [(ngModel)]="this.addBookingService.date" (ngModelChange)="this.dateChange()">
						<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
				    <mat-datepicker #date></mat-datepicker>
				  </mat-form-field>

				  <hr>

			  	<mat-form-field *ngIf="editSelected()">
    				<mat-label>Producer</mat-label>
				    <mat-select placeholder="Producer" [(ngModel)]="this.addBookingService.producer">
				      <mat-option *ngFor="let producer of this.dropDownDataService.dropdown.producerList; let pIndex=index;" #prod [value]="pIndex">{{ producer.companyname }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="editSelected()">
    				<mat-label>Cultivar</mat-label>
				    <mat-select placeholder="Cultivar" [(ngModel)]="this.addBookingService.cultivar">
				      <mat-option *ngFor="let cult of this.dropDownDataService.dropdown.cultivarList" [value]="cult.idcultivar">{{ cult.cultivar }}</mat-option>
				    </mat-select>
				  </mat-form-field> 

				  <mat-form-field *ngIf="this.addBookingService.type=='drop-off'">
    				<mat-label>Arrival Time</mat-label>
				    <mat-select placeholder="Arrival Time" [(ngModel)]="this.addBookingService.time">
				      <mat-option *ngFor="let time of this.addBookingService.arrivaltimes" [value]="time">{{ time }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="editSelected() && this.addBookingService.type=='collection'">
    				<mat-label>Time to destination</mat-label>
				    <mat-select placeholder="Time to destination" [(ngModel)]="this.addBookingService.journeytime" (ngModelChange)="this.addBookingService.updateTime()">
				      <mat-option *ngFor="let time of this.addBookingService.times" [value]="time">{{ this.transTime(time) }}</mat-option>
				    </mat-select>
				  </mat-form-field>

			  	<ng-container *ngIf="(this.addBookingService.type=='collection' || (this.addBookingService.time!=null && this.addBookingService.type=='drop-off')) && this.addBookingService.producer!=null">

				  	<hr>

					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Load Type</mat-label>
					    <mat-select placeholder="Load Type" [(ngModel)]="this.addBookingService.loadtype" (ngModelChange)="this.addBookingService.updateLoad()">
					      <mat-option value="loose">Loose nuts</mat-option>
					      <mat-option value="bigbag">Big bags</mat-option>
					      <mat-option value="smallbag">Small Bags</mat-option>
					    </mat-select>
					  </mat-form-field>	

					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Total WNIS (kg)</mat-label>
					    <input matInput type="number" placeholder="Total WNIS (kg)" [(ngModel)]="this.addBookingService.load" (keyup)="this.addBookingService.updateLoad()" (ngModelChange)="this.addBookingService.updateLoad()" (blur)="this.addBookingService.loadVeh()">
					  </mat-form-field>

				  	<ng-container *ngIf="this.addBookingService.type=='collection' && this.addBookingService.load>0">

				  		<hr>

					  	<mat-form-field *ngIf="editSelected()">
    						<mat-label>Vehicle</mat-label>
						    <mat-select placeholder="Vehicle" [(ngModel)]="this.addBookingService.vehicleIndex" (ngModelChange)="this.addBookingService.vehicleselect()">
						    	<ng-container *ngFor="let vehicle of this.addBookingService.vehicleList['vehicles']; let vIndex=index;" #vehicle>
						      	<mat-option *ngIf="vehicle[this.addBookingService.loadtype + 'cap']>=this.addBookingService.load" [value]="vIndex">{{ vehicle.name }} : {{ vehicle.type | titlecase }}{{ (vehicle.tipper==1 ? ' + Tipper' : '') + (vehicle.crane==1 ? ' + Crane' : '') }}{{ (vehicle.registration==null ? '' : ' [ ' + vehicle.registration + ' ]' ) }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>

							<ng-container *ngIf="this.addBookingService.vehicleList['vehicles']!==undefined">
						  	<ng-container *ngIf="this.addBookingService.vehicleList['vehicles'][this.addBookingService.vehicleIndex]!==undefined">

							  	<mat-form-field *ngIf="editSelected()">
    									<mat-label>Driver</mat-label>
								    <mat-select placeholder="Driver" [(ngModel)]="this.addBookingService.driverIndex" (keyup)="this.addBookingService.time=null" (ngModelChange)="this.addBookingService.time=null">
								      <mat-option *ngFor="let driver of this.addBookingService.vehicleList['vehicles'][this.addBookingService.vehicleIndex]['drivers']; let dIndex=index" #driver [value]="dIndex">{{ driver.driver }}</mat-option>
								    </mat-select>
								  </mat-form-field>

							  	<mat-form-field *ngIf="editSelected()">
    								<mat-label>Dispatch Time</mat-label>
								    <mat-select placeholder="Dispatch Time" [(ngModel)]="this.addBookingService.time" (ngModelChange)="this.addBookingService.updateTime()">
								    	<ng-container *ngFor="let time of this.addBookingService.vehicleList['vehicles'][this.addBookingService.vehicleIndex]['drivers'][this.addBookingService.driverIndex]['times']; let tIndex=index;" #time>
								      	<mat-option *ngIf="this.addBookingService.confirmTime(tIndex)" [value]="time.time">{{ time.time }}</mat-option>
								      </ng-container>
								    </mat-select>
								  </mat-form-field>

								  <ng-container *ngIf="this.addBookingService.time!==null">

									  <mat-form-field>
    									<mat-label>Arrival Time</mat-label>
									    <input matInput type="time" placeholder="Arrival Time" class="boldtext" disabled [ngModel]="this.addBookingService.loadtime">
									  </mat-form-field>

									  <mat-form-field>
    									<mat-label>Time Needed to Load (Mins)</mat-label>
									    <input matInput type="number" placeholder="Time Needed to Load (Mins)" class="boldtext" disabled [ngModel]="this.addBookingService.loadduration">
									  </mat-form-field>

									  <mat-form-field>
    									<mat-label>Return Time</mat-label>
									    <input matInput type="time" placeholder="Return Time" class="boldtext" disabled [ngModel]="this.addBookingService.unloadtime">
									  </mat-form-field>

									  <mat-form-field>
    									<mat-label>Time Needed to Unload (Mins)</mat-label>
									    <input matInput type="number" placeholder="Time Needed to Unload (Mins)" class="boldtext" disabled [ngModel]="this.addBookingService.unloadduration">
									  </mat-form-field>

									  <mat-form-field>
    									<mat-label>Completion Time</mat-label>
									    <input matInput type="time" placeholder="Completion Time" class="boldtext" disabled [ngModel]="this.addBookingService.completetime">
									  </mat-form-field>

									</ng-container>

								</ng-container>
							</ng-container>

						</ng-container>

				  	<ng-container *ngIf="this.addBookingService.type!=='collection' && this.addBookingService.load>0">

				  		<hr>

						  <mat-form-field>
    						<mat-label>Time Needed to Unload (Mins)</mat-label>
						    <input matInput type="number" placeholder="Time Needed to Unload (Mins)" class="boldtext" disabled [ngModel]="this.addBookingService.unloadduration">
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Completion Time</mat-label>
						    <input matInput type="time" placeholder="Completion Time" class="boldtext" disabled [ngModel]="this.addBookingService.completetime">
						  </mat-form-field>

				  	</ng-container>

				  	<ng-container *ngIf="this.addBookingService.showRecurring()">

						  <hr>

							<div class="d-flex justify-content-start" style="display: inline-block;padding-top: 5px;">
								<p class="mat-form-field my-auto">Recurring Booking?</p>
						  	<mat-slide-toggle class="slider-margin slider-label ml-4" [(ngModel)]="this.addBookingService.recurring"></mat-slide-toggle>
							</div>

							<ng-container *ngIf="this.addBookingService.recurring">

								<hr class="mb-4">

								<mat-form-field>
    							<mat-label>Frequency</mat-label>
							    <mat-select placeholder="Frequency" [(ngModel)]="this.addBookingService.frequency" (onChange)="this.addBookingService.checkClashes()">
							      <mat-option (click)="this.addBookingService.checkClashes()" value="none">Doesn't repeat</mat-option>
							      <mat-option (click)="this.addBookingService.checkClashes()" value="weekly">Weekly on {{ this.addBookingService.weekday[this.addBookingService.d.getDay()] }}</mat-option>
							      <mat-option (click)="this.addBookingService.checkClashes()" value="everyweekday">Every Weekday (Mon-Fri)</mat-option>
							      <mat-option (click)="this.addBookingService.checkClashes()" value="custom">Custom...</mat-option>
							    </mat-select>
							  </mat-form-field>

								<ng-container *ngIf="this.addBookingService.frequency=='custom'">

									<mat-form-field>
    								<mat-label>Repeat Every</mat-label>
								    <mat-select placeholder="Repeat Every" [(ngModel)]="this.addBookingService.repeat" (click)="this.addBookingService.checkClashes()">
								      <mat-option value="1">Week</mat-option>
								      <mat-option value="2">2 Weeks</mat-option>
								      <mat-option value="3">3 Weeks</mat-option>
								      <mat-option value="4">4 Weeks</mat-option>
								    </mat-select>
								  </mat-form-field>

								  <div class="btn-row my-3">
									  <div class="btn-strip">
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.monday" aria-label="Monday" (click)="toggleday('monday')">
								          M
								        </button>
								      </div>
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.tuesday" aria-label="Tuesday" (click)="toggleday('tuesday')">
								          T
								        </button>
								      </div>
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.wednesday" aria-label="Wednesday" (click)="toggleday('wednesday')">
								          W
								        </button>
								      </div>
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.thursday" aria-label="Thursday" (click)="toggleday('thursday')">
								          T
								        </button>
								      </div>
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.friday" aria-label="Friday" (click)="toggleday('friday')">
								          F
								        </button>
								      </div>
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.saturday" aria-label="Saturday" (click)="toggleday('saturday')">
								          S
								        </button>
								      </div>
										  <div class ="sm-button">
												<button mat-mini-fab [class.selected]="this.addBookingService.sunday" aria-label="Sunday" (click)="toggleday('sunday')">
								          S
								        </button>
								      </div>
								    </div>
								  </div>

								  <br>

								</ng-container>

							  <mat-form-field>
    							<mat-label>Last Date</mat-label>
							    <input matInput [matDatepicker]="lastdate" [(ngModel)]="this.addBookingService.enddate" (ngModelChange)="this.addBookingService.checkClashes()" (onBlur)="this.addBookingService.checkClashes()">
									<mat-datepicker-toggle matIconSuffix [for]="lastdate"></mat-datepicker-toggle>
							    <mat-datepicker #lastdate></mat-datepicker>
							  </mat-form-field>

							  <ng-container *ngIf="this.addBookingService.check['dates'].length>0">
							  	
							  	<h5 class="my-3">Scheduled Dates ({{ this.addBookingService.check['dates'].length }})</h5>
							  	<p class="ml-3" *ngFor="let date of this.addBookingService.check['dates']">{{ date | date:'MMM d, EEE' }}</p>

							  </ng-container>

							  <ng-container *ngIf="this.addBookingService.check['clashes'].length>0">

							  	<h5 class="my-3">Unavailable Dates ({{ this.addBookingService.check['clashes'].length }})</h5>
							  	<p class="ml-3" *ngFor="let clash of this.addBookingService.check['clashes']">{{ clash | date:'MMM d, EEE' }}</p>
							  	
							  </ng-container>

							</ng-container>

				  	</ng-container>

					</ng-container>

				</ng-container>

				<hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [(ngModel)]="this.addBookingService.note"></textarea>
			  </mat-form-field>

			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.addBookingService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.addBookingService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.addBookingService.saveChanges()">Update</button>

			<button *ngIf="this.addBookingService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>