<div class="container">
	<div class="row justify-content-between">
		<div class="col-xs-6 col-9 ml-3 px-0 main-header">
			<h3>Order: {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}, {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyer }}</h3>
		</div>
		<div class="mr-4 ml-3 col-auto">
			<button mdbBtn class="btn btn-info" *ngIf="isAuthorised('buyingedit')" (click)="editOrder()">Edit Order</button>
		</div>
	</div>
	<br>
	<p-tabView [(activeIndex)]="activeIndex">
		<p-tabPanel header="Details">
			<table class="table col-sm">
				<tbody>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>Buyer</td>
						<td><a (click)="viewCompany(this.tS['orders'][this.tS.clickedIndex['orders']]?.idbuyer)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyer }}</a></td>
					</tr>
					<tr>
						<td>{{ this.pS.portalMod()['portal'] }} Reference</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}</td>
					</tr>
					<tr>
						<td>Customer PO</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.ordernumber }}</td>
					</tr>
					<tr>
						<td>Order Date</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderdate | date:'MMM d, y, EEE' }}</td>
					</tr>
					<tr>
						<td>Dispatch Date</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.dispatchdate | date:'MMM d, y, EEE' }}</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderstatus | titlecase }}</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>Contact</td>
						<td><a (click)="viewContact(this.tS['orders'][this.tS.clickedIndex['orders']]?.idcontact)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercon }}</a></td>
					</tr>
					<tr>
						<td>Email</td>
						<td><a href="mailto:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.buyeremail}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyeremail }}</a></td>
					</tr>
					<tr>
						<td>Telephone</td>
						<td><a href="tel:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.buyertel}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyertel }}</a></td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>Total Ordered</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.total | number:'1.0-0' }} ({{ this.tS['orders'][this.tS.clickedIndex['orders']]?.dispatched | number:'1.0-0' }} Dispatched)</td>
					</tr>
					<tr>
						<td>Order Value (FGP)</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.reqFGP | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }} ({{ this.tS['orders'][this.tS.clickedIndex['orders']]?.reqFGP / this.tS['orders'][this.tS.clickedIndex['orders']]?.total / 11.34 | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }} Avg)</td>
					</tr>
					<tr>
						<td>Dispatch Value (FGP)</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.disFGP | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }} ({{ this.tS['orders'][this.tS.clickedIndex['orders']]?.disFGP / this.tS['orders'][this.tS.clickedIndex['orders']]?.dispatched / 11.34 | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }} Avg)</td>
					</tr>
					<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.depositpaid>0">
						<tr class="tableseperator"><td></td><td></td></tr>			
						<tr>
							<td>Deposit Paid (from Buyer)</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.depositpaid | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }}</td>
						</tr>
					</ng-container>
					<tr>
						<td>Amount Outstanding</td>
						<td>{{ ( this.tS['orders'][this.tS.clickedIndex['orders']]?.orderstatus=='paid' ? 0 : this.tS['orders'][this.tS.clickedIndex['orders']]?.disFGP - this.tS['orders'][this.tS.clickedIndex['orders']]?.depositpaid ) | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }}</td>
					</tr>
					<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.fees.length>0">
						<tr class="tableseperator"><td></td><td></td></tr>			
						<ng-container *ngFor="let fee of this.tS['orders'][this.tS.clickedIndex['orders']]?.fees">
							<tr>
								<td>Fees: {{ fee.fee }}</td>
								<td>{{ fee.amount | currency:this.tS['orders'][this.tS.clickedIndex['orders']]?.currency:'symbol-narrow' }}</td>
							</tr>
						</ng-container>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>			
					<ng-container *ngFor="let detail of this.tS['orders'][this.tS.clickedIndex['orders']]?.details">
						<tr>
							<td>Style</td>
							<td>{{ detail.fullstyle }}</td>
						</tr>
						<tr *ngIf="detail.type=='nis' && detail.minskr>0">
							<td>Min SKR</td>
							<td>{{ detail.minskr | percent:'1.1' }}</td>
						</tr>
						<tr *ngIf="detail.type=='nis' && detail.maxuskr>0">
							<td>Max USKR</td>
							<td>{{ detail.maxuskr | percent:'1.1' }}</td>
						</tr>
						<tr *ngIf="detail.maxsize>0">
							<td>Maximum size</td>
							<td>{{ detail.maxsize }}mm</td>
						</tr>
						<tr *ngIf="detail.minsize>0">
							<td>Minimum size</td>
							<td>{{ detail.minsize }}mm</td>
						</tr>
						<tr>
							<td>Requested</td>
							<td>{{ detail.quantity | number:'1.0-0' }}: {{ ( detail.variety == 'A' ? 'Any' : ( detail.variety == 'H' ? 'Hybrid' : 'Integ' ) ) }}</td>
						</tr>
						<tr>
							<td>Dispatched</td>
							<td>{{ +detail.H + +detail.I | number:'1.0-0' }} Total : {{ ( detail.H > 0 ? detail.H + ' Hybrids' : '' ) }}{{ ( detail.H > 0 ? ', ' : '' ) }}{{ ( detail.I > 0 ? detail.I : '' ) | number:'1.0-0' }}{{ ( detail.I > 0 ? ' Integs' : '' ) }}</td>
						</tr>
						<tr>
							<td>Price</td>
							<td>{{ detail.price | currency:detail.currency:'symbol-narrow' }}</td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
					</ng-container>
					<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.iddetail !== null">
						<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.freightco>0">
							<tr>
								<td>Freight Forwarder</td>
								<td><a (click)="viewCompany(this.tS['orders'][this.tS.clickedIndex['orders']]?.freightco)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.freight }}</a></td>
							</tr>
							<tr>
								<td>Freight Forwarder - Contact</td>
								<td><a (click)="viewContact(this.tS['orders'][this.tS.clickedIndex['orders']]?.freightcontact)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.freightcon }}</a></td>
							</tr>
							<tr>
								<td>Freight Forwarder - Telephone</td>
								<td><a href="tel:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.freighttel}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.freighttel }}</a></td>
							</tr>
							<tr>
								<td>Freight Forwarder - Email</td>
								<td><a href="mailto:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.freightemail}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.freightemail }}</a></td>
							</tr>
							<tr class="tableseperator"><td></td><td></td></tr>
						</ng-container>
						<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeco>0">
							<tr>
								<td>Consignee</td>
								<td><a (click)="viewCompany(this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeco)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consignee }}</a></td>
							</tr>
							<tr>
								<td>Consignee - Contact</td>
								<td><a (click)="viewContact(this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecontact)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecon }}</a></td>
							</tr>
							<tr>
								<td>Consignee - Telephone</td>
								<td><a href="tel:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneetel}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneetel }}</a></td>
							</tr>
							<tr>
								<td>Consignee - Email</td>
								<td><a href="mailto:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeemail}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeemail }}</a></td>
							</tr>
							<tr class="tableseperator"><td></td><td></td></tr>
						</ng-container>
						<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.notifyco>0">
							<tr>
								<td>2nd Notify Party</td>
								<td><a (click)="viewCompany(this.tS['orders'][this.tS.clickedIndex['orders']]?.notifyco)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.notify }}</a></td>
							</tr>
							<tr>
								<td>2nd Notify Party - Contact</td>
								<td><a (click)="viewContact(this.tS['orders'][this.tS.clickedIndex['orders']]?.notifycontact)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.notifycon }}</a></td>
							</tr>
							<tr>
								<td>2nd Notify Party - Telephone</td>
								<td><a href="tel:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.notifytel}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.notifytel }}</a></td>
							</tr>
							<tr>
								<td>2nd Notify Party - Email</td>
								<td><a href="mailto:{{this.tS['orders'][this.tS.clickedIndex['orders']]?.notifyemail}}">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.notifyemail }}</a></td>
							</tr>
							<tr class="tableseperator"><td></td><td></td></tr>
						</ng-container>
						<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.logisticsco>0">
							<tr>
								<td>Logistics</td>
								<td><a (click)="viewCompany(this.tS['orders'][this.tS.clickedIndex['orders']]?.logisticsco)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.logistics }}</a></td>
							</tr>
							<tr class="tableseperator"><td></td><td></td></tr>
						</ng-container>
						<tr>
							<td>Export Instruction Date</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.instructiondate | date:'MMM d, y, EEE' }}</td>
						</tr>
						<tr>
							<td>Booking Reference Number</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.bookingref }}</td>
						</tr>
						<tr>
							<td>HTS Code</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.hts=='0802.61.0000' ? '0802.61.0000 - Macadamia Nuts, In Shell, Fresh Or Dried' : '0802.62.0000 - Macadamia Nuts, Shelled, Fresh Or Dried' ) }}</td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr>
							<td>2020 Incoterm</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.incoterm }} {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.delport }}</td>
						</tr>
						<tr>
							<td>Payment Terms</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.paymentterms }}</td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.bankaccountno!=null">
							<td>Bank Account</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.bankname }} [{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.accountname }}: {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.bankaccountno }}]</td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr>
							<td>Carrier</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.carrier }}</td>
						</tr>
						<tr>
							<td>Vessel Name</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vesselname }}</td>
						</tr>
						<tr>
							<td>Vessel Voyager Number</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vesselno }}</td>
						</tr>
						<tr>
							<td>Container Type</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.containertype }}</td>
						</tr>
						<tr>
							<td>Container Number</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.containerno }}</td>
						</tr>
						<tr>
							<td>Seal Serial Number</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.sealno }}</td>
						</tr>
						<tr>
							<td>Truck Reg No.</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.truckreg }}</td>
						</tr>
						<tr>
							<td>Phostoxin Batch No</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.phostoxin }}</td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr>
							<td>Dispatched From</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.expport == null ? '' : this.tS['orders'][this.tS.clickedIndex['orders']]?.expport + ', ') + this.tS['orders'][this.tS.clickedIndex['orders']]?.expcountry }}</td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.delcountry!==null">
							<td>Destination</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.delport == null ? '' : this.tS['orders'][this.tS.clickedIndex['orders']]?.delport + ', ') + this.tS['orders'][this.tS.clickedIndex['orders']]?.delcountry }}</td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.stackingdatefrom!==null">
							<td>Stacking Dates</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.stackingdatefrom | date:'MMM d, y, EEE' }} - {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.stackingdateto | date:'MMM d, y, EEE' }}</td>
						</tr>
						<tr>
							<td>Expected Loading Date</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.exploadingdate | date:'MMM d, y, EEE' }}</td>
						</tr>
						<tr>
							<td>ETD</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.etd | date:'MMM d, y, EEE' }}</td>
						</tr>
						<tr>
							<td>ETA</td>
							<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.eta | date:'MMM d, y, EEE' }}</td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr>
							<td>VAT Invoice</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.vatinvoice==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.vatinvoice==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vatinvoice_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Packing List</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.packlist==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.packlist==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.packlist_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.style.substr(0,1)=='N'">
							<td>PPECB Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.ppecb==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.ppecb==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.ppecb_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.style.substr(0,1)=='N'">
							<td>Phytosanitary Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.phyto==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.phyto==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.phyto_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Microbiological Test Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.micro==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.micro==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.micro_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Certificate of Origin</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.certoforigin==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.certoforigin==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.certoforigin_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Certificate of Analysis</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.certofanalysis==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.certofanalysis==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.certofanalysis_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.style.substr(0,1)=='N'">
							<td>Fumigation Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.fumigation==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.fumigation==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.fumigation_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Bill of Lading - Draft</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.billofladingdraft==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.billofladingdraft==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.billofladingdraft_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Bill of Lading</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.billoflading==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.billoflading==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.billoflading_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.delcountry=='United States'">
							<td>ISF (Importer Security Filing)</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.isf==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.isf==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.isf_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr *ngIf="this.countryService.checkEU(this.tS['orders'][this.tS.clickedIndex['orders']]?.delcountry)">
							<td>EUR1 Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.eur1==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.eur1==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.eur1_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Weight SOLAS Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.solas==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.solas==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.solas_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Telex Release</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.telexrelease==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.telexrelease==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.telexrelease_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr>
							<td>Seaway Bill</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.seawaybill==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.seawaybill==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.seawaybill_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.style.substr(0,1)=='N'">
							<td>Export Certificate</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.exportcert==1 ? 'Complete ' : 'Not complete' ) }}<span *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.exportcert==1">[ {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.exportcert_date | date:'MMM d, y, EEE' }} ]</span></td>
						</tr>
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr>
							<td>Documents Couriered</td>
							<td>{{ (this.tS['orders'][this.tS.clickedIndex['orders']]?.docscouriered==1 ? 'Yes' : 'No' ) }}</td>
						</tr>
						<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.docscouriered==1">
							<tr>
								<td>Courier</td>
								<td><a (click)="viewCompany(this.tS['orders'][this.tS.clickedIndex['orders']]?.courier)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.couriername }}</a></td>
							</tr>
							<tr>
								<td>Tracking Number</td>
								<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.trackingno }}</td>
							</tr>
							<tr>
								<td>Couriered Date</td>
								<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.couriereddate | date:'MMM d, y, EEE' }}</td>
							</tr>
						</ng-container>
						<tr class="tableseperator"><td></td><td></td></tr>
					</ng-container>
					<tr>
						<td>Notes</td>
						<td>{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.notes }}</td>
					</tr>
					<tr>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</p-tabPanel>
		<p-tabPanel header="Commercial Invoice">
			<app-commercialinvoice></app-commercialinvoice>
		</p-tabPanel>
		<p-tabPanel header="VAT Invoice">
			<app-vatinvoice></app-vatinvoice>
		</p-tabPanel>
		<ng-container *ngIf="this.tS.quality['pallets'].length>0">
			<p-tabPanel header="Quality Certificate">
				<app-qualitycert></app-qualitycert>
			</p-tabPanel>
			<ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.type=='nis'">
				<p-tabPanel header="Packing List">
					<app-packinglist></app-packinglist>
				</p-tabPanel>
				<p-tabPanel header="Fumigation Certificate">
					<app-fumigation></app-fumigation>
				</p-tabPanel>
				<p-tabPanel header="PPECB Certificate">
					<app-ppecb></app-ppecb>
				</p-tabPanel>
				<p-tabPanel header="Shipping Instruction">
					<app-shippinginstruction></app-shippinginstruction>
				</p-tabPanel>
			</ng-container>
		</ng-container>
	</p-tabView>
	<br><br>
</div>