import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BuyingLoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { MenuItem } from 'primeng/api';

declare var require: any;

@Injectable()
export class BuyingHeaderService{
  items: MenuItem[];
  enditems: MenuItem[];
  fullitems: MenuItem[];
  loginitems: MenuItem[];
  blank: MenuItem[] = [];

	constructor(
		private loginService: BuyingLoginService,
		private portalService: PortalService,
		private router: Router) {}

  onLogout() {
  	this.loginService.logOut();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  isActive(option, setting) {
    var ans = false;
    if (this.isAuthenticated(option)) {
      for (var i = 0; i < this.portalService.portalMod()['settings'].length; i++) {
        if (this.portalService.portalMod()['settings'][i]['setting']==setting) {
          ans = (this.portalService.portalMod()['settings'][i]['value']==1);
        }
      }
    }
    return ans;
  }

  refreshMenu(origin = null) {
    this.loginitems = [
      {
        label: 'Log In',
        command: (event: Event) => { this.onLogout() }
      }
    ];
    this.items = [
      {
        label: 'Overview',
        items: [
          {
            label: 'Available Stock',
            routerLink: '/buying/offers',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            styleClass: 'lastseparator' 
          }
        ]
      }
    ];
    this.enditems = [
      {
        label: 'Log Out',
        styleClass: 'logoutlabel',
        command: (event: Event) => { this.onLogout() }
      }
    ];
    this.fullitems = [...this.items,...this.enditems];
  }

}