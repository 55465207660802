import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { BankAccountsService } from './bankaccounts.service';
import { CountryService } from '../../../services/countries.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-bankaccounts',
  templateUrl: './bankaccounts.component.html',
  styleUrls: ['./bankaccounts.component.scss']
})
export class BankAccountsComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public tableService: TableDataService,
    public bAS: BankAccountsService,
    public countryService: CountryService
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.bAS.resetForm();
    this.dropDownDataService.getDropdown("bankAccountListAll");
    this.countryService.country = {"id": this.countryService.countries[159]['id'], "country": "South Africa"};
    this.countryService.setState(+this.countryService.country.id);
  }

  ngOnDestroy() {
    this.bAS.idcompany = 0;
    this.bAS.resetForm();
    this.bAS.editmode = false;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  canSave() {
    if (
        (this.bAS.companyname !== undefined ? this.bAS.companyname.toString().replace(/ /g, "") !== "" : false) && 
        (this.bAS.bankname !== undefined ? this.bAS.bankname.toString().replace(/ /g, "") !== "" : false) && 
        (this.bAS.bankaccount !== undefined ? this.bAS.bankaccount.toString().replace(/ /g, "") !== "" : false) && 
        (this.bAS.banksortcode !== undefined ? this.bAS.banksortcode.toString().replace(/ /g, "") !== "" : false)
      ) {
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.bAS.idcompany = 0;
    this.bAS.resetForm();
    this.bAS.editmode = state;
    this.bAS.archived = false;
  }

  editSelected() {
    return (this.bAS.editmode == false || (this.bAS.editmode == true && this.bAS.idcompany > 0));
  }

  onCalculate() {
    if (this.canSave()) {
      this.bAS.addAccount();
      window.scroll(0,0);
      this.bAS.resetForm();
      this.dropDownDataService.getDropdown("bankAccountListAll");
    }
  }

  async onDelete(type = 'delete') {
    const res = await this.loginService.confirm("Are sure you want to " + type + " this bank account?");
    if (res) {
      this.bAS.deleteEntry(type);
      this.bAS.resetForm();
      this.dropDownDataService.getDropdown("bankAccountListAll");
    }
  }
  
}