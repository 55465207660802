import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesno'
})
export class YesNoPipe implements PipeTransform {

  transform(value: string | number): string {
    if (+value > 0) {
    	return 'Yes';
    }
    return 'No';
  }

}
