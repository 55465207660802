import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TeamLoginService } from '../../auth/login/teamlogin.service';

import { InventoryService } from '../../overview/inventory/inventory.service';
import { PortalService } from '../../../auth/portal.service';


@Component({
  selector: 'app-invsummary',
  templateUrl: './invsummary.component.html',
  styleUrls: ['./invsummary.component.scss']
})
export class InvSummaryWidget implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public inventoryService: InventoryService,
    public portalService: PortalService,
  	) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  async refresh(event) {
    await this.inventoryService.loadInventoryGrouped();
    event.target.blur();
  }

}
