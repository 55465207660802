import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { Setting } from '../general/general.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class SeasonalSettingsService{
	private postBody: {};
	httpRes: any;
	public editmode: boolean = false;
	public loadedEntry: any;
	public season: number;
	public setting: string;
	public settings: Setting[] = [{"setting": null, "value": null}];
	public numberMode: string = 'continue';
	public firstNumber: number = 1;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService,
		public dropDownDataService: DropDownDataService) {}

	addItem() {
		var fields = {"setting": null, "value": null};
		this.settings = this.splitTermsService.addItem(this.settings,fields);
	}

	removeItem(index: number) {
		this.settings = this.splitTermsService.removeItem(this.settings, index);
	}

	addSettings() {
		this.fixDates();
		this.postBody = {
			"name": "addSeasonalSettings",
			"param":{
				"season": this.season,
		    "settings": this.settings
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Settings for season " + this.season + " were not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	saveChanges(duplicate: boolean = false) {
		this.fixDates();
		this.postBody = {
			"name": "updateSeasonalSettings",
			"param":{
				"season": this.season,
		    "settings": this.settings
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Settings for season " + this.season + " were not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() { 
		this.postBody = {
			"name": "deleteSeasonalSettings",
			"param":{
		    "season": this.season,
		    "settings": this.settings
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']['message']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Settings for season " + this.season + " were not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  loadForEdit() {
		this.postBody = {
			"name": "loadSeasonalSettings",
			"param": {
				"season": this.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.settings = rawData.response.result.reply;
		for (var i = 0; i < this.settings.length; i++) {
			if (this.settings[i]['setting']=='deliverynostart') {
				if (this.settings[i]['value']=='continue' || this.settings[i]['value']=='reset') {
					this.numberMode = this.settings[i]['value'];
				} else {
					this.numberMode = 'set';
					this.firstNumber = +this.settings[i]['value'];
				}
			}
		}
	}

  checkType(index,type) {
  	var ans = true;
		for (var i = 0; i < this.settings.length; i++) {
			if (this.settings[i]['setting']==type && index!==i) {
				ans = false;
			}
		}
		return ans;
	}

  checkSeason(season) {
  	var ans = true;
		for (var i = 0; i < this.dropDownDataService.dropdown.savedSeasonSettingList?.length; i++) {
			if (this.dropDownDataService.dropdown.savedSeasonSettingList[i]['season']==season) {
				ans = false;
			}
		}
		return ans;
	}

	setValue(index) {
		if (this.settings[index]['setting']=='deliverynostart') {
			if (this.numberMode=='set') {
				this.settings[index]['value'] = this.firstNumber + '';
			} else {
				this.settings[index]['value'] = this.numberMode + '';
			}
		}
	}

	checkSettings() {
		var ans = true;
		if (this.settings.length<1) {
			return false;
		}
		for (var i = 0; i < this.settings.length; i++) {
			if (this.settings[i]['setting']==null || this.settings[i]['value']==null) {
				ans = false;
			}
		}
		return ans;
	}

  resetForm(keepRef: boolean = false) {
    window.scroll(0,0);
    this.editmode = false;
		this.season = null;
    this.settings = [{"setting": null, "value": null}];
    this.numberMode = 'continue';
    this.firstNumber = 1;
  }

  fixDates() {
		for (var i = 0; i < this.settings.length; i++) {
			if (this.settings[i]['setting']=='defaultseasonstart') {
				this.settings[i]['value'] = moment(this.settings[i]['value']).format('YYYY-MM-DD');
			}
		}
  }



}