<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
  <br>
  <div *ngIf="multipleMembers()">
    <div class="row justify-content-between">
      <div class="col main-header">
        <h2>Invoice Generator</h2>
      </div>
      <div class="dropdown col-auto" dropdown>
        <h5>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>{{ this.loginService.selectedmembername }}<span class="caret"></span></a>
          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <button 
              class="dropdown-item waves-light py-3" 
              mdbWavesEffect 
              (click)="onMemberSelected(mship['membership'], mship['companyname'])"
              *ngFor='let mship of this.loginService.memberships'>{{ mship['companyname'] }}</button>
          </ul>
        </h5>
      </div>
    </div>
  </div>
  <div *ngIf="!multipleMembers()">
    <div class="row justify-content-between">
      <div class="col-xs-6 ml-3">
        <h2>Invoice Generator</h2>
      </div>
    </div>
  </div>
  <br>

  <div class="col px-0">      
    <div class="form-container">

      <mat-form-field class="col px-0">
        <mat-select placeholder="Delivery Number" [(ngModel)]="this.invoiceService.idinvoice" (ngModelChange)="this.loadForEdit()" (keyup)="this.loadForEdit()">
          <mat-option *ngFor="let invoice of this.loginService.dropdown.remittanceList?.invoices; let invIndex=index" #invoice (click)="this.loadForEdit()" [class.duenow]="duenow(invoice.paydate)" [class.outofdate]="!duenow(invoice.paydate)" [value]="invIndex">{{ invoice.title }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col px-0" *ngIf="this.invoiceService.idinvoice!=null" (ngModelChange)="this.invoiceService.textAdjustments()" (keyup)="this.invoiceService.textAdjustments()">
        <input matInput placeholder="Custom Invoice Reference" maxlength="45" [(ngModel)]="this.invoiceService.customref">
      </mat-form-field>

      <br>
      <br>
    
    </div>  
  </div>

  <ng-container *ngIf="this.invoiceService.invoice!=null">
    <app-farmerinvoice></app-farmerinvoice>
  </ng-container>
</div>