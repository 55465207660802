import { Component, OnInit } from '@angular/core';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { CalendarOptions } from '@fullcalendar/core';
import { CsvService } from '../../services/csv.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
	clickedIndex;
  memberSelect;
  dataStore;
  producer = '0';
  producerBookingList;
  events: any[];
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin,listPlugin],
    initialView: 'timeGridWeek',
    headerToolbar: {
      left: 'prev,next,today',
      center: 'title',
      right: 'timeGridWeek,dayGridMonth,listWeek,listMonth,listYear'
    },
    buttonText: {
      today: 'Today',
      dayGridMonth: 'Month',
      timeGridWeek: 'Week',
      listMonth: 'List Month',
      listWeek: 'List Week',
      listYear: 'List Year'
    },
    dayMaxEvents: true,
    events: [],
    businessHours: {
      // days of week. an array of zero-based day of week integers (0=Sunday)
      daysOfWeek: [ 1, 2, 3, 4, 5 ],
      startTime: '07:00',
      endTime: '16:00',
    },
    slotMinTime: '05:00:00',
    slotMaxTime: '21:00:00',
    allDaySlot: false,
    height: 800
  };

  constructor(
  	public loginService: LoginService,
    public portalService: PortalService,
    public csvService: CsvService,
    ) { }

  ngOnInit() {
    this.getProducers();
    this.refreshCal();
  }

  clearAll() {
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  cacheForMember() {
    if (this.memberSelect != null) {
    }
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onSeasonSelected(season) {
    this.clearAll();
    this.loginService.season = season;
    this.cacheForMember();
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  async csvDownload() {
    var p = {
      "season": this.loginService.season,
      "producer": this.loginService.selectedmember,
      "idproducer": this.producer,
    }
    this.dataStore = await this.loginService.loadData('bookingsCalendarCSV', p);
    var entity = '';
    if (this.producer=='0') {
      entity = 'All'
    } else {
      for (var i = 0; i < this.producerBookingList.length; i++) {
        if (this.producerBookingList[i]['idcompany']==this.producer) {
          entity = this.producerBookingList[i]['companyname'];
        }
      }
    }
    var title = 'Delivery Schedule - ' + this.loginService.season + " [" + entity + "]"
    this.csvService.rawCSV(title, this.dataStore.headers, this.dataStore.events);
  }

  async getProducers() {
    this.producerBookingList = await this.loginService.loadData('producerBookingList');
  }

  refreshCal() {
    var p = {
      "season": this.loginService.season,
      "producer": this.loginService.selectedmember,
      "idproducer": this.producer,
    }
    this.loginService.loadData('bookingsCalendar', p).then(events => {
      this.events = events;
      this.calendarOptions = {...this.calendarOptions, ...{events: events}};
    })
  }

  

  
}
