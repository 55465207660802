import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common'

@Pipe({
  name: 'datemod'
})
export class DatemodPipe extends DatePipe implements PipeTransform {

  override transform(value: any, args?: any): any {
    let result;
    if (value == 'Any') {
      return value;
    }
    result = super.transform(value, args);
    return result;
  }

}
