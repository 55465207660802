import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Process, RootObject } from './processing.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class ProcessingService{
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public clickedIndex: number;
	public totalDeliveries: number;
	public grouptype: string = 'All';
  public protype: string = 'all';

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService) {}

	getProcesses(): Observable<RootObject[]> {
		this.postBody = {
			"name":"processes",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}

	loadProcesses() {
		this.postBody = {
			"name":"processes",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.totalDeliveries = 0;
		this.dataStore = rawData.response.result.reply;
		
		for (var i = 0; i < this.dataStore.length ; i++) {
			this.totalDeliveries = this.totalDeliveries + 1;
		}
	}

	setProcess(idd: number) {
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (+this.dataStore[i].idprocessing == idd) {
				this.clickedIndex = i;
			}
		}
	}

}