<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>

	<div [ngClass]="logInState">
		<div [ngClass]="classState">
			<div class="my-4 col">
				<h2>QR - Dispatch</h2>
			</div>
			
			<div class="row justify-content-md mx-1">	
				<div class="col-12 col-md-12 col-lg-8">
					<div class="form-container">	
						<mat-form-field>
					    <mat-select placeholder="Select Order Reference" [(ngModel)]="this.lQR.orderref" (keydown.tab)="loadForLogging()">
					      <mat-option *ngFor="let order of this.lS.dropdown.dispatchStyles" (click)="loadForLogging()" value="{{ order.orderref }}">{{ order.orderref }} [ {{ (order.ordernumber=='' ? '* Not assigned *' : order.ordernumber) }} ]</mat-option>
					    </mat-select>
					  </mat-form-field>
					</div>
				</div>

				<div class="my-4 col-12 col-md-12 col-lg-8" [ngClass]="showScanner">
					<div>
						<h4>Label Scan Summary</h4>
					</div>
					<table class="my-4 table">
						<tbody>
							<tr class="tableseperator"><td></td><td></td></tr>
							<tr>
								<td>Total Scanned</td>
								<td>{{ ( this.lQR.dispatchCodes[0]['code'] == "" ? 0 : this.lQR.dispatchCodes.length ) }}</td>
							</tr>
							<tr class="tableseperator"><td></td><td></td></tr>
						</tbody>
					</table>

					<app-scanner #scanner
						(scanSuccess)="handleQrCodeResult($event)">
					</app-scanner>

					<hr>
					<div class="col-12 col-sm-12 col-md-12 col-lg-4 text-center my-4 mx-1">
						<button mdbBtn class="btn btn-primary mb-2" style="width:100%; max-width:200px; margin: auto auto;" [disabled]="!this.lQR.canDispatch()" (click)="this.lQR.saveToOrder()">Log to Order{{ ( this.lQR.dispatchCodes[0]['code'] == "" ? null : ' (' + this.lQR.dispatchCodes.length + ')' ) }}</button><br>
						<button mdbBtn class="btn btn-warning mt-4" style="width:100%; max-width:200px;" (click)="reset()">Reset</button>
					</div>
					<hr>

					<table class="my-4 table">
						<h4>Boxes Remaining for Order</h4>
						<tbody>
							<tr class="tableseperator"><td></td><td></td><td></td><td></td></tr>
								<tr class="header">
									<td>STYLE</td>
									<td>REQUIRED</td>
									<td>LOADED</td>
									<td>REMAINING</td>
								</tr>
								<tr *ngFor="let count of this.lQR.tally">
									<td>{{ count['variety'] }} {{ count['style'] }}</td>
									<td>{{ count['requested'] }}</td>
									<td>{{ count['count'] }}</td>
									<td>{{ count['requested'] - count['count'] }}</td>
								</tr>
							<tr class="tableseperator"><td></td><td></td><td></td><td></td></tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>