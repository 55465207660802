import { Component, OnInit, OnDestroy, ViewChild, VERSION } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';
import { QRCode } from '../../../interfaces/qr.interface';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { portalM } from '../../../auth/portal.service';
import { DropDownDataService } from '../dropdown.service';
import { AddPalletSampleService } from './add-palletsample.service';
import { TableDataService } from '../../overview/table/table.service';
import { PortalService } from '../../../auth/portal.service';

@Component({
  selector: 'app-add-pallet-sample',
  templateUrl: './add-palletsample.component.html',
  styleUrls: ['./add-palletsample.component.scss']
})
export class AddPalletSampleComponent implements OnInit {
  ngVersion = VERSION.full;
  @ViewChild('scanner', { static: true })
  scanner: ZXingScannerComponent;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  torch = true;
  classState = '';
  codeData: QRCode;
  showScanner: string = "loggedOut";
  lines: [];

  d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
  	public ddS: DropDownDataService,
    public aPS: AddPalletSampleService,
    public tableService: TableDataService,
    public pS: PortalService,
  	) { }

  ngOnInit() {
    this.lines = portalM()['palletlines'];
    window.scrollTo(0,0);    
    this.onLoad();
  }

  ngOnDestroy() {
    this.aPS.editmode = false;
    this.aPS.pallet = null;
    this.aPS.palletname = null;
    this.aPS.resetForm();
  }

  async onLoad() {
    this.ddS.getDropdown("storageListWNIS");
    this.ddS.getDropdown("productList");
    this.ddS.getDropdown("palletList");
    this.aPS.resetForm(false);
  }

  isAuthenticated(option = null) {
    return this.loginService.isAuthenticated(option);
  }

  async handleQrCodeResult(resultString: string) {
    this.codeData = {code:''};
    this.qrResultString = resultString;
    this.codeData['code'] = resultString;
    await this.aPS.addCode(this.codeData);
    this.classState = (this.aPS.success ? "codeSave" : "" );
    setTimeout(() => {
      this.classState = "";
    }, 500);
  }

  canSave() {
    if (
      this.aPS.code['stylefull']!='' &&
      this.aPS.code['packdate']!='' &&
      this.aPS.code['iteration']!=null &&
      this.aPS.code['idstorage']!=null &&
      this.aPS.dnis > 0 &&
      this.aPS.moisture > 0 &&
      (this.aPS.a18 || 0) >= 0 &&
      (this.aPS.a20 || 0) >= 0 &&
      (this.aPS.a22 || 0) >= 0 &&
      (this.aPS.a24 || 0) >= 0 &&
      (this.aPS.a25 || 0) >= 0 &&
      (this.aPS.a26 || 0) >= 0 &&
      this.aPS.shell > 0 &&
      this.aPS.sk >= 0 &&
      (this.aPS.mould || 0) >= 0 &&
      (this.aPS.immature || 0) >= 0 &&
      (this.aPS.spot791 || 0) >= 0 &&
      (this.aPS.earlystink || 0) >= 0 &&
      (this.aPS.latestink || 0) >= 0 &&
      (this.aPS.germination || 0) >= 0 &&
      (this.aPS.nutborer || 0) >= 0 &&
      (this.aPS.discolouration || 0) >= 0 &&
      this.checktotalkernel() &&
      this.checkdnis() && 
      this.checkMoisture()
    ) {
      return true;
    } else {
      return false;
    }
  }

  checktotalkernel() {
    if (+this.aPS.tk + +this.aPS.shell == +this.aPS.dnis || (this.aPS.tk == undefined && this.aPS.dnis == undefined)) {
      return true;
    } else {
      return false;
    }
  }

  checkMoisture() {
    return this.aPS.moisture<5 && this.aPS.moisture>0;
  }

  checkdnis() {
    this.aPS.sizeddnis = this.aPS.sumdnis();
    if (this.aPS.sizeddnis == 0 || this.aPS.sizeddnis == this.aPS.dnis || this.aPS.sizeddnis == this.aPS.currentcount) {
      return true;
    } else {
      return false;
    }
  }

  unsoundError() {
    return (
      +this.aPS.uk != (
        +(this.aPS.mould !== undefined ? this.aPS.mould : 0) + 
        +(this.aPS.immature !== undefined ? this.aPS.immature : 0) +
        +(this.aPS.spot791 !== undefined ? this.aPS.spot791 : 0) +
        +(this.aPS.earlystink !== undefined ? this.aPS.earlystink : 0) +
        +(this.aPS.latestink !== undefined ? this.aPS.latestink : 0) +
        +(this.aPS.germination !== undefined ? this.aPS.germination : 0) +
        +(this.aPS.nutborer !== undefined ? this.aPS.nutborer : 0) +
        +(this.aPS.discolouration !== undefined ? this.aPS.discolouration : 0)
      ) ? true : false);
  }

  editModeOn(state) {
    this.aPS.resetForm();
    this.aPS.editmode = state;
    this.aPS.pallet = null;
    this.aPS.palletname = null;
  }

  editSelected() {
    return this.aPS.pallet!=null || this.aPS.manualpallet!=null;
  }

  loadForEdit() {
    this.aPS.loadForEdit();
  }

  saveSample() {
    if (this.canSave()) {
      this.aPS.addPalletSample();
      window.scroll(0,0);
      this.onLoad();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete this pallet sample?");
    if (res) {
      this.aPS.deleteEntry();
      this.aPS.resetForm();
    }
  }

}
