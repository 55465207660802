<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.recallService.editmode">Add Recall</h2>
				<h2 *ngIf="this.recallService.editmode">Edit Recall</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.recallService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/contacts">View Recalls</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Recall</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.recallService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/contacts">View Recalls</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Recall</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.recallService.editmode">
			    <mat-select placeholder="Contact Name" [(ngModel)]="this.recallService.idcontact" (keydown.tab)="this.recallService.loadForEdit()">
			      <mat-option *ngFor="let contact of this.dropDownDataService.dropdown.contactList; let contactIndex=index;" [class.archived]="contact.health=='archived'" #prod (click)="this.recallService.loadForEdit()" [value]="contact.idcontact">{{ contact.fullname }} [{{ contact.companyname }}]</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <br *ngIf="this.recallService.editmode">

			  <mat-form-field *ngIf="editSelected()">
			    <input matInput placeholder="Flag (shown on scanner)" maxlength="45" [(ngModel)]="this.recallService.flag">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
			    <mat-select placeholder="Location" [(ngModel)]="this.dropDownDataService.location['idlocation']">
			      <mat-option *ngFor="let location of this.dropDownDataService.dropdown.locationList" [value]="location.idlocation">{{ location.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
			    <mat-select placeholder="Storage" [(ngModel)]="this.recallService.origin">
			      <mat-option value="0">All Sources</mat-option>
			    	<ng-container *ngFor="let storage of this.dropDownDataService.dropdown.storageListWNIS">
			      	<mat-option value="{{ storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
			      </ng-container>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
			    <mat-select placeholder="Iteration" [(ngModel)]="this.recallService.iteration">
		      	<mat-option value="0">All Iterations</mat-option>
			    	<ng-container *ngFor="let iteration of this.dropDownDataService.dropdown.iterationList">
		      		<mat-option value="{{ iteration.iteration }}">{{ iteration.iteration }}</mat-option>
		      	</ng-container>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
			    <mat-select placeholder="Style" [(ngModel)]="this.recallService.style">
			    	<ng-container *ngFor="let product of this.dropDownDataService.dropdown['productList']">
			    		<mat-option value="{{ product.style }}">{{ product.style }}</mat-option>
			    	</ng-container>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
		    	<mat-label>Start Pack Date</mat-label>
			    <input matInput [matDatepicker]="batchstart" [(ngModel)]="this.recallService.batchstart">
					<mat-datepicker-toggle matIconSuffix [for]="batchstart"></mat-datepicker-toggle>
			    <mat-datepicker #batchstart></mat-datepicker>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
		    	<mat-label>End Pack Date</mat-label>
			    <input matInput [matDatepicker]="batchend" [(ngModel)]="this.recallService.batchend">
					<mat-datepicker-toggle matIconSuffix [for]="batchend"></mat-datepicker-toggle>
			    <mat-datepicker #batchend></mat-datepicker>
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
			    <textarea matInput placeholder="Notes" [(ngModel)]="this.recallService.note"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.recallService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.recallService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.recallService.saveChanges()">Update</button>

			<button *ngIf="this.recallService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete('delete')">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>