<div class="row justify-content-between">
	<div class="col-xs-6 ml-0 col main-header">
		<h3>Delivery {{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddelivery | delivery }}</h3>
	</div>
	<div class="mr-4 ml-3 col-auto">
		<button *ngIf="canRequest()" mdbBtn class="btn btn-info whitetext" (click)="resample()">Request Resample</button>
		<button *ngIf="cantRequest()" mdbBtn class="btn btn-secondary whitetext" disabled="true">Resample Unavailable</button>
	</div>
</div>
	
<br>
<table class="table col-sm">
	<tbody>
		<tr *ngIf="this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddelfarm!=null || this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddeldepot!=null" class="tableseperator"><td></td><td></td></tr>
		<ng-container *ngIf="this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddelfarm!=null">
			<tr>
				<td>Delivery Reference</td>
				<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddelfarm }}</td>
			</tr>
		</ng-container>
		<ng-container *ngIf="this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddeldepot!=null">
			<tr>
				<td>Depot Delivery Reference</td>
				<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.iddeldepot | delivery }}</td>
			</tr>
		</ng-container>
		<tr class="tableseperator"><td></td><td></td></tr>
		<tr>
			<td>Scales WNIS</td>
			<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.scale | number:'1.2-2' }} kg</td>
		</tr>
		<tr *ngIf="+this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.smallwnis > 0">
			<td class="pl-4">WNIS delivered</td>
			<td class="pl-4">{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.wnis | number:'1.2-2' }} kg</td>
		</tr>
		<tr *ngIf="+this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.smallwnis > 0">
			<td class="pl-4">Smalls WNIS</td>
			<td class="pl-4">{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.smallwnis | number:'1.2-2' }} kg</td>
		</tr>
		<tr class="tableseperator"><td></td><td></td></tr>
		<tr>
			<td>Date Delivered</td>
			<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.ddate | date:'MMM d, EEE, y' }}</td>
		</tr>
		<tr>
			<td>Cultivar</td>
			<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.cultivar }}</td>
		</tr>
		<tr>
			<td>Paid Collection?</td>
			<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.dcollected | yesno }}</td>
		</tr>
		<tr>
			<td>Sample Status</td>
			<td>{{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.status | titlecase }}</td>
		</tr>
		<ng-template [ngIf]="sampleComplete()">
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Moisture - Whole Nut (%)</td>
				<td>{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.moisture | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Moisture - Kernel (%)</td>
				<td>{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.wetkernel | percent:'1.2' }}</td>
			</tr>
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>Moisture removed (to {{ this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.dryfloorlevel | percent:'1.1' }} kernel moisture)</td>
				<td>{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.moisture - this.portalService.calMoisture(+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.dryfloor, 0) | percent:'1.2' }} &nbsp; &nbsp;
					({{ (+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.moisture - this.portalService.calMoisture(+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.dryfloor, 0)) * +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.swnis | number:'1.0-0' }}g)</td>
			</tr>
			<tr>
				<td>TKR</td>
				<td>
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.skr + +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.uskr + +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.darkP | percent:'1.2' }} &nbsp; &nbsp; ({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.sound + +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.unsound + +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.dark | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr>
				<td>SKR</td>
				<td>
					{{ this.tableService['samples'][this.tableService.clickedIndex['samples']]?.skr | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.sound | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr>
				<td>Darks / Onion Ring</td>
				<td>
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.darkP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.dark | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>USKR</td>
				<td>{{ this.tableService['samples'][this.tableService.clickedIndex['samples']]?.uskr | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.unsound | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.mould > 0">
				<td class="pl-4">Unsound - Old/Mould</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.mouldP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.mould | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.onion > 0">
				<td class="pl-4">Unsound - Onion</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.onionP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.onion | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.immature > 0">
				<td class="pl-4">Unsound - Immature</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.immatureP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.immature | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.spot > 0">
				<td class="pl-4">Unsound - 791 Spot</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.spotP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.spot | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.earlystink > 0">
				<td class="pl-4">Unsound - Early Stinkbug</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.earlystinkP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.earlystink | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.latestink > 0">
				<td class="pl-4">Unsound - Late Stinkbug</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.latestinkP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.latestink | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.germination > 0">
				<td class="pl-4">Unsound - Germination</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.germinationP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.germination | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.nutborer > 0">
				<td class="pl-4">Unsound - Nut Borer</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.nutborerP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.nutborer | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr *ngIf="+this.tableService['samples'][this.tableService.clickedIndex['samples']]?.discolouration > 0">
				<td class="pl-4">Unsound - Discolouration</td>
				<td class="pl-4">
					{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.discolourationP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.discolouration | number:'1.0-0' }}g)
				</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Shells</td>
				<td>{{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.shellP | percent:'1.2' }} &nbsp; &nbsp;
					({{ +this.tableService['samples'][this.tableService.clickedIndex['samples']]?.shell | number:'1.0-0' }}g)
				</td>
			</tr>
		</ng-template>
		<tr class="tableseperator"><td></td><td></td></tr>
	</tbody>
</table>
<br><br>