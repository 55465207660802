<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.addPaymentService.editmode">Add Payment</h2>
				<h2 *ngIf="this.addPaymentService.editmode">Edit Payment</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.addPaymentService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/payments">View Payments</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Payment</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.addPaymentService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/payments">View Payments</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Payment</button>
			</div>
		</div>
		<br>

		<div>
			<div class="col-md-6" style="display: inline-block;">
			 	
				<div class="form-container">

				  <mat-form-field>
    			<mat-label>Delivery Number</mat-label>
				    <mat-select placeholder="Delivery Number" [(ngModel)]="this.addPaymentService.iddelivery">
				      <mat-option 
				      	value="tbc" 
				      	*ngIf="(this.dropDownDataService.dropdown.uaPaymentList?.length > 0 && this.addPaymentService.editmode) || !this.addPaymentService.editmode" 
				      	(click)="this.addPaymentService.setUnassigned()">Unassigned</mat-option>
				      <mat-option 
				      	*ngFor="let payment of this.dropDownDataService.dropdown.paymentList; index as i;" 
				      	(click)="this.addPaymentService.instalment=''" 
				      	value="{{ i }}">{{ payment.iddelivery | delivery }}</mat-option>
				    </mat-select>
				  </mat-form-field>
				  
				  <mat-form-field>
    			<mat-label>Instalment</mat-label>
				    <mat-select placeholder="Instalment" [(ngModel)]="this.addPaymentService.instalment" 
				      	(keydown.tab)="loadForEdit()">
				    	<mat-option 
				    		*ngIf="showAdvanced()"
				      	(click)="loadForEdit()" 
				    		value="a">Advanced</mat-option>
				      <mat-option 
				      	*ngIf="this.addPaymentService.editmode && 1<=this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery]?.instalments"
				      	(click)="loadForEdit()" 
				      	value="1">First</mat-option>
				      <mat-option 
				      	*ngIf="this.addPaymentService.editmode && 2<=this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery]?.instalments" 
				      	(click)="loadForEdit()" 
				      	value="2">Second</mat-option>
				      <mat-option 
				      	*ngIf="this.addPaymentService.editmode && 3<=this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery]?.instalments" 			      	
				      	(click)="loadForEdit()" 
				      	value="3">Third</mat-option>
				      <mat-option 
				      	*ngIf="this.addPaymentService.editmode && 4<=this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery]?.instalments" 			      	
				      	(click)="loadForEdit()" 
				      	value="4">Fourth</mat-option>
				    	<mat-option 
				    		*ngIf="( this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery]?.bonus==1 && this.addPaymentService.editmode) || ( this.addPaymentService.iddelivery!=='tbc' && this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery]?.bonus==0 && !this.addPaymentService.editmode )"
				      	(click)="loadForEdit()" 
				    		value="b">Bonus</mat-option>
				    </mat-select>
				  </mat-form-field>

					<mat-form-field *ngIf="this.addPaymentService.editmode && this.addPaymentService.iddelivery=='tbc'">
    			<mat-label>Producer - Advance</mat-label>
				    <mat-select placeholder="Producer - Advance" [(ngModel)]="this.addPaymentService.idproducertemp">
				      <mat-option 
				      	*ngFor="let producer of this.dropDownDataService.dropdown.uaPaymentList" 
				      	(click)="loadForEdit()" 
				      	value="{{ producer.idcompany }}">{{ producer.companyname }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="!this.addPaymentService.editmode && this.addPaymentService.iddelivery=='tbc'">
    			<mat-label>Producer - No Advance</mat-label>
				    <mat-select placeholder="Producer - No Advance" [(ngModel)]="this.addPaymentService.idproducertemp" (keydown.tab)="loadForEdit()">
				      <mat-option 
				      	*ngFor="let producer of this.dropDownDataService.dropdown.uaNoPaymentList" 
				      	(click)="loadForEdit()" 
				      	[value]="producer.idcompany">{{ producer.companyname }}</mat-option>
				    </mat-select>
				  </mat-form-field>
			  	
			  	<br *ngIf="this.addPaymentService.editmode">

				  <mat-form-field *ngIf="this.addPaymentService.editmode && this.editSelected() && this.addPaymentService.iddelivery!=='tbc'">
    			<mat-label>Updated Delivery Number</mat-label>
				    <mat-select placeholder="Updated Delivery Number" [(ngModel)]="this.addPaymentService.iddeliverytemp">
				      <mat-option *ngFor="let payment of this.dropDownDataService.dropdown.paymentList; index as i;" value="{{ i }}">{{ payment.iddelivery | delivery }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				</div>
			</div>
		</div>

		<div *ngIf="editSelected()">
			<div class="col-md-6" style="display: inline-block;">	 	
				<div class="form-container">

					<hr>

				  <mat-form-field *ngIf="this.addPaymentService.iddelivery!=='tbc'">
    			<mat-label>Producer</mat-label>
				    <input matInput class="readonly" placeholder="Producer" readonly [ngModel]="this.addPaymentService.producer">
				  </mat-form-field>

				  <mat-form-field *ngIf="this.addPaymentService.iddelivery=='tbc' && this.addPaymentService.editmode">
    			<mat-label>Updated Producer</mat-label>
				    <mat-select placeholder="Updated Producer" [(ngModel)]="this.addPaymentService.idproducer">
				      <mat-option *ngFor="let producer of this.dropDownDataService.dropdown.producerList" #prod value="{{ producer.idcompany }}">{{ producer.companyname }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="this.addPaymentService.iddelivery=='tbc' && this.addPaymentService.editmode">
    			<mat-label>Assigned Delivery Number</mat-label>
				    <mat-select placeholder="Assigned Delivery Number" [(ngModel)]="this.addPaymentService.assigneddelno">
				      <mat-option value="tbc">Unassigned</mat-option>
				      <ng-container *ngFor="let payment of this.dropDownDataService.dropdown.paymentList">
				      	<mat-option *ngIf="payment.idproducer==this.addPaymentService.idproducer" #prod value="{{ payment.iddelivery }}">{{ payment.iddelivery | delivery }}</mat-option>
				      </ng-container>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field>
    			<mat-label>Due Date</mat-label>
				    <input matInput class="readonly" [matDatepicker]="duedate" [ngClass]="{'readonly': this.addPaymentService.editmode}" [(ngModel)]="this.addPaymentService.paydate">
						<mat-datepicker-toggle matIconSuffix [for]="duedate"></mat-datepicker-toggle>
				    <mat-datepicker #duedate></mat-datepicker>
				  </mat-form-field>

					<hr>
				
				  <mat-form-field>
    			<mat-label>Amount Paid</mat-label>
				    <input matInput placeholder="Amount Paid" #amountpaid [ngModel]="+this.addPaymentService.amountpaid | currency:this.addPaymentService.paycurrency:'symbol-narrow'" (blur)="changeAmountPaid(amountpaid.value)">
				  </mat-form-field>

				  <mat-form-field>
    			<mat-label>Paid Date</mat-label>
				    <input matInput [matDatepicker]="paiddate" [(ngModel)]="this.addPaymentService.paiddate">
					<mat-datepicker-toggle matIconSuffix [for]="paiddate"></mat-datepicker-toggle>
			    <mat-datepicker #paiddate></mat-datepicker>
				  </mat-form-field>

	 				<mat-form-field>
    			<mat-label>Payment Currency</mat-label>
				    <mat-select placeholder="Payment Currency" [(ngModel)]="this.addPaymentService.paycurrency">
				    	<mat-option value="USD" (click)="adjustFX('USD')">USD</mat-option>
				    	<mat-option value="ZAR" (click)="adjustFX('ZAR')">ZAR</mat-option>
				    </mat-select>
				  </mat-form-field>

	 				<mat-form-field>
    			<mat-label>Remittance Currency</mat-label>
				    <mat-select placeholder="Remittance Currency" [disabled]="this.addPaymentService.editmode" [(ngModel)]="this.addPaymentService.invcurrency">
				    	<mat-option value="USD">USD</mat-option>
				    	<mat-option value="ZAR">ZAR</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <hr>
				  
				  <mat-form-field>
    			<mat-label>FX Rate</mat-label>
				    <input matInput type="number" step="0.0001" placeholder="FX Rate" [(ngModel)]="this.addPaymentService.fxrate">
				  </mat-form-field>

				  <mat-form-field>
    			<mat-label>FX Date</mat-label>
				    <input matInput [matDatepicker]="fxdate" [(ngModel)]="this.addPaymentService.fxdate">
						<mat-datepicker-toggle matIconSuffix [for]="fxdate"></mat-datepicker-toggle>
				    <mat-datepicker #fxdate></mat-datepicker>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>FX Source</mat-label>
				    <mat-select placeholder="FX Source" [(ngModel)]="this.addPaymentService.fxsource">
				    	<mat-option value="bankrate">Bank Rate</mat-option>
				    	<mat-option value="estimate">Estimate</mat-option>
				    	<mat-option value="fixed">Fixed</mat-option>
				    	<mat-option value="spot">Spot Price</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field>
    			<mat-label>EFT Number</mat-label>
				    <input matInput placeholder="EFT Number" [(ngModel)]="this.addPaymentService.eft">
				  </mat-form-field>

				  <hr>

				  <ng-container *ngIf="this.addPaymentService.invcurrency=='ZAR'">
					  <mat-form-field>
    				<mat-label>Amount Due</mat-label>
					    <input matInput [readonly]="(this.addPaymentService.editmode && +this.addPaymentService.instalment>0)" placeholder="Amount Due" #amountdue [ngClass]="{'readonly': (this.addPaymentService.editmode && +this.addPaymentService.instalment>0)}" [ngModel]="+this.addPaymentService.amountdueZAR | currency:'ZAR':'symbol-narrow'" (blur)="changeAmountDue(amountdue.value)">
					  </mat-form-field>
					</ng-container>

				  <ng-container *ngIf="this.addPaymentService.invcurrency!='ZAR'">
					  <mat-form-field>
    				<mat-label>Amount Due</mat-label>
					    <input matInput class="readonly" placeholder="Amount Due" readonly [ngModel]="+this.addPaymentService.amountdueZAR | currency:'ZAR':'symbol-narrow'">
					  </mat-form-field>
					</ng-container>

				  <mat-form-field>
    				<mat-label>Amount Paid</mat-label>
				    <input matInput class="readonly" placeholder="Amount Paid" readonly [ngModel]="+this.addPaymentService.amountpaidZAR | currency:'ZAR':'symbol-narrow'">
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Amount Outstanding</mat-label>
				    <input matInput class="readonly" placeholder="Amount Outstanding" readonly [ngModel]="( +this.addPaymentService.amountdue == 0 ? 0 : +this.addPaymentService.amountdueZAR - +this.addPaymentService.amountpaidZAR ) | currency:'ZAR':'symbol-narrow'">
				  </mat-form-field>

					<hr>

				  <ng-container *ngIf="this.addPaymentService.invcurrency=='ZAR'">
					  <mat-form-field>
    					<mat-label>Amount Due (USD)</mat-label>
					    <input matInput class="readonly" placeholder="Amount Due (USD)" readonly [ngModel]="+this.addPaymentService.amountdueUSD | currency:'USD':'symbol-narrow'">
					  </mat-form-field>
					</ng-container>

				  <ng-container *ngIf="this.addPaymentService.invcurrency!='ZAR'">
					  <mat-form-field>
		    			<mat-label>Amount Due (USD)</mat-label>
					    <input matInput [readonly]="(this.addPaymentService.editmode && +this.addPaymentService.instalment>0)" placeholder="Amount Due (USD)" #amountdueUSD [ngClass]="{'readonly': (this.addPaymentService.editmode && +this.addPaymentService.instalment>0)}" [ngModel]="+this.addPaymentService.amountdueUSD | currency:'USD':'symbol-narrow'" (blur)="changeAmountDue(amountdueUSD.value)">
					  </mat-form-field>
					</ng-container>

				  <mat-form-field>
    			<mat-label>Amount Paid (USD)</mat-label>
				    <input matInput class="readonly" placeholder="Amount Paid (USD)" readonly [ngModel]="( +this.addPaymentService.amountpaidUSD > 0 ? +this.addPaymentService.amountpaidUSD : 0 ) | currency:'USD':'symbol-narrow'">
				  </mat-form-field>

				  <mat-form-field>
    			<mat-label>Amount Outstanding (USD)</mat-label>
				    <input matInput class="readonly" placeholder="Amount Outstanding (USD)" readonly [ngModel]="( +this.addPaymentService.amountdue==0 ? 0 : +this.addPaymentService.amountdueUSD - +this.addPaymentService.amountpaidUSD ) | currency:'USD':'symbol-narrow'">
				  </mat-form-field>

				</div>
			</div>
			
			<div class="col-md-6" *ngIf="editSelected()">
				
				<hr *ngIf="editSelected()">
				
				<mat-form-field style="width:100%">
    			<mat-label>Notes</mat-label>
				  <textarea matInput placeholder="Notes" [(ngModel)]="this.addPaymentService.note"></textarea>
				</mat-form-field>
			
				<hr *ngIf="editSelected()">
			
				<button *ngIf="!this.addPaymentService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="savePayment()">Save</button>
				
				<button *ngIf="this.addPaymentService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.addPaymentService.saveChanges()">Update</button>
				
				<button *ngIf="this.addPaymentService.editmode && this.editSelected() && this.canDelete()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

				<br><br>

			</div>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>