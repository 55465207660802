import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { BuyingLoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { BuyingHeaderService } from '../header/header.service';

@Component({
  selector: 'app-buyinghome',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class BuyingHomeComponent implements OnInit {
	deliveryData: {};
	memberSelect;
  d: Date = new Date();

  constructor(
  	public loginService: BuyingLoginService,
    public portalService: PortalService,
    private headerService: BuyingHeaderService
  	) { }

  ngOnInit() {
  	if(this.loginService.isAuthenticated()) {
      this.memberSelect = this.loginService.selectedmember;
      this.cacheForMember();
    }
    window.scrollTo(0,0);
  }

   cacheForMember() {
    if (this.memberSelect != null) {
    }
    this.loginService.memberships = JSON.parse(localStorage.getItem('buying_memberships'));
  }

  onMemberSelected(membership, membername) {
    this.memberSelect = this.loginService.selectMembership(membership);
    this.headerService.refreshMenu();
    this.cacheForMember();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

}
