<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated() && isAuthorised('finance') && isAuthorised('admin')">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.bAS.editmode">Add Bank Account</h2>
				<h2 *ngIf="this.bAS.editmode">Edit Bank Account</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.bAS.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Bank Account</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.bAS.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Bank Account</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.bAS.editmode">
    			<mat-label>Bank Account</mat-label>
			    <mat-select placeholder="Bank Account" [(ngModel)]="this.bAS.idcompany" (keydown.tab)="this.bAS.loadForEdit()">
			      <mat-option *ngFor="let company of this.dropDownDataService.dropdown.bankAccountListAll; let contactIndex=index;" [class.archived]="company.health=='archived'" #prod (click)="this.bAS.loadForEdit()" [value]="company.idcompany">{{ company.bankname }} [{{ company.companyname }}: {{ company.bankaccount }}]</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <br *ngIf="this.bAS.editmode">

			  <mat-form-field>
    			<mat-label>Account Name</mat-label>
			    <input matInput placeholder="Account Name" [disabled]="this.bAS.archived" maxlength="90" [(ngModel)]="this.bAS.companyname">
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Bank Name</mat-label>
			    <input matInput placeholder="Bank Name" [disabled]="this.bAS.archived" maxlength="90" [(ngModel)]="this.bAS.bankname">
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Account Number</mat-label>
			    <input matInput placeholder="Account Number" [disabled]="this.bAS.archived" maxlength="90" [(ngModel)]="this.bAS.bankaccount">
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Branch Code</mat-label>
			    <input matInput placeholder="Branch Code" [disabled]="this.bAS.archived" maxlength="90" [(ngModel)]="this.bAS.banksortcode">
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Swift Code</mat-label>
			    <input matInput placeholder="Swift Code" [disabled]="this.bAS.archived" maxlength="90" [(ngModel)]="this.bAS.bankswift">
			  </mat-form-field>

			  <hr>

			  <mat-form-field>
    			<mat-label>Country</mat-label>
			    <mat-select placeholder="Country" [disabled]="this.bAS.archived" [(value)]="this.countryService.country.id" (keydown.tab)="this.countryService.setState(this.countryService.country.id)">
			      <mat-option *ngFor="let country of this.countryService.countries" [value]="country.id" (click)="this.countryService.setState(this.countryService.country.id)">{{ country.country }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>State</mat-label>
			    <mat-select placeholder="State" [disabled]="this.bAS.archived" [(ngModel)]="this.countryService.state">
			      <mat-option *ngFor="let state of this.countryService.states" value="{{ state }}">{{ state }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Address</mat-label>
			    <input matInput placeholder="Address" [disabled]="this.bAS.archived" maxlength="90" [(ngModel)]="this.bAS.address">
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Area</mat-label>
			    <input matInput placeholder="Area" [disabled]="this.bAS.archived" maxlength="45" [(ngModel)]="this.bAS.area">
			  </mat-form-field>

			   <mat-form-field>
    			<mat-label>Post Code</mat-label>
			    <input matInput placeholder="Post Code" [disabled]="this.bAS.archived" maxlength="45" [(ngModel)]="this.bAS.postcode">
			  </mat-form-field>

			   <mat-form-field>
    			<mat-label>PO Box</mat-label>
			    <input matInput placeholder="PO Box" [disabled]="this.bAS.archived" maxlength="45" [(ngModel)]="this.bAS.pobox">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [disabled]="this.bAS.archived" [(ngModel)]="this.bAS.note"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.bAS.editmode && !this.bAS.archived" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.bAS.editmode && this.editSelected() && !this.bAS.archived" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.bAS.saveChanges()">Update</button>

			<button *ngIf="this.bAS.editmode && this.editSelected() && !this.bAS.archived" mdbBtn class="btn btn-info" (click)="onDelete('archive')">Archive</button>

			<button *ngIf="this.bAS.editmode && this.editSelected() && !this.bAS.archived" mdbBtn class="btn btn-danger" (click)="onDelete('delete')">Delete</button>

			<button *ngIf="this.bAS.editmode && this.editSelected() && this.bAS.archived" mdbBtn class="btn btn-info" (click)="onDelete('unarchive')">Unarchive</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>