<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6">
				<h2>Internal Users - Portal Access</h2>
			</div>
		</div>
		<br>
		<br>
		
	 	<h3>Active User Accounts</h3> 
	 	<br>

		<div class="form-container" autocomplete="off">
		  <mat-form-field>
		    <input matInput placeholder="Search text" maxlength="45" autocomplete="random-search-text" id="search-text" [(ngModel)]="this.clientsService.searchtext" (keyup)="this.clientsService.filterTable()">
		  </mat-form-field>
		</div>

		<table class="table col" mdbTableScroll [scrollY]="scroll" maxHeight="500" bordered="true">
			<tbody>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
				<tr>
					<th>User</th>
					<th>Email</th>
					<th>Role</th>
					<th>Membership</th>
					<th class="aligncenter">Unblock</th>
					<th class="aligncenter">Reset</th>
					<th class="aligncenter">Assign</th>
				</tr>

				<ng-container *ngFor="let client of this.clientsService.filteredData; let clientIndex=index">
					<ng-container *ngFor="let membership of client.memberships; let mIndex=index;">
						<ng-container *ngIf="true">
							<tr *ngIf="mIndex==0" class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
							<tr *ngIf="mIndex==0">
								<td>{{ client.firstname + ' ' + client.lastname }}</td>
								<td>{{ client.email }}</td>
								<td>{{ membership.role | titlecase }}</td>
								<td><button mdbBtn class="btn btn-danger" style="padding: 5px 20px;" (click)="this.clientsService.removeAcc(client.idcontact, membership.role)">Remove</button></td>
								<td><button mdbBtn class="btn btn-info" style="padding: 5px 20px;" (click)="this.clientsService.unblock(client.idcontact)">Unblock</button></td>
								<td><button mdbBtn class="btn btn-warning" style="padding: 5px 20px;" (click)="this.clientsService.passReset(client.idcontact)">Reset</button></td>
								<td><button mdbBtn class="btn btn-success" style="padding: 5px 20px;" (click)="this.passAssign(client.idcontact,client.firstname + ' ' + client.lastname)">Assign</button></td>
							</tr>
							<tr *ngIf="mIndex>0">
								<td></td>
								<td></td>
								<td>{{ membership.role | titlecase }}</td>
								<td><button mdbBtn class="btn btn-danger" style="padding: 5px 20px;" (click)="this.clientsService.removeAcc(client.idcontact, membership.role)">Remove</button></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</ng-container>
					</ng-container>
				</ng-container>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			</tbody>
		</table>	

		<div class="col" id="popup">
			<div class="form-container text-center" autocomplete="off">
				<h4>Enter New Password for:</h4>
				<br>
				<h4>{{ name }}</h4>
				<br>
			  <mat-form-field>
			    <input matInput placeholder="Enter password" maxlength="45" autocomplete="new-password" [(ngModel)]="this.pass" (ngModelChange)="this.validateNewPWord()" [type]="checked? 'text' : 'password'">
			  </mat-form-field>
			  <br>
			  <ng-container *ngIf="!passwordLength">
			  	<strong style="color: red;">Use at least 8 characters.</strong>
			  	<br>
			  </ng-container>
			  <ng-container *ngIf="!passwordSpecial">
			  	<strong style="color: red;">Do not use spaces or special characters!</strong>
			  	<br>
			  </ng-container>
			  <mat-checkbox
	      	[(ngModel)]="checked">
	        Show Password
	      </mat-checkbox>
	      <br>
		    <button mdbBtn class="btn btn-primary" [disabled]="!passwordOk" (click)="passAssignSave()">Save</button>
		    <br>
		    <button mdbBtn class="btn btn-danger" (click)="cancelSave()">Cancel</button>    
			</div>
		</div>

		<br><br>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>