import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
am4core.useTheme(am4themes_animated); 
//am4core.useTheme(am4themes_material);

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Silo, RootObject } from './silos.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class SiloService{
	private postBody: {};
	httpRes: any;
	httpChart: any;
	public dnis = [];
	public wnis = [];
	public delnos = [];
	public dataStore: any;
	public clickedIndex: number = null;
	public nisview: string = 'wnis';

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private zone: NgZone) {}

	getSilo(): Observable<RootObject[]> {
		this.postBody = {
			"name":"silos",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}

	loadSilo() {
		this.postBody = {
			"name":"silos",
			"param":{
				"season": +this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

loadSiloChart() {
		this.postBody = {
			//"name":"siloChart",
			"name":"siloWithSmalls",
			"param":{
				"season": +this.loginService.season,
				"dryfloor": 0.020
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
        	this.httpChart = response;
          this.storeChart(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.dnis = [];
		this.wnis = [];
		this.delnos = [];
		this.dataStore = rawData.response.result.reply;
		if(rawData.response && rawData.response.result && rawData.response.result.reply[0] && rawData.response.result.reply[0].season) {
			//this.loginService.season = rawData.response.result.reply[0].season; 
		}
	}

	storeChart(rawData, original = true) {
		this.httpChart = (original ? rawData.response.result.reply : rawData);
		for (let i in this.httpChart['dnis']) {
			this.dnis.push(this.httpChart['dnis'][i]);
		}
		for (let i in this.httpChart['wnis']) {
			this.wnis.push(this.httpChart['wnis'][i]);
		}
		this.loadChart(this[this.nisview]);
	}

	reloadChart(view) {
		this.nisview = view;
		this.storeChart(this.httpChart, false);
	}

	setSilo(idd: number) {
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (+this.dataStore[i].style == idd) {
				this.clickedIndex = i;
			}
		}
	}

	loadChart(chartdata) {
		this.zone.runOutsideAngular(() => {
	    let chart = am4core.create("chartdiv", am4charts.XYChart3D);
	    chart.paddingRight = 20;

	    chart.data = chartdata;

	    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	    categoryAxis.dataFields.category = "category";
	    categoryAxis.renderer.grid.template.location = 0;
	    categoryAxis.renderer.grid.template.strokeOpacity = 0;
	    categoryAxis.renderer.minGridDistance = 30;

	    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	    valueAxis.renderer.grid.template.strokeOpacity = 0;
	    valueAxis.min = -10000;
	    valueAxis.max = 119000;
	    valueAxis.strictMinMax = true;
	    valueAxis.renderer.baseGrid.disabled = true;
	    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
	      return text + " kg";
	    });

	    function createSeries(field, delivery) {
	      // Set up series
	      var series = chart.series.push(new am4charts.ConeSeries());
	      series.name = delivery.name;
	      series.dataFields.valueY = field;
	      series.dataFields.categoryX = "category";
	      series.sequencedInterpolation = true;
	      
	      // Make it stacked
	      series.stacked = true;
	      
	      // Configure columns
	      series.columns.template.width = am4core.percent(80);
	      series.columns.template.tooltipText = 
	      	"[bold]" + delivery.name + 
	      	"[/]\n[font-size:14px][bold]Delivery: " + delivery.iddelivery + 
	      	"[/]\n[font-size:14px][bold]{categoryX}: {valueY} kg" + 
	      	"[/]\n[font-size:14px][bold]Cultivar: " + delivery.cultivar + 
	      	"[/]\n[font-size:14px]Moisture: " + delivery.moisture + 
	      	"[/]\n[font-size:14px]SKR: " + delivery.skr +
	      	"[/]\n[font-size:14px]USKR: " + delivery.uskr +
	      	"[/]\n[font-size:14px]SKR: " + delivery.skr +
	      	"[/]\n[font-size:14px]-" +
	      	"[/]\n[font-size:14px]S0: " + delivery.s0 +
	      	"[/]\n[font-size:14px]S1: " + delivery.s1 +
	      	"[/]\n[font-size:14px]S4l: " + delivery.s4l +
	      	"[/]\n[font-size:14px]S4r: " + delivery.s4r +
	      	"[/]\n[font-size:14px]S567: " + delivery.s567 +
	      	"[/]\n[font-size:14px]-" +
	      	"[/]\n[font-size:14px]>18mm: " + delivery.a18 +
	      	"[/]\n[font-size:14px]>20mm: " + delivery.a20 +
	      	"[/]\n[font-size:14px]>22mm: " + delivery.a22 +
	      	"[/]\n[font-size:14px]>24mm: " + delivery.a24 ;
	      series.tooltip.autoTextColor = true;
				//series.tooltip.label.fill = am4core.color("#FFFFFF");
	      return series;
	    }

	    for (let i in this.httpChart['delnos']) {
				createSeries(i, this.httpChart['delnos'][i]);
	    }

	    let series2 = chart.series.push(new am4charts.ConeSeries());
	    series2.dataFields.valueY = "value3";
	    series2.dataFields.categoryX = "category";
	    series2.stacked = true;
	    series2.columns.template.width = am4core.percent(80);
	    series2.columns.template.fill = am4core.color("#000");
	    series2.columns.template.fillOpacity = 0.1;
	    series2.columns.template.stroke = am4core.color("#000");
	    series2.columns.template.strokeOpacity = 0.2;
	    series2.columns.template.strokeWidth = 2;

	    return chart;
	  });
	}

}