import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { Contact } from '../../../interfaces/contacts.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class StockRecallService{
	private postBody: {};
	httpRes: any;
	public createform: boolean;
	public editmode: boolean = false;
	public archived: boolean = false;
	public idcontact: number = 0;
	public loadedEntry: Contact;
  public fname: string;
  public lname: string;
	public company: string;
  public firstname: string;
  public lastname: string;
  public telephone: string;
  public email: string;
  public birthday: string;
  public shirtsize: string;
  public note: string;
  public health: string;

  public style: string;
  public batchstart: string;
  public batchend: string;
  public flag: string;
  public origin: string;
  public iteration: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private portalService: PortalService) {}

	addContact() {
		this.fixDate();
		this.fname = this.firstname;
		this.lname = this.lastname;
		this.postBody = {
			"name": "addContact",
			"param":{
				"idcompany": +this.company,
				"firstname": this.firstname,
				"lastname": this.lastname,
				"telephone": this.telephone,
				"email": this.email,
				"birthday": this.birthday,
				"shirtsize": this.shirtsize,				
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response.response.result['reply']['message']);
	        	this.fname = "";
	        	this.lname = "";
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	loadForEdit() {
		this.postBody = {
			"name": "loadContact",
			"param":{
				"idcontact": +this.idcontact
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply[0];
		this.company = this.loadedEntry.idcompany;
		this.firstname = this.loadedEntry.firstname;
		this.lastname = this.loadedEntry.lastname;
		this.telephone = this.loadedEntry.telephone;
		this.email = this.loadedEntry.email;
		this.birthday = this.loadedEntry.birthday;
		this.shirtsize = this.loadedEntry.shirtsize;
		this.note = this.loadedEntry.notes;
		this.health = this.loadedEntry.health;
		this.editmode = true;
	}

	saveChanges() {
		this.fixDate();
		this.postBody = {
			"name": "updateContact",
			"param":{
				"idcontact": +this.idcontact,
				"idcompany": +this.company,
				"firstname": this.firstname,
				"lastname": this.lastname,
				"telephone": this.telephone,
				"email": this.email,
				"birthday": this.birthday,
				"shirtsize": this.shirtsize,				
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response.response.result['reply']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry(type = 'delete') {
		const firstnametemp = this.firstname;
		const lastnametemp = this.lastname;
		this.postBody = {
			"name": (type=='delete' ? "deleteContact" : (type=='archive' ? 'archiveContact' : 'unarchiveContact') ),
			"param":{
				"idcontact": +this.idcontact
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response.response.result['reply']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
    this.archived = false;
    this.company = "";
    this.firstname = "";
    this.lastname = "";
    this.telephone = "";
    this.email = "";
    this.birthday = "";
    this.shirtsize = "";
    this.note = "";
    this.health = "";
  }

	fixDate() {
	  this.batchstart = moment(this.batchstart).format('YYYY-MM-DD');
	  this.batchend = moment(this.batchend).format('YYYY-MM-DD');
	}

}