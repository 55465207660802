import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
am4core.useTheme(am4themes_animated); 
//am4core.useTheme(am4themes_material);

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Silo, RootObject } from './silolevels.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class SiloLevelsService{
	private postBody: {};
	httpRes: any;
	public siloChart: any =[];
	public delnos = [];
	public dataStore: any;
	public chartloading: boolean = false;
	public clickedIndex: number = null;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private zone: NgZone) {}


	loadSiloChart() {
		this.chartloading = true;
		this.postBody = {
			"name":"chartSiloLevels",
			"param":{
				"location": +this.loginService.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
        	this.siloChart = response;
          this.storeChart(response);
        },
        (error) => console.log(error)
      );
	}

	storeChart(rawData, original = true) {
		this.siloChart = (original ? rawData.response.result.reply : rawData);
		this.loadChart(this.siloChart);
	}

	reloadChart(view) {
		this.storeChart(this.siloChart, false);
	}

	loadChart(chartdata) {
		this.zone.runOutsideAngular(() => {
	    let chart = am4core.create("chartdiv", am4charts.XYChart3D);
	    chart.paddingRight = 20;
	    chart.height = 200;

	    chart.data = chartdata;

	    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
	    categoryAxis.dataFields.category = "name";
	    categoryAxis.renderer.grid.template.location = 0;
	    categoryAxis.renderer.grid.template.strokeOpacity = 0;
	    categoryAxis.renderer.minGridDistance = 30;

	    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	    valueAxis.renderer.grid.template.strokeOpacity = 0;
	    valueAxis.min = 0;
	    valueAxis.max = 110;
	    valueAxis.strictMinMax = true;
	    valueAxis.renderer.minGridDistance = 25;
	    valueAxis.renderer.baseGrid.disabled = true;
	    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
	      return text + " %";
	    });

	    function createSeries(silo,field) {
	      // Set up series
	      var series = chart.series.push(new am4charts.ConeSeries());
	      series.name = 'Silo Levels';
	      series.dataFields.valueY = field;
	      series.dataFields.categoryX = "name";
	      series.sequencedInterpolation = true;
	      // Make it stacked
	      series.stacked = true;
	      // Configure columns
	      series.columns.template.width = am4core.percent(80);
	      return series;
	    }

			createSeries(this.siloChart,'level');

	    let series2 = chart.series.push(new am4charts.ConeSeries());
	    series2.dataFields.valueY = "remaining";
	    series2.dataFields.categoryX = "name";
	    series2.stacked = true;
	    series2.columns.template.width = am4core.percent(80);
	    series2.columns.template.fill = am4core.color("#000");
	    series2.columns.template.fillOpacity = 0.1;
	    series2.columns.template.stroke = am4core.color("#000");
	    series2.columns.template.strokeOpacity = 0.2;
	    series2.columns.template.strokeWidth = 2;

	    this.chartloading = false;
	    return chart;
	  });
	}

}