<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>

	<div *ngIf="isAuthenticated()">
		<br>
		<div class="my-4 col">
			<h2>Trace Stock from Farm</h2>
		</div>
		
		<div class="row justify-content-md mx-1">	
			<div class="col-12 col-md-12 col-lg-12">
				<div class="form-container">	

					<mat-form-field>
				    <mat-select placeholder="Selection" [(ngModel)]="this.traceabilityService.selectionbydate" (ngModelChange)="refreshProducers()">
				      <mat-option value="all">All Deliveries</mat-option>
				      <mat-option value="date">Date Range</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <ng-container *ngIf="this.traceabilityService.selectionbydate=='date'">

					  <mat-form-field>
					    <input matInput type="date" placeholder="Date From" [(ngModel)]="this.traceabilityService.fromdate" (ngModelChange)="refreshProducers()">
					  </mat-form-field>

					  <mat-form-field>
					    <input matInput type="date" placeholder="Date To" [(ngModel)]="this.traceabilityService.todate" (ngModelChange)="refreshProducers()">
					  </mat-form-field>

					</ng-container>

					<mat-form-field>
				    <mat-select placeholder="Select Producer" [(ngModel)]="this.traceabilityService.producer">
				      <mat-option *ngFor="let producer of this.traceabilityService.producers; let pIndex=index" (click)="loadForLogging(pIndex)" value="{{ producer.idcompany }}">{{ producer.companyname }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				</div>
			</div>

			<div class="my-4 col-12 col-md-12 col-lg-12" *ngIf="this.traceabilityService.producer!==null">
				<br>
				<div class="row justify-content-between">
					<div class="col-xs-6 col-9">
						<h2>Deliveries</h2>
					</div>
					<div class="col-auto">
						<button mdbBtn class="btn btn-info" (click)="csv('batchtable')">CSV</button>
					</div>
				</div>
				<br>
				<div style="overflow-x:auto;overflow-y:auto;max-height:600px">
					<table class="my-4 table" id="batchtable">
						<tbody>
							<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
							<tr class="header">
								<td>Batch</td>
								<td>Storage</td>
								<td>Iteration</td>
								<td>Delivery</td>
								<td>Date</td>
								<td>Cultivar</td>
								<td>Total WNIS (kg)</td>
							</tr>
							<ng-container *ngFor="let delivery of this.traceabilityService.dataStore?.deliveries; let delIndex=index;">
								<tr class="tableseperator" *ngIf="(delIndex==0 ? true : (this.traceabilityService.dataStore?.deliveries[delIndex - 1]['batch']!==delivery.batch ? true : false ) )"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
								<tr (click)="this.deliverySelected(delivery)" [class.selectedRow]="this.traceabilityService.selectedBatch==delivery.idbatch">
									<ng-container *ngIf="(delIndex==0 ? true : (this.traceabilityService.dataStore?.deliveries[delIndex - 1]['batch']!==delivery.batch ? true : false ) )">
										<td>{{ delivery.batch }}</td>
										<td>{{ delivery.storage | titlecase }}</td>
										<td>{{ delivery.iteration }}</td>
									</ng-container>
									<ng-container *ngIf="(delIndex==0 ? false : (this.traceabilityService.dataStore?.deliveries[delIndex - 1]['batch']!==delivery.batch ? false : true ) )" [class.selectedRow]="this.traceabilityService.selectedBatch==delivery.idbatch">
										<td></td>
										<td></td>
										<td></td>
									</ng-container>
									<td>{{ delivery.iddelivery | delivery }}</td>
									<td>{{ delivery.ddate | date:'dd-MM-y' }}</td>
									<td>{{ delivery.cultivar | titlecase }}</td>
									<td>{{ delivery.twnis | number:'1.2-2' }} kg</td>
								</tr>
							</ng-container>
							<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
						</tbody>
					</table>
				</div>
				<br>
				<br>
				
				<ng-container *ngIf="this.traceabilityService.selectedBatch!==null">
					<br>
					<div class="row justify-content-between">
						<div class="col-xs-6 col-9">
							<h2>Stock from Batch: {{ this.traceabilityService.selectedBatchName }}</h2>
						</div>
						<div class="col-auto">
							<button mdbBtn class="btn btn-info" (click)="csv('ordertable')">CSV</button>
						</div>
					</div>
					<br>
					<div style="overflow-x:auto;overflow-y:auto;max-height:600px">
						<table class="my-4 table" id="ordertable">	
							<tbody>
								<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td></tr>
								<tr class="header">
									<td>Order / Location</td>
									<td>Total Units</td>
									<td>Style</td>
									<td>Style Total</td>
									<td>Start Label</td>
									<td>End Label</td>
								</tr>
								<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td></tr>
								<ng-container *ngFor="let storage of this.traceabilityService.dataStore?.traceFarmBatches?.stock | keyvalue">
									<ng-container *ngFor="let iteration of storage.value | keyvalue">
										<ng-container *ngFor="let order of iteration.value | keyvalue">
											<ng-container *ngFor="let style of order.value['style'] | keyvalue; let sIndex=index">

													<tr (click)="orderSelected(order.value)" [class.selectedRow]="this.traceabilityService.orderref==(order.value['status']=='packed' ? order.value['location'] : order.value['orderref'])">
														<td>{{ (sIndex==0 ? (order.value['status']=='packed' ? order.value['location'].toString().toUpperCase() : order.value['orderref']) : '') }}</td>
														<td>{{ (sIndex==0 ? order.value['total'] : '') }}</td>
														<td>{{ style.value['style'] }}</td>
														<td>{{ style.value['total'] }}</td>
														<td>{{ storage['key'].toString().toUpperCase() + '/' + ("0" + iteration.key).slice(-2) + '/' + style.value['range'].minbatchdate }}</td>
														<td>{{ storage['key'].toString().toUpperCase() + '/' + ("0" + iteration.key).slice(-2) + '/' + style.value['range'].maxbatchdate }}</td>
													</tr>
											
											</ng-container>
													<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td></tr>
										</ng-container>
									</ng-container>
								</ng-container>
							</tbody>
						</table>
					</div>
				</ng-container>

				<br>
				<br>
				
				<ng-container *ngIf="this.tableService.clickedIndex['orders']!==null">
					<app-teamorder></app-teamorder>
				</ng-container>

			</div>
		</div>
	</div>
</div>