import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { Inventory, RootObject } from './tracepackinglist.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TracePackingService } from './tracepackinglist.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-tracepackinglist',
  templateUrl: './tracepackinglist.component.html',
  styleUrls: ['./tracepackinglist.component.scss']
})
export class TracePackingComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [
    'packdate',
    'style',
    'variety',
    'total',
    'source',
    'label',
    'batch',
    'dispatchdate',
    'intakestart',
    'intakeend',
    'orderref',
    'ordernumber',
    'status'
    ];

  inventoryDB: TracePackingService | null;
  dataSource: Inventory[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;
  showTrace: string = "loggedOut";

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
  	public packingService: TracePackingService,
    public dropDownDataService: DropDownDataService,
    public csvService: CsvService,
    private http: HttpClient
  	) { }

  ngOnDestroy() {
    this.packingService.orderref = '';
  }

  ngOnInit() {
    this.dropDownDataService.getDropdown('traceOrderList');
    window.scrollTo(0,0);
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  loadForLogging() {
    this.packingService.loadInventory();
    this.showTrace = (this.packingService.orderref !== "" ? "loggedIn" : "loggedOut");
    this.updateTable();
  }

  updateTable() {
    var tempOrderRef = this.packingService.orderref;
    this.inventoryDB = new TracePackingService(this.http, this.loginService);
      this.inventoryDB.orderref = tempOrderRef;
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.inventoryDB!.getInventory();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply;
          this.datasrc = new MatTableDataSource(this.dataSource);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Packing List - ' + this.packingService.orderref + ' -');
  }

  calTotal(field: string){ 
    var total = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + +this.datasrc.filteredData[i][field];
    }
    return total;
  }

  headerUnit() {
    if (this.packingService.dataStore !== undefined) {
      if (this.packingService.dataStore[0]['style'].substr(0,1)=="N") {
        return 'Weight (kg)';
      } else {
        return 'Boxes';
      }
      return 'Boxes';
    }
  }

}