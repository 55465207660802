import { Component, OnInit, AfterViewInit } from '@angular/core';

import { LoginService } from '../auth/login/login.service';
import { ClientTableDataService } from '../table/table.service';

@Component({
  selector: 'app-remittancedetail',
  templateUrl: './remittancedetail.component.html',
  styleUrls: ['./remittancedetail.component.scss']
})
export class RemittanceDetailComponent implements OnInit {

  constructor(
  	private loginService: LoginService,
    public tableService: ClientTableDataService
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

}
