import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';

import { portalM } from './auth/portal.service';
import { PortalService } from './auth/portal.service';

// Client
import { AuthGuard } from './auth-guard.service';
import { ClientAppComponent } from './client/clientapp.component';
import { HomeComponent } from './client/home/home.component';
import { OverviewComponent } from './client/overview/overview.component';
import { FarmerInvoiceSelectorComponent } from './client/invoiceselector/invoiceselector.component';
import { ScheduleComponent } from './client/schedule/schedule.component';
import { FarmComponent } from './client/farm/farm.component';
import { LoginComponent } from './client/auth/login/login.component';
import { ResetComponent } from './client/auth/reset/reset.component';
import { UnavailableComponent } from './client/auth/unavailable/unavailable.component';
import { CalculatorComponent } from './client/calculator/calculator.component';
import { ClientTableComponent } from './client/table/table.component';

// Logistics
import { LogisticsAuthGuard } from './logistics/logisticsauth-guard.service';
import { LogisticsAppComponent } from './logistics/logisticsapp.component';
import { LogisticsLoginComponent } from './logistics/auth/login/login.component';
import { LogisticsResetComponent } from './logistics/auth/reset/reset.component';
import { LogisticsUnavailableComponent } from './logistics/auth/unavailable/unavailable.component';
import { LogisticsHomeComponent } from './logistics/home/home.component';
import { LogisticsTableComponent } from './logistics/table/table.component';
//import { LogisticsAddContactComponent } from './logistics/logging/add-contact/add-contact.component';
//import { LogisticsAddCompanyComponent } from './logistics/logging/add-company/add-company.component';
//import { LogisticsAddOrderComponent } from './logistics/logging/add-order/add-order.component';
import { LogisticsAddQrComponent } from './logistics/qr/add/add-qr.component';
import { LogisticsDispatchQrComponent } from './logistics/qr/dispatch/dispatch-qr.component';

// Buying
import { BuyingAuthGuard } from './buying/buyingauth-guard.service';
import { BuyingAppComponent } from './buying/buyingapp.component';
import { BuyingLoginComponent } from './buying/auth/login/login.component';
import { BuyingResetComponent } from './buying/auth/reset/reset.component';
import { BuyingUnavailableComponent } from './buying/auth/unavailable/unavailable.component';
import { BuyingHomeComponent } from './buying/home/home.component';
import { BuyingTableComponent } from './buying/table/table.component';
import { BuyingBidOffersComponent } from './buying/bidoffers/bidoffers.component';

// Team
import { TeamAuthGuard } from './team/teamauth-guard.service';
import { TeamAppComponent } from './team/teamapp.component';
import { TeamLoginComponent } from './team/auth/login/teamlogin.component';
import { TeamResetComponent } from './team/auth/reset/reset.component';
import { TeamHomeComponent } from './team/home/teamhome.component';
import { AddContactComponent } from './team/logging/add-contact/add-contact.component';
import { AddCompanyComponent } from './team/logging/add-company/add-company.component';
import { AddDeliveryComponent } from './team/logging/add-delivery/add-delivery.component';
import { AddSampleBatchedComponent } from './team/logging/add-sample-batched/add-sample.component';
import { AddPalletSampleComponent } from './team/logging/add-palletsample/add-palletsample.component';
import { AddPaymentComponent } from './team/logging/add-payment/add-payment.component';
import { AddProcessingComponent } from './team/logging/add-processing/add-processing.component';
import { AddCurrencyComponent } from './team/logging/add-currency/add-currency.component';
import { AddOrderComponent } from './team/logging/add-order/add-order.component';
import { AddBookingComponent } from './team/logging/add-booking/add-booking.component';
import { TeamCalculatorComponent } from './team/calculator/teamcalculator.component';
import { AddQrComponent } from './team/qr/add/add-qr.component';
import { InspectQrComponent } from './team/qr/inspect/inspect-qr.component';
import { AddArticleComponent } from './team/messages/add-article/add-article.component';
import { DispatchQrComponent } from './team/qr/dispatch/dispatch-qr.component';
import { TraceScanComponent } from './team/traceability/tracescan/tracescan.component';
import { TraceFarmComponent } from './team/traceability/tracefarm/tracefarm.component';
import { TraceInventoryComponent } from './team/traceability/traceinventory/traceinventory.component';
import { TracePackingComponent } from './team/traceability/tracepackinglist/tracepackinglist.component';
import { StockRecallComponent } from './team/traceability/stockrecall/stockrecall.component';
import { RecoverPalletComponent } from './team/overview/recoverpallet/recoverpallet.component';
import { ProcessingComponent } from './team/overview/processing/processing.component';
import { CurrencyComponent } from './team/overview/currency/currency.component';
import { InventoryComponent } from './team/overview/inventory/inventory.component';
import { BatchesComponent } from './team/overview/batches/batches.component';
import { SilosComponent } from './team/overview/silos/silos.component';
import { SiloSelectorComponent } from './team/silo-selector/silo-selector.component';
import { LogDepotDeliveryComponent } from './team/depot/log-delivery/log-delivery.component';
import { SettingsClientsNewComponent } from './team/settings/clients/clients-new/clients-new.component';
import { SettingsClientsActiveComponent } from './team/settings/clients/clients-active/clients-active.component';
import { SettingsClientsActivityComponent } from './team/settings/clients/clients-activity/clients-activity.component';
import { SettingsUsersNewComponent } from './team/settings/users/users-new/users-new.component';
import { SettingsUsersActiveComponent } from './team/settings/users/users-active/users-active.component';
import { SettingsUsersActivityComponent } from './team/settings/users/users-activity/users-activity.component';
import { PriceModelComponent } from './team/settings/pricemodel/pricemodel.component';
import { SeasonalSettingsComponent } from './team/settings/seasonal/seasonal.component';
import { GeneralSettingsComponent } from './team/settings/general/general.component';
import { EmailSettingsComponent } from './team/settings/email/email.component';
import { StorageComponent } from './team/settings/storage/storage.component';
import { CultivarComponent } from './team/settings/cultivars/cultivars.component';
import { RemittanceRebuilderComponent } from './team/settings/remittance-rebuilder/remittance-rebuilder.component';
import { QueryComponent } from './team/query/query.component';
import { SchedulingComponent } from './team/scheduling/scheduling.component';
import { TableComponent } from './team/overview/table/table.component';

import { BankAccountsComponent } from './team/settings/bankaccounts/bankaccounts.component';

import { VATInvoiceComponent } from './team/docs/vatinvoice/vatinvoice.component';


var routes: Routes = [
	( portalM()['mod-clients'] || portalM()['mod-all'] ? 
		{ path: '', component: ClientAppComponent, 
			children: [
				{ path: '', redirectTo: 'login', pathMatch: 'full' },
				{ path: 'reset', component: ResetComponent },
				{ path: 'login', component: LoginComponent },
			]
		} 
		: { path: '', component:ClientAppComponent, 
			children: [
				{ path: '', redirectTo: 'not-available', pathMatch: 'full' },
				{ path: 'login', redirectTo: 'not-available', pathMatch: 'full' },
				{ path: 'not-available', component: UnavailableComponent },
			] 
		} 
	),
	
	( portalM()['mod-logistics'] || portalM()['mod-all'] ? 
		{ path: 'logistics', component: LogisticsAppComponent, 
			children: [
				{ path: '', redirectTo: 'login', pathMatch: 'full' },
				{ path: 'reset', component: LogisticsResetComponent },
				{ path: 'login', component: LogisticsLoginComponent },
				{ path: '**', redirectTo: 'login' }
			]
		} 
		: { path: 'logistics', component:LogisticsAppComponent, 
			children: [
				{ path: '', redirectTo: 'not-available', pathMatch: 'full' },
				{ path: 'login', redirectTo: 'not-available', pathMatch: 'full' },
				{ path: 'not-available', component: LogisticsUnavailableComponent },
			] 
		} 
	),

	( portalM()['mod-buying'] || portalM()['mod-all'] ? 
		{ path: 'buying', component: BuyingAppComponent, 
			children: [
				{ path: '', redirectTo: 'login', pathMatch: 'full' },
				{ path: 'reset', component: BuyingResetComponent },
				{ path: 'login', component: BuyingLoginComponent },
				{ path: '**', redirectTo: 'login' }
			]
		} 
		: { path: 'buying', component:BuyingAppComponent, 
			children: [
				{ path: '', redirectTo: 'not-available', pathMatch: 'full' },
				{ path: 'login', redirectTo: 'not-available', pathMatch: 'full' },
				{ path: 'not-available', component: BuyingUnavailableComponent },
			] 
		} 
	),
	
	{ path: 'team', component:TeamAppComponent, 
		children: [
			{ path: '', redirectTo: 'login', pathMatch: 'full' },
			{ path: 'reset', component: TeamResetComponent },
			{ path: 'login', component: TeamLoginComponent },
			{ path: '**', redirectTo: 'login' }
		]
	},
	
	{ path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
	routes = routes;

	constructor(
    private router: Router,
    private portalService: PortalService,
    private messageService: MessageService) {}

  async routerUpdate(portal) {
  	switch (portal) {
  		case "team":
  			portal = 'team';
  			break;
  		case "logistics":
  			portal = 'logistics';
  			break;
  		case "buying":
  			portal = 'buying';
  			break;
  		default:
  			portal = 'client';
  			break;
  	}
    var p = {
      "confirm": this.portalService.portalMod()['confirm'],
      "version": this.portalService.portalMod()['version']
    };
    let update = await this.portalService.loadData(portal, "portalUpdate", p);
    let temp = await this.portalService.updatePortal(update);
    if (update['latestversion']!=undefined) {
			if (update['latestversion']!='current' && update['latestversion']!='none') {
				this.messageService.add({key: 'tc', severity:'info', life:4000, summary:'Update Required', detail:'You are running an old version. Please refresh this page in your broswer to get the latest version.', sticky:false});
			}
		}
		
    this.routes = [
			( portalM()['mod-clients'] || portalM()['mod-all'] ? 
				{ path: '', component: ClientAppComponent, 
					children: [
						{ path: '', redirectTo: 'login', pathMatch: 'full' },
						{ path: 'home', canActivate: [AuthGuard], component: HomeComponent },
						{ path: 'table/:tablename', canActivate: [AuthGuard], component: ClientTableComponent },
						{ path: 'overview/summary', canActivate: [AuthGuard], component: OverviewComponent },
						( portalM()['mod-invoice'] || portalM()['mod-all'] ? { path: 'invoicing', canActivate: [AuthGuard], component: FarmerInvoiceSelectorComponent } : { path: 'invoicing', canActivate: [AuthGuard], component:HomeComponent } ),
						( portalM()['mod-calc'] || portalM()['mod-all'] ? { path: 'calculator', canActivate: [AuthGuard], component: CalculatorComponent } : { path: 'calculator', canActivate: [AuthGuard], component:HomeComponent } ),
						( portalM()['mod-schedule'] || portalM()['mod-all'] ? { path: 'schedule', canActivate: [AuthGuard], component: ScheduleComponent } : { path: 'schedule', canActivate: [AuthGuard], component:HomeComponent } ),
						( portalM()['mod-map'] || portalM()['mod-all'] ? { path: 'farm', canActivate: [AuthGuard], component: FarmComponent } : { path: 'farm', canActivate: [AuthGuard], component:HomeComponent } ),
						{ path: 'reset', component: ResetComponent },
						{ path: 'login', component: LoginComponent },
					]
				} 
				: { path: '', component: ClientAppComponent, 
					children: [
						{ path: '', redirectTo: 'not-available', pathMatch: 'full' },
						{ path: 'login', redirectTo: 'not-available', pathMatch: 'full' },
						{ path: 'not-available', component: UnavailableComponent },
					] 
				} 
			),

			( portalM()['mod-logistics'] || portalM()['mod-all'] ? 
				{ path: 'logistics', component: LogisticsAppComponent, 
					children: [
						{ path: '', redirectTo: 'login', pathMatch: 'full' },
						{ path: 'home', canActivate: [LogisticsAuthGuard], component: LogisticsHomeComponent },
						{ path: 'table/:tablename', canActivate: [LogisticsAuthGuard], component: LogisticsTableComponent },
						//{ path: 'logging/contact', canActivate: [LogisticsAuthGuard], component:LogisticsAddContactComponent },
						//{ path: 'logging/company', canActivate: [LogisticsAuthGuard], component:LogisticsAddCompanyComponent },
						//{ path: 'logging/order', canActivate: [LogisticsAuthGuard], component:LogisticsAddOrderComponent },
						( portalM()['mod-qr'] || portalM()['mod-all'] ? { path: 'scanner/manage', canActivate: [LogisticsAuthGuard], component:LogisticsAddQrComponent } : { path: 'scanner/manage', canActivate: [LogisticsAuthGuard], component:LogisticsHomeComponent } ),
						( portalM()['mod-qr'] || portalM()['mod-all'] ? { path: 'scanner/dispatch', canActivate: [LogisticsAuthGuard], component:LogisticsDispatchQrComponent } : { path: 'scanner/dispatch', canActivate: [LogisticsAuthGuard], component:LogisticsHomeComponent } ),
						{ path: 'reset', component: LogisticsResetComponent },
						{ path: 'login', component: LogisticsLoginComponent },
						{ path: '**', redirectTo: 'login' }
					]
				}
				: { path: 'logistics', component: LogisticsAppComponent, 
					children: [
						{ path: '', redirectTo: 'not-available', pathMatch: 'full' },
						{ path: 'login', redirectTo: 'not-available', pathMatch: 'full' },
						{ path: 'not-available', component: LogisticsUnavailableComponent },
					] 
				} 
			),

			( portalM()['mod-buying'] || portalM()['mod-all'] ? 
				{ path: 'buying', component: BuyingAppComponent, 
					children: [
						{ path: '', redirectTo: 'login', pathMatch: 'full' },
						{ path: 'home', canActivate: [BuyingAuthGuard], component: BuyingHomeComponent },
						{ path: 'offers', canActivate: [BuyingAuthGuard], component: BuyingBidOffersComponent },
						{ path: 'table/:tablename', canActivate: [BuyingAuthGuard], component: BuyingTableComponent },
						{ path: 'reset', component: BuyingResetComponent },
						{ path: 'login', component: BuyingLoginComponent },
						{ path: '**', redirectTo: 'login' }
					]
				}
				: { path: 'buying', component: BuyingAppComponent, 
					children: [
						{ path: '', redirectTo: 'not-available', pathMatch: 'full' },
						{ path: 'login', redirectTo: 'not-available', pathMatch: 'full' },
						{ path: 'not-available', component: BuyingUnavailableComponent },
					] 
				} 
			),

			{ path: 'team', component:TeamAppComponent, 
				children: [
					{ path: '', redirectTo: 'login', pathMatch: 'full' },
					{ path: 'table/:tablename', canActivate: [TeamAuthGuard], component: TableComponent },
					( portalM()['mod-processing'] || portalM()['mod-all'] ? { path: 'overview/processing', canActivate: [TeamAuthGuard], component: ProcessingComponent } : { path: 'overview/processing', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-currency'] || portalM()['mod-all'] ? { path: 'overview/currency', canActivate: [TeamAuthGuard], component: CurrencyComponent } : { path: 'overview/currency', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-inventory'] || portalM()['mod-all'] ? { path: 'overview/inventory', canActivate: [TeamAuthGuard], component: InventoryComponent } : { path: 'overview/inventory', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					{ path: 'overview/batches', canActivate: [TeamAuthGuard], component: BatchesComponent },
					( portalM()['mod-silos'] || portalM()['mod-all'] ? { path: 'overview/silos', canActivate: [TeamAuthGuard], component: SilosComponent } : { path: 'overview/silos', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),

					{ path: 'logging/contact', canActivate: [TeamAuthGuard], component:AddContactComponent },
					{ path: 'logging/company', canActivate: [TeamAuthGuard], component:AddCompanyComponent },
					( portalM()['mod-siloselect'] || portalM()['mod-all'] ? { path: 'logging/silo-selector', canActivate: [TeamAuthGuard], component:SiloSelectorComponent } : { path: 'logging/silo-selector', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-schedule'] || portalM()['mod-all'] ? { path: 'logging/booking', canActivate: [TeamAuthGuard], component:AddBookingComponent } : { path: 'logging/booking', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					{ path: 'logging/delivery', canActivate: [TeamAuthGuard], component:AddDeliveryComponent },
					{ path: 'logging/sample', canActivate: [TeamAuthGuard], component:AddSampleBatchedComponent },
					{ path: 'logging/payment', canActivate: [TeamAuthGuard], component:AddPaymentComponent },
					{ path: 'logging/processing', canActivate: [TeamAuthGuard], component:AddProcessingComponent },
					( portalM()['mod-currency'] || portalM()['mod-all'] ? { path: 'logging/currency', canActivate: [TeamAuthGuard], component:AddCurrencyComponent } : { path: 'logging/currency', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-orders'] || portalM()['mod-all'] ? { path: 'logging/order', canActivate: [TeamAuthGuard], component:AddOrderComponent } : { path: 'logging/order', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),

					{ path: 'lab/pallet-sample', canActivate: [TeamAuthGuard], component:AddPalletSampleComponent },
					
					( portalM()['mod-depot'] || portalM()['mod-all'] ? { path: 'depot/log-delivery', canActivate: [TeamAuthGuard], component:LogDepotDeliveryComponent } : { path: 'depot/log-delivery', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					
					( portalM()['mod-messages'] || portalM()['mod-all'] ? { path: 'messages/add', canActivate: [TeamAuthGuard], component:AddArticleComponent } : { path: 'messages/add', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					
					( portalM()['mod-pallet'] || portalM()['mod-all'] ? { path: 'pallets/recoverpallet', canActivate: [TeamAuthGuard], component:RecoverPalletComponent } : { path: 'pallets/recoverpallet', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),

					( portalM()['mod-trace'] || portalM()['mod-all'] ? { path: 'traceability/tracestock', canActivate: [TeamAuthGuard], component:TraceScanComponent } : { path: 'traceability/tracescan', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-trace'] || portalM()['mod-all'] ? { path: 'traceability/tracefarm', canActivate: [TeamAuthGuard], component:TraceFarmComponent } : { path: 'traceability/tracefarm', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-trace'] || portalM()['mod-all'] ? { path: 'traceability/inventory', canActivate: [TeamAuthGuard], component:TraceInventoryComponent } : { path: 'traceability/inventory', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-trace'] || portalM()['mod-all'] ? { path: 'traceability/packinglist', canActivate: [TeamAuthGuard], component:TracePackingComponent } : { path: 'traceability/packinglist', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-trace'] || portalM()['mod-all'] ? { path: 'traceability/stockrecall', canActivate: [TeamAuthGuard], component:StockRecallComponent } : { path: 'traceability/stockrecall', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),

					{ path: 'settings/general/pricemodel', canActivate: [TeamAuthGuard], component: PriceModelComponent },
					{ path: 'settings/general/general', canActivate: [TeamAuthGuard], component: GeneralSettingsComponent },
					{ path: 'settings/general/seasonal', canActivate: [TeamAuthGuard], component: SeasonalSettingsComponent },
					{ path: 'settings/general/banking', canActivate: [TeamAuthGuard], component: BankAccountsComponent },
					{ path: 'settings/general/email', canActivate: [TeamAuthGuard], component: EmailSettingsComponent },
					{ path: 'settings/general/storage', canActivate: [TeamAuthGuard], component: StorageComponent },
					{ path: 'settings/general/cultivars', canActivate: [TeamAuthGuard], component: CultivarComponent },
					{ path: 'settings/remittance-rebuilder', canActivate: [TeamAuthGuard], component: RemittanceRebuilderComponent },
					( portalM()['mod-clients'] || portalM()['mod-all'] ? { path: 'settings/clients/new', canActivate: [TeamAuthGuard], component:SettingsClientsNewComponent } : { path: 'settings/clients/new', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-clients'] || portalM()['mod-all'] ? { path: 'settings/clients/active', canActivate: [TeamAuthGuard], component:SettingsClientsActiveComponent } : { path: 'settings/clients/active', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-clients'] || portalM()['mod-all'] ? { path: 'settings/clients/activity', canActivate: [TeamAuthGuard], component:SettingsClientsActivityComponent } : { path: 'settings/clients/activity', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					{ path: 'settings/team/new', canActivate: [TeamAuthGuard], component: SettingsUsersNewComponent },
					{ path: 'settings/team/active', canActivate: [TeamAuthGuard], component: SettingsUsersActiveComponent },
					{ path: 'settings/team/activity', canActivate: [TeamAuthGuard], component: SettingsUsersActivityComponent },
					
					( portalM()['mod-query'] || portalM()['mod-all'] ? { path: 'settings/query', canActivate: [TeamAuthGuard], component:QueryComponent } : { path: 'settings/query', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-scheduling'] || portalM()['mod-all'] ? { path: 'scheduling', canActivate: [TeamAuthGuard], component:SchedulingComponent } : { path: 'scheduling', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),

					( portalM()['mod-qr'] || portalM()['mod-all'] ? { path: 'qr/inspect', canActivate: [TeamAuthGuard], component:InspectQrComponent } : { path: 'qr/inspect', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-qr'] || portalM()['mod-all'] ? { path: 'qr/inventory', canActivate: [TeamAuthGuard], component:AddQrComponent } : { path: 'qr/inventory', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					( portalM()['mod-qr'] || portalM()['mod-all'] ? { path: 'qr/dispatch', canActivate: [TeamAuthGuard], component:DispatchQrComponent } : { path: 'qr/dispatch', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					
					{ path: 'test', canActivate: [TeamAuthGuard], component:VATInvoiceComponent },

					{ path: 'home', canActivate: [TeamAuthGuard], component:TeamHomeComponent },
					( portalM()['mod-calc'] || portalM()['mod-all'] ? { path: 'calculator', canActivate: [TeamAuthGuard], component:TeamCalculatorComponent } : { path: 'calculator', canActivate: [TeamAuthGuard], component:TeamHomeComponent } ),
					{ path: 'reset', component: TeamResetComponent },
					{ path: 'login', component: TeamLoginComponent },
					{ path: '**', redirectTo: 'login' }
				]
			},
			
			{ path: '**', redirectTo: '/login' }
		];
    
    this.changeRouteConfig();
  }

	changeRouteConfig() {
    this.getUrl().subscribe((data: Routes) => {
      this.routes = data;
      this.router.resetConfig(data);
    });
  }

  getUrl(): Observable<Routes> {
    return of(this.routes);
  }

}
