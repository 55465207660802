import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoginService } from '../auth/login/login.service';
import { ClientTableDataService } from '../table/table.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class ClientDeliveryService{
	private postBody: {};
	private producer: string;
	httpRes: any;
  public requestDays: number;

	constructor(
		private http: HttpClient,
		private loginService: LoginService,
		public tableService: ClientTableDataService
		) {}

  async getResampleValidDays() {
    var p = {
      "season": this.loginService.season,
      "producer": this.loginService.selectedmember,
      "setting": 'Client Resample Request',
    };
    this.requestDays = await this.loginService.loadData('getResampleValidDays',p);
  }

  resample() {
  	this.postBody = {
			"name":"resampleDelivery",
			"param":{
				"producer": this.producer,
				"season": +this.loginService.season,
				"iddelivery": +this.tableService['samples'][this.tableService.clickedIndex['samples']].iddelivery
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
    			this.loginService.toast('Resample Request',response['response']['result']['reply']['message'], (response['response']['result']['reply']['type']=='success' ? 'success' : 'warn'), 3000);
        },
        (error) => console.log(error)
      );
  }

}