import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { BuyingLoginService } from './auth/login/login.service';

@Injectable()
export class BuyingAuthGuard implements CanActivate {

	constructor(
		private loginService: BuyingLoginService,
		private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
			if(this.loginService.isAuthenticated()) {
				return true;
			} else {
				this.router.navigate(['/buying/login']);
			}
				 
	}
}