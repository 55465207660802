import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { Batch, RootObject } from './batches.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { BatchesService } from './batches.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})
export class BatchesComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [
    'type',
    'name',
    'batchtype',
    'variety',
    'intakestart',
    'intakeend',
    'emptydate',
    'batch',
    'locationname'
    ];

  processDB: BatchesService | null;
  dataSource: Batch[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
  	public batchesService: BatchesService,
    public csvService: CsvService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
    this.updateTable();
  }

  ngOnInit() {
  	this.batchesService.clickedIndex = null;
  	this.batchesService.loadBatches();
    window.scrollTo(0,0);
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    this.batchesService.loadBatches();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.batchesService.clickedIndex = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
    this.updateTable();
  }

  onSelectRow(i: number) {
    //this.clickedId = this.table['_data'][i]['idbatches'];
    //this.batchesService.setBatches(+this.clickedId);
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.batchesService.clickedIndex !== null;
  }

  updateTable() {
    this.processDB = new BatchesService(this.http, this.loginService);
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.processDB!.getBatches();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply;
          this.datasrc = new MatTableDataSource(this.dataSource);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Batches');
  }

}