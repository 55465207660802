import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Inventory, RootObject } from './inventory.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class InventoryService{
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public clickedIndex: number = null;
  public batchClicked = true;
  public loading: boolean = false;
  public grouptype: string = 'All';
  public inventorytype: string = 'Production to Date';
  public invtype: string = 'todate';
	public inventory: any;
	public inventoryToday: any;
	public dispatchToday: any;
	public totalquantity: number = 0;
	public totalquantityt: number = 0;
	public totalquantitypremium: number = 0;
	public totalquantitypremiumt: number = 0;
	public totalquantitycommercial: number = 0;
	public totalquantitycommercialt: number = 0;
	public totalquantityoil: number = 0;
	public totalquantityoilt: number = 0;
	public totalNIS: number = 0;
	public NIScount: number = 0;
	public retailquantity: number = 0;
	public todaytotalquantity: number = 0;
	public todaytotalpremium: number = 0;
	public todaytotalcommercial: number = 0;
	public todaytotaloil: number = 0;
	public todaytotalNIS: number = 0;
	public todayNIScount: number = 0;
	public todayretailquantity: number = 0;
	public dispatchtodaytotalquantity: number = 0;
	public dispatchtodaytotalNIS: number = 0;
	public dispatchtodayNIScount: number = 0;
	public dispatchRetailToday: number = 0;
	public iBatch: number = 0;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService
    ) {}

	getStock(): Observable<RootObject[]> {
		this.postBody = {
			"name":"recentStock",
			"param":{
				"season": +this.loginService.season,
				"location": this.loginService.location
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}
 
	loadStock() {
		this.postBody = {
			"name":"recentStock",
			"param":{
				"season": +this.loginService.season,
				"location": this.loginService.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

	loadInventoryGrouped() {
		this.loading = true;
		this.postBody = {
			"name":"inventoryGrouped",
			"param":{
				"season": +this.loginService.season,
				"location": this.loginService.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeGrouped(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.dataStore = rawData.response.result.reply;
	}

	storeGrouped(rawData) {
		this.inventory = rawData.response.result.reply.all;
		this.inventoryToday = rawData.response.result.reply.today;
		this.dispatchToday = rawData.response.result.reply.dispatch;
		this.totalquantity = 0;
		this.totalquantityt = 0;
		this.totalquantitypremium = 0;
		this.totalquantitypremiumt = 0;
		this.totalquantitycommercial = 0;
		this.totalquantitycommercialt = 0;
		this.totalquantityoil = 0;
		this.totalquantityoilt = 0;
		this.totalNIS = 0;
		this.NIScount = 0;
		this.retailquantity = 0;
		this.todaytotalquantity = 0;
		this.todaytotalpremium = 0;
		this.todaytotalcommercial = 0;
		this.todaytotaloil = 0;
		this.todaytotalNIS = 0;
		this.todayNIScount = 0;
		this.todayretailquantity = 0;
		this.dispatchtodaytotalquantity = 0;
		this.dispatchtodaytotalNIS = 0;
		this.dispatchtodayNIScount = 0;
		this.dispatchRetailToday = 0;
		for (var i = 0; i < this.inventory.length; i++) {
			if (this.inventory[i]['style'].substr(0,1)=="N") {
				this.totalNIS = this.totalNIS + +this.inventory[i]['quantity'];
				this.NIScount += +this.inventory[i]['count'];
			} else if (this.inventory[i]['style'].substr(0,1)=="B") {
					this.retailquantity += +this.inventory[i]['count']; 
			} else {
				if (this.inventory[i]['style'].substr(0,1)=="S") {
					this.totalquantitypremium += +this.inventory[i]['quantity'];
					this.totalquantitypremiumt += +this.inventory[i]['tquantity'];
				} else if (this.inventory[i]['style'].substr(0,1)=="C") {
					this.totalquantitycommercial += +this.inventory[i]['quantity'];
					this.totalquantitycommercialt += +this.inventory[i]['tquantity'];
				} else if (this.inventory[i]['style'].substr(0,1)=="O" || this.inventory[i]['style'].substr(0,3)=="FEE") {
					this.totalquantityoil += +this.inventory[i]['quantity'];
					this.totalquantityoilt += +this.inventory[i]['tquantity'];
				} 
				this.totalquantity += +this.inventory[i]['quantity'];
				this.totalquantityt += +this.inventory[i]['tquantity'];
			}
			switch (this.inventory[i]['style'].substr(0,3)) {
				case 'OIA':
					this.inventory[i]['style']='Oil Grade A.';
					break;
				case 'OIB':
					this.inventory[i]['style']='Oil Grade B.';
					break;
				case 'OIM':
					this.inventory[i]['style']='Oil Grade Meal.';
					break;
				case 'BCH':
					this.inventory[i]['style']='Bio Char.';
					break;
			}
		}
		for (var i = 0; i < this.inventoryToday.length; i++) {
			if (this.inventoryToday[i]['style'].substr(0,1)=="N") {
				this.todaytotalNIS += +this.inventoryToday[i]['quantity'];
				this.todayNIScount += +this.inventoryToday[i]['count'];
			} else if (this.inventoryToday[i]['style'].substr(0,1)=="B") {
				this.todayretailquantity += +this.inventoryToday[i]['count'];
			} else {
				if (this.inventoryToday[i]['style'].substr(0,1)=="S") {
					this.todaytotalpremium += +this.inventoryToday[i]['quantity'];
				} else if (this.inventoryToday[i]['style'].substr(0,1)=="C") {
					this.todaytotalcommercial += +this.inventoryToday[i]['quantity'];
				} else if (this.inventoryToday[i]['style'].substr(0,1)=="O" || this.inventoryToday[i]['style'].substr(0,3)=="FEE") {
					this.todaytotaloil += +this.inventoryToday[i]['quantity'];
				} 
				this.todaytotalquantity += +this.inventoryToday[i]['quantity'];
			}
			switch (this.inventoryToday[i]['style'].substr(0,3)) {
				case 'OIA':
					this.inventoryToday[i]['style']='Oil Grade A.';
					break;
				case 'OIB':
					this.inventoryToday[i]['style']='Oil Grade B.';
					break;
				case 'OIM':
					this.inventoryToday[i]['style']='Oil Grade Meal.';
					break;
				case 'BCH':
					this.inventoryToday[i]['style']='Bio Char.';
					break;
			}
		}
		for (var i = 0; i < this.dispatchToday.length; i++) {
			if (this.dispatchToday[i]['style'].substr(0,1)=="N") {
				this.dispatchtodaytotalNIS += +this.dispatchToday[i]['quantity'];
				this.dispatchtodayNIScount += +this.dispatchToday[i]['count'];
			} else if (this.dispatchToday[i]['style'].substr(0,1)=="B") {
				this.dispatchRetailToday += +this.dispatchToday[i]['count'];
			} else {
				this.dispatchtodaytotalquantity += +this.dispatchToday[i]['quantity'];
			}
			switch (this.dispatchToday[i]['style'].substr(0,3)) {
				case 'OIA':
					this.dispatchToday[i]['style']='Oil Grade A.';
					break;
				case 'OIB':
					this.dispatchToday[i]['style']='Oil Grade B.';
					break;
				case 'OIM':
					this.dispatchToday[i]['style']='Oil Grade Meal.';
					break;
				case 'BCH':
					this.dispatchToday[i]['style']='Bio Char.';
					break;
			}
		}
		this.loading = false;
	}

	setBatch(idd: string) {
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (this.dataStore[i].batchcode == idd) {
				this.clickedIndex = i;
			}
		}
	}

}