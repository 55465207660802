import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { TeamLoginService } from '../../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../../logging/dropdown.service';
import { SettingsUsersService } from '../users.service';
import { SearchService } from '../../../../search.service';


@Component({
  selector: 'app-settings-users-active',
  templateUrl: './users-active.component.html',
  styleUrls: ['./users-active.component.scss']
})
export class SettingsUsersActiveComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  d: Date;
  scroll: boolean = true;
  isLoadingResults = true;
  id: string;
  name: string;
  pass: string;
  searchtext: string;
  checked: boolean = false;
  passwordOk = false;
  passwordLength = false;
  passwordSpecial = false;
  filteredData: any[];

  constructor(
    public loginService: TeamLoginService,
    public searchService: SearchService,
    public clientsService: SettingsUsersService,
    public dropDownDataService: DropDownDataService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.clientsService.idcontact = 0;
    this.clientsService.resetForm();
    this.clientsService.loadFiltered();
  }

  ngOnDestroy() {
    this.clientsService.searchtext = null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
  
  cacheForMember() {
    this.clientsService.loadUsers();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.clientsService.clickedIndex !== null;
  }

  passAssign(id: string, name: string) {
    this.id = id;
    this.name = name;
    document.getElementById("popup").style.display = "block";
  }

  passAssignSave() {
    if (this.validateNewPWord()) {
      this.clientsService.passAssign(this.id,this.pass);
      this.cancelSave();
    }
  }

  validateNewPWord() {
    var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    var ans = regex.test(this.pass);
    this.passwordSpecial = (ans ? false : true);
    this.passwordLength = this.pass.length>7;
    this.passwordOk = (ans ? false : (this.passwordLength ? true : false ) );
    ans = (this.passwordLength && this.passwordOk);
    return ans;
  }

  cancelSave() {  
    document.getElementById("popup").style.display = "none";
    this.pass = "";
    this.name = "";
    this.id = "";
  }

}