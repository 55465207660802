import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'delivery'
})
export class DeliveryPipe implements PipeTransform {

  transform(value: any): any {
    if (value === null) {
      return null;
    }
    if (+value==0) {
      return 'TBA';
    }
    if (+value !== +value) {
      return value;
    }
    var forwardZeros = 4 - value.toString().length;
    if (forwardZeros >= 4) {
    	return value;
    }
    var zeros = "0";
    return zeros.repeat(forwardZeros) + value.toString();
  }

}
