<div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
    <div class="row justify-content-between">
      <div class="col-xs-6 main-header">
        <h2>PPECB Certificate</h2>
      </div>
      <div class="ml-3 col-auto">
        <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
      </div>
    </div>
    <br>
  
    <div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
      <svg id="ppecb"
       viewBox="0 0 550 800"
       xmlns="http://www.w3.org/2000/svg" 
       xmlns:xlink="http://www.w3.org/1999/xlink"  
       version="1.2" 
       baseProfile="tiny">
        <defs>
          <style>
            .cls-1 {
              fill: gray;
            }

            .cls-2 {
              font-size: 9.96px;
            }

            .cls-10, .cls-19, .cls-2, .cls-30, .cls-31, .cls-33 {
              font-family: Arial-Bold, Arial;
              font-weight: 700;
            }

            .cls-10, .cls-19, .cls-3, .cls-8, .cls-33 {
              font-size: 7.8px;
            }

            .cls-3, .cls-8 .cls-35 {
              font-family: Arial, Arial;
            }

            .cls-4 {
              letter-spacing: 9.76em;
            }

            .cls-5 {
              letter-spacing: 8.64em;
            }

            .cls-6 {
              letter-spacing: 6.69em;
            }

            .cls-7 {
              letter-spacing: 0em;
            }

            .cls-8, .cls-9 {
              fill: #0563c1;
            }

            .cls-10 {
              fill: #fff;
            }

            .cls-11 {
              letter-spacing: 8.1em;
            }

            .cls-12 {
              letter-spacing: 7.11em;
            }

            .cls-13 {
              letter-spacing: 2.38em;
            }

            .cls-14 {
              letter-spacing: 5.76em;
            }

            .cls-15 {
              letter-spacing: 7.39em;
            }

            .cls-16 {
              letter-spacing: 2.77em;
            }

            .cls-17 {
              letter-spacing: 9.68em;
            }

            .cls-18 {
              letter-spacing: 4.13em;
            }

            .cls-20 {
              letter-spacing: 3.55em;
            }

            .cls-21 {
              letter-spacing: 9.97em;
            }

            .cls-22 {
              letter-spacing: 7.16em;
            }

            .cls-23 {
              letter-spacing: 3.07em;
            }

            .cls-24 {
              letter-spacing: 0em;
            }

            .cls-25 {
              letter-spacing: 5.31em;
            }

            .cls-26 {
              letter-spacing: 8.72em;
            }

            .cls-27 {
              letter-spacing: 9.27em;
            }

            .cls-28 {
              letter-spacing: 6.28em;
            }

            .cls-29 {
              letter-spacing: 0em;
            }

            .cls-30 {
              font-size: 14.22px;
            }

            .cls-31 {
              font-size: 19.86px;
              fill: #c00000;
            }

            .cls-32 {
              fill: #8ec5e769;
            }

            .cls-33 {
              fill: #f00;
            }

            .cls-34 {
              font-family: Arial, Arial;
              font-size: 7px;
            }
            .cls-35 {
              font-size: 7px;
            }

          </style>
        </defs>
        <title>PPECB Certificate</title>
        
        <g>
          <rect x="10" y="30" width="530" height="0.6"/>
          <rect x="150" y="58" width="390" height="0.6"/>
          <rect x="150" y="76" width="230" height="0.6"/>
          <rect x="420" y="76" width="120" height="0.6"/>
          <rect x="150" y="94" width="390" height="0.6"/>
          <rect x="150" y="112" width="390" height="0.6"/>
          <rect x="10" y="130" width="530" height="0.6"/>

          <rect x="10" y="30" width="0.6" height="100"/>
          <rect x="150" y="30" width="0.6" height="100"/>
          <rect x="207.5" y="30" width="0.6" height="28"/>
          <rect x="265" y="30" width="0.6" height="100"/>
          <rect x="305" y="30" width="0.6" height="28"/>
          <rect x="420" y="58" width="0.6" height="36"/>
          <rect x="380" y="30" width="0.6" height="82"/>
          <rect x="540" y="30" width="0.6" height="100"/>
        </g>
        <g>
          <text class="cls-3" transform="translate(155 47)">COMPANY:</text>
          <text class="cls-3" transform="translate(236.25 47)" text-anchor="middle">Amber Macs</text>
          <text class="cls-3" transform="translate(270 47)">DEPOT:</text>
          <text class="cls-3" transform="translate(342.5 47)" text-anchor="middle">{{ depot }}</text>
          <text class="cls-3" transform="translate(155 70)">STACK MASS:</text> 
          <text class="cls-3" transform="translate(215 70)">{{ +this.tS['orders'][this.tS.clickedIndex['orders']]?.quantity * ( this.tS['orders'][this.tS.clickedIndex['orders']]?.type=='nis' ? 1 : 11.34 ) | number:'1.0-0' }} kg</text> 
          <text class="cls-3" transform="translate(270 70)">NO OF BAGS:</text> 
          <text class="cls-3" transform="translate(330 70)">{{ +this.tS['orders'][this.tS.clickedIndex['orders']]?.quantity / ( this.tS['orders'][this.tS.clickedIndex['orders']]?.type=='nis' ? 25 : 1 ) | number:'1.0-0' }}</text> 
          <text class="cls-3" transform="translate(385 79)">LOT NO:</text>
          <text class="cls-3" transform="translate(430 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}</text> 
          <text class="cls-3" transform="translate(155 88)">SEASON:</text>
          <text class="cls-3" transform="translate(195 88)">{{ +this.tS['orders'][this.tS.clickedIndex['orders']]?.season }}</text>
          <text class="cls-3" transform="translate(270 88)">TARGET MARKET: {{ market }}</text> 
          <text class="cls-3" transform="translate(155 106)">INSPECTION POINT: {{ inspectionpoint }}</text> 
          <text class="cls-3" transform="translate(270 106)">FBO: {{ fbo }}</text> 
          <text class="cls-3" transform="translate(385 106)">N-CODE: AME</text> 
          <text class="cls-3" transform="translate(155 124)">PRODUCT CODE: TM</text> 
          <text class="cls-3" transform="translate(270 124)">COMMENTS:</text> 
        </g>

        <g>
          <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='wide'" [attr.xlink:href]="this.pS.invoicelogo" x="15" y="35" height="60px" width="130px"/>
          <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='square'" [attr.xlink:href]="this.pS.invoicelogo" transform="scale(0.25)" x="200px" y="140" height="223px" width="255px"/>
          <text class="cls-35" transform="translate(80 106)" text-anchor="middle">{{ this.pS.portalMod()['address'] }}</text> 
          <text class="cls-35" transform="translate(80 118)" text-anchor="middle">{{ this.pS.portalMod()['state'] }}, {{ this.pS.portalMod()['area'] }}, {{ this.pS.portalMod()['postcode'] }}</text>
          <image href="assets/ppecblogo.png" x="402.5" y="32" height="25px" width="110px"/>
        </g>

        <g>
          <rect x="10" y="160" width="530" height="40" class="cls-32"></rect>
          <rect x="10" y="160" width="530" height="0.6"/>
          <rect x="10" y="200" width="530" height="0.6"/>
          <rect x="10" y="160" width="0.6" height="40"/>
          <rect x="90" y="160" width="0.6" height="40"/>
          <rect x="190" y="160" width="0.6" height="40"/>
          <rect x="250" y="160" width="0.6" height="40"/>
          <rect x="355" y="160" width="0.6" height="40"/>
          <rect x="445" y="160" width="0.6" height="40"/>
          <rect x="540" y="160" width="0.6" height="40"/>
        </g>
        <g>
          <text class="cls-2" transform="translate(50 184)" text-anchor="middle">PLN Number</text>
          <text class="cls-2" transform="translate(140 184)" text-anchor="middle">Product</text>
          <text class="cls-2" transform="translate(220 184)" text-anchor="middle">Size</text>
          <text class="cls-2" transform="translate(302.5 184)" text-anchor="middle">Weight per bag (kg)</text>
          <text class="cls-2" transform="translate(400 184)" text-anchor="middle">Bags per Pallet</text>
          <text class="cls-2" transform="translate(492.5 184)" text-anchor="middle">Total Weight (kg)</text>
        </g>

        <ng-container *ngFor="let pallet of this.tS.ppecb; let i=index;">
          <g [attr.transform]="transpallet(i)">
            <text class="cls-3" transform="translate(50 210)" text-anchor="middle">{{ pallet.pln }}</text>
            <text class="cls-3" transform="translate(140 210)" text-anchor="middle">Macadamia Nut in Shell</text>
            <text class="cls-3" transform="translate(220 210)" text-anchor="middle">{{ pallet.size }}</text>
            <text class="cls-3" transform="translate(302.5 210)" text-anchor="middle">{{ pallet.weight | number:'1.0-0' }}</text>
            <text class="cls-3" transform="translate(400 210)" text-anchor="middle">{{ pallet.count | number:'1.0-0' }}</text>
            <text class="cls-3" transform="translate(492.5 210)" text-anchor="middle">{{ +pallet.weight * +pallet.count | number:'1.0-0' }}</text>
            <rect x="10" y="200" width="0.6" height="15"/>
            <rect x="90" y="200" width="0.6" height="15"/>
            <rect x="190" y="200" width="0.6" height="15"/>
            <rect x="250" y="200" width="0.6" height="15"/>
            <rect x="355" y="200" width="0.6" height="15"/>
            <rect x="445" y="200" width="0.6" height="15"/>
            <rect x="540" y="200" width="0.6" height="15"/>
            <rect x="10" y="215" width="530" height="0.6"/>
          </g>
        </ng-container>

        <g [attr.transform]="transgrouped()">
          <rect x="10" y="200" width="530" height="15" class="cls-32"></rect>
          <text class="cls-2" transform="translate(50 211)" text-anchor="middle">SUM</text>
          <text class="cls-2" transform="translate(400 211)" text-anchor="middle">1,040 Bags</text>
          <text class="cls-2" transform="translate(492.5 211)" text-anchor="middle">26,000 KG</text>
          <rect x="10" y="200" width="0.6" height="15"/>
          <rect x="355" y="200" width="0.6" height="15"/>
          <rect x="445" y="200" width="0.6" height="15"/>
          <rect x="540" y="200" width="0.6" height="15"/>
          <rect x="10" y="215" width="530" height="0.6"/>
        </g>

        <g>
          <text class="cls-3" transform="translate(155 730)" text-anchor="middle">PPECB</text>
          <text class="cls-3" transform="translate(80 750)">Inspector Name:</text>
          <text class="cls-3" transform="translate(80 765)">Inspector Signature:</text>
          <text class="cls-3" transform="translate(80 780)">Date:</text>
          <rect x="80" y="720" width="150" height="0.6"/>
          <rect x="155" y="750" width="75" height="0.6"/>
          <rect x="155" y="765" width="75" height="0.6"/>
          <rect x="155" y="780" width="75" height="0.6"/>
        </g>
        <g>
          <ng-container *ngIf="this.fS.imageData[this.sigimage]">
            <g transform="translate(40 220)">
              <image transform="scale(0.5)" x="650px" y="980px" height="223px" width="255px" [attr.href]="this.fS.imageData[this.sigimage]" />
            </g>
          </ng-container>
          <rect x="350" y="720" width="150" height="0.6"/>
        </g>

      </svg>
    </div>     

    <br>

    <div class="col px-0">  
      <div class="form-container">  

        <div class="row justify-content-between px-2">

          <div class="col-3 ps-0">
            <mat-form-field>
              <mat-label>Inspection Point</mat-label>
              <input matInput placeholder="Inspection Point" [(ngModel)]="inspectionpoint">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field>
              <mat-label>FBO</mat-label>
              <input matInput placeholder="FBO" [(ngModel)]="fbo">
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field>
              <mat-label>Depot</mat-label>
              <input matInput placeholder="Depot" [(ngModel)]="depot">
            </mat-form-field>
          </div>

          <div class="col-3 pe-0">
            <mat-form-field>
              <mat-label>Target Market</mat-label>
              <input matInput placeholder="Target Marke" [(ngModel)]="market">
            </mat-form-field>
          </div>

        </div>

        <hr>

      </div>

    </div>   
    
  </div>
</div>