import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavbarModule, WavesModule, ButtonsModule, DropdownModule } from 'angular-bootstrap-md';

import { TeamAuthService } from '../auth/auth.service';
import { PortalService } from '../../auth/portal.service';
import { TeamHeaderService } from './teamheader.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-teamheader',
  templateUrl: './teamheader.component.html',
  styleUrls: ['./teamheader.component.scss']
})
export class TeamHeaderComponent implements OnInit {
  public innerWidth: any;
  public mobileView: boolean;
  items: MenuItem[];
  enditems: MenuItem[];
  fullitems: MenuItem[];
  loginitems: MenuItem[];
  blank: MenuItem[] = [];

  constructor(
    private authService: TeamAuthService,
    public headerService: TeamHeaderService,
    public portalService: PortalService) { }

  ngOnInit() {
    this.headerService.refreshMenu();
  }

  isAuthenticated(option = null) {
    return this.authService.isAuthenticated(option);
  }

  isAuthorised(permission: string) {
    return this.authService.isAuthorised(permission);
  }
  

}