import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { Model } from './teamcalculator.interface';

@Injectable()
export class TeamCalculatorService{
	private postBody: {};
	private producer: string;
	httpRes: any;
	public dataStore: any;
	public priceModel: Model = {model:"", modelno:0};
	public currentModel: string;
	public selectedCurrency: string;
	public fxrate: number;
	public needCultivar: boolean = false;
	public sizes: {} = {"dnis18": 0, "dnis20": 0, "dnis22": 0, "dnis25": 0};

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService) {}

	ngOnInit() {
		this.dataStore.gdnisprice = "0";
	}

	loadPriceModel() {
		this.postBody = { 
			"name":"loadPriceModel",
			"param":{
				"location": 1,
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);

        },
        (error) => console.log(error)
      );
	}

	calculatePricing(wnis: number, moisture: number, skr: number, uskr: number, transport: boolean, vatable: boolean, darks: number, cultivar: number, smalls: number = 0, transportkm: number = 0) {
		var dnissizes = this.setSizes();
		this.postBody = {
			"name":"calAll",
			"param":{
				"model": this.priceModel.modelno,
				"wnis": wnis,
				"moisture": moisture,
				"skr": [skr],
				"uskr": uskr,
				"darks": darks,
				"smalls": smalls,
				"cultivar": cultivar,
				"transport": (transport ? 1 : 0 ),
				"transportkm": transportkm,
				"currency": this.selectedCurrency,
				"vatable": (vatable ? 1 : 0 ),
				"fxrate": this.fxrate,
				"mrlinc": 0,
				"sizes": dnissizes
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		)
		.subscribe(
      (response) => {
      	this.loginService.setSession(response);
        this.httpRes = this.storeCalc(response);
      },
      (error) => console.log(error)
    );
	}

	storeResult(rawData) {
		this.priceModel = rawData.response.result.reply;
		this.currentModel = this.priceModel.model;
		this.fxrate = Math.round(this.priceModel['fxrate'] * 10) / 10;
	}

	storeCalc(rawData) {
		this.dataStore = rawData.response.result.reply;
	}

	getModel() {
		return this.priceModel.modelno;
	}

	setPriceModel(model) {
		this.priceModel = model;
		this.needCultivar = +this.priceModel.cultivar==1;
	}

	setSizes() {
		const res = [];
		for (const k in this.sizes) {
	    if (this.sizes.hasOwnProperty(k)) {
	      const newObj = {};
	      newObj[k] = this.sizes[k];
	      res.push(newObj);
	    }
		}
	  return res;
	}

}