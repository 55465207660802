import { Component, OnInit, OnDestroy } from '@angular/core';

import { PortalService } from '../../auth/portal.service';
import { LoginService } from '../auth/login/login.service';
import { HeaderService } from '../header/header.service';
import { ClientTableDataService } from '../table/table.service';
import { FarmerInvoiceSelectorService } from './invoiceselector.service';

@Component({
  selector: 'app-farmerinvoiceselector',
  templateUrl: './invoiceselector.component.html',
  styleUrls: ['./invoiceselector.component.scss']
})
export class FarmerInvoiceSelectorComponent implements OnInit {
  d: Date = new Date();
  memberSelect;

  constructor(
  	public loginService: LoginService,
    public invoiceService: FarmerInvoiceSelectorService,
    public portalService: PortalService,
    public tableService: ClientTableDataService,
    private headerService: HeaderService
  	) { }

  ngOnInit() {
    this.memberSelect = this.loginService.selectedmember;
    this.loginService.getDropdown("remittanceList");
  }

  ngOnDestroy() {
    this.invoiceService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    if (this.memberSelect != null) {
      this.tableService.refreshAll();
    }
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
  }

  onMemberSelected(membership, membername) {
    this.invoiceService.resetForm();
    this.memberSelect = this.loginService.selectMembership(membership);
    this.headerService.refreshMenu('invoicing');
    this.cacheForMember();
    this.loginService.getDropdown("remittanceList");
  }
   
  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  loadForEdit() {
    this.invoiceService.loadForEdit();
  }

  duenow(paydate) {
    let pd = new Date(paydate);
    let d = new Date('2021-10-03');
    return pd.getMonth()==d.getMonth();
    return pd.getMonth()==this.d.getMonth();
  }

}
