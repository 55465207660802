<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>

	<div [ngClass]="logInState">
		<div [ngClass]="classState">
			<div class="my-4 col">
				<h2>QR - Inspect</h2>
			</div>
			<br>

			<div class="row justify-content-md mx-1">	
				<div class="my-4 col-12 col-md-12 col-lg-8">

					<app-scanner #scanner
						(scanSuccess)="handleQrCodeResult($event)">
					</app-scanner>

					<div class="col py-4 mx-0 px-0 text-center row justify-content-between">
						<button mdbBtn class="btn btn-warning mb-2 mx-0 px-0" style="width:50%; max-width:200px;" (click)="this.teamQRService.reset()">Reset</button>
					</div>
					
					<ng-container *ngIf="this.teamQRService.qrCodes.length > 0 && this.teamQRService.qrCodes[0]['type'] !== ''">
						
						<hr>

						<h4 class="my-4">Label Scan Summary</h4>
						<table class="my-4 table">
							<tbody>
								<tr class="tableseperator"><td></td><td></td></tr>
								<tr>
									<td>Total Scanned</td>
									<td>{{ (this.teamQRService.qrCodes.length > 0 ? ( this.teamQRService.qrCodes[0]['type'] == "" ? 0 : this.teamQRService.qrCodes.length ) : 0 ) }}</td>
								</tr>
								<ng-container *ngFor="let style of this.teamQRService.codeStyles">
									<tr *ngIf="style.quantity>0">
										<td>{{ style.fullstyle }}</td>
										<td>{{ style.quantity }}</td>
									</tr>
								</ng-container>
								<ng-template [ngIf]="this.teamQRService.qrCodes.length > 0 && this.teamQRService.qrCodes[0]['type'] !== ''">
									<ng-container *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['recall']!=undefined">
										<tr class="tableseperator"><td></td><td></td></tr>
										<tr class="recall">
											<td>RECALLED</td>
											<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['recall'] | uppercase }}</td>
										</tr>
									</ng-container>
									<tr class="tableseperator"><td></td><td></td></tr>
								<tr>
									<td>Label Category</td>
									<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['label'] }}</td>
								</tr>
									<tr>
										<td>Last Label Date</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['packdate'] }}</td>
									</tr>
									<tr>
										<td>Last Label Style</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['stylefull'] }} {{ (this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['variety'] == 'I' ? 'Integ' : 'Hybrid' )}}</td>
									</tr>
									<tr>
										<td>Last Label Silo</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['storage'] }} / {{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['iteration'] | number:'2.0-0' }}</td>
									</tr>
									<tr>
										<td>Last Label Quantity</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['quantity'] }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['crackday']!=null">
										<td>Last Label Cracking Day</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['crackday'] }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['nisline']!=null">
										<td>Last Label NIS Line</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['nisline'] }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['pallet']!=null">
										<td>Last Label Pallet</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['pallet'] }}</td>
									</tr>
									<tr>
										<td>Last Label Print Time</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['printtime'] }}</td>
									</tr>
								</ng-template>
								<ng-container *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']!=undefined">
									<tr class="tableseperator"><td></td><td></td></tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['nisline']!=null">
										<td>Logged NIS Line</td>
										<td>P{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['nisline'] }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['pallet']!=null">
										<td>Logged Pallet</td>
										<td>P{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['pallet'] }}</td>
									</tr>
									<tr>
										<td>Logged Status</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['status'] | titlecase }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['destination']!=null">
										<td>Logged Location</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['destination'] | titlecase }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['orderref']!=null">
										<td>Order Reference</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['orderref'] }}</td>
									</tr>
									<tr>
										<td>Logged Source</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['source'] | titlecase }}</td>
									</tr>
									<tr *ngIf="this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['crackday']!=null">
										<td>Logged Cracking Day</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['crackday'] }}</td>
									</tr>
									<tr>
										<td>Logged Season</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['season'] }}</td>
									</tr>
									<tr>
										<td>Logged Quantity</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['quantity'] }}</td>
									</tr>
									<tr>
										<td>Logged Batch Date</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['batchdate'] }}</td>
									</tr>
									<tr>
										<td>Last Portal Update</td>
										<td>{{ this.teamQRService.qrCodes[this.teamQRService.qrCodes?.length - 1]['log']['modifieddate'] }}</td>
									</tr>
								</ng-container>
								<tr class="tableseperator"><td></td><td></td></tr>
							</tbody>
						</table>

						<div class="col py-4 mx-0 px-0 text-center row justify-content-between">
							<button mdbBtn class="btn btn-success mb-2 mx-0" style="width:50%; max-width:200px;" (click)="this.downloadCSV()">Download CSV</button>
						</div>

					</ng-container>

				</div>
			</div>
		</div>
	</div>
</div>