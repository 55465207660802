import { Component, OnInit } from '@angular/core';
import { PortalService } from '../../auth/portal.service';

//import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { LoginService } from '../auth/login/login.service';
import { FarmerInvoiceSelectorService } from '../invoiceselector/invoiceselector.service';

@Component({
  selector: 'app-farmerinvoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class FarmerInvoiceComponent implements OnInit {
  d: Date = new Date();

  constructor(
  	public loginService: LoginService,
    public invoiceService: FarmerInvoiceSelectorService,
    public portalService: PortalService
  	) { }

  ngOnInit() {
    this.invoiceService.textAdjustments();
  }

  svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('contentToConvert');
		const width = 300, height = 200;

		// create a new jsPDF instance
		const pdf = new jsPDF('p', 'pt', 'a4');

    pdf.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    })
    .then(() => {
      var savename = this.invoiceService.invoice['detail'][0].saveas + '.pdf';
      pdf.save(savename);
    })
	}

}
