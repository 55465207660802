import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class RecoverPalletService{
	private postBody: {};
	httpRes: any;
	public pallet: string = "";

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private portalService: PortalService) {}

	recoverPallet() {
		this.postBody = {
			"name": "recoverPallet",
			"param":{
				"pallet": this.pallet
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response.response.result['reply']['message']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
    this.pallet = "";
  }

}