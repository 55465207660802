import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { SettingsClientsService } from '../../settings/clients/clients.service';
import { DropDownDataService } from '../dropdown.service';
import { Contact } from '../../../interfaces/contacts.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddContactService{
	private postBody: {};
	httpRes: any;
	public createform: boolean;
	public editmode: boolean = false;
	public archived: boolean = false;
	public idcontact: number = 0;
	public loadedEntry: Contact;
  public fname: string;
  public lname: string;
	public company: string;
  public firstname: string;
  public lastname: string;
  public telephone: string;
  public email: string;
  public birthday: string;
  public shirtsize: string;
  public note: string;
  public health: string;

	constructor(
		private http: HttpClient,
		private lS: TeamLoginService,
		private pS: PortalService,
		public dDDS: DropDownDataService,
		public clientsService: SettingsClientsService) {}

	addContact() {
		this.setBirthday();
		this.fname = this.firstname;
		this.lname = this.lastname;
		this.postBody = {
			"name": "addContact",
			"param":{
				"idcompany": +this.company,
				"firstname": this.firstname,
				"lastname": this.lastname,
				"telephone": this.telephone,
				"email": this.email.toLowerCase(),
				"birthday": this.birthday,
				"shirtsize": this.shirtsize,				
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
		        if (response.response.status == '200') {
	        		this.lS.setSession(response);
		        	this.lS.toast("Save Successful",response.response.result['reply']['message']);
					    if ((this.pS.portalMod()['mod-clients'] || this.pS.portalMod()['mod-all']) && response['response']['result']['reply']['type']=='producer') {
					    	this.portalInviteCheck(response);
						  }
	    				this.dDDS.getDropdown("contactList");
		        	this.fname = "";
		        	this.lname = "";
		        	this.resetForm();
		        } else {
	        		this.lS.toast("Save Unsuccessful",response.error['message'],'warn');
		        }
	        } catch(e) {
	        	this.lS.toast("Save Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	async portalInviteCheck(response) {
	 	const res = await this.lS.confirm("Do you want to send a portal invite to '" + this.fname + " " + this.lname + "'?");
    if (res) {
    	this.clientsService.saveClientMembers(response['response']['result']['reply']['id']);
    }
	}

	loadForEdit() {
		this.postBody = {
			"name": "loadContact",
			"param":{
				"idcontact": +this.idcontact
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.lS.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply[0];
		this.archived = this.loadedEntry.health=='archived';
		this.company = this.loadedEntry.idcompany;
		this.firstname = this.loadedEntry.firstname;
		this.lastname = this.loadedEntry.lastname;
		this.telephone = this.loadedEntry.telephone;
		this.email = this.loadedEntry.email;
		this.birthday = this.loadedEntry.birthday;
		this.shirtsize = this.loadedEntry.shirtsize;
		this.note = this.loadedEntry.notes;
		this.health = this.loadedEntry.health;
		this.editmode = true;
	}

	saveChanges() {
		this.setBirthday();
		this.postBody = {
			"name": "updateContact",
			"param":{
				"idcontact": +this.idcontact,
				"idcompany": +this.company,
				"firstname": this.firstname,
				"lastname": this.lastname,
				"telephone": this.telephone,
				"email": this.email.toLowerCase(),
				"birthday": this.birthday,
				"shirtsize": this.shirtsize,				
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
		        if (response.response.status == '200') {
	        		this.lS.setSession(response);
		        	this.lS.toast("Update Successful",response.response.result['reply']);
	    				this.dDDS.getDropdown("contactList");
		        	this.editmode=false;
		        	this.resetForm();
		        } else {
	        		this.lS.toast("Update Unsuccessful",response.error['message'],'warn');
		        }
	        } catch(e) {
	        	this.lS.toast("Update Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry(type = 'delete') {
		const firstnametemp = this.firstname;
		const lastnametemp = this.lastname;
		this.postBody = {
			"name": (type=='delete' ? "deleteContact" : (type=='archive' ? 'archiveContact' : 'unarchiveContact') ),
			"param":{
				"idcontact": +this.idcontact
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
		        if (response.response.status == '200') {
	        		this.lS.setSession(response);
		        	this.lS.toast("Delete Successful",response.response.result['reply']);
	    				this.dDDS.getDropdown("contactList");
		        	this.editmode=false;
		        	this.resetForm();
		        } else {
	        		this.lS.toast("Delete Unsuccessful",response.error['message'],'warn');
		        }
	        } catch(e) {
	        	this.lS.toast("Delete Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
    this.archived = false;
    this.company = "";
    this.firstname = "";
    this.lastname = "";
    this.telephone = "";
    this.email = "";
    this.birthday = "";
    this.shirtsize = "";
    this.note = "";
    this.health = "";
  }

  setBirthday() {
		if (this.birthday!==null && this.birthday!=='') {
	  	this.birthday = (this.birthday==null ? null : moment(this.birthday).format('YYYY-MM-DD'));
	  }
  }

}