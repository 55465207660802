import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { Sample } from '../../../interfaces/samples.interface';
import { ProdItem, Samples } from './add-sample.interface';
import { portalM, PortalService } from '../../../auth/portal.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddSampleBatchedService{
	private postBody: {};
	httpRes: any;
  d: Date = new Date();
	public editmode: boolean = false;
	public stylemode: boolean = true;
	public assignedSample: boolean = true;
	public verdict: string = 'accepted';
	public rejectdestination: string;
	public iddeliveryTemp: string;
	public loadedEntry: Sample;
	public ids: string;
	public iddelivery: string;
	public addDelIndex: string = '0';
	public selectedNewDelivery: Samples = {"complete":0, "initial":0, "tested":0, "production":0};
  public sampletype:string = 'complete';
  public status:string;
  public sampletypes: Samples[] = [
  	{"complete":0, "initial":0, "tested":0, "production":0}
  ];
	public sampledate:string;
  public sampletime:string;
  public swnis:number;
  public sdnis:number;
  public wetkernel:number;
  public drykernel:number;
  public dnis18:number;
  public dnis20:number;
  public dnis22:number;
  public dnis24:number;
  public dnis25:number;
  public shell:number;
  public sk:number;
  public uk:number;
  public tk:number;
  public dark:number;
  public mould:number;
  public immature:number;
  public spot791:number;
  public earlystink:number;
  public latestink:number;
  public germination:number;
  public nutborer:number;
  public discolouration:number;
  public s0:number;
  public s1:number;
  public s1s:number;
  public s2:number;
  public s4l:number;
  public s4r:number;
  public s5:number;
  public s6:number;
  public s7:number;
  public s8:number;
  public s567:number;
	public note:string;
	public sizeddnis: number;
	public totalstyles: number;
  public promoisture:number;
  public prodnis:number;
  public pronocrack:number;
  public prosmalls:number;
  public prous:number;
  public procracked:number;
	public items: ProdItem[] = [
  	{"style": "", "boxunit": 11.34, "bagunit": 25}
  ];
  public indexLink:boolean;
	public producer: number;
	public cultivar: number;
	public sampleid: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private portalService: PortalService,
		private dDDS: DropDownDataService) {}

	addItem() {
		this.items.push({
			"style": "",
		 	"boxunit": 11.34,
		 	"bagunit": 25
		 });
	}

	removeItem(index: number) {
		this.items.splice(index, 1);
	}

  setTotal(itemindex: number) {
  	console.log('test');
		for (var i = 0; i < this.dDDS.dropdown['productList'].length; i++) {
			if (this.dDDS.dropdown['productList'][i]['style']==this.items[itemindex]['style']) {
				this.items[itemindex]['weight'] = this.dDDS.dropdown['productList'][i]['weight'];
				console.log(this.items[itemindex]['weight']);
			}
		}
		console.log('here');
		this.items[itemindex]['total'] = Math.round(( (isNaN(this.items[itemindex]['quantity']) ? 0 : this.items[itemindex]['quantity']) * (isNaN(this.items[itemindex]['weight']) ? 0 : this.items[itemindex]['weight']) + (isNaN(this.items[itemindex]['extra']) ? 0 : this.items[itemindex]['extra']) ) * 100) / 100;
  }

  checkStyle(index,style) {
  	var ans = true;
		for (var i = 0; i < this.items.length; i++) {
			if (this.items[i]['style']==style && index!==i) {
				ans = false;
			}
		}
		return ans;
	}

	addSample() {
		this.fixDate();
		this.postBody = {
			"name": "addSample",
			"param":{
				"noemail": (this.portalService.mode=='dev' ? 1 : 0),
				"assignedsample": (this.assignedSample ? 1 : 0),
				"verdict": this.verdict,
				"rejectdestination": this.rejectdestination,
				"iddelivery": this.iddeliveryTemp,
				"season": this.loginService.season,
				"idproducer": +this.producer,
				"cultivar": +this.cultivar,
				"sampledate": this.sampledate,
				"sampletime": this.sampletime,
				"status": this.sampletype,
				"swnis": (this.swnis !== undefined ? +this.swnis: 0),
				"sdnis": (this.sdnis !== undefined ? +this.sdnis: 0),
				"wetkernel": (this.wetkernel !== undefined ? +this.wetkernel/100: 0),
				"drykernel": +this.drykernel/100,
				"above18": (this.dnis18 !== undefined ? +this.dnis18: 0),
				"above20": (this.dnis20 !== undefined ? +this.dnis20: 0),
				"above22": (this.dnis22 !== undefined ? +this.dnis22: 0),
				"above24": (this.dnis24 !== undefined ? +this.dnis24: 0),
				"above25": (this.dnis25 !== undefined ? +this.dnis25: 0),
				"shell": (this.shell !== undefined ? +this.shell: 0),
				"sound": (this.sk !== undefined ? +this.sk: 0),
				"unsound": (this.uk !== undefined ? +this.uk : 0),
				"dark": (this.dark !== undefined ? +this.dark: 0),
				"mould": (this.mould !== undefined ? +this.mould: 0),
				"immature": (this.immature !== undefined ? +this.immature: 0),
				"spot791": (this.spot791 !== undefined ? +this.spot791: 0),
				"earlystink": (this.earlystink !== undefined ? +this.earlystink: 0),
				"latestink": (this.latestink !== undefined ? +this.latestink: 0),
				"germination": (this.germination !== undefined ? +this.germination: 0),
				"nutborer": (this.nutborer !== undefined ? +this.nutborer: 0),
				"discolouration": (this.discolouration !== undefined ? +this.discolouration: 0),
				"s0": this.s0,
				"s1": this.s1,
				"s1s": this.s1s,
				"s2": this.s2,
				"s4l": this.s4l,
				"s4r": this.s4r,
				"s5": this.s5,
				"s6": this.s6,
				"s7": this.s7,
				"s8": this.s8,
				"s567": this.s567,
				"promoisture": this.promoisture,
				"prodnis": this.prodnis,
				"pronocrack": this.pronocrack,
				"prosmalls": this.prosmalls,
				"prous": this.prous,
				"items": this.items,
				"notes": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']);
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Sample not saved!",'warn');
	        }
        	this.updateSampleListAdd();
        	this.resetForm();
        },
        (error) => console.log(error)
      );
	}	

	loadForEdit(iddelivery = this.iddelivery) {
		this.postBody = {
			"name": "loadSample",
			"param":{
				"ids": +this.ids,
				"iddelivery": (this.assignedSample ? iddelivery : 0),
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = response;
          this.loadEntry(true);
    			this.editmode = true;
        },
        (error) => console.log(error)
      );
	}
	
	loadEntry(freshload = false) {
		if (this.httpRes!==undefined) {
			var reply = this.httpRes.response.result.reply;
			if (freshload) {
				this.sampletype = reply['loadfirst'];
				this.sampletypes['complete'] = (reply['complete']!==undefined ? 1 : 0);
				this.sampletypes['initial'] = (reply['initial']!==undefined ? 1 : 0);
				this.sampletypes['tested'] = (reply['tested']!==undefined ? 1 : 0);
				this.sampletypes['production'] = (reply['production']!==undefined ? 1 : 0);
			}
	 		this.loadedEntry = reply[this.sampletype];

			if (this.loadedEntry !== undefined) {
				this.ids = this.loadedEntry.ids;
				this.status = this.loadedEntry.status;
				this.verdict = (this.status=='rejected' ? 'rejected' : 'accepted');
				this.rejectdestination = this.loadedEntry.rejectdestination.toString();
				this.sampleid = this.loadedEntry.sampleid;
				this.producer = +this.loadedEntry.idproducer;
				this.cultivar = +this.loadedEntry.cultivar;
				this.sampledate = this.loadedEntry.sampledate;
				this.sampletime = this.loadedEntry.sampletime;
				this.assignedSample = +this.loadedEntry.iddelivery!=0;
				this.swnis = +this.loadedEntry.swnis;
				this.sdnis = +this.loadedEntry.sdnis;
				this.wetkernel = Math.floor(+this.loadedEntry.wetkernel * 10000) / 100;
				this.drykernel = Math.floor(+this.loadedEntry.drykernel * 10000) / 100;
				this.dnis18 = +this.loadedEntry.above18;
				this.dnis20 = +this.loadedEntry.above20;
				this.dnis22 = +this.loadedEntry.above22;
				this.dnis24 = +this.loadedEntry.above24;
				this.dnis25 = +this.loadedEntry.above25;
				this.shell = +this.loadedEntry.shell;
				this.sk = +this.loadedEntry.sound;
				this.dark = +this.loadedEntry.dark;
				this.mould = +this.loadedEntry.mould;
				this.immature = +this.loadedEntry.immature;
				this.spot791 = +this.loadedEntry.spot791;
				this.earlystink = +this.loadedEntry.earlystink;
				this.latestink = +this.loadedEntry.latestink;
				this.germination = +this.loadedEntry.germination;
				this.nutborer = +this.loadedEntry.nutborer;
				this.discolouration = +this.loadedEntry.discolouration;
				this.s0 = +this.loadedEntry.s0;
				this.s1 = +this.loadedEntry.s1;
				this.s4l = +this.loadedEntry.s4l;
				this.s4r = +this.loadedEntry.s4r;
				if (this.loadedEntry.s567!=null) {
					this.stylemode = (+this.loadedEntry.s567==0);
				} else {
					this.stylemode = this.isActive('Detailed Style Sampling');
				}
				if (this.stylemode) {
					this.s1s = +this.loadedEntry.s1s;
					this.s2 = +this.loadedEntry.s2;
					this.s5 = +this.loadedEntry.s5;
					this.s6 = +this.loadedEntry.s6;
					this.s7 = +this.loadedEntry.s7;	
					this.s8 = +this.loadedEntry.s8;	
				} else {
					this.s567 = +this.loadedEntry.s567;
				}
				this.note = this.loadedEntry.notes;
				this.uk = +(this.mould !== undefined ? this.mould : 0) + 
		              +(this.immature !== undefined ? this.immature : 0) + 
		              +(this.spot791 !== undefined ? this.spot791 : 0) + 
		              +(this.earlystink !== undefined ? this.earlystink : 0) + 
		              +(this.latestink !== undefined ? this.latestink : 0) + 
		              +(this.germination !== undefined ? this.germination : 0) + 
		              +(this.nutborer !== undefined ? this.nutborer : 0) + 
		              +(this.discolouration !== undefined ? this.discolouration : 0);
		    this.tk = +(this.sk !== undefined ? this.sk : 0) + 
		              +(this.uk !== undefined ? this.uk : 0) + 
		              +(this.dark !== undefined ? this.dark : 0);
		    this.promoisture = Math.round(this.loadedEntry.promoisture*100)/100;
		    this.prodnis = (this.loadedEntry.prodnis !== undefined ? this.loadedEntry.prodnis : 0);
		    this.pronocrack = (this.loadedEntry.pronocrack !== undefined ? this.loadedEntry.pronocrack : 0);
		    this.prosmalls = (this.loadedEntry.prosmalls !== undefined ? this.loadedEntry.prosmalls : 0);
		    this.prous = (this.loadedEntry.prous !== undefined ? this.loadedEntry.prous : 0);
		    this.procracked = +this.prodnis - +this.pronocrack - +this.prosmalls;
		    this.items = reply['items'];
	    }
	    this.iddeliveryTemp = this.iddelivery;
	  }
	}

	saveChanges() {
		this.fixDate();
		this.postBody = {
			"name": "updateSample",
			"param":{
				"noemail": (this.portalService.mode=='dev' ? 1 : 0),
				"assignedsample": (this.assignedSample ? 1 : 0),
				"verdict": this.verdict,
				"rejectdestination": this.rejectdestination,
				"idd": +this.iddelivery,
				"ids": +this.ids,
				"iddelivery": +this.iddeliveryTemp,
				"season": this.loginService.season,
				"idproducer": +this.producer,
				"cultivar": +this.cultivar,
				"sampledate": this.sampledate,
				"sampletime": this.sampletime,
				"status": this.status,
				"swnis": this.swnis,
				"sdnis": this.sdnis,
				"wetkernel": (this.wetkernel !== undefined ? +this.wetkernel/100: 0),
				"drykernel": +this.drykernel/100,
				"above18": (this.dnis18 !== undefined ? +this.dnis18: 0),
				"above20": (this.dnis20 !== undefined ? +this.dnis20: 0),
				"above22": (this.dnis22 !== undefined ? +this.dnis22: 0),
				"above24": (this.dnis24 !== undefined ? +this.dnis24: 0),
				"above25": (this.dnis25 !== undefined ? +this.dnis25: 0),
				"shell": (this.shell !== undefined ? +this.shell: 0),
				"sound": this.sk,
				"unsound": (this.uk !== undefined ? +this.uk : 0),
				"dark": (this.dark !== undefined ? +this.dark: 0),
				"mould": (this.mould !== undefined ? +this.mould: 0),
				"immature": (this.immature !== undefined ? +this.immature: 0),
				"spot791": (this.spot791 !== undefined ? +this.spot791: 0),
				"earlystink": (this.earlystink !== undefined ? +this.earlystink: 0),
				"latestink": (this.latestink !== undefined ? +this.latestink: 0),
				"germination": (this.germination !== undefined ? +this.germination: 0),
				"nutborer": (this.nutborer !== undefined ? +this.nutborer: 0),
				"discolouration": (this.discolouration !== undefined ? +this.discolouration: 0),
				"s0": this.s0,
				"s1": this.s1,
				"s1s": this.s1s,
				"s2": this.s2,
				"s4l": this.s4l,
				"s4r": this.s4r,
				"s5": this.s5,
				"s6": this.s6,
				"s7": this.s7,
				"s8": this.s8,
				"s567": this.s567,
				"promoisture": this.promoisture,
				"prodnis": this.prodnis,
				"pronocrack": (this.pronocrack !== undefined ? +this.pronocrack : 0),
				"prosmalls": (this.prosmalls !== undefined ? +this.prosmalls : 0),
				"prous": (this.prous !== undefined ? +this.prous : 0),
				"items": this.items,
				"notes": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']);
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Sample was not updated!",'warn');
	        }
        	this.resetForm();
        	this.editmode=false;
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() {
		this.postBody = {
			"name": "deleteSample",
			"param":{
				"ids": (this.ids==null ? 0 : +this.ids),
				"iddelivery": +this.iddelivery,
				"season": this.loginService.season,
				"status": this.status
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.updateSampleList();
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']);
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Sample was not deleted!",'warn');
	        }
        	this.resetForm();
        	this.editmode=false;
        },
        (error) => console.log(error)
      );
	}

 getUK() {
    this.uk = +(this.mould !== undefined ? this.mould : 0) + 
              +(this.immature !== undefined ? this.immature : 0) + 
              +(this.spot791 !== undefined ? this.spot791 : 0) + 
              +(this.earlystink !== undefined ? this.earlystink : 0) + 
              +(this.latestink !== undefined ? this.latestink : 0) + 
              +(this.germination !== undefined ? this.germination : 0) + 
              +(this.nutborer !== undefined ? this.nutborer : 0) + 
              +(this.discolouration !== undefined ? this.discolouration : 0);
    this.getTK();
  }
 
  getTK() {
    this.tk = +(this.sk !== undefined ? this.sk : 0) + 
              +(this.uk !== undefined ? this.uk : 0) + 
              +(this.dark !== undefined ? this.dark : 0);
  }
 
  getcracked() {
    this.procracked = +(this.prodnis !== undefined ? this.prodnis : 0) - 
              +(this.pronocrack !== undefined ? this.pronocrack : 0) - 
              +(this.prosmalls !== undefined ? this.prosmalls : 0);
  }

  setStatus() {
  	var i = this.addDelIndex;
  	this.selectedNewDelivery = this.dDDS.dropdown.sampleListAdd['samples'][i];
  	if (this.selectedNewDelivery['type']==1) {
	  	if (this.selectedNewDelivery['initial']==1) {
	  		this.sampletype = 'initial';
	  	} else if (this.selectedNewDelivery['tested']==1) {
	  		this.sampletype = 'tested';
	  	} else if (this.selectedNewDelivery['production']==1) {
	  		this.sampletype = 'production';
  		} else {
  			this.sampletype = 'complete';
  		}
  	}	else {
  		this.sampletype = 'complete';
  	}
  }

  updateSampleListAdd() {
  	var initLength = this.dDDS.dropdown['sampleListAdd']['samples'].length;
  	for (var i = 0; i < initLength; i++) {
  		if (this.dDDS.dropdown['sampleListAdd']['samples'][i]['iddelivery']==this.iddeliveryTemp && this.dDDS.dropdown['sampleListAdd']['samples'][i][this.sampletype]==1) {
  			this.dDDS.dropdown['sampleListAdd']['samples'].shift();
  			if (initLength>1) {
  				this.dDDS.dropdown['sampleListAdd']['loadfirst'] = this.dDDS.dropdown['sampleListAdd']['samples'][i+1]['iddelivery'];
  			} else {
    			this.addDelIndex = '0';
  				this.dDDS.dropdown['sampleListAdd']['loadfirst'] = null;
  				this.dDDS.dropdown.sampleListAdd['loadfirstindex'] = null;
  				this.selectedNewDelivery = {"complete":0, "initial":0, "tested":0, "production":0};
  			}
			}
	  }
  }

  updateSampleList() {
  	for (var i = 0; i < this.dDDS.dropdown['sampleList'][this.sampletype].length; i++) {
  		if (this.dDDS.dropdown['sampleList'][this.sampletype][i]['iddelivery']==this.iddeliveryTemp) {
  			this.dDDS.dropdown['sampleList'][this.sampletype].shift();
			}
  	}
  }

  resetForm(loadLists = true) {
    window.scroll(0,0);
    if (!this.indexLink) {
	    if (loadLists) {
		    this.dDDS.getDropdown("sampleList");
		    this.dDDS.getDropdown("sampleListAdd");
		    this.dDDS.getDropdown("sampleListComplete");
		    this.dDDS.getDropdown("sampleListUnassigned");
		  }
	    if (this.dDDS.dropdown.sampleListAdd!==undefined) {
	    	if (this.dDDS.dropdown.sampleListAdd['loadfirstindex']!==null) {
		    	this.addDelIndex = this.dDDS.dropdown.sampleListAdd['loadfirstindex'].toString();
		    	this.setStatus();
		    }
	    }
	    this.ids = null;
	    this.verdict = 'accepted';
	    this.rejectdestination = null;
	    this.sampledate = this.d.toISOString().substring(0,10);
	    this.sampletime = this.d.getHours().toString().padStart(2,'0') + ':' + this.d.getMinutes().toString().padStart(2,'0') + ':' + this.d.getSeconds().toString().padStart(2,'0');
	    this.swnis = null;
	    this.sdnis = null;
	    this.wetkernel = null;
	    this.drykernel = null;
	    this.dnis18 = null;
	    this.dnis20 = null;
	    this.dnis22 = null;
	    this.dnis24 = null;
	    this.dnis25 = null;
	    this.shell = null;
	    this.sk = null;
	    this.uk = null;
	    this.tk = null;
	    this.dark = null;
	    this.mould = null;
	    this.immature = null;
	    this.spot791 = null;
	    this.earlystink = null;
	    this.latestink = null;
	    this.germination = null;
	    this.nutborer = null;
	    this.discolouration = null;
	    this.s0 = null;
	    this.s1 = null;
	    this.s1s = null;
	    this.s2 = null;
	    this.s4l = null;
	    this.s4r = null;
	    this.s5 = null;
	    this.s6 = null;
	    this.s7 = null;
	    this.s8 = null;
	    this.s567 = null;
			this.promoisture = null;
			this.prodnis = null;
			this.pronocrack = null;
			this.prosmalls = null;
			this.prous = null;
			this.procracked = null;
	    this.note = "";
	    this.items = null;
	    this.items = [
		  	{"style": "", "boxunit": 11.34, "bagunit": 25}
		  ];
		  this.indexLink = false;
		  this.producer = null;
		  this.cultivar = null;
		}
  }

  isActive(setting, option = null) {
    var ans = false;
    var iterate = false;
    if (option==null) {
      iterate = true;
    }
    if (!iterate) {
      iterate = this.loginService.isAuthenticated(option);
    }
    if (iterate) {
      for (var i = 0; i <  portalM()['settings'].length; i++) {
        if ( portalM()['settings'][i]['setting']==setting) {
          ans = ( portalM()['settings'][i]['value']==1);
        }
      }
    }
    return ans;
  }

	fixDate() {
	  this.sampledate = moment(this.sampledate).format('YYYY-MM-DD');
	}


}