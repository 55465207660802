import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md';
import { Knob } from 'primeng/knob';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { LoginService } from '../auth/login/login.service';
import { CalculatorService } from './calculator.service';
import { Model, Cultivars } from './calculator.interface';
import { portalM } from '../../auth/portal.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  memberSelect;
  calComplete: boolean = false;
  wnis = 5000;
  dnis: number = 0;
  moisture = 6;
  skr = 30.2;
  uskr = 1.6;
  darks = 0;
  transportkm = 0;
  checked = false;
  checkedVAT = true;
  disabled;
  color;
  cultivarIndex: string;
  cultivar: Cultivars = {"idcultivar": "22", "cultivar": "695 (Beaumont)", "variety": "Integ"};

  @ViewChild('knob25') knob25: Knob;
  @ViewChild('knob22') knob22: Knob;
  @ViewChild('knob20') knob20: Knob;
  @ViewChild('knob18') knob18: Knob;

  constructor(
    private loginService: LoginService,
    public cS: CalculatorService,
    private cD: ChangeDetectorRef 
    ) { }

  ngOnInit() {
    this.cS.selectedCurrency = "ZAR";
    this.cultivarIndex = this.getCultivarIndex(22);
    this.onChoose();
    this.memberSelect = this.loginService.selectedmember;
    if (this.memberSelect != null) {
      this.cS.loadPriceModel(this.memberSelect);
    }
    window.scrollTo(0,0);
  }

  ngOnDestroy() {
    this.reset();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  showknob(size,event) {
    let sum = this.cS.sizes['dnis25'] + this.cS.sizes['dnis22'] + this.cS.sizes['dnis20'];
    if (sum >= 100) {
      this.cS.sizes['dnis18'] = 0;
      this.cS.sizes[size] = 100 
       - (size=='dnis25' ? 0 : this.cS.sizes['dnis25'])
       - (size=='dnis22' ? 0 : this.cS.sizes['dnis22'])
       - (size=='dnis20' ? 0 : this.cS.sizes['dnis20']);
    }
    if (sum<100) {
      this.cS.sizes['dnis18'] = Math.max(0, 100 - this.cS.sizes['dnis25'] - this.cS.sizes['dnis22'] - this.cS.sizes['dnis20']);
    }
    this.cS.sizes[size] = Math.min(this.cS.sizes[size], 100
     - (size=='dnis25' ? 0 : this.cS.sizes['dnis25'])
     - (size=='dnis22' ? 0 : this.cS.sizes['dnis22'])
     - (size=='dnis20' ? 0 : this.cS.sizes['dnis20'])
     - (size=='dnis25' ? 0 : this.cS.sizes['dnis18']));
    this.knob18.writeValue(this.cS.sizes['dnis18']);
    switch (size) {
      case 'dnis25':
        this.knob25.writeValue(this.cS.sizes['dnis25']);
        break;
      case 'dnis22':
        this.knob22.writeValue(this.cS.sizes['dnis22']);
        break;
      case 'dnis20':
        this.knob20.writeValue(this.cS.sizes['dnis20']);
        break;
      case 'dnis18':
        this.knob18.writeValue(this.cS.sizes['dnis18']);
        break;
      default:
        break;
    }
  }

  plus(size) {
    this.cS.sizes[size] += 1;
    this.showknob(size, this.cS.sizes[size]);
  }

  minus(size) {
    this.cS.sizes[size] -= 1;
    this.showknob(size, this.cS.sizes[size]);
  }

  onChoose() {
    this.cultivar = this.cS.cultivarList[this.cultivarIndex];
  }

  getCultivarIndex(idcultivar: number) {
    for (var i = 0; i < this.cS.cultivarList.length; i++) {
      if (+this.cS.cultivarList[i]['idcultivar'] == idcultivar) {
        return i + "";
      }
    }
  }

  onCurrencySelected(currency: string) {
    this.cS.selectedCurrency = currency.toUpperCase();
    if (this.calComplete) {
      this.onCalculate();
    }
  }

  async onCalculate() {
    var calc = await this.cS.calculatePricing(+this.wnis,+this.moisture/100,+this.skr/100,+this.uskr/100,this.checked,this.checkedVAT,+this.darks/100,+this.cultivar.idcultivar);
    this.calComplete = true;
    window.scrollTo(0,0);
    this.cD.detectChanges();
  }

  async onNewCalculation() {
    this.calComplete = await false;
    window.scrollTo(0,0);
    await this.cD.detectChanges();
    await this.cD.detectChanges();
  }

  onInputChangeWNIS(event: any) {
    this.wnis = event.value;
  }
  onInputChangeMoisture(event: any) {
    this.moisture = event.value;
  }
  onInputChangeKM(event: any) {
    this.transportkm = event.value;
  }
  onInputChangeSKR(event: any) {
    this.skr = event.value;
  }
  onInputChangeUSKR(event: any) {
    this.uskr = event.value;
  }
  onInputChangeDarks(event: any) {
    this.darks = event.value;
  }

  reset() {
    this.cS.selectedCurrency = "ZAR";
    this.cS.sizes = {"dnis18": 0, "dnis20": 0, "dnis22": 0, "dnis25": 0};
    this.wnis = 5000;
    this.dnis = 0;
    this.moisture = 6;
    this.skr = 30.2;
    this.uskr = 1.6;
    this.darks = 0;
    this.transportkm = 0;
    this.checked = false;
    this.checkedVAT = true;
  }

}
