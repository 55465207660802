import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { BuyingLoginService } from '../auth/login/login.service';
//import { OfferComponent } from '../offer/offer.component';
//import { BidComponent } from '../bid/bid.component';

@Component({
  selector: 'app-buyingbidoffers',
  templateUrl: './bidoffers.component.html',
  styleUrls: ['./bidoffers.component.scss'],
  providers: [DialogService]
})
export class BuyingBidOffersComponent implements OnInit {
  type: string = null;
  hoverid: number = 0;
  offerrange = Array(2);
  ref: DynamicDialogRef;

//*
  products: any = [
    {"type": "kernel", "product" : 'Style 0'},
    {"type": "kernel", "product" : 'Style 1'},
    {"type": "kernel", "product" : 'Style 1S'},
    {"type": "nis", "product" : 'NIS 20-22m (>2%)'},
    {"type": "nis", "product" : 'NIS 22mm+ (<2%)'},
    {"type": "nis", "product" : 'NIS 24mm+ (<2%)'}
  ];

  offers: any = {
    "Style 0" : [
        {product: "Style 0", quantity: 500, price: 10.54, id: 1},
	    	{product: "Style 0", quantity: 108, price: 10.63, id: 2},
    ],
    "Style 1" : [
        {product: "Style 1", quantity: 500, price: 10.05, id: 3},
		    {product: "Style 1", quantity: 108, price: 10.08, id: 4},
    ],
    "Style 1S" : [
    		{product: "Style 1S", quantity: 500, price: 9.44, id: 5}
    ],
    "NIS 20-22m (>2%)" : [
        {product: "NIS 20-22m (>2%)", quantity: 25000, price: 2.93, id: 6},
        {product: "NIS 20-22m (>2%)", quantity: 50000, price: 2.94, id: 7},
        {product: "NIS 20-22m (>2%)", quantity: 75000, price: 2.90, id: 8}
    ],
    "NIS 22mm+ (<2%)" : [
        {product: "NIS 24mm+ (<2%)", quantity: 25000, price: 2.98, id: 9},
        {product: "NIS 24mm+ (<2%)", quantity: 25000, price: 2.95, id: 10}
    ],
    "NIS 24mm+ (<2%)" : [
        {product: "NIS 22mm+ (<2%)", quantity: 25000, price: 3.08, id: 11}
    ]
  };

/*/

  products: any = [
    {"product" : 'NIS 20-22m (>2%)'},
    {"product" : 'NIS 22mm+ (<2%)'},
    {"product" : 'NIS 24mm+ (<2%)'}
  ];

  offers: any = {
    "NIS 20-22m (>2%)" : [
        {product: "NIS 20-22m (>2%)", quantity: 25000, price: 2.93, id: 1},
        {product: "NIS 20-22m (>2%)", quantity: 50000, price: 2.94, id: 2},
        {product: "NIS 20-22m (>2%)", quantity: 75000, price: 2.90, id: 3}
    ],
    "NIS 22mm+ (<2%)" : [
        {product: "NIS 24mm+ (<2%)", quantity: 25000, price: 2.98, id: 4},
        {product: "NIS 24mm+ (<2%)", quantity: 25000, price: 2.95, id: 5}
    ],
    "NIS 24mm+ (<2%)" : [
        {product: "NIS 22mm+ (<2%)", quantity: 25000, price: 3.08, id: 6}
    ]
  };
  
  //*/
  constructor(
    //private websocketService: WebSocketService,
    public lS: BuyingLoginService,
    public dialogService: DialogService
    ) { }

  ngOnInit(): void {
  	console.log(this.offers[this.products[0].product][0]);
//    this.websocketService.receiveMessage().subscribe((message: any) => {
//      if (message.description === 'orderbook') {
//        if (message.type === 'bid') {
//          this.bids.push({ product: message.product, price: message.price, quantity: message.quantity });
//        } else if (message.type === 'offer') {
//          this.offers.push({ product: message.product, price: message.price, quantity: message.quantity });
//        } else if (message.type === 'lastTrade') {
          //this.trades[message.product] = { product: message.product, price: message.price, quantity: message.quantity, time: message.time };
//        }
//
         //
//
//
//	    }
//        }
//    });
  }

  menter(type,product,id) {
    this.type = type;
    this.hoverid = id;
  }


  mleave() {
    this.type = null;
    this.hoverid = 0;
  }

  show(type) {/*
    this.ref = this.dialogService.open(
      (type=='offers' ? OfferComponent : BidComponent), { 
        header: (type=='offers' ? 'Purchase: Trade requirements' : 'Sale: Trade requirements'),
        maximizable: true
      }
    );*/
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

}