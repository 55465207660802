import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alpha'
})
export class AlphaPipe implements PipeTransform {

  transform(value: string | number): string {
    var line = ['A','B','C','D','E','F','G','H','I','J'];
    return line[value];
  }

}
