<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>

	<div [ngClass]="logInState">
		<div [ngClass]="classState">
			<div class="my-4 col">
				<h2>QR - Manage Inventory</h2>
			</div>
			<br>

			<div class="px-3">
				<button mdbBtn class="ms-0 me-3 btn" [class.btn-secondary]="this.lQR.mode=='inspect'" *ngIf="true" (click)="toggleMode('inspect')">Inspect</button>
				<button mdbBtn class="ms-0 me-3 btn" [class.btn-success]="this.lQR.mode=='add'" (click)="toggleMode('add')">Add</button>
				<button mdbBtn class="ms-0 me-3 btn" [class.btn-info]="this.lQR.mode=='move'" *ngIf="true" (click)="toggleMode('move')">Move</button>
				<button mdbBtn class="ms-0 me-3 btn" [class.btn-danger]="this.lQR.mode=='remove'" *ngIf="true" (click)="toggleMode('remove')">Remove</button>
			</div>

			<br>

			<div *ngIf="this.lQR.mode=='add' || this.lQR.mode=='move'" class="col col-12 col-md-12 col-lg-8">
				<br>
				<div class="form-container">
					<mat-form-field>
				    <mat-select placeholder="{{ ( this.lQR.mode=='add' || this.lQR.mode=='move' ? 'New ' : '' ) }}Destination" [(ngModel)]="this.lQR.destination">
				    	<ng-container *ngIf="this.lS.dropdown.scanDestinations!==undefined">
				      	<mat-option *ngFor="let storage of this.lS.dropdown.scanDestinations['destinations']" value="{{ storage.idstorage }}">{{ storage.type | titlecase }}: {{ storage.name }}</mat-option>
				      </ng-container>
				    </mat-select>
				  </mat-form-field>
				</div>
				<div class="form-container">
					<mat-form-field>
				    <mat-select placeholder="Order Reference" [(ngModel)]="this.lQR.orderref">
				      <mat-option value="">Unassigned</mat-option>
				      <mat-option *ngFor="let order of this.lS.dropdown.dispatchStyles" value="{{ order.orderref }}">{{ order.orderref }} [ {{ (order.ordernumber=='' ? '* Not assigned *' : order.ordernumber) }} ]</mat-option>
				    </mat-select>
				  </mat-form-field>
				</div>
			</div>
			<br>
			<div class="row justify-content-md mx-1">	
				<div class="my-4 col-12 col-md-12 col-lg-8">				
					<ng-container *ngIf="this.lQR.destination!==null || (this.lQR.mode!='add' && this.lQR.mode!='move')">
						<div>
							<h4>Label Scan Summary</h4>
						</div>
						<table class="my-4 table">
							<tbody>
								<tr class="tableseperator"><td></td><td></td></tr>
								<tr>
									<td>Total Scanned</td>
									<td>{{ (this.lQR.qrCodes.length > 0 ? ( this.lQR.qrCodes[0]['type'] == "" ? 0 : this.lQR.qrCodes.length ) : 0 ) }}</td>
								</tr>
								<tr class="tableseperator"><td></td><td></td></tr>
							</tbody>
						</table>

						<app-scanner #scanner
							(scanSuccess)="handleQrCodeResult($event)">
							</app-scanner>

						<hr>
						<div class="col py-4 mx-0 px-0 text-center row justify-content-between">
							<button *ngIf="this.lQR.mode=='add'" mdbBtn class="btn btn-success mb-2 mx-0" style="width:50%; max-width:200px;" [disabled]="!this.lQR.canSave()" (click)="this.lQR.saveInventory()">Add to Inventory{{ (this.lQR.qrCodes.length > 0 ? ( this.lQR.qrCodes[0]['type'] == "" ? null : ' (' + this.lQR.qrCodes.length + ( this.lQR.onlyNISpallet() ? '' : ' of ' + this.pS.portalMod()['palletsize'] ) + ')' ) : null ) }}</button>
							<button *ngIf="this.lQR.mode=='move'" mdbBtn class="btn btn-info mb-2 mx-0" style="width:100%; max-width:200px;" [disabled]="!this.lQR.canSave()" (click)="this.lQR.moveInventory()">Move Inventory</button>
							<button *ngIf="this.lQR.mode=='remove'" mdbBtn class="btn btn-danger mb-2 mx-0" style="width:100%; max-width:200px;" [disabled]="!this.lQR.canSave()" (click)="this.lQR.removeInventory()">Remove Inventory</button>
							<button mdbBtn class="btn btn-warning mb-2 mx-0 px-0" style="width:100%; max-width:200px;" (click)="this.lQR.reset()">Reset</button>
						</div>
						
						<ng-container *ngIf="this.lQR.qrCodes.length > 0 && this.lQR.qrCodes[0]['type'] !== ''">
							
							<hr>
						
							<h4 class="my-4">Label Scan Summary</h4>
							<table class="my-4 table">
								<tbody>
									<tr class="tableseperator"><td></td><td></td></tr>
									<tr *ngIf="this.lQR.codeStyles['S0']>0">
										<td>Style 0</td>
										<td>{{ this.lQR.codeStyles['S0'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S1L']>0">
										<td>Style 1L</td>
										<td>{{ this.lQR.codeStyles['S1L'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S1']>0">
										<td>Style 1</td>
										<td>{{ this.lQR.codeStyles['S1'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S1S']>0">
										<td>Style 1s</td>
										<td>{{ this.lQR.codeStyles['S1S'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S2']>0">
										<td>Style 2</td>
										<td>{{ this.lQR.codeStyles['S2'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S4L']>0">
										<td>Style 4L</td>
										<td>{{ this.lQR.codeStyles['S4L'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S4']>0">
										<td>Style 4</td>
										<td>{{ this.lQR.codeStyles['S4'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S4R']>0">
										<td>Style 4R</td>
										<td>{{ this.lQR.codeStyles['S4R'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S4AR']>0">
										<td>Style 4AR</td>
										<td>{{ this.lQR.codeStyles['S4AR'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S4S']>0">
										<td>Style 4S</td>
										<td>{{ this.lQR.codeStyles['S4S'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S5']>0">
										<td>Style 5</td>
										<td>{{ this.lQR.codeStyles['S5'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S6']>0">
										<td>Style 6</td>
										<td>{{ this.lQR.codeStyles['S6'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S7']>0">
										<td>Style 7</td>
										<td>{{ this.lQR.codeStyles['S7'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['S8']>0">
										<td>Style 8</td>
										<td>{{ this.lQR.codeStyles['S8'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C0']>0">
										<td>Commercial C0</td>
										<td>{{ this.lQR.codeStyles['C0'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C1L']>0">
										<td>Commercial C1L</td>
										<td>{{ this.lQR.codeStyles['C1L'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C1']>0">
										<td>Commercial C1</td>
										<td>{{ this.lQR.codeStyles['C1'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C1S']>0">
										<td>Commercial C1s</td>
										<td>{{ this.lQR.codeStyles['C1S'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C2']>0">
										<td>Commercial C2</td>
										<td>{{ this.lQR.codeStyles['C2'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C4L']>0">
										<td>Commercial C4L</td>
										<td>{{ this.lQR.codeStyles['C4L'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C4']>0">
										<td>Commercial C4</td>
										<td>{{ this.lQR.codeStyles['C4'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C4R']>0">
										<td>Commercial C4R</td>
										<td>{{ this.lQR.codeStyles['C4R'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C5']>0">
										<td>Commercial C5</td>
										<td>{{ this.lQR.codeStyles['C5'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C6']>0">
										<td>Commercial C6</td>
										<td>{{ this.lQR.codeStyles['C6'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C7']>0">
										<td>Commercial C7</td>
										<td>{{ this.lQR.codeStyles['C7'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['C8']>0">
										<td>Commercial C8</td>
										<td>{{ this.lQR.codeStyles['C8'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['COM']>0">
										<td>Commercial</td>
										<td>{{ this.lQR.codeStyles['COM'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['OIL']>0">
										<td>Oil Grade</td>
										<td>{{ this.lQR.codeStyles['OIL'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['OIA']>0">
										<td>Oil Grade A</td>
										<td>{{ this.lQR.codeStyles['OIA'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['OIB']>0">
										<td>Oil Grade B</td>
										<td>{{ this.lQR.codeStyles['OIB'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['OIC']>0">
										<td>Oil Grade C</td>
										<td>{{ this.lQR.codeStyles['OIC'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['OIM']>0">
										<td>Oil Grade Meal</td>
										<td>{{ this.lQR.codeStyles['OIM'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['BCH']>0">
										<td>Bio Char</td>
										<td>{{ this.lQR.codeStyles['BCH'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['N18']>0">
										<td>NIS > 18mm</td>
										<td>{{ this.lQR.codeStyles['N18'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['N20']>0">
										<td>NIS > 20mm</td>
										<td>{{ this.lQR.codeStyles['N20'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['N22']>0">
										<td>NIS > 22mm</td>
										<td>{{ this.lQR.codeStyles['N22'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['N24']>0">
										<td>NIS > 24mm</td>
										<td>{{ this.lQR.codeStyles['N24'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['N25']>0">
										<td>NIS > 25mm</td>
										<td>{{ this.lQR.codeStyles['N25'] }}</td>
									</tr>
									<tr *ngIf="this.lQR.codeStyles['N26']>0">
										<td>NIS > 26mm</td>
										<td>{{ this.lQR.codeStyles['N26'] }}</td>
									</tr>
									<ng-template [ngIf]="this.lQR.qrCodes.length > 0 && this.lQR.qrCodes[0]['type'] !== ''">
										<ng-container *ngIf="this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['recall']!=undefined">
											<tr class="tableseperator"><td></td><td></td></tr>
											<tr class="recall">
												<td>RECALLED</td>
												<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['recall'] | uppercase }}</td>
											</tr>
										</ng-container>
										<tr class="tableseperator"><td></td><td></td></tr>
										<tr>
											<td>Last Label Date</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['packdate'] }}</td>
										</tr>
										<tr>
											<td>Last Label Style</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['style'] }} {{ (this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['variety'] == 'I' ? 'Integ' : 'Hybrid' )}}</td>
										</tr>
										<tr>
											<td>Last Label Silo</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['storage'] }} / {{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['iteration'] | number:'2.0-0' }}</td>
										</tr>
										<tr>
											<td>Last Label Quantity</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['quantity'] }}</td>
										</tr>
										<tr>
											<td>Last Label Print Time</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['printtime'] }}</td>
										</tr>
									</ng-template>
									<ng-container *ngIf="this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']!=undefined">
										<tr class="tableseperator"><td></td><td></td></tr>
										<tr>
											<td>Logged Source</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['source'] | titlecase }}</td>
										</tr>
										<tr>
											<td>Logged Quantity</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['quantity'] }}</td>
										</tr>
										<tr>
											<td>Logged Season</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['season'] }}</td>
										</tr>
										<tr>
											<td>Logged Batch Date</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['batchdate'] }}</td>
										</tr>
										<tr>
											<td>Logged Status</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['status'] | titlecase }}</td>
										</tr>
										<tr *ngIf="this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['orderref']!=null">
											<td>Order Reference</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['orderref'] }}</td>
										</tr>
										<tr *ngIf="this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['destination']!=null">
											<td>Logged Location</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['destination'] | titlecase }}</td>
										</tr>
										<tr>
											<td>Last Portal Update</td>
											<td>{{ this.lQR.qrCodes[this.lQR.qrCodes?.length - 1]['log']['modifieddate'] }}</td>
										</tr>
									</ng-container>
									<tr class="tableseperator"><td></td><td></td></tr>
								</tbody>
							</table>

							<div class="col py-4 mx-0 px-0 text-center row justify-content-between">
								<button mdbBtn class="btn btn-success mb-2 mx-0" style="width:50%; max-width:200px;" (click)="this.downloadCSV()">Download CSV</button>
							</div>

						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>