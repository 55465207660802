import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
 providedIn: 'root'
})
export class FilesService {
	constructor(private http: HttpClient) {}
	private sanitizer: DomSanitizer;
	public imageData = [];

	getImage(httpRoot: string, token: string, image: string): Observable<Blob> {
 		var postBody = {
      "name": 'getImage',
      "param":{
        "request": true,
        "image": image
      }
    };
    return this.http.post(
      httpRoot,
      postBody,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(token)
        }),
        responseType: 'blob'
      });
 	}

 	getSig(httpRoot: string, token: string, image: string) {
 		this.getImage(httpRoot, token, image).subscribe(
      (data: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Convert the blob to a data URL, which can be used as the source for the img element
          this.imageData[image] = reader.result;
        };
        reader.readAsDataURL(data);
      },
      (error) => {
        console.error('Error loading image', error);
      }
    );
 	}

}