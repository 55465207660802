<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.aCS.editmode">Add Company</h2>
				<h2 *ngIf="this.aCS.editmode">Edit Company</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.aCS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/companies">View Companies</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Company</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.aCS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/companies">View Companies</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Company</button>
			</div>
		</div>
		<br>
		<div class="col col-lg-9 col-xl-7">	 	
			<div class="form-container">
			  
			  <mat-form-field *ngIf="this.aCS.editmode">
    			<mat-label>Company Name</mat-label>
			    <mat-select placeholder="Company Name" [(ngModel)]="this.aCS.idcompany" (keydown.tab)="loadForEdit()">
			      <mat-option *ngFor="let company of this.dDDS.dropdown.companyList" #prod (click)="this.loadForEdit()" [class.archived]="company.health=='archived'" [value]="company.idcompany">{{ company.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <br *ngIf="this.aCS.editmode">
			  
			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Company Type</mat-label>
			    <mat-select placeholder="Company Type" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.companytype">
			      <mat-option value="buyer">Buyer</mat-option>
			      <mat-option value="consignee">Consignee</mat-option>
			      <mat-option value="courier">Courier</mat-option>
			      <mat-option value="producer">Farmer</mat-option>
			      <mat-option value="freight">Freight Forwarder</mat-option>
			      <mat-option value="logistics">Logistics</mat-option>
			      <mat-option value="processor">Processor</mat-option>
			      <mat-option value="services">Service Provider</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <form [formGroup]="form" *ngIf="editSelected()">
			    <mat-form-field >
			      <mat-label>Company Name</mat-label>
			      <input matInput placeholder="Company Name" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.companyname" maxlength="75" formControlName="companyname">
			      <mat-error *ngIf="form.get('companyname').errors?.forbiddenString">This company name already exists</mat-error>
			      <mat-error *ngIf="form.get('companyname').errors?.required">Company name required</mat-error>
			    </mat-form-field>
			  </form>

				<mat-form-field *ngIf="editSelected()">
    			<mat-label>Parent Company</mat-label>
			    <mat-select placeholder="Parent Company" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.parent">
			    	<mat-option #prod (click)="this.aCS.setFarmId()" value="" *ngIf="!this.aCS.editmode">No parent</mat-option>
				     <ng-container *ngFor="let company of this.dDDS.dropdown.companyList">
				      <mat-option *ngIf="+company.idcompany==+this.aCS.idcompany" #prod (click)="this.aCS.setFarmId()" value="{{ company.idcompany }}">None</mat-option>
				    </ng-container>
				     <ng-container *ngFor="let company of this.dDDS.dropdown.companyList">
				      <mat-option *ngIf="+company.idcompany!=+this.aCS.idcompany" #prod (click)="this.aCS.setFarmId()" value="{{ company.idcompany }}">{{ company.companyname }}</mat-option>
			   		</ng-container>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'" [class.notunique]="!this.aCS.checkFarmIdUnique()" >
    			<mat-label>Farm ID</mat-label>
			    <input matInput placeholder="Farm ID" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.farmid">
			  </mat-form-field>
				
			  <strong *ngIf="!this.aCS.checkFarmIdUnique()">Farm ID already taken</strong>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Contact Name</mat-label>
			    <mat-select placeholder="Contact Name" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.contact">
			      <mat-option *ngIf="!this.aCS.editmode" #prod value="0">Create New Contact (as below):</mat-option>
			      <mat-option *ngFor="let contact of this.dDDS.dropdown.mainContactList" #prod (click)="loadContactDetails()" value="{{ contact.idcontact }}">{{ contact.firstname }} {{ contact.lastname }} [{{ contact.companyname }}]</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && !this.aCS.editmode">
    			<mat-label>First Name</mat-label>
			    <input matInput placeholder="First Name" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.firstname">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && !this.aCS.editmode">
    			<mat-label>Last Name</mat-label>
			    <input matInput placeholder="Last Name" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.lastname">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Telephone Number</mat-label>
			    <input matInput placeholder="Telephone Number" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.tel">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Email Address</mat-label>
			    <input matInput placeholder="Email Address" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.email">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Country</mat-label>
			    <mat-select placeholder="Country" [disabled]="this.aCS.archived" [(value)]="this.countryService.country.id" (keydown.tab)="this.countryService.setState(this.countryService.country.id)">
			      <mat-option *ngFor="let country of this.countryService.countries" [value]="country.id" (click)="this.countryService.setState(this.countryService.country.id)">{{ country.country }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>State</mat-label>
			    <mat-select placeholder="State" [disabled]="this.aCS.archived" [(ngModel)]="this.countryService.state">
			      <mat-option *ngFor="let state of this.countryService.states" value="{{ state }}">{{ state }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Address</mat-label>
			    <input matInput placeholder="Address" [disabled]="this.aCS.archived" maxlength="90" [(ngModel)]="this.aCS.address">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Area</mat-label>
			    <input matInput placeholder="Area" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.area">
			  </mat-form-field>

			   <mat-form-field *ngIf="editSelected()">
    			<mat-label>Post Code</mat-label>
			    <input matInput placeholder="Post Code" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.postcode">
			  </mat-form-field>

			   <mat-form-field *ngIf="editSelected()">
    			<mat-label>PO Box</mat-label>
			    <input matInput placeholder="PO Box" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.pobox">
			  </mat-form-field>

	      <hr *ngIf="editSelected() && this.aCS.companytype=='producer'">

			   <mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'">
    			<mat-label>Distance to Processor (km)</mat-label>
			    <input matInput placeholder="Distance to Processor (km)" [disabled]="this.aCS.archived" type="number" [(ngModel)]="this.aCS.hubkm">
			  </mat-form-field>

			  <hr>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Website URL</mat-label>
			    <input matInput placeholder="Website URL" [disabled]="this.aCS.archived" maxlength="2048" [(ngModel)]="this.aCS.website">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'">
    			<mat-label>Producer Unit Code (PUC)</mat-label>
			    <input matInput placeholder="Producer Unit Code (PUC)" [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.farmerpuc">
			  </mat-form-field>

				<mat-slide-toggle *ngIf="editSelected() && this.aCS.companytype=='producer'" class="slider-margin slider-label" [(ngModel)]="this.aCS.globalgap">
	        GLOBAL G.A.P. Certified?
	      </mat-slide-toggle> 

			  <hr *ngIf="editSelected() && this.aCS.companytype=='producer'">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>VAT No.</mat-label>
			    <input matInput placeholder="VAT No." [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.vat">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Company Reg No.</mat-label>
			    <input matInput placeholder="Company Reg No." [disabled]="this.aCS.archived" maxlength="45" [(ngModel)]="this.aCS.regnumber">
			  </mat-form-field>	

			  <mat-slide-toggle class="slider-margin slider-label" [disabled]="this.aCS.archived || !isAuthorised('finance')" [(ngModel)]="this.aCS.billingname" *ngIf="editSelected() && this.aCS.companytype=='producer'">
	        Bill parent company?
	      </mat-slide-toggle>  

				<mat-slide-toggle class="slider-margin slider-label" [disabled]="this.aCS.archived || !isAuthorised('finance')" [(ngModel)]="this.aCS.billingname" *ngIf="editSelected() && this.aCS.companytype=='buyer'">
	        Pay parent company?
	      </mat-slide-toggle>  

	      <hr *ngIf="editSelected() && this.aCS.companytype=='producer'">

			 	<mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'">
    			<mat-label>Season for Price Model</mat-label>
			    <input matInput type="number" placeholder="Season for Price Model" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.priceseason">
			  </mat-form-field>
	 
	 			<mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'">
    			<mat-label>Price Model</mat-label>
			    <mat-select placeholder="Price Model" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.pricemodel" (keydown.tab)="setPModel(this.aCS.pricemodel)">
			    	<mat-option value="0" (click)="setPModelDefault()">Default</mat-option>
			      <mat-option *ngFor="let price of this.dDDS.dropdown.priceModelList" value="{{ price.modelno }}" (click)="setPModel(price.modelno)">{{ price.model }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'">
    			<mat-label>Payment Terms</mat-label>
			    <mat-select placeholder="Payment Terms" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.payterms">
			      <mat-option value="default">Default</mat-option>
			      <mat-option value="custom">Custom</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <ng-container *ngIf="this.dDDS.payterms=='custom' && this.aCS.companytype=='producer'">
		      <div *ngFor="let item of this.dDDS.items; let itemindex=index">

		      	<hr>

						<div class="row justify-content-between">
							<div class="col-xs-6 ml-3">
								<p>Payment - {{ itemindex + 1 }}</p>
							</div>
							<div *ngIf="this.pS.portalMod()['batch']" class="mr-4 ml-3">
							  <mat-slide-toggle class="slider-margin slider-label pb-3" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.items[itemindex]['estimate']">
					        Based on estimate?
					      </mat-slide-toggle>  
							</div>
						</div>

						<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <mat-form-field>
    							<mat-label>Period Type</mat-label>
							    <mat-select placeholder="Period Type" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.items[itemindex]['type']">
							      <mat-option value="#">Specific Date</mat-option>
							      <mat-option value="m">Months</mat-option>
									  <mat-option value="w">Weeks</mat-option>
							      <mat-option value="d">Calendar Days</mat-option>
							      <mat-option value="b">Business Days</mat-option>
							    </mat-select>
							  </mat-form-field>

							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] == 'm'">	
    							<mat-label>Occurence in Month</mat-label>			    
							    <mat-select placeholder="Occurence in Month" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.items[itemindex]['order']">
							      <mat-option value="f">First</mat-option>
							      <mat-option value="s">Second</mat-option>
							      <mat-option value="t">Third</mat-option>
							      <mat-option value="l">Last</mat-option>
										<mat-option value="x">Specific Day</mat-option>
							    </mat-select>
							  </mat-form-field>
							  
							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] == '#'">
    							<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="specificdate" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.items[itemindex]['timingDate']">
									<mat-datepicker-toggle matIconSuffix [for]="specificdate"></mat-datepicker-toggle>
							    <mat-datepicker #specificdate></mat-datepicker>
							  </mat-form-field>

							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] != '#' && this.dDDS.items[itemindex]['type'] != 'm'">
    							<mat-label>Days after Delivery</mat-label>
							    <input matInput type="number" min="0" step="1" [disabled]="this.aCS.archived" placeholder="Days after Anchor Date" [(ngModel)]="this.dDDS.items[itemindex]['timing']">
							  </mat-form-field>

							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] == 'm'">
    							<mat-label>Months after Delivery</mat-label>
							    <input matInput type="number" min="0" step="1" [disabled]="this.aCS.archived" placeholder="Months after Anchor Date" [(ngModel)]="this.dDDS.items[itemindex]['timing']">
							  </mat-form-field>

							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] != '#' && this.dDDS.items[itemindex]['type'] != 'm' && this.dDDS.items[itemindex]['type'] != ''">
  								<mat-label>Date Anchor</mat-label>
							    <mat-select placeholder="Date Anchor" [(ngModel)]="this.dDDS.items[itemindex]['datetype']">
							      <mat-option value="delivery">From Delivery Date</mat-option>
							      <mat-option value="sample">From Sample Date</mat-option>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>

						<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <mat-form-field>
    							<mat-label>{{ (this.pS.portalMod()['batch'] ? (this.dDDS.items[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}</mat-label>
							    <input matInput type="number" min="0" max="100" step="10" placeholder="{{ (this.pS.portalMod()['batch'] ? (this.dDDS.items[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.items[itemindex]['percent']">
							  </mat-form-field>

							  <mat-form-field *ngIf="(this.dDDS.items[itemindex]['type'] == 'm' && this.dDDS.items[itemindex]['order'] !== 'x') || this.dDDS.items[itemindex]['type'] == 'b' || this.dDDS.items[itemindex]['type'] == 'd' || this.dDDS.items[itemindex]['type'] == 'w'">				    
    							<mat-label>Weekday</mat-label>
							    <mat-select placeholder="Weekday" [disabled]="this.aCS.archived" [(ngModel)]="this.dDDS.items[itemindex]['weekday']">
									  <mat-option *ngIf="this.dDDS.items[itemindex]['type'] != 'm'" value="0">Any</mat-option>
							      <mat-option value="1">Monday</mat-option>
							      <mat-option value="2">Tuesday</mat-option>
							      <mat-option value="3">Wednesday</mat-option>
							      <mat-option value="4">Thursday</mat-option>
							      <mat-option value="5">Friday</mat-option>
							      <mat-option value="6">Saturday</mat-option>
							      <mat-option value="7">Sunday</mat-option>
							    </mat-select>
							  </mat-form-field>

							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] == 'm' && this.dDDS.items[itemindex]['order'] == 'x'">
    							<mat-label>Day of Month</mat-label>
							    <input matInput type="number" min="1" max="28" step="1" placeholder="Day of Month" [(ngModel)]="this.dDDS.items[itemindex]['mdate']">
							  </mat-form-field>

							  <mat-form-field *ngIf="this.dDDS.items[itemindex]['type'] == 'm' || this.dDDS.items[itemindex]['type'] == ''">
  								<mat-label>Date Anchor</mat-label>
							    <mat-select placeholder="Date Anchor" [(ngModel)]="this.dDDS.items[itemindex]['datetype']">
							      <mat-option value="delivery">From Delivery Date</mat-option>
							      <mat-option value="sample">From Sample Date</mat-option>
							    </mat-select>
							  </mat-form-field>
							  
							  <div class="row">
								  <button mat-icon-button class="col" [disabled]="this.aCS.archived" (click)="this.dDDS.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
								  <button mat-icon-button class="col" [disabled]="this.aCS.archived" *ngIf="this.dDDS.items.length>1" (click)="this.dDDS.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
								</div>

							</div>
						</div>
					</div>
				</ng-container>


			  <ng-container *ngIf="editSelected() && this.aCS.companytype=='producer'">

				  <hr>
			    <p>Cultivars Grown</p>

		      <div *ngFor="let cultivar of this.aCS.cultivars; let cultindex=index">

						<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">
						
								<mat-form-field>
    							<mat-label>Cultivar</mat-label>
							    <mat-select placeholder="Cultivar" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.cultivars[cultindex]['idcultivar']">
							    	<ng-container *ngFor="let cult of this.dDDS.dropdown.cultivarList; let itemindex=index;">
							      	<mat-option *ngIf="this.aCS.checkType(cultindex,cult.idcultivar)" value="{{ cult.idcultivar }}">{{ cult.cultivar }}</mat-option>
							      </ng-container>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
						<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">
								
								<div class="row">	  
								  <button mat-icon-button class="col" [disabled]="this.aCS.archived" (click)="this.aCS.addCultivar()"><i class="material-icons addbutton">add_circle_outline</i></button>
								  <button mat-icon-button class="col" [disabled]="this.aCS.archived" *ngIf="this.aCS.cultivars.length>1" (click)="this.aCS.removeCultivar(cultindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
								</div>

							</div>
						</div>
		      
		      </div>
		    </ng-container>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Bank Name</mat-label>
			    <input matInput placeholder="Bank Name" [disabled]="this.aCS.archived || !isAuthorised('finance')" maxlength="45" [(ngModel)]="this.aCS.bankname">
			  </mat-form-field>	

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Account No.</mat-label>
			    <input matInput placeholder="Account No." [disabled]="this.aCS.archived || !isAuthorised('finance')" maxlength="45" [(ngModel)]="this.aCS.bankaccount">
			  </mat-form-field>	

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Sort Code</mat-label>
			    <input matInput placeholder="Sort Code" [disabled]="this.aCS.archived || !isAuthorised('finance')" maxlength="45" [(ngModel)]="this.aCS.banksortcode">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>IBAN</mat-label>
			    <input matInput placeholder="IBAN" [disabled]="this.aCS.archived || !isAuthorised('finance')" maxlength="45" [(ngModel)]="this.aCS.bankiban">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>SWIFT</mat-label>
			    <input matInput placeholder="SWIFT" [disabled]="this.aCS.archived || !isAuthorised('finance')" maxlength="45" [(ngModel)]="this.aCS.bankswift">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected() && this.aCS.companytype=='producer'">
    			<mat-label>Hectarage</mat-label>
			    <input matInput placeholder="Hectarage" [disabled]="this.aCS.archived" type="number" [(ngModel)]="this.aCS.hectarage">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [disabled]="this.aCS.archived" [(ngModel)]="this.aCS.notes"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="editSelected()">

			<button *ngIf="!this.aCS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && !this.aCS.archived" mdbBtn class="btn btn-primary" [disabled]="!canUpdate()" (click)="this.aCS.saveChanges()">Update</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && !this.aCS.archived" mdbBtn class="btn btn-info" (click)="onDelete('archive')">Archive</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && !this.aCS.archived" mdbBtn class="btn btn-danger" (click)="onDelete('delete')">Delete</button>

			<button *ngIf="this.aCS.editmode && this.editSelected() && this.aCS.archived" mdbBtn class="btn btn-info" (click)="onDelete('unarchive')">Unarchive</button>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>