import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BuyingLoginService } from './login.service';
import { BuyingResetService } from '../reset/reset.service';
import { PortalService } from '../../../auth/portal.service';


@Component({
  selector: 'app-buyinglogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class BuyingLoginComponent implements OnInit {
	loginForm: UntypedFormGroup;
  httpRes: any;

  constructor(
    private loginService: BuyingLoginService,
    private resetService: BuyingResetService,
    private route: ActivatedRoute,
    public portalService: PortalService,
    private router: Router) { }

  ngOnInit() {
    this.loginService.logOut(); 
    this.loginForm = new UntypedFormGroup({
      'userData': new UntypedFormGroup({
        'email': new UntypedFormControl(null, [Validators.required, Validators.email]),
        'password': new UntypedFormControl(null, Validators.required)
      })
    });
    window.scroll(0,0);
  }

  onSubmit(form: any) {
    const email = form.value.userData.email;
    const password = form.value.userData.password;
    this.loginForm.reset();
    this.loginService.loginRequest(email, password)
  }

  onLogOut() {
    this.loginService.logOut();
  }

  isAuth() {
    return this.loginService.isAuthenticated();
  }

  newPassword() {
    if (this.loginForm.value.userData.email != null) {
      this.resetService.emailToReset = this.loginForm.value.userData.email;
    }
    this.router.navigate(['/buying/reset']);
  }

}