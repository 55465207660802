import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { portalM } from '../../../auth/portal.service';
import { DropDownDataService } from '../dropdown.service';
import { AddSampleBatchedService } from './add-sample.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-add-sample-batched',
  templateUrl: './add-sample.component.html',
  styleUrls: ['./add-sample.component.scss']
})
export class AddSampleBatchedComponent implements OnInit {
  d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
  	public dDDS: DropDownDataService,
    public aSS: AddSampleBatchedService,
    public tableService: TableDataService,
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);    
    this.onLoad();
    this.aSS.sampledate = this.d.toISOString().substring(0,10);
    this.aSS.sampletime = this.d.getHours().toString().padStart(2,'0') + ':' + this.d.getMinutes().toString().padStart(2,'0') + ':' + this.d.getSeconds().toString().padStart(2,'0');
  }

  ngOnDestroy() {
    this.aSS.editmode = false;
    this.aSS.addDelIndex = '0';
    this.aSS.iddelivery = null;
    this.aSS.iddeliveryTemp = null;
    this.aSS.resetForm();
    this.tableService.loadData('samples');
    this.tableService.loadData('orders');
  }

  async onLoad() {
    this.aSS.stylemode = await this.aSS.isActive('Detailed Style Sampling');
    this.dDDS.getDropdown("sampleList");
    this.dDDS.dropdown['sampleListComplete'] = await this.loginService.loadData("sampleListComplete");
    this.dDDS.dropdown['sampleListAdd'] = await this.loginService.loadData("sampleListAdd");
    this.dDDS.dropdown['sampleListUnassigned'] = await this.loginService.loadData("sampleListUnassigned");
    this.dDDS.getDropdown("storageListWNIS");
    this.dDDS.getDropdown("producerList");
    this.dDDS.getDropdown("cultivarList");
    this.aSS.resetForm(false);
  }

  isAuthenticated(option = null) {
    return this.loginService.isAuthenticated(option);
  }

  batchOn() {
    return portalM()['batch'];
  }

  samplenissizes() {
    if (this.aSS.dnis18 + this.aSS.dnis20 + this.aSS.dnis22 + this.aSS.dnis24 + this.aSS.dnis25 > 0) {
      return true;
    }
    return portalM()['samplenissize'];
  }

  validsize($size) {
    return portalM()['sample' + $size];
  }

  canSave() {
    switch(this.aSS.sampletype) {
      case "initial":
        if (
          this.aSS.sampledate !== undefined &&
          this.aSS.sampletime !== undefined &&
          this.aSS.swnis > 0 &&
          (this.aSS.wetkernel == undefined || (this.aSS.wetkernel >= 0 && this.aSS.wetkernel < 60)) &&
          this.aSS.shell > 0 &&
          this.aSS.sk >= 0 &&
          (this.aSS.mould == undefined || this.aSS.mould >= 0) &&
          (this.aSS.immature == undefined || this.aSS.immature >= 0) &&
          (this.aSS.spot791 == undefined || this.aSS.spot791 >= 0) &&
          (this.aSS.earlystink == undefined || this.aSS.earlystink >= 0) &&
          (this.aSS.latestink == undefined || this.aSS.latestink >= 0) &&
          (this.aSS.germination == undefined || this.aSS.germination >= 0) &&
          (this.aSS.nutborer == undefined || this.aSS.nutborer >= 0) &&
          (this.aSS.discolouration == undefined || this.aSS.discolouration >= 0)
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case "tested":
        if (
          this.aSS.sampledate !== undefined &&
          this.aSS.sampletime !== undefined &&
          this.aSS.swnis > 0 &&
          this.aSS.sdnis > 0 &&
          this.aSS.drykernel > 0 &&
          (this.aSS.dnis18 == undefined || this.aSS.dnis18 >= 0) &&
          (this.aSS.dnis20 == undefined || this.aSS.dnis20 >= 0) &&
          (this.aSS.dnis22 == undefined || this.aSS.dnis22 >= 0) &&
          (this.aSS.dnis24 == undefined || this.aSS.dnis24 >= 0) &&
          (this.aSS.dnis25 == undefined || this.aSS.dnis25 >= 0) &&
          this.aSS.shell > 0 &&
          this.aSS.sk >= 0 &&
          (this.aSS.mould == undefined || this.aSS.mould >= 0) &&
          (this.aSS.immature == undefined || this.aSS.immature >= 0) &&
          (this.aSS.spot791 == undefined || this.aSS.spot791 >= 0) &&
          (this.aSS.earlystink == undefined || this.aSS.earlystink >= 0) &&
          (this.aSS.latestink == undefined || this.aSS.latestink >= 0) &&
          (this.aSS.germination == undefined || this.aSS.germination >= 0) &&
          (this.aSS.nutborer == undefined || this.aSS.nutborer >= 0) &&
          (this.aSS.discolouration == undefined || this.aSS.discolouration >= 0) &&
          (this.aSS.s0 == undefined || this.aSS.s0 >= 0) &&
          (this.aSS.s1 == undefined || this.aSS.s1 >= 0) &&
          (this.aSS.s4l == undefined || this.aSS.s4l >= 0) &&
          (this.aSS.s4r == undefined || this.aSS.s4r >= 0) &&
          (this.aSS.stylemode ?
            (this.aSS.s1s == undefined || this.aSS.s1s >= 0) &&
            (this.aSS.s2 == undefined || this.aSS.s2 >= 0) &&
            (this.aSS.s5 == undefined || this.aSS.s5 >= 0) &&
            (this.aSS.s6 == undefined || this.aSS.s6 >= 0) &&
            (this.aSS.s7 == undefined || this.aSS.s7 >= 0) &&
            (this.aSS.s8 == undefined || this.aSS.s8 >= 0)
            :
            (this.aSS.s567 == undefined || this.aSS.s567 >= 0)
          ) &&
          this.checktotalkernel() &&
          this.checkwetdry() &&
          this.checkwetkernel() &&
          this.checkstyles() &&
          this.checkdnis() && 
          this.checkMoisture()
        ) {
          return true;
        } else {
          return false;
        }
        break;
      case "production":
        if (
          (
            this.aSS.promoisture>=0 && this.aSS.promoisture!==null &&
            this.aSS.prodnis>=0 && this.aSS.prodnis!==null &&
            (this.aSS.pronocrack == undefined || this.aSS.pronocrack>=0) && 
            (this.aSS.prosmalls == undefined || this.aSS.prosmalls>=0) && 
            (this.aSS.prous == undefined || this.aSS.prous>=0)
          )
        ) { 
          for (var i = 0; i < this.aSS.items.length; i++) {
            if (
              this.aSS.items[i]['style'] !== "" &&
              (this.aSS.items[i]['quantity'] > 0 || this.aSS.items[i]['extra'] > 0) &&
              this.aSS.items[i]['bagunit'] > 0 && 
              this.aSS.items[i]['boxunit'] > 0 &&
              (
                (this.aSS.items[i]['style'].slice(0,1)=="N" && this.aSS.items[i]['skr'] !== undefined && this.aSS.items[i]['uskr'] !== undefined) || this.aSS.items[i]['style'].slice(0,1) != "N"
              )
            ) {
            } else {
              return false;
            }
          }
        } else {
          return false; 
        }
        return true;
        break;
      case "complete":
        if (
          this.aSS.sampledate !== undefined &&
          this.aSS.sampletime !== undefined &&
          this.aSS.swnis > 0 &&
          this.aSS.sdnis > 0 &&
          this.aSS.drykernel > 0 &&
          (this.aSS.dnis18 == undefined || this.aSS.dnis18 >= 0) &&
          (this.aSS.dnis20 == undefined || this.aSS.dnis20 >= 0) &&
          (this.aSS.dnis22 == undefined || this.aSS.dnis22 >= 0) &&
          (this.aSS.dnis24 == undefined || this.aSS.dnis24 >= 0) &&
          (this.aSS.dnis25 == undefined || this.aSS.dnis25 >= 0) &&
          this.aSS.shell > 0 &&
          this.aSS.sk >= 0 &&
          (this.aSS.mould == undefined || this.aSS.mould >= 0) &&
          (this.aSS.immature == undefined || this.aSS.immature >= 0) &&
          (this.aSS.spot791 == undefined || this.aSS.spot791 >= 0) &&
          (this.aSS.earlystink == undefined || this.aSS.earlystink >= 0) &&
          (this.aSS.latestink == undefined || this.aSS.latestink >= 0) &&
          (this.aSS.germination == undefined || this.aSS.germination >= 0) &&
          (this.aSS.nutborer == undefined || this.aSS.nutborer >= 0) &&
          (this.aSS.discolouration == undefined || this.aSS.discolouration >= 0) &&
          (this.aSS.s0 == undefined || this.aSS.s0 >= 0) &&
          (this.aSS.s1 == undefined || this.aSS.s1 >= 0) &&
          (this.aSS.s4l == undefined || this.aSS.s4l >= 0) &&
          (this.aSS.s4r == undefined || this.aSS.s4r >= 0) &&
          (this.aSS.stylemode ?
            (this.aSS.s1s == undefined || this.aSS.s1s >= 0) &&
            (this.aSS.s2 == undefined || this.aSS.s2 >= 0) &&
            (this.aSS.s5 == undefined || this.aSS.s5 >= 0) &&
            (this.aSS.s6 == undefined || this.aSS.s6 >= 0) &&
            (this.aSS.s7 == undefined || this.aSS.s7 >= 0) &&
            (this.aSS.s8 == undefined || this.aSS.s8 >= 0)
            :
            (this.aSS.s567 == undefined || this.aSS.s567 >= 0)
          ) &&
          this.checktotalkernel() &&
          this.checkwetdry() &&
          this.checkwetkernel() &&
          this.checkstyles() &&
          this.checkdnis() && 
          this.checkMoisture()
        ) {
          return true;
        } else {
          return false;
        }
        break;
    }
  }

  checktotalkernel() {
    if (this.aSS.sampletype=='initial') {
      return true;
    } else {
      if (+this.aSS.tk + +this.aSS.shell == +this.aSS.sdnis || (this.aSS.tk == undefined && this.aSS.sdnis == undefined)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkstyles() {
    this.aSS.totalstyles = (this.aSS.s0 !== undefined ? +this.aSS.s0 : 0) +
      (this.aSS.s1 !== undefined ? +this.aSS.s1 : 0) +
      (this.aSS.s4l !== undefined ? +this.aSS.s4l : 0) +
      (this.aSS.s4r !== undefined ? +this.aSS.s4r : 0) +
      (this.aSS.stylemode ? 
        (this.aSS.s1s !== undefined ? +this.aSS.s1s : 0) +
        (this.aSS.s2 !== undefined ? +this.aSS.s2 : 0) +
        (this.aSS.s5 !== undefined ? +this.aSS.s5 : 0) +
        (this.aSS.s6 !== undefined ? +this.aSS.s6 : 0) +
        (this.aSS.s7 !== undefined ? +this.aSS.s7 : 0) +
        (this.aSS.s8 !== undefined ? +this.aSS.s8 : 0)
        : 
        (this.aSS.s567 !== undefined ? +this.aSS.s567 : 0)
       );
    if (this.aSS.totalstyles == this.aSS.sk || this.aSS.totalstyles == 0) {
      return true;
    } else {
      return false;
    }
  }

  checkwetdry() {
    if ((this.aSS.swnis >= this.aSS.sdnis && this.aSS.swnis / this.aSS.sdnis < 2.5) || (this.aSS.swnis == undefined && this.aSS.sdnis == undefined)) {
      return true;
    } else {
      return false;
    }
  }

  checkwetkernel() {
    if (this.aSS.wetkernel == undefined || (this.aSS.wetkernel >= 0 && this.aSS.wetkernel < 60)) {
      return true;
    } else {
      return false;
    }    
  }

  checkMoisture() {
    if (this.aSS.sampletype=='production') {
      return  this.aSS.promoisture<5;
    } else {
      return  this.aSS.drykernel<5;
    }
  }

  checkdnis() {
    this.aSS.sizeddnis = (this.aSS.dnis18 !== undefined ? +this.aSS.dnis18 : 0) +
      (this.aSS.dnis20 !== undefined ? +this.aSS.dnis20 : 0) +
      (this.aSS.dnis22 !== undefined ? +this.aSS.dnis22 : 0) +
      (this.aSS.dnis24 !== undefined ? +this.aSS.dnis24 : 0) +
      (this.aSS.dnis25 !== undefined ? +this.aSS.dnis25 : 0);
    if (this.aSS.sizeddnis == 0 || this.aSS.sizeddnis == this.aSS.sdnis) {
      return true;
    } else {
      return false;
    }
  }

  unsoundError() {
    return (
      +this.aSS.uk != (
        +(this.aSS.mould !== undefined ? this.aSS.mould : 0) + 
        +(this.aSS.immature !== undefined ? this.aSS.immature : 0) +
        +(this.aSS.spot791 !== undefined ? this.aSS.spot791 : 0) +
        +(this.aSS.earlystink !== undefined ? this.aSS.earlystink : 0) +
        +(this.aSS.latestink !== undefined ? this.aSS.latestink : 0) +
        +(this.aSS.germination !== undefined ? this.aSS.germination : 0) +
        +(this.aSS.nutborer !== undefined ? this.aSS.nutborer : 0) +
        +(this.aSS.discolouration !== undefined ? this.aSS.discolouration : 0)
      ) ? true : false);
  }

  editModeOn(state) {
    this.aSS.resetForm();
    this.aSS.editmode = state;
    if (this.aSS.iddelivery!==null && state) {
      this.aSS.loadEntry(true);
    }
    if (!state) {
      this.aSS.stylemode = this.aSS.isActive('Detailed Style Sampling');
    }
  }

  editSelected() {
    return (this.aSS.editmode == false || (this.aSS.assignedSample && this.aSS.editmode == true && +this.aSS.iddelivery > 0) || (!this.aSS.assignedSample && +this.aSS.ids>0));
  }

  loadForEdit() {
    if (this.aSS.assignedSample) {
      var tempiddelivery = this.aSS.iddelivery;
      this.aSS.resetForm();
      this.aSS.iddelivery = tempiddelivery;
      this.aSS.loadForEdit();
    } else {
      this.aSS.loadForEdit();
    }
  }

  saveSample() {
    if (this.canSave()) {
      if (this.aSS.assignedSample) {
        this.aSS.iddeliveryTemp = this.dDDS.dropdown.sampleListAdd['samples'][this.aSS.addDelIndex]['iddelivery'];
      }
      this.aSS.addSample();
      window.scroll(0,0);
      this.aSS.resetForm();
      this.onLoad();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete sample for delivery number " + this.aSS.iddelivery + "?");
    if (res) {
      this.aSS.deleteEntry();
      this.aSS.resetForm();
    }
  }

}
