import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { PortalService } from '../../auth/portal.service'; 
import { LogisticsLoginService } from '../auth/login/login.service'; 
import { BaseQRService } from '../../services/qr.service'; 
import { QRCode, CodeStyles, BoxCount } from '../../interfaces/qr.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class LogisticsQRService{
	d: Date = new Date();
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public qrCodes: QRCode [] = [];
	public dqrCodes: QRCode [] = [];
	public dispatchCodes: QRCode [] = [];
	public codeStyles: CodeStyles [] = [];
	public save: boolean;
	public orderref: string = '';
	public previousref: string = '';
	public validSerials: number[] = []
	public dispatchedSerials: number[] = []
	public tally: BoxCount[] = [];
	public scanerror: string = '';
	public destination: string;
	public destinationName: string;
	public mode: string = 'inspect';
	public recall: boolean = false;
	public res: boolean;
	public addDispatch: boolean;
	public stophere: boolean = false;
	public canscan: boolean = true;
	public versions = ['0', '1', '2'];
	public inspectversions = ['0', '1', '2', '3'];


	constructor(
		private http: HttpClient,
		private lS: LogisticsLoginService,
		private qr: BaseQRService,
		private pS: PortalService,
		) {}

	async storeCode(array, code) {
		this[array.slice(0)].push(code);
		if (this[array.slice(0)][0]['type'] == "") {this.qrCodes.shift()};
	}

	checkOrder(codeData: QRCode) {
		if (this.orderref!=="") {
			var style = codeData.style;
			this.save = false;
			for (var i = 0; i < this.lS.dropdown['dispatchStyles'].length; i++) {
				if (this.lS.dropdown['dispatchStyles'][i]['orderref']==this.orderref) {
					for (var j = 0; j < this.lS.dropdown['dispatchStyles'][i]['styles'].length; j++) {
						if (this.lS.dropdown['dispatchStyles'][i]['styles'][j]['style']==style) {
							this.save = true;
						}
					}
				}
			}
		}
	}

	async addCode(codeData: QRCode) {
		var addOk = false;
	 	if (this.qrCodes.length==1) {
	 		addOk = true;
	 	} else if (this.qrCodes[0]['style'].substring(0,1).toUpperCase()!='N') {
	 		addOk = true;
	 	} else if (this.qrCodes.length<this.pS.portalMod()['palletsize']) {
	 		addOk = true
	 	}
	 	if (addOk) {
			this.save = true;
			this.recall = false;
			for (var i = 0; i < this.qrCodes.length; i++) {
				if (this.qrCodes[i].code == codeData.code) {
					this.save = false;
				}
			}
			if (this.save) {
				var code = await this.qr.readCode(codeData, this.lS.dropdown['productList'], this.lS.dropdown['storageListWNIS'], this.lS.season);
				this.save = (this.mode=='inspect' ? this.inspectversions.includes(code['version'].toString()) : this.versions.includes(code['version'].toString()) );
				if (this.save) {
					if (this.mode=='inspect') {
						var p = {
							"code": codeData.code,
							"location": this.lS.selectedmember
						};
						code['log'] = await this.lS.loadData('inspectQRcode', p);
					}
					this.checkOrder(codeData);
				} else {
					this.lS.toast("Code Error","This label is not recognised as a product label",'warn');
				}
				if (this.save) {
					var recalled = false;
					if (this.lS.dropdown['scanDestinations']['recall'].length>0) {
						var recall = this.qr.checkRecall(code, this.lS.dropdown['scanDestinations'], this.mode);
						recalled = recall['recall'];
						if (recalled) {
							if (this.mode!=='inspect') {
								this.lS.toast('RECALLED!','Concern ' + recall['flag'],'error',0);
							} else {
								code['recall'] = recall['flag'];
							}
						}
					} 
					if (!recalled) {
						await this.storeCode('qrCodes',code);
						for (var m = 0; m < this.codeStyles.length; m++) {
							if (this.codeStyles[m]['style']==code['style']) {
								this.codeStyles[m]['quantity'] = this.codeStyles[m]['quantity'] + 1;
							}
						}
					}
					if (this.qrCodes[0]['type'] == "") {this.qrCodes.shift()};
				}
			} else {
				this.save = false;
			}
		}
	}

	canSave() {
		return (this.qrCodes.length>0 ? 
			(this.qrCodes[0]['type'] !== "" ? 
				(this.mode=='add' ? this.onlyNISpallet() : true) 
				: false )
			: false );
	}

	onlyNISpallet() {
	  var canSave = true;
	  if (this.qrCodes[0]['style'].substring(0,1).toUpperCase()=='N') {
	    if (!this.lS.isAuthorised('nisadmin') && this.qrCodes.length != this.pS.portalMod()['palletsize']) {
	      canSave = false;
	    }
	  }
	  return canSave;
	}

	canDispatch() {
		return (this.dispatchCodes[0]['code'] !== "");
	}

	reset() {
		this.destination = null;
		this.qrCodes = [{
			type:"",
			code:"",
			raw:""
		}];
		this.dispatchCodes = [{code:""}];
		this.codeStyles = [];
		for (var i=0; i<this.lS.dropdown['productList'].length; i++) {
			this.codeStyles.push({style: this.lS.dropdown['productList'][i]['abbr'], fullstyle: this.lS.dropdown['productList'][i]['style'], quantity: 0});
		}
		this.validSerials = [];
		this.dispatchedSerials = [];
		this.tally = [];
		this.recall = false;
		this.res = null;
		this.addDispatch = null;
	}

	saveInventory(dispatch: boolean = false) {
		this.postBody = {
			"name":"saveInventory",
			"param":{
				"location": 1,
				"destination": (dispatch ? 0 : this.destination),
				"orderref": this.orderref,
				"dispatch": (dispatch ? 1 : 0),
				"type": this.mode,
				"codes": this.qrCodes
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
			  		this.lS.setSession(response);
			    	this.lS.toast("Save Successful",response['response']['result']['reply']['successtotal'] + " items added to the inventory successfully.");	
			    	if (response['response']['result']['reply']['errors']['batches'].length > 0) {
			    		var batcherrors = '';
			    		for (var i = 0; i < response['response']['result']['reply']['errors']['batches'].length; i++){
			      		batcherrors = batcherrors + (i > 0 ? ", " : "");
			      		batcherrors = batcherrors + response['response']['result']['reply']['errors']['batches'][i]['storage'];
			      	}	
			    		this.lS.toast("Save Unsuccessful",'Storage ' + batcherrors + " requires cracking / batch update. Inventory not saved.",'warn',0);
			    	}
			    	if (dispatch) { this.qrCodes = [{type:"",code:"",raw:""}]; } else { this.orderref = ""; this.reset(); };
			    } catch(e) {
			    	this.lS.toast("Save Unsuccessful","There were errors saving",'warn');
			    	if (dispatch) { this.qrCodes = [{type:"",code:"",raw:""}]; } else { this.reset(); };
			    }
        },
        (error) => console.log(error)
      );
	}

	async removeInventory() {
    const res = await this.lS.confirm("Are sure you want to remove these labels from Inventory?");
    if (res) {
      this.postBody = {
				"name":"removeInventory",
				"param":{
					"codes": this.qrCodes
				}
			};
			return this.http.post(
				this.lS.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				})
				.subscribe(
		      (response) => {
		      	this.lS.setSession(response);
		        this.httpRes = response;
		        this.lS.toast("Removal Successful",response['response']['result']['reply']['removals'] + ' item(s) were removed from the inventory.');
		        this.reset();
		      },
		      (error) => console.log(error)
		    );
		}
	}

	moveInventory(dispatch: boolean = false) {
		this.destinationName = 'Storage ID :' + this.destination;
		for (var i = 0; i < this.lS.dropdown.scanDestinations.length; i++) {
			if (this.destination==this.lS.dropdown.scanDestinations[i]['idstorage']) {
				var type = this.lS.dropdown.scanDestinations[i]['type'];
				this.destinationName = type.substring(0,1).toUpperCase() + type.substring(1) + ": " + this.lS.dropdown.scanDestinations[i]['name'];
			}
		}
		this.postBody = {
			"name":"moveInventory",
			"param":{
				"location": 1,
				"destination": this.destination,
				"orderref": this.orderref,
				"codes": this.qrCodes
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
		  		this.lS.setSession(response);
	        this.lS.toast("Inventory Move Successful",response['response']['result']['reply']['message'],'success',4000);
	        this.reset();
        },
        (error) => console.log(error)
      );
	}

	fullOrder(orderref: string) {
		this.postBody = {
			"name":"allOrders",
			"param":{
				"season": +this.lS.season,
				"orderref": orderref
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.lS.setSession(response);
          this.organiseOrder(response['response']['result']['reply']);
        },
        (error) => console.log(error)
      );
	}

	organiseOrder(selectedOrder) {
		this.reset();		
		var aCount: number [] = [];
		var hCount: number [] = [];	
		var iCount: number [] = [];
		for (var i = 0; i < selectedOrder['orders'].length; i++) {
			var variety = selectedOrder['orders'][i]['variety'].substring(0,1).toUpperCase();
			var style = (selectedOrder['orders'][i]['style']).toUpperCase();
			this.tally.push({
				style: style,
				variety: (variety == "H" ? "Hybrid" : (variety == "I" ? "Integ" : "Any")),
				count: +selectedOrder['orders'][i]['total'],
				requested: +selectedOrder['orders'][i]['quantity'],
			});
			aCount[style] = 0;
			hCount[style] = 0;
			iCount[style] = 0;
			aCount[style + 'Q'] = 0;
			hCount[style + 'Q'] = 0;
			iCount[style + 'Q'] = 0;
		}
		for (var i = 0; i < selectedOrder['serials']['valid'].length; i++) {
			this.validSerials[selectedOrder['serials']['valid'][i]['serialno']] = 1;
		}
		for (var i = 0; i < selectedOrder['serials']['dispatched'].length; i++) {
			this.dispatchedSerials[selectedOrder['serials']['dispatched'][i]['serialno']] = 1;
		}
		for (var i = 0; i < this.tally.length; i++) {
			var sty = this.tally[i]['style'];
			var vty = this.tally[i]['variety'].substring(0,1);
			if(vty == "A") { 
				aCount[sty] = aCount[sty] + +this.tally[i]['count']; 
				aCount[sty + 'Q'] = aCount[sty + 'Q'] + +this.tally[i]['requested']; //
			};
			if(vty == "H") { 
				hCount[sty] = hCount[sty] + +this.tally[i]['count']; 
				hCount[sty + 'Q'] = hCount[sty + 'Q'] + +this.tally[i]['requested']; //
			};
			if(vty == "I") { 
				iCount[sty] = iCount[sty] + +this.tally[i]['count']; 
				iCount[sty + 'Q'] = iCount[sty + 'Q'] + +this.tally[i]['requested']; //
			};
		}
		for (var i = 0; i < this.tally.length; i++) {
			sty = this.tally[i]['style'];
			vty = this.tally[i]['variety'].substring(0,1);
			if (vty == "H") {	this.tally[i]['count'] = Math.min(hCount[sty], this.tally[i]['requested']);	}
			if (vty == "I") {	this.tally[i]['count'] = Math.min(iCount[sty], this.tally[i]['requested']);	}
			if (vty == "A") { this.tally[i]['count'] = aCount[sty] - hCount[sty + 'Q'] - iCount[sty + 'Q'];
			}
		}
	}

	async getGroupedInventory(codeData: QRCode) {
		var p = {
      "serial": codeData.code
    }
    return await this.lS.loadData("getGroupedInventory", p);
	}

	async logCode(codeData: QRCode) {
		if (this.canscan) {
			this.canscan = false;
			// Check no double scanning
			this.save = true;
			this.recall = false;
			for (var i = 0; i < this.dispatchCodes.length; i++) {
				if (this.dispatchCodes[i].code == codeData.code) {
					this.save = false;
				}
			}
			if (this.save) {
				var code = await this.qr.readCode(codeData, this.lS.dropdown['productList'], this.lS.dropdown['storageListWNIS'], this.lS.season);
				this.save = this.versions.includes(code['version'].toString());
			}
			if (this.save) {
				if (this.mode=='inspect') {
					var p = {
						"code": codeData.code
					};
					code['log'] = await this.lS.loadData('inspectQRcode', p);
				}
				if (this.lS.dropdown['scanDestinations']['recall'].length>0) {
					var saved = !this.qr.checkRecall(code, this.lS.dropdown['scanDestinations'], this.mode);
					this.save = !saved['recall'];
					if (!this.save) {
						if (this.mode!=='inspect') {
							this.lS.toast('RECALLED!','Concern ' + saved['flag'],'error',0);
						} else {
							code['recall'] = saved['flag'];
						}
					}
				}
			} else {
				this.lS.toast("Code Error","This label is not recognised as a product label",'warn');
			}
			if (this.save) {
				this.storeCode('dqrCodes',code);
			}
			if (this.dqrCodes[0]['style'].substring(0,1).toUpperCase()=='N') {
				var groupedSerials = await this.getGroupedInventory(codeData);
		    this.stophere = false;
		    for (var i = 0; i < groupedSerials.length; i++) {
		    	if (!this.stophere) {
		    		var thiscode = await this.qr.readCode(groupedSerials[i], this.lS.dropdown['productList'], this.lS.dropdown['storageListWNIS'], this.lS.season);
						if (this.mode=='inspect') {
							var p = {
								"code": codeData.code
							};
							code['log'] = await this.lS.loadData('inspectQRcode', p);
						}
		    		await this.logCodeDispatch(thiscode);
		    	}
		    }
			} else {
				await this.logCodeDispatch(code);
			}
			this.canscan = true;
		}
	}

	async logCodeDispatch(codeData: QRCode) {
		if (this.save) {
			// Check number left of style / variety
			var style = codeData.style;
			var variety = codeData.variety;
			var quantity = +codeData.quantity;
			var logVariety = "N";
			var remaining = 0;
			// If space for Hybrid or Integ log there, else log to Any
			for (var i = 0; i < this.tally.length; i++) {
				if (this.tally[i]['style'] == style && this.tally[i]['variety'].substring(1,0) == variety) {
					remaining = +this.tally[i]['requested'] - +this.tally[i]['count'];
					logVariety = (remaining > 0 ? variety : logVariety);
				}
			}
			if (logVariety == "N") {
				for (var i = 0; i < this.tally.length; i++) {
					if (this.tally[i]['style'] == style && this.tally[i]['variety'].substring(1,0) == "A") {
						remaining = +this.tally[i]['requested'] - +this.tally[i]['count'];
						logVariety = (remaining > 0 ? "A" : logVariety);
					}
				}
			}
			// Check remaining items
			if (logVariety != "N") {
				var checkSerial = false;
				if (remaining > 0) {
					checkSerial = true;
				} else {
					variety = (variety == "H" ? "Hybrid" : "Integ");
					this.lS.toast("Wrong Item","No more " + variety + " " + style + " items required for this order.",'warn');
				}
				// Check serial is valid
				if (checkSerial) {
					var canLog = false;
					var dispatcherror = false;
					try {
						canLog = (this.validSerials[codeData.code] == 1);
					} catch {}
					try {
						dispatcherror = (this.dispatchedSerials[codeData.code] == 1);
					} catch {}
					// Add inventory is not already in stock
					if (!dispatcherror && !canLog) {
				    if (this.res == null) {
				    	if (style.slice(0,1)=="N") {
				    		const message = await this.lS.confirm("Label not found in inventory. As this is NIS, the whole pallet will need to be scanned into inventory before dispatch.");
				    		this.stophere = true;
				    		canLog=false;
				    	} else {
				    		this.res = await this.lS.confirm("Label not found in inventory. ADD & DISPATCH new items to this order?");
				    	}
				    }
				    if (this.res) {
							this.addCode(codeData);
						  this.scanerror = 'codeNew';
						  if (!this.addDispatch) {
						  	this.lS.toast("New items","Previously unscanned items will be saved and marked as disaptched to order '" + this.orderref + "'.",'success',3000);
						  }
						  this.addDispatch = true;
						  canLog = true;
						} else if (this.stophere) {
						  this.addDispatch = false;
						  this.scanerror = 'codeIgnore';
						} else {
						  this.addDispatch = false;
						  this.scanerror = 'codeIgnore';
						  this.lS.toast("New Items","No new items will be saved during this dispatch",'warn',0);
						}
					}
					// Assign to saveArray
					if (dispatcherror) {
						this.lS.toast("Dispatch Error","Item already assigned as dispatched to order '" + this.orderref + "'",'warn',0);
					} else if (canLog) {
						if (this.dispatchCodes[0]['code'] == "") {this.dispatchCodes.shift()};
						this.dispatchCodes.push(codeData);
						this.validSerials[codeData.code] = 0;
						// Update running tally
						var count = false;
						for (var i = 0; i < this.tally.length; i++) {
							if (this.tally[i]['style'] == style && this.tally[i]['variety'].substring(0,1) == variety) {
								if (this.tally[i]['count'] < this.tally[i]['requested']) {
									this.tally[i]['count'] = this.tally[i]['count'] + quantity;
									count = true;
								}
							}
						}
						if (!count) {
							for (var i = 0; i < this.tally.length; i++) {
								if (this.tally[i]['style'] == style && this.tally[i]['variety'].substring(0,1) == 'A') {
									if (this.tally[i]['count'] < this.tally[i]['requested']) {
										this.tally[i]['count'] = this.tally[i]['count'] + quantity;
										count = true;
									}
								}
							}
						}
					}
				}
			} else {
				variety = (variety == "H" ? "Hybrid" : "Integ");
				this.lS.toast("Save Unsuccessful","No more " + variety + " " + style + " items required for this order.",'warn');
			}
		}
	}

	saveToOrder() {
		this.postBody = {
			"name":"saveDispatch",
			"param":{
				"location": 1,
				"season": this.lS.season,
				"orderref": this.orderref,
				"codes": this.dispatchCodes,
				"newcodes": this.qrCodes
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.lS.setSession(response);
        		if (response['response']['result']['reply']['added']!=undefined) {
        			if (response['response']['result']['reply']['added']['successtotal']>0) {
	        			this.lS.toast("New items",response['response']['result']['reply']['added']['successtotal'] + " previously unscanned items were saved.",'success',3000);	
	        		}
	        	}
        		if (response['response']['result']['reply']['added']['errors'].length > 0) {
	        		this.lS.toast("Error: New items",response['response']['result']['reply']['added']['errors']['codes'].length + " new items were NOT successfully saved.",'warn',0);	
	        	}
		      	this.lS.toast("Save Successful",response['response']['result']['reply']['successtotal'] + " items have been added to order reference: " + this.orderref,'success',3000);
	        	if (+response['response']['result']['reply']['failuretotal'] > 0) {
	        		this.lS.toast("Save Unsuccessful",response['response']['result']['reply']['failuretotal'] + " items were NOT assigned to order '" + this.orderref + "'",'warn',0);	
	        	}
	        	if (response['response']['result']['reply']['orderstatus'] == 'dispatched') {
	        		this.lS.toast("Save Successful","Order '" + this.orderref + "': Dispatch complete");
	        	}
	        	this.reset();
						this.fullOrder(this.orderref);
	        } catch(e) {
	        	this.lS.toast("Save Unsuccessful","There were errors saving",'warn',0);
	 					this.reset();
	        }
        },
        (error) => console.log(error)
      );
	}


  undoLast() {
  }

}