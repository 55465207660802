import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../auth/login/teamlogin.service'
import { Producers } from '../../interfaces/dropdown.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class TraceabilityService{
	private postBody: {};
	httpRes: any;
	public orderref: string = '';
	public season: string;
	public dataStore: any;
	public farmers: any;
	public batch: string;
	public producer: number;
	public producers: Producers [];
	public producername: string;
	public selectedStyle: string;
	public selectedBatch: string;
	public selectedBatchName: string;
	public selectionbydate: string = 'all';
	public fromdate: string;
	public todate: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService
		) {}

	getProducers() {
		this.postBody = {
			"name": 'traceproducersfromdates',
			"param": {
				"location": 1,
				"type": this.selectionbydate,
				"fromdate": this.fromdate,
				"todate": this.todate
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
        	this.producers = response['response']['result']['reply'];
        },
        (error) => console.log(error)
      );
	}

	traceOrder() {
		this.postBody = {
			"name": (this.orderref=='inventory' ? "traceInventory" : "traceOrder"),
			"param":{
				"orderref": this.orderref
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.loginService.setSession(response);
          	this.dataStore = response['response']['result']['reply'];
	        } catch(e) {
	        	console.log("There were errors");
	        }
        },
        (error) => console.log(error)
      );
	}

	traceFarmer() {
		this.postBody = {
			"name": "traceFarmer",
			"param":{
				"location": 1,
				"producer": this.producer,
				"selectionbydate": this.selectionbydate,
				"fromdate": this.fromdate,
				"todate": this.todate
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.loginService.setSession(response);
        		if (response['response']['result']['reply']['message']==undefined) {
        			this.dataStore = response['response']['result']['reply'];
        		} else {
        			this.loginService.toast("Trace Complete",response['response']['result']['reply']['message']);
        		}          	
	        } catch(e) {
	        	console.log("There were errors");
	        }
        },
        (error) => console.log(error)
      );
	}

  styleSelected(style) {
  	this.selectedStyle = style.style;
  }

  deliverySelected(delivery) {
  	this.orderref = null;
  	this.season = delivery.season;
  	this.selectedBatch = delivery.idbatch;
  	this.selectedBatchName = delivery.batch;
  	this.batchSelected(this.selectedBatch, 'traceFarmBatches');
  }

  batchSelected(batch, api = 'traceInventoryFarmers') {
  	this.batch = batch;
  	if (batch!==null) {
	    this.postBody = {
				"name": api,
				"param":{
					"location": 1,
					"idbatch": this.batch
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response) => {
	        	try {
	        		this.loginService.setSession(response);
	        		if (api=='traceInventoryFarmers') {
	          		this.farmers = response['response']['result']['reply']['batches'][0]['farmers'];
	          	} else {
	          		this.dataStore[api] = response['response']['result']['reply'];
	          	}
		        } catch(e) {
		        	console.log("There were errors");
		        }
	        },
	        (error) => console.log(error)
	      );
	  }
  }

  reset(keep: boolean = false) {
    this.orderref = '';
    this.season = null;
    this.batch = null;
    this.farmers = null;
    this.selectedStyle = null;
    this.selectedBatch = null;
    this.selectedBatchName = null;
    this.dataStore = null;
    if (!keep) {
    	this.producer = null;
    	this.producername = null;
    	this.fromdate = null;
    	this.todate = null;
    	this.selectionbydate = 'all';
    }
  }

}