import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TeamLoginService } from '../../auth/login/teamlogin.service';

import { TeamRemittancesService } from '../../overview/remittances/teamremittances.service';
import { PortalService } from '../../../auth/portal.service';


@Component({
  selector: 'app-delsummary',
  templateUrl: './delsummary.component.html',
  styleUrls: ['./delsummary.component.scss']
})
export class DelSummaryWidget implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public remittancesService: TeamRemittancesService,
    public portalService: PortalService,
  	) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  async refresh(event) {
    await this.remittancesService.loadOverview();
    event.target.blur();
  }

}
