<ng-template [ngIf]="isAuthenticated()">

	<div class="menuholder p-menubar fullmenu">
		<div class="container">
			<div class="row">
				<p-menubar [model]="this.headerService.items" class="col">
					<ng-template pTemplate="start" class="p-mr-2">
				    <a routerLinkActive="active"
							[routerLinkActiveOptions]="{exact: true}"
							class="navbar-brand"
							routerLink="/logistics/home">
							<img src="../../assets/{{ this.portalService.icon }}" height="30" class="d-inline-block align-top">
				    	<span>{{  this.portalService.portalMod()['header'] }}</span>
				    </a>
				  </ng-template>
				</p-menubar>
				<p-menubar [model]="this.headerService.enditems" class="col endmenu" styleClass="d-flex justify-content-end">
					<!--
					<ng-container *ngIf="this.portalService.portalMod()['externallogo']!=null">
						<ng-template pTemplate="start" class="p-mr-2">
					    <a
								class="navbar-brand"
								[attr.href]="this.portalService.externallink"
								target="_blank">
								<img src="../../assets/{{ this.portalService.externallogo }}" height="30" class="d-inline-block align-top">
					    	<span>{{  this.portalService.externallinkname }}</span>
					    </a>
					   </ng-template>
					</ng-container>
					-->
				</p-menubar>
			</div>
		</div>
	</div>
	<div class="menuholder p-menubar mobilemenu">
		<div class="container mobilecontainer">
			<div class="row">
				<ng-container *ngIf="this.portalService.portalMod()['externallogo']==null">
					<p-menubar [model]="this.headerService.fullitems" class="col">
						<ng-template pTemplate="start" class="p-mr-2">
					    <a routerLinkActive="active"
								[routerLinkActiveOptions]="{exact: true}"
								class="navbar-brand"
								routerLink="/logistics/home">
								<img src="../../assets/{{ this.portalService.icon }}" height="30" class="d-inline-block align-top">
					    	{{  this.portalService.portalMod()['header'] }}
					    </a>
					  </ng-template>
					</p-menubar>
				</ng-container>
				<ng-container *ngIf="this.portalService.portalMod()['externallogo']!=null">
					<p-menubar [model]="this.headerService.fullitems" class="col">
						<ng-template pTemplate="start" class="p-mr-2">
					    <div class="row justify-content-between">
						    <a routerLinkActive="active"
									[routerLinkActiveOptions]="{exact: true}"
									class="navbar-brand col"
									routerLink="/logistics/home">
									<img src="../../assets/{{ this.portalService.icon }}" height="30" class="d-inline-block align-top">
						    	{{  this.portalService.portalMod()['header'] }}
						    </a>
						    <!--
						    <div class="navbar-brand col-auto px-0">
							    <a
										class="col align-self-end"
										[attr.href]="this.portalService.externallink"
										target="_blank">
										<img src="../../assets/{{ this.portalService.externallogo }}" height="30" class="d-inline-block align-top">
							    	<span>{{  this.portalService.externallinkname }}</span>
							    </a>
							  </div>
							-->
							</div>
					  </ng-template>
					</p-menubar>
				</ng-container>
			</div>
		</div>
	</div>

</ng-template>

<ng-template [ngIf]="!isAuthenticated()">
	<div class="menuholder p-menubar">
		<div class="container">
			<div class="row">
				<p-menubar class="col nomobile">
					<ng-template pTemplate="start" class="p-mr-2">
				    <a routerLinkActive="active"
							[routerLinkActiveOptions]="{exact: true}"
							class="navbar-brand">
							<img src="../../assets/{{ this.portalService.icon }}" height="30" class="d-inline-block align-top">
				    	<span>{{ this.portalService.portalMod()['header'] }}</span>
				    </a>
				  </ng-template>
				</p-menubar>
				<!--
				<ng-container *ngIf="this.portalService.portalMod()['externallogo']!=null">
					<p-menubar class="col endmenu nomobile" styleClass="d-flex justify-content-end">
						<ng-template pTemplate="start" class="p-mr-2">
					    <a
								class="navbar-brand"
								[attr.href]="this.portalService.externallink">
								<img src="../../assets/{{ this.portalService.externallogo }}" height="30" class="d-inline-block align-top">
					    	<span>{{  this.portalService.externallinkname }}</span>
					    </a>
					   </ng-template>
					</p-menubar>
				</ng-container>
				-->
			</div>
		</div>
	</div>

</ng-template>