import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { CountryService } from '../../../services/countries.service';
import { AddOrderService } from './add-order.service';
import { TableDataService } from '../../overview/table/table.service';
import { PortalService } from '../../../auth/portal.service';

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
  	public lS: TeamLoginService,
  	public dDDS: DropDownDataService,
    public aOS: AddOrderService,
    public pS: PortalService,
    public tableService: TableDataService,
    public cS: CountryService
  	) { }

  ngOnInit() {
    this.form = this.fb.group({
      orderref: ['', [Validators.required, this.forbiddenStringValidator.bind(this)]]
    });
    window.scrollTo(0,0);
    this.aOS.season = this.lS.season + '';
    this.dDDS.getDropdown("buyerList");
    this.dDDS.getDropdown("contactList");
    this.dDDS.getDropdown("productList");
    this.dDDS.getDropdown("cultivarList");
    this.dDDS.getDropdown("orderList");
    this.dDDS.getDropdown("freightList");
    this.dDDS.getDropdown("consigneeList");
    this.dDDS.getDropdown("logisticsList");
    this.dDDS.getDropdown("courierList");
    this.dDDS.getDropdown("bankAccountList");
  }

  ngOnDestroy() {
    this.aOS.editmode = false;
    this.aOS.resetForm(false);
    this.tableService.loadData('orders');
  }

  forbiddenStringValidator(control) {
    const forbiddenStrings = this.dDDS.dropdown.orderList;
    if (control.value.trim()==this.aOS.editorderref.trim()) {
      return null;
    }   
    const forbidden = forbiddenStrings.some(obj => obj.orderref.trim() === control.value.trim());    
    return forbidden ? { 'forbiddenString': true } : null;
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  canSave() {
    var itemscomplete = true;
    var feescomplete = true;
    for (var i=0; i< this.aOS.items.length; i++) {
      if (
        this.aOS.items[i]['index'] == undefined ||
        this.aOS.items[i]['style'] == "" ||
        this.aOS.items[i]['variety'] == "" ||
        this.aOS.items[i]['price'] == undefined ||
        +this.aOS.items[i]['price'] <= 0 ||
        this.aOS.items[i]['price'] == null ||
        this.aOS.items[i]['quantity'] == undefined ||
        this.aOS.items[i]['quantity'] == null ||
        this.aOS.items[i]['quantity'] <= 0 ||
        this.aOS.currency == undefined
       ) { itemscomplete = false; }
    }
    for (var i=0; i<this.aOS.fees.length; i++) {
      if (
        this.aOS.fees[i]['fee'] == undefined ||
        +this.aOS.fees[i]['amount'] < 0 
      ) { feescomplete = false; }
    }
    if (
        (this.aOS.orderref !== undefined ? this.aOS.orderref.replace(/ /g, "") !== "" : false) &&
        this.aOS.buyer !== undefined &&
        this.aOS.thisCompanyContact !== undefined &&
        this.aOS.orderdate !== undefined &&
        this.aOS.orderdate !== "" &&
        (this.form.status == "VALID") &&
        itemscomplete == true
      ) {
      return itemscomplete && feescomplete;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.aOS.resetForm(false);
    this.dDDS.getDropdown("orderList");
    this.aOS.editmode = state;
  }

  editSelected() {
    return (this.aOS.editmode == false || (this.aOS.editmode == true && this.aOS.editorderref !== undefined && this.aOS.editorderref !== ""));
  }

  loadForEdit() {
    this.aOS.resetForm(true);
    this.aOS.loadForEdit();
    this.form.get('orderref')?.setValue(this.aOS.orderref);
  }

  saveDelivery() {
    if (this.canSave()) {
      this.aOS.addOrder();
      window.scroll(0,0);
    }
  }

  saveChanges() {
    if (this.canSave()) {
      this.aOS.saveChanges();
      this.editModeOn(false);
    }
  }

  async onDelete() {
    const res = await this.lS.confirm("Are sure you want to delete order reference: '" + this.aOS.orderref + "'?");
    if (res) {
      this.aOS.deleteEntry();
      this.aOS.resetForm(false);
    }
  }

  changeAmountCur($event, field: string, i = null) {
    var event = ($event.target as HTMLInputElement).value;
    switch (field) {
       case "fees":
         this.aOS[field][i]['amount'] = event.replace(/[^\/\d.]/g,'');
         break;
       case "items":
         this.aOS[field][i]['price'] = event.replace(/[^\/\d.]/g,'');
         break;   
       default:
         this.aOS[field] = event.replace(/[^\/\d.]/g,'');
         break;
    }
  }

  changeAmountPct($event, field: string, i = null) {
    var event = ($event.target as HTMLInputElement).value;
    switch (field) {
       case "fees":
         this.aOS[field][i]['vatrate'] = +event.replace(/[^\/\d.]/g,'') / 100 + '';
         break;
       default:
         this.aOS[field] = +event.replace(/[^\/\d.]/g,'') / 100 + '';
         break;
    }
  }

}
