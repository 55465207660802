import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Inventory, RootObject } from './tracepackinglist.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class TracePackingService{
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public orderref: string = '';

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService
    ) {}

	getInventory(): Observable<RootObject[]> {
		this.postBody = {
			"name": (this.orderref=='inventory' ? "inventoryList" : "packingList"),
			"param":{
				"season": +this.loginService.season,
				"orderref": this.orderref
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}

	loadInventory() {
		this.postBody = {
			"name": (this.orderref=='inventory' ? "inventoryList" : "packingList"),
			"param":{
				"season": +this.loginService.season,
				"orderref": this.orderref
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.dataStore = rawData.response.result.reply;
	}


}