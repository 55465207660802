<div class="container">

  <div class="row justify-content-center">
		<img src="../../../assets/{{ this.portalService.logo }}" style="margin:30px auto; max-height:300px;">
	</div>

  <div class="row justify-content-center">
		<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2">
			<h2 style="font-family: 'Didot'; text-align: center;">The logistics portal is not currently available.</h2>
		</div>
	</div>
	<br>
</div>