import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { DepotDeliveryService } from '../depotdelivery.service';

@Component({
  selector: 'app-log-delivery',
  templateUrl: './log-delivery.component.html',
  styleUrls: ['./log-delivery.component.scss']
})
export class LogDepotDeliveryComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public addDeliveryService: DepotDeliveryService,
  	) { }

  ngOnInit() {
    window.scroll(0,0);
    this.dropDownDataService.getDropdown("newDepotDeliveryNumber",this.addDeliveryService.iddepot);
    this.dropDownDataService.getDropdown("depotList",this.addDeliveryService.iddepot);
    this.dropDownDataService.getDropdown("depotDeliveryList",this.addDeliveryService.iddepot);
    this.dropDownDataService.getDropdown("producerList");
    this.dropDownDataService.getDropdown("contactList");
    this.dropDownDataService.getDropdown("cultivarProfileList");
    this.addDeliveryService.depotdate = this.d.toISOString().substring(0,10);
    this.addDeliveryService.season = +this.d.getFullYear();
    this.dropDownDataService.tags = [{"serial": ""}];;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  ngOnDestroy() {
    this.addDeliveryService.editmode = false;
    this.addDeliveryService.iddeldepot = undefined;
    this.addDeliveryService.resetForm();
  }

  updateContacts(producer: string) {
    this.dropDownDataService.setCompanyContacts(producer);
    this.addDeliveryService.thisCompanyContacts = this.dropDownDataService.thisCompanyContacts;
    this.addDeliveryService.thisCompanyContact = this.dropDownDataService.thisCompanyContacts[0]['idcontact'];
    this.addDeliveryService.cultivars = this.dropDownDataService.dropdown.cultivarProfileList[producer];
  }
 
  canSave() {
    if (
        this.addDeliveryService.season !== undefined &&
        this.dropDownDataService.depot['idlocation'] !== undefined &&
        this.dropDownDataService.getNewDepotDeliveryNumber !== undefined &&
        this.addDeliveryService.depotdate !== undefined &&
        this.addDeliveryService.producer !== undefined &&
        this.addDeliveryService.thisCompanyContacts !== undefined &&
        this.addDeliveryService.depotwnis > 0 &&
        +this.addDeliveryService.cultivar > 0
      ) {
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    if (
        this.addDeliveryService.season !== undefined &&
        this.dropDownDataService.depot['idlocation'] !== undefined &&
        this.addDeliveryService.depotdate !== undefined &&
        this.addDeliveryService.producer !== undefined &&
        this.addDeliveryService.thisCompanyContacts !== undefined &&
        this.addDeliveryService.depotwnis > 0 &&
        +this.addDeliveryService.cultivar > 0
       ) {
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.addDeliveryService.iddeldepot = 0;
    this.addDeliveryService.resetForm();
    this.dropDownDataService.getDropdown("depotDeliveryList");
    this.addDeliveryService.editmode = state;
  }

  editSelected() {
    return (this.addDeliveryService.editmode == false || (this.addDeliveryService.editmode == true && this.addDeliveryService.iddeldepot > 0));
  }

  loadForEdit() {
    this.addDeliveryService.resetForm();
    this.addDeliveryService.loadForEdit();
  }

  saveDelivery() {
    if (this.canSave()) {
      this.addDeliveryService.depot = this.dropDownDataService.depot['idlocation'];
      this.addDeliveryService.delivery = this.dropDownDataService.getNewDepotDeliveryNumber;
      this.addDeliveryService.addDelivery();
      window.scroll(0,0);
      this.addDeliveryService.resetForm();
    }
  }

  saveChanges() {
    if (this.canUpdate()) {
      this.addDeliveryService.saveChanges();
      this.editModeOn(false);
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete delivery number " + this.addDeliveryService.iddeldepot + "?");
    if (res) {
      this.addDeliveryService.deleteEntry();
      this.addDeliveryService.resetForm();
    }
  }


}