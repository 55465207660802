import { Component, OnInit, OnDestroy } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';

import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-shippinginstruction',
  templateUrl: './shippinginstruction.component.html',
  styleUrls: ['./shippinginstruction.component.scss']
})
export class ShippingInstructionComponent implements OnInit {
  d: Date = new Date();

  constructor(
  	public lS: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tS: TableDataService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
    //this.tS.loadPPECB();
  }

  ngOnDestroy() {
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  async svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('shippinginstruction');

		// create a new jsPDF instance
		const pdfTeam = await new jsPDF('p', 'pt', 'a4');
    var savename = this.pS.portalMod()['companyname'] + ' - Shipping Instruction - ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref +'.pdf';
    this.setupPDF(pdfTeam, svgElement, savename);
	}

	async setupPDF(pdfTeam, svgElement, savename) {
		const pdf = await pdfTeam.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    });
    const pdfprint = await pdf.save(savename, {returnPromise: true});
	}

  transline(i, jump = 15) {
    return 'translate(0 ' + jump * i + ')';
  }


}
