<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6">
				<h2>Client Portal Access - User Activity</h2>
			</div>
		</div>
		<br>
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 col-9">
				<h3>
					<div class="dropdown" dropdown>
			      <a dropdownToggle mdbWavesEffect type="button" class="pl-0 nav-link dropdown-toggle waves-light" mdbWavesEffect>
			      {{ 
			      	(this.clientsService.dataRequest=='recentClientLoginsByDate' ? 'Recent Logins By Date - Last 7 Days' : '') +
			      	(this.clientsService.dataRequest=='recentClientLogins' ? 'Recent Logins By Producer - Last 7 Days' : '') +
			      	(this.clientsService.dataRequest=='recentFailedClientLogins' ? 'Failed Login Attempts - Last 7 Days' : '') +
			      	(this.clientsService.dataRequest=='recentClientResets' ? 'Password Resets - Last 7 Days' : '') +
			      	(this.clientsService.dataRequest=='recentClientResetAttempts' ? 'Password Reset Attempts - Last 7 Days' : '')
			      }}<span class="caret"></span></a>
			      <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
			        <button 
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.clientsService.setTable('recentClientLoginsByDate')">Recent Logins By Date - Last 7 Days</button>
			        <button 
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.clientsService.setTable('recentClientLogins')">Recent Logins By Producer - Last 7 Days</button>
			        <button 
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.clientsService.setTable('recentFailedClientLogins')">Failed Login Attempts - Last 7 Days</button>
			        <button 
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.clientsService.setTable('recentClientResets')">Password Resets - Last 7 Days</button>
			        <button 
			        	class="dropdown-item waves-light py-3" 
			        	mdbWavesEffect 
			        	(click)="this.clientsService.setTable('recentClientResetAttempts')">Password Reset Attempts - Last 7 Days</button>
			      </ul>
			    </div>
				</h3>
			</div>
			<div class="col-auto" *ngIf="isAuthenticated('superadmin')">
				<button mdbBtn class="btn btn-info" (click)="csv('activity')">CSV</button>
			</div>
		</div>
		
	 	<br>

		<div class="form-container" autocomplete="off">
		  <mat-form-field>
		    <input matInput placeholder="Search text" maxlength="45" autocomplete="random-client-search-text" id="client-search-text" [(ngModel)]="this.clientsService.searchtext" (keyup)="this.clientsService.filterTable()">
		  </mat-form-field>
		</div>

		<table class="table col" id="activity" mdbTableScroll [scrollY]="scroll" maxHeight="500" bordered="true">
			<tbody>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td></tr>
				<tr>
					<th>Company</th>
					<th>Contact</th>
					<th>Email</th>
					<th>Date</th>
				</tr>
				<tr *ngIf="this.clientsService.dataRequest=='recentClientLoginsByDate'" class="tableseperator"><td></td><td></td><td></td><td></td></tr>
				<ng-container *ngFor="let client of this.clientsService.filteredData; let clientIndex=index">
					<tr *ngIf="this.clientsService.dataRequest!='recentClientLoginsByDate'" class="tableseperator"><td></td><td></td><td></td><td></td></tr>
					<tr *ngIf="this.clientsService.dataRequest=='recentClientLoginsByDate' && this.compDates(clientIndex)" class="tableseperator"><td></td><td></td><td></td><td></td></tr>
					<tr>
						<td><a (click)="viewCompany(client.idcompany)">{{ client.companyname }}</a></td>
						<td><a (click)="viewContact(client.idcontact)">{{ client.name }}</a></td>
						<td><a href="mailto:{{ client.email }}">{{ client.email }}</a></td>		
						<td>{{ (this.clientsService.dataRequest=='recentClientLoginsByDate' ? client.time : '') | date:'EEE, MMM d, y @ HH:mm' }}</td>			
					</tr>
					<ng-container *ngIf="this.clientsService.dataRequest!='recentClientLoginsByDate'">
						<tr *ngFor="let date of client.dates">
							<td></td>
							<td></td>
							<td>{{ (this.clientsService.dataRequest=='recentClientLogins' ? (date.count>1 ? date.count + ' logins' : date.count + ' login') : '') + (this.clientsService.dataRequest=='recentFailedClientLogins' ? (date.count>1 ? date.count + ' attempts' : date.count + ' attempt') : '') }}</td>	
							<td>{{ date.time | date:'EEE, MMM d, y @ HH:mm' }}</td>				
						</tr>
					</ng-container>
				</ng-container>
				<tr class="tableseperator"><td></td><td></td><td></td><td></td></tr>
			</tbody>
		</table>

		<br><br>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>