import { Component, OnInit, OnDestroy } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';
import { FilesService } from '../../../services/files.service';

import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-ppecb',
  templateUrl: './ppecb.component.html',
  styleUrls: ['./ppecb.component.scss']
})
export class PPECBComponent implements OnInit {
  d: Date = new Date();
  sigimage: string = 'stamp';
  inspectionpoint: string = '7239';
  fbo: string = 'D15701';
  depot: string = 'White River';
  market: string = '';

  constructor(
    public fS: FilesService,
  	public lS: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tS: TableDataService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
    this.fS.getSig(this.lS.httpRoot, 'team_id_token', this.sigimage);
    this.tS.loadPPECB();
  }

  ngOnDestroy() {
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  async svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('ppecb');

		// create a new jsPDF instance
		const pdfTeam = await new jsPDF('p', 'pt', 'a4');
    var savename = this.pS.portalMod()['companyname'] + ' - PPECB Certificate - ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref +'.pdf';
    this.setupPDF(pdfTeam, svgElement, savename);
	}

	async setupPDF(pdfTeam, svgElement, savename) {
		const pdf = await pdfTeam.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    });
    const pdfprint = await pdf.save(savename, {returnPromise: true});
	}

  transpallet(i) {
    return 'translate(0 ' + 15 * i + ')';
  }

  transgrouped(option = 0) {
    var step = -1;
    switch (option) {
      case 0:
        step = this.tS.ppecb.length * 15;
        break;
      default:
        step = 0;
        break;
    }
    return 'translate(0 ' + step + ')';
  }


}
