<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3">
				<h2>Email Settings</h2>
			</div>
			
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">
	  		  
			  <mat-form-field>
    			<mat-label>Name</mat-label>
	    		<input matInput type="text" placeholder="Name" [(ngModel)]="this.settingsService.settings['name']">
			  </mat-form-field>
			  		  
			  <mat-form-field>
    			<mat-label>Email</mat-label>
	    		<input matInput type="text" placeholder="Email" [(ngModel)]="this.settingsService.settings['email']">
			  </mat-form-field>
			  		  
			  <mat-form-field>
    			<mat-label>Reply To Name</mat-label>
	    		<input matInput type="text" placeholder="Reply To Name" [(ngModel)]="this.settingsService.settings['replyname']">
			  </mat-form-field>
			  		  
			  <mat-form-field>
    			<mat-label>Reply Email</mat-label>
	    		<input matInput type="text" placeholder="Reply Email" [(ngModel)]="this.settingsService.settings['replyto']">
			  </mat-form-field>
			  		  
			  <mat-form-field>
    			<mat-label>{{ (this.settingsService.settings['pass']=='1' ? 'Password (Currently set)' : 'Password (Not currently set)' ) }}</mat-label>
	    		<input matInput type="password" placeholder="{{ (this.settingsService.settings['pass']=='1' ? 'Password (Currently set)' : 'Password (Not currently set)' ) }}" [(ngModel)]="this.settingsService.settings['password']">
			  </mat-form-field>
			  		  
			  <mat-form-field>
    			<mat-label>Outgoing Server</mat-label>
	    		<input matInput type="text" placeholder="Outgoing Server" [(ngModel)]="this.settingsService.settings['outgoing']">
			  </mat-form-field>
			  		  
			  <mat-form-field>
    			<mat-label>Outgoing Port</mat-label>
	    		<input matInput type="text" placeholder="Outgoing Port" [(ngModel)]="this.settingsService.settings['outport']">
			  </mat-form-field>
				  
			  <mat-form-field>
    			<mat-label>Security</mat-label>
			    <mat-select placeholder="Security" [(ngModel)]="this.settingsService.settings['security']">
			      <mat-option value="ssl">SSL</mat-option>
			      <mat-option value="tls">TLS</mat-option>
			      <mat-option value="false">None</mat-option>
			    </mat-select>
			  </mat-form-field>
				  
			  <mat-form-field>
    			<mat-label>SMTP Authentication</mat-label>
			    <mat-select placeholder="SMTP Authentication" [(ngModel)]="this.settingsService.settings['smtpauth']">
			      <mat-option value="true">True</mat-option>
			      <mat-option value="false">False</mat-option>
			    </mat-select>
			  </mat-form-field>

			</div>

			<hr>

			<button  mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button  mdbBtn class="btn btn-info" (click)="this.settingsService.loadEmail()">Reset</button>

			<button mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>