<div *ngIf="isAuthenticated()">
  <br>
  <div class="row mt-3">
    <div class="col">      
			<div *ngIf="multipleMembers()">
				<div class="row justify-content-between">
					<div class="col">
						<h2>Farm Overview</h2>
					</div>
					<div class="dropdown" dropdown>
	          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
	          {{ this.loginService.selectedmembername }}<span class="caret"></span></a>
	          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
	            <button 
	            	class="dropdown-item waves-light py-3" 
	            	mdbWavesEffect 
	            	(click)="onMemberSelected(mship['membership'], mship['companyname'])"
	            	*ngFor='let mship of this.loginService.memberships'>{{ mship['companyname'] }}</button>
	          </ul>
	        </div>
				</div>
			</div>
			<div *ngIf="!multipleMembers()">
				<div class="row justify-content-between">
					<div class="col-xs-6 ml-3">						
						<h2>Farm Overview</h2>
					</div>
					<div class="mr-4 ml-3">
						<h4>{{ this.loginService.selectedmembername }}</h4>
					</div>
				</div>
			</div>
      <br>

      <div class="col">
				<app-map></app-map>
      </div>
    </div>
  </div>
</div>
