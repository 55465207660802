import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { Storages, Storage } from './storage.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class StorageService{
	private postBody: {};
	httpRes: any;
	public storage: Storages = {"current": [], "archived": []};
	public editmode: boolean = false;
	public loadedEntry: any;
	public location: string = '1';
	request: string;
	public saving: boolean = false;
	public index: number;
	public idstorage: string;
	public name: string;
	public type: string;
	public contents: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService,
		public dropDownDataService: DropDownDataService) {}

	saveType() {
		this.storage['current'].splice(0, 0, {"name": '', "idstorage": 0, "type": this.type, "contents": this.contents});
		this.type = null;
		this.contents = null;
	}

	renameItem(index: number) {
		this.index = index;
		this.request = 'renameStorage';
		this.editStorage(index);
	}

	archiveItem(index: number) {
		this.index = index;
		this.request = 'archiveStorage';
		this.editStorage(index);
	}

	unarchiveItem(index: number) {
		this.index = index;
		this.request = 'unarchiveStorage';
		this.editStorage(index);
	}

	addItem(type: string) {
		var contents = '';
		var id = 0;
		for (var i = 0; i < this.storage['current'].length; i++) {
			if (this.storage['current'][i]['type']==type) {
				contents = this.storage['current'][i]['contents'];
				id = i;
				break;
			}
		}
		this.storage['current'].splice(id, 0, {"name": '', "idstorage": 0, "type": type, "contents": contents});
	}

	saveItem(index: number) {
		this.index = index;
		this.request = 'saveStorage';
		this.type = this.storage['current'][index]['type'];
		this.contents = this.storage['current'][index]['contents'];
		this.editStorage(index);
	}

	deleteItem(index: number) {
    this.storage['current'].splice(index,1);
	}

	deleteSavedItem(index: number) {
		this.index = index;
		this.request = 'deleteStorage';
		this.editStorage(index);
	}

	loadStorage() {
		this.postBody = {
			"name": "loadStorage",
			"param":{
				"location": this.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.storage = response['response']['result']['reply'];
        },
        (error) => console.log(error)
      );
	}

	editStorage(index: number) {
		this.saving = true;
		if (this.editmode) {
			this.idstorage = this.storage['archived'][index]['idstorage'];
			this.name = this.storage['archived'][index]['name'];
			this.type = this.storage['archived'][index]['type'];
		} else {
			this.idstorage = this.storage['current'][index]['idstorage'];
			this.name = this.storage['current'][index]['name'];
		}
		this.postBody = {
			"name": this.request,
			"param":{
				"location": this.location,
		    "idstorage": this.idstorage,
		    "name": this.name,
		    "type": this.type,
		    "contents": this.contents
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
        		if (this.request=='archiveStorage' || this.request=='deleteStorage') {
        			this.storage['current'].splice(index,1);
        		}
        		if (this.request=='unarchiveStorage') {
        			this.storage['archived'].splice(index,1);
        		}
			      this.index = null;
			    	this.saving = false;
			  		this.request = null;
			    	this.idstorage = null;
			    	this.type = null;
			    	this.contents = null;
	        	this.loadStorage();
	        } catch(e) {
			    	this.saving = false;
			      this.index = null;
			  		this.request = null;
			    	this.idstorage = null;
			    	this.type = null;
			    	this.contents = null;
	        }
        },
        (error) => console.log(error)
      );
	}


  resetForm(keepRef: boolean = false) {
    this.editmode = false;
  }



}