import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { PortalService } from '../../../auth/portal.service';
import { TeamAuthService } from '../auth.service';
import { TeamHeaderService } from '../../header/teamheader.service';

import { Memberships } from './teamlogin.interface';

declare var require: any;

@Injectable()
export class TeamLoginService{
	private postBody: {};
	private username: string;
	private passwordHash: string;
	public httpRoot = (this.portalService.mode=='dev' ?  'api/' + this.portalService.dev + 'jwt-team/' : this.portalService.root + 'api/jwt-team/index.php');
	httpRes: any;
	private d: Date;
	private timeNow: Date;
	public portal = 'team';
	public latestseason: number= 2020;
	public season: number = 2020;
	public location: number = 1;
	public seasons: number [] = [];
	expiration = -1;
	uname: string;
	selectedmember: string;
	selectedmembername: string;
	memberships: Memberships [];

	constructor(
		private http: HttpClient,
		private router: Router,
		private portalService: PortalService,
		private authService: TeamAuthService,
		private headerService: TeamHeaderService,
    private confirmationService: ConfirmationService) {}

	loginRequest(username: string, password: string) {
		if (username!==null) {
	    var sha512 = require('js-sha512');
	    this.d = new Date();
	    this.passwordHash = sha512(password); 
			this.postBody = {
				"name":"generateToken",
				"param":{
					"email": username.toLowerCase(),
					"pass": this.passwordHash
				}
			};
			return this.http.post(
				this.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': ' token'
					})
				})
				.subscribe(
	        (response) => {
	        	try {
	        		if (response['response']['status']==200) {
			        	if (this.setSession(response, false)) {
				        	this.setSeasons(response['response']['result']['season']);
				        	this.season = response['response']['result']['season'];
				        	this.latestseason = this.season;
				        	this.firstMembership();
				          this.router.navigate(['/team/home']);
				          this.httpRes = response;
				          this.headerService.refreshMenu();
			        	}
			        } else {
			        	this.toast('Login Error',response['response']['result'],'warn');
			        }
		        } catch (e) {
			        this.toast('Login Error',response['error']['message'],'warn');
		        }
	        },
	        (error) => this.toast('Login Error',"Unable to connect to server: " + this.httpRoot,'warn')
	      );
		}
	}

	setSession(authResult, loggedInReg = true) {
		if (!loggedInReg || (loggedInReg && !this.authService.loggedOut)) {
			if (authResult['error']==undefined) {
	    	if (!loggedInReg || (!this.authService.loggedOut && loggedInReg)) {
					var session = this.authService.setSession(authResult, this.uname, this.httpRoot);
					this.uname = session.uname;
					this.authService.loggedOut = (loggedInReg ? this.authService.loggedOut : false);
					return session.status;
	    	}
			} else {
				this.authService.logOut();
				return false;
			}
		} 
		return false;
	}

	logOut() {
		this.authService.logOut();
	}

	public isAuthenticated(option = null) {
		return this.authService.isAuthenticated(option);
	}

	public isAuthorised(permission: string) {
		return this.authService.isAuthorised(permission);
	}

	firstMembership() {
		var mship = this.authService.firstMembership();
		if (mship['status']) {
			this.memberships = mship['memberships'];
			this.selectedmember = mship['selectedmember'];
			return this.selectedmember;
		} else {
			this.toast('Login Error','User not active','warn');
		}
		this.logOut();
	}

  setSeasons(season) {
    for (var i = this.portalService.portalMod()['startyear']; i <= +season + 1; i++) {
      this.seasons[i-this.portalService.portalMod()['startyear']] = i;
    }
  }

  async dataRequest(api: string, param) {
		this.postBody = {
			"name": api,
			"param": param
		};
		//console.log(this.postBody);
		let response = await fetch(
			this.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				}
			}
		)
		return response.json();
	}

	async loadData(api: string, param = null, loggedInReg = true) {
  	if (param==null) {
  		param = {
  			"season": this.season,
  			"location": this.location
  		};
  	}
  	//console.log(param);
		const response = await this.dataRequest(api, param);
		//console.log(response);
   	this.setSession(response);
   	try {
			return response['response']['result']['reply'];
		} catch (e) {
			return response['error'];
		}
	}

	toast(summary, detail, severity = 'success', life = 2000, key = 'tc') {
		this.portalService.toast(summary, detail, severity, life, key);
	}

  async confirm(message, acceptLabel = 'Yes', rejectLabel = 'No'): Promise<boolean> {
		return await this.portalService.confirmBool(message, acceptLabel, rejectLabel); 		
	}

	portalUpdate() {
		this.postBody = {
			"name":"portalUpdate",
			"param":{
					"confirm": this.portalService.portalMod()['confirm']
			}
		};
		return this.http.post(
			this.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': ' token'
				})
			})
			.subscribe(
        (response) => {
        	try {
        		if (response['response']['status']==200) {
		        	this.portalService.updatePortal(response['response']['result']);
		        }
	        } catch (e) {	        }
        },
        (error) => console.log(error)
      );
	}

}