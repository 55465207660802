import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { CsvService } from '../../../../services/csv.service';

import { TeamLoginService } from '../../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../../logging/dropdown.service';
import { TableDataService } from '../../../overview/table/table.service';
//import { TeamCompanyService } from '../../../overview/companies/teamcompanies.service';
//import { TeamContactService } from '../../../overview/contacts/teamcontacts.service';
import { SettingsUsersService } from '../users.service';


@Component({
  selector: 'app-settings-users-activity',
  templateUrl: './users-activity.component.html',
  styleUrls: ['./users-activity.component.scss']
})
export class SettingsUsersActivityComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  d: Date;
  scroll: boolean = true;
  isLoadingResults = true;

  constructor(
    public loginService: TeamLoginService,
  	public usersService: SettingsUsersService,
    public dropDownDataService: DropDownDataService,
    public tableService: TableDataService,
    //public companyService: TeamCompanyService,
    //public contactService: TeamContactService,
    private router: Router,
    private http: HttpClient,
    public csvService: CsvService
  	) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.usersService.idcontact = 0;
    this.usersService.resetForm();
    this.dropDownDataService.getDropdown("internalContactList");
    this.usersService.userActivity('getUsersActivity');
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
  
  cacheForMember() {
    this.usersService.loadUsers();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.usersService.clickedIndex !== null;
  }

  async viewContact(idcontact) {
    this.tableService.reset('contacts');
    this.tableService.setDelivery(idcontact, 'contacts');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/contacts']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  async viewCompany(idcompany) {
    this.tableService.reset('companies');
    this.tableService.setDelivery(idcompany, 'companies');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100);
  }

  csv(datatable) {
    var thistable=document.getElementById(datatable);
    var title = 'User Activity';
    this.csvService.csvSimple(thistable, title);
  }

}