<div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
    <div class="row justify-content-between">
      <div class="col-xs-6 main-header">
        <h2>Fumigation Certificate</h2>
      </div>
      <div class="ml-3 col-auto">
        <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
      </div>
    </div>
    <br>
  
    <div class="col px-0" style="display: inline-block;">      
      <div class="form-container">
  
        <mat-label>Certificate Number</mat-label>
        <mat-form-field class="col px-0 pe-4">
        <input matInput placeholder="Certificate Number" #cert [ngModel]="certno" (blur)="certChange(cert.value)">
        </mat-form-field>
  
      </div>  
    </div>

    <div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
      <svg id="fumigation"
       viewBox="0 0 550 800"
       xmlns="http://www.w3.org/2000/svg" 
       xmlns:xlink="http://www.w3.org/1999/xlink"  
       version="1.2" 
       baseProfile="tiny">
        <style type="text/css">
          .st0{font-family:'Calibri-Bold';}
          .st1{font-size:10.0768px;}
          .st2{font-family:'Calibri';}
          .st3{fill:#757070;}
          .st4{font-family:'Arial-BoldMT';}
          .st5{font-size:6.7176px;}
          .st6{font-size:13.4358px;}
        </style>
        <title>Fumigation Certificate</title>
        
        <image href="assets/fumlogo.jpg" x="165px" y="35" height="60px" width="250px"/>
        <image href="assets/fumdetail.jpg" x="90px" y="100" height="80px" width="380px"/>

        <text class="st0 st6" transform="translate(197 200)">FUMIGATION CERTIFICATE</text>
        <rect x="197" y="202" width="175" height="0.8"/>

        <text class="st0 st1" transform="translate(83 233)">Commodity:</text>
        <text class="st2 st1" transform="translate(310 233)">Macadamia Nut In Shell</text>
        <text class="st0 st1" transform="translate(83 266)">Exporter:</text>
        <text class="st2 st1" transform="translate(310 266)">{{ this.pS.portalMod()['companyname'] }}</text>
        <text class="st0 st1" transform="translate(83 299)">Date:</text>
        <text class="st2 st1" transform="translate(310 299)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.exploadingdate | date:'d/M/y' }}</text>
        <text class="st0 st1" transform="translate(83 332)">Invoice No:</text>
        <text class="st2 st1" transform="translate(310 332)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}</text>
        <text class="st0 st1" transform="translate(83 365)">Container No:</text>
        <text class="st2 st1" transform="translate(310 365)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.containerno }}</text>
        <text class="st0 st1" transform="translate(83 398)">Vessel:</text>
        <text class="st2 st1" transform="translate(310 398)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vesselname }} {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vesselno }}</text>
        <text class="st0 st1" transform="translate(83 431)">Truck Reg No:</text>
        <text class="st2 st1" transform="translate(310 431)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.truckreg }}</text>
        <text class="st0 st1" transform="translate(83 464)">Seal No:</text>
        <text class="st2 st1" transform="translate(310 464)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.sealno }}</text>
        <text class="st0 st1" transform="translate(83 497)">Phostoxin Batch No:</text>
        <text class="st2 st1" transform="translate(310 497)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.phostoxin }}</text>
        <text class="st0 st1" transform="translate(83 530)">Purpose of Fumigation:</text>
        <text class="st2 st1" transform="translate(310 530)">Prevention / Export</text>
        <text class="st0 st1" transform="translate(83 563)">Dosage:</text>
        <text class="st2 st1" transform="translate(310 563)">60 TABLETS</text>
        <text class="st0 st1" transform="translate(83 596)">Type of Fumigation:</text>
        <text class="st2 st1" transform="translate(310 596)">Phostoxin</text>
        <text class="st0 st1" transform="translate(83 629)">Duration and Temperature:</text>
        <text class="st2 st1" transform="translate(310 629)">5 Days @ 25◦C</text>

        <g>
            <rect x="293" y="220" width="0.5" height="20.5"/>
            <rect x="465.5" y="220" width="0.5" height="20.5"/>
            <rect x="293.5" y="220" width="172" height="0.5"/>
            <rect x="293.5" y="240" width="172" height="0.5"/>
            <rect x="293" y="253" width="0.5" height="20.5"/>
            <rect x="465.5" y="253" width="0.5" height="20.5"/>
            <rect x="293.5" y="253" width="172" height="0.5"/>
            <rect x="293.5" y="273" width="172" height="0.5"/>
            <rect x="293" y="286" width="0.5" height="20.5"/>
            <rect x="465.5" y="286" width="0.5" height="20.5"/>
            <rect x="293.5" y="286" width="172" height="0.5"/>
            <rect x="293.5" y="306" width="172" height="0.5"/>
            <rect x="293" y="319" width="0.5" height="20.5"/>
            <rect x="465.5" y="319" width="0.5" height="20.5"/>
            <rect x="293.5" y="319" width="172" height="0.5"/>
            <rect x="293.5" y="339" width="172" height="0.5"/>
            <rect x="293" y="352" width="0.5" height="20.5"/>
            <rect x="465.5" y="352" width="0.5" height="20.5"/>
            <rect x="293.5" y="352" width="172" height="0.5"/>
            <rect x="293.5" y="372" width="172" height="0.5"/>
            <rect x="293" y="385" width="0.5" height="20.5"/>
            <rect x="465.5" y="385" width="0.5" height="20.5"/>
            <rect x="293.5" y="385" width="172" height="0.5"/>
            <rect x="293.5" y="405" width="172" height="0.5"/>
            <rect x="293" y="418" width="0.5" height="20.5"/>
            <rect x="465.5" y="418" width="0.5" height="20.5"/>
            <rect x="293.5" y="418" width="172" height="0.5"/>
            <rect x="293.5" y="438" width="172" height="0.5"/>
            <rect x="293" y="451" width="0.5" height="20.5"/>
            <rect x="465.5" y="451" width="0.5" height="20.5"/>
            <rect x="293.5" y="451" width="172" height="0.5"/>
            <rect x="293.5" y="471" width="172" height="0.5"/>
            <rect x="293" y="484" width="0.5" height="20.5"/>
            <rect x="465.5" y="484" width="0.5" height="20.5"/>
            <rect x="293.5" y="484" width="172" height="0.5"/>
            <rect x="293.5" y="504" width="172" height="0.5"/>
            <rect x="293" y="517" width="0.5" height="20.5"/>
            <rect x="465.5" y="517" width="0.5" height="20.5"/>
            <rect x="293.5" y="517" width="172" height="0.5"/>
            <rect x="293.5" y="537" width="172" height="0.5"/>
            <rect x="293" y="550" width="0.5" height="20.5"/>
            <rect x="465.5" y="550" width="0.5" height="20.5"/>
            <rect x="293.5" y="550" width="172" height="0.5"/>
            <rect x="293.5" y="570" width="172" height="0.5"/>
            <rect x="293" y="583" width="0.5" height="20.5"/>
            <rect x="526" y="583" width="0.5" height="20.5"/>
            <rect x="293.5" y="583" width="233" height="0.5"/>
            <rect x="293.5" y="603" width="233" height="0.5"/>
            <rect x="293" y="616" width="0.5" height="20.5"/>
            <rect x="526" y="616" width="0.5" height="20.5"/>
            <rect x="293.5" y="616" width="233" height="0.5"/>
            <rect x="293.5" y="636" width="233" height="0.5"/>
        </g>

        <g transform="translate(0 40)">
            <text class="st0 st1" transform="translate(83 640)">Completed By:</text>
            <rect x="83" y="642" width="61" height="0.6"/>

            <ng-container *ngIf="this.fS.imageData[this.sigimage]">
              <image x="180px" y="610px" height="60px" width="255px" [attr.href]="this.fS.imageData[this.sigimage]" />
            </ng-container>
        </g>
        <text class="st3 st4 st5" transform="translate(250 777)">Certificate No# {{ certno }}</text>

      </svg>
    </div> 

  </div>
</div>