<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2>Recover Cracked Pallet</h2>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field>
			    <mat-select placeholder="Pallet Number" [(ngModel)]="this.rPService.pallet">
			      <mat-option *ngFor="let pallet of this.dropDownDataService.dropdown.recoverPalletList; let contactIndex=index;" #prod [value]="pallet.palletraw">{{ this.pS.portalMod()['palletlines'][pallet.line] }} {{ pallet.pallet }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			</div>

			<hr>

			<button mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Recover</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>