<div class="container">
	<div class="row justify-content-between">
		<div class="col-xs-6 main-header">
			<h3>Contact: {{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.firstname }} {{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.lastname }}</h3>
		</div>
		<div class="mr-4 ml-3 col-auto">
			<button mdbBtn class="btn btn-secondary" (click)="editContact()">Edit Contact</button>
		</div>
	</div>
	<br>
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Company</td>
				<td><a (click)="viewCompany(this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.idcompany)">{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.companyname }}</a></td>
			</tr>
			<tr>
				<td>Contact</td>
				<td>{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.firstname }} {{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.lastname }}</td>
			</tr>
			<tr>
				<td>Telephone</td>
				<td><a href="tel:{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.telephone }}">{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.telephone }}</a></td>
			</tr>
			<tr>
				<td>Email</td>
				<td><a href="mailto:{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.email }}">{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.email }}</a></td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Birthday</td>
				<td>{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.birthday | date:'MMM d, EEE' }}</td>
			</tr>
			<tr>
				<td>Shirt Size</td>
				<td>{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.shirtsize }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Notes</td>
				<td>{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.notes }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngIf="this.tableService['contacts'][this.tableService.clickedIndex['contacts']].memberships?.length>0">
				<tr *ngFor="let membership of this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.memberships; let i=index">
					<td *ngIf="i==0">Active Memberships</td>
					<td *ngIf="i>0"></td>
					<td><a (click)="viewCompany(membership.idcompany)">{{ membership.companyname }}</a></td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<tr *ngIf="this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.lastlogin!=null">
				<td>Last Portal log in</td>
				<td>{{ this.tableService['contacts'][this.tableService.clickedIndex['contacts']]?.lastlogin | date:'EEEE, MMMM d, y, H:mm:ss z' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
	<br><br>
</div>