import { Injectable } from '@angular/core';

import { QRCode } from '../interfaces/qr.interface';
import { PortalService } from '../auth/portal.service'; 
import { portalM } from '../auth/portal.service';

@Injectable()
export class BaseQRService{

	constructor(
		private pS: PortalService,
		) {}

	checkRecall(code, scanDestinations, mode) {
		var recallStart = false;
		var recallEnd = false;
		var ldate = new Date(code['packdate']);
		for (var m = 0; m < scanDestinations['recall'].length; m++) {
			var flag = scanDestinations['recall'][m]['flag'];
			if ( 
					(scanDestinations['recall'][m]['storage']==null ||
					scanDestinations['recall'][m]['storage']==code['storage']) &&
					(scanDestinations['recall'][m]['iteration']==null ||
					+scanDestinations['recall'][m]['iteration']==+code['iteration']) &&
					(scanDestinations['recall'][m]['style']==null ||
					scanDestinations['recall'][m]['style']==code['style'])
				) {
				if (scanDestinations['recall'][m]['batchstart']==null) {
					recallStart = true;
				} else {
					var sdate = new Date(scanDestinations['recall'][m]['batchstart']);
					if (+ldate - +sdate >= 0) {
						recallStart = true;
					} else {
						recallStart = false;
					}
				}
				if (scanDestinations['recall'][m]['batchend']==null) {
					recallEnd = true;
				} else {
					var edate = new Date(scanDestinations['recall'][m]['batchend']);
					if (+edate - +ldate >= 0) {
						recallEnd = true;
					} else {
						recallEnd = false;
					}
				}
			}
		}
		if (recallStart && recallEnd) {
			return {'flag': flag, 'recall': true};
		} else {
			return {'flag': flag, 'recall': false};
		}
	}

	codeClean(codeData: QRCode) {
		var code = codeData['code'];
		var j = 0;
		var k = 0;
		var l = 0;
		var pre = 'S';
		// Offset for 3 digit storage vs 2
		if (+code.search('-') !== -1) {
			j = +code.search('-') + 1;
			l = 1;
		}
		// Offset for styles missing 1st letter coding (e.g. 1 vs S1)
		if (isNaN(+code.slice(8+j,9+j))) {
			pre = code.slice(8+j,9+j);
			k = 1;
		}
		return {j: j, k: k, l: l, pre: pre};
	}

	async readCode(codeData, productList, storageListWNIS, season) {
		var code = codeData;
		var cx = this.codeClean(codeData);
		var j = cx['j'];
		var k = cx['k'];
		var l = cx['l'];
		var pre = cx['pre'];
		switch (codeData['code'].length) {
			case 25:
				code['version'] = parseInt(code['code'].substring(4, 6),36);
				code['label'] = (code['version']==3 ? 'Pallet Sample' : 'Product');
				var date = parseInt(code['code'].substring(6, 9),36);
				code['packdate'] = this.pS.addDaysToDate(date);
				code['season'] = season;
				code['idproduct'] = parseInt(code['code'].substring(12, 15),36);
				for (var j = 0; j < productList.length; j++) {
					if (+productList[j]['code']==code['idproduct']) {
						code['style'] = productList[j]['abbr'];
						code['stylefull'] = productList[j]['style'];
						var variety = productList[j]['variety'].substring(0,1).toUpperCase(); 
						code['variety'] = (variety=='A' ? 'I' : variety);
						code['quantity'] = (code['style'].slice(0,1)=='N' || code['style'].slice(0,1)=="B" ? productList[j]['weight'] : 1 );
						code['type'] = productList[j]['type'];
					}
				}
				code['idstorage'] = parseInt(code['code'].substring(15, 17),36);
				for (var j = 0; j < storageListWNIS.length; j++) {
					if (+storageListWNIS[j]['idstorage']==code['idstorage']) {
						code['storage'] = storageListWNIS[j]['name'].toUpperCase();
					}
				}
				code['iteration'] = parseInt(code['code'].substring(17, 19),36);
				var hextime = parseInt(code['code'].substring(9, 12),36).toString().padStart(5, '0');
					code['printtime'] = hextime.substring(0, 2) + ':' + hextime.substring(2, 4) + ':' + hextime.substring(4, 5) + '0';
				var palletfull = parseInt(code['code'].substring(19, 23),36);
				code['palletfull'] = (code['type']=='nis' ? (palletfull==0 ? null : palletfull) : null);
				var pallet = Math.floor(parseInt(code['code'].substring(19, 23),36)/10);
				code['pallet'] = (code['type']=='nis' ? (pallet==0 ? null : pallet) : null);
				var nisline = portalM()['palletlines'][+parseInt(code['code'].substring(19, 23),36).toString().substring(0, -1)];
				code['nisline'] = (code['type']=='nis' ? nisline : null);
				code['crackday'] = (code['type']=='kernel' ? (pallet==0 ? null : pallet) : null);
				var d = new Date(code['packdate'].substring(0,4),code['packdate'].substring(5,7)-1,code['packdate'].substring(8,10));
				var yeardate = (Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()) - Date.UTC(d.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
				code['batch'] = code['storage'] + ' / ' + code['iteration'] + ' / ' + yeardate;
				break;
			case 21:
			case 23:
			case 24:
			case 28:
				code['version'] = 1;
				code['season'] = code['code'].slice(0+j,4+j);
				code['packdate'] = code['code'].slice(0+j,4+j) + '-' + code['code'].slice(4+j,6+j) + '-' + code['code'].slice(6+j,8+j);
				code['style'] = (pre + (codeData.code.slice(8+j,9+j) == "S" || codeData.code.slice(8+j,9+j) == "C" ? codeData.code.slice(8+j+k,10+j+k).replace("0","").toUpperCase() : codeData.code.slice(8+j+k,10+j+k).toUpperCase() ));
				code['fullstyle'] = code['style'];
				code['variety'] = code['code'].slice(10+j+k,11+j+k);
				code['storage'] = code['code'].slice(11+j+k,13+j+k+l);
				code['iteration'] = code['code'].slice(13+j+k+l,15+j+k+l);
				code['printtime'] = code['code'].slice(15+j+k+l,17+j+k+l) + ':' + code['code'].slice(17+j+k+l,19+j+k+l) + ':' + code['code'].slice(19+j+k+l,21+j+k+l);
				code['quantity'] = (code['style'].slice(0,1)=="N" || code['style'].slice(0,1)=="B" ? +code['code'].slice(23+j+k+l,27+j+k+l) : 1 );
				var d = new Date(code['packdate'].substring(0,4),code['packdate'].substring(5,7),code['packdate'].substring(8,10));
				var yeardate = (Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()) - Date.UTC(d.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
				code['batch'] = code['storage'] + ' / ' + code['iteration'] + ' / ' + yeardate;
				code['palletfull'] = 'N/A';
				code['pallet'] = 'N/A';
				code['nisline'] = 'N/A';
				code['idstorage'] = 'N/A';
				code['idproduct'] = 'N/A';
				break;
		}
		return code;
	}

}