import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { AddCurrencyService } from './add-currency.service';

@Component({
  selector: 'app-currency',
  templateUrl: './add-currency.component.html',
  styleUrls: ['./add-currency.component.scss']
})
export class AddCurrencyComponent implements OnInit {

  constructor(
  	public loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public addCurrencyService: AddCurrencyService,
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.addCurrencyService.editmode = false;
    this.dropDownDataService.getDropdown("currencyList");
    this.addCurrencyService.date = this.addCurrencyService.d.toISOString().substring(0,10);
  }

  ngOnDestroy() {
    this.addCurrencyService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        this.addCurrencyService.date !== undefined
      ) {
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.addCurrencyService.resetForm();
    this.addCurrencyService.editmode = state;
    if (state) {
      this.addCurrencyService.getData();
    }
  }

  editSelected() {
    return (this.addCurrencyService.editmode == false || (this.addCurrencyService.editmode == true));
  }

  onCalculate() {
    if (this.canSave()) {
      this.addCurrencyService.addCurrency();
      window.scroll(0,0);
      this.addCurrencyService.resetForm();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete currency entry for " + this.addCurrencyService.currency + " on " + this.addCurrencyService.date + "?");
    if (res) {
      this.addCurrencyService.deleteEntry();
      this.addCurrencyService.resetForm();
    }
  }

}