import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { AddPaymentService } from './add-payment.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit {
  d: Date = new Date();
  public index: string;

  constructor(
  	private loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public addPaymentService: AddPaymentService,
    public tableService: TableDataService,
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.addPaymentService.paiddate = this.d.toISOString().substring(0,10);
    this.addPaymentService.fxdate = this.d.toISOString().substring(0,10);
    this.dropDownDataService.getDropdown("paymentList");
    this.dropDownDataService.getDropdown("uaPaymentList");
    this.dropDownDataService.getDropdown("uaNoPaymentList");
    this.dropDownDataService.getDropdown("producerList");
  }

  ngOnDestroy() {
    this.addPaymentService.resetForm();
    this.addPaymentService.editmode = false;
    this.tableService.loadData('payments');
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        (+this.addPaymentService.amountdue > 0 || this.addPaymentService.instalment=='a') &&
        this.addPaymentService.paydate!==null
      ) {
      return true;
    } else {
      return false;
    }
  }

  canDelete() {
    return (this.addPaymentService.instalment=='a' || this.addPaymentService.instalment=='b' ? true : false);
  }

  editModeOn(state) {
    this.addPaymentService.resetForm();
    this.addPaymentService.editmode = state;
  }

  editSelected() {
    return (!(this.addPaymentService.instalment==="") && (this.addPaymentService.idproducertemp!=='0' || this.addPaymentService.iddelivery!=='tbc'));
  }

  showAdvanced() {
    return ( 
      this.addPaymentService.iddelivery=='tbc' ||
      ( 
        !this.addPaymentService.editmode && (
          ( !(!this.addPaymentService.iddelivery) ? 
            this.addPaymentService.iddelivery!=='tbc' &&
            +this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery].advanced==0
            : false )
        )
      ) || (
        this.addPaymentService.editmode && (
          ( !(!this.addPaymentService.iddelivery) ?
             this.addPaymentService.iddelivery!=='tbc' &&
            +this.dropDownDataService.dropdown.paymentList[this.addPaymentService.iddelivery].advanced==1
            : false )
        )
      )
    );
  }
  
  loadForEdit() {
    if (!(this.addPaymentService.iddelivery=='tbc') || +this.addPaymentService.idproducertemp!==0) {
      var tempiddelivery = this.addPaymentService.iddelivery;
      var tempinstalment = this.addPaymentService.instalment;
      this.addPaymentService.resetForm(this.addPaymentService.iddelivery !== 'tbc');
      this.addPaymentService.iddelivery = tempiddelivery;
      this.addPaymentService.instalment = tempinstalment;
      this.addPaymentService.loadForEdit();
    }
  }

  savePayment() {
    if (this.canSave()) {
      this.addPaymentService.iddeliverytemp = this.addPaymentService.iddelivery;
      this.addPaymentService.addPayment();
      window.scroll(0,0);
      this.addPaymentService.resetForm();
      this.dropDownDataService.getDropdown("paymentList");
      this.dropDownDataService.getDropdown("uaPaymentList");
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete this payment for delivery number " + this.addPaymentService.iddelivery + "?");
    if (res) {
      this.addPaymentService.deleteEntry();
      this.addPaymentService.resetForm();
      this.dropDownDataService.getDropdown("paymentList");
      this.dropDownDataService.getDropdown("uaPaymentList");
    }
  }

  changeAmountDue($event) {
    this.addPaymentService.amountdue = $event.replace(/[^\/\d.]/g,'');
    if (this.addPaymentService.invcurrency == 'USD') {
      this.addPaymentService.amountdueUSD = +this.addPaymentService.amountdue;
      this.addPaymentService.amountdueZAR = +this.addPaymentService.amountdue * this.addPaymentService.fxrate;
    } else {
      this.addPaymentService.amountdueUSD = +this.addPaymentService.amountdue / this.addPaymentService.fxrate;
      this.addPaymentService.amountdueZAR = +this.addPaymentService.amountdue;
    }
  }

  adjustFX(fx) {
    this.addPaymentService.paycurrency = fx;
    this.changeAmountPaid(this.addPaymentService.amountpaid);
  }

  changeAmountPaid($event) {
    this.addPaymentService.amountpaid = $event.replace(/[^\/\d.]/g,'');
    if (this.addPaymentService.paycurrency == 'USD') {
      this.addPaymentService.amountpaidUSD = +this.addPaymentService.amountpaid;
      this.addPaymentService.amountpaidZAR = +this.addPaymentService.amountpaid * this.addPaymentService.fxrate;
    } else {
      this.addPaymentService.amountpaidUSD = +this.addPaymentService.amountpaid / this.addPaymentService.fxrate;
      this.addPaymentService.amountpaidZAR = +this.addPaymentService.amountpaid;
    }
  }

}
