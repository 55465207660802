import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instalment'
})
export class InstalmentPipe implements PipeTransform {

  transform(value: any): any {
    if (value === null) {
      return null;
    }
    if (isNaN(+value)) {
      switch (value) {
        case "a":
          value = 'Advanced';
          break;
        case 'b':
          value = 'Bonus';
          break;
        case 'm':
          value = 'MRL';
          break;
        default:
          return this.titleCase(value);
          break;
      }
      return value;
    }	
    return value;
  }

  titleCase(text: string) {
    var sentence = text.toLowerCase().split(" ");
    for(var i = 0; i< sentence.length; i++){
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
   const value = sentence.join(" ");
   return value;
   }

}
