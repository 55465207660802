import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';


import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddBookingService{
	private postBody: {};
	httpRes: any;
	public d: Date = new Date(Date.now());
	public createform: boolean;
	public editmode: boolean = false;
	public recurring: boolean = false;
	public frequency: string = 'none';
	public repeat: string = '1';
	public loadedEntry: any;
	public date: string = this.d.toISOString().substring(0,10);
	public enddate: string = this.date;
	public bookingedit: string;
	public idb: number;
	public location: number = 1;
	public interval: number = 15;
	public type: string = 'drop-off';
	public status: string = 'confirmed';
	public producer: number;
	public cultivar: number = 47;
	public load: number = 0;
	public loadtype: string = 'bigbag';
	public vehicle: string;
	public vehicleIndex: number;
	public driver: string;
	public driverIndex: number = 0;
	public time: string;
	public firsttime: string = '07:00:00';
	public lasttime: string = '16:00:00';
  public arrivaltimes: any;
	public loadtime: string;
	public loadduration: number;
	public unloadtime: string;
	public unloadduration: number;
	public duration: number;
	public journeytime: number = 30;
	public completetime: string;
	public mintime: number;
	public note: string;
  public times = [10,20,30,40,50,60,70,80,90,100,110,120,130,140,150];
	public vehicleList = {"vehicles": [], "iteration": 15, "mintime": 120};
	public weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
	public weekdays = [];
	public check = {"clashes": [], "dates": [this.date]};
	public monday: boolean = false;
	public tuesday: boolean = false;
	public wednesday: boolean = false;
	public thursday: boolean = false;
	public friday: boolean = false;
	public saturday: boolean = false;
	public sunday: boolean = false;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dropDownDataService: DropDownDataService) {}

	async addBooking() {
		const ans = await (this.recurring ? this.recurringMsg() : true);
		this.setWeekdays();
		if (ans) {
			this.postBody = {
				"name": "addBooking",
				"param":{
					"location": this.loginService.location,
					"season": this.loginService.season,
					"type": this.type,
					"status": this.status,
					"idproducer": this.dropDownDataService.dropdown['producerList'][+this.producer]['idcompany'],
					"producer": this.dropDownDataService.dropdown['producerList'][+this.producer]['companyname'],
					"date": this.date,
					"time": this.time,
					"enddate": this.enddate,
					"recurring": (this.recurring ? 1 : 0 ),
					"frequency": this.frequency,
					"repeat": this.repeat,
					"weekdays": this.weekdays,
					"journeytime": (this.type=='collection' ? this.journeytime : 0 ),
					"loadtime": (this.loadtime==null ? '' : this.loadtime),
					"loadduration": (this.loadduration==null ? 0 : this.loadduration),
					"unloadtime": this.unloadtime,
					"unloadduration": this.unloadduration,
					"duration": this.duration,
					"completetime": this.completetime,
					"loadamount": this.load,
					"loadtype": this.loadtype,
					"idcultivar": this.cultivar,
					"idvehicle": (this.type=='collection' ? this.vehicleList['vehicles'][this.vehicleIndex]['idvehicle'] : 0 ),
					"iddriver": (this.type=='collection' ? this.vehicleList['vehicles'][this.vehicleIndex]['drivers'][this.driverIndex]['iddriver'] : 0 ),
					"notes": (this.note == null ? "" : this.note)
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	try {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
		        	this.resetForm();
		        } catch(e) {
		        	this.loginService.toast("Save Unsuccessful","Booking was not saved!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
	      
		}
	}

	async saveChanges() {
		const ans = await (this.recurring ? this.recurringMsg() : true);
		if (ans) {
			this.postBody = {
				"name": "updateBooking",
				"param":{
					"idb": this.idb,
					"location": this.loginService.location,
					"season": this.loginService.season,
					"type": this.type,
					"status": this.status,
					"idproducer": this.dropDownDataService.dropdown['producerList'][+this.producer]['idcompany'],
					"producer": this.dropDownDataService.dropdown['producerList'][+this.producer]['companyname'],
					"date": this.date,
					"time": this.time,
					"enddate": this.enddate,
					"recurring": (this.recurring ? 1 : 0 ),
					"frequency": this.frequency,
					"repeat": this.repeat,
					"weekdays": this.weekdays,
					"journeytime": (this.type=='collection' ? this.journeytime : 0 ),
					"loadtime": (this.loadtime==null ? '' : this.loadtime),
					"loadduration": (this.loadduration==null ? 0 : this.loadduration),
					"unloadtime": this.unloadtime,
					"unloadduration": this.unloadduration,
					"duration": this.duration,
					"completetime": this.completetime,
					"loadamount": this.load,
					"loadtype": this.loadtype,
					"idcultivar": this.cultivar,
					"idvehicle": (this.type=='collection' ? this.vehicleList['vehicles'][this.vehicleIndex]['idvehicle'] : 0 ),
					"iddriver": (this.type=='collection' ? this.vehicleList['vehicles'][this.vehicleIndex]['drivers'][this.driverIndex]['iddriver'] : 0 ),
					"notes": (this.note == null ? "" : this.note)
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	try {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
		        	this.editmode=false;
		        	this.resetForm();
		        } catch(e) {
		        	this.loginService.toast("Update Unsuccessful","Booking entry was not updated!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
	  }
	}

	deleteEntry() { 
		this.postBody = {
			"name": "deleteBooking",
			"param":{
				"idb": this.idb,
				"location": this.loginService.location,
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Booking entry was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	loadForEdit() {
		this.postBody = {
			"name": "loadBooking",
			"param":{
				"idb": +this.idb,
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.vehicleList = rawData.response.result.reply['vehicleList'];
		this.loadedEntry = rawData.response.result.reply['booking'][0];
		this.type = this.loadedEntry.type;
		this.status = this.loadedEntry.status;
		this.date = this.loadedEntry.date;
		this.time = (this.loadedEntry.time==null ? this.loadedEntry.unloadtime.substring(0,5) : this.loadedEntry.time.substring(0,5));
		var addtime = true;
		for (var i = 0; i < this.times.length; i++) {
			if (this.times[i] == this.loadedEntry.journeytime) {
				addtime = false;
			}
		}
		if (addtime) {
			this.times.push(this.loadedEntry.journeytime);
		}
		this.journeytime = this.loadedEntry.journeytime;
		this.loadtime = this.loadedEntry.loadtime;
		this.unloadtime = this.loadedEntry.unloadtime;
		this.completetime = this.loadedEntry.completetime;
		this.duration = this.loadedEntry.duration;
		this.loadduration = this.loadedEntry.loadduration;
		this.unloadduration = this.loadedEntry.unloadduration;
		this.location = this.loadedEntry.idlocation;
		this.load = this.loadedEntry.loadamount;
		this.loadtype = this.loadedEntry.loadtype;
		this.producer = this.setValue('producer',this.loadedEntry.idproducer);
		this.vehicleIndex = (this.type=='collection' ? this.setValue('vehicle',this.loadedEntry.idvehicle) : 0);
		this.driverIndex = (this.type=='collection' ? +this.setValue('driver',this.loadedEntry.iddriver) : 0);
		this.updateTime();
	}

	setValue(type: string, value): number {
		switch (type) {
			case "producer":
				for (var i = 0; i < this.dropDownDataService.dropdown['producerList'].length; i++) {
					if (+this.dropDownDataService.dropdown['producerList'][i]['idcompany']==+value) {
						return i;
					}
				}
				break;
			case "vehicle":
				for (var i = 0; i < this.vehicleList['vehicles'].length; i++) {
					if (+this.vehicleList['vehicles'][i]['idvehicle']==+value) {
						return i;
					}
				}
				break;
			case "driver":
				for (var i = 0; i < this.vehicleList['vehicles'].length; i++) {
					if (+this.vehicleList['vehicles'][this.vehicleIndex]['drivers'][i]['iddriver']==+value) {
						return i;
					}
				}
				break;
		}
	}

  resetForm(keepRef: boolean = false) {
    window.scroll(0,0);
    this.date = this.d.toISOString().substring(0,10);
    this.enddate = this.date;
    this.status = 'confirmed';
    this.time = null;
    this.load = (keepRef ? this.load : 0);
    this.loadtype = (keepRef ? this.loadtype : 'bigbag');
    this.cultivar = (keepRef ? this.cultivar : 47);
    this.producer = (keepRef ? this.producer : null);
    this.duration = null;
    this.loadduration = null;
    this.unloadduration = null;
    this.journeytime = 30;
    this.loadtime = null;
    this.unloadtime = null;
    this.completetime = null;
    this.driverIndex = 0;
    this.vehicleIndex = null;
    this.note = null;
    this.monday = false;
		this.tuesday = false;
		this.wednesday = false;
		this.thursday = false;
		this.friday = false;
		this.saturday = false;
		this.sunday = false;
		this.recurring = false;
		this.frequency = 'none';
		this.repeat = '1';
  	this.weekdays = [];
	  this.check = {"clashes": [], "dates": [this.date]};
    this.dropDownDataService.getDropdown("bookingList");
  }

  async loadVeh() {
		this.time = (this.type=='collection' ? null : this.time);
    var p = {
      "season": this.loginService.season,
      "location": this.loginService.location,
      "date": this.date,
      "journeytime": (this.journeytime>0 ? this.journeytime : null),
      "loadtype": this.loadtype,
      "loadamount": this.load
    }
    this.vehicleList = await this.loginService.loadData("vehicleList", p);
    this.interval = this.vehicleList['interval'];
    this.firsttime = this.vehicleList['firsttime'];
    this.lasttime = this.vehicleList['lasttime'];
    this.arrivaltimes = this.generate_series(this.interval);
  }

	vehicleselect() {
  	this.time = null;
		if (this.driver==null || this.driver==undefined) {
			this.driver = this.vehicleList['vehicles'][0]['drivers'][0]['iddriver'];
		}
		this.updateLoad();
	}

	updateLoad() {		
		if (this.type=='collection') {
			this.loadduration = Math.ceil((this.vehicleList['vehicles'][this.vehicleIndex]==undefined ? 60 : Math.max(1,Math.floor(this.load/1000)) * +this.vehicleList['vehicles'][this.vehicleIndex][this.loadtype + 'load'] + +this.vehicleList['vehicles'][this.vehicleIndex]['bufferduration'] ) / this.interval) * this.interval;
			this.unloadduration = Math.ceil((this.vehicleList['vehicles'][this.vehicleIndex]==undefined ? 60 : Math.max(1,Math.floor(this.load/1000)) * +this.vehicleList['vehicles'][this.vehicleIndex][this.loadtype + 'unload'] + +this.vehicleList['vehicles'][this.vehicleIndex]['bufferduration'] ) / this.interval) * this.interval;
		} else {
			this.loadduration = 0;
			var unitload = (this.loadtype=='bigbag' ? 5 : (this.loadtype=='smallbag' ? 10 : 1 ));
			this.unloadduration = Math.ceil((10 + unitload * Math.max(1,Math.floor(this.load/1000)))/this.interval) * this.interval;
		}
		this.updateTime();
	}

	updateTime() {
		var dt = new Date(this.date + ' ' + this.time);
		var st = new Date(this.date + ' ' + this.time);
		var jtime = (this.type=='collection' ? this.journeytime : 0 );
		if (this.type=='collection') {
			dt.setMinutes(dt.getMinutes() + +jtime);
			this.loadtime = (dt.getHours()<10 ? '0' : '') + dt.getHours() + ':' + (dt.getMinutes()<10 ? '0' : '') + dt.getMinutes() + ':00';
		}
		dt.setMinutes(dt.getMinutes() + +this.loadduration + +jtime);
		this.unloadtime = (dt.getHours()<10 ? '0' : '') + dt.getHours() + ':' + (dt.getMinutes()<10 ? '0' : '') + dt.getMinutes() + ':00';
		dt.setMinutes(dt.getMinutes() + +this.unloadduration);
		this.completetime = (dt.getHours()<10 ? '0' : '') + dt.getHours() + ':' + (dt.getMinutes()<10 ? '0' : '') + dt.getMinutes() + ':00';
		this.duration = (dt.getHours() - st.getHours()) * 60 + dt.getMinutes() - st.getMinutes();
		this.checkClashes();
	}

  confirmTime(index) {
  	var time = this.vehicleList['vehicles'][this.vehicleIndex]['drivers'][this.driverIndex]['times'][index];
    if (time.avail==0) {
      return false;
    }
    if (this.vehicleList['vehicles'][this.vehicleIndex]['times'][time.time]==0) {
    	return false;
    }
    return true;
  }

  generate_series(step) {
    const dt = new Date('01/01/1970' + ' ' + this.firsttime);
    const lt = new Date('01/01/1970' + ' ' + this.lasttime);
    const rc = [];
    while (dt<=lt) {
      rc.push((dt.getHours()<10 ? '0' : '') + dt.getHours() + ':' + (dt.getMinutes()<10 ? '0' : '') + dt.getMinutes());
      dt.setMinutes(dt.getMinutes() + step);
    }
    return rc
  }

  async recurringMsg() {
  	const ans = await this.loginService.confirm('Recurring bookings are not confirmed until save has completed, as some may conflict with existing bookings.','Continue','Cancel');
  	return ans;
  }

  setWeekdays() {
  	this.weekdays = [];
  	if (this.monday) { this.weekdays.push(1) };
  	if (this.tuesday) { this.weekdays.push(2) };
  	if (this.wednesday) { this.weekdays.push(3) };
  	if (this.thursday) { this.weekdays.push(4) };
  	if (this.friday) { this.weekdays.push(5) };
  	if (this.saturday) { this.weekdays.push(6) };
  	if (this.sunday) { this.weekdays.push(7) };
  }

  showRecurring() {
  	var ans = false;
  	if (this.load>0 && this.time!=undefined && !this.editmode) {
	  	if (this.type=='collection') {
	  		if (this.vehicleList['vehicles']!==undefined) {
	  			if (this.vehicleList['vehicles'][this.vehicleIndex]!==undefined) {
	  				ans = true;
	  			}
	  		}
	  	} else {
	  		ans = true;
	  	}
	  }
  	return ans;
  }

  async checkClashes() {
  	if (this.recurring && this.time!=null) {
	  	var p = {
				"location": this.loginService.location,
	  		"season": this.loginService.season,
	  		"type": this.type,
	  		"date": this.date,
	  		"time": this.time,
	  		"recurring": (this.recurring ? 1 : 0 ),
	  		"enddate": this.enddate,
	  		"frequency": this.frequency,
	  		"repeat": this.repeat,
	  		"weekdays": this.weekdays,
	  		"completetime": this.completetime,
				"idvehicle": (this.type=='collection' ? this.vehicleList['vehicles'][this.vehicleIndex]['idvehicle'] : 0 ),
				"iddriver": (this.type=='collection' ? this.vehicleList['vehicles'][this.vehicleIndex]['drivers'][this.driverIndex]['iddriver'] : 0 )
	  	};
	  	this.check = await this.loginService.loadData('checkBookingClash', p);
	  } else {
	  	this.check = {"clashes": [], "dates": [this.date]};
	  }
  }



	// MYSQL loading time: DATE_ADD(CONCAT(date,' ',time), INTERVAL (duration-loadingduration-unloadingduration) * 30 SECOND)
	// MYSQL Unloading time: DATE_ADD(CONCAT(date,' ',time), INTERVAL (duration-unloadingduration) * 60 SECOND)


}