import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { AddPaymentService } from '../../logging/add-payment/add-payment.service';

@Component({
  selector: 'app-picking',
  templateUrl: './picking.component.html',
  styleUrls: ['./picking.component.scss']
})
export class PickingComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
    private loginService: TeamLoginService,
    public tableService: TableDataService,
    private router: Router,
    private addPaymentService: AddPaymentService,
    public dDS: DropDownDataService
    ) { }

  ngOnInit() {
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  async editStock() {
    for (var i=0; i<this.tableService.selectedRows.length; i++) {
      this.tableService.selectedRows[i]['orderref'] = (this.tableService.order=='' ? null : this.tableService.orderdetails[this.tableService.order]['orderref']);
    }
    var pallets = '';
    for (var i = 0; i < this.tableService.selectedRows?.length; i++) {
      pallets = pallets + (pallets.length>0 ? ',' : '') + "'" + this.tableService.selectedRows[i]['pallet'] + "'";
    }
    var p = {
      "season": this.loginService.season,
      "pallets": pallets,
      "orderref": (this.tableService.order=='' ? '' : this.tableService.orderdetails[this.tableService.order]['orderref']),
      "tname": this.tableService.tablename
    };
    var dataStore = await this.loginService.loadData('reassignPallets', p);
    this.loginService.toast('Picking List', dataStore['message'], dataStore['messagetype'], 3000);
  }

  calTotal(field: string){
    var total = 0;
    for (var i = 0; i < this.tableService.selectedRows?.length; i++) {
      total = total + +this.tableService.selectedRows[i][field];
    }
    return total;
  }

  calWavg(field: string, base: string) {
    var total = 0;
    for (var i = 0; i < this.tableService.selectedRows?.length; i++) {
      total = total + +this.tableService.selectedRows[i][field] * +this.tableService.selectedRows[i][base];
    }
    return total / this.calTotal(base);
  }

  calAvg(field: string) {
    var total = 0;
    for (var i = 0; i < this.tableService.selectedRows?.length; i++) {
      total = total + +this.tableService.selectedRows[i][field];
    }
    return total / this.tableService.selectedRows?.length;
  }

  async dispatchSelected() {
    for (var i=0; i<this.tableService.selectedRows.length; i++) {
      this.tableService.selectedRows[i]['orderref'] = (this.tableService.order=='' ? null : this.tableService.orderdetails[this.tableService.order]['orderref']);
    }
    var pallets = '';
    for (var i = 0; i < this.tableService.selectedRows?.length; i++) {
      pallets = pallets + (pallets.length>0 ? ',' : '') + "'" + this.tableService.selectedRows[i]['pallet'] + "'";
    }
    var p = {
      "season": this.loginService.season,
      "pallets": pallets,
      "orderref": (this.tableService.order=='' ? '' : this.tableService.orderdetails[this.tableService.order]['orderref'])
    };
    var dataStore = await this.loginService.loadData('dispatchPallets', p);
    this.loginService.toast('Picking List', dataStore['message'], dataStore['messagetype'], 3000);

  }

}
