<div class="container row justify-content-center">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="this.resetService.validLink('resetPassword')">
		<br>
		<h2>Password Reset</h2>
		<br>
		<h4>Please set a new password below</h4>
		<br>
		<form [formGroup]="frmSignup">
		  <div class="form-group">
		    <label for="password" 
		    	[ngClass]="
		    		frmSignup.controls['password'].hasError('required') ||
		    		frmSignup.controls['password'].hasError('minlength') ||
		    		frmSignup.controls['password'].hasError('hasNumber') ||
		    		frmSignup.controls['password'].hasError('hasCapitalCase') ||
		    		frmSignup.controls['password'].hasError('hasSmallCase') ||
		    		!frmSignup.controls['password'].hasError('hasSpecialCharacters')
		    	? 'text-danger' : ''">Password:</label>
		    <input id="password" formControlName="password" type="password" class="form-control" 
		    	[ngClass]="
		    		frmSignup.controls['password'].hasError('required') ||
		    		frmSignup.controls['password'].hasError('minlength') ||
		    		frmSignup.controls['password'].hasError('hasNumber') ||
		    		frmSignup.controls['password'].hasError('hasCapitalCase') ||
		    		frmSignup.controls['password'].hasError('hasSmallCase') ||
		    		!frmSignup.controls['password'].hasError('hasSpecialCharacters')
		    	? 'is-invalid' : ''">
		    <br>
		    <label class="info col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
		      <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
		        frmSignup.controls['password'].hasError('minlength') ? 'cancel' :
		        'check_circle' }}</i>
		      Must be at least 8 characters
		    </label>
		    <label class="info col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
		      <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
		        frmSignup.controls['password'].hasError('hasNumber') ? 'cancel' :
		        'check_circle' }}</i>
		      Must contain at least 1 number
		    </label>
		    <label class="info col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
		      <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
		        frmSignup.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
		        'check_circle' }}</i>
		      Must contain at least 1 in Capital Case
		    </label>
		    <label class="info col" [ngClass]="frmSignup.controls['password'].hasError('required') || frmSignup.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
		      <i class="material-icons">{{ frmSignup.controls['password'].hasError('required') ||
		        frmSignup.controls['password'].hasError('hasSmallCase') ? 'cancel' :
		        'check_circle' }}</i>
		      Must contain at least 1 Letter in Small Case
		    </label>
		    <label class="info col" [ngClass]="!frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
		      <i class="material-icons">{{ !frmSignup.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
		        'check_circle' }}</i>
		      Must not contain any Special Characters
		    </label>
		  </div>
		  <br>
		  <div class="form-group">
		    <label for="confirmPassword" [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'text-danger' : ''">Confirm
		      Password:</label>
		    <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control"
		      [ngClass]="frmSignup.controls['confirmPassword'].invalid ? 'is-invalid' : ''">
		    <br>
		    <label class="info col" *ngIf="frmSignup.controls['confirmPassword'].hasError('required')" [ngClass]="frmSignup.controls['confirmPassword'].hasError('required') ? 'text-danger' : 'text-success'">
		      <i class="material-icons">{{ frmSignup.controls['confirmPassword'].hasError('required') ? 'cancel' :
		        'check_circle' }}</i>
		      Password is Required!
		    </label>
		    <label class="info col" *ngIf="frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')" [ngClass]="frmSignup.controls['password'].hasError('NoPassswordMatch') ? 'text-success' : 'text-danger'">
		      <i class="material-icons">{{ frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch') ? 'cancel' :
		        'check_circle' }}</i>
		      Passwords do not match
		    </label>
		    <br>
		  </div>
		  <div class="row justify-content-between align-items-center px-3 pt-3">
				<div class="col justify-content-center" style="max-width:155px">
					<button mdbBtn class="btn btn-primary" (click)="savePassword()">Save</button>
				</div>
				<div class="col-auto" routerLinkActive="active">
					<button mdbBtn class="btn btn-link" routerLink="/login">Return to Log In Page</button>
				</div>
			</div>
			<br>
		</form>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="this.resetService.validLink('resetFailure')">
		<br>
		<h2>Password Reset</h2>
		<br>
		<h4>Your password reset has not been succesful.</h4>
		<br>
		<p>Please wait 24 hours before attempting to reset your password again. If you still continue to see this message please contact {{ this.portalService.portalMod()['portal'] }} directly at <a href="mailto:{{ this.portalService.email }}">{{ this.portalService.portalMod()['email'] }}</a>.</p>
		<br>
		<p>Your password reset may have failed for any one of the following reasons:</p>
		<ul>
			<li>You have used an incorrect email address, i.e. one that does not have an account</li>
			<li>You have used an old password reset link that has expired</li>
			<li>You have used the same password link too many times</li>
			<li>You have already reset your password with the link used</li>
			<li>You have tried to reset your password too many times within 24 hours</li>
		</ul>
		<br>
		<div class="row justify-content-between align-items-center px-3 pt-3">
			<div class="col-auto" routerLinkActive="active">
				<button mdbBtn class="btn btn-link" routerLink="/login">Return to Log In Page</button>
			</div>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="this.resetService.validLink('resetRequest')">
		<br>
		<h2>Password Reset</h2>
		<br>
		<h4>If you need a new password please enter your email below:</h4>
		<br>
		<form [formGroup]="resetForm">
			<div formGroupName="userData">
				<div class="form-group">
					<label for="email">Email</label>
					<input 
						type="text" 
						id="email" 
						autocomplete="email" 
						formControlName="email" 
						class="form-control">
					<span
	          *ngIf="!resetForm.get('userData.email').valid && resetForm.get('userData.email').touched"
	          class="help-block">Please enter a valid email!</span>
	      </div>
				<div class="row justify-content-between align-items-center px-3 pt-3">
					<div class="col justify-content-center" style="max-width:155px">
						<button mdbBtn class="btn btn-warning" (click)="resetRequest()">Reset</button>
					</div>
					<div class="col-auto" routerLinkActive="active">
						<button mdbBtn class="btn btn-link" routerLink="/login">Return to Log In Page</button>
					</div>
				</div>
			</div>
		</form>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="this.resetService.validLink('resetSuccess')">
		<br>
		<h2>Password Reset</h2>
		<br>
		<h4>Password change was successful!</h4>
		<br>
		<div class="row justify-content-between align-items-center px-3 pt-3">
			<div class="col-auto" routerLinkActive="active">
				<button mdbBtn class="btn btn-link" routerLink="/login">Return to Log In Page</button>
			</div>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="this.resetService.validLink('resetSent')">
		<br>
		<h2>Password Reset</h2>
		<br>
		<p>A password reset link will have been sent to your email address if it has been recognised. If you do not receive this link in the next 20 minutes, please try again or contact {{ this.portalService.portalMod()['portal'] }} directly at <a href="mailto:{{ this.portalService.email }}">{{ this.portalService.portalMod()['email'] }}</a>.</p>
		<br>	
		<div class="row justify-content-between align-items-center px-3 pt-3">
			<div class="col-auto" routerLinkActive="active">
				<button mdbBtn class="btn btn-link" routerLink="/login">Return to Log In Page</button>
			</div>
		</div>
	</div>
	<br>
</div>