<div class="container">
  <div class="row justify-content-between">
    <div class="col">
      <h3 *ngIf="this.tableService.selectedRows.length==1">Payment Edit: Delivery {{ this.tableService.selectedRows[0]['iddelivery'] | delivery }} [{{ ( this.tableService.selectedRows[0].instalment == "a" ? "Advanced" : (this.tableService.selectedRows[0].instalment == "b" ? "Bonus" : this.tableService.selectedRows[0].instalment ) ) }}] Selected</h3>
      <h3 *ngIf="this.tableService.selectedRows.length > 1">Batch Payment Edit: {{ this.tableService.selectedRows.length }} Payments Selected</h3>
    </div>
  </div>
  <br>
  <table class="table col-sm">
    <tbody>
      <tr class="tableseperator"><td></td><td></td></tr>
      <ng-container *ngIf="this.tableService.selectedRows.length==1">
        <tr>
          <td>Amount Due</td>
          <td>{{ +this.tableService.selectedRows[0].amountdue | currency: this.tableService.selectedRows[0].paycurrency:'symbol-narrow' }}</td>
        </tr>
        <tr>
          <td>Amount Paid to Date</td>
          <td>{{ (this.tableService.selectedRows[0].paycurrency=='ZAR' ? +this.tableService.selectedRows[0].amountpaid : +this.tableService.selectedRows[0].amountpaidUSD) | currency: this.tableService.selectedRows[0].paycurrency:'symbol-narrow' }}</td>
        </tr>
        <tr>
          <td>Amount Remaining</td>
          <td>{{ +this.tableService.selectedRows[0].amountdue - (this.tableService.selectedRows[0].paycurrency=='ZAR' ? +this.tableService.selectedRows[0].amountpaid : +this.tableService.selectedRows[0].amountpaidUSD) | currency: this.tableService.selectedRows[0].paycurrency:'symbol-narrow' }}</td>
        </tr>
        <tr class="tableseperator"><td></td><td></td></tr>
        <tr>
          <td>Set Total Amount Paid</td>
          <td>
            <mat-form-field class="inline">              
              <input matInput #amountdue [ngModel]="+this.tableService.batchpay['newpaid'] | currency: this.tableService.batchpay['paycurrency']:'symbol-narrow'" (blur)="changeAmountPaid(amountdue.value)">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>New Amount Remaining</td>
          <td>{{ +this.tableService.batchpay['amountdue'] - (this.tableService.batchpay['paycurrency']=='ZAR' ? +this.tableService.batchpay['amountpaidZAR'] : +this.tableService.batchpay['amountpaidUSD']) | currency: this.tableService.batchpay['paycurrency']:'symbol-narrow' }}</td>
        </tr>
        <tr class="tableseperator"><td></td><td></td></tr>
      </ng-container>
      <ng-container *ngIf="this.tableService.selectedRows.length>1">
        <tr>
          <td>Set Amount Paid</td>
          <td>
            Fully Paid
          </td>
        </tr>
        <tr class="tableseperator"><td></td><td></td></tr>
        <tr>
          <td>Total Outstanding</td>
          <td>
            {{ amountToPay() | currency: this.tableService.selectedRows[0].paycurrency:'symbol-narrow' }}
          </td>
        </tr>
        <tr class="tableseperator"><td></td><td></td></tr>
        <tr>
          <td>Total to be Marked Paid</td>
          <td>
            {{ amountDue() | currency: this.tableService.selectedRows[0].paycurrency:'symbol-narrow' }}
          </td>
        </tr>
        <tr class="tableseperator"><td></td><td></td></tr>
      </ng-container>
      <tr>
        <td>Set Paid Date</td>
        <td>
          <mat-form-field class="inline">
            <input matInput type="date" [(ngModel)]="this.tableService.batchpay['paiddate']">
          </mat-form-field>
        </td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
    </tbody>
  </table>
  <br>
  <div *ngIf="this.tableService.selectedRows.length==1" class="row justify-content-between">
    <div class="col-xs-6 ml-3"></div>
    <div class="mr-4 ml-3">
      <button mdbBtn class="btn btn-primary" (click)="updatePayment()">Save Changes</button>
    </div>
  </div>
  <div *ngIf="this.tableService.selectedRows.length>1" class="row justify-content-between">
    <div class="col-xs-6 ml-3"></div>
    <div class="mr-4 ml-3">
      <button mdbBtn class="btn btn-secondary" (click)="updatePayments()">Save Bulk Changes</button>
    </div>
  </div>
  <br><br>
</div>