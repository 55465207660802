import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { Setting, Pallet } from './general.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class GeneralSettingsService{
	private postBody: {};
	httpRes: any;
	public loadedEntry: any;
	public setting: string;
	public settings: Setting[];
	public saving: boolean = false;
	public index: number;

	public clientinvoicegen: boolean;
	public clientportal: boolean;
	public clientcalc: boolean;

	public palletsave: string = '';
	public pallet: Pallet = {'skr': '0', 'uskr': '0', 'moisture': '0'};


	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService,
		public dropDownDataService: DropDownDataService) {}

	updateSetting(index) {
		this.postBody = {
			"name": "updateGeneralSetting",
			"param":{
		    "settings": this.settings[index]
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
	        	this.loadForEdit();
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Settings were not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  loadForEdit() {
		this.postBody = {
			"name": "loadGeneralSettings",
			"param": {
				"location": this.loginService.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		var pallet = false;
		this.settings = rawData.response.result.reply;
		for (var i = 0; i < this.settings.length; i++) {
			this.settings[i]['value'] = (+this.settings[i]['value']==1 ? 1 : 0);
			if (this.settings[i]['setting']=='Pallet Sampling' && this.settings[i]['value']==1) {
				pallet = true;
			}
		}
		if (pallet) {
			this.loadPalletSettings();
		}
	}

  loadPalletSettings() {
		this.postBody = {
			"name": "loadPalletSettings",
			"param": {
				"location": this.loginService.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.setPallet(response);
        },
        (error) => console.log(error)
      );
	}

	setPallet(rawData) {
		var pallet = rawData.response.result.reply;
		for (var i = 0; i < pallet.length; i++) {
			this.pallet[pallet[i]['setting']] = +pallet[i]['value'];
		}
	}

  resetForm(keepRef: boolean = false) {
    window.scroll(0,0);
    this.settings = null;
    this.saving = false;
  }

  saveValue(index: number) {
		this.index = index;
		this.editSetting(index);
  }

	editSetting(index: number) {
		this.saving = true;
		this.postBody = {
			"name": 'saveGeneralSettingValue',
			"param":{
				"value4": this.settings[index]['value4'],
		    "setting": this.settings[index]['setting']
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
			    	this.saving = false;
	        	this.loadForEdit();
	        } catch(e) {
			    	this.saving = false;
	        }
        },
        (error) => console.log(error)
      );
	}

	savePalletValue(type) {
		this.saving = true;
		this.palletsave = type;
		this.postBody = {
			"name": 'savePalletSettingValue',
			"param":{
				"value": this.pallet[type],
		    "setting": type
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
			    	this.saving = false;
						this.palletsave = '';
						this.loginService.toast("Update Successful",response['response']['result']['reply']['message']);
	        } catch(e) {
			    	this.saving = false;
						this.palletsave = '';
	        	this.loginService.toast("Update Unsuccessful","Setting was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  onUploadStamp(event) {
    if (event.files.length==1) {
			const file:File = event.files[0];

			const formData = new FormData();
      formData.append("image", file);

			this.http.post(
				//'api/test/upload.php', 
				this.loginService.httpRoot,
				formData,
				{
					headers: new HttpHeaders({
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token'),
						'Api-Call': 'uploadStamp'
					})
				})
      .subscribe(res => {
				this.loginService.toast("Upload Successful","Company stamp uploaded succesfully.");
      })
    }
  }

  onUploadSig(event) {
    if (event.files.length==1) {
			const file:File = event.files[0];

			const formData = new FormData();
      formData.append("image", file);

			this.http.post(
				//'api/test/upload.php', 
				this.loginService.httpRoot,
				formData,
				{
					headers: new HttpHeaders({
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token'),
						'Api-Call': 'uploadSig'
					})
				})
      .subscribe(res => {
				this.loginService.toast("Upload Successful","Document signature uploaded succesfully.");
      })
    }
  }

  onUploadFumSig(event) {
    if (event.files.length==1) {
			const file:File = event.files[0];

			const formData = new FormData();
      formData.append("image", file);

			this.http.post(
				//'api/test/upload.php', 
				this.loginService.httpRoot,
				formData,
				{
					headers: new HttpHeaders({
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token'),
						'Api-Call': 'uploadFumSig'
					})
				})
      .subscribe(res => {
				this.loginService.toast("Upload Successful","Fumigation signature uploaded succesfully.");
      })
    }
  }


}