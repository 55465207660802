import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoginService } from '../auth/login/login.service';
import { HeaderService } from '../header/header.service';
import { FarmerRemittanceService } from '../remittance/remittance.service';
import { PortalService } from '../../auth/portal.service';
import { CsvService } from '../../services/csv.service';
import { SplitTermsService } from '../../services/splitterms.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class ClientTableDataService {
	private postBody: {};
	httpRes: any;
	public allData: any = [];
	public filteredData: any;
	public deliveries: any;
	public samples: any;
	public loading: boolean = true;
	public stopLoading: boolean = false;
	public stillLoading: boolean = true;
	public delivery: any;
  public clickedId: any = [];
	public clickedIndex: any[] = [];
	public clickedMoisture: number;
	public clickedSKR: number;
	public clickedUSKR: number;
	public clickedUnsound: number;
	public totalDeliveries: number;
	public paypercent: [];
	public payday: any [];
	public paydayRaw: any [];
	public epaypercent: [];
	public epayday: any [];
	public epaydayRaw: any [];
	public currency: string = 'ZAR';
	public bpanel: boolean = false;
	public selected: any;
	public clickedType: string;
  public indexLink = false;
  public addCur: boolean;
  public checkBoxesActive: boolean = false;
  public checkBoxes: boolean = false;
  public checkboxdata = [];
  public selectedRows = [];
  public batchpay = [];
  public tablename: string;
  public sampleUnA: any;
  public order: string = '';
  public orderdetails: [] = [];

	constructor(
		private http: HttpClient,
		private loginService: LoginService,
		private portalService: PortalService,
		public headerService: HeaderService,
		private remittanceService: FarmerRemittanceService,
		public splitTermsService: SplitTermsService,
		public csvService: CsvService,
		)	{}

	async loadData(data: string, season = this.loginService.season, part = 1) {
		this.loading = (part==1);
		this.stillLoading = true;
		var p = {
			"season": (season>0 ? season : this.loginService.season),
			"producer": +this.loginService.selectedmember,
			"part": part
		}
		const response = await this.loginService.loadData(data, p);
		const httpRes = await this.storeResult(response, data);
		return true;
	}

	async storeResult(rawData, data: string) {
		if (rawData['part']==undefined || rawData['part']==1) {
			this.allData[data] = rawData;
			this.loginService.dropdown['tableColumns'][data] = this.allData[data]['columns'];
			this.checkBoxesActive = (this.allData[data]['checkboxes']==1);
			if (this.clickedIndex[data]==undefined) {
				this.clickedIndex[data] = null;
			}
			this.loading = false;
			this.indexLink = false;
		} else {
			this.allData[data][data].push(...rawData[data]);
			if (rawData[data].length>0) {
				this.sortDropdowns(data,rawData);
			}
		}
		if (this.allData[data][data][0]['currency']!=undefined) {
			this.currency = this.allData[data][data][0]['currency'];
		}
		if (rawData['part']!==undefined && !this.stopLoading) {
			if (rawData[data].length>0) {
				this.loadData(data, this.loginService.season, +rawData['part'] + 1);
			} else {
				this.stillLoading = false;
				this.loginService.dropdown['tableColumns'][data] = this.allData[data]['columns'];
			}
		}
		if (rawData['part']==undefined) {
			this.stillLoading = false;
		}
		this[data] = await [...this.allData[data][data]];
		if (!this.stillLoading) {
			this.refreshSelectedRows(data, rawData['key']);
		}
	}

	sortDropdowns(data,rawData) {
		for (var i=0; i<this.allData[data]['columns'].length; i++) {
			if (this.allData[data]['columns'][i]['type']=='dropdown'){
				var dropdown = this.allData[data]['columns'][i]['field'];
				if (this.allData[data][dropdown]!==undefined) {
					var curDrop = [...this.allData[data][dropdown],...rawData[dropdown]];
					curDrop = this.removeDuplicates(curDrop,dropdown);
					curDrop.sort(this.compareValues(dropdown));
					this.allData[data][this.allData[data]['columns'][i]['field']] = curDrop;
				}
			}
		}
	}

	removeDuplicates(arr,k) {
		const uniqueK = new Set();
		return arr.filter(element => {
			const isDup = uniqueK.has(element[k]);
			uniqueK.add(element[k]);
			if (!isDup) {
				return true;
			}
			return false;
		});
	}

	compareValues(key, order = 'asc') {
	  return function innerSort(a, b) {
	    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
	      // property doesn't exist on either object
	      return 0;
	    }
	    const varA = (typeof a[key] === 'string')
	      ? a[key].toUpperCase() : a[key];
	    const varB = (typeof b[key] === 'string')
	      ? b[key].toUpperCase() : b[key];
	    let comparison = 0;
	    if (varA > varB) {
	      comparison = 1;
	    } else if (varA < varB) {
	      comparison = -1;
	    }
	    return (
	      (order === 'desc') ? (comparison * -1) : comparison
	    );
	  };
	}

	async setDelivery(idd: any, data: string, season = null) {
		if (this[data]==undefined) {
			const ans = await this.loadData(data, season);
		}
		if (season>0 && this.allData[data]['season']!=season) {
			const ans = await this.loadData(data, season);
		}
		switch (data) {
			case "deliveries":
			case "samples":				
				this.setD(idd, 'deliveries');
				this.setD(idd, 'samples');
				break;
			case "payments":
			case "remittances":
				this.setId(idd, data);
				this.getRemittance(idd);
    		this.remittanceService.iddelivery = idd + '';
		    if (this.remittanceService.iddelivery!==undefined) {
		      this.remittanceService.loadRemittance();
		    }
				break;
			case "":
				break;
			default:
				this.setId(idd, data);
				break;
		}
		this.indexLink = false;
	}

	async getData(idd: number) {
		this.postBody = {
			"name":"loadRemittance",
			"param":{
				"producer": +this.loginService.selectedmember,
				"iddelivery": +this.clickedId['iddelivery'],
				"season": +this.loginService.season
			}
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('id_token')
				}
			}
		)
		return response.json();
	}

	getRemittance(idd: number) {
		this.getData(idd)
		  .then(response => {
		  	this.loginService.setSession(response);
		  	this.selected = response['response']['result']['reply'];
		  })
		  .catch(error => console.log(error));
	}

	async setD(idd, data) {
		for (var i = 0; i < this[data].length; i++) {
			if (this[data][i][this.allData[data]['key']] == idd) {
				this.clickedIndex[data] = i;
			}
		}
		this.paypercent = [];
		if (this.delivery['dcustompaypercent'] != null) {
			if (this.delivery['dcustompaypercent'].charAt(this.delivery['dcustompaypercent'].length - 1) == ";") {
				this.delivery['dcustompaypercent'] = this.delivery['dcustompaypercent'].slice(0,this.delivery['dcustompaypercent'].length - 1);
			}
			if (this.delivery['dcustompayday'].charAt(this.delivery['dcustompayday'].length - 1) == ";") {
				this.delivery['dcustompayday'] = this.delivery['dcustompayday'].slice(0,this.delivery['dcustompayday'].length - 1);
			}
			if (this.delivery['ecustompaypercent']!==null) {
				if (this.delivery['ecustompaypercent'].charAt(this.delivery['ecustompaypercent'].length - 1) == ";") {
					this.delivery['ecustompaypercent'] = this.delivery['ecustompaypercent'].slice(0,this.delivery['ecustompaypercent'].length - 1);
				}
				if (this.delivery['ecustompayday'].charAt(this.delivery['ecustompayday'].length - 1) == ";") {
					this.delivery['ecustompayday'] = this.delivery['ecustompayday'].slice(0,this.delivery['ecustompayday'].length - 1);
				}
			}
			this.paypercent = this.delivery['dcustompaypercent'].split(";");
			this.payday = this.delivery['dcustompayday'].split(";");
			this.epaypercent = (this.delivery['ecustompaypercent']==null ? null : this.delivery['ecustompaypercent'].split(";"));
			this.epayday = (this.delivery['ecustompayday']==null ? null : this.delivery['ecustompayday'].split(";"));
			this.paydayRaw = this.delivery['dcustompayday'].split(";");
			this.epaydayRaw = (this.epayday==null ? null : this.delivery['ecustompayday'].split(";"));
			this.payday = this.splitTermsService.transDay(this.paydayRaw);
			this.epayday = (this.epaydayRaw==null ? null : this.splitTermsService.transDay(this.epaydayRaw));
		}
	}

	setC(idd) {
		var data = 'companies';
		for (var i = 0; i < this[data].length ; i++) {
			if (+this[data][i].idcompany == idd) {
				this.clickedIndex['companies'] = i;
				this.clickedType = this[data][i]['companytype'];
				if (this[data][i]['companytype']=="producer") {
					if (this[data][i]['paypercent'].charAt(this[data][i]['paypercent'].length - 1) == ";") {
						this[data][i]['paypercent'] = this[data][i]['paypercent'].slice(0,this[data][i]['paypercent'].length - 1);
					}
					if (this[data][i]['payday'].charAt(this[data][i]['payday'].length - 1) == ";") {
						this[data][i]['payday'] = this[data][i]['payday'].slice(0,this[data][i]['payday'].length - 1);
					}
					if (this[data][i]['epaypercent']!==null) {
						if (this[data][i]['epaypercent'].charAt(this[data][i]['epaypercent'].length - 1) == ";") {
							this[data][i]['epaypercent'] = this[data][i]['epaypercent'].slice(0,this[data][i]['epaypercent'].length - 1);
						}
						if (this[data][i]['epayday'].charAt(this[data][i]['epayday'].length - 1) == ";") {
							this[data][i]['epayday'] = this[data][i]['epayday'].slice(0,this[data][i]['epayday'].length - 1);
						}
					}
					this.paypercent = this[data][i]['paypercent'].split(";");
					this.payday = this[data][i]['payday'].split(";");
					this.epaypercent = (this[data][i]['epaypercent']==null ? null : this[data][i]['epaypercent'].split(";"));
					this.epayday = (this[data][i]['epayday']==null ? null : this[data][i]['epayday'].split(";"));
					this.paydayRaw = this[data][i]['payday'].split(";");
					this.epaydayRaw = (this.epayday==null ? null : this[data][i]['epayday'].split(";"));
					this.payday = this.splitTermsService.transDay(this.paydayRaw);
					this.epayday = (this.epaydayRaw==null ? null : this.splitTermsService.transDay(this.epaydayRaw));
				}
			}
		}
	}

	setId(idd, data) {
		for (var i = 0; i < this[data].length; i++) {
			if (this[data][i][this.allData[data]['key']] == idd) {
				this.clickedIndex[data] = i;
			}
		}
	}

	reset(data: string) {
    delete this.clickedIndex[data];
    this.checkboxdata = null;
    this.selectedRows = null;
    this.filteredData = null;
	}

	refreshAll() {
    this.loadData('deliveries');
    this.loadData('samples');
    if (this.headerService.hasAccess('finance')) {
	    this.loadData('remittances');
	    this.loadData('payments'); 
	  }
	}

	loadReady(data) {
		if (this.allData[data.toLowerCase()]==undefined) {
			return false;
		}
		if (this.allData[data.toLowerCase()]['key']==undefined) {
			return false;
		}
		if (this[data.toLowerCase()][this.clickedIndex[data.toLowerCase()]]==undefined) {
			return false;
		}
		if (this[data.toLowerCase()][this.clickedIndex[data.toLowerCase()]][this.allData[data.toLowerCase()]['key']]==undefined) {
			return false;
		}
		return true;
	}

	clearData() {
		this.allData = [];
		this.checkBoxesActive = false;
		this.selectedRows = [];
		this.clickedIndex = [];
		this.filteredData = null;
		this.tablename = null;
		this['deliveries'] = null;
		this['samples'] = null;
		this['remittances'] = null;
		this['payments'] = null;
	}

	saveCSV(data) {
		var exportData = this.organiseExport(data, 'csv');
		this.csvService.rawCSV(exportData['title'], exportData['headers'], exportData['data']);
	}

	saveXLSX(data) {
		var exportData = this.organiseExport(data, 'xlsx');
		this.csvService.exportExcel(exportData['title'], exportData['data']);
	}

	organiseExport(data, type = 'csv') {
		var headers = [];
		var exportData = [];
		var footer = [];
		var count = [];
		var sum = [];
		var tabledata = 'filteredData';
		this.checkboxdata = (this.checkboxdata==null ? [] : this.checkboxdata);
		if (this.checkboxdata.length>0 && this.checkBoxesActive && this.checkBoxes) {
			tabledata = 'checkboxdata';
		}
		this.addCur = false;
		for (var i = 0; i < this[tabledata].length; i++) {
			if (exportData[i]==undefined) {
				exportData[i] = [];
			}
			for (var j = 0; j < this.loginService.dropdown['tableColumns'][data].length; j++) {
				headers[j] = this.loginService.dropdown['tableColumns'][data][j]['header'];
				var index = (type=='xlsx' ? headers[j] : j );
				if (this.loginService.dropdown['tableColumns'][data][j]['pipe']=='currency') {
					this.addCur = true;
					exportData[i][index] = this.fixFx(this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['field']], this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['fxrate']], this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['currency']]);
				} else {
					exportData[i][index] = this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['field']];
				}
				switch (this.loginService.dropdown['tableColumns'][data][j]['footer']) {
				 	case "text":
				 		footer[j] = this.loginService.dropdown['tableColumns'][data][j]['text']
				 		break;
				 	case "count":
				 		count[j] = (count[j]==undefined ? 0 : count[j]) + (this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['field']]==null ? 0 : 1);
				 		footer[j] = count[j];
				 		break;
				 	case "sum":
				 		footer[j] = (footer[j]==undefined ? 0 : footer[j]) + +exportData[i][index];
				 		break;
				 	case "wavg":
				 		count[j] = (count[j]==undefined ? 0 : count[j]) + +this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['base']];
				 		sum[j] = (sum[j]==undefined ? 0 : sum[j]) + +exportData[i][j] * this[tabledata][i][this.loginService.dropdown['tableColumns'][data][j]['base']];
				 		footer[j] = (i == this[tabledata].length - 1 ? sum[j] / count[j] : '');
				 		break;
				 	default:
				 		footer[j] = '';
				 		break;
				 } 
			}
		}
		var title = this.loginService.selectedmembername + ' - ' + data.charAt(0).toUpperCase() + data.slice(1) + (this.addCur ? ' (' + this.currency + ')' : '');
		return {'title': title, 'headers': headers, 'data': exportData };
	}

  fixFx(value: number, fx: number, currency: string) {
    if (this.currency=='USD') {
      value = value * (currency=='USD' ? 1 : 1/fx );
    } else {
      value = value * (currency=='USD' ? fx : 1 );
    }
    return value;
  }

  sortCheckBoxes(id) {
  	this.checkboxdata = [];
  	var clearAfter = true;
  	if (id==null) {
  		for (var i = 0; i < this.selectedRows.length; i++) {
  			if (this.selectedRows[i]['disabled']==0) {
	  			this.checkboxdata.push({"idpayment": this.selectedRows[i]['id'], "companyname": this.selectedRows[i]['companyname']});
	  		}
	  	}
	  } else {
	  	clearAfter = false;
	  	this.checkboxdata = [{"idpayment": id}];
	  }
	  return clearAfter;
  }

  async boxChecked(tablename,table,key,newamount = false) {
  	switch (tablename) {
	  	default:
				this.checkboxdata = table._selection;
  			break;
	  	}
  }

  refreshSelectedRows(tablename, key) {
  	if (this.selectedRows!=undefined) {
	  	for (var i=0; i<this.selectedRows.length; i++) {
	  		for (var j=0; j<this[tablename].length; j++) {
	  			if (this[tablename][j][key]==this.selectedRows[i][key]) {
	  				this.selectedRows[i] = this[tablename][j];
	  			}
	  		}
	  	}
	  }
  }

  updateRow(tablename, id, changes) {
	  var key = this.allData[tablename]['key'];
  	for (var i=0; i<changes.length; i++) {
  		var field = changes[i]['field'];
  		var value = changes[i]['value'];
			for (var j=0; j<this[tablename].length; j++) {
				if (this[tablename][j][key]==id) {
					this[tablename][j][field] = value;
				}
		  }
		}
		this.refreshSelectedRows(tablename, key);
  }

  clearAll() {
  	this['deliveries'] = null;
  	this['samples'] = null;
  	this['remittances'] = null;
  	this['payments'] = null;
  }

}