import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { AddPaymentService } from '../../logging/add-payment/add-payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class TeamPaymentComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
    private loginService: TeamLoginService,
    public tableService: TableDataService,
    private router: Router,
    private addPaymentService: AddPaymentService,
    private dropDownDataService: DropDownDataService
    ) { }

  ngOnInit() {
    this.dropDownDataService.getDropdown("paymentList"); 
    this.dropDownDataService.getDropdown("uaPaymentList");
    this.dropDownDataService.getDropdown("uaNoPaymentList");
    this.dropDownDataService.getDropdown("producerList"); 	
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  editPayment() {
    this.addPaymentService.editmode = true;
    this.dropDownDataService.getDropdown("paymentList");
    this.dropDownDataService.getDropdown("uaPaymentList");
    this.dropDownDataService.getDropdown("uaNoPaymentList");
    this.dropDownDataService.getDropdown("producerList");
    this.addPaymentService.editPayment(
    	this.tableService['payments'][this.tableService.clickedIndex['payments']].iddelivery,
    	this.tableService['payments'][this.tableService.clickedIndex['payments']].instalment,
    	this.tableService['payments'][this.tableService.clickedIndex['payments']].idcompany
    );
    this.router.navigate(['/team/logging/payment']);
  }

  viewCompany(idcompany) {
    this.tableService.reset('companies');
    this.tableService.setDelivery(idcompany, 'companies');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100);
  }

}
