<div *ngIf="isAuthenticated()" class="container">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<div class="row justify-content-between">
					<div class="col">
						<h2>Batches</h2>
					</div>
					<div class="mr-4 ml-3">
						<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
					</div>
				</div>
			</div>
			<br>
			<mat-form-field class="filter-wide">
			  <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter">
			</mat-form-field>
			<div class="example-container mat-elevation-z8">
				<div class="example-loading-shade"
			       *ngIf="isLoadingResults">
			    <mat-spinner *ngIf="isLoadingResults" color="accent"></mat-spinner>
			  </div>
				<div class="example-table-container">
				  <table mat-table matSort [dataSource]="datasrc" hover="true" class="example-table">
				  	<ng-container matColumnDef="type">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Storage Type </th>
				      <td mat-cell *matCellDef="let element"> {{element.type | titlecase }} </td>
				    </ng-container>
				  	<ng-container matColumnDef="name" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Storage </th>
				      <td mat-cell *matCellDef="let element"> {{element.name }} </td>
				    </ng-container>
				  	<ng-container matColumnDef="batchtype">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Batch Type </th>
				      <td mat-cell *matCellDef="let element"> {{ (element.batchtype=='nis' ? 'NIS' : (element.batchtype=='gbelow20' ? 'NIS < 20mm' : '')) }}{{ (element.batchtype=='deliveries' || element.batchtype=='mix' || element.batchtype=='transfer' ? element.batchtype : '') | titlecase }} </td>
				    </ng-container>
				    <ng-container matColumnDef="variety">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Variety </th>
				      <td mat-cell *matCellDef="let element"> {{element.variety }} </td>
				    </ng-container>
				   <ng-container matColumnDef="intakestart">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Loading </th>
				      <td mat-cell *matCellDef="let element"> {{element.intakestart | date:'MMM d, EEE'}}</td>
				    </ng-container>
				    <ng-container matColumnDef="intakeend">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Processing </th>
				      <td mat-cell *matCellDef="let element"> {{element.intakeend | date:'MMM d, EEE'}} </td>
				    </ng-container>
				    <ng-container matColumnDef="emptydate">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Completion Date </th>
				      <td mat-cell *matCellDef="let element"> {{element.emptydate | date:'MMM d, EEE'}} </td>
				    </ng-container>
				    <ng-container matColumnDef="batch">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Batch Name </th>
				      <td mat-cell *matCellDef="let element"> {{element.batch}} </td>
				    </ng-container>
				    <ng-container matColumnDef="locationname">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
				      <td mat-cell *matCellDef="let element"> {{element.locationname}} </td>
				    </ng-container>			    
				    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				    <tr mat-row 
				    	*matRowDef="let row; let i = index; columns: displayedColumns;" 
				    	(click)="onSelectRow(i)"
				    	style="cursor: pointer"
				    	class="example-element-row"></tr>
				  </table>
				</div>
			</div>
			<br>
			<br>
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>