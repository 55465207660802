<div *ngIf="isAuthenticated()">
  <br>
  <div class="row mt-3">
    <div class="col">
      <div>
        <div class="row justify-content-between">
          <div class="col main-header">
            <h2>
              <div class="dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                Delivery Schedule: {{ this.loginService.season }} Season<span class="caret"></span></a>
                <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                  <button 
                    class="dropdown-item waves-light py-3" 
                    mdbWavesEffect 
                    (click)="onSeasonSelected(season)"
                    *ngFor='let season of this.loginService.seasons'>{{ season }} Season</button>
                </ul>
              </div>
            </h2>
          </div>
          <button mdbBtn class="btn btn-primary mr-4 col-auto" (click)="csvDownload()">CSV</button>
        </div>
      </div>

      <br>

      <div class="col">

      	<div class="form-container" *ngIf="multipleMembers()">
          <mat-form-field class="col">
            <mat-select placeholder="Delivery Schedule for" [(ngModel)]="this.producer" (keyup)="refreshCal()" (ngModelChange)="refreshCal()">
            	<mat-option (click)="refreshCal()" value="0">All Producers</mat-option>
              <mat-option *ngFor="let producer of this.producerBookingList" (click)="refreshCal()" value="{{ producer['idcompany'] }}">{{ producer['companyname'] }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      	<br>
      	<br>
      	
      	<full-calendar [options]="calendarOptions"></full-calendar>

      </div>
    </div>
  </div>
</div>