import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TraceInventoryService } from '../traceinventory/traceinventory.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { AddSampleBatchedService } from '../../logging/add-sample-batched/add-sample.service';

@Component({
  selector: 'app-tracebatch',
  templateUrl: './tracebatch.component.html',
  styleUrls: ['./tracebatch.component.scss']
})
export class BatchComponent implements OnInit {
	clickedIndex;
  dataStore;
  i = 0;

  constructor(
  	private loginService: TeamLoginService,
  	public inventoryService: TraceInventoryService,
    private router: Router,
    private addDeliveryService: AddDeliveryService,
    private addSampleService: AddSampleBatchedService,
    private dropDownDataService: DropDownDataService
    ) { }

  ngOnInit() {
  }

  selectBatch(i: number) {
    this.inventoryService.iBatch = i;
    this.inventoryService.batchClicked = true;
  }
}
