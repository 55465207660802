<div *ngIf="this.articleService.title!==undefined">
	<div>
		<h2><a href="{{ this.portalService.portalMod()['blog'] }}" target="_blank">Latest Article from our Blog</a></h2>
	</div>		
	<br>
	<div>
	  <mdb-card
	    [style.background-image]="this.articleService.imageurl"
	    class="card-image mb-3 w-100"
	  >
	    <div class="text-white text-left align-items-left rgba-grey-strong py-5 px-5 waves-light">
	      <div>
	        <h3 class="card-title pt-2 mb-0"><strong>{{ this.articleService.title }}</strong></h3>
	        <p class="card-title pt-2 mb-0"><strong>{{ this.articleService.author }}</strong></p>
	        <p class="card-title pt-2"><strong>{{ this.articleService.date | date:'MMM d, yyyy' }}</strong></p>
	        <p style=" white-space: pre-line">{{ this.articleService.body }}</p>
	        <a *ngIf="this.articleService.url!=='' && this.articleService.url!==null" mdbBtn color="primary" href="{{ this.articleService.url }}" target="_blank" class="waves-light" mdbWavesEffect>
	          <mdb-icon far icon="newspaper" class="left"></mdb-icon>
	          View article</a>
	      </div>
	    </div>
	  </mdb-card>
	</div>
</div>