import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { TeamCalculatorService } from '../calculator/teamcalculator.service';
import { TableDataService } from '../overview/table/table.service';

import { TeamRemittancesService } from '../overview/remittances/teamremittances.service';
import { InventoryService } from '../overview/inventory/inventory.service';
import { SiloService } from '../overview/silos/silos.service';
import { DepotDeliveryService } from '../depot/depotdelivery.service'
import { DropDownDataService } from '../logging/dropdown.service';
import { PortalService } from '../../auth/portal.service';


@Component({
  selector: 'app-teamhome',
  templateUrl: './teamhome.component.html',
  styleUrls: ['./teamhome.component.scss']
})
export class TeamHomeComponent implements OnInit {
	deliveryData: {};
	memberSelect;  
  d: Date = new Date();
  silosVisible = false;

  constructor(
  	public lS: TeamLoginService,
    public rS: TeamRemittancesService,
    public iS: InventoryService,
    public siloService: SiloService,
    public cS: TeamCalculatorService,
    public dDS: DepotDeliveryService,
    public dDDS: DropDownDataService,
    public pS: PortalService,
    public tS: TableDataService,
    private router: Router
  	) { }

  ngOnInit() {
  	if (this.lS.isAuthenticated()) {
      this.cacheForMember();
    }
    window.scrollTo(0,0);
  }

  cacheForMember() {  
    this.dDDS.getDropdown("tableColumns");
    if (this.isAuthorised('manageinventory') && !this.isAuthorised('general') && !this.isAuthorised('lab')) {
      this.dDDS.getDropdown("scanDestinations");
      this.iS.loadInventoryGrouped();
    }
    if (this.isAuthorised('general')) {
      this.rS.loadOverview();
      this.iS.loadInventoryGrouped();
    } else if (this.isAuthorised('siloselector') || this.isAuthorised('general')) {
      if (!this.isAuthorised('manageinventory')) {
        this.router.navigate(['/team/logging/silo-selector']);
      }
    } else if (this.isAuthorised('depot')) {
      this.dDS.loadDepotOverview();
    }
    this.lS.memberships = JSON.parse(localStorage.getItem('memberships'));
  }

  showSilo() {
    this.silosVisible = true;
  }

  ngAfterViewInit() {
    if (this.isAuthorised('general')) {
      this.dDDS.getDropdown("companyList");
      this.dDDS.getDropdown("contactList");
      this.dDDS.getDropdown("productList");
      this.dDDS.getDropdown("sampleList");
      if (this.pS.portalMod()['batch']) {
      }
    }
    if (this.isAuthorised('buying')) {
      this.dDDS.getDropdown("orderList");
    }
    setTimeout(function() {
      this.showSilo();
    }.bind(this), 0); 
    
  }

  async onSeasonSelected(season) {
    this.lS.season = season;
    this.cacheForMember();
    this.tS.clearData();
    this.lS.toast('Season Change','Selected season now set to ' + this.lS.season,'success');
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.lS.isAuthenticated();
    }
    if (this.pS.portalMod()[option] || this.pS.portalMod()['mod-all']) {
      return this.lS.isAuthenticated();
    } return false;
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

}
