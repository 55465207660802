import { Component, VERSION, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { PortalService } from '../../../auth/portal.service';
import { LogisticsLoginService } from '../../auth/login/login.service';
import { LogisticsQRService } from '../qr.service';
import { CsvService } from '../../../services/csv.service';
import { QRCode } from '../../../interfaces/qr.interface';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';

@Component({
  selector: 'app-logisticsqr-add',
  templateUrl: './add-qr.component.html',
  styleUrls: ['./add-qr.component.scss']
})
export class LogisticsAddQrComponent implements OnInit {
	///

  ngVersion = VERSION.full;
  @ViewChild('scanner', { static: true })
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  ///


  ///

  cover = "cover";
  torch = true;
  classState = '';
  logInState = '';
  codeData: QRCode;
	
  constructor(
    public lQR: LogisticsQRService,
    public lS: LogisticsLoginService,
    public pS: PortalService,
    public csvService: CsvService,
    ) { }

  ngOnInit() {
    this.onLoad();
    this.lQR.reset();
    this.setDestination();
  }

  ngOnDestroy() {
    this.lQR.reset();
    this.lQR.orderref = '';
    this.lQR.mode = 'inspect';
  }

  async onLoad() {
    this.lS.dropdown["scanDestinations"] = await this.lS.loadData('scanDestinations');
    this.lS.dropdown["productList"] = await this.lS.loadData('productList');
    this.lS.dropdown["dispatchStyles"] = await this.lS.loadData('dispatchStyles');
    this.lS.dropdown["storageListWNIS"] = await this.lS.loadData('storageListWNIS');
  }

  setDestination() {
    if (this.lS.dropdown.scanDestinations['destinations'].length==1) {
      this.lQR.destination = this.lS.dropdown.scanDestinations['destinations'][0]['idstorage'] + '';
    }
  }

  isAuthenticated() {
    this.logInState = (this.lS.isAuthenticated() ? "loggedIn" : "loggedOut" );
    return this.lS.isAuthenticated();
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    // console.debug('Devices: ', cameras);
    this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
    this.codeData = {code:''};
    this.qrResultString = resultString;
    this.codeData['code'] = resultString;
    this.lQR.addCode(this.codeData);
    this.classState = (this.lQR.recall ? "codeRecall" : (this.lQR.save ? "codeSave" : "codeRead" ));
    setTimeout(() => {
      this.classState = "";
    }, 500);
  }

  onDeviceSelectChange(selected: string) {
    // console.debug('Selection changed: ', selected);
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  setCameras(event) {
    this.availableDevices = event;
    this.currentDevice = this.availableDevices[0];
      //selects the devices's back camera by default
    for (const device of event) {
      if (/back|rear|environment/gi.test(device.label)) {
        //this.scanner.changeDevice(device);
        this.scanner.device = device;
        this.currentDevice = device;
        break;
      }
    }
    this.scanner.hasDevices.subscribe((x: boolean) => this.hasDevices = x);
    // or you can manually check if the component found them
    // this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.scanComplete.subscribe((x: Result) => this.qrResult = x);
    this.scanner.permissionResponse.subscribe((x: boolean) => this.hasPermission = x);
  }

  toggleMode(mode) {
    this.lQR.reset();
    this.setDestination();
    this.lQR.mode = mode;
  }

  downloadCSV() {
    this.csvService.rawCSV('QR Scanner report', Object.keys(this.lQR.qrCodes[0]), this.lQR.qrCodes);
  }

}
