<div class="container">
	<div class="row justify-content-between">
		<div class="col-xs-6 main-header">
			<h3>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype == "buyer" ? "Buyer" : "Company" }}: {{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companyname }}</h3>
		</div>
		<div class="mr-4 ml-3 col-auto">
			<button mdbBtn class="btn btn-secondary" (click)="editCompany()">Edit Company</button>
		</div>
	</div>
	<br>
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Company Type</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype | titlecase }}</td>
			</tr>
			<tr *ngIf="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype=='producer'">
				<td>Farm ID</td>
				<td>{{ ( this.tableService['companies'][this.tableService.clickedIndex['companies']]?.farmid==null ? "Not assigned" : this.tableService['companies'][this.tableService.clickedIndex['companies']]?.farmid ) }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Parent Company</td>
				<td><a (click)="viewCompany(this.tableService['companies'][this.tableService.clickedIndex['companies']]?.parent)">{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.parentname }}</a></td>
			</tr>
			<tr>
				<td>Contact</td>
				<td><a (click)="viewContact(this.tableService['companies'][this.tableService.clickedIndex['companies']]?.contact)">{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.firstname }} {{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.lastname }}</a></td>
			</tr>
			<tr>
				<td>Telephone</td>
				<td><a href="tel:{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.tel }}">{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.tel }}</a></td>
			</tr>
			<tr>
				<td>Email</td>
				<td><a href="mailto:{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.email }}">{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.email }}</a></td>
			</tr>
			<tr *ngIf="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype=='producer'">
				<td>Producer Unit Code (PUC)</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.farmerpuc }}</td>
			</tr>
			<tr *ngIf="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype=='producer'">
				<td>GLOBAL G.A.P. Certified?</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.globalgap | yesno }}</td>
			</tr>
			<tr>
				<td>Website</td>
				<td><a href="{{ (this.tableService['companies'][this.tableService.clickedIndex['companies']]?.website==null ? null : ( this.tableService['companies'][this.tableService.clickedIndex['companies']]?.website.substr(0,4)=='http' ? this.tableService['companies'][this.tableService.clickedIndex['companies']]?.website : 'https://' + this.tableService['companies'][this.tableService.clickedIndex['companies']]?.website )) }}" target="_blank">{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.website }}</a></td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			
			<ng-template [ngIf]="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype=='producer'">	
				<ng-container *ngFor="let cultivar of this.tableService['companies'][this.tableService.clickedIndex['companies']]?.cultivars">
					<tr>
						<td>Cultivar</td>
						<td>{{ cultivar }}</td>
					</tr>
				</ng-container>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr *ngIf="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype=='producer'">
					<td>Hectarage</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.hectarage | dashblank }}</td>
				</tr>
			</ng-template>
			
			<tr>
				<td>Notes</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.notes }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
	<ng-template [ngIf]="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.children.length>0">
		<br>
		<h4>Child Companies:</h4>
		<table class="table col-sm">
			<tbody>
				<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let child of this.tableService['companies'][this.tableService.clickedIndex['companies']]?.children">
						<tr>
							<td>Child</td>
							<td><a (click)="viewCompany(child.idchild)">{{ child.companyname }}</a></td>
						</tr>
					</ng-container>
				<tr class="tableseperator"><td></td><td></td></tr>
			</tbody>
		</table>
	</ng-template>
	<br>
	<h4>Address:</h4>
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Address</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.address }}</td>
			</tr>
			<tr>
				<td>Area</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.area }}</td>
			</tr>
			<tr>
				<td>PO Box</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pobox }}</td>
			</tr>
			<tr>
				<td>Post Code</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.postcode }}</td>
			</tr>
			<tr>
				<td>State</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.state }}</td>
			</tr>
			<tr>
				<td>Country</td>
				<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.country }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
	<br>
	<h4>Banking Details:</h4>
	<table class="table col-sm">
		<tbody>
			<ng-template [ngIf]="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.billingname=='parent' && this.tableService['companies'][this.tableService.clickedIndex['companies']]?.parent!=this.tableService['companies'][this.tableService.clickedIndex['companies']]?.idcompany">
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Bill Parent</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.parentname }}</td>
				</tr>
				<tr>
					<td>Parent: VAT</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pvat }}</td>
				</tr>
				<tr>
					<td>Parent: Registration Number</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pregnumber }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Parent: Bank Name</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pbankname }}</td>
				</tr>
				<tr>
					<td>Parent: Bank Account Number</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pbankaccount }}</td>
				</tr>
				<tr>
					<td>Parent: Bank Sort Code</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.bpanksortcode }}</td>
				</tr>
				<tr>
					<td>Parent: Bank IBAN</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pbankiban }}</td>
				</tr>
				<tr>
					<td>Parent: Bank SWIFT</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.pbankswift }}</td>
				</tr>
			</ng-template>
			<ng-template [ngIf]="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.billingname=='child' || this.tableService['companies'][this.tableService.clickedIndex['companies']]?.parent==this.tableService['companies'][this.tableService.clickedIndex['companies']]?.idcompany">
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>VAT</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.vat }}</td>
				</tr>
				<tr>
					<td>Registration Number</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.regnumber }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Bank Name</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.bankname }}</td>
				</tr>
				<tr>
					<td>Bank Account Number</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.bankaccount }}</td>
				</tr>
				<tr>
					<td>Bank Sort Code</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.banksortcode }}</td>
				</tr>
				<tr>
					<td>Bank IBAN</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.bankiban }}</td>
				</tr>
				<tr>
					<td>Bank SWIFT</td>
					<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.bankswift }}</td>
				</tr>
			</ng-template>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
		<ng-template [ngIf]="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype=='producer'">
			<br>
			<h4>Pricing:</h4>
			<table class="table col-sm">
				<tbody>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>Price Model</td>
						<td>{{ this.tableService['companies'][this.tableService.clickedIndex['companies']]?.model }}</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let payterm of this.tableService.epayday; let i = index;">
						<tr>
							<td>Estimate Payment No. {{ i + 1 }}</td>
							<td>{{ this.tableService.epaypercent[i] | percent }} of Estimate : {{ payterm }}</td>
						</tr>
					</ng-container>
					<tr *ngIf="this.tableService.epayday!==null" class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let payterm of this.tableService.payday; let i = index;">
						<tr>
							<td>Payment No. {{ i + 1 }}</td>
							<td>{{ this.tableService.paypercent[i] | percent }} of Total : {{ payterm }}</td>
						</tr>
					</ng-container>
					<ng-container *ngIf="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.priceseason==this.loginService.season && isAuthorised('finance')">
						<tr class="tableseperator"><td></td><td></td></tr>
						<tr>
							<td class="middleText">Re-assign price model to ALL deliveries this season</td>
							<td><button mdbBtn class="btn btn-info" (click)="reassignAll()">Re-asssign ALL {{ this.loginService.season }} deliveries</button></td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-template>
		<ng-container *ngIf="this.tableService['companies'][this.tableService.clickedIndex['companies']]?.companytype == 'buyer' && this.tableService['companies'][this.tableService.clickedIndex['companies']]?.orders!=undefined">
			<br>
			<h4>Orders:</h4>
			<table class="table col-sm">
				<tbody>
					<ng-container *ngFor="let order of this.tableService['companies'][this.tableService.clickedIndex['companies']]?.orders; let orderIndex=index;">
						<tr class="tableseperator" *ngIf="( orderIndex>0 ? ( order.season==this.tableService['companies'][this.tableService.clickedIndex['companies']]?.orders[orderIndex-1]['season'] ? false : true ) : true )"><td></td><td></td></tr>
						<tr>
							<td>{{ ( ( orderIndex>0 ? ( order.season==this.tableService['companies'][this.tableService.clickedIndex['companies']]?.orders[orderIndex-1]['season'] ? false : true ) : true ) ? order.season : '' ) }}</td>
							<td><a (click)="viewOrder(order.orderref, order.season)">{{ order.orderref }}{{ ( order.ordernumber==null ? '' : ' (' + order.ordernumber + ')' ) }} - Quantity: {{ +order.totalquantity | number:'1.0-0' }} - {{ (order.orderstatus=='dispatched' ? order.orderstatus + ': ' : order.orderstatus ) | titlecase }}{{ ( order.dispatchdate==null ? '' : order.dispatchdate ) | date:'MMM d, EEE, y' }}</a></td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-container>
	<br><br>
</div>