<div class="container">
	<h3>Remittance for Delivery {{ this.tableService.selected?.iddelivery | delivery }} ({{ (+this.tableService.selected?.selectout==1 ? 'Select-Outs' : (+this.tableService.selected?.smalldel==1 ? 'Smalls' : 'Standard' )) }}):</h3>
	<br>
	<table class="table col-sm">
		<tbody>
			<tr class='tableseperator'><td></td><td></td></tr>
			<tr>
				<td>Cultivar</td>
				<td>{{ this.tableService.selected?.cultivar }}</td>
			</tr>
			<tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td>Total WNIS (kg)</td>
				<td>{{ +this.tableService.selected?.wnis | number:'1.2-2' }} kg</td>
			</tr>
			<tr>
				<td>WNIS (kg) excl. Smalls</td>
				<td>{{ (+this.tableService.selected?.wnis > 0 ? +this.tableService.selected?.wnis - +this.tableService.selected?.smalls : 0) | number:'1.2-2' }} kg</td>
			</tr>
			<tr>
				<td>Smalls (kg)</td>
				<td>{{ +this.tableService.selected?.smalls | number:'1.2-2' }} kg</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td>Total DNIS (kg) @ {{ this.tableService.selected?.dryfloorlevel | percent:'1.1' }}</td>
				<td>{{ (+this.tableService.selected?.fees.dnis + +this.tableService.selected?.fees.smallsdnis ) | number:'1.2-2' }} kg</td>
			</tr>
			<tr>
				<td>DNIS (kg) @ {{ this.tableService.selected?.dryfloorlevel | percent:'1.1' }}, excl. Smalls</td>
				<td>{{ +this.tableService.selected?.fees.dnis | number:'1.2-2' }} kg</td>
			</tr>
			<tr>
				<td>Smalls DNIS (kg) @ {{ this.tableService.selected?.dryfloorlevel | percent:'1.1' }}</td>
				<td>{{ +this.tableService.selected?.fees.smallsdnis | number:'1.2-2' }} kg</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td>Moisture - Whole Nut (%)</td>
				<td>{{ +this.tableService.selected?.moisture | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Moisture - Kernel (%)</td>
				<td>{{ +this.tableService.selected?.wetkernel | percent:'1.2' }}</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td>SKR (%)</td>
				<td>{{ +this.tableService.selected?.tskr | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>USKR (%)</td>
				<td>{{ +this.tableService.selected?.usk | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Darks (%)</td>
				<td>{{ +this.tableService.selected?.dkr | percent:'1.2' }}</td>
			</tr>
			<tr>
				<td>Collected?</td>
				<td>{{ ( +this.tableService.selected?.collected ? "Yes" : "No" ) }}</td>
			</tr>
			<tr>
				<td>FX Rate</td>
				<td>{{ +this.tableService.selected?.fees.fxrate | number:'1.3-3' }}</td>
			</tr>
			<ng-container *ngIf="this.tableService.selected?.fees.modeldnissizes">
				<tr class='tableseperator'><td></td><td></td></tr>
				<tr>
					<td>DNIS >25mm</td>
					<td>{{ +this.tableService.selected?.fees.sizepercents.dnis25 | percent:'1.2' }} @ {{ +this.tableService.selected?.fees.sizepricesinv.dnis25 | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
				</tr>
				<tr>
					<td>DNIS >22mm</td>
					<td>{{ +this.tableService.selected?.fees.sizepercents.dnis22 | percent:'1.2' }} @ {{ +this.tableService.selected?.fees.sizepricesinv.dnis22 | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
				</tr>
				<tr>
					<td>DNIS >20mm</td>
					<td>{{ +this.tableService.selected?.fees.sizepercents.dnis20 | percent:'1.2' }} @ {{ +this.tableService.selected?.fees.sizepricesinv.dnis20 | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
				</tr>
				<tr>
					<td>DNIS >18mm</td>
					<td>{{ +this.tableService.selected?.fees.sizepercents.dnis18 | percent:'1.2' }} @ {{ +this.tableService.selected?.fees.sizepricesinv.dnis18 | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
				</tr>
			</ng-container>
			<tr class='tableseperator'><td></td><td></td></tr>
			<tr>
				<td>SK Gross Price / kg of DNIS</td>
				<td>{{ +this.tableService.selected?.fees.gdnisprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.cultivarbonus > 0">
				<td>Cultivar/ kg of DNIS</td>
				<td>{{ +this.tableService.selected?.fees.cultivarbonus | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.darkprice > 0">
				<td>Darks Price / kg of DNIS</td>
				<td>{{ (+this.tableService.selected?.fees.dnis>0 ? +this.tableService.selected?.fees.darkprice / +this.tableService.selected?.fees.dnis : 0) | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.smallsprice">
				<td>Smalls / kg of DNIS Smalls</td>
				<td>{{ (+this.tableService.selected?.fees.dnis>0 ? +this.tableService.selected?.fees.smallsprice / +this.tableService.selected?.fees.dnis : 0) | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.selectoutprice > 0">
				<td>Select-outs / kg of DNIS</td>
				<td>{{ (+this.tableService.selected?.fees.dnis>0 ? +this.tableService.selected?.fees.selectoutprice / +this.tableService.selected?.fees.dnis : 0) | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td class="pl-4">Gross SK Price</td>
				<td class="pl-4">{{ +this.tableService.selected?.fees.grossprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.darkprice > 0">
				<td class="pl-4">Gross Darks Price</td>
				<td class="pl-4">{{ +this.tableService.selected?.fees.darkprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.smallsrate">
				<td class="pl-4">Gross Smalls Price</td>
				<td class="pl-4">{{ +this.tableService.selected?.fees.smallsprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.selectoutrate > 0">
				<td class="pl-4">Gross Select-outs Price</td>
				<td class="pl-4">{{ +this.tableService.selected?.fees.selectoutprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td>Total Gross Price</td>
				<td>{{ +this.tableService.selected?.fees.totalgrossprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr class='tableseperator'><td></td><td></td></tr>
			<tr>
				<td class="pl-4">Drying Fee</td>
				<td class="pl-4 negnumber">({{ +this.tableService.selected?.fees.dryfee | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr>
				<td class="pl-4">Process Fee</td>
				<td class="pl-4 negnumber">({{ +this.tableService.selected?.fees.processfee | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr>
				<td class="pl-4">Marketing Fee</td>
				<td class="pl-4 negnumber">({{ +this.tableService.selected?.fees.marketingfee | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr>
				<td class="pl-4">Transport Fee</td>
				<td class="pl-4 negnumber">({{ +this.tableService.selected?.fees.transportfee | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr>
				<td class="pl-4" *ngIf="+this.tableService.selected?.fees.vatable == 1">SAMAC Fee ({{ +this.tableService.selected?.fees.samacrate | currency:'ZAR':'symbol-narrow' }}) ex VAT</td>
				<td class="pl-4" *ngIf="+this.tableService.selected?.fees.vatable != 1">SAMAC Fee ({{ +this.tableService.selected?.fees.samacrate | currency:'ZAR':'symbol-narrow' }}) inc VAT</td>
				<td class="pl-4 negnumber">({{ +this.tableService.selected?.fees.samacfee | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr *ngIf="+this.tableService.selected?.fees.mrlfee>0">
				<td class="pl-4" *ngIf="+this.tableService.selected?.fees.vatable == 1">MRL Test (ex VAT)</td>
				<td class="pl-4" *ngIf="+this.tableService.selected?.fees.vatable != 1">MRL Test (inc VAT)</td>
				<td class="pl-4 negnumber">({{ +this.tableService.selected?.fees.mrlfee | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr>
				<td>Total Fees</td>
				<td class="negnumber">({{ +this.tableService.selected?.fees.netfees | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }})</td>
			</tr>
			<tr class='tableseperator'><td></td><td></td></tr>
			<tr>
				<td>Net Price</td>
				<td [class.negnumber]="+this.tableService.selected?.fees.netprice<0">{{ +this.tableService.selected?.fees.netprice | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr *ngIf="this.tableService.selected?.fees.vatable==1">
				<td class="pl-4">VAT Payable</td>
				<td class="pl-4" [class.negnumber]="+this.tableService.selected?.fees.vatpayable<0">{{ +this.tableService.selected?.fees.vatpayable | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr class='tableseperatorlight'><td></td><td></td></tr>
			<tr *ngIf="this.tableService.selected?.fees.vatable==1">
				<td>Net Price (inc. VAT)</td>
				<td [class.negnumber]="+this.tableService.selected?.fees.netvat<0">
					{{ +this.tableService.selected?.fees.netvat | currency:this.tableService.selected?.fees.invcurrency:'symbol-narrow' }}
				</td>
			</tr>
			<tr class='tableseperator'><td></td><td></td></tr>
		</tbody>
	</table>
	<br><br>
</div>