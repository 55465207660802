import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md';
import * as moment from 'moment';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../../overview/table/table.service';
import { DropDownDataService } from '../dropdown.service';
import { AddBookingService } from './add-booking.service';

@Component({
  selector: 'app-booking',
  templateUrl: './add-booking.component.html',
  styleUrls: ['./add-booking.component.scss']
})
export class AddBookingComponent implements OnInit {

  constructor(
  	public loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public addBookingService: AddBookingService,
    public tableService: TableDataService,
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.addBookingService.date = this.addBookingService.d.toISOString().substring(0,10);
    this.dropDownDataService.getDropdown("bookingList");
    this.dropDownDataService.getDropdown("locationList");
    this.dropDownDataService.getDropdown("producerList");
    this.dropDownDataService.getDropdown("cultivarList");
    this.addBookingService.loadVeh();
  }

  ngOnDestroy() {
    this.addBookingService.editmode = false;
    this.addBookingService.resetForm();
    this.tableService.loadData('bookings');
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        this.addBookingService.date !== undefined &&
        this.addBookingService.time !== undefined &&
        this.addBookingService.producer !== undefined &&
        this.addBookingService.load > 0
      ) {
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.addBookingService.resetForm();
    this.addBookingService.editmode = state;
  }

  editSelected() {
    return (this.addBookingService.editmode == false || (this.addBookingService.editmode == true && this.addBookingService.idb!=null));
  }

  async onCalculate() {
    if (this.canSave()) {
      await this.addBookingService.addBooking();
      window.scroll(0,0);
      this.addBookingService.resetForm();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete this booking?");
    if (res) {
      this.addBookingService.deleteEntry();
      this.addBookingService.resetForm();
    }
  }

  dateChange() {
    this.addBookingService.time = null;
    this.addBookingService.d = new Date(Date.parse(this.addBookingService.date));
    this.addBookingService.date = moment(this.addBookingService.date).format('YYYY-MM-DD');
    this.addBookingService.loadVeh();
  }

  transTime(time) {
    var t = '';
    if (time>=60) {
      t = Math.floor(time/60) + ' hour' + (time>120 ? 's' : '');
    }
    t = (t.length>0 ? t + ' ' : '') + (time - Math.floor(time/60)*60 == 0 ? '' : (time - Math.floor(time/60)*60) + ' mins');
    return t;
  }

  toggleday(day) {
    this.addBookingService[day.slice(0)] = !this.addBookingService[day.slice(0)];
    this.addBookingService.setWeekdays();
    this.addBookingService.checkClashes();
  }

}