<app-logisticsheader (featureSelected)="onNavigate($event)"></app-logisticsheader>
<div class="container-fluid px-0">
  <div class="row mx-0">
    <div class="col px-0">
    	<div class="container row mx-auto px-0">
      	<router-outlet></router-outlet>
      </div>
    </div>
  </div>
	<app-footer></app-footer>
</div>