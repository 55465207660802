import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { StorageService } from './storage.service';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public dropDownDataService: DropDownDataService,
    public settingsService: StorageService,
    public portalService: PortalService
    ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.settingsService.editmode = false;
    this.settingsService.loadStorage();
  }

  ngOnDestroy() {
    this.settingsService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  editModeOn(state) {
    this.settingsService.resetForm(state);
    this.settingsService.loadStorage();
    this.settingsService.editmode = state;
  }


}