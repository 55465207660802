import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfirmationService } from 'primeng/api';
import { Memberships } from './login/teamlogin.interface';
import { PortalService } from '../../auth/portal.service';

declare var require: any;

@Injectable()
export class TeamAuthService{
	private d: Date;
	private timeNow: Date;
	public httpRoot: string;
	loggedOut: boolean = true;
	expiration = -1;
	expcount: number;
	selectedmember: string;
	expTimeout = null;
	timeLeft = null;
	logOutMsg = null;
	dialog = false;
	uname: string;

	constructor(
		private router: Router,
		private portalService: PortalService,
		private http: HttpClient,
    private confirmationService: ConfirmationService
		) {}

	setSession(authResult, uname, httpRoot) {
		this.uname = uname;
		this.httpRoot = httpRoot
		this.logOutMsg = null;
		this.dialog = false;
		var session = {"status": false, "uname": uname};
		if (authResult != undefined) {
			if (authResult.response != undefined) {
		    if (authResult.response.result.token != null) {
		    	this.d = new Date();
				  this.expiration = Math.floor((+authResult.response.result.exp - +authResult.response.result.iat) * 1000 + this.d.getTime());
					session.uname = (authResult.response.result.uname == null ? session.uname : authResult.response.result.uname );
				  localStorage.setItem('team_id_token', authResult.response.result.token);
				  localStorage.setItem('team_memberships', JSON.stringify(authResult.response.result.ms));
				  session.status = true;
					if (this.expTimeout!=null) {clearTimeout( this.expTimeout); }
					if (uname!==undefined) {
						var d = new Date();
						var exp = this.expiration - d.getTime();
						this.expcount = 20*1000;
						this.expTimeout = setTimeout(() => {
							if (this.logOutMsg==null) {
								var secsleft = 20;
								this.timeLeft = setInterval(async() => {
									secsleft = await Math.floor(this.expcount/1000);
									this.expcount = await this.expcount - 1000;
									this.dialog = true;
									var logOutMsg = await this.confirmBool("You will be logged out in " + secsleft + " seconds. Do you want to stay logged in?");		
									if (this.expcount<0) {
										clearInterval(this.timeLeft);
									}
									if (logOutMsg) {
										this.refreshLogin();
									} else if (!logOutMsg || this.expcount < 0) {
										this.logOut();
									}	
								},1000);
							}							
						},exp-20*1000);
					}
				} else {
					session.status = false;
				}
			}
		}
		return session;
	}

	confirmBool(message): Promise<boolean> {
    return new Promise(resolve => {
      this.confirmationService.confirm({
        message,
        header: "Stay Logged In?",
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
        key: "logOutTimer"
      })
    });
  }

	getExpiration(expiration) {
		this.timeNow = new Date();
		const timeLeft = expiration - this.timeNow.getTime();
		return timeLeft;
	}

  isAuthenticated(option = null) {
    if (option==null) {
      return this.isAuth();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
    	return this.isAuth();
    } return false;
  }

	public isAuth() {
		if (typeof this.selectedmember !== 'undefined') {
			return this.getExpiration(this.expiration) > 0;
		}
		return false;
	}

	public isAuthorised(permission: string) {
		if (localStorage.getItem('team_memberships') != '[]') {
			var memberships = JSON.parse(localStorage.getItem('team_memberships'));
			if (memberships!=null) {
				for (var i = 0; i < memberships.length; i++) {
					if (memberships[i].membership == permission) {
						return true;
					}
				}
			}
		}
		return false;
	}

	firstMembership() {
		var member = {"status": false, "selectedmember": null, "memberships": []};
		if (localStorage.getItem('team_memberships') != '[]' && localStorage.getItem('team_memberships') != null) {
			var memberships: Memberships [] = JSON.parse(localStorage.getItem('team_memberships'));
			this.selectedmember = JSON.parse(localStorage.getItem('team_memberships'))[0].membership;
			member = {"status": true, "selectedmember": this.selectedmember, "memberships": memberships}
		} 
		return member;
	}

	logOut() {
		clearInterval(this.timeLeft);
		this.dialog = false;
		this.loggedOut = true;
		localStorage.removeItem('team_id_token');
		localStorage.removeItem('team_memberships');
		this.expiration = -1;
		this.router.navigate(['/team/login']);
	}

  refreshLogin() {
		clearInterval(this.timeLeft);
		var postBody = {
			"name": "freshLogin",
			"param": {"uname": this.uname}
		};
		return this.http.post(
			this.httpRoot,
			postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.setSession(response, this.uname, this.httpRoot);
        },
        (error) => console.log(error)
      );
	}

}