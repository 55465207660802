import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { GeneralSettingsService } from './general.service';

@Component({
  selector: 'app-generalsettings',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public dropDownDataService: DropDownDataService,
    public settingsService: GeneralSettingsService,
    public portalService: PortalService
    ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.settingsService.loadForEdit();
  }

  ngOnDestroy() {
    this.settingsService.resetForm();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  subsettingName(setting) {
    var ans;
    switch (setting) {
      case "Client Resample Request":
        ans = 'Days Valid';
        break;
      case "Client Invoice Generator":
        ans = 'Days Before Payment';
        break;
      default:
        ans = 'Value';
        break;
    }
    return ans;
  }

  palletSettings() {
    var ans = false;
    if (this.settingsService.settings!=undefined) {
      for (var i = 0; i < this.settingsService.settings.length; i++) {
        if (this.settingsService.settings[i]['setting']=='Pallet Sampling' && this.settingsService.settings[i]['value']==1) {
          ans = true;
        }
      }
    }
    return ans;
  }

}