import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { Cultivars } from './cultivars.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class CultivarService{
	private postBody: {};
	httpRes: any;
	public cultivar: Cultivars = {"current": [], "archived": []};
	public editmode: boolean = false;
	public loadedEntry: any;
	public location: string = '1';
	request: string;
	public saving: boolean = false;
	public index: number;
	public idcultivar: string;
	public name: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService,
		public dropDownDataService: DropDownDataService) {}

	saveType() {
		this.cultivar['current'].splice(0, 0, {"name": '', "idcultivar": 0});
	}

	renameItem(index: number) {
		this.index = index;
		this.request = 'renameCultivar';
		this.editCultivar(index);
	}

	archiveItem(index: number) {
		this.index = index;
		this.request = 'archiveCultivar';
		this.editCultivar(index);
	}

	unarchiveItem(index: number) {
		this.index = index;
		this.request = 'unarchiveCultivar';
		this.editCultivar(index);
	}

	addItem() {
		this.cultivar['current'].splice(0, 0, {"name": '', "idcultivar": 0});
	}

	saveItem(index: number) {
		this.index = index;
		this.request = 'saveCultivar';
		this.editCultivar(index);
	}

	deleteItem(index: number) {
    this.cultivar['current'].splice(index,1);
	}

	deleteSavedItem(index: number) {
		this.index = index;
		this.request = 'deleteCultivar';
		this.editCultivar(index);
	}

	loadCultivar() {
		this.postBody = {
			"name": "loadCultivar",
			"param":{
				"location": this.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.cultivar = response['response']['result']['reply'];
        },
        (error) => console.log(error)
      );
	}

	editCultivar(index: number) {
		this.saving = true;
		if (this.editmode) {
			this.idcultivar = this.cultivar['archived'][index]['idcultivar'];
			this.name = this.cultivar['archived'][index]['name'];
		} else {
			this.idcultivar = this.cultivar['current'][index]['idcultivar'];
			this.name = this.cultivar['current'][index]['name'];
		}
		this.postBody = {
			"name": this.request,
			"param":{
				"location": this.location,
		    "idcultivar": this.idcultivar,
		    "name": this.name
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
        		if (this.request=='archiveCultivar' || this.request=='deleteCultivar') {
        			this.cultivar['current'].splice(index,1);
        		}
        		if (this.request=='unarchiveCultivar') {
        			this.cultivar['archived'].splice(index,1);
        		}
			      this.index = null;
			    	this.saving = false;
			  		this.request = null;
			    	this.idcultivar = null;
	        	this.loadCultivar();
	        } catch(e) {
			    	this.saving = false;
			      this.index = null;
			  		this.request = null;
			    	this.idcultivar = null;
	        }
        },
        (error) => console.log(error)
      );
	}


  resetForm(keepRef: boolean = false) {
    this.editmode = false;
  }



}