import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../auth/login/teamlogin.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class SchedulingService{
	private postBody: {};
	httpRes: any;
	public query: string;

	constructor(
		private http: HttpClient,
		public loginService: TeamLoginService) {}

	runQuery() {
		this.loginService.toast("Success","Query run");
	}

	clearQuery() {
		this.resetForm();
	}

  resetForm() {
    window.scroll(0,0);
    this.query = null;
  }

}