<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3">
				<h2>Query Database</h2>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container w-100">

			  <mat-form-field class="w-100">
			    <textarea matInput rows="5" placeholder="Query" [(ngModel)]="this.queryService.query"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="this.editSelected()">

			<button mdbBtn class="btn btn-primary" (click)="this.queryService.runQuery()">Run Query</button>

			<button mdbBtn class="btn btn-warning" (click)="this.queryService.clearQuery()">Clear</button>

			<br><br>
		</div>
	</div>
</div>