<div *ngIf="isAuthenticated()" class="container">
	<br>
	<div class="row mt-3">
		<div class="col">
			<h2>
				<div class="dropdown" dropdown>
	        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-0" mdbWavesEffect>
	        Inventory - {{ this.inventoryService.inventorytype }}<span class="caret"></span></a>
	        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
	          <button 
	          	class="dropdown-item waves-light py-3" 
	          	mdbWavesEffect 
	          	(click)="setInventory('Production to Date')">Production to Date</button>
	          <button 
	          	class="dropdown-item waves-light py-3" 
	          	mdbWavesEffect 
	          	(click)="setInventory('In Stock')">In Stock</button>
	          <button 
	          	class="dropdown-item waves-light py-3" 
	          	mdbWavesEffect 
	          	(click)="setInventory('Dispatched')">Dispatched</button>
	        </ul>
	      </div>
			</h2>
			<br>
			<div>
				<div class="row justify-content-between">
					<div class="col main-header">
						<h3>
							<div class="dropdown" dropdown>
				        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-0" mdbWavesEffect>
				        Category: {{ this.inventoryService.grouptype }}<span class="caret"></span></a>
				        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('All')">All</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Premium')">Premium</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Commercial')">Commercial</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Nut-in-Shell')">Nut-in-Shell</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Retail')">Retail</button>
				        </ul>
				      </div>
						</h3>
					</div>
					<div class="mr-4 ml-3 col-auto">
						<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
					</div>
				</div>
			</div>
			<!--
			<div>
				<div class="row justify-content-between">
					<div class="col">
						<h2>Inventory</h2>
					</div>
					<div class="mr-4 ml-3">
						<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
					</div>
				</div>
			</div>
			-->
			<mat-form-field class="filter-wide">
			  <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter">
			</mat-form-field>
			<div class="example-container mat-elevation-z8">
				<div class="example-loading-shade"
			       *ngIf="isLoadingResults">
			    <mat-spinner *ngIf="isLoadingResults" color="accent"></mat-spinner>
			  </div>
				<div class="example-table-container">
				  <table mat-table matSort [dataSource]="datasrc" hover="true" class="example-table">
				  	<ng-container matColumnDef="_batchdate" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pack Date </th>
				      <td mat-cell *matCellDef="let element"> {{ element.batchdate | date:'MMM d, EEE' }} </td>
				      <td mat-footer-cell *matFooterCellDef> Total </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="stotal" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Premium Total </th>
				      <td mat-cell *matCellDef="let element"> {{ element.stotal | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('stotal') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="ctotal" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commercial Total </th>
				      <td mat-cell *matCellDef="let element"> {{ element.ctotal | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('ctotal') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="nistotal" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS Total </th>
				      <td mat-cell *matCellDef="let element"> {{ element.nistotal | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('nistotal') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='r'" matColumnDef="rtotal" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Retail Total </th>
				      <td mat-cell *matCellDef="let element"> {{ element.rtotal | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('rtotal') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				   <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s0">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S0 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s0 | number:'1.0-0' | dashblank:' boxes' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s0') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s1l">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S1L </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s1l | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s1l') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s1">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S1 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s1 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s1') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s1s">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S1s </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s1s | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s1s') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s2">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S2 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s2 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s2') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s4l">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S4L </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4l | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s4l') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s4">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S4 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s4') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s4r">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S4R </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4r | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s4r') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s4ar">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S4AR </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4ar | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s4ar') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s4s">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S4S </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s4s | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s4s') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s5">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S5 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s5 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s5') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s6">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S6 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s6 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s6') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s7">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S7 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s7 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s7') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='p'" matColumnDef="s8">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> S8 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.s8 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('s8') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="com">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> COM </th>
				      <td mat-cell *matCellDef="let element"> {{ element.com | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('com') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				   <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c0">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C0 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c0 | number:'1.0-0' | dashblank:' boxes' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c0') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c1l">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C1L </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c1l | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c1l') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c1">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C1 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c1 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c1') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c1s">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C1s </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c1s | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c1s') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c2">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C2 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c2 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c2') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c4l">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C4L </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c4l | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c4l') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c4">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C4 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c4 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c4') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c4r">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C4R </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c4r | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c4r') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c4s">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C4S </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c4s | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c4s') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c5">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C5 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c5 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c5') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c6">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C6 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c6 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c6') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c7">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C7 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c7 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c7') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="c8">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> C8 </th>
				      <td mat-cell *matCellDef="let element"> {{ element.c8 | number:'1.0-0' | dashblank:' boxes' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('c8') | number:'1.0-0' | dashblank:' boxes' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="oil">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Oil Grade </th>
				      <td mat-cell *matCellDef="let element"> {{ element.oil | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('oil') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="oia">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Oil Grade A</th>
				      <td mat-cell *matCellDef="let element"> {{ element.oia | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('oia') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="oib">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Oil Grade B</th>
				      <td mat-cell *matCellDef="let element"> {{ element.oib | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('oib') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="oic">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Oil Grade C</th>
				      <td mat-cell *matCellDef="let element"> {{ element.oic | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('oic') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='c'" matColumnDef="oim">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Oil Grade Meal </th>
				      <td mat-cell *matCellDef="let element"> {{ element.oim | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('oim') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='r'" matColumnDef="bch">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Bio Char </th>
				      <td mat-cell *matCellDef="let element"> {{ element.bch | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('bch') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n18">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 18mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n18 | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n18') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n20">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 20mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n20 | number:'1.0-0' | dashblank:' kg' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n20') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n22">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 22mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n22 | number:'1.0-0' | dashblank:' kg' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n22') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n24">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 24mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n24 | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n24') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n25">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 25mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n25 | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n25') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n26">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 24mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n26 | number:'1.0-0' | dashblank:' kg' }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n26') | number:'1.0-0' | dashblank:' kg' }} </td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n18bags">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 18mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n18count | number:'1.0-0' | dashblank:' bags' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n18count') | number:'1.0-0' | dashblank:' bags'}}</td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n20bags">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 20mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n20count | number:'1.0-0' | dashblank:' bags' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n20count') | number:'1.0-0' | dashblank:' bags'}}</td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n22bags">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 22mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n22count | number:'1.0-0' | dashblank:' bags' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n22count') | number:'1.0-0' | dashblank:' bags'}}</td>
				    </ng-container>
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n24bags">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 24mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n24count | number:'1.0-0' | dashblank:' bags' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n24count') | number:'1.0-0' | dashblank:' bags'}}</td>
				    </ng-container>	
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n25bags">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 25mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n24count | number:'1.0-0' | dashblank:' bags' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n24count') | number:'1.0-0' | dashblank:' bags'}}</td>
				    </ng-container>				   
				    <ng-container *ngIf="this.inventoryService.grouptype.substr(0,1).toLowerCase()=='a' || this.inventoryService.grouptype.substr(0,1).toLowerCase()=='n'" matColumnDef="n26bags">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> NIS > 26mm </th>
				      <td mat-cell *matCellDef="let element"> {{ element.n26count | number:'1.0-0' | dashblank:' bags' }}</td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('n26count') | number:'1.0-0' | dashblank:' bags'}}</td>
				    </ng-container>				   
				    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				    <tr mat-row 
				    	*matRowDef="let row; let i = index; columns: displayedColumns;" 
				    	(click)="onSelectRow(i)"
				    	style="cursor: pointer"
				    	class="example-element-row"></tr>
	 					<tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="example-first-footer-row" ></tr>
				  </table>
				</div>
			</div>
			<br>
			<br>
			
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>