<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>

	<div [ngClass]="logInState">
		<div [ngClass]="classState">
			<div class="my-4 col">
				<h2>QR - Dispatch</h2>
			</div>
			
			<div class="row justify-content-md mx-1">	
				<div class="col-12 col-md-12 col-lg-8">
					<div class="form-container">	
						<mat-form-field>
					    <mat-select placeholder="Select Order Reference" [(ngModel)]="this.teamQRService.orderref" (keydown.tab)="loadForLogging()">
					      <mat-option *ngFor="let order of this.dropDownDataService.dropdown.dispatchStyles" (click)="loadForLogging()" value="{{ order.orderref }}">{{ order.orderref }} [ {{ (order.ordernumber=='' ? '* Not assigned *' : order.ordernumber) }} ] - {{ order.buyer }}</mat-option>
					    </mat-select>
					  </mat-form-field>
					</div>
				</div>

				<div class="my-4 col-12 col-md-12 col-lg-8" [ngClass]="showScanner">
					<div>
						<h4>Summary</h4>
					</div>
					<table class="my-4 table">
						<tbody>
							<tr class="tableseperator"><td></td><td></td></tr>
							<tr>
								<td>Total Scanned</td>
								<td>{{ ( this.teamQRService.dispatchCodes[0]['code'] == "" ? 0 : this.teamQRService.dispatchCodes.length ) }}</td>
							</tr>
							<tr class="tableseperator"><td></td><td></td></tr>
						</tbody>
					</table>

					<app-scanner #scanner
						(scanSuccess)="handleQrCodeResult($event)">
					</app-scanner>

					<div class="col pt-4 mx-0 px-0 text-center row justify-content-between">
						<button mdbBtn class="btn btn-primary mb-2 ms-0" style="width:100%; max-width:200px; margin: auto auto;" [disabled]="!this.teamQRService.canDispatch()" (click)="this.teamQRService.saveToOrder()">Log to Order{{ ( this.teamQRService.dispatchCodes[0]['code'] == "" ? null : ' (' + this.teamQRService.dispatchCodes.length + ')' ) }}</button><br>
						<button mdbBtn class="btn btn-warning mb-2 mx-0 px-0" style="width:100%; max-width:200px;" (click)="reset()">Reset</button>
					</div>
						
					<hr>

					<h4 class="my-4">Boxes Remaining for Order</h4>
					<table class="my-4 table">
						<tbody>
							<tr class="tableseperator"><td></td><td></td><td></td><td></td></tr>
								<tr class="header">
									<td>STYLE</td>
									<td>REQUIRED</td>
									<td>LOADED</td>
									<td>REMAINING</td>
								</tr>
								<tr *ngFor="let count of this.teamQRService.tally">
									<td>{{ count['variety'] }} {{ count['style'] }}</td>
									<td>{{ count['requested'] }}</td>
									<td>{{ count['count'] }}</td>
									<td>{{ count['requested'] - count['count'] }}</td>
								</tr>
							<tr class="tableseperator"><td></td><td></td><td></td><td></td></tr>
						</tbody>
					</table>

					<div class="col py-4 mx-0 px-0 text-center row justify-content-between">
						<button *ngIf="this.teamQRService.dispatchCodes.length>0 && this.teamQRService.dispatchCodes[0]['code']!=''" mdbBtn class="btn btn-success mb-2 mx-0" style="width:50%; max-width:200px;" (click)="this.downloadCSV()">Download CSV</button>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>