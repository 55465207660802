import { Component, OnInit, NgZone } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule, WavesModule, CardsModule } from 'angular-bootstrap-md';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { PortalService } from '../../auth/portal.service';
import { SchedulingService } from './scheduling.service';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {

  constructor(
  	private loginService: TeamLoginService,
    private portalService: PortalService,
    public schedulingService: SchedulingService,
    private zone: NgZone
  	) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.loadChart();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  editSelected() {
  }

  loadChart() {
    this.zone.runOutsideAngular(() => {
      /* Chart code */
      // Themes begin
      // Themes end

      let chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.paddingRight = 30;
      chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";

      let colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.4;

      chart.data = [
        {
          name: "John",
          fromDate: "2018-01-01 08:00",
          toDate: "2018-01-01 10:00",
          color: colorSet.getIndex(0).brighten(0)
        },
        {
          name: "John",
          fromDate: "2018-01-01 12:00",
          toDate: "2018-01-01 15:00",
          color: colorSet.getIndex(0).brighten(0.4)
        },
        {
          name: "John",
          fromDate: "2018-01-01 15:30",
          toDate: "2018-01-01 21:30",
          color: colorSet.getIndex(0).brighten(0.8)
        },

        {
          name: "Jane",
          fromDate: "2018-01-01 09:00",
          toDate: "2018-01-01 12:00",
          color: colorSet.getIndex(2).brighten(0)
        },
        {
          name: "Jane",
          fromDate: "2018-01-01 13:00",
          toDate: "2018-01-01 17:00",
          color: colorSet.getIndex(2).brighten(0.4)
        },

        {
          name: "Peter",
          fromDate: "2018-01-01 11:00",
          toDate: "2018-01-01 16:00",
          color: colorSet.getIndex(4).brighten(0)
        },
        {
          name: "Peter",
          fromDate: "2018-01-01 16:00",
          toDate: "2018-01-01 19:00",
          color: colorSet.getIndex(4).brighten(0.4)
        },

        {
          name: "Melania",
          fromDate: "2018-01-01 16:00",
          toDate: "2018-01-01 20:00",
          color: colorSet.getIndex(6).brighten(0)
        },
        {
          name: "Melania",
          fromDate: "2018-01-01 20:30",
          toDate: "2018-01-01 24:00",
          color: colorSet.getIndex(6).brighten(0.4)
        },

        {
          name: "Donald",
          fromDate: "2018-01-01 13:00",
          toDate: "2018-01-01 24:00",
          color: colorSet.getIndex(8).brighten(0)
        }
      ];

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "name";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.inversed = true;
      categoryAxis.cursorTooltipEnabled = false;

      let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd HH:mm";
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
      dateAxis.max = new Date(2018, 0, 1, 24, 0, 0, 0).getTime();
      dateAxis.strictMinMax = true;
      dateAxis.renderer.tooltipLocation = 0;
      dateAxis.cursorTooltipEnabled = false;



      let series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.width = am4core.percent(80);
      //series1.columns.template.tooltipText = "{name}: {openDateX} - {dateX}";

      series1.dataFields.openDateX = "fromDate";
      series1.dataFields.dateX = "toDate";
      series1.dataFields.categoryY = "name";
      series1.columns.template.propertyFields.fill = "color"; // get color from data
      series1.columns.template.propertyFields.stroke = "color";
      
      /*
      series1.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      series1.columns.template.events.on("hit", function(ev) {
        console.log(ev.target.dataItem['dates']);
      });
      */

      //chart.scrollbarX = new am4core.Scrollbar();
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.disabled = true;
      chart.cursor.lineY.disabled = true;
      chart.cursor.behavior = "none";
      chart.maskBullets = false;


      // series bullet
      var bullet: any = series1.bullets.push(new am4charts.CircleBullet());
      bullet.stroke = am4core.color("#ffffff");
      bullet.strokeWidth = 3;
      bullet.opacity = 0; // initially invisible
      bullet.defaultState.properties.opacity = 0;
      bullet.locationX = 0;
      bullet.circle.radius = 15;
      // resize cursor when over
      bullet.cursorOverStyle = am4core.MouseCursorStyle.horizontalResize;
      bullet.draggable = true;

      // create hover state
      var hoverState = bullet.states.create("hover");
      hoverState.properties.opacity = 1; // visible when hovered


      bullet.events.on("over", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        itemBullet.opacity = 1;
      });
      bullet.events.on("out", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        itemBullet.opacity = 0;
      });

      bullet.events.on("drag", event => {
        handleDrag(event, 'toDate');
      });

      bullet.events.on("dragstop", event => {
        var dataItem = event.target.dataItem;
        handleDrag(event, 'toDate');
        dataItem['column'].isHover = false;
        event.target.isHover = false;
      });

      bullet.events.on("positionchanged", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        var column = dataItem['column'];
        itemBullet.minX = 0;
        itemBullet.maxX = chart.seriesContainer.pixelWidth;
        itemBullet.minY = column.pixelY + column.pixelHeight / 2;
        itemBullet.maxY = itemBullet.minY;
      });

      function handleDrag(event, type) {
        var dataItem = event.target.dataItem;
        // convert coordinate to value
        var value = dateAxis.xToValue(event.target.pixelX);
        var d = new Date(value);
        // set new value
        chart.data[dataItem.index][type] = d;
        // Reload chart data points
        chart.invalidateRawData();
        // make column hover
        dataItem.column.isHover = true;
        // hide tooltip not to interrupt
        dataItem.column.hideTooltip(0);
        // make bullet hovered (as it might hide if mouse moves away)
        event.target.isHover = true;
      }

      // column template
      var columnTemplate = series1.columns.template;
      columnTemplate.column.cornerRadiusTopRight = 8;
      columnTemplate.column.cornerRadiusBottomRight = 8;
      columnTemplate.column.fillOpacity = 0.8;

      // hover state
      var columnHoverState = columnTemplate.column.states.create("hover");
      columnHoverState.properties.fillOpacity = 1;
      // you can change any property on hover state and it will be animated
      columnHoverState.properties.cornerRadiusTopRight = 35;
      columnHoverState.properties.cornerRadiusBottomRight = 35;
      // show bullet when hovered

/*
      columnTemplate.events.on("over", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        itemBullet.isHover = true;
       // itemBullet.opacity = 1;
      });

      // hide bullet when mouse is out
      columnTemplate.events.on("out", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        itemBullet.isHover = false;
        //itemBullet.opacity = 0;
      });

      // start dragging bullet even if we hit on column not just a bullet, this will make it more friendly for touch devices
      columnTemplate.events.on("down", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        itemBullet.dragStart(event.pointer);
      });

      // when columns position changes, adjust minX/maxX of bullets so that we could only dragg vertically
      columnTemplate.events.on("positionchanged", event => {
        var dataItem = event.target.dataItem;
        var itemBullet = dataItem['bullets'].getKey(bullet.uid);
        var column = dataItem['column'];
        itemBullet.minX = 0;
        itemBullet.maxX = chart.seriesContainer.pixelWidth;
        itemBullet.minY = column.pixelY + column.pixelHeight / 2;
        itemBullet.maxY = itemBullet.minY;
      });

*/
      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      columnTemplate.adapter.add("fill", (fill, target) => {
        return chart.colors.getIndex(target.dataItem.index).saturate(0.3);
      });

      bullet.adapter.add("fill", (fill, target) => {
        return chart.colors.getIndex(target.dataItem.index).saturate(0.3);
      });

    });
  }


  

}
