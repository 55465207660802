import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { StockRecallService } from './stockrecall.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-stockrecall',
  templateUrl: './stockrecall.component.html',
  styleUrls: ['./stockrecall.component.scss']
})
export class StockRecallComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public tableService: TableDataService,
    public recallService: StockRecallService
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.recallService.resetForm();
    //this.dropDownDataService.getDropdown("recallList");
    this.dropDownDataService.getDropdown("iterationList");
    this.dropDownDataService.getDropdown("storageListWNIS");
    this.dropDownDataService.getDropdown("locationList");
  }

  ngOnDestroy() {
    this.recallService.idcontact = 0;
    this.recallService.resetForm();
    this.recallService.editmode = false;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        true
      ) {
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.recallService.idcontact = 0;
    this.recallService.resetForm();
    this.recallService.editmode = state;
  }

  editSelected() {
    return (this.recallService.editmode == false || (this.recallService.editmode == true && this.recallService.idcontact > 0));
  }

  onCalculate() {
    if (this.canSave()) {
      this.recallService.addContact();
      window.scroll(0,0);
      this.recallService.resetForm();
      this.dropDownDataService.getDropdown("recallList");
    }
  }

  async onDelete(type = 'delete') {
    const res = await this.loginService.confirm("Are sure you want to " + type + " contact " + this.recallService.firstname + " " + this.recallService.lastname + "?");
    if (res) {
      this.recallService.deleteEntry(type);
      this.recallService.resetForm();
      this.dropDownDataService.getDropdown("recallList");
    }
  }
  
}