import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { AddDeliveryService } from '../logging/add-delivery/add-delivery.service';
import { TableDataService } from '../overview/table/table.service';
import { DropDownDataService } from '../logging/dropdown.service';
import { Location, Storage } from '../../interfaces/dropdown.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class SiloSelectorService{
	private postBody: {};
	httpRes: any;
	d: Date = new Date();
	public stats: string [];
    public isStats: boolean = false;
	public selectedStorage: Storage;
    public deliverysource: any = "0";
    public iddeldepot: string;
    public depotwnisd: number;

	constructor(
		private http: HttpClient,
		private lS: TeamLoginService,
        private aDS: AddDeliveryService,
        private tS: TableDataService,
		private dDDS: DropDownDataService
    ) {}

	saveDelivery() {
    	this.aDS.status = 'draft';
        this.aDS.location = this.dDDS.location['idlocation'];
        this.dDDS.setCompanyContacts(this.aDS.producer);
        this.aDS.thisCompanyContacts = this.dDDS.thisCompanyContacts;
        this.aDS.thisCompanyContact = this.dDDS.thisCompanyContacts[0]['idcontact'];
        this.aDS.deliverydate = this.d.toISOString().substring(0,10);
        this.aDS.deliverytime = this.d.toISOString().substring(11,16);
        this.aDS.delivery = this.dDDS.getNewDeliveryNumber;
        this.aDS.season = +this.d.getFullYear();
        this.aDS.pricemodel = "0";
        this.dDDS.paypercent = "";
        this.dDDS.payday = "";
        this.dDDS.payterms = 'default';
		if (+this.aDS.iddeldepot>0) {
            this.aDS.siloselsave = true;
            this.aDS.iddepot = this.deliverysource;
            this.aDS.saveChanges();
        } else {
            this.aDS.addDelivery();
        }
		this.resetForm();
        this.tS.loadData('deliveries');
	}

    resetForm() {
        window.scroll(0,0);
        this.dDDS.getDropdown("locationList");
        this.dDDS.getDropdown("depotDeliveryList");
        this.iddeldepot = undefined;
        this.deliverysource = "0";
        this.aDS.producer = undefined;
        this.aDS.wnis = 0;
        this.aDS.scalewnis = 0;
        this.aDS.smalls = 0 + '';
        this.aDS.d18 = 0;
        this.aDS.d20 = 0;
        this.aDS.d22 = 0;
        this.aDS.d25 = 0;
        this.aDS.depotwnisd = 0;
        this.aDS.cultivar = undefined;
        this.aDS.cultivars = [];
        this.aDS.storages = [{"idstorage": "", "wnis": null}];
        this.aDS.thisCompanyContacts = undefined;
        this.aDS.thisCompanyContact = undefined;
        this.aDS.delivery = undefined;
        this.aDS.pricemodel = undefined;
        this.dDDS.paypercent = undefined;
        this.dDDS.payday = undefined;
        this.dDDS.payterms = undefined;
        this.aDS.collected = undefined;
        this.aDS.paidCollection = undefined;
        this.aDS.storageOptions = [];
        this.stats = null;
        this.isStats = false;
        this.aDS.siloselsave = false;
    }

    loadForEdit() {
        this.aDS.storages = [{"idstorage": "", "wnis": null}];
        this.stats = null;
        this.isStats = false;
        this.aDS.loadForEdit(true);
    }

  sumwnis() {
        this.aDS.wnis = this.aDS.scalewnis - +this.aDS.smalls;
  }

}