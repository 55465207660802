import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { SettingsClientsService } from '../../settings/clients/clients.service';
import { CountryService } from '../../../services/countries.service';
import { DropDownDataService } from '../dropdown.service';
import { Company, Cultivars } from '../../../interfaces/companies.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddCompanyService{
	d: Date = new Date();
	private postBody: {};
	httpRes: any;
	public createform: boolean;
	public editmode: boolean = false;
	public archived: boolean = false;
	public idcompany: number;
	public farmid: string;
	public contact: string;
	public loadedEntry: Company;
	public cname: string;
	public paypercentU: string;
  public paydayU: string;
	public epaypercentU: string;
  public epaydayU: string;
	public companytype: string;
  public companyname: string;
  public editcompanyname: string;
  public parent: string;
  public firstname: string;
  public lastname: string;
  public tel: string;
  public email: string;
  public website: string;
  public country: string;
  public state: string;
  public address: string;
  public area: string;
  public postcode: string;
  public pobox: string;
  public hubkm: string;
  public vat: string;
  public regnumber: string;
  public farmerpuc: string;
  public pricemodel: string;
  public paypercent: string;
  public payday: string;
  public epaypercent: string;
  public epayday: string;
  public priceseason: number;
  public billingname: boolean = true;
  public bankname: string;
  public bankaccount: string;
  public banksortcode: string;
  public bankiban: string;
  public bankswift: string;
  public hectarage: string;
  public globalgap: boolean = false;
  public notes: string;
  public payterms: string = "default";
  public cultivars: Cultivars[] = [
  	{"idcultivar": "47", "cultivar": "Mixed"}
  ];
  public health: string;
  public modelset: boolean = true;

	constructor(
		private http: HttpClient,
		private pS: PortalService,
		private cS: CountryService,
		private lS: TeamLoginService,
		public dDDS: DropDownDataService,
		public clientsService: SettingsClientsService) {}

	addCompany() {
		if(this.companytype=='producer') {
			this.dDDS.combineTerms();
		}
		if (this.dDDS.checkTerms(this.companytype, this.modelset)) {
			this.cname = this.companyname;
			var fname = this.firstname;
			var lname = this.lastname;
			this.postBody = {
				"name": "addCompany",
				"param":{
					"season": this.lS.season,
					"companytype": this.companytype,
					"companyname": this.companyname,
					"parent": (this.parent == null ? 0 : +this.parent),
					"farmid": (this.farmid==null? "" : this.farmid.toUpperCase()),
					"contact": this.contact,
					"firstname": this.firstname,
					"lastname": this.lastname,
					"tel": this.tel,
					"email": this.email.toLowerCase(),
					"website": this.website,
					"country": (this.cS.countries[this.cS.country.id]['country'] == null ? "" : this.cS.countries[this.cS.country.id]['country']),
					"state": (this.cS.state == null ? "" : this.cS.state),
					"address": (this.address == null ? "" : this.address),
					"area": (this.area == null ? "" : this.area),
					"postcode": (this.postcode == null ? "" : this.postcode),
					"pobox": (this.pobox == null ? "" : this.pobox),
					"hubkm": (this.hubkm == null ? "" : this.hubkm),
					"vat": (this.vat == null ? "" : this.vat), 
					"pricemodel": this.pricemodel,
					"paypercent": this.dDDS.paypercent,
					"payday": this.dDDS.payday,
					"epaypercent": this.dDDS.epaypercent,
					"epayday": this.dDDS.epayday,
					"payterms": this.dDDS.payterms,
					"priceseason": this.priceseason,
					"regnumber": (this.regnumber == null ? "" : this.regnumber),
					"farmerpuc": (this.farmerpuc == null ? "" : this.farmerpuc),
					"billingname": (this.billingname ? 'parent' : 'child'),
					"bankname": (this.bankname == null ? "" : this.bankname),
					"bankaccount": (this.bankaccount == null ? "" : this.bankaccount),
					"banksortcode": (this.banksortcode == null ? "" : this.banksortcode),
					"bankiban": (this.bankiban == null ? "" : this.bankiban),
					"bankswift": (this.bankswift == null ? "" : this.bankswift),
					"hectarage": (this.hectarage == null ? "" : this.hectarage),
					"globalgap": (this.globalgap ? 1 : 0),
					"cultivars": this.cultivars,
					"notes": (this.notes == null ? "" : this.notes)
				}
			};
			return this.http.post(
				this.lS.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	try {
	        		this.lS.setSession(response);
	        		this.lS.toast("Save Successful",response['response']['result']['reply']['message']);
					    if ((this.pS.portalMod()['mod-clients'] || this.pS.portalMod()['mod-all']) && this.companytype=='producer') {
					    	if (this.companytype=='producer' && response['response']['result']['reply']['data']['contactSaved']==1) {
							  	this.portalInviteCheck(response, fname, lname);
							  }
						  }
		        	this.cname = "";
		        	//this.resetForm(false);
		        } catch(e) {
		        	this.lS.toast("Save Unsuccessful","Company was not saved!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
		}
	}

	async portalInviteCheck(response, fname, lname) {
	 	const res = await this.lS.confirm("Do you want to send a portal invite to '" + fname + " " + lname + "'?");
    if (res) {
    	this.clientsService.saveClientMembers(response['response']['result']['reply']['data']['idcontact']);
			this.dDDS.getDropdown("mainContactList");
    }
	}

	loadForEdit() {
		this.postBody = {
			"name": "loadCompany",
			"param":{
				"idcompany": +this.idcompany,
				"season": this.lS.season
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.lS.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		var tempid = this.idcompany;
		this.idcompany = tempid;
		this.loadedEntry = rawData.response.result.reply[0];
		this.companytype = this.loadedEntry.companytype;
	  this.companyname = this.loadedEntry.companyname.toString();
	  this.parent = this.loadedEntry.parent.toString();
	  this.farmid = (this.loadedEntry.farmid==null ? null : this.loadedEntry.farmid.toString());
	  this.contact = this.loadedEntry.contact.toString();
	  this.tel = this.loadedEntry.tel;
	  this.email = this.loadedEntry.email;
	  this.website = this.loadedEntry.website;
	  this.cS.country = this.cS.countries[this.cS.getCountryId(this.loadedEntry.country)];
	  this.cS.setState(this.cS.country.id);
	  this.cS.state = this.loadedEntry.state;
	  this.address = this.loadedEntry.address;
	  this.area = this.loadedEntry.area;
	  this.postcode = this.loadedEntry.postcode;
	  this.pobox = this.loadedEntry.pobox;
	  this.hubkm = this.loadedEntry.hubkm;
	  this.vat = this.loadedEntry.vat;
	  this.regnumber = this.loadedEntry.regnumber;
	  this.farmerpuc = this.loadedEntry.farmerpuc;
	  this.pricemodel = (this.loadedEntry.pricemodel==null ? null : this.loadedEntry.pricemodel.toString());
	  this.dDDS.paypercentU = this.loadedEntry.paypercent;
	  this.dDDS.paydayU = this.loadedEntry.payday;
	  this.dDDS.epaypercentU = this.loadedEntry.epaypercent;
	  this.dDDS.epaydayU = this.loadedEntry.epayday;
	  this.priceseason = (+this.loadedEntry.priceseason > 0 ? +this.loadedEntry.priceseason : +this.d.getFullYear());
	  this.billingname = (this.loadedEntry.billingname == 'parent' ? true : false);
	  this.bankname = this.loadedEntry.bankname;
	  this.bankaccount = this.loadedEntry.bankaccount;
	  this.banksortcode = this.loadedEntry.banksortcode;
	  this.bankiban = this.loadedEntry.bankiban;
	  this.bankswift = this.loadedEntry.bankswift;
	  this.hectarage = this.loadedEntry.hectarage;
	  this.globalgap = (+this.loadedEntry.globalgap == 1 ? true : false);
	  this.notes = this.loadedEntry.notes;
		this.health = this.loadedEntry.health;
		this.archived = this.loadedEntry.health=='archived';
	  this.cultivars = (this.loadedEntry.cultivars.length < 1 ? [{"idcultivar": "47", "cultivar": "Mixed"}] : this.loadedEntry.cultivars);
	  for (var i = 0; i < this.cultivars.length; i++) {
	  	this.cultivars[i]['idcultivar'] = this.cultivars[i]['idcultivar'].toString();
	  }
	  this.dDDS.payterms = this.loadedEntry.payterm;
	  if(this.loadedEntry.companytype=='producer') {
	  	this.dDDS.splitTerms();
	  }
    for (var i = 0; i<this.dDDS.dropdown['companyList'].length; i++) {
      if (this.dDDS.dropdown['companyList'][i]['idcompany']==this.idcompany) {
        this.editcompanyname = this.dDDS.dropdown['companyList'][i]['companyname'].trim();
      }
    }
	}

	saveChanges() {
		if(this.companytype=='producer') {
			this.dDDS.combineTerms();
		}
		if (this.dDDS.checkTerms(this.companytype, this.modelset)) {
			this.postBody = {
				"name": "updateCompany",
				"param":{
					"season": this.lS.season,
					"idcompany": +this.idcompany,
					"companytype": this.companytype,
					"companyname": this.companyname,
					"parent": (this.parent == null ? 0 : +this.parent),
					"farmid": (this.farmid==null? "" : this.farmid.toUpperCase()),
					"contact": +this.contact,
					"tel": this.tel,
					"email": this.email.toLowerCase(),
					"website": this.website,
					"country": (this.cS.countries[this.cS.country.id]['country'] == null ? "" : this.cS.countries[this.cS.country.id]['country']),
					"state": (this.cS.state == null ? "" : this.cS.state),
					"address": (this.address == null ? "" : this.address),
					"area": (this.area == null ? "" : this.area),
					"postcode": (this.postcode == null ? "" : this.postcode),
					"pobox": (this.pobox == null ? "" : this.pobox),
					"hubkm": (this.hubkm == null ? "" : this.hubkm),
					"vat": (this.vat == null ? "" : this.vat), 
					"pricemodel": this.pricemodel,
					"paypercent": this.dDDS.paypercent,
					"payday": this.dDDS.payday,
					"epaypercent": this.dDDS.epaypercent,
					"epayday": this.dDDS.epayday,
					"payterms": this.dDDS.payterms,
					"priceseason": this.priceseason,
					"regnumber": (this.regnumber == null ? "" : this.regnumber),
					"farmerpuc": (this.farmerpuc == null ? "" : this.farmerpuc),
					"billingname": (this.billingname ? 'parent' : 'child'),
					"bankname": (this.bankname == null ? "" : this.bankname),
					"bankaccount": (this.bankaccount == null ? "" : this.bankaccount),
					"banksortcode": (this.banksortcode == null ? "" : this.banksortcode),
					"bankiban": (this.bankiban == null ? "" : this.bankiban),
					"bankswift": (this.bankswift == null ? "" : this.bankswift),
					"hectarage": (this.hectarage == null ? "" : this.hectarage),
					"globalgap": (this.globalgap ? 1 : 0),
					"cultivars": this.cultivars,
					"notes": (this.notes == null ? "" : this.notes)
				}
			};
			return this.http.post(
				this.lS.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	try {
	        		this.lS.setSession(response);
		        	if (response.response.status == '200') {
	        			this.lS.toast("Update Successful",response['response']['result']['reply']['message']);
		        		this.editmode=false;
		        		this.resetForm(false);
		        	} else {
		        		this.lS.toast("Update Unsuccessful","Company was not updated!",'warn');
		        	}
		        } catch(e) {
		        	this.lS.toast("Update Unsuccessful","Company was not updated!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
		}
	}

	deleteEntry(type = 'delete') {
		const companynametemp = this.companyname;
		this.postBody = {
			"name": (type=='delete' ? "deleteCompany" : (type=='archive' ? 'archiveCompany' : 'unarchiveCompany') ),
			"param":{
				"idcompany": +this.idcompany,
				"companyname": this.companyname
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Delete Successful",response['response']['result']['reply']['message']);
	        	this.editmode=false;
	        	this.resetForm(false);
	        } catch(e) {
	        	this.lS.toast("Delete Unuccessful",response['response']['result']['reply']['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	resetForm(keepid: boolean = true) {
    var tempid = this.idcompany;
    window.scroll(0,0);
  	this.dDDS.payterms = 'default';
  	this.dDDS.items = [{"estimate": 0, "type": "", "percent": "", "timing": "","timingDate": "","weekday": "","order": ""}];
  	this.cultivars = [{"idcultivar": "47", "cultivar": "Mixed"}];
    this.dDDS.getDropdown("companyList");
    this.idcompany = null;
    this.archived = false;
    this.companytype = "producer";
    this.companyname = "";
    this.editcompanyname = "";
    this.parent = "";
    this.farmid = null;
    this.firstname = "";
    this.lastname = "";
    this.contact = "0";
    this.tel = "";
    this.email = "";
    this.website = "";
    this.cS.country = {"id": this.cS.countries[159]['id'], "country": "South Africa"};
    this.cS.state = '';
    this.cS.setState(+this.cS.country.id);
    this.address = "";
    this.area = "";
    this.postcode = "";
    this.pobox = "";
    this.hubkm = "";
    this.vat = "";
    this.regnumber = "";
    this.farmerpuc = "";
    this.pricemodel = "0";
    this.dDDS.paypercent = "";
    this.dDDS.payday = "";
    this.dDDS.paypercentU = "";
    this.dDDS.paydayU = "";
    this.dDDS.epaypercent = "";
    this.dDDS.epayday = "";
    this.dDDS.epaypercentU = "";
    this.dDDS.epaydayU = "";
    this.priceseason = +this.d.getFullYear();
    this.billingname = true;
    this.bankname = "";
    this.bankaccount = "";
    this.banksortcode = "";
    this.bankiban = "";
    this.bankswift = "";
    this.hectarage = "";
    this.globalgap = false;
    this.notes = "";
    this.idcompany = (keepid ? tempid : null);
    this.health = "";
  }

	addCultivar() {
		this.cultivars.push({
			"idcultivar": "",
			"cultivar": ""
		 });
	}

	removeCultivar(index: number) {
		this.cultivars.splice(index, 1);
	}

	editCompany(idcompany) {
    this.editmode = true;
    this.idcompany = idcompany;
    this.dDDS.getDropdown("contactList");
    this.dDDS.getDropdown("companyList");
    this.dDDS.getDropdown("producerList");
    this.dDDS.getDropdown("priceModelList");
    this.dDDS.getDropdown("priceTermsList");
    this.loadForEdit();
	}

	parentfarmid(parent) {
		var id = null;
		for (var i = 0; i < this.dDDS.dropdown.companyList.length; i++) {
			if (this.dDDS.dropdown.companyList[i]['idcompany']==parent) {
				id = (this.dDDS.dropdown.companyList[i]['farmid']==null ? null : this.dDDS.dropdown.companyList[i]['farmid']);
			}
		}
		return id;
	}

	setFarmId() {
		this.farmid = (this.parentfarmid(this.parent)==null ? null : this.parentfarmid(this.parent) + '/');
	}

	checkFarmIdUnique() {
		var unique = true;
		var listid;
		var thisid;
		if (this.farmid!=null) {
			thisid = this.farmid.trim().toUpperCase();
			unique = this.farmid.slice(-1)!="/";
			for (var i = 0; i < this.dDDS.dropdown['companyList'].length; i++) {
				listid = this.dDDS.dropdown.companyList[i]['farmid'];
				if (listid==thisid && unique) {
					if (!(this.editmode && this.dDDS.dropdown.companyList[i]['idcompany']==this.idcompany)) {
						unique = false;
					}
				}
				
			}
		}
		return unique;
	}

  checkType(index,type) {
  	var ans = true;
		for (var i = 0; i < this.cultivars.length; i++) {
			if (this.cultivars[i]['idcultivar']==type && index!==i) {
				ans = false;
			}
		}
		return ans;
	}

}