<div class="container px-4">
  <div class="row justify-content-center">
		<img src="../../../assets/{{ this.portalService.logo }}">
	</div>

  <div class="row justify-content-center">
    <div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2" *ngIf="!isAuth()">
      <h2 style="font-family: 'Didot'; text-align: center;">Team Log In</h2>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit(f)" #f="ngForm">
				<div formGroupName="userData">
					<div class="form-group">
						<label for="email">Email</label>
						<input 
							type="text" 
							id="email" 
							formControlName="email" 
							class="form-control">
						<span
              *ngIf="!loginForm.get('userData.email').valid && loginForm.get('userData.email').touched"
              class="help-block">Please enter a valid email!</span>
          </div>
          <div class="form-group">
						<label for="password">Password</label>
						<input 
							type="password" 
							id="password" 
							formControlName="password" 
							class="form-control">
						<span
              *ngIf="!loginForm.get('userData.password').valid && loginForm.get('userData.password').touched"
              class="help-block">Please enter a password!</span>
					</div>
					<div class="row justify-content-start align-items-center px-3 pt-3">
						<div class="col justify-content-center" style="max-width:155px">
							<button mdbBtn class="btn btn-primary loginlabel" type="submit"  [disabled]="this.portalService.portalMod()['settings']==undefined">Log in</button>
						</div>
						<div class="col">
						</div>
						<div class="col">
							<button mdbBtn class="btn btn-link" type="submit"  (click)="newPassword()">Forgot your password?</button>
						</div>
					</div>
				</div>
	  	</form>
		</div>
		<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2" *ngIf="isAuth()">
			<h2 style="font-family: 'Didot'; text-align: center;">You are currently logged in.</h2>
			<div class="row justify-content-between px-3 pt-3">
				<button mdbBtn class="btn-danger mx-auto my-4" (click)="onLogOut()">Log out</button>
			</div>
		</div>
	</div>
	<br>
</div>