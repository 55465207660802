import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule, WavesModule, CardsModule } from 'angular-bootstrap-md';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { PortalService } from '../../auth/portal.service';
import { QueryService } from './query.service';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit {

  constructor(
  	private loginService: TeamLoginService,
    private portalService: PortalService,
    public queryService: QueryService
  	) { }

  ngOnInit() {
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  editSelected() {
  }

}
