<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
  <br>
  <div class="row justify-content-between">
    <div class="col-xs-6 col main-header">
      <h2>Remittance</h2>
    </div>
    <div class="col-auto">
      <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
    </div>
  </div>
  <br>

  <ng-container *ngIf="!this.rS.fees?.modeldnissizes"><app-clientremittancestd></app-clientremittancestd></ng-container>
  <ng-container *ngIf="this.rS.fees?.modeldnissizes"><app-clientremittancesized></app-clientremittancesized></ng-container>

</div>