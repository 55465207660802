import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { AddSampleBatchedService } from '../../logging/add-sample-batched/add-sample.service';
import { PortalService } from '../../../auth/portal.service';

@Component({
  selector: 'app-teamdelivery',
  templateUrl: './teamdelivery.component.html',
  styleUrls: ['./teamdelivery.component.scss']
})
export class TeamDeliveryComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
  	private lS: TeamLoginService,
    public tS: TableDataService,
    private aDS: AddDeliveryService,
    public aSS: AddSampleBatchedService,
    public dDDS: DropDownDataService,
    public portalService: PortalService,
    private router: Router
    ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.lS.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.lS.isAuthenticated();
    } return false;
  }

  sampleComplete() {
    return this.tS.delivery.samplestatus == 'complete';
  }

  async editSample() {
    this.aSS.editmode = true;
    this.aSS.iddelivery = this.tS.delivery['iddelivery'].toString();
    this.aSS.loadForEdit(this.tS.delivery['iddelivery'].toString());
    this.tS.stopLoading = true;
    var lists = [
      'sampleListComplete',
      'sampleList',
      'sampleListUnassigned'
    ];
    for (var i = 0; i<lists.length; i++) {
      if (this.dDDS.dropdown[lists[i]]==undefined) {
        this.dDDS.dropdown[lists[i]] = await this.lS.loadData(lists[i]);
      }
    }
    this.aSS.indexLink = true;
    this.router.navigate(['/team/logging/sample']);
    this.aSS.loadForEdit();
  }

  async editSampleU() {
    this.aSS.editmode = true;
    this.aSS.assignedSample = false;
    this.tS.stopLoading = true;
    var lists = [
      'sampleListComplete',
      'sampleList',
      'sampleListUnassigned'
    ];
    for (var i = 0; i<lists.length; i++) {
      if (this.dDDS.dropdown[lists[i]]==undefined) {
        this.dDDS.dropdown[lists[i]] = await this.lS.loadData(lists[i]);
      }
    }
    this.aSS.ids = this.tS.sampleUnA['ids'].toString();
    this.aSS.loadForEdit();
    this.aSS.indexLink = true;
    this.router.navigate(['/team/logging/sample']);
    this.aSS.loadForEdit();
  }

  async editDelivery() {
    this.aDS.delivery = this.tS.delivery['iddelivery'].toString();
    this.aDS.editmode = true;
    this.tS.stopLoading = true;
    var lists = [
      'deliveryList',
      'producerList',
      'contactList',
      'cultivarList',
      'locationList',
      'depotList',
      'cultivarProfileList',
      'newDeliveryNumber',
      'priceTermsList',
      'storageListWNIS'
    ];
    for (var i = 0; i<lists.length; i++) {
      if (this.dDDS.dropdown[lists[i]]==undefined) {
        this.dDDS.dropdown[lists[i]] = await this.lS.loadData(lists[i]);
      }
    }
    this.router.navigate(['/team/logging/delivery']);
    this.aDS.loadForEdit();
  }

  viewContact(idcontact) {
    this.tS.reset('contacts');
    this.tS.setDelivery(idcontact, 'contacts');
    this.tS.indexLink = true;
    this.router.navigate(['/team/table/contacts']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  viewCompany(idcompany) {
    this.tS.reset('companies');
    this.tS.setDelivery(idcompany, 'companies');
    this.tS.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100);
  }
  
}
