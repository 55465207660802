import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavbarModule, WavesModule, ButtonsModule, DropdownModule } from 'angular-bootstrap-md';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { HeaderService } from './header.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public loginService: LoginService,
    public portalService: PortalService,
    public headerService: HeaderService) { }

  ngOnInit() {
    this.headerService.refreshMenu();
  }

  isAuthenticated(option = null) {
    return this.headerService.isAuthenticated(option);
  }

}