import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule, CardsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { AddArticleService } from './add-article.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
    public addArticleService: AddArticleService,
    public dropDownDataService: DropDownDataService
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.addArticleService.resetForm();
    this.addArticleService.articleList();
    this.dropDownDataService.getDropdown("contactList");
  }

  ngOnDestroy() {
    this.addArticleService.editmode=false;
    this.addArticleService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
 
  canSave() {
    if (
        this.addArticleService.title !== null &&
        this.addArticleService.body !== null &&
        (this.addArticleService.type=='blog' || (this.addArticleService.type=='announcement' && (this.addArticleService.canread || (!this.addArticleService.canread && this.addArticleService.expirydate!==null))))
      ) {
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    if (
        this.addArticleService.title !== null &&
        this.addArticleService.body !== null &&
        (this.addArticleService.type=='blog' || (this.addArticleService.type=='announcement' && (this.addArticleService.canread || (!this.addArticleService.canread && this.addArticleService.expirydate!==null))))
      ) {
      return true;
    } else {
      return false;
    }
  }

  onSave() {
    if (this.canSave()) {
      this.addArticleService.addArticle();
      window.scroll(0,0);
      this.addArticleService.resetForm();
      this.addArticleService.articleList();
    }
  }

  editModeOn(state) {
    this.addArticleService.articleref = '';
    this.addArticleService.resetForm();
    this.addArticleService.editmode = state;
  }

  editSelected() {
    return (this.addArticleService.editmode == false || (this.addArticleService.editmode == true && this.addArticleService.articleref.length>0));
  }

  loadForEdit() {
    this.addArticleService.resetForm();
    this.addArticleService.loadForEdit();
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete this message?");
    if (res) {
      this.addArticleService.deleteEntry();    
      this.addArticleService.resetForm();
    }
  }

  requireExpiry() {
    return this.addArticleService.type=='announcement' && !this.addArticleService.canread && this.addArticleService.expirydate==null;
  }


}