import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoginService } from '../auth/login/login.service';
import { Remittance } from './remittance.interface';
import { DonutSlice } from '../../interfaces/svgdonut.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class FarmerRemittanceService {
	private postBody: {};
	httpRes: any;
	public loadedEntry: Remittance;
	public fees: any;
	public payments: any = [];
	public pmodel: any;
	public useChild: boolean = false;
	public iddelivery: string;
	numerals = ['one','two','three','four','five','six'];
	public svgdonut: any;

	constructor(
		private http: HttpClient,
		private loginService: LoginService) {}

	loadRemittance() {
		this.payments = [];
		if (+this.iddelivery>0) {
			this.postBody = {
				"name":"loadRemittance",
				"param":{
					"producer": +this.loginService.selectedmember,
					"iddelivery": +this.iddelivery,
					"season": +this.loginService.season
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	this.loginService.setSession(response);
	          this.populateRemittance(response);
	        },
	        (error) => console.log(error)
	      );
		}
	}
	
	populateRemittance(rawData) {
		this.loadedEntry = rawData.response.result.reply[0];
		this.fees = rawData.response.result.reply[1];
		var j = 0;
		for (var i = 0; i < rawData.response.result.reply[2].length; i++) {
			if (rawData.response.result.reply[2][i]['instalment']>0) {
				this.payments[this.numerals[j]] = rawData.response.result.reply[2][i];
				j++;
				this.payments['count'] = j;
			}
			if (rawData.response.result.reply[2][i]['instalment']=='m') {
				this.payments['m'] = rawData.response.result.reply[2][i];
			}
		}
		var sizes = [];
		var dnistotal = 0;
		for (const [k, v] of Object.entries(this.fees['sizepercents'])) {
			sizes[k] = v;
			dnistotal += +v * +this.fees['dnis'];
		}
		this.svgdonut = {
			"dnis18": {
				"percent": sizes['dnis18'],
				"rate": this.fees['sizepricesinv']['dnis18'],
				"kg": sizes['dnis18'] *  dnistotal,
				"amount": this.fees['sizecontributions']['dnis18'],
				"donut": this.setDonut(sizes['dnis18'])
			},
			"dnis20": {
				"percent": sizes['dnis20'],
				"rate": this.fees['sizepricesinv']['dnis20'],
				"kg": sizes['dnis20'] *  dnistotal,
				"amount": this.fees['sizecontributions']['dnis20'],
				"donut": this.setDonut(sizes['dnis20'])
			},
			"dnis22": {
				"percent": sizes['dnis22'],
				"rate": this.fees['sizepricesinv']['dnis22'],
				"kg": sizes['dnis22'] *  dnistotal,
				"amount": this.fees['sizecontributions']['dnis22'],
				"donut": this.setDonut(sizes['dnis22'])
			},
			"dnis25": {
				"percent": sizes['dnis25'],
				"rate": this.fees['sizepricesinv']['dnis25'],
				"kg": sizes['dnis25'] *  dnistotal,
				"amount": this.fees['sizecontributions']['dnis25'],
				"donut": this.setDonut(sizes['dnis25'])
			},
			"total": {
				"percent": 1,
				"donut": this.setDonut(1)
			},
			"sizes": sizes
		};
		this.pmodel = rawData.response.result.reply[3];
		this.fees['feevat'] = +this.fees['vatable'] * +this.fees['vatrate'] * ( +this.fees['dryfee'] + +this.fees['processfee'] + +this.fees['marketingfee'] + +this.fees['transportfee'] ) + (+this.fees['samacexvat'] + +this.fees['mrlexvat'] * (this.fees['mrlclass']=='i' ? 1 : 0 )) * +this.fees['vatrate'];
		this.useChild = (this.loadedEntry.billingname == 'child' && this.loadedEntry.companyname != this.loadedEntry.pcompanyname ? true : false);
		this.loadedEntry['reference'] = (this.loadedEntry.companyname == this.loadedEntry.pcompanyname ? "" : (this.useChild ? this.loadedEntry.pcompanyname : this.loadedEntry.companyname ) );
		
		if (this.useChild) {
			this.loadedEntry['fulladdress'] = "";
			this.loadedEntry['fulladdress'] = this.loadedEntry['fulladdress'] + (this.loadedEntry.address != "" ? this.loadedEntry.address + " " : "");
			this.loadedEntry['fulladdress'] = this.loadedEntry['fulladdress'] + (this.loadedEntry.area != "" ? this.loadedEntry.area + " " : "");
			this.loadedEntry['fulladdress'] = this.loadedEntry['fulladdress'] + (this.loadedEntry.state != "" ? this.loadedEntry.state + " " : "");
		} else {
			this.loadedEntry['fulladdress'] = "";
			this.loadedEntry['fulladdress'] = this.loadedEntry['fulladdress'] + (this.loadedEntry.paddress != "" ? this.loadedEntry.paddress + " " : "");
			this.loadedEntry['fulladdress'] = this.loadedEntry['fulladdress'] + (this.loadedEntry.parea != "" ? this.loadedEntry.parea + " " : "");
			this.loadedEntry['fulladdress'] = this.loadedEntry['fulladdress'] + (this.loadedEntry.pstate != "" ? this.loadedEntry.pstate + " " : "");
		}		
		var addressfontsize = 16;
		var textlength = 50;
		if (this.loadedEntry['fulladdress'].length > textlength) {
			addressfontsize = Math.floor(textlength / this.loadedEntry['fulladdress'].length * addressfontsize);
			document.getElementById('address').style.fontSize = addressfontsize + "px";
		}
	}

	setDonut(percent: number) {
		percent *= 100;
		var base = 86;
		var value = percent * base / 100;
		var backlimit = 18;
		var ans: DonutSlice[] = [
			{
	      id: 1,
	      percent: Math.max(0, base-value-backlimit),
	      color: 'rgb(236, 238, 240)',
	      label: 'Slice 1',
	    },
	    {
	      id: 2,
	      percent: Math.min(base-backlimit,value),
	      color: 'DarkSeaGreen',
	      label: 'Slice 2',
	    },
	    {
	      id: 3,
	      percent: 100-base,
	      color: 'White',
	      label: 'Slice 3',
	    },
	    {
	      id: 4,
	      percent: Math.max(0, base-value),
	      color: 'rgb(236,238,240)',
	      label: 'Slice 4',
	    },
	    {
	      id: 5,
	      percent: Math.max(0, value-base+backlimit),
	      color: 'DarkSeaGreen',
	      label: 'Slice 5',
	    }
	  ];
	  return ans;
	}
	
}