import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
am4core.useTheme(am4themes_animated); 
//am4core.useTheme(am4themes_material);

import { Silo, RootObject } from './silos.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { SiloService } from './silos.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-silos',
  templateUrl: './silos.component.html',
  styleUrls: ['./silos.component.scss']
})
export class SilosComponent implements OnInit {

  private chart: am4charts.XYChart;

  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [
    'name',
    'dwnis',
    'ddnis',
    'sk',
    'date',
    'deliveries',
    'samplesComplete',
    'sound',
    'unsound',
    'dark',
    'above18',
    'above20',
    'above22',
    'above24',
    's0',
    's1',
    's4l',
    's4r',
    's567',
    ];

  siloDB: SiloService | null;
  dataSource: Silo[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
  	public siloService: SiloService,
    public csvService: CsvService,
    private http: HttpClient,
    private zone: NgZone
  	) { }

  ngAfterViewInit() {
    this.updateTable();
  }

  ngOnDestroy() {
    this.siloService.clickedIndex = null;
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  ngOnInit() {
    this.siloService.loadSiloChart();
    //this.siloService.loadSilo();
    window.scrollTo(0,0);
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    this.siloService.loadSilo();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.siloService.clickedIndex = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
    this.updateTable();
  }

  onSelectRow(i: number) {
    //this.clickedId = this.table['_data'][i]['idOrder'];
    //this.siloService.setOrder(+this.clickedId);
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.siloService.clickedIndex !== null;
  }

  updateTable() {
    this.siloDB = new SiloService(this.http, this.loginService, this.zone);
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.siloDB!.getSilo();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply;
          this.datasrc = new MatTableDataSource(this.dataSource);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Silos');
  }

  calTotal(field: string){ 
    var total = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + (this.datasrc.filteredData[i][field] == null ? 0 : +this.datasrc.filteredData[i][field]);
    }
    return total;
  }

  calWAvg(field: string){ 
    var total = 0;
    var wavg = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + (this.datasrc.filteredData[i]['stwnis'] == null ? 0 : +this.datasrc.filteredData[i]['stwnis']);
      wavg = wavg +( (this.datasrc.filteredData[i][field] == null ? 0 : +this.datasrc.filteredData[i][field]) * (this.datasrc.filteredData[i]['stwnis'] == null ? 0 : +this.datasrc.filteredData[i]['stwnis']) );
    }
    return wavg / total;
  }

}