<div *ngIf="isAuthenticated()" class="container">
	<br>
	<div class="row mt-3">
		<div class="col">
			<div>
				<h2>Processing: {{ this.loginService.season }} Season</h2>
			</div>
			<br>
			<div>
				<div class="row justify-content-between">
					<div class="col main-header">
						<h3>
							<div class="dropdown" dropdown>
				        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-0" mdbWavesEffect>
				        Category: {{ this.processingService.grouptype }}<span class="caret"></span></a>
				        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('All')">All</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Cracking')">Cracking</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('NIS')">NIS</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('NIS Only')">NIS Only</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Sized Only')">Sized Only</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Batch Blending')">Batch Blending</button>
				          <button 
				          	class="dropdown-item waves-light py-3" 
				          	mdbWavesEffect 
				          	(click)="setType('Batch Transfer')">Batch Transfer</button>
				        </ul>
				      </div>
						</h3>
					</div>
					<div class="mr-4 ml-3 col-auto">
						<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
					</div>
				</div>
			</div>
			<br>
			<mat-form-field class="filter-wide">
			  <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter">
			</mat-form-field>
			<div class="example-container mat-elevation-z8">
				<div class="example-loading-shade"
			       *ngIf="isLoadingResults">
			    <mat-spinner *ngIf="isLoadingResults" color="accent"></mat-spinner>
			  </div>
				<div class="example-table-container">
				  <table mat-table matSort [dataSource]="datasrc" hover="true" class="example-table">
				  	<ng-container matColumnDef="date" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
				      <td mat-cell *matCellDef="let element"> {{ element.date | date:'MMM d, EEE' }} </td>
				      <td mat-footer-cell *matFooterCellDef> Total </td>
				    </ng-container>
				    <ng-container matColumnDef="process" sticky>
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Process </th>
				      <td mat-cell *matCellDef="let element"> {{ element.processname }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				   <ng-container matColumnDef="quantity">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> DNIS (kg) </th>
				      <td mat-cell *matCellDef="let element"> {{ +element.quantity | number }} </td>
				      <td mat-footer-cell *matFooterCellDef> {{ calTotal('quantity') | number }} </td>
				    </ng-container>
				    <ng-container matColumnDef="originname">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
				      <td mat-cell *matCellDef="let element"> {{ element.sourcetype | titlecase }} {{ element.originname }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				    <ng-container matColumnDef="destinationname">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Destination </th>
				      <td mat-cell *matCellDef="let element"> {{ element.destinationtype | titlecase }} {{ element.destinationname }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				    <ng-container matColumnDef="notes">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Comment </th>
				      <td mat-cell *matCellDef="let element"> {{ element.notes }} </td>
				      <td mat-footer-cell *matFooterCellDef class="alignleft">  </td>
				    </ng-container>
				    <ng-container matColumnDef="variety">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Variety </th>
				      <td mat-cell *matCellDef="let element"> {{ element.variety }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				    <ng-container matColumnDef="batch">
				      <th mat-header-cell *matHeaderCellDef mat-sort-header> Batch </th>
				      <td mat-cell *matCellDef="let element"> {{ element.batch }} </td>
				      <td mat-footer-cell *matFooterCellDef>  </td>
				    </ng-container>
				    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
				    <tr mat-row 
				    	*matRowDef="let row; let i = index; columns: displayedColumns;" 
				    	(click)="onSelectRow(i)"
				    	style="cursor: pointer"
				    	class="example-element-row"></tr>
	 					<tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="example-first-footer-row" ></tr>
				  </table>
				</div>
			</div>
			<br>
			<br>
			<!--
			<div *ngIf="clickedIndexNull()">
				<app-teamdelivery></app-teamdelivery>
			</div>
		-->
		</div>
	</div>
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>