<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.addDeliveryService.editmode">Log Depot Delivery</h2>
				<h2 *ngIf="this.addDeliveryService.editmode">Edit Depot Delivery</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.addDeliveryService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/depotdeliveries">View Depot Deliveries</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Depot Delivery</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.addDeliveryService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/depotdeliveries">View Depot Deliveries</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Log Depot Delivery</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">	
			  
			  <mat-form-field *ngIf="this.addDeliveryService.editmode">
    			<mat-label>Depot Delivery Number</mat-label>
			    <mat-select placeholder="Depot Delivery Number" [(ngModel)]="this.addDeliveryService.iddeldepot" (keydown.tab)="loadForEdit()">
			      <mat-option *ngFor="let delivery of this.dropDownDataService.dropdown.depotDeliveryList" #prod (click)="loadForEdit()" value="{{ delivery.iddeldepot }}">{{ delivery.iddeldepot | delivery }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <br *ngIf="this.addDeliveryService.editmode">

			  <mat-form-field *ngIf="!this.addDeliveryService.editmode">
    			<mat-label>Depot Location</mat-label>
			    <mat-select placeholder="Depot Location" [(ngModel)]="this.dropDownDataService.depot['idlocation']">
			      <mat-option *ngFor="let depot of this.dropDownDataService.dropdown.depotList" [value]="depot.idlocation">{{ depot.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field *ngIf="this.addDeliveryService.editmode && this.editSelected()">
    			<mat-label>Depot Location</mat-label>
			    <mat-select placeholder="Depot Location" [(ngModel)]="this.addDeliveryService.depot">
			      <mat-option *ngFor="let depot of this.dropDownDataService.dropdown.depotList" [value]="depot.idlocation">{{ depot.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="!this.addDeliveryService.editmode">
    			<mat-label>Depot Delivery Number</mat-label>
			    <input matInput type="number" placeholder="Depot Delivery Number" [(ngModel)]="this.dropDownDataService.getNewDepotDeliveryNumber" value="{{ this.dropDownDataService.getNewDepotDeliveryNumber }}">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Date</mat-label>
			    <input matInput [matDatepicker]="date" [(ngModel)]="this.addDeliveryService.depotdate">
					<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
			    <mat-datepicker #date></mat-datepicker>
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Producer</mat-label>
			    <mat-select placeholder="Producer" [(ngModel)]="this.addDeliveryService.producer" (keydown.tab)="updateContacts(this.addDeliveryService.producer)">
			      <mat-option *ngFor="let producer of this.dropDownDataService.dropdown.producerList" #prod (click)="updateContacts(prod.value)" [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && this.addDeliveryService.getFarmId(this.addDeliveryService.producer)!=null">
    			<mat-label>Farm ID</mat-label>
			    <mat-select placeholder="Farm ID" [(ngModel)]="this.addDeliveryService.producer" (keydown.tab)="updateContacts(this.addDeliveryService.producer)">
			      <ng-container *ngFor="let producer of this.dropDownDataService.dropdown.producerList">
			      	<mat-option *ngIf="producer.farmid!=null" #prod (click)="updateContacts(prod.value)" [value]="producer.idcompany">{{ producer.farmid }}</mat-option>
			      </ng-container>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Contact Name</mat-label>
			    <mat-select placeholder="Contact Name" [(ngModel)]="this.addDeliveryService.thisCompanyContact">
			      <mat-option *ngFor="let contact of this.addDeliveryService.thisCompanyContacts"  [value]="contact?.idcontact">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Farmer Delivery Reference (Optional)</mat-label>
			    <input matInput placeholder="Farmer Delivery Reference (Optional)" [(ngModel)]="this.addDeliveryService.iddelfarm" value="{{ this.addDeliveryService.iddelfarm }}">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Cultivar</mat-label>
			    <mat-select placeholder="Cultivar" [(ngModel)]="this.addDeliveryService.cultivar">
			      <mat-option *ngFor="let cult of this.addDeliveryService.cultivars" [value]="cult.idcultivar">{{ cult.cultivar }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.addDeliveryService.dehusking" *ngIf="editSelected()">
	        Dehusking?
	      </mat-slide-toggle> 

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected() && this.addDeliveryService.dehusking">
    			<mat-label>Nuts in Husk (kg)</mat-label>
			    <input matInput type="number" placeholder="Nuts in Husk (kg)" [(ngModel)]="this.addDeliveryService.huskmass">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && this.addDeliveryService.dehusking">
    			<mat-label>Reject from Husking (kg)</mat-label>
			    <input matInput type="number" placeholder="Reject from Husking (kg)" [(ngModel)]="this.addDeliveryService.rejecthuskmass">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Smalls (kg)</mat-label>
			    <input matInput type="number" placeholder="Smalls (kg)" [(ngModel)]="this.addDeliveryService.depotsmalls">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Accepted WNIS (kg)</mat-label>
			    <input matInput type="number" placeholder="Accepted WNIS (kg)" [(ngModel)]="this.addDeliveryService.depotwnis">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Dispatch WNIS (kg)</mat-label>
			    <input matInput type="number" placeholder="Dispatch WNIS (kg)" [(ngModel)]="this.addDeliveryService.depotwnisd">
			  </mat-form-field>

				<hr *ngIf="editSelected()">

			  <ng-container *ngIf="editSelected()">
		      <p>Tag Serial Numbers:</p>
		      <div *ngFor="let tag of this.dropDownDataService.tags; let tagindex=index">
						<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <mat-form-field>
    							<mat-label>Tag Serial Number {{ tagindex + 1 }}</mat-label>
							    <input matInput type="text" min="0" step="1" placeholder="Tag Serial Number {{ tagindex + 1 }}" [(ngModel)]="this.dropDownDataService.tags[tagindex]['serial']">
							  </mat-form-field>

							</div>
						</div>

						<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <div class="row">
								  <button mat-icon-button class="col" (click)="this.dropDownDataService.addTag()"><i class="material-icons addbutton">add_circle_outline</i></button>
								  <button mat-icon-button class="col" *ngIf="this.dropDownDataService.tags.length>1" (click)="this.dropDownDataService.removeTag(tagindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
								</div>

							</div>
						</div>
					</div>
				</ng-container>

	      <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [(ngModel)]="this.addDeliveryService.note"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="editSelected()">

			<button *ngIf="!this.addDeliveryService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="saveDelivery()">Save</button>

			<button *ngIf="this.addDeliveryService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canUpdate()" (click)="this.saveChanges()">Update</button>

			<button *ngIf="this.addDeliveryService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>