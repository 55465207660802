import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { AddContactService } from './add-contact.service';
import { CountryService } from '../../../services/countries.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
	d: Date = new Date();
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
  	private loginService: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tableService: TableDataService,
    public aCS: AddContactService,
    public countryService: CountryService
  	) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, this.forbiddenStringValidator.bind(this)]]
    });
    window.scrollTo(0,0);
    this.aCS.resetForm();
    this.dDDS.getDropdown("companyList");
    this.dDDS.getDropdown("contactList");
  }

  ngOnDestroy() {
    this.aCS.idcontact = 0;
    this.aCS.resetForm();
    this.aCS.editmode = false;
    this.tableService.loadData('contacts');
  }

  forbiddenStringValidator(control) {
    const forbiddenStrings = this.dDDS.dropdown.contactList;
    const forbidden = forbiddenStrings.some(obj => obj.email.trim() === control.value.trim().toLowerCase());    
    return forbidden ? { 'forbiddenString': true } : null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        (this.aCS.company !== undefined ? this.aCS.company.toString().replace(/ /g, "") !== "" : false) &&
        (this.aCS.firstname !== undefined ? this.aCS.firstname.replace(/ /g, "") !== "" : false) &&
        (this.aCS.lastname !== undefined ? this.aCS.lastname.replace(/ /g, "") !== "" : false) &&
        (this.aCS.email !== undefined ? this.aCS.email.replace(/ /g, "") !== "" : false) &&
        (this.form.status == "VALID")
      ) {
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.aCS.idcontact = 0;
    this.aCS.resetForm();
    this.aCS.editmode = state;
    this.aCS.archived = false;
  }

  editSelected() {
    return (this.aCS.editmode == false || (this.aCS.editmode == true && this.aCS.idcontact > 0));
  }

  onCalculate() {
    if (this.canSave()) {
      this.aCS.addContact();
      window.scroll(0,0);
      this.aCS.resetForm();
      this.dDDS.getDropdown("contactList");
    }
  }

  async onDelete(type = 'delete') {
    const res = await this.loginService.confirm("Are sure you want to " + type + " contact " + this.aCS.firstname + " " + this.aCS.lastname + "?");
    if (res) {
      this.aCS.deleteEntry(type);
      this.aCS.resetForm();
      this.dDDS.getDropdown("contactList");
    }
  }
  
}