import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { Process, RootObject } from './processing.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { ProcessingService } from './processing.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})
export class ProcessingComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [
    'date',
    'process',
    'quantity',
    'originname',
    'destinationname',
    'notes',
    'variety',
    'batch'
    ];

  processDB: ProcessingService | null;
  dataSource: Process[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
    public processingService: ProcessingService,
    public csvService: CsvService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
    this.updateTable();
  }

  ngOnInit() {
  	this.processingService.clickedIndex = null;
  	this.processingService.loadProcesses();
    window.scrollTo(0,0);
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    this.processingService.loadProcesses();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.processingService.clickedIndex = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
    this.updateTable();
  }

  onSelectRow(i: number) {
    //this.clickedId = this.table['_data'][i]['idprocessing'];
    //this.processingService.setProcess(+this.clickedId);
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.processingService.clickedIndex !== null;
  }

  setType(type) {
    this.processingService.grouptype = type;
    switch(type) {
      case 'All':
        this.processingService.protype = 'all';
        break;
      case 'Cracking':
        this.processingService.protype = 'cracking';
        break;
      case 'NIS':
        this.processingService.protype = 'nis';
        break;
      case 'NIS Only':
        this.processingService.protype = 'nisonly';
        break;
      case 'Sized Only':
        this.processingService.protype = 'sizedonly';
        break;
      case 'Batch Blending':
        this.processingService.protype = 'mix';
        break;
      case 'Batch Transfer':
        this.processingService.protype = 'transfer';
        break;
    }
    this.updateTable();
  }

  updateTable() {
    this.processDB = new ProcessingService(this.http, this.loginService);
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.processDB!.getProcesses();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply;
          this.datasrc = new MatTableDataSource(this.dataSource[this.processingService.protype]);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Processing');
  }

  calTotal(field: string){ 
    var total = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + +this.datasrc.filteredData[i][field];
    }
    return total;
  }
  
}