import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { SettingsClientsService } from '../../settings/clients/clients.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { Company } from '../../../interfaces/companies.interface';
import { CountryService } from '../../../services/countries.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class BankAccountsService{
	private postBody: {};
	httpRes: any;
	public createform: boolean;
	public editmode: boolean = false;
	public archived: boolean = false;
	public loadedEntry: Company;
	public companyname: string;
	public address: string;
	public area: string;
	public state: string;
	public country: string;
	public postcode: string;
	public pobox: string;
	public bankname: string;
	public bankaccount: string;
	public banksortcode: string;
	public bankswift: string;
  public note: string;
  public health: string;

	public idcompany: number = 0;
  public fname: string;
  public lname: string;
	public company: string;
  public firstname: string;
  public lastname: string;
  public telephone: string;
  public email: string;
  public birthday: string;
  public shirtsize: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private portalService: PortalService,
		private countryService: CountryService,
		public dropDownDataService: DropDownDataService,
		public clientsService: SettingsClientsService) {}

	addAccount() {
		this.postBody = {
			"name": "addAccount",
			"param":{
				"companyname": this.companyname,
				"country": (this.countryService.countries[this.countryService.country.id]['country'] == null ? "" : this.countryService.countries[this.countryService.country.id]['country']),
				"state": (this.countryService.state == null ? "" : this.countryService.state),
				"address": (this.address == null ? "" : this.address),
				"area": (this.area == null ? "" : this.area),
				"postcode": (this.postcode == null ? "" : this.postcode),
				"pobox": (this.pobox == null ? "" : this.pobox),
				"bankname": (this.bankname == null ? "" : this.bankname),
				"bankaccount": (this.bankaccount == null ? "" : this.bankaccount),
				"banksortcode": (this.banksortcode == null ? "" : this.banksortcode),
				"bankswift": (this.bankswift == null ? "" : this.bankswift),
				"note": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
        		this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
      			this.dropDownDataService.getDropdown("bankAccountListAll");
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Bank account was not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	loadForEdit() {
		this.postBody = {
			"name": "loadBankAccount",
			"param":{
				"idcompany": +this.idcompany
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply[0];
		this.archived = this.loadedEntry.health=='archived';
		this.company = this.loadedEntry.idcompany;
		this.companyname = this.loadedEntry.companyname;
		this.bankname = this.loadedEntry.bankname;
		this.bankaccount = this.loadedEntry.bankaccount;
		this.banksortcode = this.loadedEntry.banksortcode;
		this.bankswift = this.loadedEntry.bankswift;
		this.address = this.loadedEntry.address;
		this.area = this.loadedEntry.area;
		this.postcode = this.loadedEntry.postcode;
		this.pobox = this.loadedEntry.pobox;
	  this.countryService.country = this.countryService.countries[this.countryService.getCountryId(this.loadedEntry.country)];
	  this.countryService.setState(this.countryService.country.id);
	  this.countryService.state = this.loadedEntry.state;
		this.note = this.loadedEntry.notes;
		this.health = this.loadedEntry.health;
		this.editmode = true;
	}

	saveChanges() {
		this.postBody = {
			"name": "updateAccount",
			"param":{
				"idcompany": +this.idcompany,
				"companyname": this.companyname,
				"bankname": this.bankname,
				"bankaccount": this.bankaccount,
				"banksortcode": this.banksortcode,
				"bankswift": this.bankswift,
				"address": this.address,
				"area": this.area,
				"postcode": this.postcode,
				"pobox": this.pobox,
				"country": (this.countryService.countries[this.countryService.country.id]['country'] == null ? "" : this.countryService.countries[this.countryService.country.id]['country']),
				"state": (this.countryService.state == null ? "" : this.countryService.state),
				"notes": (this.note == null ? "" : this.note),
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
		        if (response.response.status == '200') {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Update Successful",response.response.result['reply']['message']);
      				this.dropDownDataService.getDropdown("bankAccountListAll");
		        	this.editmode=false;
		        	this.resetForm();
		        } else {
	        		this.loginService.toast("Update Unsuccessful",response.error['message'],'warn');
		        }
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry(type = 'delete') {
		this.postBody = {
			"name": (type=='delete' ? "deleteAccount" : (type=='archive' ? 'archiveAccount' : 'unarchiveAccount') ),
			"param":{
				"idcompany": +this.idcompany,
				"bankname": this.bankname,
				"bankaccount": this.bankaccount
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
		        if (response.response.status == '200') {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Delete Successful",response.response.result['reply']['message']);
		        	this.editmode=false;
		        	this.resetForm();
      				this.dropDownDataService.getDropdown("bankAccountListAll");
		        } else {
	        		this.loginService.toast("Delete Unsuccessful",response.error['message'],'warn');
		        }
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful",response.error['message'],'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
    this.archived = false;
    this.company = "";
		this.companyname = "";
		this.bankname = "";
		this.bankaccount = "";
		this.banksortcode = "";
		this.bankswift = "";
		this.address = "";
		this.area = "";
		this.postcode = "";
		this.pobox = "";
    this.countryService.country = {"id": this.countryService.countries[159]['id'], "country": "South Africa"};
    this.countryService.state = '';
    this.countryService.setState(+this.countryService.country.id);
    this.note = "";
    this.health = "";
  }

}