import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input } from '@angular/core';
import { Routes, Router, RouterModule, ActivatedRoute, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { BuyingLoginService } from '../auth/login/login.service';
import { BuyingTableDataService } from './table.service';
import { PortalService } from '../../auth/portal.service';
import { Table } from 'primeng/table';

import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-buyingtable',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class BuyingTableComponent implements OnInit {
  d: Date = new Date();
  memberSelect;
  dataStore;
  entity: string = "Sample";
  tname: string = "Samples";
  tablename: string = "Samples";
  exportname: string = this.tablename + " - " + this.d.toISOString().slice(0, 10);
  component: string = "app-teamdelivery";
  searchtext: string = '';
  curswitch: boolean = false;
  remittanceDetails: boolean = false;
  exportData;
  exportDate;
  exportMonth;
  headers;
  choice: string = "all";
  csvReport;
  csvdata;
  csvloading = false;

  @ViewChild(Table) private dt1: Table;
  @Input() preload: {};

  constructor(
    public lS: BuyingLoginService,
    public tS: BuyingTableDataService,
    public portalService: PortalService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.tS.selectedRows = null;
    if (this.preload==null) {
      this.router.events.subscribe(event =>{
        if (event instanceof NavigationStart){
          this.tS.reset(this.tablename.toLowerCase());
        }
      })
      this.route.params.subscribe( params =>
        this.setParams(params)
      );
    } else {
      this.tS.reset(this.preload['tablename']);
      this.setParams(this.preload);
    }
    window.scrollTo(0,0);
  }

  ngAfterViewInit() {
    this.resetTableAutoScroll();
  }
  
  resetTableAutoScroll() {
    if (this.dt1['originalResetScrollTop']==undefined) {
      this.dt1['originalResetScrollTop'] = this.dt1.resetScrollTop;
    }
    if (this.dt1 !=null && this.dt1['originalResetScrollTop']!=undefined) {
      if (this.tS.stillLoading) {
        this.dt1.resetScrollTop = function() {}
      } else {
        this.dt1.resetScrollTop = function() {
          this.dt1.originalResetScrollTop();
        }
      }
    }
  }

  ngOnDestroy() {
    this.tS.reset(this.tablename.toLowerCase());
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.lS.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.lS.isAuthenticated();
    } return false;
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  setParams(params) {
    var t = params['tablename'];
    switch (t) {
      case "contacts":
        this.tname = 'Contacts';
        this.tablename = 'contacts';
        this.entity = 'Contact';
        this.component = 'contact';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "companies":
        this.tname = 'Companies';
        this.tablename = 'companies';
        this.entity = 'Company';
        this.component = 'company';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
       case "availableinventory":
        this.tname = 'Available Inventory';
        this.tablename = 'availableinventory';
        this.entity = '';
        this.component = 'stock';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
       case "orders":
        this.tname = 'Orders';
        this.tablename = 'orders';
        this.entity = '';
        this.component = 'order';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
    }
    this.tS.tablename = this.tablename;
    if (!this.tS.indexLink) {this.tS.clickedIndex[t.toLowerCase()] = null;}
    this.exportname = t + " - " + this.d.toISOString().slice(0, 10);
    if (this.tS[t]==null) {
      this.tS.loadData(t);
    }
  }

  cacheForMember() {
    this.tS.loadData(this.tablename.toLowerCase());
    this.lS.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.tS.clickedIndex[this.tablename.toLowerCase()] = null;
    this.cacheForMember();
  }

  onSelectRow(clickedId) {
    var tname = this.tablename;
    this.tS.clickedId[this.tS.allData[this.tablename.toLowerCase()]['key']] = clickedId;
    this.tS.setSelection(clickedId, this.tablename.toLowerCase());
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  multipleMembers() {
    return +this.lS.memberships.length > 1;
  }

  toggleCheckboxes() {
    this.tS.selectedRows = [];
    this.tS.checkBoxes = !this.tS.checkBoxes;
  }

  calTotal(field: string, type: string = 'simple'){
    var cur = '';
    var fx = '';
    if (type!=='simple') {
      for (var i = 0; this.tS.allData[this.tablename.toLowerCase()]?.columns.length; i++) {
        if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['field']==field) {
          cur = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['currency'];
          fx = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['fxrate'];
          break;
        }
      }
    }
    var total = 0;
    for (var i = 0; i < this.tS.filteredData?.length; i++) {
      total = total + +this.tS.filteredData[i][field];
    }
    return total;
  }

  calCount(field: string, type: string = 'simple'){ 
    var total = 0;
    for (var i = 0; i < this.tS.filteredData?.length; i++) {
      total = total + (this.tS.filteredData[i][field]==null ? 0 : 1);
    }
    return total;
  }

  calWavg(field: string, type: string = 'simple') {
    var base = '';
    var cur = '';
    var fx = '';
    try {
      for (var i = 0; this.tS.allData[this.tablename.toLowerCase()]?.columns.length; i++) {
        if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['field']==field) {
          if (type!=='simple') {
            cur = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['currency'];
            fx = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['fxrate'];
          }
          if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['base']!==undefined) {
            base = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['base'];
          }
        }
      }
    }
    catch(e) {
    }
    var total = 0;
    for (var i = 0; i < this.tS.filteredData?.length; i++) {
      total = total + +this.tS.filteredData[i][field] * (base=='' ? 1 : +this.tS.filteredData[i][base]);
    }
    return total / (base=='' ? this.calCount(field) : this.calTotal(base) );
  }

  onCurSelect(currency) {
    this.tS.currency = currency;
  }

  batchPanel() {
    this.tS.clickedIndex[this.tablename.toLowerCase()] = null;
    this.tS.bpanel = !this.tS.bpanel;
  }

  clear(table: any) {
    table.clear();
    table.filter();
    this.tS.selectedRows = null;
    this.searchtext = '';
    table.filterGlobal(this.searchtext, 'contains');
    this.tS.clickedId[this.tS.allData[this.tablename.toLowerCase()]['key']] = null;
  }

  onFilter(event, dt) { 
    this.tS.filteredData = event.filteredValue;
  }

  isSelected(idd) {
    return this.tS.clickedId[this.tS.allData[this.tablename.toLowerCase()]['key']] == idd;
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    })
  }

  changeTable(tablename) {
    var t = {'tablename': tablename.toLowerCase()};
    this.tS.reset(t['tablename']);
    this.setParams(t);
  }


}