<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.settingsService.editmode">Create Seasonal Settings</h2>
				<h2 *ngIf="this.settingsService.editmode">Edit Seasonal Settings</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.settingsService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/overview/currency">View Seasonal Settings</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Seasonal Settings</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.settingsService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/overview/currency">View Seasonal Settings</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Create Seasonal Settings</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.settingsService.editmode">
    			<mat-label>Season - Edit</mat-label>
			    <mat-select placeholder="Season - Edit" [(ngModel)]="this.settingsService.season" (keydown.tab)="this.settingsService.loadForEdit()">
			      <mat-option *ngFor="let season of this.dropDownDataService.dropdown.savedSeasonSettingList" (click)="this.settingsService.loadForEdit()" value="{{ season.season }}">{{ season.season }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field *ngIf="!this.settingsService.editmode">
    			<mat-label>Season</mat-label>
			    <mat-select placeholder="Season" [(ngModel)]="this.settingsService.season">
			    	<ng-container *ngFor="let season of this.loginService.seasons">
			      	<mat-option *ngIf="this.settingsService.checkSeason(season)" value="{{ season }}">{{ season }}</mat-option>
			      </ng-container>
			    </mat-select>
			  </mat-form-field>

		  	<br *ngIf="this.settingsService.editmode && editSelected()">

		  	<ng-container *ngIf="editSelected()">
				  <div class="col px-0" *ngFor="let setting of this.settingsService.settings; let settingIndex=index;">

				  	<hr>

						<div class="col-9 px-0" style="display: inline-block;">
							<div class="form-container">
							  		  
							  <mat-form-field>
    							<mat-label>Setting</mat-label>
							    <mat-select placeholder="Setting" [(ngModel)]="this.settingsService.settings[settingIndex]['setting']" (selectionChange)="this.settingsService.setValue(settingIndex)">
							      <mat-option *ngIf="this.settingsService.checkType(settingIndex, 'defaultpricemodel')" value="defaultpricemodel">Default Pricing Model</mat-option>
							      <mat-option *ngIf="this.settingsService.checkType(settingIndex, 'defaultseasonstart')" value="defaultseasonstart">Default Season Start Date (every year going forward)</mat-option>
							      <mat-option *ngIf="this.settingsService.checkType(settingIndex, 'seasonstart')" value="seasonstart">{{ this.settingsService.season }} Season Start Date</mat-option>
							      <mat-option *ngIf="this.settingsService.checkType(settingIndex, 'deliverynostart')" value="deliverynostart">{{ this.settingsService.season }} Season Delivery Numbering</mat-option>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-3 px-0" style="display: inline-block;">
							<div class="form-container">
							  
								<button mat-icon-button class="col" (click)="this.settingsService.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>

							</div>
						</div>

						<div class="col-9 px-0" style="display: inline-block;">
							<div class="form-container">
							  
							  <mat-form-field *ngIf="this.settingsService.settings[settingIndex]['setting']=='defaultseasonstart' || this.settingsService.settings[settingIndex]['setting']=='seasonstart'">
    							<mat-label>Date</mat-label>
					    		<input matInput [matDatepicker]="date" [(ngModel)]="this.settingsService.settings[settingIndex]['value']">
									<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
							    <mat-datepicker #date></mat-datepicker>
							  </mat-form-field>
							  
							  <mat-form-field *ngIf="this.settingsService.settings[settingIndex]['setting']=='deliverynostart'">
    							<mat-label>Value</mat-label>
							    <mat-select placeholder="Value" [(ngModel)]="this.settingsService.numberMode" (selectionChange)="this.settingsService.setValue(settingIndex)">
							      <mat-option value="continue">Continue from Last Season</mat-option>
							      <mat-option value="reset">Reset to 1</mat-option>
							      <mat-option value="set">Start from:</mat-option>
							    </mat-select>
							  </mat-form-field>
							  
							  <mat-form-field *ngIf="this.settingsService.settings[settingIndex]['setting']=='deliverynostart' && this.settingsService.numberMode=='set'">
    							<mat-label>Season's Frist Delivery Number</mat-label>
					    		<input matInput type="number" min="0" placeholder="Season's Frist Delivery Number" [(ngModel)]="this.settingsService.firstNumber" (blur)="this.settingsService.setValue(settingIndex)">
							  </mat-form-field>
							  
							  <mat-form-field *ngIf="this.settingsService.settings[settingIndex]['setting']=='defaultpricemodel'">
    							<mat-label>Price Model</mat-label>
							    <mat-select placeholder="Price Model" [(ngModel)]="this.settingsService.settings[settingIndex]['value']">
							      <mat-option *ngFor="let model of this.dropDownDataService.dropdown.priceModelListAll" value="{{ model.modelno }}">{{ model.model }}</mat-option>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-3 px-0" style="display: inline-block;">
							<div class="form-container">

						  	<button mat-icon-button class="col" *ngIf="this.settingsService.settings.length>1" (click)="this.settingsService.removeItem(settingIndex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

							</div>
						</div>

					</div>
				</ng-container>

				
			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.settingsService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.settingsService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.settingsService.saveChanges()">Update</button>

			<button *ngIf="this.settingsService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>