import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { AddOrderService } from '../../logging/add-order/add-order.service';
import { PortalService } from '../../../auth/portal.service';
import { TeamAuthService } from '../../auth/auth.service';
import { CountryService } from '../../../services/countries.service';

@Component({
  selector: 'app-teamorder',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class TeamOrderComponent implements OnInit {
  public activeIndex;

  constructor(
    private router: Router,
    public aS: TeamAuthService,
    private lS: TeamLoginService,
    public tS: TableDataService,
    private aOS: AddOrderService,
    private dDDS: DropDownDataService,
    public countryService: CountryService,
    public pS: PortalService
    ) { }

  ngOnInit() {
    this.dDDS.getDropdown("contactList");
    this.dDDS.getDropdown("buyerList");
    this.dDDS.getDropdown("contactList");
    this.dDDS.getDropdown("orderList");
    this.dDDS.getDropdown("freightList");
    this.dDDS.getDropdown("consigneeList");
    this.dDDS.getDropdown("logisticsList");
    this.dDDS.getDropdown("courierList");
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  ngOnDestroy() {
    this.tS.clickedIndex['orders'] = null;
  }

  editOrder() {
    this.aOS.editorderref = this.tS['orders'][this.tS.clickedIndex['orders']].orderref;
    this.aOS.editmode = true;
    this.aOS.loadForEdit();
    this.router.navigate(['/team/logging/order']);
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  viewContact(idcontact) {
    this.tS.reset('contacts');
    this.tS.setDelivery(idcontact, 'contacts');
    this.tS.indexLink = true;
    this.router.navigate(['/team/table/contacts']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  viewCompany(idcompany) {
    this.tS.reset('companies');
    this.tS.setDelivery(idcompany, 'companies');
    this.tS.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }
  
}
