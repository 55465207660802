<div class="container px-0" [ngClass]="classState">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3 px-0" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ms-0 main-header">
				<h2 *ngIf="!this.aPS.editmode">Add Pallet Sample</h2>
				<h2 *ngIf="this.aPS.editmode">Edit Pallet Sample</h2>
			</div>
			<div class="mr-4 me-9 col-auto" *ngIf="!this.aPS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/palletsamples">View Pallet Samples</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Pallet Sample</button>
			</div>
			<div class="mr-4 me-9 col-auto" *ngIf="this.aPS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/palletsamples">View Pallet Samples</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Pallet Sample</button>
			</div>
		</div>
		<br>

		<ng-container *ngIf="!this.aPS.editmode && isAuthenticated('nisadmin')">
			<div class="col-6" style="display: inline-block;">				 	
				<div class="form-container">
				  <mat-form-field>
		  			<mat-label>Pallet Line - Manual</mat-label>
    				<mat-select placeholder="Pallet Line - Manual" [(ngModel)]="this.aPS.palletline">
			      	<ng-container *ngFor="let line of this.lines; let i=index">
				      	<mat-option value="{{ i }}">{{ line }}</mat-option>
				      </ng-container>
				    </mat-select>
				  </mat-form-field>
				</div>
			</div>
			<div class="col-6" style="display: inline-block;">				 	
				<div class="form-container">
				  <mat-form-field>
		  			<mat-label>Pallet ID - Manual</mat-label>
				    <input matInput type="number" placeholder="Pallet ID" maxlength="90" [(ngModel)]="this.aPS.manualpallet" (ngModelChange)="this.aPS.setManualPallet()">
				  </mat-form-field>
				</div>
			</div>
		</ng-container>

		<app-scanner #scanner *ngIf="this.aPS.pallet==null && !this.aPS.editmode && this.aPS.manualpallet==null"
			(scanSuccess)="handleQrCodeResult($event)">
		</app-scanner>

		<div class="row justify-content-between">
			<div class="col" style="display: inline-block;">
			 	
				<div class="form-container">

					<!-- Edit Pallet Number -->
				  <mat-form-field *ngIf="this.aPS.editmode">
  					<mat-label>Pallet ID</mat-label>
				    <mat-select placeholder="Pallet ID" [(ngModel)]="this.aPS.idpallet" (keydown.tab)="loadForEdit()">
				      <mat-option *ngFor="let pallet of this.ddS.dropdown.palletList" #prod (click)="loadForEdit()" value="{{ pallet.idpallet }}">{{ this.lines[pallet.line] }} {{ pallet.pallet }}</mat-option>
				    </mat-select>
				  </mat-form-field>

			  	<br *ngIf="this.aPS.editmode">

				 </div>
			</div>

		</div>

		<ng-container *ngIf="editSelected()">

			<div *ngIf="this.aPS.manualpallet!=null">
		
				<hr>

				<div class="col-6" style="display: inline-block;">				 	
					<div class="form-container">

					  <mat-form-field>
		    			<mat-label>Pallet ID</mat-label>
					    <input matInput placeholder="Pallet ID" disabled maxlength="90" [ngModel]="this.aPS.palletname">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="this.aPS.code['packdate']==''">
	    				<mat-label>Pack Date</mat-label>
					    <input matInput [matDatepicker]="packdate" [(ngModel)]="this.aPS.code['packdate']" (ngModelChange)="this.aPS.fixDate('packdate')">
							<mat-datepicker-toggle matIconSuffix [for]="packdate"></mat-datepicker-toggle>
					    <mat-datepicker #packdate></mat-datepicker>
					  </mat-form-field>

					</div>
				</div>

				<div class="col-6" style="display: inline-block;">
					<div class="form-container">

					  <mat-form-field [class.errorcheck]="this.aPS.code['stylefull']==''">
		    			<mat-label>Style</mat-label>
	    				<mat-select placeholder="Style" [(ngModel)]="this.aPS.code['stylefull']">
					      <ng-container *ngFor="let product of this.ddS.dropdown['productList']">
					      	<mat-option *ngIf="product['type']=='nis'" (click)="this.aPS.setProduct(product['code'])" value="{{ product['style'] }}">{{ product['style'] }}</mat-option>
					      </ng-container>
					    </mat-select>
					  </mat-form-field>
	 	
						<div>

						  <mat-form-field class="ps-0 col-6" [class.errorcheck]="this.aPS.code['idstorage']==null">
			    			<mat-label>Storage</mat-label>
						    <mat-select placeholder="Storage" [(ngModel)]="this.aPS.code['idstorage']">
						    	<ng-container *ngFor="let storage of this.ddS.dropdown.storageListWNIS">
						      	<mat-option value="{{ storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>
						  
						  <mat-form-field class="pe-0 col-6" [class.errorcheck]="this.aPS.code['iteration']==null">
			    			<mat-label>Iteration</mat-label>
						    <input matInput placeholder="Iteration" type="number" maxlength="90" [(ngModel)]="this.aPS.code['iteration']">
						  </mat-form-field>
						
						</div>

					</div>
				</div>

			</div>

			<div *ngIf="this.aPS.manualpallet==null">
		
				<hr>

				<div class="col-6" style="display: inline-block;">				 	
					<div class="form-container">

					  <mat-form-field>
		    			<mat-label>Pallet Line</mat-label>
	    				<mat-select placeholder="Pallet Line" disabled [(ngModel)]="this.aPS.palletline">
				      	<ng-container *ngFor="let line of this.lines; let i=index">
					      	<mat-option value="{{ i }}">{{ line }}</mat-option>
					      </ng-container>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field>
		    			<mat-label>Style</mat-label>
	    				<mat-select placeholder="Style" [(ngModel)]="this.aPS.code['stylefull']">
					      <ng-container *ngFor="let product of this.ddS.dropdown['productList']">
					      	<mat-option *ngIf="product['type']=='nis'" (click)="this.aPS.setProduct(product['code'])" value="{{ product['style'] }}">{{ product['style'] }}</mat-option>
					      </ng-container>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field>
		    			<mat-label>Batch</mat-label>
					    <input matInput placeholder="Batch" disabled maxlength="90" [(ngModel)]="this.aPS.code['batch']">
					  </mat-form-field>

					</div>
				</div>

				<div class="col-6" style="display: inline-block;">				 	
					<div class="form-container">

					  <mat-form-field>
		    			<mat-label>Pallet ID</mat-label>
					    <input matInput placeholder="Pallet ID" disabled maxlength="90" [(ngModel)]="this.aPS.palletname">
					  </mat-form-field>

					  <mat-form-field>
		    			<mat-label>Pack Date</mat-label>
					    <input matInput placeholder="Pack Date" disabled maxlength="90" [(ngModel)]="this.aPS.code['packdate']">
					  </mat-form-field>

					</div>
				</div>

			</div>

			<hr>

			<div class="col-6" style="display: inline-block;">
				<div class="form-container">

				  <mat-form-field [class.errorcheck]="!checktotalkernel()" [class.errorcheck]="this.aPS.dnis==null">
  					<mat-label>Sample Dry Mass NIS (g)</mat-label>
				    <input matInput type="number" min="0" placeholder="Sample Dry Mass (g)" [(ngModel)]="this.aPS.dnis">
				  </mat-form-field>

				  <mat-form-field [class.errorcheck]="this.aPS.moisture==null" [class.errorcheck]="!checkMoisture()">
  					<mat-label>Dry Kernel Moisture (%)</mat-label>
				    <input matInput type="number" min="0" step="0.01" max="50" placeholder="Dry Kernel Moisture (%)" [(ngModel)]="this.aPS.moisture">
				  </mat-form-field>

				  <mat-form-field [class.errorcheck]="!checktotalkernel() ||  this.aPS.shell==null">
  					<mat-label>Shells (g)</mat-label>
				    <input matInput type="number" min="0" placeholder="Shells (g)" [(ngModel)]="this.aPS.shell" (ngModelChange)="this.aPS.getTK()" (keyup)="this.aPS.getTK()">
				  </mat-form-field>

				</div>
			</div>

			<div class="col-6" style="display: inline-block;">
				<div class="form-container">

				  <mat-form-field [class.errorcheck]=" !checktotalkernel() ||  this.aPS.sk==null">
  					<mat-label>Sound Kernel (g)</mat-label>
				    <input matInput type="number" min="0" placeholder="Sound Kernel (g)" [(ngModel)]="this.aPS.sk" (ngModelChange)="this.aPS.getTK()" (keyup)="this.aPS.getTK()">
				  </mat-form-field>

				  <mat-form-field>
  					<mat-label>Darks & Onion Ring (g)</mat-label>
				    <input matInput type="number" min="0" placeholder="Darks & Onion Ring (g)" [(ngModel)]="this.aPS.dark" (ngModelChange)="this.aPS.getTK()" (keyup)="this.aPS.getTK()">
				  </mat-form-field>

				  <mat-form-field>
  					<mat-label>Unsound Kernel (g)</mat-label>
				    <input matInput type="number" disabled placeholder="Unsound Kernel (g)" [ngModel]="this.aPS.uk">
				  </mat-form-field>

				  <mat-form-field [class.errorcheck]="!checktotalkernel()">
  					<mat-label>Total Kernel (g)</mat-label>
				    <input matInput type="number" disabled placeholder="Total Kernel (g)" [ngModel]="this.aPS.tk">
				  </mat-form-field>

				</div>
			</div>

			<hr>

			<div>
				<div class="col-6" style="display: inline-block;">
				 	
					<div class="form-container">

					  <mat-form-field>
    					<mat-label>Total number of nuts</mat-label>
					    <input matInput type="number" [disabled]="this.aPS.dnisunit=='count'" min="0" placeholder="Total number of nuts" [(ngModel)]="this.aPS.currentcount">
					  </mat-form-field>

					  <mat-form-field>
	  					<mat-label>DNIS Unit</mat-label>
					    <mat-select placeholder="DNIS Unit" [(ngModel)]="this.aPS.dnisunit" (keydown.tab)="this.aPS.convertdnis()">
					      <mat-option #unit (click)="this.aPS.convertdnis()" value="count">Number of nuts</mat-option>
					      <mat-option #unit (click)="this.aPS.convertdnis()" value="weight">Weight (grams)</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkdnis()">
    					<mat-label>DNIS: 18-20mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})</mat-label>
					    <input matInput type="number" min="0" placeholder="DNIS: 18-20mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})" [(ngModel)]="this.aPS.a18" (ngModelChange)="this.aPS.getCount()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkdnis()">
    					<mat-label>DNIS: 20-22mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})</mat-label>
					    <input matInput type="number" min="0" placeholder="DNIS: 20-22mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})" [(ngModel)]="this.aPS.a20" (ngModelChange)="this.aPS.getCount()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkdnis()">
    					<mat-label>DNIS: 22-24mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})</mat-label>
					    <input matInput type="number" min="0" placeholder="DNIS: 22-24mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})" [(ngModel)]="this.aPS.a22" (ngModelChange)="this.aPS.getCount()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkdnis()">
    					<mat-label>DNIS: +24mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})</mat-label>
					    <input matInput type="number" min="0" placeholder="DNIS: +24mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})" [(ngModel)]="this.aPS.a24" (ngModelChange)="this.aPS.getCount()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkdnis()">
    					<mat-label>DNIS: +25mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})</mat-label>
					    <input matInput type="number" min="0" placeholder="DNIS: +25mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})" [(ngModel)]="this.aPS.a25" (ngModelChange)="this.aPS.getCount()">
					  </mat-form-field>

					  <mat-form-field [class.errorcheck]="!checkdnis()">
    					<mat-label>DNIS: +26mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})</mat-label>
					    <input matInput type="number" min="0" placeholder="DNIS: +26mm ({{ (this.aPS.dnisunit=='count' ? 'no. of nuts' : 'g') }})" [(ngModel)]="this.aPS.a26" (ngModelChange)="this.aPS.getCount()">
					  </mat-form-field>

					</div>
				</div>

				<div class="col-6" style="display: inline-block;">
					<div class="form-container">

					  <mat-form-field>
    					<mat-label>Old/Mould/Rotten (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Old/Mould/Rotten (g)" [(ngModel)]="this.aPS.mould" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Immature (sinkers) (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Immature (sinkers) (g)" [(ngModel)]="this.aPS.immature" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>791 Spot (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="791 Spot (g)" [(ngModel)]="this.aPS.spot791" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Early Stinkbug (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Early Stinkbug (g)" [(ngModel)]="this.aPS.earlystink" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Late Stinkbug (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Late Stinkbug (g)" [(ngModel)]="this.aPS.latestink" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Early Germination (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Early Germination (g)" [(ngModel)]="this.aPS.germination" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>FCM / Nut Borer (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="FCM / Nut Borer (g)" [(ngModel)]="this.aPS.nutborer" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Discolouration (g)</mat-label>
					    <input matInput type="number" min="0" placeholder="Discolouration (g)" [(ngModel)]="this.aPS.discolouration" (keyup)="this.aPS.getUK()" (ngModelChange)="this.aPS.getUK()">
					  </mat-form-field>   
					  
					</div>
				</div>

				<hr>

				<div class="col" style="display: inline-block;">

					<mat-form-field style="width:100%">
    				<mat-label>Notes</mat-label>
					  <input type="text" matInput placeholder="Notes" [(ngModel)]="this.aPS.note">
					</mat-form-field>

				</div>

				<hr>
				
				<div class="row justify-content-between px-2">
					<button *ngIf="!this.aPS.editmode" mdbBtn class="btn btn-primary cust-btn" [disabled]="!canSave()" (click)="saveSample()">Save</button>
					<button *ngIf="this.aPS.editmode" mdbBtn class="btn btn-primary cust-btn" [disabled]="!canSave()" (click)="this.aPS.saveChanges()">Update</button>	
					<button *ngIf="this.aPS.editmode" mdbBtn class="btn btn-danger cust-btn" (click)="onDelete()">Delete</button>
				</div>

				<br><br>
			</div>

		</ng-container>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>