import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { Batch } from './add-processing.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddProcessingService{
	private postBody: {};
	httpRes: any;
	d: Date = new Date();
	public createform: boolean;
	public editmode: boolean = false;
	public loadedEntry: any;
  public date: string;
  public idprocessing: number;
  public batch: Batch = {"name": null, "type": null, "idstorage": null, "idbatch": null};
  public batch2: Batch = {"name": null, "type": null, "idstorage": null, "idbatch": null};
  public process: string = 'cracking';
	public storage: string;
	public destination: string;
	public overfill: string;
  public quantity: number;
  public quantity2: number;
  public note: string;
  public batchcomplete: boolean = false;
  public batchcompleteB: boolean = false;
  public selectedNISweight: number;
  public selectedNIScount: number;
  public autonis: boolean = false;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dropDownDataService: DropDownDataService) {}

	addProcessing() {
		this.fixDate();
		this.postBody = {
			"name": "addProcess",
			"param":{
				"location": +this.dropDownDataService.location['idlocation'],
				"season": +this.loginService.season,
				"date": this.date,
				"process": this.process,
				"idbatch": this.batch['idbatch'],
				"idbatch2": this.batch2['idbatch'],
				"quantity": this.quantity,
				"quantity2": this.quantity2,
				"destination": this.destination,	
				"overfill": this.overfill,	
				"complete": this.batchcomplete,	
				"completeB": this.batchcompleteB,				
				"notes": (this.note == null ? "" : this.note),
			}
		};console.log(this.postBody);
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	console.log(response);
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Processing log was not updated, please try update current entry instead",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	loadForEdit() { 
		this.postBody = {
			"name": "loadProcessing",
			"param":{
				"idprocessing": +this.idprocessing
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) { 
		this.loadedEntry = rawData.response.result.reply[0];
		this.idprocessing = this.loadedEntry.idprocessing;
		this.quantity = this.loadedEntry.quantity;
		this.date = this.loadedEntry.date.slice(0,10);
		this.process = this.loadedEntry.process;
		this.storage = this.loadedEntry.origin;
		this.note = this.loadedEntry.notes;
	}

	saveChanges() {
		this.fixDate();
		this.postBody = {
			"name": "updateProcessing",
			"param":{
				"idprocessing": +this.idprocessing,
				"location": +this.dropDownDataService.location['idlocation'],
				"season": +this.loginService.season,
				"date": this.date,
				"process": this.process,
				"overfill": this.overfill,
				"quantity": this.quantity,	
				"complete": this.batchcomplete,		
				"notes": (this.note == null ? "" : this.note),
			}
		};console.log(this.postBody);
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	console.log(response);
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']);
	        	this.editmode=false;
	        	this.idprocessing=0;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Processing entry was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() { 
		this.postBody = {
			"name": "deleteProcessing",
			"param":{
				"idprocessing": +this.idprocessing,
				"process": this.process,
				"date": this.date
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']);
	        	this.editmode=false;
	        	this.idprocessing=0;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Processing entry was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
    //this.date = this.d.toISOString().substring(0,10);
    this.dropDownDataService.getDropdown("locationList");
    this.process = 'cracking';
    this.batch = {"name": null, "type": null, "idstorage": null, "idbatch": null};
    this.batch2 = {"name": null, "type": null, "idstorage": null, "idbatch": null};
    this.destination = null;
    this.overfill = null;
    this.quantity = null;
    this.quantity2 = null;
    this.note = null;
    this.dropDownDataService.getDropdown("processingList");
    this.dropDownDataService.getDropdown("processList");
    this.idprocessing = 0;
    this.batchcomplete = false;
  }

	fixDate() {
	  this.date = moment(this.date).format('YYYY-MM-DD');
	}

}