import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PortalService } from '../../../auth/portal.service';


@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent implements OnInit {
	loginForm: UntypedFormGroup;
  httpRes: any;

  constructor(
    private route: ActivatedRoute,
    public portalService: PortalService,
    private router: Router) { }

  ngOnInit() {
    window.scroll(0,0);
  }

}