import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { CsvService } from '../../../../services/csv.service';

import { PortalService } from '../../../../auth/portal.service';
import { TeamLoginService } from '../../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../../logging/dropdown.service';
import { TableDataService } from '../../../overview/table/table.service';
//import { TeamCompanyService } from '../../../overview/companies/teamcompanies.service';
//import { TeamContactService } from '../../../overview/contacts/teamcontacts.service';
import { SettingsClientsService } from '../clients.service';


@Component({
  selector: 'app-settings-clients-activity',
  templateUrl: './clients-activity.component.html',
  styleUrls: ['./clients-activity.component.scss']
})
export class SettingsClientsActivityComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  d: Date;
  scroll: boolean = true;
  isLoadingResults = true;

  constructor(
    public portalService: PortalService,
    public loginService: TeamLoginService,
  	public clientsService: SettingsClientsService,
    public dropDownDataService: DropDownDataService,
    public tableService: TableDataService,
    //public companyService: TeamCompanyService,
    //public contactService: TeamContactService,
    private router: Router,
    private http: HttpClient,
    public csvService: CsvService
  	) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.clientsService.idcontact = 0;
    this.clientsService.resetForm();
    this.clientsService.setTable("recentClientLoginsByDate");
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.clientsService.clickedIndex !== null;
  }

  async viewContact(idcontact) {
    this.tableService.reset('contacts');
    this.tableService.setDelivery(idcontact, 'contacts');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/contacts']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  async viewCompany(idcompany) {
    this.tableService.reset('companies');
    this.tableService.setDelivery(idcompany, 'companies');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100);
  }

  compDates(index: number) {
    if (index==0) {
      return false;
    }
    return this.clientsService.activityData[this.clientsService.dataRequest][index - 1]['time'].substr(0,10)!==this.clientsService.activityData[this.clientsService.dataRequest][index]['time'].substr(0,10);
  }

  csv(datatable) {
    var thistable=document.getElementById(datatable);
    var title = 'Client Activity: ' + this.clientsService.dataRequest;
    this.csvService.csvSimple(thistable, title);
  }

}