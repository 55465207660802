import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { PortalService } from '../../../auth/portal.service';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { DropDownDataService } from '../dropdown.service';
import { TableDataService } from '../../overview/table/table.service';
import { Storage } from '../../../interfaces/dropdown.interface';
import { Delivery, Contacts } from '../../../interfaces/deliveries.interface';
import { Cultivars } from '../../../interfaces/companies.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddDeliveryService{
	private postBody: {};
	httpRes: any;
	d: Date = new Date();
	public editmode: boolean = false;
	public validConfirm: boolean = false;
	public method: number = null;
	public iddelivery: number;
	public iddepot: number;
	public iddeldepot: number;
	public iddeliveryTemp: number;
	public iddelfarm: string;
	public loadedEntry: Delivery;
	public thisCompanyContacts: Contacts[];
	public thisCompanyContact: string;
	public season: number;
	public location: number;
	public delivery: number;
	public deliverydate: string;
	public deliverytime: string; 
	public loaddate: string;
	public depotdate: string;
	public producer: string;
	public contact: string;
	public wnis: number;
	public scalewnis: number;
	public smalls: string;
	public cultivar: string;
	public selectout: boolean = false;
	public storage: string;
	public collected: boolean;
	public paidCollection: boolean;
	public pricemodel: string = "0";
 	public paypercent: string;
 	public payday: string;
 	public paypercentU: string;
 	public paydayU: string;
 	public epaypercent: string;
 	public epayday: string;
 	public epaypercentU: string;
 	public epaydayU: string;
	public note: string; 
	public status: string = 'final';
	public tempstatus: string;
	public cultivars: Cultivars [] = [];
	public storageOptions: Storage [];
	public altStorageOptions: Storage [];
	public storages: Storage [] = [{"idstorage": "", "wnis": null}];
	public stats: string [];
  public isStats: boolean = false;
	public dehusking: boolean = false;
	public huskmass: number;
	public rejecthuskmass: number;
	public depotsmalls: number;
	public depotwnis: number;
	public depotwnisd: number;
	public siloselsave:boolean = false;
	public d18: number;
	public d20: number;
	public d22: number;
	public d25: number;
	public d1825: number = 0;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private portalService: PortalService,
		public splitTermsService: SplitTermsService,
		private dDS: DropDownDataService,
		public tableService: TableDataService) {}


	addDelivery() {
		var allowsave = true;
		if (this.status!=='draft' && this.dDS.payterms!=='default') {
			this.dDS.combineTerms();
			allowsave = this.dDS.checkTerms();
		}
		if (this.checkTerms() && allowsave) {
			var deliveryTemp = this.delivery;
			this.fixDate('deliverydate');
			this.fixDate('loaddate');
			this.postBody = {
				"name": "addDelivery",
				"param":{
					"noemail": (this.portalService.mode=='dev' ? 1 : 0),
					"location": this.location,
					"season": this.season,
					"delivery": this.delivery,
					"iddelfarm": this.iddelfarm,
					"date": this.deliverydate,
					"ldate": this.loaddate,
					"time": this.deliverytime,
					"producer": this.producer,
					"contact": this.thisCompanyContact,
					"wnis": +this.wnis,
					"scalewnis": this.scalewnis,
					"smalls": this.smalls,
					"cultivar": this.cultivar,
					"selectout": (this.selectout ? 1 : 0),
					"storages": this.storages,
					"collection": (this.collected ? 1 : 0),
					"paidcollection": (this.paidCollection ? 1 : 0),
					"pricemodel": +this.pricemodel,
					"paypercent": this.dDS.paypercent,
					"payday": this.dDS.payday,
					"epaypercent": this.dDS.epaypercent,
					"epayday": this.dDS.epayday,
					"payterms": this.dDS.payterms,
					"d18": this.d18,
					"d20": this.d20,
					"d22": this.d22,
					"d25": this.d25,
					"notes": (this.note == null ? "" : this.note),
					"status": this.status
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response) => {
	        	try {
	        		this.loginService.setSession(response);
		        	this.loginService.toast("Save Successful","Delivery " + deliveryTemp + " was successfully saved.");
		        	this.resetForm();
		        } catch(e) {
		        	this.loginService.toast("Save Unsuccessful","Delivery not saved!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
		}
	}

	async loadDelivery(depot: boolean = false) {
		this.postBody = {
			"name": (depot ? "loadDepotDelivery" : "loadDelivery"),
			"param": (depot ? {"iddeldepot": +this.iddeldepot, "season": this.loginService.season} : {"iddelivery": +this.delivery, "season": this.loginService.season} )
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				}
			}
		)
		return response.json();
	}

	loadForEdit(depot: boolean = false) {
		this.loadDelivery(depot)
		  .then(response => {
		  	this.loginService.setSession(response);
		  	this.loadEntry(response);
        if (depot) {
        	this.getSilo();
        }
		  })
		  .catch(error => console.log(error));
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply;
		this.iddelfarm = (this.loadedEntry.iddelfarm==null || this.loadedEntry.iddelfarm=='' ? null : this.loadedEntry.iddelfarm);
		this.season = +this.loadedEntry.season;
		this.location = (this.loadedEntry.location==null ? null : +this.loadedEntry.location);
		this.delivery = +this.loadedEntry.iddelivery;
		this.getEditMethod();
		this.iddelivery = +this.delivery;
		this.iddeldepot = +this.loadedEntry.iddeldepot;
		this.iddepot = +this.loadedEntry.iddepot;
		this.deliverydate = this.loadedEntry.ddate;
		this.deliverytime = this.loadedEntry.dtime;
		this.loaddate = this.loadedEntry.ldate;
		this.depotdate = this.loadedEntry.depotdate;
		this.producer = this.loadedEntry.idproducer;
		this.cultivars = this.dDS.dropdown.cultivarProfileList;
		this.cultivar = this.loadedEntry.dcultivar;
		this.selectout = (+this.loadedEntry.selectout == 1 ? true : false);
		this.thisCompanyContacts = this.loadedEntry.contacts;
		this.thisCompanyContact = this.loadedEntry.dcontact;
		this.wnis = +this.loadedEntry.dwnis;
		this.scalewnis = +this.loadedEntry.scalewnis;
		this.smalls = +this.loadedEntry.smalls + '';
		this.dehusking = (+this.loadedEntry.dehusking == 1 ? true : false );
		this.huskmass = +this.loadedEntry.huskmass;
		this.rejecthuskmass = +this.loadedEntry.rejecthuskmass;
		this.depotsmalls = +this.loadedEntry.depotsmalls;
		this.depotwnis = +this.loadedEntry.depotwnis;
		this.depotwnisd = +this.loadedEntry.depotwnisd;
		this.storages = this.loadedEntry.storages;
		this.collected = (+this.loadedEntry.dcollected == 1 ? true : false );
		this.paidCollection = (+this.loadedEntry.dpaidcollection == 1 ? true : false );
		this.d18 = this.loadedEntry.d18;
		this.d20 = this.loadedEntry.d20;
		this.d22 = this.loadedEntry.d22;
		this.d25 = this.loadedEntry.d25;
		this.sizednis();
		this.pricemodel = (this.loadedEntry['dpricemodel'] !== null ? this.loadedEntry['dpricemodel'] : (this.loadedEntry['pricemodel']==undefined ? null : this.loadedEntry['pricemodel'] ));
		this.dDS.paypercentU = (this.loadedEntry['dcustompaypercent'] != undefined ? this.loadedEntry.dcustompaypercent : this.dDS.dropdown.priceTermsList[0].paypercent);
	 	this.dDS.paydayU = (this.loadedEntry['dcustompayday'] != undefined ? this.loadedEntry.dcustompayday : this.dDS.dropdown.priceTermsList[0].payday);
		this.dDS.epaypercentU = (this.loadedEntry['ecustompaypercent'] != undefined ? this.loadedEntry.ecustompaypercent : this.dDS.dropdown.priceTermsList[0].epaypercent);
	 	this.dDS.epaydayU = (this.loadedEntry['ecustompayday'] != undefined ? this.loadedEntry.ecustompayday : this.dDS.dropdown.priceTermsList[0].epayday);
	  this.dDS.payterms = this.loadedEntry.payterm;
	  this.dDS.setPModel(+this.pricemodel,this.editmode);
		this.dDS.tagserialsU = this.loadedEntry.tagserials;
	  this.dDS.splitTags();
		this.note = this.loadedEntry.note;
		this.tempstatus = this.loadedEntry.status;
	}

	saveChanges() {
		var allowsave = true;
		if (this.status!=='draft') {
			this.dDS.combineTerms();
			allowsave = this.dDS.checkTerms();
		}
		if (this.iddepot>0 && this.iddeldepot>0) {
			this.dDS.combineTags();
		}
		if (this.checkTerms() && allowsave) {
			this.iddeliveryTemp = (this.iddeldepot>0 ? this.delivery : this.iddelivery);
			this.fixDate('deliverydate');
			this.fixDate('loaddate');
			this.fixDate('depotdate');
			this.postBody = {
				"name": "updateDelivery",
				"param":{
					"noemail": (this.portalService.mode=='dev' ? 1 : 0),
					"iddelivery": +(this.siloselsave ? this.delivery : this.iddelivery),
					"delivery": +this.delivery,
					"iddepot": this.iddepot,
					"iddeldepot": this.iddeldepot,
					"iddelfarm": this.iddelfarm,
					"location": this.location,
					"season": this.season,
					"date": this.deliverydate,
					"ldate": this.loaddate,
					"time": this.deliverytime,
					"producer": this.producer,
					"contact": this.thisCompanyContact,
					"wnis": +this.wnis,
					"scalewnis": this.scalewnis,
					"smalls": this.smalls,
					"cultivar": this.cultivar,
					"selectout": (this.selectout ? 1 : 0),
					"storages": this.storages,
					"collection": (this.collected ? 1 : 0),
					"paidcollection": (this.paidCollection ? 1 : 0),
					"pricemodel": +this.pricemodel,
					"paypercent": this.dDS.paypercent,
					"payday": this.dDS.payday,
					"epaypercent": this.dDS.epaypercent,
					"epayday": this.dDS.epayday,
					"payterms": this.dDS.payterms,
					"d18": this.d18,
					"d20": this.d20,
					"d22": this.d22,
					"d25": this.d25,
					"depotdate": this.depotdate,
					"dehusking": (this.dehusking ? 1 : 0),
					"huskmass": this.huskmass,
					"rejecthuskmass": this.rejecthuskmass,
					"depotsmalls": (this.depotsmalls>0 ? this.depotsmalls : 0),
					"depotwnis": this.depotwnis,
					"depotwnisd": (this.depotwnisd==undefined ? 0 : this.depotwnisd),
					"tagserials": this.dDS.tagserials,
					"notes": (this.note == null ? "" : this.note),
					"status": this.status
				}
			};
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
					})
				}
				)
				.subscribe(
	        (response: any) => {
	        	try {
	        		this.loginService.setSession(response);
	        		this.loginService.toast("Update Successful",response['response']['result']['reply']);
		        	this.editmode=false;
		        	this.resetForm();
		        } catch(e) {
		        	this.loginService.toast("Update Unsuccessful","Delivery was not updated. If a sample entry was made, please delete this first!",'warn');
		        }
	        },
	        (error) => console.log(error)
	      );
		}
	}

	deleteEntry() {
		this.postBody = {
			"name": "deleteDelivery",
			"param":{
				"iddelivery": +this.iddelivery,
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful","Delivery number " + this.iddelivery + " was successfully deleted.");
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Delivery was not deleted!,'warn'");
	        }
        },
        (error) => console.log(error)
      );
	}

	validateDelivery() {
		this.postBody = {
			"name": "validateDelivery",
			"param":{
				"iddelivery": +this.iddelivery,
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
   					this.loginService.toast("Validation Successful",response['response']['result']['reply']);
				    this.tableService.loadData('deliveries');
	        } catch(e) {
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm(keepdel = false) {
    window.scroll(0,0);
    this.method = null;
  	this.dDS.payterms = 'default';
  	this.dDS.items = [{"estimate": 0,"type": "", "percent": "", "timing": "","timingDate": "","weekday": "","order": ""}];
    this.dDS.getDropdown("newDeliveryNumber");
    this.dDS.getDropdown("locationList");
    this.season = +this.loginService.season;
    this.iddepot = null;
    this.iddeldepot = null;
    this.iddelfarm = null;
    this.delivery = (keepdel ? this.delivery : null);
    this.deliverydate = this.d.toISOString().substring(0,10);
    this.deliverytime = "12:00";
    this.loaddate = this.deliverydate;
    this.producer = null;
    this.thisCompanyContacts = null;
    this.wnis = null;
    this.scalewnis = null;
    this.smalls = null;
    this.cultivar = null;
    this.selectout = false;
    this.storages = [{"idstorage": "", "wnis": null}];
    this.storageOptions = null;
    this.altStorageOptions = null;
    this.collected = false;
    this.paidCollection =false;
    this.pricemodel = "0";
    this.depotdate = null;
		this.dehusking = null;
		this.huskmass = null;
		this.rejecthuskmass = null;
		this.depotsmalls = null;
		this.depotwnis = null;
		this.depotwnisd = null;
		this.dDS.tagserials = null;
    this.dDS.tags = [{"serial": ""}];  
    this.dDS.paypercent = "0";
    this.dDS.payday = "0";
    this.dDS.paypercentU = "";
    this.dDS.paydayU = "";
    this.dDS.epaypercent = "0";
    this.dDS.epayday = "0";
    this.dDS.epaypercentU = "";
    this.dDS.epaydayU = "";
		this.d18 = null;
		this.d20 = null;
		this.d22 = null;
		this.d25 = null;
		this.d1825 = 0;
    this.note = "";
    this.siloselsave = false;
  }

  setwnis(option = 0, index = null, value = null) {
  	this.smalls = +(this.smalls==undefined ? 0 : this.smalls) + '';
  	this.wnis = +this.scalewnis - +this.smalls;
  	if (+this.smalls!==this.scalewnis && +this.smalls>0 && this.storages.length<2) {
			this.storages[0]['wnis'] = this.scalewnis - +this.smalls;
			this.addItem();
		}
  	if (this.storages.length==1) {
  		this.storages[0]['wnis'] = this.scalewnis;
  	} else if (this.storages.length==2) {
  		this.storages[0]['wnis'] = this.scalewnis - +this.smalls;
  	} 
  	if (this.storages.length>1) {
  		var sum = 0;
  		for (var i = 0; i < this.storages.length - 1; i++) {
	  		sum = sum + (option==2 && index==i ? value : +this.storages[i]['wnis']);
	  		if (+this.storages[i]['wnis']==0 && option!=1) {
	  			this.removeItem(i);
	  		}
  		}
			this.storages[this.storages.length - 1]['wnis'] = this.scalewnis - sum;
  		if (sum==this.scalewnis && option!=1) {
				this.removeItem(this.storages.length - 1);
			}
  	}
  }

  sizednis() {
  	this.d1825 = Math.round(((this.d18==null ? 0 : +this.d18) + (this.d20==null ? 0 : +this.d20) + +(this.d22==null ? 0 : +this.d22) + (this.d25==null ? 0 : +this.d25)) * 100)/100;
	}

	setDefaultMethod(){
		var pricemodel = this.dDS.dropdown.defaultPriceModel['modelno'];
  	if (pricemodel>0) {
			for (var i=0; i<this.dDS.dropdown.priceModelList.length; i++) {
	  		if (this.dDS.dropdown.priceModelList[i]['modelno']==pricemodel) {
		  		this.method = this.dDS.dropdown.priceModelList[i]['method'];
		  	}
		  }
	  }
	}

	getAddMethod() {
		var pricemodel = 0;
		for (var i=0; i<this.dDS.dropdown.producerList.length; i++) {
  		if (this.dDS.dropdown.producerList[i]['idcompany']==this.producer) {
  			pricemodel = (this.dDS.dropdown.producerList[i]['season']==this.loginService.season ? this.dDS.dropdown.producerList[i]['pricemodel'] : this.dDS.dropdown.defaultPriceModel['modelno']);
  		}
  	}
  	if (pricemodel>0) {
			for (var i=0; i<this.dDS.dropdown.priceModelList.length; i++) {
	  		if (this.dDS.dropdown.priceModelList[i]['modelno']==pricemodel) {
		  		this.method = this.dDS.dropdown.priceModelList[i]['method'];
		  	}
		  }
	  }
	} 

	getEditMethod() {
  	for (var i=0; i<this.dDS.dropdown.deliveryList.length; i++) {
  		if (this.dDS.dropdown.deliveryList[i]['iddelivery']==this.delivery) {
  			this.method = this.dDS.dropdown.deliveryList[i]['method'];
  		}
  	}
	} 

  lastStorage(index) {
  	return (index == this.storages.length - 1);
  }

	getSilo() {
		this.postBody = {
			"name": "getSilo",
			"param":{
				"location": +this.dDS.location['idlocation'],
				"idproducer": +this.producer,
				"cultivar": +this.cultivar
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
            this.loginService.setSession(response);
            this.storageOptions = response['response']['result']['reply']['storage'];
            this.altStorageOptions = response['response']['result']['reply']['altstorage'];
            this.stats = response['response']['result']['reply']['stats'];
            this.isStats = response['response']['result']['reply']['isStats'];
        },
        (error) => console.log(error)
      );
	}

	addItem(option = 0) {
		var fields = {"idstorage": "", "wnis": null};
		this.storages = this.splitTermsService.addItem(this.storages,fields);
		this.setwnis(option);
	}

	removeItem(index: number) {
		this.storages = this.splitTermsService.removeItem(this.storages, index);
		this.setwnis();
	}

  checkType(index,type) {
  	var ans = true;
  	var count = 0;
		for (var i = 0; i < this.storages.length; i++) {
			if (this.storages[i]['idstorage']==type && index!==i) {
				count = count + 1;
				if (+this.smalls==0 || count > 1) {
					ans = false;
				}
			}
		}
		return ans;
	}

	checkTerms() {
		var ans = true;
		var sum = 0;
		if (this.storages.length<1 || (+this.smalls>0 && this.scalewnis!=+this.smalls && this.storages.length<2)) {	
		ans = false;
		} else {
			for (var i = 0; i < this.storages.length; i++) {
				sum = sum + +this.storages[i]['wnis'];
				if (+this.storages[i]['wnis']==0 || +this.storages[i]['wnis']==null || this.storages[i]['idstorage']=="") {
					ans = false;
				}
			}
		}
		ans = (sum==this.scalewnis ? ans : false);
		return ans;
	}

	setSelectout(type = 'normal') {
		if (this.selectout) {
			if (type=='normal') {
				for (var i = 0; i < this.dDS.dropdown.storageListWNIS.length; i++) {
					if (this.dDS.dropdown.storageListWNIS[i]['type']=='selectouts') {
						this.storages[0]['idstorage'] = this.dDS.dropdown.storageListWNIS[i]['idstorage'];
						break;
					}
				}	
				for (var i = 0; i < this.dDS.dropdown.cultivarList.length; i++) {
					if (this.dDS.dropdown.cultivarList[i]['cultivar']=='Mixed') {
						this.cultivar = this.dDS.dropdown.cultivarList[i]['idcultivar'];
						break;
					}
				}	
			} else {
				for (var i = 0; i < this.storageOptions.length; i++) {
					if (this.storageOptions[i]['type']=='selectouts') {
						this.storages[0]['idstorage'] = this.storageOptions[i]['idstorage'];
						break;
					}
				}
			}
		}
	}

  setLoadDate(index, idstorage) {
    for (var i = 0; i < this.storageOptions.length; i++) {
      if (this.storageOptions[i]['idstorage']==idstorage) {
        this.storages[index]['type'] = this.storageOptions[i]['type'];
      }
    }
    this.loaddate = (this.storages[index]['type'].toLowerCase() == 'warehouse' ? null : this.deliverydate);
  }

	fixDate(field) {
		if (this[field]!==null && this[field]!=='') {
	  	this[field] = moment(this[field]).format('YYYY-MM-DD');
	  }
	}


}