import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';

//import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { RemittanceService } from './remittance.service';
import { TableDataService } from '../table/table.service';
import { TeamRemittancesService } from '../remittances/teamremittances.service';

@Component({
  selector: 'app-remittance',
  templateUrl: './remittance.component.html',
  styleUrls: ['./remittance.component.scss']
})
export class RemittanceComponent implements OnInit {
  d: Date = new Date();

  constructor(
  	public lS: TeamLoginService,
  	public rS: RemittanceService,
  	public dDDS: DropDownDataService,
    public tS: TableDataService,
    public rsS: TeamRemittancesService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
    this.dDDS.getDropdown("remittanceList");
    this.dDDS.getDropdown("seasonMonthList");
  	if (this.rS.monthSelected!=null || this.rS.iddelivery!=null) {
  		this.rS.loadRemittance();
  	}
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.rS.reset();
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  async svgPDF(iddelivery = this.rS.iddelivery, companyname = this.rS.companyname) {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('contentToConvert');
		const width = 300, height = 200;

		// create a new jsPDF instance
		const pdfTeam = await new jsPDF('p', 'pt', 'a4');
		var forwardZeros = 4 - iddelivery.toString().length;
    if (forwardZeros >= 4) {
      var del_id = iddelivery;
    }
    var zeros = "0";
    var del_id = zeros.repeat(forwardZeros) + iddelivery.toString();
    var savename = companyname + ' - Remittance for Delivery ' + del_id +'.pdf';
    this.setupPDF(pdfTeam, svgElement, savename);
	}

	async setupPDF(pdfTeam, svgElement, savename) {
		const pdf = await pdfTeam.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    });
    const pdfprint = await pdf.save(savename, {returnPromise: true});
	}

	async saveThisMonth() {
		for (var i = 0; i < this.dDDS.dropdown.seasonMonthList.length; i++) {
			if (this.dDDS.dropdown.seasonMonthList[i]['date']==this.rS.monthSelected) {		
				for (var j = 0; j < this.dDDS.dropdown.seasonMonthList[i]['remittances'].length; j++) {

					this.rS.iddelivery = this.dDDS.dropdown.seasonMonthList[i]['remittances'][j]['iddelivery'];
					
					const result = await this.rS.loadRemittance();
					this.svgPDF();	
				}
			}
		}
	}


}
