<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3 ml-0" *ngIf="isAuthenticated()">
	<br>

	<ng-container *ngIf="this.loginService.season==null">
		<h3>Your account for <strong>{{ this.loginService.selectedmembername }}</strong> is active but no deliveries have been uploaded yet. Please allow a couple of days after your first delivery for this information to be available to you.
		</h3>
		<br>
	</ng-container>

	<div class="row justify-content-between">
		<div class="col-xl-6 col-sm-12 row justify-content-start ml-0">
			<div class="mt-2 px-0">
				<h2 style="line-height: 1.3em">Welcome {{ this.loginService.uname }},</h2>
			</div>		
			<h2 class="pl-0">
				<div class="dropdown" dropdown>
	        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-0" mdbWavesEffect>
	        {{ this.loginService.season }} Season<span class="caret"></span></a>
	        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
	          <button 
	          	class="dropdown-item waves-light py-3" 
	          	mdbWavesEffect 
	          	(click)="onSeasonSelected(season)"
	          	*ngFor='let season of this.loginService.seasons'>{{ season }} Season</button>
	        </ul>
	      </div>
			</h2>
			<br>
		</div>

		<div *ngIf="isAuthenticated('mod-currency') && (this.calculatorService.overviewStore?.fx!==null || this.calculatorService.overviewStore?.fx12month!==null)" class="col-xl-6 col-sm-12 row justify-content-start" style="text-align: right">

			<div *ngIf="this.calculatorService.overviewStore?.fx!==null" class="col-6">
					<p class="mt-2 mb-0" style="font-weight: 600">Day Average</p><p class="mb-2 mt-0" style="font-weight: 600">ZAR/USD</p>
					<h2 class="mt-2 mb-2" style="font-weight: 500; color: green">{{ this.calculatorService.overviewStore?.fx | number:'1.2-2' }}</h2>
					<p>{{ this.calculatorService.overviewStore?.fxdate | date:'EEE, MMM d, yyyy' }}</p>
			</div>

			<div *ngIf="this.calculatorService.overviewStore?.fx12month!==null" class="col-6">
					<p class="mt-2 mb-0" style="font-weight: 600">12 Month Avg</p><p class="mb-2 mt-0" style="font-weight: 600">ZAR/USD</p>
					<h2 class="mt-2 mb-2" style="font-weight: 500; color: blue">{{ this.calculatorService.overviewStore?.fx12month | number:'1.2-2' }}</h2>
					<p>{{ this.calculatorService.overviewStore?.fxdate | date:'EEE, MMM d, yyyy' }}</p>
			</div>

			<div class="col">
				<p class="text-right">Source: Bloomberg</p>
			</div>

		</div>
	</div>

	<br>
	<div *ngIf="+this.loginService.memberships.length > 1">
		<h2>Overview</h2>
		<br>
		<div class="row justify-content-start ml-0">
			<div class="mt-2 col-auto px-0">
				<h3 class="">Summary for </h3>
			</div>		
			<h3 class="col-auto px-0">
				<div class="dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
          {{ this.loginService.selectedmembername }}<span class="caret"></span></a>
          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <button 
            	class="dropdown-item waves-light py-3" 
            	mdbWavesEffect 
            	(click)="onMemberSelected(mship['membership'], mship['companyname'], mship['season'])"
            	*ngFor='let mship of this.loginService.memberships'>{{ mship['companyname'] }}</button>
          </ul>
        </div>
			</h3>
		</div>
	</div>
	
	<div *ngIf="+this.loginService.memberships.length == 1">
		<h3>Summary for {{ this.loginService.selectedmembername }}</h3>
	</div>

	<div class="row" *ngIf="this.loginService.getFarmId(this.loginService.selectedmembername)!=null">
		<div class="mt-2 ml-0">
			<h3>Farm ID: {{ this.loginService.getFarmId(this.loginService.selectedmembername) }}</h3>
		</div>
	</div>

	<br>

	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>Total WNIS delivered</td>
				<td>{{ +this.calculatorService.overviewStore?.totalWNIS + +this.calculatorService.overviewStore?.smalls | number:'1.0-0' }} kg</td>
			</tr>
			<tr *ngIf="+this.calculatorService.overviewStore?.smalls>0" class="tableseperatorlight"><td></td><td></td></tr>
			<tr *ngIf="+this.calculatorService.overviewStore?.smalls>0">
				<td>WNIS ex. Smalls</td>
				<td>{{ +this.calculatorService.overviewStore?.totalWNIS | number:'1.0-0' }} kg</td>
			</tr>
			<tr *ngIf="+this.calculatorService.overviewStore?.smalls>0">
				<td>WNIS Smalls</td>
				<td>{{ +this.calculatorService.overviewStore?.smalls | number:'1.0-0' }} kg</td>
			</tr>
			<tr class="tableseperatorlight"><td></td><td></td></tr>
			<tr>
				<td>Deliveries made</td>
				<td>{{ +this.calculatorService.overviewStore?.deliveries + +this.calculatorService.overviewStore?.depotDeliveries + +this.calculatorService.overviewStore?.awaitingCollection | number:'1.0-0' }}</td>
			</tr>
			<tr>
				<td>Samples Outstanding</td>
				<td>{{ this.calculatorService.overviewStore?.samplesOutstanding | number:'1.0-0' }}</td>
			</tr>
			<ng-container *ngIf="this.calculatorService.overviewStore?.samplesComplete>0">
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Avg Moisture</td>
					<td>{{ this.calculatorService.overviewStore?.Moisture | percent:'1.2' }}</td>
				</tr>
				<tr class="tableseperatorlight"><td></td><td></td></tr>
				<tr>
					<td>Avg SKR</td>
					<td>{{ this.calculatorService.overviewStore?.SKR | percent:'1.2' }}</td>
				</tr>
				<tr>
					<td>Avg USKR</td>
					<td>{{ this.calculatorService.overviewStore?.USKR | percent:'1.2' }}</td>
				</tr>
				<tr>
					<td>Avg Darks</td>
					<td>{{ this.calculatorService.overviewStore?.DKR | percent:'1.2' }}</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
	
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-login></app-login>
</div>