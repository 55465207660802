<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3">
				<h2>Silo Selector</h2>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

			  <mat-form-field>
    			<mat-label>Location</mat-label>
			    <mat-select placeholder="Location" [(ngModel)]="this.dDDS.location['idlocation']">
			      <mat-option *ngFor="let location of this.dDDS.dropdown.locationList" (click)="clearFields('all')" [value]="location.idlocation">{{ location.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field>
    			<mat-label>Delivery Source</mat-label>
			    <mat-select placeholder="Delivery Source" [(ngModel)]="this.sSS.deliverysource">
			    	<mat-option (click)="clearFields('all')" value="0">Direct</mat-option>
			      <mat-option *ngFor="let depot of this.dDDS.dropdown.depotList" (click)="clearFields('all')" value="{{ depot.idlocation }}">{{ depot.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field>
    			<mat-label>Delivery Number</mat-label>
			    <input matInput type="number" placeholder="Delivery Number" [(ngModel)]="this.dDDS.getNewDeliveryNumber" value="{{ this.dDDS.getNewDeliveryNumber }}">
			  </mat-form-field>

				<hr>

			  <ng-container *ngIf="+this.sSS.deliverysource!=0">
			  
			  	<mat-form-field>
    				<mat-label>Depot Delivery Number</mat-label>
				    <mat-select placeholder="Depot Delivery Number" [(ngModel)]="this.aDS.iddeldepot" (keydown.tab)="this.sSS.loadForEdit()">
				      <mat-option *ngFor="let delivery of this.dDDS.dropdown.depotDeliveryList" #prod (click)="this.sSS.loadForEdit()" [value]="delivery.iddeldepot">{{ delivery.iddeldepot | delivery }}</mat-option>
				    </mat-select>
				  </mat-form-field>

			  </ng-container>

			  <ng-container *ngIf="+this.sSS.deliverysource==0 || this.aDS.iddeldepot!=undefined">

				  <mat-form-field>
    				<mat-label>Producer</mat-label>
				    <mat-select placeholder="Producer" [disabled]="+this.sSS.deliverysource!=0" [(ngModel)]="this.aDS.producer">
				      <mat-option *ngFor="let producer of this.dDDS.dropdown.producerList" (click)="clearFields('some')" #prod [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="this.dDDS.getFarmId(this.aDS.producer)!=null">
    				<mat-label>Farm ID</mat-label>
				    <mat-select placeholder="Farm ID" [disabled]="+this.sSS.deliverysource!=0" [(ngModel)]="this.aDS.producer">
				      <ng-container *ngFor="let producer of this.dDDS.dropdown.producerList">
				      	<mat-option *ngIf="producer.farmid!=null" #prod (click)="clearFields('some')" [value]="producer.idcompany">{{ producer.farmid }}</mat-option>
				      </ng-container>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Cultivar</mat-label>
				    <mat-select placeholder="Cultivar" [disabled]="+this.sSS.deliverysource!=0" [(ngModel)]="this.aDS.cultivar">
				    	<ng-container *ngIf="+this.aDS.producer>0">
				    		<mat-option *ngFor="let cult of this.aDS.cultivars" (click)="getSilo()" [value]="cult.idcultivar">{{ cult.cultivar }}</mat-option>
				    	</ng-container>				      
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Farmer Delivery Reference (Optional)</mat-label>
				    <input matInput placeholder="Farmer Delivery Reference (Optional)" [(ngModel)]="this.aDS.iddelfarm" value="{{ this.aDS.iddelfarm }}">
				  </mat-form-field>
			  
			  </ng-container>

				<div class="form-container">
					<ng-container *ngIf="+this.sSS.deliverysource!=0 && this.aDS.iddeldepot!=undefined">
						<hr>

						<ng-container *ngIf="this.dDDS.tagserialsU?.length>1">
							<p>Tag Serial Numbers:</p>
							<ul>
								<li *ngFor="let tag of this.dDDS.tags">{{ tag.serial }}</li>
							</ul>
						</ng-container>

					</ng-container>
				</div>
			</div>

			<hr>

			<ng-container *ngIf="this.aDS.storageOptions!=null">

				<div class="form-container">

				  <mat-form-field [class.errorcheck]="isConcern()">
    			<mat-label>Scale WNIS (kg)</mat-label>
				    <input matInput type="number" placeholder="Scale WNIS (kg)" (keyup)="this.aDS.setwnis()" (blur)="this.aDS.setwnis()" [(ngModel)]="this.aDS.scalewnis" autocomplete="off">
				  </mat-form-field>			

				  <mat-form-field [class.errorcheck]="isConcern()" *ngIf="this.aDS.depotwnisd>0">
    				<mat-label>Dispatched WNIS (kg)</mat-label>
				    <input matInput type="number" [disabled]=true placeholder="Dispatched WNIS (kg)" [(ngModel)]="this.aDS.depotwnisd">
				  </mat-form-field> 

				  <mat-form-field>
    				<mat-label>Smalls (kg)</mat-label>
				    <input matInput type="number" placeholder="Smalls (kg)" (keyup)="this.aDS.setwnis()" (blur)="this.aDS.setwnis()" [(ngModel)]="this.aDS.smalls" autocomplete="off">
				  </mat-form-field>
				  
				  <hr>

				  <mat-form-field>
	    			<mat-label>NIS >18mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >18mm (kg)" [(ngModel)]="this.aDS.d18" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>NIS >20mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >20mm (kg)" [(ngModel)]="this.aDS.d20" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>NIS >22mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >22mm (kg)" [(ngModel)]="this.aDS.d22" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>NIS >25mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >25mm (kg)" [(ngModel)]="this.aDS.d25" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>Total Sized NIS (kg)</mat-label>
				    <input matInput type="number" placeholder="Total Sized NIS (kg)" [disabled]=true [(ngModel)]="this.aDS.d1825">
				  </mat-form-field>
				  
				  <hr>

					<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.aDS.selectout" *ngIf="isAuthenticated('mod-selectouts')" (ngModelChange)="this.aDS.setSelectout('selector')">
		        Select-Outs?
		      </mat-slide-toggle>

				</div>

				<hr>

				<ng-container *ngIf="this.aDS.scalewnis>0">

					<h1 class="my-4">Send to one of the following:</h1>
			
					<div class="col px-0" *ngFor="let store of this.aDS.storages; let storeIndex=index;">

				  	<hr>

						<div class="col-9 px-0" style="display: inline-block;">
							<div class="form-container">
							  		  
							  <mat-form-field>
    							<mat-label>Storage</mat-label>
							    <mat-select placeholder="Storage" [(ngModel)]="this.aDS.storages[storeIndex]['idstorage']" (ngModelChange)="this.aDS.setLoadDate(storeIndex, store.idstorage)">
						      	<ng-container *ngFor="let store of this.aDS.storageOptions">
				      				<mat-option class="h2padding" *ngIf="this.aDS.checkType(storeIndex, store.idstorage)" (click)="this.aDS.setLoadDate(storeIndex, store.idstorage)" value="{{ store.idstorage }}"><h2>{{ store.type | titlecase }}: {{ store.name }}</h2></mat-option>
				      			</ng-container>
    								<mat-divider *ngIf="this.aDS.altStorageOptions.length>0"></mat-divider>
						      	<ng-container *ngFor="let altstore of this.aDS.altStorageOptions">
				      				<mat-option class="h2padding altstore" *ngIf="this.aDS.checkType(storeIndex, altstore.idstorage)" (click)="this.aDS.setLoadDate(storeIndex, store.idstorage)" value="{{ altstore.idstorage }}"><h2>{{ altstore.type | titlecase }}: {{ altstore.name }} (Override)</h2></mat-option>
				      			</ng-container>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-3 px-0" style="display: inline-block;">
							<div class="form-container">
							  
								<button mat-icon-button class="col" (click)="this.aDS.addItem(1)"><i class="material-icons addbutton">add_circle_outline</i></button>

							</div>
						</div>

						<div class="col-9 px-0" style="display: inline-block;">
							<div class="form-container">	
							  
							  <mat-form-field>
    							<mat-label>Load WNIS (kg)</mat-label>
					    		<input matInput type="number" placeholder="Load WNIS (kg)" [disabled]="this.aDS.lastStorage(storeIndex)" (ngModelChange)="this.aDS.setwnis(2, storeIndex, $event)" [(ngModel)]="this.aDS.storages[storeIndex]['wnis']" autocomplete="off">
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-3 px-0" style="display: inline-block;">
							<div class="form-container">

						  	<button mat-icon-button class="col" *ngIf="this.aDS.storages.length>1" (click)="this.aDS.removeItem(storeIndex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

							</div>
						</div>

					</div>

					<ng-container *ngIf="this.aDS.checkTerms()">

						<hr *ngIf="this.aDS.iddeldepot==undefined">

						<div *ngIf="this.aDS.iddeldepot==undefined" class="form-container my-4">

							<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.aDS.collected">
				        Collected by {{ this.pS.portalMod()['portal'] }}
				      </mat-slide-toggle>

				    </div>

				    <hr>

				    <div class="row justify-content-start py-4 mx-0">
						
							<div class="mainheader col-auto">
								<button  mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.sSS.saveDelivery()">Log Delivery</button>
							</div>
							
							<div class="col-auto">
								<button mdbBtn class="btn btn-warning" (click)="this.sSS.resetForm()">Reset</button>
							</div>
						
						</div>

					</ng-container>

					<hr *ngIf="this.aDS.isStats==true">

					<ng-container *ngIf="this.aDS.isStats==true">
						<p>Based on previous samples of this cultivar ({{ this.aDS.stats['lastdelivery'] }})</p>
						<ul>
							<li>Avg SKR: {{ this.aDS.stats['skr'] | percent:'1.1' }}</li>
							<li>Avg USKR: {{ this.aDS.stats['uskr'] | percent:'1.1' }}</li>
							<li>Avg DKR: {{ this.aDS.stats['dkr'] | percent:'1.1' }}</li>
							<br>
							<li>WAvg SKR: {{ this.aDS.stats['wskr'] | percent:'1.1' }}</li>
							<li>WAvg USKR: {{ this.aDS.stats['wuskr'] | percent:'1.1' }}</li>
							<li>WAvg DKR: {{ this.aDS.stats['wdkr'] | percent:'1.1' }}</li>
						</ul>
					</ng-container>
				
				</ng-container>

			</ng-container>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>