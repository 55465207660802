import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { RemittanceRebuilderService } from './remittance-rebuilder.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-remittance-rebuilder',
  templateUrl: './remittance-rebuilder.component.html',
  styleUrls: ['./remittance-rebuilder.component.scss']
})
export class RemittanceRebuilderComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	public loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public tableService: TableDataService,
    public rebuildService: RemittanceRebuilderService
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.rebuildService.resetForm();
    this.dropDownDataService.getDropdown("locationList");
    this.dropDownDataService.getDropdown("producerList");
    this.dropDownDataService.getDropdown("priceModelListAll");    
    this.dropDownDataService.getDropdown("sampleListComplete");
  }

  ngOnDestroy() {
    this.rebuildService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        true
      ) {
      return true;
    } else {
      return false;
    }
  }

  onCalculate() {
    if (this.canSave()) {
      window.scroll(0,0);
      this.rebuildService.rebuildRemittances();
    }
  }
  
}