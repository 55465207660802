import { Component, OnInit, OnDestroy } from '@angular/core';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';

@Component({
	selector: 'app-farm',
	templateUrl: './farm.component.html',	
  styleUrls: ['./farm.component.scss']
})
export class FarmComponent implements OnInit {
  memberSelect;
	
	constructor(
		public loginService: LoginService,
    public portalService: PortalService
    ) {}

	ngOnInit() {		
    window.scrollTo(0,0);
	}

  ngOnDestroy() {
    this.clearAll();
  }

  clearAll() {
  }

	isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  onMemberSelected(membership, membername) {
    this.clearAll();
    this.memberSelect = this.loginService.selectMembership(membership);
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

}