<div>
  <div class="row justify-content-between">
    <div class="col-xs-6 ml-3 col main-header">
    </div>
    <div class="col-auto">
      <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
    </div>
  </div>
  <br>
  <div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
    <svg id="contentToConvert" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 530.29 795.82">
      <defs>
        <style>
          .cls-1, .cls-110, .cls-113, .cls-114 {
            fill: none;
          }

          .cls-3 {
            fill: #fff;
          }

          .cls-4 {
            fill: #f0f0f0;
          }

          .cls-5 {
            font-size: 45.96px;
          }

          .cls-13 {
            font-size: 9.96px;
          }

          .cls-40, .cls-5 {
            fill: #70962e;
          }

          .cls-24, .cls-30, .cls-40, .cls-45, .cls-5, .cls-7, .cls-83, .cls-87 {
            font-family: Arial-BoldMT, Arial;
            font-weight: 700;
          }

          .cls-14, .cls-30, .cls-34, .cls-35, .cls-7 {
            font-size: 9.96px;
          }

          .cls-14, .cls-34, .cls-35, .cls-57, .cls-63, .cls-7, .cls-71 {
            fill: #4d4d4d;
          }

          .cls-14 {
            font-family: Arial-ItalicMT, Arial;
            font-style: italic;
          }

          .cls-24, .cls-40 {
            font-size: 12px;
          }

          .cls-34, .cls-35, .cls-57, .cls-63, .cls-71 {
            font-family: ArialMT, Arial;
          }

          .cls-45, .cls-57, .cls-63, .cls-71 {
            font-size: 8.04px;
          }

          .cls-83 {
            font-size: 15.96px;
          }

          .cls-87 {
            font-size: 11.04px;
          }

          .cls-110, .cls-113 {
            stroke: #e7e9e9;
          }

          .cls-110 {
            stroke-linecap: square;
            stroke-linejoin: round;
            stroke-width: 0.14px;
          }

          .cls-111 {
            fill: #e7e9e9;
          }

          .cls-113, .cls-114 {
            stroke-miterlimit: 10;
          }

          .cls-113 {
            stroke-width: 1.5px;
          }

          .cls-114 {
            stroke: #70962e;
            stroke-width: 2.25px;
          }
        </style>
      </defs>
      <title>Portal invoice</title>
      <rect class="cls-4" x="255" y="113.88" width="285.91" height="97.58"/>
      <g class="cls-2">
        <rect class="cls-3" x="0.48" y="211.35" width="529.8" height="584.47"/>
      </g>
      <g class="cls-2">
        <text class="cls-5" transform="translate(7.44 61.92)">Tax Invoice</text>
      </g>
      <g class="cls-6">
        <text class="cls-7" transform="translate(278.83 137.9)">Invoice Number</text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(2.4 152.54)" id="address">{{ this.invoiceService.invoice['detail'][0]?.address }}</text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(2.4 165.74)">{{ this.invoiceService.invoice['detail'][0]?.area }}</text>
      </g>
      <g class="cls-2">
        <text class="cls-7" transform="translate(275.09 165.74)">Client Reference</text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(2.4 180.38)"><tspan class="cls-12">{{ this.invoiceService.invoice['detail'][0]?.state }} {{ this.invoiceService.invoice['detail'][0]?.postcode }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(2.4 193.58)"><tspan class="cls-12">{{ this.invoiceService.invoice['detail'][0]?.country }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-7" transform="translate(294.91 193.58)">Invoice Date</text>
      </g>
      <g class="cls-23">
        <text class="cls-24" transform="translate(2.64 235)">Bill To:</text>
      </g>
      <g class="cls-2">
        <text class="cls-7" transform="translate(50 235)">{{ this.portalService.portalMod()['companyname'] }}</text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(50 249.98)"><tspan>{{ this.portalService.portalMod()['address'] }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(50 263.9)"><tspan>{{ this.portalService.portalMod()['area'] }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(50 277.82)"><tspan>{{ this.portalService.portalMod()['state'] }} {{ this.portalService.portalMod()['postcode'] }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(50 291.74)"><tspan>{{ this.portalService.portalMod()['country'] }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-14" transform="translate(50 305.69)"><tspan>VAT No: {{ this.portalService.portalMod()['vat'] }}</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-30" transform="translate(345 348.05)"><tspan class="cls-31">VAT</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-30" transform="translate(435 348.05)"><tspan class="cls-33">Total</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-30" transform="translate(307.99 616.03)"><tspan class="cls-31">Sub Total</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-30" transform="translate(307.15 638.23)"><tspan class="cls-33">Total VAT</tspan></text>
      </g>
      <g class="cls-2">
        <text class="cls-40" transform="translate(250.85 660.46)">Total Amount Due</text>
      </g>
      <g class="cls-2">
        <text class="cls-45" transform="translate(2.16 701.98)"><tspan class="cls-46">Contact Information</tspan></text>
        <text class="cls-45" transform="translate(178.97 701.98)"><tspan class="cls-46">Company Information</tspan></text>
        <text class="cls-45" transform="translate(368.23 701.98)"><tspan class="cls-54">Payment Details</tspan></text>
        <text class="cls-57" transform="translate(2.16 715.78)"><tspan class="cls-50">{{ this.invoiceService.invoice['detail'][0]?.fullname }}</tspan></text>
        <text class="cls-57" transform="translate(178.97 715.78)"><tspan class="cls-60">VAT No</tspan></text>
        <text class="cls-63" transform="translate(246.05 715.78)">{{ this.invoiceService.invoice['detail'][0]?.vat }}</text>
        <text class="cls-57" transform="translate(368.23 715.78)"><tspan class="cls-54">Bank Name</tspan></text>
        <text class="cls-57" transform="translate(426.58 715.78)"><tspan class="cls-54">{{ this.invoiceService.invoice['detail'][0]?.bankname }}</tspan></text>
        <text class="cls-57" transform="translate(2.16 729.7)" id="email"><tspan class="cls-48">{{ this.invoiceService.invoice['detail'][0]?.email }}</tspan></text>
        <text class="cls-57" transform="translate(178.97 729.7)"><tspan class="cls-46">Company Reg</tspan></text>
        <text class="cls-57" transform="translate(246.05 729.7)" id="regnumber"><tspan class="cls-48">{{this.invoiceService.invoice['detail'][0]?.regnumber }}</tspan></text>
        <text class="cls-57" transform="translate(368.23 729.7)"><tspan class="cls-54">Sort-Code</tspan></text>
        <text class="cls-71" transform="translate(426.58 729.7)">{{ this.invoiceService.invoice['detail'][0]?.banksortcode }}</text>
        <text class="cls-57" transform="translate(2.16 743.62)"><tspan class="cls-50">{{ this.invoiceService.invoice['detail'][0]?.tel }}</tspan></text>
        <text class="cls-57" transform="translate(178.97 743.62)">Farm ID</text>
        <text class="cls-63" transform="translate(246.05 743.62)">{{ this.invoiceService.invoice['detail'][0]?.farmid }}</text>
        <text class="cls-57" transform="translate(368.23 743.62)"><tspan class="cls-54">Account No</tspan></text>
        <text class="cls-57" transform="translate(426.58 743.62)"><tspan class="cls-74">{{ this.invoiceService.invoice['detail'][0]?.bankaccount }}</tspan></text>
        <text class="cls-57" transform="translate(368.23 757.54)" *ngIf="this.invoiceService.invoice['detail'][0]?.bankiban!=null"><tspan class="cls-56">IBAN</tspan></text>
        <text class="cls-57" transform="translate(426.58 757.54)" id="iban"><tspan class="cls-48">{{ this.invoiceService.invoice['detail'][0]?.bankiban }}</tspan></text>
      </g>
      <g class="cls-75">
        <text class="cls-30" transform="translate(380 616.03)">{{ this.invoiceService.invoice['tally']?.subtotal * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
      </g>
      <g class="cls-77">
        <text class="cls-30" transform="translate(380 638.23)">{{ this.invoiceService.invoice['tally']?.vattotal * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
      </g>
      <g class="cls-80">
        <text class="cls-40" transform="translate(380 660.46)">{{ this.invoiceService.invoice['tally']?.totaldue * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
      </g>
      <g class="cls-82">
        <text class="cls-83" transform="translate(3.24 129.6)">{{ this.invoiceService.invoice['detail'][0]?.companyname }}</text>
      </g>
      <g class="cls-86">
        <text class="cls-87" transform="translate(510.58 137.9)" text-anchor="end" id="customref">{{ this.invoiceService.customref }}</text>
      </g>
      <g class="cls-90">
        <text class="cls-87" transform="translate(510 193.58)" text-anchor="end">{{ this.invoiceService.invoice['detail'][0]?.paydate | date:'MMM d, y' }}</text>
      </g>
      <g class="cls-95">
        <text class="cls-87" transform="translate(510 165.74)" text-anchor="end">{{ this.invoiceService.invoiceref }}</text>
      </g>
      <g class="cls-98">
        <text class="cls-30" transform="translate(246.29 347.57)">Account</text>
      </g>
      <g class="cls-101">
        <text class="cls-30" transform="translate(2.4 347.57)">Description</text>
      </g>
      <ng-container *ngIf="this.invoiceService.invoice['instalments']['total']<11">
        <ng-container *ngFor="let invoice of this.invoiceService.invoice['invoices']; let invoiceId=index;">
          <g class="cls-115" [attr.transform]="'translate(0,' + 24.12 * invoiceId + ')'">
            <g class="cls-103">
              <text class="cls-35" transform="translate(2.4 371.57)">{{ invoice.lineitem }}</text>
            </g>
            <g class="cls-99">
              <text class="cls-35" transform="translate(246.29 371.57)">{{ invoice.netprice * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
            </g>
            <g class="cls-2">
              <text class="cls-34" transform="translate(345 371.57)">{{ invoice.vatpayable * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
            </g>
            <g class="cls-2">
              <text class="cls-35" transform="translate(435 371.57)">{{ invoice.netvat * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
            </g>
          </g>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="this.invoiceService.invoice['instalments']['total']>10">
        <ng-container *ngFor="let instalment of this.invoiceService.invoice['invoices']; let invoiceId=index;">
          <g class="cls-115" [attr.transform]="'translate(0,' + 24.12 * invoiceId + ')'">
            <g class="cls-103">
              <text class="cls-35" transform="translate(2.4 371.57)">{{ this.invoiceService.invoice['invoices'][invoiceId]?.lineitem }}</text>
            </g>
            <g class="cls-99">
              <text class="cls-35" transform="translate(246.29 371.57)">{{ this.invoiceService.invoice['invoices'][invoiceId]?.netprice * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
            </g>
            <g class="cls-2">
              <text class="cls-34" transform="translate(345 371.57)">{{ this.invoiceService.invoice['invoices'][invoiceId]?.vatpayable * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
            </g>
            <g class="cls-2">
              <text class="cls-35" transform="translate(435 371.57)">{{ this.invoiceService.invoice['invoices'][invoiceId]?.netvat * this.invoiceService.invoice['detail'][0]?.fxc | currency:this.invoiceService.invoice['detail'][0]?.paycurrency:'symbol-narrow' }}</text>
            </g>
          </g>
        </ng-container>
      </ng-container>
      <g class="cls-109">
        <rect class="cls-111" x="0.48" y="355.97" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="380.09" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="404.21" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="428.33" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="452.45" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="476.59" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="500.71" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="524.83" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="548.95" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="573.07" width="529.8" height="0.96"/>
        <rect class="cls-111" x="0.48" y="597.19" width="529.8" height="0.96"/>
      </g>
      <g class="cls-112">
        <line class="cls-113" x1="0.56" y1="677.64" x2="529.56" y2="677.64"/>
      </g>
      <g class="cls-112">
        <line class="cls-114" y1="90.14" x2="529.56" y2="90.14"/>
      </g>
      <g class="cls-112">
        <line class="cls-114" y1="329.47" x2="529.56" y2="329.47"/>
      </g>
      <g class="cls-112">
        <line class="cls-114" y1="775.08" x2="529.56" y2="775.08"/>
      </g>
    </svg>

  </div>
</div>