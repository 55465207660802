import { Component, OnInit } from '@angular/core';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';

@Component({
  selector: 'app-teamremittancedetail',
  templateUrl: './teamremittancedetail.component.html',
  styleUrls: ['./teamremittancedetail.component.scss']
})
export class TeamRemittanceDetailComponent implements OnInit {
	clickedIndex;
  dataStore;
  fx;

  constructor(
  	private loginService: TeamLoginService,
    public tableService: TableDataService
    ) { }

  ngOnInit() {
    if (this.tableService['remittances'][this.tableService.clickedIndex['remittances']].paycurrency == 'ZAR') {
      this.fx = +this.tableService['remittances'][this.tableService.clickedIndex['remittances']].fxrate;
    } else {
      this.fx = 1;
    }
  }

}
