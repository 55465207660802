import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { Remittance, RootObject } from '../../../interfaces/remittances.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';


@Injectable()
export class TeamRemittancesService{
	private postBody: {};
	httpRes: any;
	public loading: boolean = false;
	public dataStore: any;
	public clickedIndex: number = null;
	public clickedIndexR: number = null;
	public overviewStore: any;
	public totalWNIS: number;
	public WNISexSmalls: number;;
	public totalSmalls: number;
	public depotWNIS: number;
	public totalDeliveries: number;
	public depotDeliveries: number;
	public completeSamples: number;
	public samplesExist: boolean = false;
	public samplesOutstanding: number;
	public avgSKR: number;
	public avgDarks: number;
	public avgUSKR: number;
	public avgMoisture: number;
	public paydate: string;
	public nextPay: number;
	public paycurrency: string;
	public feecurrency: string;
	public batchPanel: boolean = false;
	public currency: string = 'ZAR';
	public delivery: number;
	public model: string;
	public selected: Remittance;

	public remittances: Remittance[];
	
	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService) {}

	getRemittances(): Observable<RootObject[]> {
		this.postBody = {
			"name":"remittances",
			"param":{
				"season": this.loginService.season
			}
		};
		return this.http.post<RootObject[]>(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		);
	}

	loadRemittances() {
		this.postBody = {
			"name":"remittances",
			"param":{
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
		)
		.subscribe(
      (response) => {
      	this.loginService.setSession(response);
        this.httpRes = this.storeResult(response);
      },
      (error) => console.log(error)
    );
	}

	loadOverview() {
		this.loading = true;
		this.postBody = {
			"name":"loadOverview",
			"param":{
				"season": this.loginService.season,
				"idlocation": this.loginService.location
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeOverview(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.dataStore = rawData.response.result.reply['remittances'];
		this.paycurrency = (this.dataStore.length > 0 ? this.dataStore[0]['paycurrency'] : null);
		this.feecurrency = (this.dataStore.length > 0 ? this.dataStore[0]['feecurrency'] : null);
	}

	fxr(currency: string, fxrate: number) {
		if (currency!==this.currency) {
			return (currency=='USD' ? fxrate : 1/fxrate);
		} else {
			return 1;
		}
	}

	storeOverview(rawData) {
		this.overviewStore = rawData.response.result.reply;
		this.loading = false;
	}


	setDelivery(idd: number) {
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (+this.dataStore[i].iddelivery == idd) {
				this.clickedIndex = i;
				this.delivery = idd;
				this.model = this.dataStore[i]['pricemodel'];
				this.getRemittance();
			}
		}
	}

	async getData() {
		this.postBody = {
			"name": 'calAllDelivery',
			"param": {
				"delivery": this.delivery,
				"model": this.model,
				"season": +this.loginService.season,
				"currency": this.currency
			}
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				}
			}
		)
		return response.json();
	}

	getRemittance() {
		this.getData()
		  .then(response => {
		  	this.loginService.setSession(response);
		  	this.selected = response['response']['result']['reply'];
		  })
		  .catch(error => console.log(error));
	}

}