<ng-container *ngIf="this.inventoryService.totalquantity>0 && isAuthenticated('mod-inventory')">
	<br>
	<div class="row justify-content-between mx-0">
		<h4 class="col mt-2 pl-0">Inventory Total - Kernel</h4>
		<button type="button" pButton pRipple class="p-mr-2 p-ml-2 p-button p-px-2 mb-3 refresh-button" pTooltip="Loading..." tooltipPosition="bottom" (click)="this.refresh($event)"><i class="pi pi-refresh" [class.pi-spin]="this.inventoryService.loading"></i></button>
	</div>		
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>TOTAL</td>
				<td>{{ this.inventoryService.totalquantity | number:'1.0-0' }}<span *ngIf="this.inventoryService.totalquantityt>0"> (+{{ this.inventoryService.totalquantityt | number:'1.0-0' }})</span></td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngIf="this.inventoryService.totalquantitypremium>0">
				<tr>
					<td>Total Premium Grade</td>
					<td>{{ this.inventoryService.totalquantitypremium | number:'1.0-0' }}<span *ngIf="this.inventoryService.totalquantitypremiumt>0"> (+{{ this.inventoryService.totalquantitypremiumt | number:'1.0-0' }})</span></td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<ng-container *ngFor="let inventory of this.inventoryService.inventory">
				<tr *ngIf="inventory.grade=='Premium'">
					<td>{{ "Style " + inventory.style.slice(0, -1) }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}<span *ngIf="inventory.tquantity>0"> (+{{ inventory.tquantity | number:'1.0-0' }})</span></td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngIf="this.inventoryService.totalquantitycommercial>0">
				<tr>
					<td>Total Commercial Grade</td>
					<td>{{ this.inventoryService.totalquantitycommercial | number:'1.0-0' }}<span *ngIf="this.inventoryService.totalquantitycommercialt>0"> (+{{ this.inventoryService.totalquantitycommercialt | number:'1.0-0' }})</span></td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<ng-container *ngFor="let inventory of this.inventoryService.inventory">
					<tr *ngIf="inventory.style.substr(0,1)=='C'">
						<td>{{ (inventory.style.slice(0,3)=="FEE" ? "Feed Grade" : (inventory.style.slice(0,1)=="O" ? "" : "Style ") + inventory.style.slice(0, -1)) }}</td>
						<td>{{ inventory.quantity | number:'1.0-0' }}<span *ngIf="inventory.tquantity>0"> (+{{ inventory.tquantity | number:'1.0-0' }})</span></td>
					</tr>
				</ng-container>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<ng-container *ngIf="this.inventoryService.totalquantityoil>0">
				<tr>
					<td>Total Oil Grade</td>
					<td>{{ this.inventoryService.totalquantityoil | number:'1.0-0' }}<span *ngIf="this.inventoryService.totalquantityoilt>0"> (+{{ this.inventoryService.totalquantityoilt | number:'1.0-0' }})</span></td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<ng-container *ngFor="let inventory of this.inventoryService.inventory">
				<tr *ngIf="inventory.style.substr(0,1)=='O'">
					<td>{{ inventory.style.slice(0, -1) }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}<span *ngIf="inventory.tquantity>0"> (+{{ inventory.tquantity | number:'1.0-0' }})</span></td>
				</tr>
				<tr *ngIf="inventory.style.substr(0,3)=='FEE'">
					<td>{{ "Feed Grade" + inventory.style.slice(0, -1) }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}<span *ngIf="inventory.tquantity>0"> (+{{ inventory.tquantity | number:'1.0-0' }})</span></td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>
<ng-container *ngIf="this.inventoryService.todaytotalquantity>0 && isAuthenticated('mod-inventory')">
	<br>
	<h4>Inventory Today - Kernel</h4>	
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>TOTAL</td>
				<td>{{ this.inventoryService.todaytotalquantity | number:'1.0-0' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngIf="this.inventoryService.todaytotalpremium>0">
				<tr>
					<td>Total Premium Grade</td>
					<td>{{ this.inventoryService.todaytotalpremium | number:'1.0-0' }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<ng-container *ngFor="let inventory of this.inventoryService.inventoryToday">
				<tr *ngIf="inventory.grade=='Premium'">
					<td>{{ "Style " + inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngIf="this.inventoryService.todaytotalcommercial>0">
				<tr>
					<td>Total Commercial Grade</td>
					<td>{{ this.inventoryService.todaytotalcommercial | number:'1.0-0' }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<ng-container *ngFor="let inventory of this.inventoryService.inventoryToday">
				<tr *ngIf="inventory.style.substr(0,1)=='C'">
					<td>{{ "Style " + inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="this.inventoryService.todaytotaloil>0">
				<tr>
					<td>Total Oil Grade</td>
					<td>{{ this.inventoryService.todaytotaloil | number:'1.0-0' }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</ng-container>
			<ng-container *ngFor="let inventory of this.inventoryService.inventoryToday">
				<tr *ngIf="inventory.style.substr(0,1)=='O'">
					<td>{{ inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}</td>
				</tr>
			</ng-container>
			<ng-container *ngFor="let inventory of this.inventoryService.inventoryToday">
				<tr *ngIf="inventory.style.substr(0,3)=='FEE'">
					<td>{{ "Feed Grade" + inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>

<ng-container *ngIf="this.inventoryService.NIScount>0 && isAuthenticated('mod-inventory')">
	<br>
	<h4>Inventory Total - Nut-in-Shell</h4>	
	<table class="table col-sm">
		<tbody>	
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>TOTAL NIS</td>
				<td>{{ this.inventoryService.totalNIS | number:'1.0-0' }} kg ({{ this.inventoryService.NIScount | number:'1.0-0' }} bags)</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.inventory">
				<tr *ngIf="inventory.grade=='NIS'">
					<td>NIS > {{ inventory.style.substr(1,2) + "mm" }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>
<ng-container *ngIf="this.inventoryService.todayNIScount>0 && isAuthenticated('mod-inventory')">
	<br>
	<h4>Inventory Today - Nut-in-Shell</h4>	
	<table class="table col-sm">
		<tbody>	
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>TOTAL NIS</td>
				<td>{{ this.inventoryService.todaytotalNIS | number:'1.0-0' }} kg ({{ this.inventoryService.todayNIScount | number:'1.0-0' }} bags)</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.inventoryToday">
				<tr *ngIf="inventory.grade=='NIS'">
					<td>NIS > {{ inventory.style.substr(1,2) + "mm - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>

<ng-container *ngIf="this.inventoryService.retailquantity>0 && isAuthenticated('mod-inventory')">
	<br>
	<h4>Inventory Total - Retail</h4>	
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.inventory">
				<tr *ngIf="inventory.grade=='Retail'">
					<td>{{ inventory.style.slice(0, -1) }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>
<ng-container *ngIf="this.inventoryService.todayretailquantity>0 && isAuthenticated('mod-inventory')">
	<br>
	<h4>Inventory Today - Retail</h4>	
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.inventoryToday">
				<tr *ngIf="inventory.grade=='Retail'">
					<td>{{ inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>

<ng-container *ngIf="this.inventoryService.dispatchtodaytotalquantity>0 && isAuthenticated('mod-qr')">
	<br>
	<h4>Dispatched Today - Kernel</h4>	
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>TOTAL</td>
				<td>{{ this.inventoryService.dispatchtodaytotalquantity | number:'1.0-0' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.dispatchToday">
				<tr *ngIf="inventory.grade=='Premium'">
					<td>{{ "Style " + inventory.style.slice(0, -1) }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.dispatchToday">
				<tr *ngIf="inventory.grade=='Commercial'">
					<td>{{ (inventory.style.slice(0,3)=="FEE" ? "Feed Grade" : (inventory.style.slice(0,1)=="O" ? "" : "Style ") + inventory.style.slice(0, -1)) }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }}</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>

<ng-container *ngIf="this.inventoryService.dispatchtodayNIScount>0 && isAuthenticated('mod-qr')">
	<br>
	<h4>Dispatched Today - Nut-in-Shell</h4>	
	<table class="table col-sm">
		<tbody>	
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr>
				<td>TOTAL NIS</td>
				<td>{{ this.inventoryService.dispatchtodaytotalNIS | number:'1.0-0' }} kg ({{ this.inventoryService.dispatchtodayNIScount | number:'1.0-0' }} bags)</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.dispatchToday">
				<tr *ngIf="inventory.grade=='NIS'">
					<td>NIS > {{ inventory.style.substr(1,2) + "mm" }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>

<ng-container *ngIf="this.inventoryService.dispatchRetailToday>0 && isAuthenticated('mod-qr')">
	<br>
	<h4>Dispatched Today - Retail</h4>	
	<table class="table col-sm">
		<tbody>
			<tr class="tableseperator"><td></td><td></td></tr>
			<ng-container *ngFor="let inventory of this.inventoryService.dispatchToday">
				<tr *ngIf="inventory.grade=='Retail'">
					<td>{{ inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
					<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
				</tr>
			</ng-container>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
</ng-container>