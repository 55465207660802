<div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
    <div class="row justify-content-between" *ngIf="!this.tS.bpanel" >
      <div class="col-xs-6 main-header">
        <h2>Remittance</h2>
      </div>
      <div class="ml-3 col-auto" *ngIf="!isAuthorised('accountant')">
        <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
      </div>
    </div>
    <div class="row justify-content-between" *ngIf="this.tS.bpanel">
      <div class="col-xs-6 main-header">
        <h2>Batched Remittances</h2>
      </div>
      <div class="mr-4 ml-3 col-auto">
        <button mdbBtn class="btn btn-secondary whitetext" [disabled]="this.rS.monthSelected==null" (click)="saveThisMonth()">Save ALL PDFS</button>
      </div>
    </div>
    <br>

    <div class="form-container" *ngIf="this.tS.bpanel">
      <mat-form-field class="col">
        <mat-select placeholder="Remittances for Month" [(ngModel)]="this.rS.monthSelected">
          <mat-option *ngFor="let month of this.dDDS.dropdown.seasonMonthList" value="{{ month.date }}">{{ month.month }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-container" *ngIf="!this.tS.bpanel">
      <mat-form-field class="col">
        <mat-select placeholder="Delivery Number" [(ngModel)]="this.rS.iddelivery">
          <mat-option *ngFor="let delivery of this.dDDS.dropdown.remittanceList" (click)="this.rS.loadRemittance()" [value]="delivery.iddelivery">{{ delivery.iddelivery | delivery }}{{ ( +delivery.selectout==1 ? ' - Select-outs' : '') }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="this.rS.iddelivery!=null">
    
      <ng-container *ngIf="!this.rS.fees?.modeldnissizes"><app-remittancestd></app-remittancestd></ng-container>
      <ng-container *ngIf="this.rS.fees?.modeldnissizes"><app-remittancesized></app-remittancesized></ng-container>

    </ng-container>
  </div>
</div>