import { Component, VERSION, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { LogisticsLoginService } from '../../auth/login/login.service';
import { LogisticsQRService } from '../qr.service';
import { QRCode } from '../../../interfaces/qr.interface';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';

@Component({
  selector: 'app-logisticsqr-dispatch',
  templateUrl: './dispatch-qr.component.html',
  styleUrls: ['./dispatch-qr.component.scss']
})
export class LogisticsDispatchQrComponent implements OnInit {
	///

  ngVersion = VERSION.full;
  @ViewChild('scanner', { static: true })
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  ///


  ///

  cover = "cover";
  torch = true;
  classState = '';
  logInState = '';
  codeData: QRCode;
  showScanner: string = "loggedOut";
	
  constructor(
    public lQR: LogisticsQRService,
    public lS: LogisticsLoginService,
    ) { }

  ngOnInit() {
    this.lQR.reset();
    this.lS.getDropdown("scanDestinations");
    this.lS.getDropdown('dispatchStyles');
  }

  ngOnDestroy() {
    this.lQR.previousref = '';
    this.lQR.orderref = '';
  }

  isAuthenticated() {
    this.logInState = (this.lS.isAuthenticated() ? "loggedIn" : "loggedOut" );
    return this.lS.isAuthenticated();
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    // console.debug('Devices: ', cameras);
    this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
    if (this.showScanner == "loggedIn") {
      this.codeData = {code:''};
      this.qrResultString = resultString;
      this.codeData['code'] = resultString;
      this.lQR.logCode(this.codeData);
      this.classState = (this.lQR.recall ? "codeRecall" : (this.lQR.scanerror == '' ? (this.lQR.save ? "codeSave" : "codeRead" ) : this.lQR.scanerror));
      this.lQR.scanerror = '';
      setTimeout(() => {
        this.classState = '';
      }, 500);
    }
  }

  onDeviceSelectChange(selected: string) {
    // console.debug('Selection changed: ', selected);
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  setCameras(event) {
    this.availableDevices = event;
    this.currentDevice = this.availableDevices[0];
      //selects the devices's back camera by default
    for (const device of event) {
      if (/back|rear|environment/gi.test(device.label)) {
        //this.scanner.changeDevice(device);
        this.scanner.device = device;
        this.currentDevice = device;
        break;
      }
    }
    this.scanner.hasDevices.subscribe((x: boolean) => this.hasDevices = x);
    // or you can manually check if the component found them
    // this.scanner.camerasNotFound.subscribe(() => this.hasDevices = false);
    this.scanner.scanComplete.subscribe((x: Result) => this.qrResult = x);
    this.scanner.permissionResponse.subscribe((x: boolean) => this.hasPermission = x);
  }

  async loadForLogging(){
    if (this.lQR.previousref !== '' && this.lQR.previousref !== this.lQR.orderref) {      
      const res = await this.lS.confirm('Do you want to change the active Order Reference?');
      this.lQR.orderref = (res ? this.lQR.orderref : this.lQR.previousref);
    }
    this.lQR.previousref = this.lQR.orderref;
    this.lQR.fullOrder(this.lQR.orderref);
    this.showScanner = (this.lQR.orderref !== "" ? "loggedIn" : "loggedOut");
  }

  reset() {
    this.lQR.previousref = '';
    this.lQR.orderref = '';
    this.lQR.reset();
    this.showScanner = (this.lQR.orderref !== "" ? "loggedIn" : "loggedOut");
  }

}
