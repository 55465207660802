<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.addArticleService.editmode">Add Message</h2>
				<h2 *ngIf="this.addArticleService.editmode">Edit Message</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.addArticleService.editmode">
				<!--<button mdbBtn class="btn btn-info" routerLink="/team/messages/articles">View Articles</button>-->
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Message</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.addArticleService.editmode">
				<!--<button mdbBtn class="btn btn-info" routerLink="/team/messages/articles">View Articles</button>-->
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Message</button>
			</div>
		</div>
		<br>
		<div class="col col-xl-97">	 	
			<div class="form-container">

	      <mat-form-field *ngIf="editSelected() || this.addArticleService.editmode">
    			<mat-label>Message Type</mat-label>
			    <mat-select placeholder="Message Type" [(ngModel)]="this.addArticleService.type" (ngModelChange)="this.addArticleService.typeChange()">
			      <mat-option value="announcement">Announcement</mat-option>
			      <mat-option value="blog">Blog Post</mat-option>
			    </mat-select>
			  </mat-form-field>
			  
			  <mat-form-field *ngIf="this.addArticleService.editmode">
    			<mat-label>Article Title</mat-label>
			    <mat-select placeholder="Article Title" [(ngModel)]="this.addArticleService.articleref" (keydown.tab)="loadForEdit()">
			      <mat-option *ngFor="let article of this.dropDownDataService.dropdown.articleList" #prod (click)="this.loadForEdit()" value="{{ article.articleref }}">{{ article.title }} - {{ (article.date==null ? article.createddate : article.date) | date:'MMM d, yyyy' }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <ng-container *ngIf="this.addArticleService.type=='announcement'">

				  <hr *ngIf="editSelected()">

					<div class="row" *ngIf="editSelected()">
					  <div class="col main-header">
					  	<mat-form-field class="w-100">
    						<mat-label>Start Date</mat-label>
						    <input matInput [matDatepicker]="startdate" maxlength="45" [(ngModel)]="this.addArticleService.startdate">
								<mat-datepicker-toggle matIconSuffix [for]="startdate"></mat-datepicker-toggle>
						    <mat-datepicker #startdate></mat-datepicker>
						  </mat-form-field>
						</div>
					  <div class="col-auto pr-0">
							<button mdbBtn class="btn btn-link" (click)="this.addArticleService.startdate=null">Clear</button>
						</div>
					</div>

					<div class="row" *ngIf="editSelected()">
					  <div class="col main-header">
					  	<mat-form-field class="w-100" [class.errorcheck]="requireExpiry()">
    						<mat-label>Expiry Date</mat-label>
						    <input matInput [matDatepicker]="expdate" maxlength="45" [(ngModel)]="this.addArticleService.expirydate">
								<mat-datepicker-toggle matIconSuffix [for]="expdate"></mat-datepicker-toggle>
						    <mat-datepicker #expdate></mat-datepicker>
						  </mat-form-field>
						</div>
					  <div class="col-auto pr-0">
							<button mdbBtn class="btn btn-link" (click)="this.addArticleService.expirydate=null">Clear</button>
						</div>
					</div>

				</ng-container>

				<div class="row" *ngIf="editSelected()">
				  <div class="col main-header">
				  	<mat-form-field class="w-100">
    					<mat-label>Date</mat-label>
					    <input matInput [matDatepicker]="date" maxlength="45" [(ngModel)]="this.addArticleService.date">
							<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
					    <mat-datepicker #date></mat-datepicker>
					  </mat-form-field>
					</div>
				  <div class="col-auto pr-0">
						<button mdbBtn class="btn btn-link" (click)="this.addArticleService.date=null">Clear</button>
					</div>
				</div>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Title</mat-label>
			    <input matInput placeholder="Title" maxlength="200" [class.errorcheck]="this.addArticleService.title==null" [(ngModel)]="this.addArticleService.title">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Author</mat-label>
			    <input matInput placeholder="Author" maxlength="200" [class.errorcheck]="this.addArticleService.author==null" [(ngModel)]="this.addArticleService.author">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Body</mat-label>
			    <textarea matInput placeholder="Body" rows="8" [class.errorcheck]="this.addArticleService.body==null" [(ngModel)]="this.addArticleService.body"></textarea>
			  </mat-form-field>

			  <ng-container *ngIf="this.addArticleService.type!=='blog'">

					<mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.addArticleService.canread" *ngIf="editSelected()">
		        User can dismiss (mark read)?
		      </mat-slide-toggle> 

				  <hr *ngIf="editSelected()">

				  <p class="">Message for:</p>

				  <div *ngFor="let target of this.addArticleService.targets; let itemindex=index">

						<div class="col-8 pl-0" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <mat-form-field>
    							<mat-label>Email</mat-label>
							    <mat-select placeholder="Email" [(ngModel)]="this.addArticleService.targets[itemindex]['email']">
							      <mat-option *ngIf="itemindex==0" value="all">* All Portal Clients *</mat-option>
				      			<mat-option *ngFor="let contact of this.dropDownDataService.dropdown.contactList" #prod value="{{ contact.email }}">{{ contact.firstname }} {{ contact.lastname }} [{{ contact.companyname }}]</mat-option>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-4" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <div class="row">
								  <button mat-icon-button class="col" (click)="this.addArticleService.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
								  <button mat-icon-button class="col" *ngIf="this.addArticleService.targets.length>1" (click)="this.addArticleService.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
								</div>

							</div>
						</div>

					</div>

				</ng-container>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Image URL</mat-label>
			    <input matInput placeholder="Image URL" type="text" maxlength="2048" [(ngModel)]="this.addArticleService.imageurl" (ngModelChange)="this.addArticleService.sanitizeURL()">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Article URL</mat-label>
			    <input matInput placeholder="Article URL" type="text" maxlength="2048" [(ngModel)]="this.addArticleService.url">
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [(ngModel)]="this.addArticleService.notes"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="editSelected()">
			
			<ng-container *ngIf="!this.addArticleService.editmode || this.addArticleService.articleref!==''">

				<ng-container *ngIf="this.addArticleService.type=='blog'">
					<h2>{{ this.addArticleService.type | titlecase }} Preview</h2>
					<br>
					<div>
					  <mdb-card
					    [style.background-image]="this.addArticleService.imgurl"
					    class="card-image mb-3 w-100"
					  >
					    <div class="text-white text-left align-items-left rgba-grey-strong py-5 px-5 waves-light">
					      <div>
					        <h3 class="card-title pt-2 mb-0"><strong>{{ this.addArticleService.title }}</strong></h3>
					        <p class="card-title pt-2 mb-0"><strong>{{ this.addArticleService.author }}</strong></p>
					        <p class="card-title pt-2"><strong>{{ this.addArticleService.date | date:'MMM d, yyyy' }}</strong></p>
					        <p style=" white-space: pre-line">{{ this.addArticleService.body }}</p>
					        <a *ngIf="this.addArticleService.url!=='' && this.addArticleService.url!==null" mdbBtn color="primary" href="{{ this.addArticleService.url }}" target="_blank" class="waves-light" mdbWavesEffect>
					          <mdb-icon far icon="newspaper" class="left"></mdb-icon>
					          View article</a>
					      </div>
					    </div>
					  </mdb-card>
					</div>
					<hr>
				</ng-container>
				
				<ng-container *ngIf="this.addArticleService.type=='announcement'">
					<h2>{{ this.addArticleService.type | titlecase }} Preview</h2>
					<br>
					<div>
					  <mdb-card
					  	id="closeablecard"
					    [style.background-image]="this.addArticleService.imageurl"
					    class="card-image mb-3 w-100 mt-4"
					  >
							<div *ngIf="this.addArticleService.canread" class="card-header bg-transparent border-bottom-0">
					      <button type="button" class="close" aria-label="Close">
					        <span aria-hidden="true">&times;</span>
					      </button>
					    </div>
					    <div class="text-white text-left align-items-left py-5 px-5 waves-light" [class.mt-n5]="this.addArticleService.canread"	style="background-color: rgb(173, 196, 219)">
					      <div>
					        <h3 class="card-title mb-0"><strong>New Message: {{ this.addArticleService.title }}</strong></h3>
					        <p class="card-title pt-1"><strong>{{ this.addArticleService.date | date:'MMM d, yyyy' }}</strong></p>
					        <p style=" white-space: pre-line">{{ this.addArticleService.body }}</p>
					        <p class="card-title mb-0"><strong>{{ this.addArticleService.author }}</strong></p>
						      <div *ngIf="this.addArticleService.imageurl!=='' && this.addArticleService.imageurl!==null" class="row my-4">  
						        <div class="col text-center">
						          <img style="max-width: 100%" [src]="this.addArticleService.aimgurl">
						        </div>
						      </div>
					        <a *ngIf="this.addArticleService.url!=='' && this.addArticleService.url!==null" mdbBtn color="primary" href="{{ this.addArticleService.url }}" target="_blank" class="waves-light mt-4" mdbWavesEffect>
					          <i class="fas fa-external-link-alt"></i>
					          Open Link</a>
					      </div>
					    </div>
					  </mdb-card>
					</div>
					<hr>
				</ng-container>
			
			</ng-container>

			<button *ngIf="!this.addArticleService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onSave()">Save</button>

			<button *ngIf="this.addArticleService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canUpdate()" (click)="this.addArticleService.saveChanges()">Update</button>

			<button *ngIf="this.addArticleService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>