<h3>Remittance for Delivery {{ this.tableService.selected[0]?.iddelivery | delivery }} ({{ (+this.tableService.selected[0]?.selectout==1 ? 'Select-Outs' : (+this.tableService.selected[0]?.smalldel==1 ? 'Smalls' : 'Standard' )) }}):</h3>
<br>
<table class="table col-sm">
	<tbody>
		<tr class='tableseperator'><td></td><td></td></tr>
		<tr>
			<td>Cultivar</td>
			<td>{{ this.tableService.selected[0]?.cultivar }}</td>
		</tr>
		<tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td>Total WNIS (kg)</td>
			<td>{{ +this.tableService.selected[0]?.wnis | number:'1.2-2' }} kg</td>
		</tr>
		<tr>
			<td>WNIS (kg) excl. Smalls</td>
			<td>{{ (+this.tableService.selected[0]?.wnis > 0 ? +this.tableService.selected[0]?.wnis - +this.tableService.selected[0]?.smalls : 0) | number:'1.2-2' }} kg</td>
		</tr>
		<tr>
			<td>Smalls (kg)</td>
			<td>{{ +this.tableService.selected[0]?.smalls | number:'1.2-2' }} kg</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td>Total DNIS (kg) @ {{ this.tableService.selected[0]?.dryfloorlevel | percent:'1.1' }}</td>
			<td>{{ (+this.tableService.selected[1].dnis + +this.tableService.selected[1].smallsdnis ) | number:'1.2-2' }} kg</td>
		</tr>
		<tr>
			<td>DNIS (kg) @ {{ this.tableService.selected[0]?.dryfloorlevel | percent:'1.1' }}, excl. Smalls</td>
			<td>{{ +this.tableService.selected[1].dnis | number:'1.2-2' }} kg</td>
		</tr>
		<tr>
			<td>Smalls DNIS (kg) @ {{ this.tableService.selected[0]?.dryfloorlevel | percent:'1.1' }}</td>
			<td>{{ +this.tableService.selected[1].smallsdnis | number:'1.2-2' }} kg</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td>Moisture - Whole Nut (%)</td>
			<td>{{ +this.tableService.selected[0]?.moisture | percent:'1.2' }}</td>
		</tr>
		<tr>
			<td>Moisture - Kernel (%)</td>
			<td>{{ +this.tableService.selected[0]?.wetkernel | percent:'1.2' }}</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td>SKR (%)</td>
			<td>{{ +this.tableService.selected[0]?.skr | percent:'1.2' }}</td>
		</tr>
		<tr>
			<td>USKR (%)</td>
			<td>{{ +this.tableService.selected[0]?.uskr | percent:'1.2' }}</td>
		</tr>
		<tr>
			<td>Darks (%)</td>
			<td>{{ +this.tableService.selected[0]?.dkr | percent:'1.2' }}</td>
		</tr>
		<tr>
			<td>Collected?</td>
			<td>{{ ( +this.tableService.selected[0]?.collected ? "Yes" : "No" ) }}</td>
		</tr>
		<ng-container *ngIf="this.tableService.selected[1].modeldnissizes">
			<tr class='tableseperator'><td></td><td></td></tr>
			<tr>
				<td>DNIS >25mm</td>
				<td>{{ +this.tableService.selected[1].sizepercents.dnis25 | percent:'1.2' }} @ {{ +this.tableService.selected[1].sizepricesinv.dnis25 | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr>
				<td>DNIS >22mm</td>
				<td>{{ +this.tableService.selected[1].sizepercents.dnis22 | percent:'1.2' }} @ {{ +this.tableService.selected[1].sizepricesinv.dnis22 | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr>
				<td>DNIS >20mm</td>
				<td>{{ +this.tableService.selected[1].sizepercents.dnis20 | percent:'1.2' }} @ {{ +this.tableService.selected[1].sizepricesinv.dnis20 | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
			</tr>
			<tr>
				<td>DNIS >18mm</td>
				<td>{{ +this.tableService.selected[1].sizepercents.dnis18 | percent:'1.2' }} @ {{ +this.tableService.selected[1].sizepricesinv.dnis18 | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
			</tr>
		</ng-container>
		<tr class='tableseperator'><td></td><td></td></tr>
		<tr>
			<td>SK Gross Price / kg of DNIS</td>
			<td>{{ +this.tableService.selected[1].gdnisprice | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].cultivarbonus > 0">
			<td>Cultivar/ kg of DNIS</td>
			<td>{{ +this.tableService.selected[1].cultivarbonus | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].darkprice > 0">
			<td>Darks Price / kg of DNIS</td>
			<td>{{ (+this.tableService.selected[1].dnis>0 ? +this.tableService.selected[1].darkprice / +this.tableService.selected[1].dnis : 0) | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].smallsprice">
			<td>Smalls / kg of DNIS Smalls</td>
			<td>{{ (+this.tableService.selected[1].dnis>0 ? +this.tableService.selected[1].smallsprice / +this.tableService.selected[1].dnis : 0) | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].selectoutprice > 0">
			<td>Select-outs / kg of DNIS</td>
			<td>{{ (+this.tableService.selected[1].dnis>0 ? +this.tableService.selected[1].selectoutprice / +this.tableService.selected[1].dnis : 0) | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td class="pl-4">Gross SK Price</td>
			<td class="pl-4">{{ +this.tableService.selected[1].grossprice | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].darkprice > 0">
			<td class="pl-4">Gross Darks Price</td>
			<td class="pl-4">{{ +this.tableService.selected[1].darkprice | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].smallsrate">
			<td class="pl-4">Gross Smalls Price</td>
			<td class="pl-4">{{ +this.tableService.selected[1].smallsprice | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].selectoutrate > 0">
			<td class="pl-4">Gross Select-outs Price</td>
			<td class="pl-4">{{ +this.tableService.selected[1].selectoutprice | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td>Total Gross Price</td>
			<td>{{ +this.tableService.selected[1].totalgrossprice | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr class='tableseperator'><td></td><td></td></tr>
		<tr>
			<td class="pl-4">Drying Fee</td>
			<td class="pl-4 negnumber">({{ +this.tableService.selected[1].dryfee | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr>
			<td class="pl-4">Process Fee</td>
			<td class="pl-4 negnumber">({{ +this.tableService.selected[1].processfee | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr>
			<td class="pl-4">Marketing Fee</td>
			<td class="pl-4 negnumber">({{ +this.tableService.selected[1].marketingfee | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr>
			<td class="pl-4">Transport Fee</td>
			<td class="pl-4 negnumber">({{ +this.tableService.selected[1].transportfee | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr>
			<td class="pl-4" *ngIf="+this.tableService.selected[1].vatable == 1">SAMAC Fee ({{ +this.tableService.selected[1].samacrate | currency:'ZAR':'symbol-narrow' }}) ex VAT</td>
			<td class="pl-4" *ngIf="+this.tableService.selected[1].vatable != 1">SAMAC Fee ({{ +this.tableService.selected[1].samacrate | currency:'ZAR':'symbol-narrow' }}) inc VAT</td>
			<td class="pl-4 negnumber">({{ +this.tableService.selected[1].samacfee | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr *ngIf="+this.tableService.selected[1].mrlfee>0">
			<td class="pl-4" *ngIf="+this.tableService.selected[1].vatable == 1">MRL Test (ex VAT)</td>
			<td class="pl-4" *ngIf="+this.tableService.selected[1].vatable != 1">MRL Test (inc VAT)</td>
			<td class="pl-4 negnumber">({{ +this.tableService.selected[1].mrlfee | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr>
			<td>Total Fees</td>
			<td class="negnumber">({{ +this.tableService.selected[1].netfees | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }})</td>
		</tr>
		<tr class='tableseperator'><td></td><td></td></tr>
		<tr>
			<td>Net Price</td>
			<td [class.negnumber]="+this.tableService.selected[1].netprice<0">{{ +this.tableService.selected[1].netprice  | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr *ngIf="this.tableService.selected[1].vatable==1">
			<td class="pl-4">VAT Payable</td>
			<td class="pl-4" [class.negnumber]="+this.tableService.selected[1].vatpayable<0">{{ +this.tableService.selected[1].vatpayable | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr class='tableseperatorlight'><td></td><td></td></tr>
		<tr *ngIf="this.tableService.selected[1].vatable==1">
			<td>Net Price (inc. VAT)</td>
			<td [class.negnumber]="+this.tableService.selected[1].netvat<0">{{ +this.tableService.selected[1].netvat | currency:this.tableService.selected[1].invcurrency:'symbol-narrow' }}</td>
		</tr>
		<tr class='tableseperator'><td></td><td></td></tr>

	</tbody>
</table>
<br><br>