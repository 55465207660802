<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.settingsService.editmode">Manage Storage</h2>
				<h2 *ngIf="this.settingsService.editmode">Storage Archive</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.settingsService.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Storage Archive</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.settingsService.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Manage Storage</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7" *ngIf="!this.settingsService.editmode">

			<hr>

			<div class="my-3 inline">
				<h4>Add New Storage Type:</h4>
			</div>

			<div class="row">
				<div class="col-5 inline">
					<div class="form-container">
						
						<mat-form-field>
					    <input matInput placeholder="Type" maxlength="45" [(ngModel)]="this.settingsService.type">
					  </mat-form-field>

					</div>
				</div>
				<div class="col-5 inline">
					<div class="form-container">

					  <mat-form-field>
					    <mat-select placeholder="Contents" [(ngModel)]="this.settingsService.contents">
					      <mat-option value="wnis">WNIS (kg)</mat-option>
					      <mat-option value="bags">DNIS Bags</mat-option>
					      <mat-option value="boxes">Boxed Kernel</mat-option>
					      <mat-option value="kernel">Kernel (kg)</mat-option>
					      <mat-option value="shells">Shells (kg)</mat-option>
					    </mat-select>
					  </mat-form-field>

					</div>
				</div>
				<div class="col-2 inline">
					<div class="form-container">
			  
						<button mat-icon-button class="col" (click)="this.settingsService.saveType()"><i class="inline material-icons blue-icon">save</i></button>

					</div>
				</div>
			</div>

		 	<div  class="col px-0" *ngFor="let storage of this.settingsService.storage.current; let sIndex=index;">

				<ng-container *ngIf="sIndex==0">
					<hr>
					<h4 class="my-3 inline">{{ storage.type | titlecase }}: </h4><h5 class="ml-2 inline">{{ storage.contents | uppercase }}</h5><button mat-icon-button class="inline" (click)="this.settingsService.addItem(storage.type)"><i class="material-icons green-icon mb-2">add_circle</i></button>
				</ng-container>

				<ng-container *ngIf="sIndex>0 && storage.type!==this.settingsService.storage.current[sIndex-1]['type']">
					<hr>
					<h4 class="my-3 inline">{{ storage.type | titlecase }}: </h4><h5 class="ml-2 inline">{{ storage.contents | uppercase }}</h5><button mat-icon-button class="inline green-icon" (click)="this.settingsService.addItem(storage.type)"><i class="material-icons mb-2">add_circle</i></button>
				</ng-container>

				<div>

					<div class="col-9 inline">
						<div class="form-container">
						  
						  <mat-form-field>
				    		<input matInput placeholder="Storage Name" [(ngModel)]="storage.name">
						  </mat-form-field>

						</div>
					</div>

					<div class="col-3 inline">
						<div class="form-container">
						  <div class="row">
						  	<ng-container *ngIf="storage.idstorage>0">
								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.request!=='renameStorage' || (this.settingsService.request=='renameStorage' && 
								  !(this.settingsService.saving && this.settingsService.idstorage==storage.idstorage))"
								  (click)="this.settingsService.renameItem(sIndex)"><i class="material-icons green-icon">done</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index==sIndex && 
								  this.settingsService.idstorage==storage.idstorage && this.settingsService.request=='renameStorage' && this.settingsService.saving"
								  ><i class="inline spinner material-icons amber-icon">sync</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="storage.used==1 && (this.settingsService.request!=='archiveStorage' || (this.settingsService.request=='archiveStorage' && 
								  !(this.settingsService.saving && this.settingsService.idstorage==storage.idstorage)))"
								  (click)="this.settingsService.archiveItem(sIndex)"><i class="material-icons">archive</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="storage.used==0 && (this.settingsService.request!=='archiveStorage' || (this.settingsService.request=='archiveStorage' && 
								  !(this.settingsService.saving && this.settingsService.idstorage==storage.idstorage)))"
								  (click)="this.settingsService.deleteSavedItem(sIndex)"><i class="material-icons red-icon">delete</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index==sIndex && 
								  this.settingsService.idstorage==storage.idstorage && this.settingsService.request=='archiveStorage' && this.settingsService.saving"
								  ><i class="inline spinner material-icons amber-icon">sync</i></button>

								</ng-container>
						  	<ng-container *ngIf="storage.idstorage==0">
								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index!==sIndex || (this.settingsService.index==sIndex  &&
								  !this.settingsService.saving)" 
								  [disabled]="storage.name==''" [class.blue-icon]="storage.name!==''" [class.grey-icon]="storage.name==''" (click)="this.settingsService.saveItem(sIndex)"><i class="inline material-icons">save</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index==sIndex && 
								  this.settingsService.saving && this.settingsService.request=='saveStorage'"><i class="inline spinner material-icons amber-icon">sync</i></button>

								  <button mat-icon-button class="col" (click)="this.settingsService.deleteItem(sIndex)"><i class="inline material-icons red-icon">delete</i></button>
						  	</ng-container>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col col-lg-9 col-xl-7" *ngIf="this.settingsService.editmode">
		 	<div  class="col px-0" *ngFor="let storage of this.settingsService.storage.archived; let sIndex=index;">
				<ng-container *ngIf="sIndex==0">
					<hr>
					<h4 class="my-3 inline">{{ storage.type | titlecase }}: </h4><h5 class="ml-2 inline">{{ storage.contents | uppercase }}</h5>
				</ng-container>

				<ng-container *ngIf="sIndex>0 && storage.type!==this.settingsService.storage.archived[sIndex-1]['type']">
					<hr>
					<h4 class="my-3 inline">{{ storage.type | titlecase }}: </h4><h5 class="ml-2 inline">{{ storage.contents | uppercase }}</h5>
				</ng-container>

				<div>

					<div class="col-9 inline">
						<div class="form-container">
						  
						  <mat-form-field>
				    		<input matInput placeholder="Storage Name" [(ngModel)]="storage.name">
						  </mat-form-field>

						</div>
					</div>

					<div class="col-3 inline">
						<div class="form-container">
						  <div class="row">
							  
							  <button mat-icon-button class="col" 
							  *ngIf="this.settingsService.request!=='unarchiveStorage' || (this.settingsService.request=='unarchiveStorage' && 
							  !(this.settingsService.saving && this.settingsService.idstorage==storage.idstorage))"
							  (click)="this.settingsService.unarchiveItem(sIndex)"><i class="material-icons purple-icon">unarchive</i></button>

							  <button mat-icon-button class="col" 
							  *ngIf="this.settingsService.index==sIndex && 
							  this.settingsService.idstorage==storage.idstorage && this.settingsService.request=='unarchiveStorage' && this.settingsService.saving"
							  ><i class="inline spinner material-icons amber-icon">sync</i></button>
						  	
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<br><br>
	</div>


	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>