import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { HeaderService } from '../header/header.service';
import { CalculatorService } from '../calculator/calculator.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
	deliveryData: {};
	memberSelect;
  d: Date = new Date();

  constructor(
  	public loginService: LoginService,
    public calculatorService: CalculatorService,
    private portalService: PortalService,
    private headerService: HeaderService
  	) { }

  ngOnInit() {
  	if(this.loginService.isAuthenticated()) {
      this.setSeasons();
      this.memberSelect = this.loginService.selectedmember;
      this.cacheForMember();
    }
    window.scrollTo(0,0);
  }

   cacheForMember() {
    if (this.memberSelect != null) {
      this.calculatorService.loadPriceModel(this.memberSelect);
      this.calculatorService.getCultivarList();
      this.calculatorService.loadOverview(this.memberSelect);
    }
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
  }

  onMemberSelected(membership, membername, season) {
    this.memberSelect = this.loginService.selectMembership(membership, season);
    this.headerService.refreshMenu();
    this.cacheForMember();
  }

  onSeasonSelected(season) {
    this.loginService.season = season;
    this.cacheForMember();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  setSeasons() {
    for (var i = 2019; i <= +this.d.getFullYear(); i++) {
      this.loginService.seasons[i-2019] = i;
    }
  }

}
