import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { TeamLoginService } from '../../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../../logging/dropdown.service';
import { SettingsUsersService } from '../users.service';


@Component({
  selector: 'app-settings-users',
  templateUrl: './users-new.component.html',
  styleUrls: ['./users-new.component.scss']
})
export class SettingsUsersNewComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  d: Date;
  contact;
  scroll: boolean = true;
  isLoadingResults = true;

  constructor(
    public loginService: TeamLoginService,
  	public usersService: SettingsUsersService,
    public dropDownDataService: DropDownDataService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.usersService.idcontact = 0;
    this.usersService.resetForm();
    this.dropDownDataService.getDropdown("producerList");
    this.dropDownDataService.getDropdown("internalContactList");
  }

  ngOnDestroy() {
    this.usersService.idMembership = null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
  
  cacheForMember() {
    this.usersService.loadUsers();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.usersService.clickedIndex !== null;
  }

  canSave() {
    return (this.usersService.invitations.length>0 ? true : false);
  }

  async onSave() {
    const res = await this.loginService.confirm("Are you sure you want to send portal invitations to these contacts?");
    if (res) {
      this.usersService.saveUserMembers();
      this.usersService.resetForm();
      this.loginService.toast("Portal invitations Successful","Sent!");
    } else {
      this.loginService.toast("Portal invitations Unsuccessful","No invitations sent",'warn');
    }
  }

}