import { Component, OnInit, OnDestroy } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';

import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-qualitycert',
  templateUrl: './qualitycert.component.html',
  styleUrls: ['./qualitycert.component.scss']
})
export class QualityCertComponent implements OnInit {
  d: Date = new Date();
  quality: any;

  constructor(
  	public lS: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tS: TableDataService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
    this.tS.loadQuality();
  }

  ngOnDestroy() {
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  async svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('qualitycert');

		// create a new jsPDF instance
		const pdfTeam = await new jsPDF('p', 'pt', 'a4');
    var savename = this.pS.portalMod()['companyname'] + ' - Quality Certificate - ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref +'.pdf';
    this.setupPDF(pdfTeam, svgElement, savename);
	}

	async setupPDF(pdfTeam, svgElement, savename) {
		const pdf = await pdfTeam.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    });
    const pdfprint = await pdf.save(savename, {returnPromise: true});
	}

  transdetail(i) {
    return 'translate(0 ' + 10.2 * i + ')';
  }

  transsize(i) {
    return 'translate(0 ' + 12.5 * i + ')';
  }

  transgrouped(option = 0) {
    var step = -1;
    switch (option) {
      case 0:
        step = this.tS.quality['pallets'].length * 12.5 - 35;
        break;
      case 1:
        for (const [k,v] of Object.entries(this.tS.quality['sizes'])) {
          step += (v['split']>0 ? 1 : 0);
        }
        step = step * 12.8 - 35;
        break;      
      default:
        step = 0;
        break;
    }
    return 'translate(0 ' + step + ')';
  }

  transcontact(type, field, i = null) {
    return 'translate(0 ' + 10.2 * this.transstep(type, field, i) + ')';
  }

  transpage() {
    return 'translate(0 ' + Math.max(0, 10.2 * Math.max(this.transstep('b', 'contactshift'), this.transstep('c', 'contactshift')) - 70) + ')';
  }

  transstep(type, field, i = null) {    
    type = (type=='b' ? 'buyer' : 'consignee');
    var step = 1;
    switch (field) {
      case "contactshift":
      case "email":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'tel']==null ? 0 : 1);
      case "tel":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'con']==null ? 0 : 1);
      case "con":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'vat']==null ? 0 : 1);
      case "vat":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'country']==null ? 0 : 1);
        break;
    }
    return step;
  }

  changeValue(group: string, field: string, index: number, $event) {
    if (group=="sized") {
      var item = this.tS.quality[group][index]['size'];
      this.tS.quality['sizes'][item][field] = $event.replace(/[^\/\d.]/g,'') / 100;
      this.tS.quality[group][index][field] = $event.replace(/[^\/\d.]/g,'') / 100;
    } else {
      this.tS.quality[group][index][field] = $event.replace(/[^\/\d.]/g,'') / (field=='nutsperkg' ? 1 : 100);
    }
  }

  changePercent() {

  }


}
