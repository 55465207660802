import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';

import { LogisticsResetService } from './reset.service';
import { CustomValidators } from './reset.validators';
import { PortalService } from '../../../auth/portal.service';

@Component({
  selector: 'app-logisticsreset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class LogisticsResetComponent implements OnInit {
	public frmSignup: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  httpRes: any;

  constructor(
    public resetService: LogisticsResetService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    public portalService: PortalService) { }

  ngOnInit() {
    this.resetService.resetParams = this.route.snapshot.queryParams;
    if (this.resetService.resetParams != null) {
      this.resetService.confirmResetCode();
    }
    
    this.resetForm = new UntypedFormGroup({
      'userData': new UntypedFormGroup({
        'email': new UntypedFormControl(null, [Validators.required, Validators.email])
      })
    });
    this.frmSignup = this.createSignupForm();
    if (this.resetService.emailToReset != null) {
      this.resetForm.get('userData').get('email').setValue(this.resetService.emailToReset);
    }
    window.scrollTo(0,0);
  }

  ngOnDestroy() {  
    this.resetService.resetSubmitted = false;    
  }

  createSignupForm(): UntypedFormGroup {
    return this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }

  savePassword() {
    if (
      !this.frmSignup.controls['confirmPassword'].hasError('required') &&
      !this.frmSignup.controls['password'].hasError('minlength') &&
      !this.frmSignup.controls['password'].hasError('hasNumber') &&
      !this.frmSignup.controls['password'].hasError('hasCapitalCase') &&
      !this.frmSignup.controls['password'].hasError('hasSmallCase') &&
      this.frmSignup.controls['password'].hasError('hasSpecialCharacters') &&
      !this.frmSignup.controls['confirmPassword'].hasError('NoPassswordMatch')
    ) {
      this.resetService.setNewPassword(this.frmSignup.get('password').value);
    }
  }

  resetRequest() {
    const email = this.resetForm.value.userData.email;
    this.resetForm.reset();
    this.resetService.resetPassword(email);    
    this.resetService.resetSubmitted = true;
  }


}