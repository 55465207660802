<div class="col pt-3 mx-auto" *ngIf="isAuthenticated()">
	<br>
	<div class="row justify-content-between">
		<div class="col-xl-6 col-sm-12 row justify-content-start">
			<div class="mt-2 ml-0">
				<h2 style="line-height: 1.3em">Welcome {{ this.loginService.uname }},</h2>
			</div>
			<br>
		</div>
	</div>

	<app-buyingbidoffers></app-buyingbidoffers>
	<!--
	<app-announcement></app-announcement>
	<app-article></app-article>
	-->
	
</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3 mx-auto" *ngIf="!isAuthenticated()">
	<app-buyinglogin></app-buyinglogin>
</div>