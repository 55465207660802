import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../login/teamlogin.service';

declare var require: any;

@Injectable()
export class TeamResetService{
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public resetParams: any[any];
	public emailToReset: string = "";
	public codeConfirmed: boolean = false;
	public changeSuccess: boolean = false;
	public resetSubmitted: boolean = false;
	passwordHash: string;


	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService) {}

	resetPassword(email: string) {
		localStorage.clear();
		this.postBody = { 
			"name":"resetRequestU",
			"param":{
				"email": email
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json'
				})
			}
			)
			.subscribe(
        (response) => {
          this.httpRes = response;
        },
        (error) => console.log(error)
      );
	}

	setNewPassword(password: string) {
		if (localStorage.getItem('id_resettoken') != '[]') {
			var sha512 = require('js-sha512');
      this.passwordHash = sha512(password); 
			this.postBody = { 
				"name":"saveNewPassword",
				"param":{
					"password": this.passwordHash,
				}
			};
			this.passwordHash = "";
			return this.http.post(
				this.loginService.httpRoot,
				this.postBody,
				{
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + localStorage.getItem('id_resettoken')
					})
				}
				)
				.subscribe(
	        (response) => {
	          this.httpRes = response;
	          if (this.httpRes.hasOwnProperty('response')) {
	          	this.changeSuccess = true;
	          } else {
	          	this.changeSuccess = false;
	          }
	        },
	        (error) => console.log(error)
	      );
		} else {
			console.log('Not authorised to change password');
		}
	}

	confirmResetCode() {
		localStorage.clear();
		this.postBody = { 
			"name":"checkResetCode",
			"param":{
				"email": this.resetParams.email,
				"code": this.resetParams.code
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json'
				})
			}
			)
			.subscribe(
        (response) => {
          this.httpRes = response;
          if (this.httpRes.hasOwnProperty('response')) {
          	localStorage.setItem('id_resettoken', this.httpRes.response.result.token);
          	this.codeConfirmed = true;
          } else {
          	this.codeConfirmed = false;
          }
        },
        (error) => console.log(error)
      );
	}

	validLink(type: string) {
		switch (type){
			case "resetPassword":
				if (this.resetParams.code != null && !this.changeSuccess && this.codeConfirmed) {
					return true;
				} else {
					return false;
				}
				break;
			case "resetFailure":
				if (this.resetParams.code != null && !this.changeSuccess && !this.codeConfirmed) {
					return true;
				} else {
					return false;
				}
				break;
			case "resetRequest":
				if (this.resetParams.code == null && !this.resetSubmitted) {
					return true;
				} else {
					return false;
				}
				break;
			case "resetSuccess":
				if (this.resetParams.code != null && this.changeSuccess) {
					return true;
				} else {
					return false;
				}
				break;
			case "resetSent":
				if (this.resetParams.code == null && this.resetSubmitted) {
					return true;
				} else {
					return false;
				}
				break;
		}
	}

}