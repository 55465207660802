<div class="container px-0">
	<div class="col pt-3 px-0">
		<br>
		<div class="row justify-content-start ms-0">
			<div class="col justify-content-start pl-0">
				<h2 class="mb-4">Available Stock</h2>
			</div>
		</div>
		<br>

		<div class="container px-0 hor-scroll">
			<table class="table align-middle mb-0 bg-white">
			  <thead class="bg-light">
			  	<tr class="centertext">
			    	<th class="nullcell subheaders"></th>
			    	<ng-container *ngFor="let offer of offerrange; let i = index;">
				      <th class="leftline subheaders">Price</th>
				      <th class="subheaders">Quantity</th>
				    </ng-container>
			    </tr>
			  </thead>
			  <tbody>
				  <tr *ngFor="let product of products; let j = index;" class="offerrow centertext" [class.kernel-row]="product.type=='kernel'" [class.nis-row]="product.type=='nis'">
				  	<td class="rightline sideheader" [class.kernel-sideheader]="product.type=='kernel'" [class.nis-sideheader]="product.type=='nis'">{{ product.product }}</td>
				  	<ng-container *ngFor="let offer of offerrange; let i = index;">
			  			<ng-container *ngIf="offers[[product][0].product]!==undefined">
			  				<ng-container *ngIf="offers[[product][0].product][i]!=undefined">
						      <td class="leftline"
						      	[class.tradeselect]="this.hoverid==offers[[product][0].product][i]?.id"
						      	(mouseenter)="menter('offers',product.product,offers[[product][0].product][i]?.id)"
						      	(mouseleave)="mleave()"
						      	>
						      	{{ offers[[product][0].product][i]?.price | currency:'USD' }}
						      </td>
						      <td class="tradebtn" 
						      	[class.tradeselect]="this.hoverid==offers[[product][0].product][i]?.id"
										(mouseleave)="mleave()"
						      	*ngIf="this.hoverid==offers[[product][0].product][i]?.id">
										<button mdbBtn class="btn btn-warning" (click)="show('offers')">Request</button>
						      </td>
						      <td *ngIf="this.hoverid!=offers[[product][0].product][i]?.id"
						      	(mouseenter)="menter('offers',product.product,offers[[product][0].product][i]?.id)"
						      	(mouseleave)="mleave()"
						      	>
						      	{{ offers[[product][0].product][i]?.quantity | number:'1.0-0' }}
						      </td>
						    </ng-container>
			  				<ng-container *ngIf="offers[[product][0].product][i]==undefined">
						      <td class="leftline"></td>
						      <td></td>
			  				</ng-container>
					    </ng-container>
			  			<ng-container *ngIf="offers[[product][0].product]==undefined">
					      <td class="leftline"></td>
					      <td></td>
					    </ng-container>
					  </ng-container>
				  </tr>
			  </tbody>
			</table>
		</div>
	</div>
</div>