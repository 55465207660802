import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SeasonalSettingsService } from './seasonal.service';

@Component({
  selector: 'app-seasonalsettings',
  templateUrl: './seasonal.component.html',
  styleUrls: ['./seasonal.component.scss']
})
export class SeasonalSettingsComponent implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public dropDownDataService: DropDownDataService,
    public settingsService: SeasonalSettingsService,
    public portalService: PortalService
    ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.settingsService.editmode = false;
    this.dropDownDataService.getDropdown("newSeasonSettingList");
    this.dropDownDataService.getDropdown("savedSeasonSettingList");
    this.dropDownDataService.getDropdown("priceModelListAll");
  }

  ngOnDestroy() {
    this.settingsService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        +this.settingsService.season > 0 
      ) {
      if (this.settingsService.checkSettings()==false) {
        return false
      }
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.settingsService.resetForm(state);
    this.settingsService.editmode = state;
  }

  editSelected() {
    return (this.settingsService.editmode == true || (this.settingsService.editmode == false && this.settingsService.season>0));
  }

  onCalculate() {
    if (this.canSave()) {
      this.settingsService.addSettings();
      window.scroll(0,0);
      this.settingsService.resetForm();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete all settings for season " +this.settingsService.season + "?");
    if (res) {
      this.settingsService.deleteEntry();
      this.settingsService.resetForm();
    }
  }

  checkEntry($event,itemGroup,itemindex = null,key = null) {
    var ans = 'false';
    if (this.settingsService[itemGroup.slice(0)][itemindex][key]==null) {
      return true;
    }
    if (itemindex!==null) {
      for (var i = 0; i < this.settingsService[itemGroup.slice(0)].length; i++) {
        if (itemindex!==i) {
          ans = (this.settingsService[itemGroup.slice(0)][i][key] == this.settingsService[itemGroup.slice(0)][itemindex][key] ? 'true' : ans );
        }
      }
    }
    return (ans=='true' ? true : false);
  }

  checkList(itemGroup,key) {
    var ans = 'true';
    if (this.settingsService[itemGroup.slice(0)]!==undefined) {
      var total = this.settingsService[itemGroup.slice(0)].length;
      if (total > 0) {
        for (var i = 0; i < total; i++) {
          if (this.settingsService[itemGroup.slice(0)][i][key]==null) {
            ans = 'false';
          }
          for (var j = i + 1; j < total; j++) { 
            if (this.settingsService[itemGroup.slice(0)][j][key]==null) {
              ans = 'false';
            }
            ans = (this.settingsService[itemGroup.slice(0)][i][key] == this.settingsService[itemGroup.slice(0)][j][key] ? 'false' : ans );
          }
        }
      }
    }
    return ans;
  }

}