import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { Currency, RootObject } from './currency.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { CurrencyService } from './currency.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  displayedColumns: string [] = [
    'date',
    'ratetype',
    'rate',
    'ratesource',
    'currency',
    'notes'
    ];

  processDB: CurrencyService | null;
  dataSource: Currency[] = [];
  datasrc = new MatTableDataSource<object>(this.dataSource);
  exportData;
  exportDate;
  exportMonth;
  headers;
  d: Date;

  isLoadingResults = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    public loginService: TeamLoginService,
    public currencyService: CurrencyService,
    public csvService: CsvService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
    this.updateTable();
  }

  ngOnInit() {
  	this.currencyService.clickedIndex = null;
  	this.currencyService.loadProcesses();
    window.scrollTo(0,0);
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  cacheForMember() {
    this.currencyService.loadProcesses();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.currencyService.clickedIndex = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
    this.updateTable();
  }

  onSelectRow(i: number) {
    //this.clickedId = this.table['_data'][i]['idCurrency'];
    //this.currencyService.setProcess(+this.clickedId);
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.currencyService.clickedIndex !== null;
  }

  setType(type) {
    this.currencyService.grouptype = type;
    switch(type) {
      case 'All':
        this.currencyService.protype = 'all';
        break;
      case 'Average Day Rate':
        this.currencyService.protype = 'dayavg';
        break;
      case '30 Day Average':
        this.currencyService.protype = '30dayavg';
        break;
      case '12 Month Average':
        this.currencyService.protype = '12monthavg';
        break;
    }
    this.updateTable();
  }

  updateTable() {
    this.processDB = new CurrencyService(this.http, this.loginService);
      merge(this.sort.sortChange)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.processDB!.getProcesses();
          }),
          map(data => {
            this.isLoadingResults = false;
            return data;
          }),
          catchError(() => {
            this.isLoadingResults = false;
            return observableOf([]);
          })
        ).subscribe(data => {
          this.dataSource = data['response'].result.reply;
          this.datasrc = new MatTableDataSource(this.dataSource[this.currencyService.protype]);
          this.datasrc.sort = this.sort;
          this.table.renderRows();
         });
  }

  applyFilter(event: Event) {
    var filterValue = (<HTMLInputElement>event.target).value;
    this.datasrc.filter = filterValue.trim().toLowerCase();
  }

  csvDownload() {
    this.csvService.csvDownload(this.datasrc, this.displayedColumns, 'Currency');
  }

  calTotal(field: string){ 
    var total = 0;
    for (var i = 0; i < this.datasrc.filteredData.length; i++) {
      total = total + +this.datasrc.filteredData[i][field];
    }
    return total;
  }
  
}