import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { BaseQRService } from '../../../services/qr.service'; 
import { QRCode } from '../../../interfaces/qr.interface';
import { DropDownDataService } from '../dropdown.service';
import { Sample } from '../../../interfaces/samples.interface';
import { ProdItem, Samples } from './add-palletsample.interface';
import { portalM, PortalService } from '../../../auth/portal.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddPalletSampleService{
	private postBody: {};
	httpRes: any;
  d: Date = new Date();
	public editmode: boolean = false;
	public loadedEntry: Sample;
  public dnis:number;
  public moisture:number;
  public a18:number;
  public a19:number;
  public a20:number;
  public a21:number;
  public a22:number;
  public a23:number;
  public a24:number;
  public a25:number;
  public a26:number;
  public a27:number;
  public a28:number;
  public a29:number;
  public a30:number;
  public shell:number;
  public sk:number;
  public uk:number;
  public tk:number;
  public dark:number;
  public mould:number;
  public immature:number;
  public spot791:number;
  public earlystink:number;
  public latestink:number;
  public germination:number;
  public nutborer:number;
  public discolouration:number;
	public note:string;
	public sizeddnis: number = 0;
	public save: boolean;
	public recall: boolean = false;
	public success: boolean = false;
	public versions = ['3'];
	public pallet: string;
	public manualpallet: string;
	public palletname: string;
	public palletline: string;
	public idpallet: string;
	public count: number;
	public currentcount: number;
	public dnisunit: string = 'weight';
	public code: QRCode;
	public dniss = ['a18', 'a19', 'a20', 'a21', 'a22', 'a23', 'a24', 'a25', 'a26', 'a27', 'a28', 'a29', 'a30'];

	constructor(
		private http: HttpClient,
		private lS: TeamLoginService,
		private qr: BaseQRService,
		private pS: PortalService,
		private ddS: DropDownDataService) {}


	async addCode(codeData: QRCode) {
		this.code = await this.qr.readCode(codeData, this.ddS.dropdown['productList'], this.ddS.dropdown['storageListWNIS'], this.lS.season);
		if (this.code['version']!=undefined) {
			var versioncheck = this.versions.includes(this.code['version'].toString());
			if (versioncheck) {
				var exists = false;
				for (var i=0; i<this.ddS.dropdown['palletList'].length; i++) {
					if (this.code['pallet']==this.ddS.dropdown['palletList'][i]['pallet']) {
						exists = true;
					}
				}
				if (exists) {
					this.editmode = true;
					this.loadForEdit(this.code['pallet']);
					this.idpallet = this.loadedEntry['idpallet'];
				} else {
					this.success = true;
					this.pallet = this.code['pallet'];
					this.palletname = this.pallet;
				}
			} else {
				this.lS.toast("Code Error","This label is not recognised for pallet samples",'warn');
			}
		}
	}

	addPalletSample() {
		this.convertToCount();
		this.getCount();
		this.code['code'] = this.getProductCode(this.code['stylefull'],'style');
		this.postBody = {
			"name": "addPalletSample",
			"param":{
				"season": this.lS.season,
				"pallet": this.pallet,
				"palletline": this.palletline,
				"idstorage": this.code['idstorage'],
				"iteration": this.code['iteration'],
				"code": +this.code['code'],
				"packdate": this.code['packdate'],
				"count": this.count,
				"dnis": (this.dnis !== undefined ? +this.dnis: 0),
				"moisture": +this.moisture/100,
				"dnisunit": this.dnisunit,
				"a18": (this.a18 || 0),
				"a19": (this.a19 || 0),
				"a20": (this.a20 || 0),
				"a21": (this.a21 || 0),
				"a22": (this.a22 || 0),
				"a23": (this.a23 || 0),
				"a24": (this.a24 || 0),
				"a25": (this.a25 || 0),
				"a26": (this.a26 || 0),
				"a27": (this.a27 || 0),
				"a28": (this.a28 || 0),
				"a29": (this.a29 || 0),
				"a30": (this.a30 || 0),
				"shell": (this.shell !== undefined ? +this.shell: 0),
				"sound": (this.sk !== undefined ? +this.sk: 0),
				"unsound": (this.uk !== undefined ? +this.uk : 0),
				"dark": (this.dark !== undefined ? +this.dark: 0),
				"mould": (this.mould !== undefined ? +this.mould: 0),
				"immature": (this.immature !== undefined ? +this.immature: 0),
				"spot791": (this.spot791 !== undefined ? +this.spot791: 0),
				"earlystink": (this.earlystink !== undefined ? +this.earlystink: 0),
				"latestink": (this.latestink !== undefined ? +this.latestink: 0),
				"germination": (this.germination !== undefined ? +this.germination: 0),
				"nutborer": (this.nutborer !== undefined ? +this.nutborer: 0),
				"discolouration": (this.discolouration !== undefined ? +this.discolouration: 0),
				"notes": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Save Successful",response['response']['result']['reply']);
	        } catch(e) {
	        	this.lS.toast("Save Unsuccessful","Sample not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}	

	loadForEdit(palletNo = null) {
		this.postBody = {
			"name": "loadPalletSample",
			"param":{
				"idpallet": ( palletNo ? 0 : this.idpallet),
				"pallet": ( palletNo ? palletNo : 0)
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.lS.setSession(response);
          this.httpRes = response;
          this.loadEntry(true);
    			this.editmode = true;
        },
        (error) => console.log(error)
      );
	}
	
	loadEntry(freshload = false) {
		if (this.httpRes!==undefined) {
			this.resetCount();
			this.dnisunit = 'count';
			var reply = this.httpRes.response.result.reply;
	 		this.loadedEntry = reply;
			if (this.loadedEntry !== undefined) {
				this.idpallet = this.loadedEntry['idpallet'].toString();
				this.code = {
					'stylefull': this.loadedEntry.style,
					'packdate': this.loadedEntry.packdate,
					'batch': this.loadedEntry.batch,
					"code": this.getProductCode(this.loadedEntry['idproduct'])
				}
				this.dnis = +this.loadedEntry.dnis;
				this.count = +( this.loadedEntry.count==undefined ? this.count : this.loadedEntry.count );
				this.moisture = Math.floor(+this.loadedEntry.moisture * 10000) / 100;
				this.a18 = +this.loadedEntry.a18;
				this.a19 = +this.loadedEntry.a19;
				this.a20 = +this.loadedEntry.a20;
				this.a21 = +this.loadedEntry.a21;
				this.a22 = +this.loadedEntry.a22;
				this.a23 = +this.loadedEntry.a23;
				this.a24 = +this.loadedEntry.a24;
				this.a25 = +this.loadedEntry.a25;
				this.a26 = +this.loadedEntry.a26;
				this.a27 = +this.loadedEntry.a27;
				this.a28 = +this.loadedEntry.a28;
				this.a29 = +this.loadedEntry.a29;
				this.a30 = +this.loadedEntry.a30;
				this.shell = +this.loadedEntry.shell;
				this.sk = +this.loadedEntry.sound;
				this.dark = +this.loadedEntry.dark;
				this.mould = +this.loadedEntry.mould;
				this.immature = +this.loadedEntry.immature;
				this.spot791 = +this.loadedEntry.spot791;
				this.earlystink = +this.loadedEntry.earlystink;
				this.latestink = +this.loadedEntry.latestink;
				this.germination = +this.loadedEntry.germination;
				this.nutborer = +this.loadedEntry.nutborer;
				this.discolouration = +this.loadedEntry.discolouration;
				this.note = this.loadedEntry.notes;
				this.uk = +(this.mould !== undefined ? this.mould : 0) + 
		              +(this.immature !== undefined ? this.immature : 0) + 
		              +(this.spot791 !== undefined ? this.spot791 : 0) + 
		              +(this.earlystink !== undefined ? this.earlystink : 0) + 
		              +(this.latestink !== undefined ? this.latestink : 0) + 
		              +(this.germination !== undefined ? this.germination : 0) + 
		              +(this.nutborer !== undefined ? this.nutborer : 0) + 
		              +(this.discolouration !== undefined ? this.discolouration : 0);
		    this.tk = +(this.sk !== undefined ? this.sk : 0) + 
		              +(this.uk !== undefined ? this.uk : 0) + 
		              +(this.dark !== undefined ? this.dark : 0);
				this.pallet = this.loadedEntry.palletno;
				this.palletname = this.pallet;
				this.palletline = this.loadedEntry.palletline;
				this.getCount();
		  }
	  }
	}

	saveChanges() {
		this.convertToCount();
		this.getCount();
		this.code['code'] = this.getProductCode(this.code['stylefull'],'style');
		this.postBody = {
			"name": "updatePalletSample",
			"param":{
				"idpallet": this.idpallet,
				"palletline": this.palletline,
				"code": +this.code['code'],
				"season": this.lS.season,
				"dnis": this.dnis,
				"count": this.count,
				"moisture": +this.moisture/100,
				"dnisunit": this.dnisunit,
				"a18": (this.a18 || 0),
				"a19": (this.a19 || 0),
				"a20": (this.a20 || 0),
				"a21": (this.a21 || 0),
				"a22": (this.a22 || 0),
				"a23": (this.a23 || 0),
				"a24": (this.a24 || 0),
				"a25": (this.a25 || 0),
				"a26": (this.a26 || 0),
				"a27": (this.a27 || 0),
				"a28": (this.a28 || 0),
				"a29": (this.a29 || 0),
				"a30": (this.a30 || 0),
				"shell": (this.shell !== undefined ? +this.shell: 0),
				"sound": this.sk,
				"unsound": (this.uk !== undefined ? +this.uk : 0),
				"dark": (this.dark !== undefined ? +this.dark: 0),
				"mould": (this.mould !== undefined ? +this.mould: 0),
				"immature": (this.immature !== undefined ? +this.immature: 0),
				"spot791": (this.spot791 !== undefined ? +this.spot791: 0),
				"earlystink": (this.earlystink !== undefined ? +this.earlystink: 0),
				"latestink": (this.latestink !== undefined ? +this.latestink: 0),
				"germination": (this.germination !== undefined ? +this.germination: 0),
				"nutborer": (this.nutborer !== undefined ? +this.nutborer: 0),
				"discolouration": (this.discolouration !== undefined ? +this.discolouration: 0),
				"notes": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Update Successful",response['response']['result']['reply']);
	        	this.resetForm();
	        } catch(e) {
	        	this.lS.toast("Update Unsuccessful","Sample was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() {
		this.postBody = {
			"name": "deletePalletSample",
			"param":{
				"idpallet": this.idpallet
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Delete Successful",response['response']['result']['reply']);
	        	this.resetForm();
	        } catch(e) {
	        	this.lS.toast("Delete Unsuccessful","Sample was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

 getUK() {
    this.uk = +(this.mould !== undefined ? this.mould : 0) + 
              +(this.immature !== undefined ? this.immature : 0) + 
              +(this.spot791 !== undefined ? this.spot791 : 0) + 
              +(this.earlystink !== undefined ? this.earlystink : 0) + 
              +(this.latestink !== undefined ? this.latestink : 0) + 
              +(this.germination !== undefined ? this.germination : 0) + 
              +(this.nutborer !== undefined ? this.nutborer : 0) + 
              +(this.discolouration !== undefined ? this.discolouration : 0);
    this.getTK();
  }
 
  getTK() {
    this.tk = +(this.sk !== undefined ? this.sk : 0) + 
              +(this.uk !== undefined ? this.uk : 0) + 
              +(this.dark !== undefined ? this.dark : 0);
  }
 
  getCount() {
    this.currentcount = (this.dnisunit=='weight' ? this.count : this.sumdnis() );
  }

  sumdnis() {
  	return Math.round((+(this.a18 || 0) + 
              +(this.a19 || 0) + 
              +(this.a20 || 0) + 
              +(this.a21 || 0) + 
              +(this.a22 || 0) + 
              +(this.a23 || 0) + 
              +(this.a24 || 0) + 
              +(this.a25 || 0) + 
              +(this.a26 || 0) + 
              +(this.a27 || 0) + 
              +(this.a28 || 0) + 
              +(this.a29 || 0) + 
              +(this.a30 || 0))*100)/100;
  }
 
  resetForm(loadLists = true) {
    window.scroll(0,0);
    if (loadLists) {
	    this.ddS.getDropdown("storageListWNIS");
	    this.ddS.getDropdown("productList");
	    this.ddS.getDropdown("palletList");
	  }
    this.pallet = null;
    this.manualpallet = null;
    this.palletname = null;
    this.palletline = null;
    this.idpallet = null;
    this.dnis = null;
    this.moisture = null;
		this.a18 = null;
		this.a19 = null;
		this.a20 = null;
		this.a22 = null;
		this.a22 = null;
		this.a23 = null;
		this.a24 = null;
		this.a25 = null;
		this.a26 = null;
		this.a27 = null;
		this.a28 = null;
		this.a29 = null;
		this.a30 = null;
    this.shell = null;
    this.sk = null;
    this.uk = null;
    this.tk = null;
    this.dark = null;
    this.mould = null;
    this.immature = null;
    this.spot791 = null;
    this.earlystink = null;
    this.latestink = null;
    this.germination = null;
    this.nutborer = null;
    this.discolouration = null;
    this.dnisunit = 'weight';
    this.note = "";
    this.currentcount = 0;
    this.sizeddnis = 0;
    this.resetCount();
  }

  resetCount() {
  	this.count = +this.pS.portalMod()['labnutcount'];
  }

  isActive(setting, option = null) {
    var ans = false;
    var iterate = false;
    if (option==null) {
      iterate = true;
    }
    if (!iterate) {
      iterate = this.lS.isAuthenticated(option);
    }
    if (iterate) {
      for (var i = 0; i <  portalM()['settings'].length; i++) {
        if ( portalM()['settings'][i]['setting']==setting) {
          ans = ( portalM()['settings'][i]['value']==1);
        }
      }
    }
    return ans;
  }

  convertdnis() {
  	if (this.dnisunit=='weight') {
  		this.convertToWeight();
  	} else {
  		this.convertToCount();
  	}
  }

  convertToWeight() {
  	if (this.dnisunit=='count') {
	  	this.count = this.currentcount;
			this.a18 = Math.round(this.a18 / this.currentcount * this.dnis * 100) / 100;
			this.a19 = Math.round(this.a19 / this.currentcount * this.dnis * 100) / 100;
			this.a20 = Math.round(this.a20 / this.currentcount * this.dnis * 100) / 100;
			this.a21 = Math.round(this.a21 / this.currentcount * this.dnis * 100) / 100;
			this.a22 = Math.round(this.a22 / this.currentcount * this.dnis * 100) / 100;
			this.a23 = Math.round(this.a23 / this.currentcount * this.dnis * 100) / 100;
			this.a24 = Math.round(this.a24 / this.currentcount * this.dnis * 100) / 100;
			this.a25 = Math.round(this.a25 / this.currentcount * this.dnis * 100) / 100;
			this.a26 = Math.round(this.a26 / this.currentcount * this.dnis * 100) / 100;
			this.a27 = Math.round(this.a27 / this.currentcount * this.dnis * 100) / 100;
			this.a28 = Math.round(this.a28 / this.currentcount * this.dnis * 100) / 100;
			this.a29 = Math.round(this.a29 / this.currentcount * this.dnis * 100) / 100;
			this.a30 = Math.round(this.a30 / this.currentcount * this.dnis * 100) / 100;
			this.getCount();
			this.tidyweights();
		}
  }

  convertToCount() {
  	if (this.dnisunit=='weight') {
	  	this.a18 = Math.round(this.a18 / this.dnis * this.count);
	  	this.a19 = Math.round(this.a19 / this.dnis * this.count);
	  	this.a20 = Math.round(this.a20 / this.dnis * this.count);
	  	this.a21 = Math.round(this.a21 / this.dnis * this.count);
	  	this.a22 = Math.round(this.a22 / this.dnis * this.count);
	  	this.a23 = Math.round(this.a23 / this.dnis * this.count);
	  	this.a24 = Math.round(this.a24 / this.dnis * this.count);
	  	this.a25 = Math.round(this.a25 / this.dnis * this.count);
	  	this.a26 = Math.round(this.a26 / this.dnis * this.count);
	  	this.a27 = Math.round(this.a27 / this.dnis * this.count);
	  	this.a28 = Math.round(this.a28 / this.dnis * this.count);
	  	this.a29 = Math.round(this.a29 / this.dnis * this.count);
	  	this.a30 = Math.round(this.a30 / this.dnis * this.count);
			this.getCount();
		}
  }

  tidyweights() {
		var remainder = Math.round((this.dnis - this.sumdnis())*100)/100;
		for (var i = 0; i < this.dniss.length; i++) {
			if (this[this.dniss[i].slice(0)]>0) {
				this[this.dniss[i].slice(0)] += remainder;
				break;
			}
		}
  }

  setManualPallet() {
		this.pallet = this.manualpallet;
		this.palletname = this.manualpallet;
		this.code = {'packdate': '', 'stylefull': '', 'batch': ''};
  }

	fixDate(field) {
		if (this.code[field]!==null && this.code[field]!=='') {
	  	this.code[field] = moment(this.code[field]).format('YYYY-MM-DD');
	  }
	}

	setProduct(code) {
		this.code['idproduct'] = +code.toString();
	}

	getProductCode(value, field = 'idproduct') {
		for (var i = 0; i < this.ddS.dropdown.productList.length; i++) {
			if (this.ddS.dropdown.productList[i][field]==value) {
				return this.ddS.dropdown.productList[i]['code'];
			}
	  }
	}


}
