import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { DropDownDataService } from '../logging/dropdown.service';
import { SiloSelectorService } from './silo-selector.service';
import { PortalService } from '../../auth/portal.service';
import { AddDeliveryService } from '../logging/add-delivery/add-delivery.service';

@Component({
  selector: 'app-silo-selector',
  templateUrl: './silo-selector.component.html',
  styleUrls: ['./silo-selector.component.scss']
})
export class SiloSelectorComponent implements OnInit {

  constructor(
  	private loginService: TeamLoginService,
  	public dDDS: DropDownDataService,
    public sSS: SiloSelectorService,
    public aDS: AddDeliveryService,
    public pS: PortalService
  	) { }

  ngOnInit() {
    window.scroll(0,0);
    this.dDDS.getDropdown("producerList");
    this.dDDS.getDropdown("cultivarProfileList");
    this.dDDS.getDropdown("deliveryList");
    this.aDS.resetForm();
    this.dDDS.getDropdown("contactList");
    this.dDDS.getDropdown("depotList");
    this.dDDS.getDropdown("depotDeliveryList");
    this.dDDS.getDropdown("storageListWNIS");
    this.dDDS.getDropdown("priceTermsList");
    this.dDDS.getDropdown("priceModelList");
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.pS.portalMod()[option] || this.pS.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  ngOnDestroy() {
    this.sSS.resetForm();
  } 

  canSave() {
    if (
        this.dDDS.getNewDeliveryNumber !== undefined &&
        this.aDS.producer !== undefined &&
        this.aDS.producer !== null &&
        this.aDS.scalewnis > 0 &&
        +this.aDS.cultivar > 0
      ) {
      if (this.aDS.checkTerms()==false) {return false;}
      return true;
    } else {
      return false;
    }
  }

  async clearFields(fields) {
    if (fields == 'all') {
      this.aDS.producer=null;
      this.aDS.iddeldepot=undefined;
      this.aDS.depotwnisd=undefined;
    } else if (fields == 'some') {
      // Check if depot delivery exists
      for (var i = 0; i < this.dDDS.dropdown.producerList.length; i++) {
        if (this.dDDS.dropdown.producerList[i]['idcompany'] == this.aDS.producer && this.dDDS.dropdown.producerList[i]['delatdepot']>0) {
          const res = await this.loginService.confirm('Log as a delivery from the depot?');
          if (res) {
            this.sSS.deliverysource = this.dDDS.dropdown.producerList[i]['iddepot'];
            this.aDS.iddeldepot = this.dDDS.dropdown.producerList[i]['delatdepot'];
            this.sSS.loadForEdit();
          }
          break;
        }
      }
    }
    this.aDS.iddelivery=null;
    this.aDS.storageOptions=null;
    this.aDS.cultivar=null;
    this.aDS.wnis=null;
    this.aDS.collected = false;
    this.aDS.stats = null;
    this.aDS.isStats = false;
    this.aDS.storages = [{"idstorage": "", "wnis": null}];
    this.aDS.cultivars = this.dDDS.dropdown.cultivarProfileList[this.aDS.producer];
  }

  getSilo() {
    this.aDS.storages = [{"idstorage": "", "wnis": null}];
    this.aDS.getSilo();
  }

  isConcern() {
    if ((this.aDS.depotwnisd>0 && Math.abs((this.aDS.wnis - this.aDS.depotwnisd)/this.aDS.wnis)>0.02 && Math.abs(this.aDS.wnis - this.aDS.depotwnisd) > 5) || this.aDS.wnis==0 || this.aDS.wnis==undefined) {
      return true;
    } else {
      return false;
    }
  }
  
}