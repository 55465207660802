import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { EmailSettingsService } from './email.service';

@Component({
  selector: 'app-emailsettings',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailSettingsComponent implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public dropDownDataService: DropDownDataService,
    public settingsService: EmailSettingsService,
    public portalService: PortalService
    ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.settingsService.loadEmail();
  }

  ngOnDestroy() {
    this.settingsService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        this.settingsService.settings['name'] != '' &&
        this.settingsService.settings['email'] != '' &&
        this.settingsService.settings['replyname'] != '' &&
        this.settingsService.settings['replyto'] != '' &&
        this.settingsService.settings['outgoing'] != '' &&
        this.settingsService.settings['outport'] != '' && 
        this.settingsService.settings['security'] != '' && 
        this.settingsService.settings['smtpauth'] != ''
      ) {
      return true;
    } else {
      return false;
    }
  }

  onCalculate() {
    if (this.canSave()) {
      this.settingsService.saveEmail();
      window.scroll(0,0);
      this.settingsService.resetForm();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete all email settings?");
    if (res) {
      this.settingsService.deleteEmail();
      this.settingsService.resetForm();
    }
  }


}