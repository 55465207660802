<div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
  <svg id="contentToConvert"
   width="100%"
   height="100%"
   viewBox="0 0 1144 1701"
   xmlns="http://www.w3.org/2000/svg" 
   xmlns:xlink="http://www.w3.org/1999/xlink"  
   version="1.2" 
   baseProfile="tiny">
    <title>{{ this.pS.portalMod()['portal'] }} Remittance</title>
    <desc>Generated on the {{ this.pS.portalMod()['portal'] }} Portal</desc>
    <g fill="none" stroke="black" stroke-width="1" fill-rule="evenodd" stroke-linecap="square" stroke-linejoin="bevel" >
    </g>

    <g [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-27)' : 'translate(0,0)')">

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="74.5" y="284" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >VAT Number: {{ this.pS.portalMod()['vat'] }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="248.5" y="284" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >      </text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="272.5" y="284" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >Registration Number: {{ this.pS.portalMod()['regno'] }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="300" y="311" font-family="Arial" font-size="15" font-weight="400" font-style="normal" text-anchor="middle"
       >{{ this.pS.portalMod()['fulladdress'] }}</text>
      </g>

      <g *ngIf="this.pS.portalMod()['invoicelogoformat']=='wide'" fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="41.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >T: {{ this.pS.portalMod()['tel'] }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="193.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >M: {{ this.pS.portalMod()['mobile'] }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="338.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >E: {{ this.pS.portalMod()['email'] }}</text>
      </g>

      <g *ngIf="this.pS.portalMod()['invoicelogoformat']=='square'" fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="52.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >T: {{ this.pS.portalMod()['tel'] }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="198" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >M: {{ this.pS.portalMod()['mobile'] }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="350" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
       >E: {{ this.pS.portalMod()['invoiceemail'] }}</text>
      </g>

    </g>

    <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
    font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
    >
    <!--<text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="719" y="108" font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
     >TAX INVOICE</text>-->
    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="70" font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
     >REMITTANCE</text>
     <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="667" y="118" font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
     >NOTIFICATION</text>
    </g>

    <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
    font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
    >
    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="176" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
     >Delivery Number</text>
    </g>

    <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
    font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
    >
    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="176" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
     >{{ this.rS.loadedEntry?.iddelivery | delivery }}{{ ( this.rS.fees?.selectout ? ': Select-outs' : '') }}</text>
    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="176" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
     > </text>
    </g>

    <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
    font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
    >
    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="203" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
     >Invoice Date</text>
    </g>

    <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
    font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
    >
    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="203" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
     >{{ this.rS.loadedEntry?.paydate | date:'d MMMM yyyy, EEE' }}</text>
    </g>

    <g [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-27)' : 'translate(0,0)')">

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="230" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Sample Date</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ this.rS.loadedEntry?.sampledate | date:'d MMMM yyyy, EEE' }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       > </text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="257" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Delivery Date</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="257" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ this.rS.loadedEntry?.ddate | date:'d MMMM yyyy, EEE' }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="257" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       > </text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="284" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >WNIS Delivered (kg)</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="284" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ +this.rS.loadedEntry?.wnis + +this.rS.loadedEntry?.smalls | number:'1.2-2' }} kg</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="284" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       > </text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="311" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Cultivar</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="311" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ this.rS.loadedEntry?.cultivar }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="311" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       > </text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="338" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Paid Collection?</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="338" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ this.rS.loadedEntry?.collected | yesno }}</text>
      </g>
    
    </g>

    <g [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-37)' : 'translate(0,0)')">

      <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
      font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
      >
      <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,372 L1132,372, L1132,373, L19,373"/>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
      font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
      >
      <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,575 L1132,575 L1132,576 L19,576 L19,576"/>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
      font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
      >
      <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,972 L1132,972 L1132,973 L19,973 L19,973"/>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="403" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
       >PAYEE DETAILS</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Company Name</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.companyname : this.rS.loadedEntry?.pcompanyname ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >VAT Number</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.vat : this.rS.loadedEntry?.pvat ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Reference</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ this.rS.loadedEntry?.reference }}</text>
      </g>


      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Reg Number</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.regnumber : this.rS.loadedEntry?.pregnumber ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Address</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" id="address"
       >{{ this.rS.loadedEntry?.fulladdress }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Bank Name</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.bankname : this.rS.loadedEntry?.pbankname ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Email</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.email : this.rS.loadedEntry?.pemail ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Bank Account</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.bankaccount : this.rS.loadedEntry?.pbankaccount ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Tel</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.tel : this.rS.loadedEntry?.ptel ) }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Bank Sort Code</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.useChild ? this.rS.loadedEntry?.banksortcode : this.rS.loadedEntry?.pbanksortcode ) }}</text>
      </g>

      <g transform="translate(0,-10)">

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="616" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
         >DELIVERY &amp; SAMPLE REPORT</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >WNIS Delivered (kg) excl. Smalls</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.wnis | number:'1.2-2' }} kg</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Total DNIS Delivered @ {{ this.rS.loadedEntry?.dryfloorlevel | percent:'1.1' }} k.m. (kg)</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.dnis + this.rS.fees?.smallsdnis | number:'1.2-2' }} kg</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Smalls Delivered (kg)</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.smalls | number:'1.2-2' }} kg</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >DNIS excl. Smalls (kg)</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ 1 | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="706" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Premium Kernel delivered</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="706" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ this.rS.loadedEntry?.skr | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="706" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.skr * +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="735" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Dark Kernel delivered</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="735" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="735" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis * +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="746" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Wet Sample Weight</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="746" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.swnis) | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Wet Sample - Kernel Moisture (k.m.)</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.wetkernel | percent:'1.2' }}</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <!--
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Wet Sample - Total Nut Mositure</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.moisture | percent:'1.2' }}</text>
        -->
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Mould / Old</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.mould / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.mould | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Moisture removed to {{ this.rS.loadedEntry?.dryfloorlevel | percent:'1.1' }} k.m.</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.moisturerem ) | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.swnis - +this.rS.loadedEntry?.sdnis) | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Immature</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.immature / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.immature | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Dry Sample Weight @ {{ this.rS.loadedEntry?.dryfloorlevel | percent:'1.1' }} k.m.</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >100.00%</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.sdnis) | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >791 Spot</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.spot791 / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.spot791 | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Shell</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.shell / +this.rS.loadedEntry?.sdnis) | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.shell) | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Early Stinkbug</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.earlystink / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.earlystink | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Total Kernel</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ ( +this.rS.loadedEntry?.sound + +this.rS.loadedEntry?.unsound + +this.rS.loadedEntry?.dark ) / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ ( +this.rS.loadedEntry?.sound + +this.rS.loadedEntry?.unsound + +this.rS.loadedEntry?.dark ) | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Late Stinkbug</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.latestink / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.latestink | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Premium Kernel</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.sound / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.sound | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Germination</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.germination / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.germination | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Unsound Kernel</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.unsound / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.unsound | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Nut Borer</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.nutborer / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.nutborer | number:'1.2-2' }} g</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Dark Kernel</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.dark | number:'1.2-2' }} g</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Discolouration</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.discolouration / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.loadedEntry?.discolouration | number:'1.2-2' }} g</text>
        </g>
      
      </g>

    </g>

    <g [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-77)' : 'translate(0,0)')">

      <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
      font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
      >
      <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1054 L1132,1054 L1132,1080 L19,1080 L19,1054"/>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1043" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
       >INCOME</text>
      </g>

      <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
      >
      <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Product Purchased</text>
      <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Amount delivered (kg)</text>
      <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >DNIS Price (kg)</text>
      <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Kernel (kg)</text>
      <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
       >Total (ex. VAT)</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1098" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Premium Kernel</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1098" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ +this.rS.loadedEntry?.skr * +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1098" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (+this.rS.fees?.gdnisprice + +this.rS.fees?.cultivarbonus + +this.rS.fees?.baseoverride) * +this.rS.fees?.cultivarbpercent | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}
       <!--
       {{ (+this.rS.fees?.cultivarbonus>0 ? ' (Cultivar: R' + (+this.rS.fees?.cultivarbonus | number:'1.2-2') + ')' : '') }}
        -->
      </text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1098" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (+this.rS.fees?.gdnisprice + +this.rS.fees?.cultivarbonus + +this.rS.fees?.baseoverride) * +this.rS.fees?.cultivarbpercent / ( +this.rS.loadedEntry?.skr ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1098" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ +this.rS.fees?.grossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1124" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >Dark Kernel</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1124" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis * +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1124" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ +this.rS.fees?.darkprice / +this.rS.fees?.dnis | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1124" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (+this.rS.loadedEntry?.dark > 0 ? +this.rS.fees?.darkprice / ( +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis * +this.rS.fees?.dnis ) : 0 ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1124" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ +this.rS.fees?.darkprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      </g>

      <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
      font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
      >
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1151" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ ( this.rS.loadedEntry?.status=='selectout' ? 'Select-Out DNIS' : 'Smalls DNIS' ) }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1151" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ ( this.rS.loadedEntry?.status=='selectout' ? +this.rS.fees?.dnis : 0 ) + +this.rS.fees?.smallsdnis | number:'1.2-2' }} kg</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1151" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ ( this.rS.loadedEntry?.status=='selectout' ? +this.rS.fees?.selectoutrate : +this.rS.fees?.smallsrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} / kg {{( this.rS.loadedEntry?.status=='selectout' ? 'DNIS' : 'Smalls' )}}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1151" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ (this.rS.loadedEntry?.status=='smalls' || this.rS.loadedEntry?.status=='selectout' ? '' : +this.rS.fees?.smallsrate / ( +this.rS.loadedEntry?.skr )) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1151" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
       >{{ ( this.rS.loadedEntry?.status=='selectout' ? +this.rS.fees?.selectoutprice : +this.rS.fees?.smallsprice ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
      </g>

      <ng-container *ngIf="this.rS.fees?.modelnetsamac">

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >SAMAC Levy{{( this.rS.fees?.modelnetsamac ? " (included)" : "" )}}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.dnis + this.rS.fees?.smallsdnis | number:'1.2-2' }} kg</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.samacrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS + VAT</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.samacexvat / (+this.rS.fees?.dnis + this.rS.fees?.smallsdnis) / this.rS.loadedEntry?.skr | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.samacexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
        </g>

      </ng-container>

      <g [attr.transform]="(this.rS.fees?.modelnetsamac ? 'translate(0,27)' : 'translate(0,0)' )">

        <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
        font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
        >
        <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1159 L1132,1159 L1132,1160 L19,1160 L19,1159"/>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >Gross Price / kg DNIS</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ (this.rS.loadedEntry?.status=='smalls' ? '' : +this.rS.fees?.totalgrossprice / +this.rS.fees?.dnis + (this.rS.fees?.modelnetsamac ? +this.rS.fees?.samacrate : 0)) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >SUBTOTAL</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1178" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >VAT</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ +this.rS.fees?.totalgrossprice * +this.rS.fees?.vatable * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
        </g>            

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1232" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
         >TOTAL</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1232" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
         >{{ +this.rS.fees?.totalgrossprice * ( 1 + +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
        </g>

        <g [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-17)' : 'translate(0,0)')">

          <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
          font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
          >
          <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1265 L1132,1265 L1132,1289 L19,1289 L19,1265"/>
          </g>

          <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
          font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" [attr.transform]="(this.rS.fees?.mrlclass=='i' ? 'translate(0,27)' : 'translate(0,0)')"
          >
          <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1425 L1132,1425 L1132,1426 L19,1426 L19,1425"/>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1255" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
           >COSTS</text>
          </g>

          <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Fees</text>
          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Quantity</text>
          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Rate</text>
          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >VAT</text>
          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Total (ex. VAT)</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1309" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >Marketing</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1309" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.totalgrossprice | number:'1.2-2' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1309" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.marketingrate | percent:'1.2' }} of Gross Income</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1309" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.marketingfee * ( +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1309" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.marketingfee | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1336" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >Processing</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1336" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1336" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ ( +this.rS.fees?.processfee > 0 ? +this.rS.fees?.processfee / +this.rS.fees?.dnis : 0 ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1336" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.processfee * ( +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1336" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.processfee | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1363" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >Drying</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1363" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.moistrem | number:'1.2-2' }} kg  ({{ +this.rS.fees?.moistrem / +this.rS.loadedEntry?.twnis | percent:'1.2-2' }} of WNIS)</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1363" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.dryrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg moisture removed</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1363" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.dryfee * ( +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1363" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.dryfee | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1390" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >Transport{{ (this.rS.fees?.transportdepot ? ' (' + this.rS.fees?.transportdepot + ')' : '') }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1390" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >
          <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeewnis>0">{{ +this.rS.loadedEntry?.wnis + +this.rS.loadedEntry?.smalls | number:'1.2-2' }} kg
          </ng-container>
          <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeekm>0 && +this.rS.fees?.transportfeewnis>0">{{ ", " }}
          </ng-container>
          <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeekm>0">{{ +this.rS.fees?.transportkm | number:'1.0-0' }} km
          </ng-container>
          <ng-container *ngIf="this.rS.fees?.transportdepot">{{ +this.rS.fees?.dnis + this.rS.fees?.smallsdnis | number:'1.2-2' }} kg
          </ng-container>
          </text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1390" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >
            <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeebase>0">{{ +this.rS.fees?.transportfeebase | currency:this.rS.fees?.transportkmcurr:'symbol-narrow' }}
            </ng-container>
            <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeekm>0 && +this.rS.fees?.transportfeebase>0">{{ " + " }}
            </ng-container>
            <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeekm>0">{{ +this.rS.fees?.transportfeekm | currency:this.rS.fees?.transportkmcurr:'symbol-narrow' }}/km
            </ng-container>
            <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeewnis>0 && +this.rS.fees?.transportfeebase>0">{{ " + " }}
            </ng-container>
            <ng-container *ngIf="!this.rS.fees?.transportdepot && +this.rS.fees?.transportfeewnis>0">{{ +this.rS.fees?.transportfeewnis | currency:this.rS.fees?.transportcurr:'symbol-narrow' }}/kg WNIS
            </ng-container>
            <ng-container *ngIf="this.rS.fees?.transportdepot">{{ +this.rS.fees?.transportdepotdnis | currency:this.rS.fees?.transportcurr:'symbol-narrow' }}/kg DNIS
            </ng-container>
          </text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1390" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.transportfee * ( +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1390" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.transportfee | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1417" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >SAMAC Levy{{( this.rS.fees?.modelnetsamac ? " (included)" : "" )}}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1417" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.dnis + this.rS.fees?.smallsdnis | number:'1.2-2' }} kg</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1417" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.samacrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS + VAT</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1417" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.samacexvat * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1417" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.fees?.samacexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          </g>

          <ng-container *ngIf="this.rS.fees?.mrlclass=='i'">

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >MRL Test</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Seasonal (once per farm)</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ +this.rS.fees?.mrlexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} + VAT</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ +this.rS.fees?.mrlexvat * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ +this.rS.fees?.mrlexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
            </g>

          </ng-container>

          <g [attr.transform]="(this.rS.fees?.mrlclass=='i' ? 'translate(0,27)' : 'translate(0,0)')">

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Gross Costs / kg DNIS</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.loadedEntry?.status=='smalls' ? '' : +this.rS.fees?.netfees / +this.rS.fees?.dnis) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >SUBTOTAL</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1444" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ +this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1471" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >VAT</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1471" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlincvat - +this.rS.fees?.mrlfee : 0) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1498" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
             >TOTAL</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1498" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
             >{{ +this.rS.fees?.netfees + +this.rS.fees?.vatable * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlincvat : 0) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
            </g>

            <ng-container *ngIf="+this.rS.fees?.financingrate > 0">

              <g transform="translate(0,250)">
                <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
                font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
                >
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1265 L1132,1265 L1132,1289 L19,1289 L19,1265"/>
                </g>

                <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
                font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" [attr.transform]="(this.rS.fees?.mrlclass=='i' ? 'translate(0,27)' : 'translate(0,0)')"
                >
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1321 L1132,1321 L1132,1322 L19,1322 L19,1321"/>
                </g>

                <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                >
                <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1255" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                 >FINANCING{{ (+this.rS.fees?.financingrate > 0 ? ' - Flat rate of ' : '' ) }}{{ (+this.rS.fees?.financingrate > 0 ? +this.rS.fees?.financingrate : '' ) | percent:'1.1' }}</text>
                </g>

                <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                >
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Income</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="221" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Costs</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Net</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Finance Cost</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >VAT</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Total</text>
                </g>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="221" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{  +this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0)) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (+this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0))) * +this.rS.fees?.financingrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (+this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0))) * +this.rS.fees?.financingrate / (1 + +this.rS.fees?.vatrate) * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (+this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0))) * +this.rS.fees?.financingrate * (1 + +this.rS.fees?.vatrate) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
              </g>

            </ng-container>

            <g [attr.transform]="(+this.rS.fees?.financingrate > 0 ? 'translate(0, 98)' : 'translate(0,0)')">
              <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-17)' : 'translate(0,0)')">

                <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
                font-family="Helvetica" font-size="12" font-weight="400" font-style="normal"
                >
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1531 L1132,1531 L1132,1555 L19,1555 L19,1531"/>
                </g>

                <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                >
                <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1521" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                 >PAYMENTS</text>
                </g>

                <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                >
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Payment Date</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Gross</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Fees</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Gross</text>
                </g>

                <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                >
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Fees</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >VAT</text>
                <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >Total (inc. VAT)</text>
                </g>

                <ng-container *ngIf="this.rS.fees?.mrlclass=='p'">

                  <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                  font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                  >
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ this.rS.payments.m?.paydate | date:'d MMMM yyyy' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >MRL Fee</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-{{ +this.rS.fees?.mrlexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-{{ +this.rS.fees?.mrlincvat - +this.rS.fees?.mrlexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-{{ +this.rS.fees?.mrlincvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  </g>

                </ng-container>

                <g [attr.transform]="(this.rS.fees?.mrlclass=='p' ? 'translate(0,27)' : 'translate(0,0)')">
                  
                  <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                  font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                  >
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ this.rS.payments.one?.paydate | date:'d MMMM yyyy' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.one?.paypercent | percent:'1.0' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ ( this.rS.fees?.upfrontfees ? 1 : +this.rS.payments.one?.paypercent ) | percent:'1.0' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.one?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 1 : +this.rS.payments.one?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ ( this.rS.fees?.mrlclass=='p' ? 0 : +this.rS.fees?.vatable * +this.rS.payments.one?.vatdue - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.one?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  </g>

                  <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                  font-family="Arial" font-size="16" font-weight="400" font-style="normal"  *ngIf="+this.rS.payments.two?.paypercent > 0"
                  >
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ this.rS.payments.two?.paydate | date:'d MMMM yyyy' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.two?.paypercent | percent:'1.0' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.two?.paypercent ) | percent:'1.0' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.two?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.two?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.two?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1602" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.two?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  </g>

                  <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                  font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.three?.paypercent > 0"
                  >
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ this.rS.payments.three?.paydate | date:'d MMMM yyyy' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.three?.paypercent | percent:'1.0' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.three?.paypercent ) | percent:'1.0' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.three?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.three?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.three?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1629" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.payments.three?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  </g>

                  <ng-container *ngIf="this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0">

                     <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                    font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.four?.paypercent > 0"
                    >
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.payments.four?.paydate | date:'d MMMM yyyy' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.four?.paypercent | percent:'1.0' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.four?.paypercent ) | percent:'1.0' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.four?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.four?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.four?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1656" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.four?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    </g>


                     <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                    font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.five?.paypercent > 0"
                    >
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.payments.five?.paydate | date:'d MMMM yyyy' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.five?.paypercent | percent:'1.0' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.five?.paypercent ) | percent:'1.0' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.five?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.five?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.five?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1683" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.five?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    </g>


                     <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                    font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.six?.paypercent > 0"
                    >
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.payments.six?.paydate | date:'d MMMM yyyy' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.six?.paypercent | percent:'1.0' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.six?.paypercent ) | percent:'1.0' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.six?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.six?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.six?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1710" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.payments.six?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    </g>

                  </ng-container>

                </g>

              </g>
            </g>

          </g>

        </g>
      </g>

    </g> <!-- Payments > 3 -->


    <g [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-10)' : 'translate(0,0)')">
            
      <g [attr.transform]="(+this.rS.fees?.financingrate > 0 ? 'translate(0,27)' : 'translate(0,0)')">

        <g [attr.transform]="(this.rS.fees?.mrlclass=='i' || this.rS.fees?.mrlclass=='p' ? 'translate(0,27)' : 'translate(0,0)')">

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="784" y="1656" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >NET</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1656" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >{{ ( this.rS.fees?.mrlclass=='p' ? 0 : +this.rS.fees?.vatable * +this.rS.fees?.vatpayable - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1656" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >{{ (( +this.rS.payments.one?.amountdue>0 ? +this.rS.payments.one?.amountdue : 0 ) + ( +this.rS.payments.two?.amountdue>0 ? +this.rS.payments.two?.amountdue : 0 ) + ( +this.rS.payments.three?.amountdue>0 ? +this.rS.payments.three?.amountdue : 0 ) + ( +this.rS.payments.four?.amountdue>0 ? +this.rS.payments.four?.amountdue : 0 ) + ( +this.rS.payments.five?.amountdue>0 ? +this.rS.payments.five?.amountdue : 0 ) + ( +this.rS.payments.six?.amountdue>0 ? +this.rS.payments.six?.amountdue : 0 )
           - (this.rS.fees?.mrlclass=='p' ? this.rS.fees?.mrlincvat : 0)) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
          </g>

          <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
          font-family="Helvetica" font-size="12" font-weight="400" font-style="normal"
          >
          <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1638 L1132,1638 L1132,1639 L19,1639 L19,1638"/>
          </g>

        </g>

      </g>
    
    </g>

    <text fill="#000000" fill-opacity="0.5" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1683" font-family="Arial" font-size="12" font-weight="300" font-style="normal">Date Generated: {{ this.d | date:'d MMMM yyyy' }}</text>

    <g  [attr.transform]="(this.rS.payments?.count>3 || +this.rS.fees?.financingrate > 0 ? 'translate(0,-27)' : 'translate(0,0)')">

      <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='wide'" [attr.xlink:href]="this.pS.invoicelogo" x="70px" y="10" height="223px" width="455px"/>
      <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='square'" [attr.xlink:href]="this.pS.invoicelogo" x="160px" y="40" height="223px" width="255px"/>

    </g>

  </svg>
</div> 