import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../auth/login/teamlogin.service';
import { SplitTermsService } from '../../services/splitterms.service';
import { Contacts, Location, PriceModel, Producers, Sample, PriceTerm, Tag } from '../../interfaces/dropdown.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class DropDownDataService{
	private postBody: {};
	httpRes: any;
	dataGroup: string;
	public getNewDeliveryNumber: number;
	public getNewDepotDeliveryNumber: number;
	public getNewSampleNumber: Sample = {iddelivery: "0"};
	public paymentNumber: Sample = {iddelivery: "-1"};
	public thisCompanyContacts: Contacts [] = [];
	public location: Location [] = [];
	public depot: Location [] = [];
	public pricemodel: PriceModel;
	public dropdown: any[any] = [];
	public columns: any[any] = [];
	public thisCompanySeason: Producers;
	public paypercent: string;
	public payday: string;
	public paypercentU: string;
	public paydayU: string;
	public epaypercent: string;
	public epayday: string;
	public epaypercentU: string;
	public epaydayU: string;
  public payterms: string = "default";
  public tagserials: string;
  public tagserialsU: string;
	public tags: Tag[] = [{"serial": ""}];
	public items: PriceTerm[] = [
  	{"estimate": 0, "type": "", "percent": "", "timing": "", "mdate": "1", "weekday": "0", "datetype": "delivery"}
	];

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService) {
		this.dropdown.paymentList = [];
	}

	async waitDD(data: string) {
    if (this.dropdown[data]==undefined) {
      var result = await this.loginService.loadData(data);
      this.storeResult(result, data);
    }
	}

	getDropdown(dataGroup: string, location: number = 1, date: string = '', p = null) {
		this.postBody = {
			"name": dataGroup,
			"param": {
				"location": location,
				"season": +this.loginService.season,
				"date": date
			}
		};
		this.postBody = (p==null ? this.postBody : p);
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
        	this.storeResult(response['response']['result']['reply'], dataGroup);
        },
        (error) => console.log(error)
      );
	}

	storeResult(response, dataGroup) {
		switch (dataGroup) {
			case "consigneeList":
				this.dropdown[dataGroup.slice(0)] = response;
				this.dropdown['notifyList'] = response;
				break;
			case "depotList":
				this.depot = response[0];
				this.dropdown[dataGroup.slice(0)] = response;
				break;
			case "newDeliveryNumber":
				this.getNewDeliveryNumber = (response[0] !== undefined ? +response[0].iddelivery + 1 : 1 );
				break;
			case "newDepotDeliveryNumber":
				this.getNewDepotDeliveryNumber = (response[0] !== undefined ? +response[0].iddeldepot + 1 : 1 );
				break;
			case "locationList":
				this.location = response[0];
				this.dropdown[dataGroup.slice(0)] = response;
				break;
			case "priceModelList":
				this.pricemodel = (response['default']['model']==null ? response['models'][0] : response['default']);
				this.dropdown[dataGroup.slice(0)] = response['models'];
				this.setPModel();
			case "priceModelListAll":
				this.dropdown[dataGroup.slice(0)] = response['models'];
				break;
			case "sampleList":
				this.getNewSampleNumber = (response['new']['loadfirst'] !== undefined ? response['new']['loadfirst'] : {'iddelivery' : null} );
				this.dropdown[dataGroup.slice(0)] = response;
				break;
			case "tableColumns":
				this.columns = response;
				break;
			default:
				this.dropdown[dataGroup.slice(0)] = response;
		}
	}

	setCompanyContacts(producer, list = 'producerList') {
		for (var i = 0; i < Object.keys(this.dropdown[list]).length; i++) {
			if (this.dropdown[list][i].idcompany == producer) {
				var companyContact = this.dropdown[list][i].contact;
			}
		}
		this.thisCompanyContacts = [];
		for (var i = 0; i < Object.keys(this.dropdown.contactList).length; i++) {
			if (this.dropdown.contactList[i].idcompany == producer || this.dropdown.contactList[i].idcontact == companyContact) {
				this.thisCompanyContacts.push(this.dropdown.contactList[i]);
			}
		}
	}

	getLocation(id) {
		for (var i = 0; i < Object.keys(this.dropdown.locationList).length; i++) {
			if (this.dropdown.location[i].idlocation == id) {
				return this.dropdown.location[i];
			}
		}
	}

	setPriceModelSeason(producer) {
		// CHECKS IF 1st delivery of season
		for (var i = 0; i < Object.keys(this.dropdown.producerList).length; i++) {
			if (this.dropdown.producerList[i].idcompany == producer) {
				this.thisCompanySeason = this.dropdown.producerList[i].season;
			}
		}
	}

	getContactIndex($idcontact, type = 'full') {
		for (var i = 0; i < Object.keys((type=='full' ? this.dropdown.contactList : this.dropdown.mainContactList)).length; i++) {
			if ((type=='full' ? this.dropdown.contactList : this.dropdown.mainContactList)[i].idcontact == $idcontact) {
				return i;
			}
		}
	}

	addItem(list = 'items') {
		var fields = {
			"estimate": 0,
			"type": "",
			"datetype": "delivery",
			"percent": "",
		 	"timing": "",
		 	"mdate": "1",
		 	"timingDate": "",
		 	"weekday": "0",
		 	"order": ""
		 };
		this[list.slice(0)] = this.splitTermsService.addItem(this[list.slice(0)],fields);
	}

	removeItem(index: number, list = 'items') {
		this[list.slice(0)] = this.splitTermsService.removeItem(this[list.slice(0)], index);
	}

	addTag() {
		this.tags.push({"serial": ""});
	}

	removeTag(index: number) {
		this.tags.splice(index, 1);
	}

	splitTerms(list = 'items') {
		this[list.slice(0)] = [];
		var list1 = this.splitTermsService.splitTerms(this.epaypercentU,this.epaydayU,true);
		var list2 = this.splitTermsService.splitTerms(this.paypercentU,this.paydayU,false);
		for (var i in list1) {
			this[list.slice(0)].push(list1[i]);
		}
		for (var i in list2) {
			this[list.slice(0)].push(list2[i]);
		}
	}

  combineTerms(list = 'items') {
  	var terms = this.splitTermsService.combineTerms(this[list.slice(0)]);
  	this.paypercent = terms['paypercent'];
  	this.payday = terms['payday'];
  	this.epaypercent = terms['epaypercent'];
  	this.epayday = terms['epayday'];
  }

  checkTerms(companytype = 'producer', modelset = true, list = 'items') {
  	if (companytype=='producer') {
  		return this.splitTermsService.checkTerms(this[list.slice(0)], modelset);
		} else {
			return true;
		}
  }

  splitTags() {
  	if (this.tagserialsU!==null) {
	  	var serials = this.tagserialsU.split(";");
	  	for (var i = 0; i < serials.length; i++) {
	  		if (serials[i].length == 0) {
	  			serials.splice(i, 1);
	  		}
	  	}
	  	this.tags = [];
	  	for (var i = 0; i < serials.length; i++) {
	  		this.tags.push({"serial": serials[i] });
	  	}
	  }
  }

  combineTags() {
  	this.tagserials = '';
  	for (var i = 0; i < this.tags.length; i++) {
  		this.tagserials = this.tagserials + this.tags[i]['serial'] + ";";
  	}
  }

	setPModel(modelno:any = 0, editmode: boolean = false) {
	  if (!editmode) {
	  	modelno = +modelno;
	    if (modelno == 0 || modelno == null) {
	    	modelno = this.pricemodel.modelno;
	      this.paypercentU = (this.dropdown.priceTermsList !== undefined ? this.pricemodel.paypercent : "");
	      this.paydayU = (this.dropdown.priceTermsList !== undefined ? this.pricemodel.payday : ""); 
	      this.epaypercentU = (this.dropdown.priceTermsList !== undefined ? this.pricemodel.epaypercent : "");
	      this.epaydayU = (this.dropdown.priceTermsList !== undefined ? this.pricemodel.epayday : "");  
	    } else {
	      for (var i = 0; i < Object.keys(this.dropdown.priceTermsList).length; i++) {
	        if (+this.dropdown.priceTermsList[i].modelno == modelno)  {
	          this.paypercentU = this.dropdown.priceTermsList[i].paypercent;
	          this.paydayU = this.dropdown.priceTermsList[i].payday;
	          this.epaypercentU = this.dropdown.priceTermsList[i].epaypercent;
	          this.epaydayU = this.dropdown.priceTermsList[i].epayday;
	        }
	      }
	    }
	  }
    this.splitTerms();
  }

	transDay(paydayRaw) {
		return this.splitTermsService.transDay(paydayRaw);
	}

	getFarmId(producer) {
		var id = null;
		if (this.dropdown['producerList']!=undefined) {
			for (var i = 0; i < this.dropdown['producerList'].length; i++) {
				if (this.dropdown['producerList'][i]['idcompany']==producer) {
					id = (this.dropdown['producerList'][i]['farmid']==null ? null : this.dropdown['producerList'][i]['farmid']);
				}
			}
		}
		return id;
	}


}