import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { Sample } from '../../../interfaces/samples.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddPaymentService{
	private postBody: {};
	httpRes: any;
  d: Date = new Date();
	public editmode: boolean = false;
	public loadedEntry: Sample;
	public idpayment: string;
	public iddelivery: string;
	public iddeliverytemp: string;
	public assigneddelno: string;
	public idproducer: string = "0";
	public idproducertemp: string = "0";
	public instalment: any = "";
	public producer: string;
	public paydate: string;
	public amountdue: number;
	public amountdueUSD: number;
	public amountpaidUSD: number;
	public amountdueZAR: number;
	public amountpaidZAR: number;
	public amountpaid: number;
	public paiddate: string = '';
	public eft: string;
	public paycurrency: string = "ZAR";
	public invcurrency: string = "ZAR";
	public fxrate: number;
	public fxdate: string = this.paiddate;
	public fxsource: string = "fixed";
	public note: string;
	public fxcorrection: number = 1;


	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		private dropDownDataService: DropDownDataService) {}

	editPayment(iddelivery, instalment, idcompany) {
		this.editmode = true;
		for (var i = 0; i < this.dropDownDataService.dropdown.paymentList.length; i++) {
			if (this.dropDownDataService.dropdown.paymentList[i]['iddelivery'] == iddelivery + '') {
				this.iddelivery = i + '';
			}
		}
		this.iddelivery = (this.iddelivery == undefined ? 'tbc' : this.iddelivery);
		this.instalment = instalment;
		this.idproducertemp = idcompany
		this.loadForEdit();
	}

	loadForEdit() {
		this.postBody = {
			"name": ( this.editmode ? "loadPayment" : "loadNewPayment" ),
			"param":{
				"iddelivery": (this.iddelivery=='tbc' ? 0 :this.dropDownDataService.dropdown.paymentList[this.iddelivery].iddelivery),
				"season": this.loginService.season,
				"idproducer": +this.idproducertemp,
				"instalment": this.instalment
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = (this.editmode ? this.loadEntry(response) : this.loadEntryNew(response) );
        },
        (error) => console.log(error)
      );
	}
	
	loadEntryNew(rawData) {
		this.loadedEntry = rawData.response.result.reply[0];
    this.iddeliverytemp = this.iddelivery;
    this.producer = this.loadedEntry['companyname'];
    this.idproducertemp = this.loadedEntry['idcompany'];
		this.paycurrency = this.loadedEntry['paycurrency'];
		this.invcurrency = (this.loadedEntry['invcurrency']==null ? this.paycurrency : this.loadedEntry['invcurrency']);
		this.fxrate = this.loadedEntry['fxrate'].substr(0,this.loadedEntry['fxrate'].indexOf(".")+5);
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply[0];
		this.idpayment = this.loadedEntry['idpayment'].toString();
		this.idproducer = this.loadedEntry['idproducer'].toString();
		this.idproducertemp = this.loadedEntry['idproducer'].toString();
    this.iddeliverytemp = this.iddelivery.toString();
    this.assigneddelno = (this.instalment=='a' ? this.iddelivery : null);
    this.producer = this.loadedEntry['companyname'];
		this.paydate = (this.loadedEntry['paydate'] == null ? null : this.loadedEntry['paydate'].substr(0,this.loadedEntry['paydate'].indexOf(" ")));
		this.amountdue = Math.round(this.loadedEntry['amountdue']*100)/100;
		this.amountpaidUSD = Math.round(this.loadedEntry['amountpaidUSD']*100)/100;
		this.paiddate = (this.loadedEntry['paiddate'] == null ? null : this.loadedEntry['paiddate'].substr(0,this.loadedEntry['paiddate'].indexOf(" ")));
		this.amountpaid = (+this.loadedEntry['amountpaid'] == 0 ? 0 : Math.round(this.loadedEntry['amountpaid']*100)/100 * (this.loadedEntry['paycurrency']=='ZAR' ? 1 : 1/this.loadedEntry['fxrate']));
		this.eft = this.loadedEntry['eft'];
		this.paycurrency = this.loadedEntry['paycurrency'];
		this.invcurrency = this.loadedEntry['invcurrency'];
		this.fxrate = Math.round(this.loadedEntry['fxrate']*10000)/10000;
		this.fxdate = (this.loadedEntry['fxdate'] == null ? null : this.loadedEntry['fxdate'].substr(0,this.loadedEntry['fxdate'].indexOf(" ")));
		this.fxsource = this.loadedEntry['fxsource'].toLowerCase();
		this.note = this.loadedEntry['note'];
		this.amountdueUSD = +this.amountdue / (this.invcurrency=='ZAR' ? +this.fxrate : 1);
		this.amountdueZAR = +this.amountdue / (this.invcurrency=='ZAR' ? 1 : 1/+this.fxrate);
		this.amountpaidZAR = +this.amountpaidUSD * +this.fxrate;
	}

	addPayment() {
		this.checkFXAmounts();
		this.fixDates();
		this.postBody = {
			"name": "addPayment",
			"param":{
				"iddelivery": (this.iddeliverytemp=='tbc' ? 0 : +this.dropDownDataService.dropdown.paymentList[this.iddelivery].iddelivery ),
				"season": this.loginService.season,
				"idproducer": this.idproducertemp,
				"instalment": this.instalment,
				"paydate": this.paydate,
				"paiddate": (this.paiddate == null ? "" : this.paiddate),
				"amountdue": (this.amountdue == null ? 0 : this.amountdue),
				"amountpaid": (this.amountpaid == null ? 0 : +this.amountpaid),
				"amountpaidUSD": (this.amountpaidUSD == null ? 0 : +this.amountpaidUSD),
				"fxrate": this.fxrate,
				"fxdate": (this.fxdate == null ? "" : this.fxdate),
				"fxsource": this.fxsource,
				"paycurrency": this.paycurrency,
				"invcurrency": this.invcurrency,
				"eft": (this.eft == null ? "" : this.eft),
				"notes": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']);
				    this.dropDownDataService.getDropdown("paymentList");
				    this.dropDownDataService.getDropdown("uaPaymentList");
				    this.dropDownDataService.getDropdown("uaNoPaymentList");
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Payment not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}	

	saveChanges() {
		this.checkFXAmounts();
		this.fixDates();
		this.postBody = {
			"name": "updatePayment",
			"param":{
				"idpayment": this.idpayment,
				"iddelivery": +(this.iddelivery=='tbc' ? (this.assigneddelno=='tbc' ? 0 : this.assigneddelno ) : this.dropDownDataService.dropdown.paymentList[this.iddeliverytemp].iddelivery ),
				"season": this.loginService.season,
				"idproducer": this.idproducertemp,
				"producer": this.producer,
				"instalment": this.instalment,
				"paydate": this.paydate,
				"paiddate": (this.paiddate == null ? "" : this.paiddate),
				"amountdue": (this.amountdue == null ? 0 : this.amountdue),
				"amountpaid": (this.amountpaid == null ? 0 : +this.amountpaid),
				"amountpaidUSD": (this.amountpaidUSD == null ? 0 : +this.amountpaidUSD),
				"fxrate": this.fxrate,
				"fxdate": (this.fxdate == null ? "" : this.fxdate),
				"fxsource": this.fxsource,
				"paycurrency": this.paycurrency,
				"invcurrency": this.invcurrency,
				"eft": (this.eft == null ? "" : this.eft),
				"notes": (this.note == null ? "" : this.note)
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Payment was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() {
		this.postBody = {
			"name": "deletePayment",
			"param":{
				"idpayment": +this.idpayment,
				"iddelivery": (this.iddelivery=='tbc' ? 0 : +this.dropDownDataService.dropdown.paymentList[this.iddelivery].iddelivery ),
				"season": this.loginService.season,
				"instalment": this.instalment,
				"producer": this.producer
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Payment was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm(deliveryref: boolean = true) {
    window.scroll(0,0);
    this.dropDownDataService.getDropdown("paymentList");
    if (deliveryref) {
    	this.iddelivery = null;
    	this.iddeliverytemp = null;
	    this.idproducer = '0';
	    this.idproducertemp = '0';
	    this.assigneddelno = 'null';
	  } else {
	  	this.idproducer = this.idproducertemp;
	  }
    this.instalment = "";
    this.producer = null;
    this.paiddate = this.d.toISOString().substring(0,10);
    this.fxdate = this.d.toISOString().substring(0,10);
		this.paydate = null;
		this.amountdue = null;
		this.amountpaidUSD = null;
		this.amountpaid = null;
		this.eft = null;
		this.paycurrency = "ZAR";
		this.invcurrency = "ZAR";
		this.fxrate = null;
		this.fxsource = "fixed";
		this.note = null;
  }

  checkFXAmounts() {
  	if (this.paycurrency == 'ZAR') {
  		this.amountpaidUSD = Math.round(+this.amountpaid / +this.fxrate * 100) / 100;
  	} else {
  		this.amountpaidUSD = this.amountpaid;
  		this.amountpaid = Math.round(+this.amountpaidUSD * +this.fxrate * 100) / 100;
  	}
  }

  setUnassigned() {
  	this.resetForm();
  	this.iddelivery = 'tbc';
  	this.instalment='a';
  }

	fixDate(field) {
		if (this[field]!==null && this[field]!=='') {
	  	this[field] = moment(this[field]).format('YYYY-MM-DD');
	  }
	}

	fixDates() {
		var dates = [
			'paiddate',
			'fxdate',
			'paydate'
		];
		for (var i=0; i < dates.length; i++) {
			this.fixDate(dates[i] + '');
		}
	}


}