import { Component, OnInit } from '@angular/core';

//import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { PortalService } from '../../auth/portal.service';
import { LoginService } from '../auth/login/login.service';
import { FarmerRemittanceService } from './remittance.service';

@Component({
  selector: 'app-farmerremittance',
  templateUrl: './remittance.component.html',
  styleUrls: ['./remittance.component.scss']
})
export class FarmerRemittanceComponent implements OnInit {
  d: Date = new Date();

  constructor(
  	public lS: LoginService,
    public rS: FarmerRemittanceService,
    public pS: PortalService
  	) { }

  ngOnInit() {
  	this.rS.loadRemittance();
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('contentToConvert');
		const width = 300, height = 200;

		// create a new jsPDF instance
		const pdf = new jsPDF('p', 'pt', 'a4');

    pdf.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    })
    .then(() => {
      var forwardZeros = 4 - this.rS.iddelivery.toString().length;
      if (forwardZeros >= 4) {
        var del_id = this.rS.iddelivery;
      }
      var zeros = "0";
      var del_id = zeros.repeat(forwardZeros) + this.rS.iddelivery.toString();

      var savename = this.lS.selectedmembername + ' - Remittance for Delivery ' + del_id +'.pdf';
      pdf.save(savename);
    })
	}

}
