import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SearchService } from '../../../search.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';
import { Invitations } from './users.interface';

declare var require: any;

@Injectable()
export class SettingsUsersService{
	private postBody: {};
	httpRes: any;
	public dataStore: any;
	public clickedIndex: number;
	public clickedType: string;
	public idMembership: string;
	public idcontact: number;
	public idcompany: string;
	public firstname: string;
	public lastname: string;
	public email: string;
	public companyname: string;
	public selectedContact: string = '0';
	public cIndex: number = 0;
	public membership: string;
	public dataRequest: string;
	public activityData: any[any] = [];
	public invitations: Invitations[] = [];
	passwordHash: string;
  public searchtext: string;
  public filteredData: any;
  public originalData: any;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
    public searchService: SearchService,
		private dropDownDataService: DropDownDataService) {}

	selectedItem(idcompany: string, firstname: string, lastname: string, email: string, companyname: string) {
		this.idcompany = idcompany;
		this.firstname = firstname;
		this.lastname = lastname;
		this.email = email;
	}

	addItem() {
		if (this.idcontact>0) {
			// Check if already added
			var addIt = true;
			for (var i = 0; i < this.invitations.length; i++) {
				if (+this.invitations[i].idcontact == this.idcontact && this.invitations[i].idmembership == this.idMembership) {
					addIt = false;		
				}
			}
			if (addIt) {
				if(this.invitations.length > 0) {
					this.invitations.push({
						"idcontact": +this.idcontact,
					 	"idcompany": +this.idcompany,
					 	"firstname": this.firstname,
					 	"lastname": this.lastname,
					 	"email": this.email,
					 	"idmembership": this.idMembership,
					 });	
				} else {
					this.invitations = [{
						"idcontact": +this.idcontact,
					 	"idcompany": +this.idcompany,
					 	"firstname": this.firstname,
					 	"lastname": this.lastname,
					 	"email": this.email,
					 	"idmembership": this.idMembership
					 }];
				}
			}
		}
	}

	removeItem(index: number) {
		this.invitations.splice(index, 1);
	}

	saveUserMembers() {
		this.postBody = {
			"name":"saveUserMembers",
			"param":{
				"newMembers": this.invitations,
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	try {
        		this.loginService.setSession(response);
        		if (response['response']['result']['reply']['accountsAdded'] > 0 || response['response']['result']['reply']['membershipsAdded'] > 0) {
        			this.loginService.toast("Save Successful",(response['response']['result']['reply']['accountsAdded'] > 0 ? response['response']['result']['reply']['accountsAdded'] + ' new account(s) added, with ' : '') +response['response']['result']['reply']['membershipsAdded'] + ' new role(s) registered. Email notifications have been sent.');
        		} else {
        			this.loginService.toast("Save Unsuccessful",'No new accounts or roles were saved.','warn');	
        		}
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful",'No new accounts or roles were saved.','warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	loadUsers() {
		this.postBody = {
			"name":"userList",
			"param":{
				"location": 1
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          this.httpRes = this.storeResult(response);
        },
        (error) => console.log(error)
      );
	}

	storeResult(rawData) {
		this.dataStore = rawData.response.result.reply;
	}

	resetForm() {
		this.invitations = [];
	}

	setClient(idd: number) {
		for (var i = 0; i < this.dataStore.length ; i++) {
			if (+this.dataStore[i].idcompany == idd) {
				this.clickedIndex = i;
				this.clickedType = this.dataStore[i]['companytype'];
			}
		}
	}

	loadForEdit() {
	}

	saveChanges() {
	}

	unblock(id: string) {
		this.postBody = {
			"name":"unblockUserAccount",
			"param":{
				"idcontact": id.toString()
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          try {
          	this.loginService.toast("Account Unblock Successful","Account unblocked for " + response['response']['result']['reply']['name'] + " (" + response['response']['result']['reply']['email'] + ")\r\n\r\nFailed Logins: " + response['response']['result']['reply']['login_attempts'] + "\r\nReset Attempts: " + response['response']['result']['reply']['reset_attempts'] + "\r\nSuccessful Resets: " + response['response']['result']['reply']['account_resets']);
          } catch(e) {
          	this.loginService.toast("Account Unblock Unsuccessful","Password not reset. An error occured.",'warn');
          }
        },
        (error) => console.log(error)
      );
	}

	passReset(id: string) {
		this.postBody = {
			"name":"resetUserPassword",
			"param":{
				"idcontact": id.toString()
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          try {
          	this.loginService.toast("Password Reset Successful","Password reset sent successfully to " + response['response']['result']['reply']['name'] + " (" + response['response']['result']['reply']['email'] + ")\n\nPassword reset link:\n" + response['response']['result']['reply']['link']);
          } catch(e) {
          	this.loginService.toast("Password Reset Unsuccessful","Password not reset. An error occured.",'warn');
          }
        },
        (error) => console.log(error)
      );
	}

	passAssign(id: string, password: string) {
		var sha512 = require('js-sha512');
    this.passwordHash = sha512(password);
		this.postBody = {
			"name":"setUserPassword",
			"param":{
				"idcontact": id.toString(),
				"password": this.passwordHash
			}
		};
		this.passwordHash = "";
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          try {
          	this.loginService.toast("Password Change Successful","New Password set for " + response['response']['result']['reply']['fullname'] + " (" + response['response']['result']['reply']['email'] + ").")
          } catch(e) {
          	this.loginService.toast("Password Change Unsuccessful","Password was not changed. An error occured.",'warn');
          }
        },
        (error) => console.log(error)
      );
	}

	async removeAcc(id: string, member: string) {
    const res = await this.loginService.confirm("Are you sure you want to remove this account?");
    if (res) {
			this.postBody = {
			"name":"removeUserMembership",
			"param":{
				"idcontact": id.toString(),
				"idmembership": member.toString()
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response) => {
        	this.loginService.setSession(response);
          try {
          	if (response['response']['result']['reply']['removed'] > 0) {
          		this.loginService.toast("Account Removal Successful","The role '" + response['response']['result']['reply']['membership'] + "' for " + response['response']['result']['reply']['name'] + " (" + response['response']['result']['reply']['email'] + ") was successfully removed.")
							this.loadFiltered();
          	} else {
          		this.loginService.toast("Account Removal Unsuccessful","MemberThe role '" + response['response']['result']['reply']['membership'] + "' for " + response['response']['result']['reply']['name'] + " (" + response['response']['result']['reply']['email'] + ") was NOT removed, possibly removed already. Please reload this page.",'warn')
          	}
          } catch(e) {
          	this.loginService.toast("Account Removal Unsuccessful","User role / account not removed. An error occured.",'warn');
          }
        },
        (error) => console.log(error)
      );
		}
	}

	confirmChange() {
	}

	async userActivity(dataRequest) {
		var p = {
			"location": 1,
			"contact": this.selectedContact.toString()
		};
		this.dataRequest = dataRequest;
		this.dataStore = await this.loginService.loadData(dataRequest, p);
		this.activityData[dataRequest.slice(0)] = this.dataStore;
		this.filteredData = JSON.parse(JSON.stringify(this.dataStore));
	}

	setTable(table) {
		this.dataRequest = table;
		this.userActivity(table);
	}

	onSelected(i: string, cIndex: number) {
		this.selectedContact = i;
		this.cIndex = (+i==0 ? null : cIndex);
		this.userActivity('getUsersActivity');
	}

  async loadFiltered() {
    this.filteredData = await this.loginService.loadData("userList");
    this.originalData = this.filteredData;
    this.filterTable();
  }
  
  filterTable() {
	  if (this.searchtext!=null) {
	    var args = this.searchtext.split(" ");
	    for (var i = args.length-1; i >=0; i--) {
	      if (args[i]=="") {
	        args.splice(i,1);
	      }
	    }
	    let startArr = JSON.parse(JSON.stringify(this.originalData));
	    this.filteredData = this.searchService.filterArray(startArr, args, 'memberships');
	  }
  }

}