import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DepotDeliveryService } from '../depotdelivery.service';
import { TableDataService } from '../../overview/table/table.service';
//import { TeamCompanyService } from '../../overview/companies/teamcompanies.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-depotdelivery',
  templateUrl: './depotdelivery.component.html',
  styleUrls: ['./depotdelivery.component.scss']
})
export class DepotDeliveryComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
  	private loginService: TeamLoginService,
    public tableService: TableDataService,
  	public deliveryService: DepotDeliveryService,
    //public companyService: TeamCompanyService,
    private addDeliveryService: AddDeliveryService,
    private dropDownDataService: DropDownDataService,
    private router: Router
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }

  editDelivery() {
    this.addDeliveryService.delivery = this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']].iddelivery.toString();
    this.addDeliveryService.editmode = true;
    this.dropDownDataService.getDropdown("deliveryList");
    this.dropDownDataService.getDropdown("cultivarProfileList");
    this.router.navigate(['/team/logging/delivery']);
    this.addDeliveryService.loadForEdit();
  }

  editDepotDelivery() {
    this.deliveryService.iddeldepot = this.tableService['depotdeliveries'][this.tableService.clickedIndex['depotdeliveries']].iddeldepot.toString();
    this.deliveryService.editmode = true;
    this.dropDownDataService.getDropdown("depotList",this.deliveryService.iddepot);
    this.dropDownDataService.getDropdown("depotDeliveryList",this.deliveryService.iddepot);
    this.dropDownDataService.getDropdown("cultivarProfileList");
    this.router.navigate(['/team/depot/log-delivery']);
    this.deliveryService.loadForEdit();
  }

  viewCompany(idcompany) {
    if (this.loginService.isAuthorised('general')) {
      //this.companyService.setCompany(idcompany);
        //this.router.navigate(['/team/overview/companies']);
      this.tableService.reset('companies');
      this.tableService.setDelivery(idcompany, 'companies');
      this.tableService.indexLink = true;
      this.router.navigate(['/team/table/companies']);
      setTimeout(function() {
        window.scrollTo(0,750);
      }, 100);
    }
  }
  
}
