import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";
am4core.useTheme(am4themes_animated); 
//am4core.useTheme(am4themes_material);

import { Silo, RootObject } from './silolevels.interface';
import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { SiloLevelsService } from './silolevels.service';
import { CsvService } from '../../../services/csv.service';

@Component({
  selector: 'app-silolevels',
  templateUrl: './silolevels.component.html',
  styleUrls: ['./silolevels.component.scss']
})
export class SiloLevelsWidget implements OnInit {

  private chart: am4charts.XYChart;

  isLoadingResults = true;
  refreshfocus = false;

  constructor(
    public loginService: TeamLoginService,
  	public siloService: SiloLevelsService,
    public csvService: CsvService,
    private http: HttpClient,
    private zone: NgZone
  	) { }

  ngOnInit() {
    this.siloService.loadSiloChart();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  async refresh(event) {
    await this.siloService.loadSiloChart();
    event.target.blur();
  }

  cacheForMember() {
  }

}