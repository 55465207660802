import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CsvService } from '../../../../services/csv.service';

import { PortalService } from '../../../../auth/portal.service';
import { TeamLoginService } from '../../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../../logging/dropdown.service';
import { SettingsClientsService } from '../clients.service';
import { SearchService } from '../../../../search.service';


@Component({
  selector: 'app-settings-clients-active',
  templateUrl: './clients-active.component.html',
  styleUrls: ['./clients-active.component.scss']
})
export class SettingsClientsActiveComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  d: Date;
  scroll: boolean = true;
  isLoadingResults = true;
  id: string;
  name: string;
  pass: string;
  searchtext: string;
  checked: boolean = true;
  passwordOk: boolean = true;
  passwordLength = false;
  passwordSpecial = false;
  filteredData: any[];

  constructor(
    public portalService: PortalService,
    public loginService: TeamLoginService,
    public searchService: SearchService,
  	public clientsService: SettingsClientsService,
    public dropDownDataService: DropDownDataService,
    private http: HttpClient,
    public csvService: CsvService
  	) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.clientsService.idcontact = 0;
    this.clientsService.resetForm();
    this.clientsService.loadFiltered();
  }

  ngOnDestroy() {
    this.clientsService.searchtext = null;
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.clientsService.clickedIndex !== null;
  }

  passAssign(id: string, name: string) {
    this.id = id;
    this.name = name;
    document.getElementById("popup").style.display = "block";
  }

  passAssignSave() {
    if (this.validateNewPWord()) {
      this.clientsService.passAssign(this.id,this.pass);
      this.cancelSave();
    }
  }

  validateNewPWord() {
    var regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    var ans = regex.test(this.pass);
    this.passwordSpecial = (ans ? false : true);
    this.passwordLength = this.pass.length>7;
    this.passwordOk = (ans ? false : (this.passwordLength ? true : false ) );
    ans = (this.passwordLength && this.passwordOk);
    return ans;
  }

  cancelSave() {  
    document.getElementById("popup").style.display = "none";
    this.pass = "";
    this.name = "";
    this.id = "";
  }

  csv(datatable) {
    var thistable=document.getElementById(datatable);
    var title = 'Clients: Active Accounts';
    this.csvService.csvSimple(thistable, title, 4);
  }

}