import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, Router, RouterModule, ActivatedRoute, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { AddDeliveryService } from '../../logging/add-delivery/add-delivery.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { PortalService } from '../../../auth/portal.service';
import { CsvService } from '../../../services/csv.service';
import { Table } from 'primeng/table';

import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  d: Date = new Date();
  memberSelect;
  dataStore;
  entity: string = "Sample";
  tname: string = "Samples";
  tablename: string = "Samples";
  exportname: string = this.tablename + " - " + this.d.toISOString().slice(0, 10);
  component: string = "app-teamdelivery";
  subdir: string = 'logging';
  searchtext: string = '';
  curswitch: boolean = false;
  remittanceDetails: boolean = false;
  exportData;
  exportDate;
  exportMonth;
  headers;
  choice: string = "all";
  csvReport;
  csvdata;
  csvloading = false;

  @ViewChild(Table) private dt1: Table;

  constructor(
    public lS: TeamLoginService,
    public tS: TableDataService,
    public dDDS: DropDownDataService,
    public pS: PortalService,
    private aDS: AddDeliveryService,
    public csvService: CsvService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.tS.stopLoading = false;
    this.tS.selectedRows = null;
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
        this.tS.reset(this.tablename.toLowerCase());
      }
    })
    this.route.params.subscribe( params =>
      this.setParams(params)
    );
    window.scrollTo(0,0);
  }

  ngAfterViewInit() {
    this.resetTableAutoScroll();
  }
  
  resetTableAutoScroll() {
    if (this.dt1['originalResetScrollTop']==undefined) {
      this.dt1['originalResetScrollTop'] = this.dt1.resetScrollTop;
    }
    if (this.dt1 !=null && this.dt1['originalResetScrollTop']!=undefined) {
      if (this.tS.stillLoading) {
        this.dt1.resetScrollTop = function() {}
      } else {
        this.dt1.resetScrollTop = function() {
          this.dt1.originalResetScrollTop();
        }
      }
    }
  }

  ngOnDestroy() {
    this.tS.stopLoading = true;
    this.tS.reset(this.tablename.toLowerCase());
    this.clear(this.dt1);
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.lS.isAuthenticated();
    }
    if (this.pS.portalMod()[option] || this.pS.portalMod()['mod-all']) {
      return this.lS.isAuthenticated();
    } return false;
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  setParams(params) {
    var t = params['tablename'];
    switch (t) {
      case "companies":
        this.tname = 'Companies';
        this.tablename = 'companies';
        this.entity = 'Company';
        this.component = 'company';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "contacts":
        this.tname = 'Contacts';
        this.tablename = 'contacts';
        this.entity = 'Contact';
        this.component = 'contact';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "deliveries":
        this.tname = 'Deliveries';
        this.tablename = 'deliveries';
        this.entity = 'Delivery';
        this.component = 'delivery';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "depotdeliveries":
        this.tname = 'Depot Deliveries';
        this.tablename = 'depotdeliveries';
        this.entity = 'Depot Delivery';
        this.component = 'depotdelivery';
        this.subdir = 'depot';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "samples":
        this.tname = 'Samples';
        this.tablename = 'samples';
        this.entity = 'Sample';
        this.component = 'delivery';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "resamples":
        this.tname = 'Resamples';
        this.tablename = 'resamples';
        this.entity = '';
        this.component = '';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "samplesunassigned":
        this.tname = 'Samples - Unassigned';
        this.tablename = 'samplesunassigned';
        this.entity = '';
        this.component = 'delivery';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "remittances":
        this.tname = 'Remittances';
        this.tablename = 'remittances';
        this.entity = '';
        this.component = 'remittance';
        this.subdir = 'logging';
        this.curswitch = true;
        this.tS.bpanel = false;
        break;
      case "payments":
        this.tname = 'Payments';
        this.tablename = 'payments';
        this.entity = 'Payment';
        this.component = 'payment';
        this.subdir = 'logging';
        this.curswitch = true;
        this.tS.bpanel = false;
        break;
      case "orders":
        this.tname = 'Orders';
        this.tablename = 'orders';
        this.entity = 'Order';
        this.component = 'order';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "bookings":
        this.tname = 'Bookings';
        this.tablename = 'bookings';
        this.entity = 'Booking';
        this.component = 'booking';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "batches":
        this.tname = 'Batches';
        this.tablename = 'batches';
        this.entity = 'Batch';
        this.component = 'batch';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "pricemodel":
        this.tname = 'Price Models';
        this.tablename = 'pricemodel';
        this.entity = '';
        this.component = 'model';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "assignedinventory":
        this.tname = 'Assigned Inventory';
        this.tablename = 'assignedinventory';
        this.entity = '';
        this.component = 'stock';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "palletsamples":
        this.tname = 'Pallet Samples';
        this.tablename = 'palletsamples';
        this.entity = 'pallet sample';
        this.subdir = 'lab';
        this.component = '';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "palletreview":
        this.tname = 'Pallet Review';
        this.tablename = 'palletreview';
        this.entity = '';
        this.subdir = 'lab';
        this.component = 'palletreview';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "palletactions":
        this.tname = 'Pallets - To Do';
        this.tablename = 'palletactions';
        this.entity = '';
        this.subdir = 'lab';
        this.component = 'palletaction';
        this.curswitch = false;
        this.tS.bpanel = false;
        break;
      case "pickinglist":
        this.tname = 'Picking List';
        this.tablename = 'pickinglist';
        this.entity = 'pallet sample';
        this.subdir = 'lab';
        this.component = 'picking';
        this.curswitch = false;
        this.tS.bpanel = false;
        this.tS.getPickingOrders();
        break;
      case "pickedlist":
        this.tname = 'Picked List';
        this.tablename = 'pickedlist';
        this.entity = 'pallet sample';
        this.subdir = 'lab';
        this.component = 'picking';
        this.curswitch = false;
        this.tS.bpanel = false;
        this.tS.getPickingOrders();
        break;
    }
    this.tS.tablename = this.tablename;
    if (!this.tS.indexLink) {this.tS.clickedIndex[t.toLowerCase()] = null;}
    this.exportname = t + " - " + this.d.toISOString().slice(0, 10);
    if (this.tS[t]==null) {
      this.tS.loadData(t);
    }
  }

  cacheForMember() {
    this.tS.loadData(this.tablename.toLowerCase());
    this.lS.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.tS.clickedIndex[this.tablename.toLowerCase()] = null;
    this.lS.selectedmember = membership;
    this.lS.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
  }

  onSelectRow(clickedId) {
    var tname = this.tablename;
    this.tS.clickedId[this.tS.allData[this.tablename.toLowerCase()]['key']] = clickedId;
    this.tS.setDelivery(clickedId, this.tablename.toLowerCase());
    setTimeout(function() {
      if (tname=='bookings') {
        window.scrollTo(0,1550);
      } else {
        window.scrollTo(0,750);
      }
    }, 100); 
  }

  multipleMembers() {
    return +this.lS.memberships.length > 1;
  }

  toggleCheckboxes() {
    this.tS.selectedRows = [];
    this.tS.checkBoxes = !this.tS.checkBoxes;
  }

  calTotal(field: string, type: string = 'simple', trigger: string = ''){
    var cur = '';
    var fx = '';
    if (type!=='simple') {
      for (var i = 0; this.tS.allData[this.tablename.toLowerCase()]?.columns.length; i++) {
        if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['field']==field) {
          cur = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['currency'];
          fx = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['fxrate'];
          break;
        }
      }
    }
    var total = 0;
    for (var i = 0; i < this.tS.filteredData?.length; i++) {
      total = total + (type=='fx' ? 
        this.fixFx(
          this.tS.filteredData[i][field], 
          this.tS.filteredData[i][fx], 
          this.tS.filteredData[i][cur]
        ) 
        : +this.tS.filteredData[i][field] * (trigger=='' ? 1 : (+this.tS.filteredData[i][trigger]>0 ? 1 : 0)));
    }
    return total;
  }

  calCount(field: string, type: string = 'simple', trigger: string = ''){ 
    var total = 0;
    for (var i = 0; i < this.tS.filteredData?.length; i++) {
      total = total + (this.tS.filteredData[i][field]==null ? 0 : 1) * (trigger=='' ? 1 : (+this.tS.filteredData[i][trigger]>0 ? 1 : 0));
    }
    return total;
  }

  calWavg(field: string, type: string = 'simple') {
    var base = '';
    var trigger = '';
    var cur = '';
    var fx = '';
    try {
      for (var i = 0; this.tS.allData[this.tablename.toLowerCase()]?.columns.length; i++) {
        if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['field']==field) {
          var pipe = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['pipe'];
          if (type!=='simple') {
            cur = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['currency'];
            fx = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['fxrate'];
          }
          if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['base']!==undefined) {
            base = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['base'];
          }
          if (this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['wavgtrigger']!==undefined) {
            trigger = this.tS.allData[this.tablename.toLowerCase()]['columns'][i]['wavgtrigger'];
          }
        }
      }
    }
    catch(e) {
    }
    var total = 0;
    for (var i = 0; i < this.tS.filteredData?.length; i++) {
      total = total + (type=='fx' ? 
        this.fixFx(
          this.tS.filteredData[i][field], 
          this.tS.filteredData[i][fx], 
          this.tS.filteredData[i][cur]
        ) 
        : +this.tS.filteredData[i][field]) * (base=='' ? 1 : +this.tS.filteredData[i][base]) * (trigger=='' ? 1 : (+this.tS.filteredData[i][trigger]>0 ? 1 : 0))
    }
    if (pipe=='integer') {
      return Math.round( total / (base=='' ? this.calCount(field, 'simple', trigger) : this.calTotal(base, 'simple', trigger) ));
    } else {
      return total / (base=='' ? this.calCount(field, 'simple', trigger) : this.calTotal(base, 'simple', trigger) );
    }
  }

  fixFx(value: number, fx: number, currency: string) {
    return this.tS.fixFx(value, fx, currency);
  }

  onCurSelect(currency) {
    this.tS.currency = currency;
  }

  batchPanel() {
    this.tS.clickedIndex[this.tablename.toLowerCase()] = null;
    this.tS.bpanel = !this.tS.bpanel;
  }

  clear(table: any) {
    table.clear();
    table.filter();
    this.tS.selectedRows = null;
    this.tS.checkBoxes = false;
    this.searchtext = '';
    table.filterGlobal(this.searchtext, 'contains');
    this.tS.clickedId[this.tS.allData[this.tablename.toLowerCase()]['key']] = null;
  }

  onFilter(event, dt) { 
    this.tS.filteredData = event.filteredValue;
  }

  isSelected(idd) {
    return this.tS.clickedId[this.tS.allData[this.tablename.toLowerCase()]['key']] == idd;
  }

  validateDelivery() {
    var cId = this.tS.clickedIndex['deliveries'];
    this.tS.clickedIndex['deliveries'] = null;
    this.aDS.iddelivery = this.tS['deliveries'][cId].iddelivery;
    this.aDS.validateDelivery();
  }

  async onUpload(event) {
    this.csvReport = {"data": [], "successful": 0, "failed": 0}
    this.csvloading = true;
    const input = event.target.files[0];
    var csv = await this.readFileAsync(input);
    var lines = csv.toString().split("\n");
    var result = [];
    var headers=['date','reference','amount'];
    currentline=lines[0].split(",");
    var USD = currentline[0].toLowerCase()=="usd";
    for(var i=2;i<lines.length;i++){
      var obj = {};
      var currentline=lines[i].split(",");
      for(var j=0;j<headers.length;j++){
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    this.csvdata = result;
    var chunksize = 100;
    for (var i=0; i<this.csvdata.length; i+=chunksize) {
      const csvpart = this.csvdata.slice(i, i+chunksize);
      var p = {
        "type": "Nedbank",
        "currency": (USD ? "USD" : "ZAR"),
        "payments": csvpart
      };
      var reply = await this.lS.loadData('csvPaymentUpload', p);
      this.csvReport['data'].push(...reply['report']);
      this.csvReport['successful'] += reply['successful'];
      this.csvReport['failed'] += reply['failed'];
      this.csvReport['headers'] = reply['headers'];
    }
    this.csvloading = false;
    if (this.csvReport['successful']>0) {
      var message = 'Successfully reconciled ' + this.csvReport['successful'] + ' payments.';
      this.lS.toast('CSV Bank Statement Upload', message, 'success', 4000);
    }
    if (this.csvReport['successful']==0) {
      var message = 'No payments were successfully reconciled.';
      this.lS.toast('CSV Bank Statement Upload', message, 'warn', 4000);
    }
    if (this.csvReport['failed']>0) {
      var message = 'Failed to reconcile ' + this.csvReport['failed'] + ' payments.';
      this.lS.toast('CSV Bank Statement Upload', message, 'warn', 4000);
    }
    this.csvService.rawCSV('AmberMacs - CSV Payment Reconciliation Report', this.csvReport['headers'], this.csvReport['data'], true);
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    })
  }

  changeTable(tablename) {
    var t = {'tablename': tablename.toLowerCase()};
    this.tS.reset(t['tablename']);
    this.setParams(t);
  }

  async buttonAction(action,id) {  
    var p = {
      "season": this.lS.season,
      "location": this.lS.location,
      "id": id
    }
    const response = await this.lS.loadData(action, p);
    this.lS.toast('Pallet Status Updated', response.message);
    if (response['action']=='updateRow') {
      this.tS.updateRow(this.tablename.toLowerCase(), response.id, response.changes);
    }
  }

  async reloadTable(event) {
    var t = this.tablename.toLowerCase();
    this.tS.reset(this.tablename.toLowerCase());
    this.clear(this.dt1);
    const refresh = await this.tS.loadData(t);
    this.tS[this.tablename.toLowerCase()] = await [...this.tS[this.tablename.toLowerCase()]];
    this.tS.filteredData = await [...this.tS.filteredData];
  }
   

}