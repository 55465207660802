<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6">
				<h2>Internal Users - Portal Access</h2>
			</div>
		</div>
		<br>
		<br>
		<div class="col px-0">
		 	<h3>Portal User Invitations</h3> 
		 	<br>
			<div class="form-container col col-md-6 pl-0">

				<mat-form-field>
    			<mat-label>Contact Name</mat-label>
			    <mat-select placeholder="Contact Name" [(ngModel)]="this.usersService.idcontact">
			      <mat-option *ngFor="let contact of this.dropDownDataService.dropdown.internalContactList" #prod (click)="this.usersService.selectedItem( contact.idcompany, contact.firstname, contact.lastname, contact.email, contact.companyname)" value="{{ contact.idcontact }}">{{ contact.firstname }} {{ contact.lastname }} [{{ contact.companyname }}]</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Role</mat-label>
			    <mat-select placeholder="Role" [(ngModel)]="this.usersService.idMembership" (keydown.tab)="this.usersService.confirmChange()">
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="accountant">Accountant (use as sole role)</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="accountant">Accountant</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="admin">Admin</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="booking">Booking Schedule</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="buying">Buying - View</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="buyingedit">Buying - Edit</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="currency">Currency</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="customer">Customer</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="depot">Depot</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="extendedlogin">Extended Login</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="factory">Factory</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="finance">Finance</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="financeadmin">Finance Admin</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="general">General</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="lab">Lab</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="manageinventory">Manage Inventory</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="management">Management</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="messages">Messaging</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="nisadmin">NIS Admin</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="remittance">Remittance</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="siloselector">Silo Selector</mat-option>
			      <mat-option (click)="this.usersService.confirmChange()" #prod value="siloselectorforced">Silo Selector - Default</mat-option>
			    </mat-select>
			  </mat-form-field>
			  
			</div>

			<button mdbBtn class="btn btn-primary" [disabled]="this.usersService.idMembership==null || +this.usersService.idcontact<1" (click)="this.usersService.addItem()">Add</button>

			<br>
			<br>

			<table *ngIf="this.usersService.invitations.length > 0" class="table col" mdbTableScroll [scrollY]="scroll" maxHeight="500" bordered="true">
				<tbody>
					<tr *ngIf="this.usersService.invitations.length>0">
						<th>Contact</th>
						<th>Email</th>
						<th>Role</th>
						<th>Remove</th>
					</tr>
					<tr *ngFor="let invite of this.usersService.invitations; let itemindex=index">
						<td>{{ invite.firstname + ' ' + invite.lastname }}</td>
						<td>{{ invite.email }}</td>
						<td>{{ invite.idmembership | titlecase }}</td>
						<td>
						  <button mat-icon-button class="col" *ngIf="this.usersService.invitations.length>0" (click)="this.usersService.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
						</td>
					</tr>
				</tbody>
			</table>

			<button *ngIf="this.usersService.invitations.length > 0" mdbBtn class="btn btn-primary my-4" (click)="onSave()">Send Invites</button>
			<button *ngIf="this.usersService.invitations.length > 0" mdbBtn class="btn btn-warning my-4" (click)="this.usersService.resetForm()">Clear</button>

			<hr>

		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>