<div class="container p-0" [class.hide]="this.siloService.siloChart==null || this.siloService.siloChart[0]==null">
	<div class="row justify-content-between">
		<h4 class="col mt-1">Silo Levels</h4>

		<ng-container *ngIf="this.siloService.siloChart!=undefined">
			<p *ngIf="this.siloService.siloChart[0]!=null" class="col-auto ml-3 mr-1 mt-1 px-0">({{ this.siloService.siloChart[0]['leveldate'] | date:'d/M/yy, H:mm' }})</p>
			<button type="button" pButton pRipple class="p-mr-2 p-ml-2 p-button p-px-2 mr-4 refresh-button" pTooltip="Loading..." tooltipPosition="bottom" (click)="this.refresh($event)"><i class="pi pi-refresh" [class.pi-spin]="this.siloService.chartloading"></i></button>
		</ng-container>
	</div>
	<hr class="hr-sep mb-0 mt-2">
	<div id="chartdiv" style="width: 100%; height: 180px;"></div>
	<hr class="hr-sep">
</div>
<br>