<div class="container">
	<div class="row justify-content-between">
		<div class="col-xs-6 ml-3">
			<h3>Batches for: {{ this.inventoryService.dataStore[this.inventoryService.clickedIndex]?.style.slice(0,-1) }} {{ this.inventoryService.dataStore[this.inventoryService.clickedIndex]?.variety }}</h3>
		</div>
	</div>
	<br>
	<table class="my-4 table">
		<tbody>
			<tr class="header">
				<td>Batch</td>
				<td>Silo</td>
				<td>Style</td>
				<td>Quantity</td>
				<td>First Delivery</td>
				<td>Last Delivery</td>
				<td>Completed</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
			<tr *ngFor="let batch of this.inventoryService.dataStore[this.inventoryService.clickedIndex]?.batches; let i = index" (click)="selectBatch(i)">
				<td>{{ batch.batch }}</td>
				<td>{{ batch.silo | uppercase }}</td>
				<td>{{ batch.style }}</td>
				<td>{{ batch.total }}</td>
				<td>{{ batch.intakestart | date:'MMM d, EEE' }}</td>
				<td>{{ batch.intakeend | date:'MMM d, EEE' }}</td>
				<td>{{ batch.emptydate | date:'MMM d, EEE' }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
		</tbody>
	</table>
	<br>

	<ng-container *ngIf="this.inventoryService.batchClicked">
	<table class="my-4 table">	
		<h2>Farmers for Batch: {{ this.inventoryService.dataStore[this.inventoryService.clickedIndex]?.batches[this.inventoryService.iBatch].batch }}</h2>
		<tbody>
			<tr class="header">
				<td>Producer</td>
				<td>Associated Deliveries</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
			<tr *ngFor="let farmer of this.inventoryService.dataStore[this.inventoryService.clickedIndex]?.batches[this.inventoryService.iBatch].farmers">
				<td>{{ farmer.companyname }}</td>
				<td>{{ farmer.deliveries }}</td>
			</tr>
			<tr class="tableseperator"><td></td><td></td></tr>
		</tbody>
	</table>
	</ng-container>

	<br><br>
</div>