<div class="container">
  <div class="row justify-content-between">
    <div class="col">
      <h3>Pallet Review [ {{ ( this.tableService.selectedRows.length>1 ? this.tableService.selectedRows.length + ' Pallets' : '1 Pallet' ) }} Selected ]</h3>
    </div>
  </div>
  <br>
 
  <br>
  <div class="row justify-content-between">
    <div class="col-xs-6 ml-3"></div>
    <div class="mr-4 ms-0">
      <button mdbBtn class="btn btn-success" (click)="groupEdit('approved')">Approve Selected</button>
      <button mdbBtn class="btn btn-info" (click)="groupEdit('to crack')">Crack Selected</button>
      <button mdbBtn class="btn btn-danger" (click)="groupEdit('review')">Set To Review</button>
    </div>
  </div>
  <br><br>
</div>