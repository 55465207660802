import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, Router, RouterModule, ActivatedRoute, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { LoginService } from '../auth/login/login.service';
import { ClientTableDataService } from '../table/table.service';
import { PortalService } from '../../auth/portal.service';
import { CsvService } from '../../services/csv.service';
import { Table } from 'primeng/table';

import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-clienttable',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class ClientTableComponent implements OnInit {
  d: Date = new Date();
  memberSelect;
  dataStore;
  entity: string = "Sample";
  tname: string = "Samples";
  tablename: string = "Samples";
  exportname: string = this.tablename + " - " + this.d.toISOString().slice(0, 10);
  component: string = "app-delivery";
  subdir: string = 'logging';
  searchtext: string = '';
  curswitch: boolean = false;
  remittanceDetails: boolean = false;
  exportData;
  exportDate;
  exportMonth;
  headers;
  choice: string = "all";
  csvReport;
  csvdata;
  csvloading = false;

  @ViewChild(Table) private dt1: Table;

  constructor(
    public loginService: LoginService,
    public tableService: ClientTableDataService,
    public portalService: PortalService,
    public csvService: CsvService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    this.tableService.stopLoading = false;
    this.tableService.selectedRows = null;
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
        this.tableService.reset(this.tablename.toLowerCase());
      }
    })
    this.route.params.subscribe( params =>
      this.setParams(params)
    );
    window.scrollTo(0,0);
  }

  ngAfterViewInit() {
    this.resetTableAutoScroll();
  }
  
  resetTableAutoScroll() {
    if (this.dt1['originalResetScrollTop']==undefined) {
      this.dt1['originalResetScrollTop'] = this.dt1.resetScrollTop;
    }
    if (this.dt1 !=null && this.dt1['originalResetScrollTop']!=undefined) {
      if (this.tableService.stillLoading) {
        this.dt1.resetScrollTop = function() {}
      } else {
        this.dt1.resetScrollTop = function() {
          this.dt1.originalResetScrollTop();
        }
      }
    }
  }

  ngOnDestroy() {
    this.tableService.stopLoading = true;
    this.tableService.reset(this.tablename.toLowerCase());
    this.clear(this.dt1);
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  setParams(params) {
    this.remittanceDetails = false;
    var t = params['tablename'];
    switch (t) {
      case "deliveries":
        this.tname = 'Deliveries';
        this.tablename = 'deliveries';
        this.entity = 'Delivery';
        this.component = 'delivery';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tableService.bpanel = false;
        break;
      case "samples":
        this.tname = 'Samples';
        this.tablename = 'samples';
        this.entity = 'Sample';
        this.component = 'delivery';
        this.subdir = 'logging';
        this.curswitch = false;
        this.tableService.bpanel = false;
        break;
      case "remittances":
        this.tname = 'Remittances';
        this.tablename = 'remittances';
        this.entity = '';
        this.component = 'remittance';
        this.subdir = 'logging';
        this.curswitch = true;
        this.tableService.bpanel = false;
        break;
      case "payments":
        this.tname = 'Payments';
        this.tablename = 'payments';
        this.entity = 'Payment';
        this.component = 'remittance';
        this.subdir = 'logging';
        this.curswitch = true;
        this.tableService.bpanel = false;
        break;
    }
    this.tableService.tablename = this.tablename;
    if (!this.tableService.indexLink) {this.tableService.clickedIndex[t.toLowerCase()] = null;}
    this.exportname = t + " - " + this.d.toISOString().slice(0, 10);
    if (this.tableService[t]==null) {
      this.tableService.loadData(t);
      if (t=='deliveries' && this.tableService['samples']==null) {
        this.tableService.loadData('samples');
      }
      if (t=='samples' && this.tableService['deliveries']==null) {
        this.tableService.loadData('deliveries');
      }
      if (t=='payments' && this.tableService['remittances']==null) {
        this.tableService.loadData('remittances');
      }
    }
  }

  cacheForMember() {
    this.tableService.refreshAll();
    this.loginService.memberships = JSON.parse(localStorage.getItem('memberships'));
   }

  onMemberSelected(membership, membername) {
    this.tableService.clickedIndex[this.tablename.toLowerCase()] = null;
    this.loginService.selectedmember = membership;
    this.loginService.selectedmembername = membername;
    this.memberSelect = membership;
    this.cacheForMember();
  }

  onSelectRow(clickedId) {
    var tname = this.tablename;
    this.tableService.clickedId[this.tableService.allData[this.tablename.toLowerCase()]['key']] = clickedId;
    this.tableService.setDelivery(clickedId, this.tablename.toLowerCase());
    setTimeout(function() {
       window.scrollTo(0,750)
    }, 100); 
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  toggleCheckboxes() {
    this.tableService.selectedRows = [];
    this.tableService.checkBoxes = !this.tableService.checkBoxes;
  }

  calTotal(field: string, type: string = 'simple'){
    var cur = '';
    var fx = '';
    if (type!=='simple') {
      for (var i = 0; this.tableService.allData[this.tablename.toLowerCase()]?.columns.length; i++) {
        if (this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['field']==field) {
          cur = this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['currency'];
          fx = this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['fxrate'];
          break;
        }
      }
    }
    var total = 0;
    for (var i = 0; i < this.tableService.filteredData?.length; i++) {
      total = total + (type=='fx' ? 
        this.fixFx(
          this.tableService.filteredData[i][field], 
          this.tableService.filteredData[i][fx], 
          this.tableService.filteredData[i][cur]
        ) 
        : +this.tableService.filteredData[i][field]);
    }
    return total;
  }

  calCount(field: string, type: string = 'simple'){ 
    var total = 0;
    for (var i = 0; i < this.tableService.filteredData?.length; i++) {
      total = total + (this.tableService.filteredData[i][field]==null ? 0 : 1);
    }
    return total;
  }

  calWavg(field: string, type: string = 'simple') {
    var base = '';
    var cur = '';
    var fx = '';
    try {
      for (var i = 0; this.tableService.allData[this.tablename.toLowerCase()]?.columns.length; i++) {
        if (this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['field']==field) {
          var pipe = this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['pipe'];
          if (type!=='simple') {
            cur = this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['currency'];
            fx = this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['fxrate'];
          }
          if (this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['base']!==undefined) {
            base = this.tableService.allData[this.tablename.toLowerCase()]['columns'][i]['base'];
          }
        }
      }
    }
    catch(e) {
    }
    var total = 0;
    for (var i = 0; i < this.tableService.filteredData?.length; i++) {
      total = total + (type=='fx' ? 
        this.fixFx(
          this.tableService.filteredData[i][field], 
          this.tableService.filteredData[i][fx], 
          this.tableService.filteredData[i][cur]
        ) 
        : +this.tableService.filteredData[i][field]) * (base=='' ? 1 : +this.tableService.filteredData[i][base]);
    }
    if (pipe=='integer') {
      return Math.round( total / (base=='' ? this.calCount(field) : this.calTotal(base) ));
    } else {
      return total / (base=='' ? this.calCount(field) : this.calTotal(base) );
    }
  }

  fixFx(value: number, fx: number, currency: string) {
    return this.tableService.fixFx(value, fx, currency);
  }

  onCurSelect(currency) {
    this.tableService.currency = currency;
  }

  batchPanel() {
    this.tableService.clickedIndex[this.tablename.toLowerCase()] = null;
    this.tableService.bpanel = !this.tableService.bpanel;
  }

  clear(table: any) {
    table.clear();
    table.filter();
    this.tableService.selectedRows = null;
    this.tableService.checkBoxes = false;
    this.searchtext = '';
    table.filterGlobal(this.searchtext, 'contains');
    this.tableService.clickedId[this.tableService.allData[this.tablename.toLowerCase()]['key']] = null;
  }

  onFilter(event, dt) { 
    this.tableService.filteredData = event.filteredValue;
  }

  isSelected(idd) {
    if (this.tableService.clickedId[this.tableService.allData[this.tablename.toLowerCase()]['key']]==undefined) {
      return false;
    }
    return this.tableService.clickedId[this.tableService.allData[this.tablename.toLowerCase()]['key']] == idd;
  }

  changeTable(tablename) {
    var t = {'tablename': tablename.toLowerCase()};
    this.tableService.reset(t['tablename']);
    this.setParams(t);
  }

  async buttonAction(action,id) {  
    var p = {
      "season": this.loginService.season,
      "id": id
    }
    const response = await this.loginService.loadData(action, p);
    this.loginService.toast('Pallet Status Updated', response.message);
    if (response['action']=='updateRow') {
      this.tableService.updateRow(this.tablename.toLowerCase(), response.id, response.changes);
    }
  }

  async reloadTable(event) {
    var t = this.tablename.toLowerCase();
    this.tableService.reset(this.tablename.toLowerCase());
    this.clear(this.dt1);
    const refresh = await this.tableService.loadData(t);
    this.tableService[this.tablename.toLowerCase()] = await [...this.tableService[this.tablename.toLowerCase()]];
    this.tableService.filteredData = await [...this.tableService.filteredData];
  }

}