<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>

	<div *ngIf="isAuthenticated()">
		<br>
		<div [ngClass]="logInState">
			<div [ngClass]="classState">
				<div class="my-4 col">
					<h2>Trace Farms from Stock</h2>
				</div>
				
				<div class="row justify-content-md mx-1">	
					<div class="col-12 col-md-12 col-lg-8">
						<div class="form-container">	
							<mat-form-field>
						    <mat-select placeholder="Select Order Reference" [(ngModel)]="this.traceabilityService.orderref">
						      <mat-option (click)="loadForLogging()" value="inventory">* Current Inventory *</mat-option>
						      <mat-option *ngFor="let order of this.dropDownDataService.dropdown.traceOrderList" (click)="loadForLogging()" value="{{ order.orderref }}">{{ order.orderref }}</mat-option>
						    </mat-select>
						  </mat-form-field>
						</div>
					</div>

					<div class="my-4 col-12 col-md-12 col-lg-8" [ngClass]="showTrace">

						<table class="my-4 table" *ngIf="this.traceabilityService.orderref!='inventory' && this.traceabilityService.dataStore?.buyer!= undefined">	
							<h2>Buyer</h2>
							<tbody>	
								<tr class="tableseperator"><td></td><td></td></tr>
								<tr>
									<td>Company</td>
									<td>{{ this.traceabilityService.dataStore?.buyer.companyname }}</td>
								</tr>
								<tr>
									<td>Contact</td>
									<td>{{ this.traceabilityService.dataStore?.buyer.name }}</td>
								</tr>
								<tr>
									<td>Telephone</td>
									<td><a href="tel:{{ this.traceabilityService.dataStore?.buyer.tel }}">{{ this.traceabilityService.dataStore?.buyer.tel }}</a></td>
								</tr>
								<tr>
									<td>Email</td>
									<td><a href="mailto:{{ this.traceabilityService.dataStore?.buyer.email }}">{{ this.traceabilityService.dataStore?.buyer.email }}</a></td>
								</tr>
								<tr class="tableseperator"><td></td><td></td><td></td></tr>
							</tbody>
						</table>
						<br>

						<table class="my-4 table">	
							<h2>Styles</h2>
							<tbody>
								<tr class="header">
									<td>Style</td>
									<td>Variety</td>
									<td>Total</td>
								</tr>
								<tr class="tableseperator"><td></td><td></td><td></td></tr>
								<tr *ngFor="let style of this.traceabilityService.dataStore?.styles" (click)="this.traceabilityService.styleSelected(style)">
									<td>{{ style.style }}</td>
									<td>{{ (style.variety=="I" ? "Integ" : "Hybrid") }}</td>
									<td>{{ style.total }}</td>
								</tr>
								<tr class="tableseperator"><td></td><td></td><td></td></tr>
							</tbody>
						</table>
						<br>

						<ng-container *ngIf="this.traceabilityService.selectedStyle!==undefined">

							<div class="row justify-content-between">
								<div class="col-xs-6 col-9">
									<h2>Batches</h2>
								</div>
								<div class="col-auto">
									<button mdbBtn class="btn btn-info" (click)="csv('batches')">CSV</button>
								</div>
							</div>
							<table class="my-4 table" id="batches" *ngIf="this.traceabilityService.selectedStyle!==undefined">
								<tbody>
									<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
									<tr class="header">
										<td>Style</td>
										<td>Silo</td>
										<td>Label</td>
										<td>Quantity</td>
										<td>Batch</td>
										<td>First Delivery</td>
										<td>Last Delivery</td>
									</tr>
									<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
									<ng-container *ngFor="let batch of this.traceabilityService.dataStore?.batches; let i = index">
										<tr *ngIf="batch.style==this.traceabilityService.selectedStyle" (click)="this.traceabilityService.batchSelected(batch.batch)">
											<td>{{ batch.style }}</td>
											<td>{{ batch.silo | uppercase }}</td>
											<td>{{ batch.label }}</td>
											<td>{{ batch.total }}</td>
											<td>{{ batch.batch }}</td>
											<td>{{ batch.intakestart | date:'MMM d, y' }}</td>
											<td>{{ batch.intakeend | date:'MMM d, y' }}</td>
										</tr>
									</ng-container>
									<tr class="tableseperator"><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
								</tbody>
							</table>
							<br>
							
							<ng-container *ngIf="this.traceabilityService.batch!==undefined">
								<h2>Farmers for Batch: {{ this.traceabilityService.batch }}</h2>
								<table class="my-4 table">	
									<tbody>
										<tr class="header">
											<td>Producer</td>
											<td>Associated Deliveries</td>
										</tr>
										<tr class="tableseperator"><td></td><td></td></tr>
										<tr *ngFor="let farmer of this.traceabilityService.farmers">
											<td>{{ farmer.companyname }}</td>
											<td>{{ farmer.deliveries }}</td>
										</tr>
										<tr class="tableseperator"><td></td><td></td></tr>
									</tbody>
								</table>
							</ng-container>

						</ng-container>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>