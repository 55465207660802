import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';

import { TeamLoginService } from '../../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../../logging/dropdown.service';
import { SettingsClientsService } from '../clients.service';


@Component({
  selector: 'app-settings-clients',
  templateUrl: './clients-new.component.html',
  styleUrls: ['./clients-new.component.scss']
})
export class SettingsClientsNewComponent implements OnInit {
  memberSelect;
  clickedId;
  dataStore;
  d: Date;
  scroll: boolean = true;
  isLoadingResults = true;

  constructor(
    public loginService: TeamLoginService,
  	public clientsService: SettingsClientsService,
    public dropDownDataService: DropDownDataService,
    private http: HttpClient
  	) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.clientsService.idcontact = 0;
    this.clientsService.resetForm();
    this.dropDownDataService.getDropdown("producerList");
    this.dropDownDataService.getDropdown("contactList");
  }

  ngOnDestroy() {
    this.clientsService.idMembership = null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  multipleMembers() {
    return +this.loginService.memberships.length > 1;
  }

  clickedIndexNull() {
    return this.clientsService.clickedIndex !== null;
  }

  canSave() {
    return (this.clientsService.invitations.length>0 ? true : false);
  }

  async onSave() {
    const res = await this.loginService.confirm("Are you sure you want to send portal invitations to these contacts?");
    if (res) {
      this.clientsService.saveClientMembers();
      this.clientsService.resetForm();
      this.loginService.toast("Invitations Successful","Sent!");
    } else {
      this.loginService.toast("Invitations Unsuccessful","No invitations sent",'warn');
    }
  }

}