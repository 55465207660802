import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { CountryService } from '../../../services/countries.service';
import { Order } from '../../../interfaces/orders.interface';
import { OrderItem, Consignee, FeeItem } from './add-order.interface';
import { Contacts } from '../../../interfaces/deliveries.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class AddOrderService{
	private postBody: {};
	httpRes: any;
	d: Date = new Date();
	public editmode: boolean = false;
	public season: any;
	public orderref: string;
	public editorderref: string = "";
	public ordernumber: string;
	public orderstatus: string = 'confirmed';
	public idbuying: number;
	public idbuyingTemp: number;
	public loadedEntry: Order;
	public buyer: string;
	public orderdate: string;
	public dispatchdate: string;
	public currency: string = "USD";
	public depositpaid: string = "0";
	public note: string; 
	public items: OrderItem[] = [
  	{"style": "", "variety": "A", "minskr": 0, "maxuskr": 0, "cultivars": [{"idcultivar": "47"}] }
	];
	public fees:FeeItem[] = [];
	public contents: string = "kernel"; 
	public shippingDetails: boolean = (this.orderstatus=='dispatched' || this.orderstatus=='completed' ? true : false);
	public thisCompanyContacts: Contacts[];
	public thisCompanyContact: string;
	public thisCompanyEmail: string;
	public thisCompanyTel: string;
	public freightco: string; 
	public thisFreightContacts: Contacts[];
	public thisFreightContact: string;
	public thisFreightEmail: string;
	public thisFreightTel: string;
	public consigneeList: Consignee [] = [];
	public consigneeco: string; 
	public thisConsigneeContacts: Contacts[];
	public thisConsigneeContact: string;
	public thisConsigneeEmail: string;
	public thisConsigneeTel: string;
	public notifyList: Consignee [] = [];
	public notifyco: string;
	public thisNotifyContacts: Contacts[];
	public thisNotifyContact: string;
	public thisNotifyEmail: string;
	public thisNotifyTel: string;
	public logisticsco: string;

	public instructiondate: string;
	public bookingref: string;
	public letterofcredit: string;
	public carrier: string;
	public vesselname: string;
	public vesselno: string;
	public billofladingno: string;
	public incoterm: string;
	public paymentterms: string = '100% to be paid against scanned copies of Original Documents';
	public shiprelease: string;
	public bankaccount: string;
	public containertype: string;
	public containerno: string;
	public sealno: string;
	public truckreg: string;
	public phostoxin: string;
	public hts: string = "0802.62.0000";
	public stackingdatefrom: string;
	public stackingdateto: string;
	public etd: string;
	public eta: string;
	public exploadingdate: string;

	public vatinvoice: boolean = false;
	public vatinvoice_date: Date = this.d;
	public packlist: boolean = false;
	public packlist_date: Date = this.d;
	public ppecb: boolean = false;
	public ppecb_date: Date = this.d;
	public phyto: boolean = false;
	public phyto_date: Date = this.d;
	public micro: boolean = false;
	public micro_date: Date = this.d;
	public certoforigin: boolean = false;
	public certoforigin_date: Date = this.d;
	public certofanalysis: boolean = false;
	public certofanalysis_date: Date = this.d;
	public fumigation: boolean = false;
	public fumigation_date: Date = this.d;
	public billofladingdraft: boolean = false;
	public billofladingdraft_date: Date = this.d;
	public billoflading: boolean = false;
	public billoflading_date: Date = this.d;
	public isf: boolean = false;
	public isf_date: Date = this.d;
	public eur1: boolean = false;
	public eur1_date: Date = this.d;
	public solas: boolean = false;
	public solas_date: Date = this.d;
	public seawaybill: boolean = false;
	public seawaybill_date: Date = this.d;
	public exportcert: boolean = false;
	public exportcert_date: Date = this.d;
	public telexrelease: boolean = false;
	public telexrelease_date: Date = this.d;
	public docscouriered: boolean = false;
	public courier: string;
	public trackingno: string;
	public couriereddate: string;

	constructor(
		private http: HttpClient,
		private lS: TeamLoginService,
		private dDDS: DropDownDataService,
    private cS: CountryService) {}
	
	addItem() {
		this.items.push({
			"style": "",
		 	"variety": "A",
		 	"quantity": null,
		 	"price": null,
		 	"minskr": 0,
		 	"maxuskr": 0,
		 	"cultivars": [{"idcultivar": "47"}]
		 });
	}

	removeItem(index: number) {
		this.items.splice(index, 1);
	}

	addFee() {
		this.fees.push({
			"fee": "",
		 	"amount": "0",
		 	"currency": "USD",
		 	"vatable": true,
		 	"vatrate": "0.15"
		 });
	}

	removeFee(index: number) {
		this.fees.splice(index, 1);
	}

	addOrder() {
		this.fixDates();
		this.postBody = {
			"name": "addOrder",
			"param":{
				"season": this.season,
				"orderref": this.orderref,
				"ordernumber": this.ordernumber,
				"buyer": this.buyer,
				"contact": this.thisCompanyContact,
				"orderdate": this.orderdate,
				"dispatchdate": this.dispatchdate,
				"orderstatus": this.orderstatus,
				"currency": this.currency,
				"items": this.items,
				"fees": this.fees,
				"depositpaid": this.depositpaid,
				"notes": (this.note  == null ? "" : this.note),
				"freightco": this.freightco,
				"freightcontact": this.thisFreightContact,
				"consigneeco": this.consigneeco,
				"consigneecontact": this.thisConsigneeContact,
				"notifyco": this.notifyco,
				"notifycontact": this.thisNotifyContact,
				"logisticsco": this.logisticsco,
				"instructiondate": this.instructiondate,
				"bookingref": this.bookingref,
				"letterofcredit": this.letterofcredit,
				"carrier": this.carrier,
				"vesselname": this.vesselname,
				"vesselno": this.vesselno,
				"billofladingno": this.billofladingno,
				"incoterm": this.incoterm,
				"paymentterms": this.paymentterms,
				"shiprelease": this.shiprelease,
				"bankaccount": this.bankaccount,
				"containertype": this.containertype,
				"containerno": this.containerno,
				"sealno": this.sealno,
				"truckreg": this.truckreg,
				"phostoxin": this.phostoxin,
				"hts": this.hts,
				"stackingdatefrom": this.stackingdatefrom,
				"stackingdateto": this.stackingdateto,
				"etd": this.etd,
				"eta": this.eta,
				"exploadingdate": this.exploadingdate,
				"vatinvoice": (this.vatinvoice ? 1 : 0),
				"vatinvoice_date": this.vatinvoice_date,
				"packlist": (this.packlist ? 1 : 0),
				"packlist_date": this.packlist_date,
				"ppecb": (this.ppecb ? 1 : 0),
				"ppecb_date": this.ppecb_date,
				"phyto": (this.phyto ? 1 : 0),
				"phyto_date": this.phyto_date,
				"micro": (this.micro ? 1 : 0),
				"micro_date": this.micro_date,
				"certoforigin": (this.certoforigin ? 1 : 0),
				"certoforigin_date": this.certoforigin_date,
				"certofanalysis": (this.certofanalysis ? 1 : 0),
				"certofanalysis_date": this.certofanalysis_date,
				"fumigation": (this.fumigation ? 1 : 0),
				"fumigation_date": this.fumigation_date,
				"billofladingdraft": (this.billofladingdraft ? 1 : 0),
				"billofladingdraft_date": this.billofladingdraft_date,
				"billoflading": (this.billoflading ? 1 : 0),
				"billoflading_date": this.billoflading_date,
				"isf": (this.isf ? 1 : 0),
				"isf_date": this.isf_date,
				"eur1": (this.eur1 ? 1 : 0),
				"eur1_date": this.eur1_date,
				"solas": (this.solas ? 1 : 0),
				"solas_date": this.solas_date,
				"telexrelease": (this.telexrelease ? 1 : 0),
				"telexrelease_date": this.telexrelease_date,
				"seawaybill": (this.seawaybill ? 1 : 0),
				"seawaybill_date": this.seawaybill_date,
				"exportcert": (this.exportcert ? 1 : 0),
				"exportcert_date": this.exportcert_date,
				"docscouriered": (this.docscouriered ? 1 : 0),
				"courier": this.courier,
				"trackingno": this.trackingno,
				"couriereddate": this.couriereddate,
				"delcountry": (+this.cS.delcountry.id > 0 ? (this.cS.countries[this.cS.delcountry.id]['country'] == null ? "" : this.cS.countries[this.cS.delcountry.id]['country']) : ''),
				"delport": this.cS.port,
				"expcountry": (+this.cS.expcountry.id > 0 ? (this.cS.countries[this.cS.expcountry.id]['country'] == null ? "" : this.cS.countries[this.cS.expcountry.id]['country']) : ''),
				"expport": this.cS.expport
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			})
			.subscribe(
        (response) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Save Successful",'Order "' + this.orderref + '" was successfully saved.');
	        	this.resetForm(false);
	        } catch(e) {
	        	this.lS.toast("Save Unsuccessful","Order not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}
	
	loadForEdit() {
		this.postBody = {
			"name": "loadOrder",
			"param":{
				"orderref": this.editorderref
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			})
			.subscribe(
        (response: any) => {
        	this.lS.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.resetForm(true);
		this.loadedEntry = rawData.response.result.reply;
		this.season = this.loadedEntry[0]['season'].toString();
		this.orderref = this.loadedEntry[0]['orderref'];
		this.ordernumber = this.loadedEntry[0]['ordernumber'];
		this.buyer = this.loadedEntry[0]['idbuyer'].toString();
		if (+this.buyer > 0) {
			this.updateContacts(this.buyer,'buyer');
			this.thisCompanyContact = this.loadedEntry[0]['idcontact'].toString();
			this.updateContact(this.thisCompanyContact,'buyer');
		}
		this.orderdate = this.loadedEntry[0]['orderdate'];
		this.dispatchdate = this.loadedEntry[0]['dispatchdate'];
		this.orderstatus = this.loadedEntry[0]['orderstatus'];
		this.currency = this.loadedEntry[0]['currency'];
		this.note = this.loadedEntry['notes'];
		for (var i = 0; i < rawData.response.result.reply.length; i++) {
			for (var j = 0; j < this.dDDS.dropdown['productList'].length; j++) {
				if (this.dDDS.dropdown['productList'][j]['abbr']==this.loadedEntry[i]['style'].toUpperCase()) {
					var index = j;
					var abbr = this.dDDS.dropdown['productList'][j]['abbr'];
					var type = this.dDDS.dropdown['productList'][j]['type'];
				}
			}
			var cultivars = (this.loadedEntry[i]['cultivars'].length==0 ? [{"idcultivar": "47"}] : this.loadedEntry[i]['cultivars']);
			for (var j=0; j < cultivars.length; j++) {
				cultivars[j]['idcultivar'] = cultivars[j]['idcultivar'].toString();
			}
			this.items.push({
				"index": index.toString(),
				"abbr": abbr,
				"type": type,
				"style": this.loadedEntry[i]['style'].toUpperCase(),
			 	"variety": this.loadedEntry[i]['variety'].toUpperCase(),
				"price": this.loadedEntry[i]['price'],
				"quantity": +this.loadedEntry[i]['quantity'],
				"minsize": +this.loadedEntry[i]['minsize'],
				"maxsize": +this.loadedEntry[i]['maxsize'],
				"minskr": +this.loadedEntry[i]['minskr'],
				"maxuskr": +this.loadedEntry[i]['maxuskr'],
				"cultivars": cultivars
			 });
		}
		this.fees = (this.loadedEntry[0]['fees'].length==0 ? [] : this.loadedEntry[0]['fees']);
		for (var i=0; i<this.fees.length; i++) {
			this.fees[i]['vatable'] = this.fees[i]['vaton']==1;
		}
		this.depositpaid = this.loadedEntry[0]['depositpaid'].toString();
		this.logisticsco = this.loadedEntry[0]['logisticsco'].toString();
		this.instructiondate = this.loadedEntry[0]['instructiondate'];
		this.bookingref = this.loadedEntry[0]['bookingref'];
		this.letterofcredit = this.loadedEntry[0]['letterofcredit'];
		this.carrier = this.loadedEntry[0]['carrier'];
		this.vesselname = this.loadedEntry[0]['vesselname'];
		this.vesselno = this.loadedEntry[0]['vesselno'];
		this.billofladingno = this.loadedEntry[0]['billofladingno'];
		this.incoterm = this.loadedEntry[0]['incoterm'];
		this.paymentterms = this.loadedEntry[0]['paymentterms'];
		this.shiprelease = this.loadedEntry[0]['shiprelease'];		
		this.bankaccount = this.loadedEntry[0]['bankaccount'].toString();
		this.containertype = this.loadedEntry[0]['containertype'];
		this.containerno = this.loadedEntry[0]['containerno'];
		this.sealno = this.loadedEntry[0]['sealno'];
		this.truckreg = this.loadedEntry[0]['truckreg'];
		this.phostoxin = this.loadedEntry[0]['phostoxin'];
		this.hts = this.loadedEntry[0]['hts'];
		this.stackingdatefrom = this.loadedEntry[0]['stackingdatefrom'];
		this.stackingdateto = this.loadedEntry[0]['stackingdateto'];
		this.etd = this.loadedEntry[0]['etd'];
		this.eta = this.loadedEntry[0]['eta'];
		this.exploadingdate = this.loadedEntry[0]['exploadingdate'];
		this.vatinvoice = (+this.loadedEntry[0]['vatinvoice'] == 1 ? true : false );
		this.vatinvoice_date = this.loadedEntry[0]['vatinvoice_date'];
		this.packlist = (+this.loadedEntry[0]['packlist'] == 1 ? true : false );
		this.packlist_date = this.loadedEntry[0]['packlist_date'];
		this.ppecb = (+this.loadedEntry[0]['ppecb'] == 1 ? true : false );
		this.ppecb_date = this.loadedEntry[0]['ppecb_date'];
		this.phyto = (+this.loadedEntry[0]['phyto'] == 1 ? true : false );
		this.phyto_date = this.loadedEntry[0]['phyto_date'];
		this.micro = (+this.loadedEntry[0]['micro'] == 1 ? true : false );
		this.micro_date = this.loadedEntry[0]['micro_date'];
		this.certoforigin = (+this.loadedEntry[0]['certoforigin'] == 1 ? true : false );
		this.certoforigin_date = this.loadedEntry[0]['certoforigin_date'];
		this.certofanalysis = (+this.loadedEntry[0]['certofanalysis'] == 1 ? true : false );
		this.certofanalysis_date = this.loadedEntry[0]['certofanalysis_date'];
		this.fumigation = (+this.loadedEntry[0]['fumigation'] == 1 ? true : false );
		this.fumigation_date = this.loadedEntry[0]['fumigation_date'];
		this.billofladingdraft = (+this.loadedEntry[0]['billofladingdraft'] == 1 ? true : false );
		this.billofladingdraft_date = this.loadedEntry[0]['billofladingdraft_date'];
		this.billoflading = (+this.loadedEntry[0]['billoflading'] == 1 ? true : false );
		this.billoflading_date = this.loadedEntry[0]['billoflading_date'];
		this.isf = (+this.loadedEntry[0]['isf'] == 1 ? true : false );
		this.isf_date = this.loadedEntry[0]['isf_date'];
		this.eur1 = (+this.loadedEntry[0]['eur1'] == 1 ? true : false );
		this.eur1_date = this.loadedEntry[0]['eur1_date'];
		this.solas = (+this.loadedEntry[0]['solas'] == 1 ? true : false );
		this.solas_date = this.loadedEntry[0]['solas_date'];
		this.telexrelease = (+this.loadedEntry[0]['telexrelease'] == 1 ? true : false );
		this.telexrelease_date = this.loadedEntry[0]['telexrelease_date'];
		this.seawaybill = (+this.loadedEntry[0]['seawaybill'] == 1 ? true : false );
		this.seawaybill_date = this.loadedEntry[0]['seawaybill_date'];
		this.exportcert = (+this.loadedEntry[0]['exportcert'] == 1 ? true : false );
		this.exportcert_date = this.loadedEntry[0]['exportcert_date'];
		this.docscouriered = (+this.loadedEntry[0]['docscouriered'] == 1 ? true : false );
		this.courier = this.loadedEntry[0]['courier'].toString();
		this.trackingno = this.loadedEntry[0]['trackingno'];
		this.couriereddate = this.loadedEntry[0]['couriereddate'];
    this.shippingDetails = (this.orderstatus=='dispatched' || 
    	this.orderstatus=='completed' || 
    	this.orderstatus=='paid' || 
    	this.vatinvoice ||
			this.packlist ||
			this.ppecb ||
			this.phyto ||
			this.micro ||
			this.certoforigin ||
			this.certofanalysis ||
			this.fumigation ||
			this.billofladingdraft ||
			this.billoflading ||
			this.isf ||
			this.eur1 ||
			this.solas ||
			this.telexrelease ||
			this.seawaybill ||
			this.exportcert
    	 ? true : false);

		this.cS.delcountry['id'] = this.cS.getCountryId(this.loadedEntry[0]['delcountry']);
		this.cS.expcountry['id'] = this.cS.getCountryId(this.loadedEntry[0]['expcountry']);
		if (+this.cS.delcountry['id'] > 0) {
			this.cS.setPort(this.cS.delcountry['id']);
			this.cS.setPort(this.cS.expcountry['id'], 'export');
			this.cS.port = this.loadedEntry[0]['delport'];
			this.cS.expport = this.loadedEntry[0]['expport'];
		}		
		this.freightco = this.loadedEntry[0]['freightco'].toString();
		if (+this.freightco > 0) {
			this.updateContacts(this.freightco,'freight');
			this.thisFreightContact = this.loadedEntry[0]['freightcontact'].toString();
			this.updateContact(this.thisFreightContact,'freight');
		}
		this.consigneeco = this.loadedEntry[0]['consigneeco'].toString();
		if (+this.consigneeco > 0) {
			this.updateContacts(this.consigneeco,'consignee');
			this.thisConsigneeContact = this.loadedEntry[0]['consigneecontact'].toString();
			this.updateContact(this.thisConsigneeContact,'consignee');
		}
		this.notifyco = this.loadedEntry[0]['notifyco'].toString();
		if (+this.notifyco > 0) {
			this.updateContacts(this.notifyco,'notify');
			this.thisNotifyContact = this.loadedEntry[0]['notifycontact'].toString();
			this.updateContact(this.thisNotifyContact,'notify');
		}
		this.setS();
	}

	saveChanges() {
		var tempref = this.orderref;
		var tempeditref = this.editorderref;
		if (tempref !== tempeditref) {
			this.lS.toast("Please Note","If this order has stock assigned to it, changing the order name will take time to save fully. Please be patient.",'info',6000);
		}
		this.fixDates();
		this.postBody = {
			"name": "updateOrder",
			"param":{
				"season": +this.season,
				"editorderref": this.editorderref,
				"orderref": this.orderref,
				"ordernumber": this.ordernumber,
				"buyer": this.buyer,
				"contact": this.thisCompanyContact,
				"orderdate": this.orderdate,
				"dispatchdate": this.dispatchdate,
				"orderstatus": this.orderstatus,
				"currency": this.currency,
				"items": this.items,
				"fees": this.fees,
				"depositpaid": this.depositpaid,
				"notes": (this.note == null ? "" : this.note),
				"freightco": this.freightco,
				"freightcontact": this.thisFreightContact,
				"consigneeco": this.consigneeco,
				"consigneecontact": this.thisConsigneeContact,
				"notifyco": this.notifyco,
				"notifycontact": this.thisNotifyContact,
				"logisticsco": this.logisticsco,
				"instructiondate": this.instructiondate,
				"bookingref": this.bookingref,
				"letterofcredit": this.letterofcredit,
				"carrier": this.carrier,
				"vesselname": this.vesselname,
				"vesselno": this.vesselno,
				"billofladingno": this.billofladingno,
				"incoterm": this.incoterm,
				"paymentterms": this.paymentterms,
				"shiprelease": this.shiprelease,
				"bankaccount": this.bankaccount,
				"containertype": this.containertype,
				"containerno": this.containerno,
				"sealno": this.sealno,
				"truckreg": this.truckreg,
				"phostoxin": this.phostoxin,
				"hts": this.hts,
				"stackingdatefrom": this.stackingdatefrom,
				"stackingdateto": this.stackingdateto,
				"etd": this.etd,
				"eta": this.eta,
				"exploadingdate": this.exploadingdate,
				"vatinvoice": (this.vatinvoice ? 1 : 0),
				"vatinvoice_date": this.vatinvoice_date,
				"packlist": (this.packlist ? 1 : 0),
				"packlist_date": this.packlist_date,
				"ppecb": (this.ppecb ? 1 : 0),
				"ppecb_date": this.ppecb_date,
				"phyto": (this.phyto ? 1 : 0),
				"phyto_date": this.phyto_date,
				"micro": (this.micro ? 1 : 0),
				"micro_date": this.micro_date,
				"certoforigin": (this.certoforigin ? 1 : 0),
				"certoforigin_date": this.certoforigin_date,
				"certofanalysis": (this.certofanalysis ? 1 : 0),
				"certofanalysis_date": this.certofanalysis_date,
				"fumigation": (this.fumigation ? 1 : 0),
				"fumigation_date": this.fumigation_date,
				"billofladingdraft": (this.billofladingdraft ? 1 : 0),
				"billofladingdraft_date": this.billofladingdraft_date,
				"billoflading": (this.billoflading ? 1 : 0),
				"billoflading_date": this.billoflading_date,
				"isf": (this.isf ? 1 : 0),
				"isf_date": this.isf_date,
				"eur1": (this.eur1 ? 1 : 0),
				"eur1_date": this.eur1_date,
				"solas": (this.solas ? 1 : 0),
				"solas_date": this.solas_date,
				"telexrelease": (this.telexrelease ? 1 : 0),
				"telexrelease_date": this.telexrelease_date,
				"seawaybill": (this.seawaybill ? 1 : 0),
				"seawaybill_date": this.seawaybill_date,
				"exportcert": (this.exportcert ? 1 : 0),
				"exportcert_date": this.exportcert_date,
				"docscouriered": (this.docscouriered ? 1 : 0),
				"courier": this.courier,
				"trackingno": this.trackingno,
				"couriereddate": this.couriereddate,
				"delcountry": (+this.cS.delcountry.id > 0 ? (this.cS.countries[this.cS.delcountry.id]['country'] == null ? "" : this.cS.countries[this.cS.delcountry.id]['country']) : ''),
				"delport": this.cS.port,
				"expcountry": (+this.cS.expcountry.id > 0 ? (this.cS.countries[this.cS.expcountry.id]['country'] == null ? "" : this.cS.countries[this.cS.expcountry.id]['country']) : ''),
				"expport": this.cS.expport
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Update Successful","Order reference " + tempref + " (" + tempeditref + ") was successfully updated.");
	        	this.editmode=false;
	        	this.resetForm(false);
	        } catch(e) {
	        	this.lS.toast("Update Unsuccessful","Order was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() {
		var tempeditref = this.editorderref;
		this.postBody = {
			"name": "deleteOrder",
			"param":{
				"orderref": this.editorderref
			}
		};
		return this.http.post(
			this.lS.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.lS.setSession(response);
	        	this.lS.toast("Delete Successful","Order number '" + tempeditref + "' was successfully deleted.");
	        	this.editmode=false;
	        	this.resetForm(false);
	        } catch(e) {
	        	this.lS.toast("Delete Unsuccessful","Order was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  resetForm(editref: boolean) {
    window.scroll(0,0);
    this.items = (editref ? [] : [
	  	{"style": "", "variety": "A", "price": null, "quantity": null, "minskr": 0, "maxuskr": 0, "cultivars": [{"idcultivar": "47"}] }
		]);
		this.fees =[];
		this.orderref = "";
		this.season = this.lS.season;
		this.editorderref = (editref ? this.editorderref : "");
		this.ordernumber = "";
		this.buyer = "";
		this.orderdate = "";
		this.dispatchdate = "";
		this.orderstatus = "confirmed";
		this.currency = "USD";
    this.note = "";
    this.contents = "kernel";
    this.depositpaid = "0";
    this.shippingDetails = (this.orderstatus=='dispatched' || this.orderstatus=='completed' ? true : false);
		this.thisCompanyContacts = null;
		this.thisCompanyContact = null;
		this.thisCompanyEmail = null;
		this.thisCompanyTel = null;
		this.freightco = null;
		this.thisFreightContacts = null;
		this.thisFreightContact = null;
		this.thisFreightEmail = null;
		this.thisFreightTel = null;
		this.consigneeList = [];
		this.consigneeco = null;
		this.thisConsigneeContacts = null;
		this.thisConsigneeContact = null;
		this.thisConsigneeEmail = null;
		this.thisConsigneeTel = null;
		this.notifyList = [];
		this.notifyco = null;
		this.thisNotifyContacts = null;
		this.thisNotifyContact = null;
		this.thisNotifyEmail = null;
		this.thisNotifyTel = null;
		this.logisticsco = null;
		this.instructiondate = null;
		this.bookingref = null;
		this.letterofcredit = null;
		this.carrier = null;
		this.vesselname = null;
		this.vesselno = null;
		this.billofladingno = null;
		this.incoterm = null;
		this.paymentterms = '100% to be paid against scanned copies of Original Documents';
		this.shiprelease = null;
		this.bankaccount = null;
		this.containertype = null;
		this.containerno = null;
		this.sealno = null;
		this.truckreg = null;
		this.phostoxin = null;
		this.hts = "0802.62.0000";
		this.stackingdatefrom = null;
		this.stackingdateto = null;
		this.etd = null;
		this.eta = null;
		this.exploadingdate = null;
		this.vatinvoice = false;
		this.vatinvoice_date = this.d;
		this.packlist = false;
		this.packlist_date = this.d;
		this.ppecb = false;
		this.ppecb_date = this.d;
		this.phyto = false;
		this.phyto_date = this.d;
		this.micro = false;
		this.micro_date = this.d;
		this.certoforigin = false;
		this.certoforigin_date = this.d;
		this.certofanalysis = false;
		this.certofanalysis_date = this.d;
		this.fumigation = false;
		this.fumigation_date = this.d;
		this.billofladingdraft = false;
		this.billofladingdraft_date = this.d;
		this.billoflading = false;
		this.billoflading_date = this.d;
		this.isf = false;
		this.isf_date = this.d;
		this.eur1 = false;
		this.eur1_date = this.d;
		this.solas = false;
		this.solas_date = this.d;
		this.seawaybill = false;
		this.seawaybill_date = this.d;
		this.exportcert = false;
		this.exportcert_date = this.d;
		this.telexrelease = false;
		this.telexrelease_date = this.d;
		this.docscouriered = false;
		this.courier = null;
		this.trackingno = null;
		this.couriereddate = null;
		this.cS.delcountry = {"id": null};
		this.cS.expcountry = {"id": 159};
		this.cS.ports = [];
		this.cS.expports = [
   		"Durban",
			"East London",
			"Port Elizabeth",
			"Cape Town",
			"Richards Bay",
			"Ngqura"
		];
		this.cS.port = '';
		this.cS.expport = "Durban";
  }

  setS(item = null, index = null) {
  	if (item!=null && index!=null) {
	  	this.items[item]['minsize'] = this.dDDS.dropdown['productList'][index]['minsize'];
	  	this.items[item]['maxsize'] = this.dDDS.dropdown['productList'][index]['maxsize'];
	  }
  	this.contents = "kernel";
		this.hts = "0802.62.0000";
		this.items[item]['style'] = this.dDDS.dropdown['productList'][index]['style'];
		this.items[item]['abbr'] = this.dDDS.dropdown['productList'][index]['abbr'];
		this.items[item]['type'] = this.dDDS.dropdown['productList'][index]['type'];
  	for (var i = 0; i < this.items.length; i++) {
  		if (this.items[i]['style'].substr(0,1)=="N") {
  			this.hts = "0802.61.0000";
  			this.contents = "nis";
  			break;
  		}
  	}
  }

  getConsignees() {
  	this.consigneeList = this.dDDS.dropdown['consigneeList'];
  	this.notifyList = this.consigneeList;
  }

  statusChange() {
    this.shippingDetails = (this.orderstatus=='dispatched' || this.orderstatus=='completed' ? true : false);
  }

  updateContacts(company: string, companytype: string = 'buyer') {
    var list = '';
    var thisCo = '';
    switch (companytype) {
      case 'buyer':
        this.getConsignees();
        list = 'buyerList';
        thisCo = 'thisCompany';
        break;
      case 'freight':
        list = 'freightList';
        thisCo = 'thisFreight';
        break;
      case 'consignee':
        list = 'consigneeList';
        thisCo = 'thisConsignee';
        break;
      case 'notify':
        list = 'notifyList';
        thisCo = 'thisNotify';
        break;
    }
    this.dDDS.setCompanyContacts(company, list);
    this[thisCo + 'Contacts'] = this.dDDS.thisCompanyContacts;
    this[thisCo + 'Contact'] = this.dDDS.thisCompanyContacts[0]['idcontact'].toString(); 
    this[thisCo + 'Email'] = this.dDDS.thisCompanyContacts[0]['email']; 
    this[thisCo + 'Tel'] = this.dDDS.thisCompanyContacts[0]['telephone'];
  }

  updateContact(contact: string, companytype: string = 'buyer') {
    var thisCo = '';
    switch (companytype) {
      case 'buyer':
        thisCo = 'thisCompany';
        break;
      case 'freight':
        thisCo = 'thisFreight';
        break;
      case 'consignee':
        thisCo = 'thisConsignee';
        break;
      case 'notify':
        thisCo = 'thisNotify';
        break;
    }
    var i = this.dDDS.getContactIndex(contact);
    this[thisCo + 'Contact'] = this.dDDS.dropdown.contactList[i]['idcontact'].toString(); 
    this[thisCo + 'Email'] = this.dDDS.dropdown.contactList[i]['email']; 
    this[thisCo + 'Tel'] = this.dDDS.dropdown.contactList[i]['telephone'];
  }

	fixDate(field) {
		if (this[field]!==null && this[field]!=='') {
	  	this[field] = moment(this[field]).format('YYYY-MM-DD');
	  }
	}

	fixDates() {
		var dates = [
			'orderdate',
			'dispatchdate',
			'instructiondate',
			'stackingdatefrom',
			'stackingdateto',
			'etd',
			'eta',
			'exploadingdate',
			'couriereddate',
			'vatinvoice_date',
			'packlist_date',
			'ppecb_date',
			'phyto_date',
			'micro_date',
			'certoforigin_date',
			'certofanalysis_date',
			'fumigation_date',
			'billofladingdraft_date',
			'billoflading_date',
			'isf_date',
			'eur1_date',
			'solas_date',
			'telexrelease_date',
			'seawaybill_date',
			'exportcert_date',
		];
		for (var i=0; i < dates.length; i++) {
			this.fixDate(dates[i] + '');
		}
	}

  checkType(index: number,type) {
  	var ans = true;
		for (var i = 0; i < this.items.length; i++) {
			if (this.items[i]['style']==type && index!==i) {
				ans = false;
			}
		}
		return ans;
	}

  checkCult(item: number,index: number,type) {
  	var ans = true;
		for (var i = 0; i < this.items[item]['cultivars'].length; i++) {
			if (this.items[item]['cultivars'][i]['idcultivar']==type && index!==i) {
				ans = false;
			}
		}
		return ans;
	}

	toggleDoc(field) {
		if (this[field]) {
			this[field.slice(0) + '_date'] = this.d;
		}
	}

	addCultivar(item: number) {
		this.items[item]['cultivars'].push({
			"idcultivar": "",
			"cultivar": ""
		 });
	}

	removeCultivar(item: number, index: number) {
		this.items[item]['cultivars'].splice(index, 1);
	}


}