import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';
import { FilesService } from '../../../services/files.service';

import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { TableDataService } from '../../overview/table/table.service';

@Component({
  selector: 'app-vatinvoice',
  templateUrl: './vatinvoice.component.html',
  styleUrls: ['./vatinvoice.component.scss']
})
export class VATInvoiceComponent implements OnInit {
  d: Date = new Date();
  sig: any;
  sigimage: string = 'sig';

  @ViewChild("sigImage") image: ElementRef;

  constructor(
    public fS: FilesService,
  	public lS: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tS: TableDataService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
    this.fS.getSig(this.lS.httpRoot, 'team_id_token', this.sigimage);
  }

  ngOnDestroy() {
  }

  isAuthenticated() {
    return this.lS.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.lS.isAuthorised(permission);
  }

  async svgPDF() {
    // parentdiv is the html element which has to be converted to PDF
    const svgElement = document.getElementById('vatinvoice');

		// create a new jsPDF instance
		const pdfTeam = await new jsPDF('p', 'pt', 'a4');
    var savename = this.pS.portalMod()['companyname'] + ' - VAT Invoice - ' + this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref +'.pdf';
    this.setupPDF(pdfTeam, svgElement, savename);
	}

	async setupPDF(pdfTeam, svgElement, savename) {
		const pdf = await pdfTeam.svg(svgElement, {
      x:25,
      y:20,
      height:800,
      width:550
    });
    const pdfprint = await pdf.save(savename, {returnPromise: true});
	}

  transdetail(i) {
    return 'translate(0 ' + 10.2 * i + ')';
  }

  transdetails() {
    var i = this.tS['orders'][this.tS.clickedIndex['orders']]?.details.length;
    return 'translate(0 ' + 10.2 * i + ')';
  }

  transtotal(j = 0) {
    var i = j + this.tS['orders'][this.tS.clickedIndex['orders']]?.details.length + this.tS['orders'][this.tS.clickedIndex['orders']]?.fees.length;
    return 'translate(0 ' + 10.2 * i + ')';
  }

  transcontact(type, field, i = null) {
    return 'translate(0 ' + 10.2 * this.transstep(type, field, i) + ')';
  }

  transpage() {
    return 'translate(0 ' + Math.max(0, 10.2 * Math.max(this.transstep('b', 'contactshift'), this.transstep('c', 'contactshift')) - 70) + ')';
  }

  transstep(type, field, i = null) {    
    type = (type=='b' ? 'buyer' : 'consignee');
    var step = 1;
    switch (field) {
      case "contactshift":
      case "email":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'tel']==null ? 0 : 1);
      case "tel":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'con']==null ? 0 : 1);
      case "con":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'vat']==null ? 0 : 1);
      case "vat":
        step += (this.tS['orders'][this.tS.clickedIndex['orders']]?.[type + 'country']==null ? 0 : 1);
        break;
    }
    return step;
  }


}
