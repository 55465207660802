import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../table/table.service';
import { AddContactService } from '../../logging/add-contact/add-contact.service';
import { DropDownDataService } from '../../logging/dropdown.service';

@Component({
  selector: 'app-teamcontact',
  templateUrl: './teamcontact.component.html',
  styleUrls: ['./teamcontact.component.scss']
})
export class TeamContactComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
  	private loginService: TeamLoginService,
    public tableService: TableDataService,
    private router: Router,
    private dropDownDataService: DropDownDataService,
    private addContactService: AddContactService,

    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }

  editContact() {
    this.addContactService.idcontact = this.tableService['contacts'][this.tableService.clickedIndex['contacts']].idcontact;
    this.addContactService.loadForEdit();
    this.router.navigate(['/team/logging/contact']);
  }

  viewCompany(idcompany) {
    this.tableService.reset('companies');
    this.tableService.setDelivery(idcompany, 'companies');
    this.tableService.indexLink = true;
    this.router.navigate(['/team/table/companies']);
    setTimeout(function() {
      window.scrollTo(0,750);
    }, 100); 
  }


}
