import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { LoginService } from '../auth/login/login.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class ArticleService{
	private postBody: {};
	httpRes: any;

	public loadedEntry: any;
  public date: string;
	public author: string;
	public title: string;
	public body: string;
	public imageurl: any;
	public url: string;
  public note: string;

	constructor(
		private http: HttpClient,
		private loginService: LoginService,
		private sanitizer: DomSanitizer) {}

	async getDropdown(dataGroup: string) {
		this.postBody = {
			"name": dataGroup,
			"param": {
				"producer": this.loginService.selectedmember,
				"season": +this.loginService.season
			}
		};
		let response = await fetch(
			this.loginService.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('id_token')
				}
			}
		)
		return response.json();
	}

	getArticle() {
		this.getDropdown('loadLastArticle')
		  .then(response => {
		  	this.loginService.setSession(response);
				this.loadedEntry = response['response']['result']['reply'];
	  		this.loadForEdit();
	  	})
		  .catch(error => console.log(error));
	}

	loadForEdit(loadedEntry = this.loadedEntry) {
		if (loadedEntry.length>0) {
			this.date = loadedEntry[0].date;
			this.author = loadedEntry[0].author;
			this.title = loadedEntry[0].title;
			this.body = loadedEntry[0].body;
			this.imageurl = (loadedEntry[0].imageurl==null ? '' : this.sanitizer.bypassSecurityTrustStyle("url(" + loadedEntry[0].imageurl + ")"));
			this.url = loadedEntry[0].url;	
		}
	}



}