<div class="col pt-3 mx-auto" *ngIf="isAuthenticated()">
	<br>
	<div *ngIf="multipleMembers()">
		<div class="row justify-content-between">
			<div class="col row justify-content-start">
				<div class="main-header mt-2 ms-0">
					<h2 style="line-height: 1.3em">Welcome {{ this.loginService.uname }},</h2>
				</div>
				<div class="dropdown col-auto row ms-auto pe-0" dropdown>
					<div class="col my-auto pe-0">
	          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pe-0" mdbWavesEffect>
	          <h5 class="me-2" style="display: inline;">{{ this.loginService.selectedmembername }}<span class="caret"></span></h5></a>
	        </div>
          <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <button 
            	class="dropdown-item waves-light py-3" 
            	mdbWavesEffect 
            	(click)="onMemberSelected(mship['membership'], mship['name'])"
            	*ngFor='let mship of this.loginService.memberships'>{{ mship['name'] }}</button>
          </ul>
        </div>
				<br>
			</div>
		</div>
	</div>
	<div *ngIf="!multipleMembers()">
		<div class="row justify-content-between">
			<div class="col row justify-content-start">
				<div class="main-header mt-2 ms-0">
					<h2 style="line-height: 1.3em">Welcome {{ this.loginService.uname }},</h2>
				</div>
				<div class="ml-3 col-auto row ms-auto">
					<h5 class="col-auto my-auto pe-0">{{ this.loginService.selectedmembername }}</h5>
				</div>
				<br>
			</div>
		</div>
	</div>
	
	<app-logisticstable [preload]="this.invTable"></app-logisticstable>
	<!--
	<app-announcement></app-announcement>
	<app-article></app-article>
	-->
	
</div>

<div class="col pt-3 mx-auto" *ngIf="!isAuthenticated()">
	<app-logisticslogin></app-logisticslogin>
</div>