import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { Setting } from './email.interface';


import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class EmailSettingsService{
	private postBody: {};
	public loadedEntry: any;
	public settings: Setting[] = [];

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService,
		public dropDownDataService: DropDownDataService) {}

	saveEmail() {
		this.postBody = {
			"name": "saveEmailDetails",
			"param":{
				"season": this.loginService.season,
		    "settings": this.settings
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
	        	this.resetForm();
	        	this.loadEmail();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Email settings were not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEmail() { 
		this.postBody = {
			"name": "deleteEmailDetails",
			"param":{
		    "season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']['message']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Email settings were not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  loadEmail() {
		this.postBody = {
			"name": "loadEmailDetails",
			"param": {
				"season": this.loginService.season
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
					this.settings = response.response.result.reply;
        },
        (error) => console.log(error)
      );
	}

  resetForm() {
    window.scroll(0,0);
    this.settings = [];
  }



}