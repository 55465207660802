<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.aOS.editmode">Add Order</h2>
				<h2 *ngIf="this.aOS.editmode">Edit Order</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.aOS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/orders">View Orders</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Order</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.aOS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/orders">View Orders</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Order</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">	
			  
			  <mat-form-field *ngIf="this.aOS.editmode">
    			<mat-label>{{ this.pS.portalMod()['portal'] }} Reference</mat-label>
			    <mat-select placeholder="{{ this.pS.portalMod()['portal'] }} Reference" [(ngModel)]="this.aOS.editorderref" (keydown.tab)="loadForEdit()">
			      <mat-option *ngFor="let order of this.dDDS.dropdown.orderList" #prod (click)="loadForEdit()" [value]="order.orderref">{{ order.orderref }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<br *ngIf="this.aOS.editmode">
			  
			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Season</mat-label>
			    <mat-select placeholder="Season" [(ngModel)]="this.aOS.season">
			      <mat-option *ngFor="let season of this.lS.seasons" value="{{ +season }}">{{ season }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <form [formGroup]="form" *ngIf="editSelected()">
			    <mat-form-field >
			      <mat-label>{{ pS.portalMod().portal }} Reference</mat-label>
			      <input matInput placeholder="{{ pS.portalMod().portal }} Reference" [(ngModel)]="this.aOS.orderref" maxlength="45" formControlName="orderref">
			      <mat-error *ngIf="form.get('orderref').errors?.forbiddenString">This order reference already exists</mat-error>
			      <mat-error *ngIf="form.get('orderref').errors?.required">Order reference required</mat-error>
			    </mat-form-field>
			  </form>
<!--
				<mat-form-field *ngIf="editSelected()">
    			<mat-label>{{ this.pS.portalMod()['portal'] }} Reference</mat-label>
			    <input matInput placeholder="{{ this.pS.portalMod()['portal'] }} Reference" maxlength="45" [(ngModel)]="this.aOS.orderref">
			  </mat-form-field>
-->
				<mat-form-field *ngIf="editSelected()">
    			<mat-label>Customer PO</mat-label>
			    <input matInput placeholder="Customer PO" maxlength="45" [(ngModel)]="this.aOS.ordernumber">
			  </mat-form-field>

	      <hr *ngIf="editSelected()">

	      <mat-form-field *ngIf="editSelected()">
    			<mat-label>Buyer</mat-label>
			    <mat-select placeholder="Buyer" [(ngModel)]="this.aOS.buyer" (keydown.tab)="this.aOS.updateContacts(this.aOS.buyer)">
			      <mat-option *ngFor="let buyer of this.dDDS.dropdown.buyerList" #buy (click)="this.aOS.updateContacts(buy.value)" value="{{ buyer.idcompany }}">{{ buyer.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <ng-container *ngIf="this.aOS.buyer!=null && editSelected()">

				  <mat-form-field *ngIf="this.aOS.editmode">
    				<mat-label>Contact Name</mat-label>
				    <mat-select placeholder="Contact Name" [(ngModel)]="this.aOS.thisCompanyContact">
				      <mat-option *ngFor="let contact of this.aOS.thisCompanyContacts" #buyerContact (click)="this.aOS.updateContact(buyerContact.value,'buyer')" value="{{contact?.idcontact}}">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="!this.aOS.editmode">
    				<mat-label>Contact Name</mat-label>
				    <mat-select placeholder="Contact Name" [(ngModel)]="this.aOS.thisCompanyContact">
				      <mat-option *ngFor="let contact of this.aOS.thisCompanyContacts" #buyerContact (click)="this.aOS.updateContact(buyerContact.value,'buyer')" [value]="contact.idcontact">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Email</mat-label>
				    <a href="mailto:{{this.aOS.thisCompanyEmail}}"><input matInput placeholder="Email" disabled="true" [(ngModel)]="this.aOS.thisCompanyEmail"></a>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Telephone</mat-label>
				    <a href="tel:{{this.aOS.thisCompanyTel}}"><input matInput placeholder="Telephone" disabled="true" [(ngModel)]="this.aOS.thisCompanyTel"></a>
				  </mat-form-field>
			  
			  </ng-container>

	      <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Status</mat-label>
			    <mat-select placeholder="Status" [(ngModel)]="this.aOS.orderstatus">
			      <mat-option (click)="this.aOS.statusChange()" value="negotiating">Negotiating</mat-option>
			      <mat-option (click)="this.aOS.statusChange()" value="confirmed">Confirmed</mat-option>
			      <mat-option (click)="this.aOS.statusChange()" value="dispatched">Dispatched</mat-option>
			      <mat-option (click)="this.aOS.statusChange()" value="completed">Completed - Awaiting Payment</mat-option>
			      <mat-option (click)="this.aOS.statusChange()" value="paid">Completed - Paid</mat-option>
			    </mat-select>
			  </mat-form-field>
			  
				<div class="row" *ngIf="editSelected()">
					<div class="col main-header">
				  	<mat-form-field class="w-100">
		    			<mat-label>Order Date</mat-label>
					    <input matInput [matDatepicker]="orddate" [(ngModel)]="this.aOS.orderdate">
							<mat-datepicker-toggle matIconSuffix [for]="orddate"></mat-datepicker-toggle>
					    <mat-datepicker #orddate></mat-datepicker>
					  </mat-form-field>
					</div>
					<div class="col-auto pr-0">
						<button mdbBtn class="btn btn-link" (click)="this.aOS.orderdate=null">Clear</button>
					</div>
				</div>
			  
				<div class="row" *ngIf="editSelected()">
					<div class="col main-header">
				  	<mat-form-field class="w-100">
		    			<mat-label>Dispatch Date</mat-label>
					    <input matInput [matDatepicker]="dispdate" [(ngModel)]="this.aOS.dispatchdate">
							<mat-datepicker-toggle matIconSuffix [for]="dispdate"></mat-datepicker-toggle>
					    <mat-datepicker #dispdate></mat-datepicker>
					  </mat-form-field>
					</div>
					<div class="col-auto pr-0">
						<button mdbBtn class="btn btn-link" (click)="this.aOS.dispatchdate=null">Clear</button>
					</div>
				</div>

	      <hr *ngIf="editSelected()">

	      <div *ngFor="let item of this.aOS.items; let itemindex=index">

	      	<hr *ngIf="editSelected() && itemindex>0">

					<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
						<div class="form-container">

						  <mat-form-field>
    						<mat-label>Style</mat-label>
						    <mat-select placeholder="Style" [(ngModel)]="this.aOS.items[itemindex]['index']">
						      <ng-container *ngFor="let product of this.dDDS.dropdown['productList']; let i=index;">
						      	<mat-option *ngIf="this.aOS.checkType(itemindex, product['abbr'])" (click)="this.aOS.setS(itemindex, i)" value="{{ i }}">{{ product['style'] }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>

						</div>
					</div>
						 
					<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
						<div class="form-container">

						  <mat-form-field>
    						<mat-label>Variety</mat-label>
						    <mat-select placeholder="Variety" [(ngModel)]="this.aOS.items[itemindex]['variety']">
						      <mat-option value="A">Any</mat-option>
						      <mat-option value="H">Hybrid</mat-option>
						      <mat-option value="I">Integ</mat-option>
						      <mat-option value="C">Custom Cultivars</mat-option>
						    </mat-select>
						  </mat-form-field>

						</div>
					</div>

					<ng-container *ngIf="this.aOS.items[itemindex]['variety']=='C'">
						<ng-container *ngFor="let cultivar of this.aOS.items[itemindex]['cultivars']; let cultindex=index">
							<div class="col-12" style="display: inline-block;" *ngIf="editSelected()">
								<div class="col-6 ps-0" style="display: inline-block;" *ngIf="editSelected()">
									<div class="form-container">
								
										<mat-form-field>
			  							<mat-label>Cultivar</mat-label>
									    <mat-select placeholder="Cultivar" [(ngModel)]="cultivar['idcultivar']">
									    	<ng-container *ngFor="let cult of this.dDDS.dropdown.cultivarList">
									      	<mat-option *ngIf="this.aOS.checkCult(itemindex,cultindex,cult.idcultivar)" value="{{ cult.idcultivar }}">{{ cult.cultivar }}</mat-option>
									      </ng-container>
									    </mat-select>
									  </mat-form-field>

									</div>
								</div>
								<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
									<div class="form-container">
										
										<div class="row">	  
										  <button mat-icon-button class="col" (click)="this.aOS.addCultivar(itemindex)"><i class="material-icons addbutton">add_circle_outline</i></button>
										  <button mat-icon-button class="col" *ngIf="this.aOS.items[itemindex]['cultivars'].length>1" (click)="this.aOS.removeCultivar(itemindex,cultindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
										</div>

									</div>
								</div>

							</div>
						</ng-container>
					</ng-container>

					<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
						<div class="form-container">

						  <mat-form-field>
    						<mat-label>Minimum Size (mm)</mat-label>
						    <input matInput type="number" step="1" placeholder="Min Size (mm)" [(ngModel)]="this.aOS.items[itemindex]['minsize']">
						  </mat-form-field>
						  
						  <mat-form-field *ngIf="this.aOS.items[itemindex]['type']=='nis'">
    						<mat-label>Min SKR %</mat-label>
						    <input matInput type="number" step="0.1" placeholder="Min SKR %" [(ngModel)]="this.aOS.items[itemindex]['minskr']">
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Price</mat-label>
						    <input matInput placeholder="Price" [ngModel]="+this.aOS.items[itemindex]['price'] | currency:this.aOS.currency:'symbol-narrow'" (blur)="changeAmountCur($event,'items',itemindex)">
						  </mat-form-field>

						  <mat-form-field *ngIf="this.aOS.items[itemindex]['style'].slice(0,1)!='N'">
    						<mat-label>Quantity</mat-label>
						    <input matInput type="number" step="1" placeholder="Quantity" [(ngModel)]="this.aOS.items[itemindex]['quantity']">
						  </mat-form-field>

						  <mat-form-field *ngIf="this.aOS.items[itemindex]['style'].slice(0,1)=='N'">
    						<mat-label>Weight (kg)</mat-label>
						    <input matInput type="number" step="1" placeholder="Weight (kg)" [(ngModel)]="this.aOS.items[itemindex]['quantity']">
						  </mat-form-field>

						</div>
					</div>


					<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
						<div class="form-container">
						  
						  <mat-form-field>
    						<mat-label>Maximum Size (mm)</mat-label>
						    <input matInput type="number" step="1" placeholder="Max Size (mm)" [(ngModel)]="this.aOS.items[itemindex]['maxsize']">
						  </mat-form-field>
						  
						  <mat-form-field *ngIf="this.aOS.items[itemindex]['type']=='nis'">
    						<mat-label>Max USKR %</mat-label>
						    <input matInput type="number" step="0.1" placeholder="Max USKR %" [(ngModel)]="this.aOS.items[itemindex]['maxuskr']">
						  </mat-form-field>
						  
						  <mat-form-field>
    						<mat-label>Currency</mat-label>
						    <mat-select placeholder="Currency" [(ngModel)]="this.aOS.currency">
						      <mat-option value="USD">USD</mat-option>
						      <mat-option value="ZAR">ZAR</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <div class="row">
							  <button mat-icon-button class="col" (click)="this.aOS.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
							  <button mat-icon-button class="col" *ngIf="this.aOS.items.length>1" (click)="this.aOS.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
							</div>

						</div>
					</div>

				</div>

	      <ng-container *ngIf="editSelected() && this.aOS.orderstatus!='negotiating'">

	      	<hr *ngIf="editSelected()">

					<div class="row justify-content-between">
						<div class="col">
							<h4 class="py-3">Shipping Details</h4>
						</div>
				    <mat-slide-toggle class="slider-margin slider-label mt-3 py-1" [(ngModel)]="this.aOS.shippingDetails">
				      Show Details
				    </mat-slide-toggle> 
					</div>

					<ng-container *ngIf="this.aOS.shippingDetails">

						<hr>

		      	<h4 class="py-3">Document Checklist</h4>

						<div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.vatinvoice" (click)="this.aOS.toggleDoc('vatinvoice')">
					      VAT Invoice
					    </mat-slide-toggle>

							<div *ngIf="this.aOS.vatinvoice" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="vatinvoice_date" [(ngModel)]="this.aOS.vatinvoice_date">
									<mat-datepicker-toggle matIconSuffix [for]="vatinvoice_date"></mat-datepicker-toggle>
							    <mat-datepicker #vatinvoice_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.packlist" (click)="this.aOS.toggleDoc('packlist')">
				      Packing List
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.packlist" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="packlist_date" [(ngModel)]="this.aOS.packlist_date">
									<mat-datepicker-toggle matIconSuffix [for]="packlist_date"></mat-datepicker-toggle>
							    <mat-datepicker #packlist_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div *ngIf="this.aOS.contents=='nis'" class="row justify-content-between">

					    <mat-slide-toggle class="slider-margin slider-label mt-3" [(ngModel)]="this.aOS.ppecb" (click)="this.aOS.toggleDoc('ppecb')">
					      PPECB Certificate
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.ppecb" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="ppecb_date" [(ngModel)]="this.aOS.ppecb_date">
									<mat-datepicker-toggle matIconSuffix [for]="ppecb_date"></mat-datepicker-toggle>
							    <mat-datepicker #ppecb_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div *ngIf="this.aOS.contents=='nis'" class="row justify-content-between">

					    <mat-slide-toggle class="slider-margin slider-label mt-3" [(ngModel)]="this.aOS.phyto" (click)="this.aOS.toggleDoc('phyto')">
					      Phytosanitary Certificate
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.phyto" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="phyto_date" [(ngModel)]="this.aOS.phyto_date">
									<mat-datepicker-toggle matIconSuffix [for]="phyto_date"></mat-datepicker-toggle>
							    <mat-datepicker #phyto_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.micro" (click)="this.aOS.toggleDoc('micro')">
				      Microbiological Test Certificate
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.micro" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="micro_date" [(ngModel)]="this.aOS.micro_date">
									<mat-datepicker-toggle matIconSuffix [for]="micro_date"></mat-datepicker-toggle>
							    <mat-datepicker #micro_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.certoforigin" (click)="this.aOS.toggleDoc('certoforigin')">
				      Certificate of Origin
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.certoforigin" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="certoforigin_date" [(ngModel)]="this.aOS.certoforigin_date">
									<mat-datepicker-toggle matIconSuffix [for]="certoforigin_date"></mat-datepicker-toggle>
							    <mat-datepicker #certoforigin_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.certofanalysis" (click)="this.aOS.toggleDoc('certofanalysis')">
				      Certificate of Analysis
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.certofanalysis" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="certofanalysis_date" [(ngModel)]="this.aOS.certofanalysis_date">
									<mat-datepicker-toggle matIconSuffix [for]="certofanalysis_date"></mat-datepicker-toggle>
							    <mat-datepicker #certofanalysis_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div *ngIf="this.aOS.contents=='nis'" class="row justify-content-between">

					    <mat-slide-toggle class="slider-margin slider-label mt-3" [(ngModel)]="this.aOS.fumigation" (click)="this.aOS.toggleDoc('fumigation')">
					      Fumigation Certificate
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.fumigation" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="fumigation_date" [(ngModel)]="this.aOS.fumigation_date">
									<mat-datepicker-toggle matIconSuffix [for]="fumigation_date"></mat-datepicker-toggle>
							    <mat-datepicker #fumigation_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.billofladingdraft" (click)="this.aOS.toggleDoc('billofladingdraft')">
				     		Bill of Lading - Draft
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.billofladingdraft" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="billofladingdraft_date" [(ngModel)]="this.aOS.billofladingdraft_date">
									<mat-datepicker-toggle matIconSuffix [for]="billofladingdraft_date"></mat-datepicker-toggle>
							    <mat-datepicker #billofladingdraft_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.billoflading" (click)="this.aOS.toggleDoc('billoflading')">
				      	Bill of Lading - Original
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.billoflading" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="billoflading_date" [(ngModel)]="this.aOS.billoflading_date">
									<mat-datepicker-toggle matIconSuffix [for]="billoflading_date"></mat-datepicker-toggle>
							    <mat-datepicker #billoflading_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div *ngIf="this.cS.US" class="row justify-content-between">

					    <mat-slide-toggle class="slider-margin slider-label mt-3" [(ngModel)]="this.aOS.isf" (click)="this.aOS.toggleDoc('isf')">
					      ISF (Importer Security Filing)
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.isf" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="isf_date" [(ngModel)]="this.aOS.isf_date">
									<mat-datepicker-toggle matIconSuffix [for]="isf_date"></mat-datepicker-toggle>
							    <mat-datepicker #isf_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div *ngIf="this.cS.EU" class="row justify-content-between">

					    <mat-slide-toggle class="slider-margin slider-label mt-3" [(ngModel)]="this.aOS.eur1" (click)="this.aOS.toggleDoc('eur1')">
					      EUR1 Certificate
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.eur1" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="eur1_date" [(ngModel)]="this.aOS.eur1_date">
									<mat-datepicker-toggle matIconSuffix [for]="eur1_date"></mat-datepicker-toggle>
							    <mat-datepicker #eur1_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.solas" (click)="this.aOS.toggleDoc('solas')">
				      	Weight SOLAS Certificate
					    </mat-slide-toggle> 

							<div *ngIf="this.aOS.solas" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="solas_date" [(ngModel)]="this.aOS.solas_date">
									<mat-datepicker-toggle matIconSuffix [for]="solas_date"></mat-datepicker-toggle>
							    <mat-datepicker #solas_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.telexrelease" (click)="this.aOS.toggleDoc('telexrelease')">
				      	Telex Release
					    </mat-slide-toggle>

							<div *ngIf="this.aOS.telexrelease" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="telexrelease_date" [(ngModel)]="this.aOS.telexrelease_date">
									<mat-datepicker-toggle matIconSuffix [for]="telexrelease_date"></mat-datepicker-toggle>
							    <mat-datepicker #telexrelease_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div class="row justify-content-between">

					    <mat-slide-toggle class="col slider-margin slider-label mt-3" [(ngModel)]="this.aOS.seawaybill" (click)="this.aOS.toggleDoc('seawaybill')">
				      	Seaway Bill
					    </mat-slide-toggle>

							<div *ngIf="this.aOS.seawaybill" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="seawaybill_date" [(ngModel)]="this.aOS.seawaybill_date">
									<mat-datepicker-toggle matIconSuffix [for]="seawaybill_date"></mat-datepicker-toggle>
							    <mat-datepicker #seawaybill_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <div *ngIf="this.aOS.contents=='nis'" class="row justify-content-between">

					    <mat-slide-toggle class="slider-margin slider-label mt-3" [(ngModel)]="this.aOS.exportcert" (click)="this.aOS.toggleDoc('exportcert')">
					      Export Certificate
					    </mat-slide-toggle>

							<div *ngIf="this.aOS.exportcert" class="col">
						  	<mat-form-field class="w-100">
				    			<mat-label>Date</mat-label>
							    <input matInput [matDatepicker]="exportcert_date" [(ngModel)]="this.aOS.exportcert_date">
									<mat-datepicker-toggle matIconSuffix [for]="exportcert_date"></mat-datepicker-toggle>
							    <mat-datepicker #exportcert_date></mat-datepicker>
							  </mat-form-field>
							</div>

						</div>

				    <hr>

				    <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.aOS.docscouriered">
				      Documents Couriered
				    </mat-slide-toggle>  

				    <ng-container *ngIf="this.aOS.docscouriered">

				    	<br>

				      <mat-form-field>
    						<mat-label>Courier</mat-label>
						    <mat-select placeholder="Courier" [(ngModel)]="this.aOS.courier">
						      <mat-option *ngFor="let courier of this.dDDS.dropdown.courierList" value="{{ courier.idcompany }}">{{ courier.companyname }}</mat-option>
						    </mat-select>
						  </mat-form-field>

							<mat-form-field>
    						<mat-label>Tracking Number</mat-label>
						    <input matInput placeholder="Tracking Number" [(ngModel)]="this.aOS.trackingno">
						  </mat-form-field>

							<mat-form-field>
    						<mat-label>Date Couriered</mat-label>
						    <input matInput [matDatepicker]="courdate" [(ngModel)]="this.aOS.couriereddate">
								<mat-datepicker-toggle matIconSuffix [for]="courdate"></mat-datepicker-toggle>
						    <mat-datepicker #courdate></mat-datepicker>
						  </mat-form-field>

						</ng-container>

					  <hr>

						<mat-form-field>
    					<mat-label>Dispatch Country</mat-label>
					  	<mat-select placeholder="Dispatch Country" [(value)]="this.cS.expcountry.id" (keydown.tab)="this.cS.setPort(this.cS.expcountry.id, 'export')">
					      <mat-option [value]="null" (click)="this.cS.clear('expports')">Please Select</mat-option>
					      <mat-option *ngFor="let country of this.cS.countries" [value]="country.id" (click)="this.cS.setPort(this.cS.expcountry.id, 'export')">{{ country.country }}</mat-option>
					    </mat-select>
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Dispatch Port</mat-label>
					    <mat-select placeholder="Dispatch Port" [(ngModel)]="this.cS.expport">
				      	<mat-option *ngFor="let port of this.cS.expports" value="{{ port }}">{{ port }}</mat-option>
				    </mat-select>
					  </mat-form-field>

					  <hr>

						<mat-form-field>
    					<mat-label>Destination Country</mat-label>
					  	<mat-select placeholder="Destination Country" [(value)]="this.cS.delcountry.id" (keydown.tab)="this.cS.setPort(this.cS.delcountry.id)">
					      <mat-option [value]="null" (click)="this.cS.clear('ports')">Please Select</mat-option>
					      <mat-option *ngFor="let country of this.cS.countries" [value]="country.id" (click)="this.cS.setPort(this.cS.delcountry.id)">{{ country.country }}</mat-option>
					    </mat-select>
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Destination Port</mat-label>
					    <mat-select placeholder="Destination Port" [(ngModel)]="this.cS.port">
				      	<mat-option *ngFor="let port of this.cS.ports" value="{{ port }}">{{ port }}</mat-option>
				    </mat-select>
					  </mat-form-field>

					  <hr>

						<mat-form-field>
    					<mat-label>2020 Incoterm</mat-label>
					    <mat-select placeholder="2020 Incoterm" [(ngModel)]="this.aOS.incoterm">
		            <mat-option value="EXW">EXW: EX Works</mat-option>
		            <mat-option value="FCA">FCA: Free Carrier</mat-option>
		            <mat-option value="FAS">FAS: Free Alongside Ship</mat-option>
		            <mat-option value="FOB">FOB: Free on Board</mat-option>
		            <mat-option value="CFR">CFR: Cost & Freight</mat-option>
		            <mat-option value="CIF">CIF: Cost, Insurance & Freight</mat-option>
		            <mat-option value="CPT">CPT: Cost Paid To</mat-option>
		            <mat-option value="CIP">CIP: Carrier & Insurance Paid to</mat-option>
		            <mat-option value="DPU">DPU: Delivered at Place Unloaded</mat-option>
		            <mat-option value="DAP">DAP: Delivered At Place</mat-option>
		            <mat-option value="DDP">DDP: Delivered Duty Paid</mat-option>
				    	</mat-select>
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Payment Terms</mat-label>
					    <input matInput placeholder="Payment Terms" maxlength="95" [(ngModel)]="this.aOS.paymentterms">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Shipment Release</mat-label>
					    <mat-select placeholder="Shipment Release" [(ngModel)]="this.aOS.shiprelease">
		            <mat-option value="billoflading">Original Bill of Lading</mat-option>
		            <mat-option value="waybill">Sea Waybill</mat-option>
		            <mat-option value="telex">Telex Release</mat-option>
				    	</mat-select>
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Bank Account for Payment</mat-label>
					    <mat-select placeholder="Bank Account for Payment" [(ngModel)]="this.aOS.bankaccount">
				      	<mat-option *ngFor="let account of this.dDDS.dropdown.bankAccountList" value="{{ account.idcompany }}">{{ account.bankname }} [{{ account.companyname }}: {{ account.bankaccount }}]</mat-option>
				    </mat-select>
					  </mat-form-field>

					  <hr>

						<div class="col">
							<h4 class="py-3">Deposit from Buyer</h4>
						</div>

						<div class="col" style="display: inline-block;" *ngIf="editSelected()">
							<div class="form-container">

							  <mat-form-field>
			    				<mat-label>Deposit Paid</mat-label>
			    				<input matInput placeholder="Deposit Paid" [ngModel]="+this.aOS.depositpaid | currency:this.aOS.currency:'symbol-narrow'" (blur)="changeAmountCur($event,'depositpaid')">
							  </mat-form-field>

							</div>
						</div>
						
					  <hr>

						<div class="col">
							<h4 class="py-3">Additional Fees</h4>
						</div>

					  <div class="row" *ngIf="this.aOS.fees.length==0">
						  <button mat-icon-button class="col" (click)="this.aOS.addFee()"><i class="material-icons addbutton">add_circle_outline</i></button>
						</div>

			      <div *ngFor="let item of this.aOS.fees; let feeindex=index">

			      	<hr *ngIf="editSelected() && feeindex>0">

							<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <mat-form-field>
				    				<mat-label>Fee</mat-label>
				    				<input matInput placeholder="Fee" [(ngModel)]="this.aOS.fees[feeindex]['fee']">
								  </mat-form-field>

								  <mat-form-field>
		    						<mat-label>VAT Rate</mat-label>
								    <input matInput placeholder="VAT Rate" [ngModel]="+this.aOS.fees[feeindex]['vatrate'] | percent:'1.1'" (blur)="changeAmountPct($event,'fees',feeindex)">
								  </mat-form-field>

							    <mat-slide-toggle class="slider-margin slider-label vatslider" [(ngModel)]="this.aOS.fees[feeindex]['vatable']">
							      Vat chargeable?
							    </mat-slide-toggle>  

								</div>
							</div>
								 
							<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <mat-form-field>
		    						<mat-label>Fee Amount</mat-label>
								    <input matInput placeholder="Fee Amount" [ngModel]="+this.aOS.fees[feeindex]['amount'] | currency:this.aOS.fees[feeindex]['currency']:'symbol-narrow'" (blur)="changeAmountCur($event,'fees',feeindex)">
								  </mat-form-field>

								  <mat-form-field>
		    						<mat-label>Currency</mat-label>
								    <mat-select placeholder="Currency" disabled [ngModel]="this.aOS.currency">
								      <mat-option value="USD">USD</mat-option>
								      <mat-option value="ZAR">ZAR</mat-option>
								    </mat-select>
								  </mat-form-field>

								  <div class="row">
									  <button mat-icon-button class="col" (click)="this.aOS.addFee()"><i class="material-icons addbutton">add_circle_outline</i></button>
									  <button mat-icon-button class="col" (click)="this.aOS.removeFee(feeindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
									</div>

								</div>
							</div>

						</div>

					  <hr>

					  <div class="row">
							<div class="col main-header">
						  	<mat-form-field class="w-100">
				    			<mat-label>Stacking Date From</mat-label>
							    <input matInput [matDatepicker]="stdatefrom" [(ngModel)]="this.aOS.stackingdatefrom">
									<mat-datepicker-toggle matIconSuffix [for]="stdatefrom"></mat-datepicker-toggle>
							    <mat-datepicker #stdatefrom></mat-datepicker>
							  </mat-form-field>
							</div>
							<div class="col-auto pr-0">
								<button mdbBtn class="btn btn-link" (click)="this.aOS.stackingdatefrom=null">Clear</button>
							</div>
						</div>

					  <div class="row">
							<div class="col main-header">
						  	<mat-form-field class="w-100">
		    					<mat-label>Stacking Date To</mat-label>
							    <input matInput [matDatepicker]="stdate" [(ngModel)]="this.aOS.stackingdateto">
									<mat-datepicker-toggle matIconSuffix [for]="stdate"></mat-datepicker-toggle>
							    <mat-datepicker #stdate></mat-datepicker>
							  </mat-form-field>
							</div>
							<div class="col-auto pr-0">
								<button mdbBtn class="btn btn-link" (click)="this.aOS.stackingdateto=null">Clear</button>
							</div>
						</div>

					  <hr>

					  <div class="row">
							<div class="col main-header">
						  	<mat-form-field class="w-100">
		    					<mat-label>Expected Loading Date</mat-label>
							    <input matInput [matDatepicker]="expldate" [(ngModel)]="this.aOS.exploadingdate">
									<mat-datepicker-toggle matIconSuffix [for]="expldate"></mat-datepicker-toggle>
							    <mat-datepicker #expldate></mat-datepicker>
							  </mat-form-field>
							</div>
							<div class="col-auto pr-0">
								<button mdbBtn class="btn btn-link" (click)="this.aOS.exploadingdate=null">Clear</button>
							</div>
						</div>

					  <div class="row">
							<div class="col main-header">
						  	<mat-form-field class="w-100">
		    					<mat-label>ETD</mat-label>
							    <input matInput [matDatepicker]="etddate" [(ngModel)]="this.aOS.etd">
									<mat-datepicker-toggle matIconSuffix [for]="etddate"></mat-datepicker-toggle>
							    <mat-datepicker #etddate></mat-datepicker>
							  </mat-form-field>
							</div>
							<div class="col-auto pr-0">
								<button mdbBtn class="btn btn-link" (click)="this.aOS.etd=null">Clear</button>
							</div>
						</div>

					  <div class="row">
							<div class="col main-header">
						  	<mat-form-field class="w-100">
		    					<mat-label>ETA</mat-label>
							    <input matInput [matDatepicker]="etadate" [(ngModel)]="this.aOS.eta">
									<mat-datepicker-toggle matIconSuffix [for]="etadate"></mat-datepicker-toggle>
							    <mat-datepicker #etadate></mat-datepicker>
							  </mat-form-field>
							</div>
							<div class="col-auto pr-0">
								<button mdbBtn class="btn btn-link" (click)="this.aOS.eta=null">Clear</button>
							</div>
						</div>

					  <hr>

						<mat-form-field>
    					<mat-label>Carrier</mat-label>
					    <input matInput placeholder="Carrier" [(ngModel)]="this.aOS.carrier">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Vessel Name</mat-label>
					    <input matInput placeholder="Vessel Name" [(ngModel)]="this.aOS.vesselname">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Vessel Voyager Number</mat-label>
					    <input matInput placeholder="Vessel Voyager Number" [(ngModel)]="this.aOS.vesselno">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Bill of Lading Number</mat-label>
					    <input matInput placeholder="Bill of Lading Number" [(ngModel)]="this.aOS.billofladingno">
					  </mat-form-field>

		        <mat-form-field class="col px-0">
		          <mat-select placeholder="Container Type" [(ngModel)]="this.aOS.containertype">
		            <mat-option value="Food Grade 20ft">Food Grade 20ft</mat-option>
		            <mat-option value="Food Grade 40ft">Food Grade 40ft</mat-option>
		          </mat-select>
		        </mat-form-field>

						<mat-form-field>
    					<mat-label>Container Number</mat-label>
					    <input matInput placeholder="Container Number" [(ngModel)]="this.aOS.containerno">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Seal Serial Number</mat-label>
					    <input matInput placeholder="Seal Serial Number" [(ngModel)]="this.aOS.sealno">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Truck Vehicle Reg Number</mat-label>
					    <input matInput placeholder="Truck Vehicle Reg Number" [(ngModel)]="this.aOS.truckreg">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Phostoxin Batch No.</mat-label>
					    <input matInput placeholder="Phostoxin Batch No." [(ngModel)]="this.aOS.phostoxin">
					  </mat-form-field>

						<hr>

			      <mat-form-field>
    					<mat-label>Freight Forwarder</mat-label>
					    <mat-select placeholder="Freight Forwarder" [(ngModel)]="this.aOS.freightco" (keydown.tab)="this.aOS.updateContacts(this.aOS.freightco,'freight')">
					      <mat-option *ngFor="let freight of this.dDDS.dropdown.freightList" #freightco (click)="this.aOS.updateContacts(freightco.value,'freight')"  value="{{ freight.idcompany }}">{{ freight.companyname }}</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <ng-container  *ngIf="+this.aOS.freightco>0">
					  
						  <mat-form-field>
    						<mat-label>Contact Name</mat-label>
						    <mat-select placeholder="Contact Name" [(ngModel)]="this.aOS.thisFreightContact" (keydown.tab)="this.aOS.updateContact(this.aOS.thisFreightContact,'freight')">
						      <mat-option *ngFor="let contact of this.aOS.thisFreightContacts" #freightContact (click)="this.aOS.updateContact(freightContact.value,'freight')" [value]="contact?.idcontact">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Email</mat-label>
						    <a href="mailto:{{this.aOS.thisFreightEmail}}"><input matInput placeholder="Email" disabled="true" [(ngModel)]="this.aOS.thisFreightEmail"></a>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Telephone</mat-label>
						    <a href="tel:{{this.aOS.thisFreightTel}}"><input matInput placeholder="Telephone" disabled="true" [(ngModel)]="this.aOS.thisFreightTel"></a>
						  </mat-form-field>
						
						</ng-container>

						<hr>

						<mat-form-field>
    					<mat-label>Consignee</mat-label>
					    <mat-select placeholder="Consignee" [(ngModel)]="this.aOS.consigneeco" (keydown.tab)="this.aOS.updateContacts(this.aOS.consigneeco,'consignee')">
					      <mat-option *ngFor="let consignee of this.aOS.consigneeList" #consigneeco (click)="this.aOS.updateContacts(consigneeco.value,'consignee')"  value="{{ consignee.idcompany }}">{{ consignee.companyname }}</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <ng-container  *ngIf="+this.aOS.consigneeco>0">
					  
						  <mat-form-field>
    						<mat-label>Notify Party</mat-label>
						    <mat-select placeholder="Notify Party" [(ngModel)]="this.aOS.thisConsigneeContact" (keydown.tab)="this.aOS.updateContact(this.aOS.thisConsigneeContact,'consignee')">
						      <mat-option *ngFor="let contact of this.aOS.thisConsigneeContacts" #consigneeContact (click)="this.aOS.updateContact(consigneeContact.value,'consignee')" [value]="contact?.idcontact">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Email</mat-label>
						    <a href="mailto:{{this.aOS.thisConsigneeEmail}}"><input matInput placeholder="Email" disabled="true" [(ngModel)]="this.aOS.thisConsigneeEmail"></a>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Telephone</mat-label>
						    <a href="tel:{{this.aOS.thisConsigneeTel}}"><input matInput placeholder="Telephone" disabled="true" [(ngModel)]="this.aOS.thisConsigneeTel"></a>
						  </mat-form-field>
						
						</ng-container>

						<hr>

						<mat-form-field>
    					<mat-label>2nd Notify Party</mat-label>
					    <mat-select placeholder="2nd Notify Party" [(ngModel)]="this.aOS.notifyco" (keydown.tab)="this.aOS.updateContacts(this.aOS.notifyco,'notify')">
					      <mat-option *ngFor="let notify of this.aOS.notifyList" #notifyco (click)="this.aOS.updateContacts(notifyco.value,'notify')"  value="{{ notify.idcompany }}">{{ notify.companyname }}</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <ng-container  *ngIf="+this.aOS.notifyco>0">
					  
						  <mat-form-field>
    						<mat-label>Notify Party</mat-label>
						    <mat-select placeholder="Notify Party" [(ngModel)]="this.aOS.thisNotifyContact" (keydown.tab)="this.aOS.updateContact(this.aOS.thisNotifyContact,'notify')">
						      <mat-option *ngFor="let contact of this.aOS.thisNotifyContacts" #notifyContact (click)="this.aOS.updateContact(notifyContact.value,'notify')" [value]="contact?.idcontact">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Email</mat-label>
						    <a href="mailto:{{this.aOS.thisNotifyEmail}}"><input matInput placeholder="Email" disabled="true" [(ngModel)]="this.aOS.thisNotifyEmail"></a>
						  </mat-form-field>

						  <mat-form-field>
    						<mat-label>Telephone</mat-label>
						    <a href="tel:{{this.aOS.thisNotifyTel}}"><input matInput placeholder="Telephone" disabled="true" [(ngModel)]="this.aOS.thisNotifyTel"></a>
						  </mat-form-field>
						
						</ng-container>

						<hr>

						<mat-form-field>
    					<mat-label>Logistics Company</mat-label>
					    <mat-select placeholder="Logistics Company" [(ngModel)]="this.aOS.logisticsco">
					      <mat-option *ngFor="let logistics of this.dDDS.dropdown.logisticsList" value="{{ logistics.idcompany }}">{{ logistics.companyname }}</mat-option>
					    </mat-select>
					  </mat-form-field>

						<hr>

					  <div class="row">
							<div class="col main-header">
						  	<mat-form-field class="w-100">
		    					<mat-label>Export Instruction Date</mat-label>
							    <input matInput [matDatepicker]="expinstdate" [(ngModel)]="this.aOS.instructiondate">
									<mat-datepicker-toggle matIconSuffix [for]="expinstdate"></mat-datepicker-toggle>
							    <mat-datepicker #expinstdate></mat-datepicker>
							  </mat-form-field>
							</div>
							<div class="col-auto pr-0">
								<button mdbBtn class="btn btn-link" (click)="this.aOS.instructiondate=null">Clear</button>
							</div>
						</div>

						<mat-form-field>
    					<mat-label>Letter of Credit Number</mat-label>
					    <input matInput placeholder="Bill of Lading Number" [(ngModel)]="this.aOS.letterofcredit">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>Booking Reference Number</mat-label>
					    <input matInput placeholder="Booking Reference Number" [(ngModel)]="this.aOS.bookingref">
					  </mat-form-field>

						<mat-form-field>
    					<mat-label>HTS Code</mat-label>
					  	<mat-select placeholder="HTS Code" [(value)]="this.aOS.hts">
					      <mat-option [value]="'0802.61.0000'">0802.61.0000 - Macadamia Nuts, In Shell, Fresh Or Dried</mat-option>
					      <mat-option [value]="'0802.62.0000'">0802.62.0000 - Macadamia Nuts, Shelled, Fresh Or Dried</mat-option>
					    </mat-select>
					  </mat-form-field>

		      </ng-container>

		    </ng-container>

	      <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [(ngModel)]="this.aOS.note"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="editSelected()">
			
			<button *ngIf="!this.aOS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="saveDelivery()">Save</button>

			<button *ngIf="this.aOS.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.saveChanges()">Update</button>

			<button *ngIf="this.aOS.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>