import { Component, OnInit } from '@angular/core';
import * as atlas from 'azure-maps-control';
import * as atlasdraw from 'azure-maps-drawing-tools';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',	
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

	constructor() {}

	ngOnInit() {
		this.loadMap();
	}

	loadMap() {
		var map = new atlas.Map('myMap', {
			center: new atlas.data.Position(31.05211, -25.31923),
			zoom: 13,
		  pitch: 45,
		  view: 'Auto',
			showLogo: false,
  		showFeedbackLink: false,
			language: 'en-GB',//'af-ZA'
			authOptions: {
				authType: atlas.AuthenticationType.subscriptionKey,
				subscriptionKey: 'XOcanERxUuFfUfc9zIr_MsK0TZcWC2OwZdWbUA9eNgo',

			}
		});

		map.events.add('ready', function() {
			var dataSource = new atlas.source.DataSource();
			map.sources.add(dataSource);

			var symlayer = new atlas.layer.SymbolLayer(dataSource, null, {
				textOptions: {
					textField: 'AmberMacs',
					offset: [0, 1]
				}
			});
			map.layers.add(symlayer)
			dataSource.add(new atlas.data.Point([31.05211, -25.31923]))

			/*// ADD HQ
			var layer = new atlas.layer.SymbolLayer(dataSource);
			map.layers.add(layer);
			var popupTemplate = '<div class="customInfobox"><div class="name">{name}</div>{description}</div>';
			var popup = new atlas.Popup({
			  pixelOffset: [0, -18],
			  closeButton: false
			});

			dataSource.add(new atlas.data.Feature(new atlas.data.Point([31.05211, -25.31923]), {
			  name: 'AmberMacs', 
			  description: 'Plot 88 Old Plaston Road, White River 1240, Mpumulanaga, South Africa'
			}));
			map.events.add('mouseover', layer, function (e) {
				if (e.shapes && e.shapes.length > 0) {
			    var content, coordinate;
			    var properties = e.shapes[0]['data']['properties'];//e.shapes[0].getProperties();
			    coordinate = e.shapes[0]['data']['geometry']['coordinates'];//e.shapes[0].coordinates;//[31.05211, -25.31923];//
			    content = popupTemplate.replace(/{name}/g, properties.name).replace(/{description}/g, properties.description);
    
			    popup.setOptions({
			      content: content,
			      position: coordinate
			    });
			    popup.open(map);
			  }
			});
			map.events.add('mouseleave', layer, function (){
			  popup.close();
			});
			*/
			
			// ADD Drawing Tools
			var drawingManager = new atlasdraw.drawing.DrawingManager(map,{
			    mode: atlasdraw.drawing.DrawingMode.drawPolygon,
			    interactionType: atlasdraw.drawing.DrawingInteractionType.hybrid,
			    toolbar: new atlasdraw.control.DrawingToolbar({
            position: atlas.ControlPosition.TopRight,
            style: 'light',
            buttons: ["draw-polygon","edit-geometry","erase-geometry"]
        	})
			});
			map.controls.add([
				new atlas.control.ZoomControl(),
				new atlas.control.CompassControl(),
				new atlas.control.PitchControl(),
				new atlas.control.StyleControl(),
			], {
				position: atlas.ControlPosition.TopRight
			})

		})


		
	}

}