import { Component, OnInit } from '@angular/core';
import { PortalService } from '../../../auth/portal.service';

//import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';

import { LoginService } from '../../auth/login/login.service';
import { FarmerRemittanceService } from '../remittance.service';

@Component({
  selector: 'app-clientremittancestd',
  templateUrl: './remittance.component.html',
  styleUrls: ['./remittance.component.scss']
})
export class ClientRemittanceStdComponent implements OnInit {
  d: Date = new Date();

  constructor(
  	public lS: LoginService,
  	public rS: FarmerRemittanceService,
  	public pS: PortalService
  	) { }

  ngOnInit() {
  }

}
