<div class="container">
  <div class="row justify-content-between">
    <div class="col-sm ml-3">
      <h3>Stock: {{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.style }}: {{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.invcount | number:'1.0-0' }} ({{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.invseason }}) - {{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.storage }}{{ (this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.orderref=="" ? '' : ' [ ' + this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.orderref + ' ]') }}</h3>
    </div>
  </div>
  <br>
  <table class="table col-sm">
    <tbody>
      <tr class="tableseperator"><td></td><td></td><td class="buttoncell"></td></tr>
      <tr>
        <td>Season</td>
        <td>{{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.invseason }}</td>
        <td class="buttoncell"></td>
      </tr>
      <tr>
        <td>Style</td>
        <td>{{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.fullstyle }}</td>
        <td class="buttoncell"></td>
      </tr>
      <tr>
        <td>Location</td>
        <td>
        	<mat-form-field class="inline">
				    <mat-select [(ngModel)]="this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']].destination">
				    	<ng-container *ngIf="this.dropDownDataService.dropdown.scanDestinations!==undefined">
				      	<mat-option *ngFor="let storage of this.dropDownDataService.dropdown.scanDestinations['destinations']" [value]="storage.idstorage">{{ storage.type | titlecase }}: {{ storage.name }}</mat-option>
				      </ng-container>
				    </mat-select>
				  </mat-form-field>
				</td>
        <td class="buttoncell"><button mdbBtn class="button btn btn-secondary" (click)="editStock('Relocate')">Relocate</button></td>
      </tr>
      <tr>
        <td>Order Assignment</td>
        <td>
        	<mat-form-field class="inline">
				    <mat-select [(ngModel)]="this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']].orderref">
				      <mat-option value="">None</mat-option>
				      <mat-option *ngFor="let order of this.dropDownDataService.dropdown.orderRefList" value="{{ order.orderref }}">{{ order.orderref }} [{{ order.orderstatus | titlecase }}]</mat-option>
				    </mat-select>
				  </mat-form-field>
				</td>
        <td class="buttoncell"><button mdbBtn class="button btn btn-info" (click)="editStock('Reassign')">Reassign</button></td>
      </tr>
      <tr *ngIf="this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.orderref!=''">
        <td>Quantity Required</td>
        <td>{{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.quantity | number:'1.0-0' }}</td>
        <td class="buttoncell"></td>
      </tr>
      <tr>
        <td>Quantity Packed</td>
        <td>{{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.invcount | number:'1.0-0' }}</td>
        <td class="buttoncell"></td>
      </tr>
      <tr *ngIf="this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.orderref!=''">
        <td>Quantity Remaining</td>
        <td>{{ this.tableService['assignedinventory'][this.tableService.clickedIndex['assignedinventory']]?.remaining | number:'1.0-0' }}</td>
        <td class="buttoncell"></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td class="buttoncell"><button mdbBtn class="button btn btn-danger" (click)="editStock('Remove')">Remove</button></td>
      </tr>
      <tr class="tableseperator"><td></td><td></td><td class="buttoncell"></td></tr>
    </tbody>
  </table>
  <br><br>  
</div>