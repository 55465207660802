import { Component, OnInit, AfterViewInit } from '@angular/core';

import { LoginService } from '../auth/login/login.service';
import { ClientDeliveryService } from './delivery.service';
import { ClientTableDataService } from '../table/table.service';
import { PortalService } from '../../auth/portal.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
	clickedIndex;
  dataStore;

  constructor(
  	private loginService: LoginService,
    public deliveryService: ClientDeliveryService,
    public tableService: ClientTableDataService,
    public portalService: PortalService
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(function() {
      window.scrollTo(0,520);
    }, 200); 
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  isActive(option, setting) {
    var ans = false;
    if (this.isAuthenticated(option)) {
      for (var i = 0; i < this.portalService.portalMod()['settings'].length; i++) {
        if (this.portalService.portalMod()['settings'][i]['setting']==setting) {
          ans = (this.portalService.portalMod()['settings'][i]['value']==1);
        }
      }
    }
    return ans;
  }

  sampleComplete() {
    return this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']].status == 'complete';
  }

  resample() {
    this.deliveryService.resample();
  }

  canRequest() {
    var ans = false;
    if (this.isActive('mod-resample','Client Resample Request')) {
      var d = new Date();
      d.setHours(0, 0, 0, 0);
      d.setTime(d.getTime() - d.getTimezoneOffset()*60*1000);
      var sdate = new Date(this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.sampledate);
      sdate.setHours(0, 0, 0, 0);
      sdate.setTime(sdate.getTime() - sdate.getTimezoneOffset()*60*1000);
      if ((d.getTime() - sdate.getTime()) / (1000 * 60 * 60 * 24) <= this.deliveryService.requestDays) {
        if (this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']]?.status=='complete') {
          ans = true;
        }
      }
    }
    return ans;
  }

  cantRequest() {
    var ans = false;
    if (!this.canRequest() && this.isActive('mod-resample','Client Resample Request') && this.tableService['deliveries'][this.tableService.clickedIndex['deliveries']].status=='complete') {
      ans = true;
    }
    return ans;
  }

}
