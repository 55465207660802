import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LogisticsLoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { CsvService } from '../../services/csv.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class LogisticsTableDataService{
	private postBody: {};
	httpRes: any;
	public allData: any = [];
	public filteredData: any;
	public deliveries: any;
	public samples: any;
	public loading: boolean = true;
	public stillLoading: boolean = true;
	public delivery: any;
  public clickedId: any = [];
	public clickedIndex: any[] = [];
	public clickedMoisture: number;
	public clickedSKR: number;
	public clickedUSKR: number;
	public clickedUnsound: number;
	public totalDeliveries: number;
	public paypercent: [];
	public payday: any [];
	public paydayRaw: any [];
	public epaypercent: [];
	public epayday: any [];
	public epaydayRaw: any [];
	public currency: string = 'USD';
	public bpanel: boolean = false;
	public selected: any;
	public clickedType: string;
  public indexLink = false;
  public addCur: boolean;
  public checkBoxesActive: boolean = false;
  public checkBoxes: boolean = false;
  public checkboxdata = [];
  public selectedRows = [];
  public batchpay = [];
  public tablename: string;
  public sampleUnA: any;

	constructor(
		private http: HttpClient,
		private lS: LogisticsLoginService,
		private portalService: PortalService,
		public csvService: CsvService,
		)	{}

	async loadData(data: string, part = 1) {
		this.loading = (part==1);
		this.stillLoading = true;
		var p = {
			"location": this.lS.selectedmember,
			"part": part
		}
		const response = await this.lS.loadData(data, p);
		const httpRes = await this.storeResult(response, data);
		return true;
	}

	async storeResult(rawData, data: string) {
		if (rawData['part']==undefined || rawData['part']==1) {
			this.allData[data] = rawData;
			this.lS['columns'][data] = this.allData[data]['columns'];
			this.checkBoxesActive = (this.allData[data]['checkboxes']==1);
			if (this.clickedIndex[data]==undefined) {
				this.clickedIndex[data] = null;
			}
			this.loading = false;
			this.indexLink = false;
		} else {
			this.allData[data][data].push(...rawData[data]);
			if (rawData[data].length>0) {
				this.sortDropdowns(data,rawData);
			}
		}
		if (rawData['part']!==undefined) {
			if (rawData[data].length==rawData['unit']) {
				this.loadData(data, +rawData['part'] + 1);
			} else {
				this.stillLoading = false;
				this.lS['columns'][data] = this.allData[data]['columns'];
			}
		}
		if (rawData['part']==undefined) {
			this.stillLoading = false;
		}
		this[data] = await [...this.allData[data][data]];
		if (!this.stillLoading) {
			this.refreshSelectedRows(data, rawData['key']);
		}
	}

	sortDropdowns(data,rawData) {
		for (var i=0; i<this.allData[data]['columns'].length; i++) {
			if (this.allData[data]['columns'][i]['type']=='dropdown'){
				var dropdown = this.allData[data]['columns'][i]['field'];
				var curDrop = [...this.allData[data][dropdown],...rawData[dropdown]];
				curDrop = this.removeDuplicates(curDrop,dropdown);
				curDrop.sort(this.compareValues(dropdown));
				this.allData[data][this.allData[data]['columns'][i]['field']] = curDrop;
			}
		}
	}

	removeDuplicates(arr,k) {
		const uniqueK = new Set();
		return arr.filter(element => {
			const isDup = uniqueK.has(element[k]);
			uniqueK.add(element[k]);
			if (!isDup) {
				return true;
			}
			return false;
		});
	}

	compareValues(key, order = 'asc') {
	  return function innerSort(a, b) {
	    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
	      // property doesn't exist on either object
	      return 0;
	    }
	    const varA = (typeof a[key] === 'string')
	      ? a[key].toUpperCase() : a[key];
	    const varB = (typeof b[key] === 'string')
	      ? b[key].toUpperCase() : b[key];
	    let comparison = 0;
	    if (varA > varB) {
	      comparison = 1;
	    } else if (varA < varB) {
	      comparison = -1;
	    }
	    return (
	      (order === 'desc') ? (comparison * -1) : comparison
	    );
	  };
	}

	async setSelection(idd: any, data: string, season = null) {
		if (this[data]==undefined) {
			const ans = await this.loadData(data, season);
		}
		if (season>0 && this.allData[data]['season']!=season) {
			const ans = await this.loadData(data, season);
		}
		switch (data) {
			default:
				this.setId(idd, data);
				break;
		}
		this.indexLink = false;
	}
	
	setId(idd, data) {
		for (var i = 0; i < this[data].length; i++) {
			if (this[data][i][this.allData[data]['key']] == idd) {
				this.clickedIndex[data] = i;
			}
		}
	}

	reset(data: string) {
    delete this.clickedIndex[data];
    this.selectedRows = null;
    this.filteredData = null;
	}

	loadReady(data) {
		if (this.allData[data.toLowerCase()]==undefined) {
			return false;
		}
		if (this.allData[data.toLowerCase()]['key']==undefined) {
			return false;
		}
		if (this[data.toLowerCase()][this.clickedIndex[data.toLowerCase()]]==undefined) {
			return false;
		}
		if (this[data.toLowerCase()][this.clickedIndex[data.toLowerCase()]][this.allData[data.toLowerCase()]['key']]==undefined) {
			return false;
		}
		return true;
	}

	clearData() {
		this.allData = [];
		this.checkBoxesActive = false;
		this.selectedRows = [];
		this.clickedIndex = [];
		this.filteredData = null;
		this.tablename = null;
		this['trades'] = null;
	}

	saveCSV(data) {
		var headers = [];
		var exportData = [];
		var footer = [];
		var count = [];
		var sum = [];
		this.addCur = false;
		for (var i = 0; i < this.filteredData.length; i++) {
			if (exportData[i]==undefined) {
				exportData[i] = [];
			}
			for (var j = 0; j < this.lS['columns'][data].length; j++) {
				headers[j] = this.lS['columns'][data][j]['header'];
				if (this.lS['columns'][data][j]['pipe']=='currency') {
					this.addCur = true;
					exportData[i][j] = this.filteredData[i][this.lS['columns'][data][j]['field']];
				} else {
					exportData[i][j] = this.filteredData[i][this.lS['columns'][data][j]['field']];
				}
				switch (this.lS['columns'][data][j]['footer']) {
				 	case "text":
				 		footer[j] = this.lS['columns'][data][j]['text']
				 		break;
				 	case "count":
				 		count[j] = (count[j]==undefined ? 0 : count[j]) + (this.filteredData[i][this.lS['columns'][data][j]['field']]==null ? 0 : 1);
				 		footer[j] = count[j];
				 		break;
				 	case "sum":
				 		footer[j] = (footer[j]==undefined ? 0 : footer[j]) + +exportData[i][j];
				 		break;
				 	case "wavg":
				 		count[j] = (count[j]==undefined ? 0 : count[j]) + +this.filteredData[i][this.lS['columns'][data][j]['base']];
				 		sum[j] = (sum[j]==undefined ? 0 : sum[j]) + +exportData[i][j] * this.filteredData[i][this.lS['columns'][data][j]['base']];
				 		footer[j] = (i == this.filteredData.length - 1 ? sum[j] / count[j] : '');
				 		break;
				 	default:
				 		footer[j] = '';
				 		break;
				 } 
			}
		}
		exportData[exportData.length] = [];
		exportData[exportData.length] = footer;
		var title = data.charAt(0).toUpperCase() + data.slice(1) + (this.addCur ? ' (' + this.currency + ')' : '');
		this.csvService.rawCSV(title,headers,exportData);
	}

  refreshSelectedRows(tablename, key) {
  	if (this.selectedRows!=undefined) {
	  	for (var i=0; i<this.selectedRows.length; i++) {
	  		for (var j=0; j<this[tablename].length; j++) {
	  			if (this[tablename][j][key]==this.selectedRows[i][key]) {
	  				this.selectedRows[i] = this[tablename][j];
	  			}
	  		}
	  	}
	  }
  }

}