<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.settingsService.editmode">Manage Cultivars</h2>
				<h2 *ngIf="this.settingsService.editmode">Cultivar Archive</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.settingsService.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Cultivar Archive</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.settingsService.editmode">
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Manage Cultivars</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7" *ngIf="!this.settingsService.editmode">

			<hr>
			<h4 class="my-3 inline">Cultivars: </h4><button mat-icon-button class="inline" (click)="this.settingsService.addItem()"><i class="material-icons green-icon mb-2">add_circle</i></button>

		 	<div  class="col px-0" *ngFor="let cultivar of this.settingsService.cultivar.current; let cIndex=index;">

				<div>

					<div class="col-9 inline">
						<div class="form-container">
						  
						  <mat-form-field>
				    		<input matInput placeholder="Cultivar Name" [(ngModel)]="cultivar.cultivar">
						  </mat-form-field>

						</div>
					</div>

					<div class="col-3 inline">
						<div class="form-container">
						  <div class="row">
						  	<ng-container *ngIf="cultivar.idcultivar>0">
								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.request!=='renameCultivar' || (this.settingsService.request=='renameCultivar' && 
								  !(this.settingsService.saving && this.settingsService.idcultivar==cultivar.idcultivar))"
								  (click)="this.settingsService.renameItem(cIndex)"><i class="material-icons green-icon">done</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index==cIndex && 
								  this.settingsService.idcultivar==cultivar.idcultivar && this.settingsService.request=='renameCultivar' && this.settingsService.saving"
								  ><i class="inline spinner material-icons amber-icon">sync</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="cultivar.used==1 && (this.settingsService.request!=='archiveCultivar' || (this.settingsService.request=='archiveCultivar' && 
								  !(this.settingsService.saving && this.settingsService.idcultivar==cultivar.idcultivar)))"
								  (click)="this.settingsService.archiveItem(cIndex)"><i class="material-icons">archive</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="cultivar.used==0 && (this.settingsService.request!=='archiveCultivar' || (this.settingsService.request=='archiveCultivar' && 
								  !(this.settingsService.saving && this.settingsService.idcultivar==cultivar.idcultivar)))"
								  (click)="this.settingsService.deleteSavedItem(cIndex)"><i class="material-icons red-icon">delete</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index==cIndex && 
								  this.settingsService.idcultivar==cultivar.idcultivar && this.settingsService.request=='archiveCultivar' && this.settingsService.saving"
								  ><i class="inline spinner material-icons amber-icon">sync</i></button>

								</ng-container>
						  	<ng-container *ngIf="cultivar.idcultivar==0">
								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index!==cIndex || (this.settingsService.index==cIndex  &&
								  !this.settingsService.saving)" 
								  [disabled]="cultivar.cultivar==''" [class.blue-icon]="cultivar.cultivar!==''" [class.grey-icon]="cultivar.cultivar==''" (click)="this.settingsService.saveItem(cIndex)"><i class="inline material-icons">save</i></button>

								  <button mat-icon-button class="col" 
								  *ngIf="this.settingsService.index==cIndex && 
								  this.settingsService.saving && this.settingsService.request=='saveCultivar'"><i class="inline spinner material-icons amber-icon">sync</i></button>

								  <button mat-icon-button class="col" (click)="this.settingsService.deleteItem(cIndex)"><i class="inline material-icons red-icon">delete</i></button>
						  	</ng-container>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col col-lg-9 col-xl-7" *ngIf="this.settingsService.editmode">
		 	<div  class="col px-0" *ngFor="let cultivar of this.settingsService.cultivar.archived; let cIndex=index;">

				<div>

					<div class="col-9 inline">
						<div class="form-container">
						  
						  <mat-form-field>
				    		<input matInput placeholder="Cultivar Name" [(ngModel)]="cultivar.cultivar">
						  </mat-form-field>

						</div>
					</div>

					<div class="col-3 inline">
						<div class="form-container">
						  <div class="row">
							  
							  <button mat-icon-button class="col" 
							  *ngIf="this.settingsService.request!=='unarchiveCultivar' || (this.settingsService.request=='unarchiveCultivar' && 
							  !(this.settingsService.saving && this.settingsService.idcultivar==cultivar.idcultivar))"
							  (click)="this.settingsService.unarchiveItem(cIndex)"><i class="material-icons purple-icon">unarchive</i></button>

							  <button mat-icon-button class="col" 
							  *ngIf="this.settingsService.index==cIndex && 
							  this.settingsService.idcultivar==cultivar.idcultivar && this.settingsService.request=='unarchiveCultivar' && this.settingsService.saving"
							  ><i class="inline spinner material-icons amber-icon">sync</i></button>
						  	
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<br><br>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>