import { Component, OnInit } from '@angular/core';

import { BuyingLoginService } from './auth/login/login.service';

@Component({
  selector: 'buyingapp-root',
  templateUrl: './buyingapp.component.html',
  styleUrls: ['./buyingapp.component.scss'],
  providers: []
})
export class BuyingAppComponent {
  loadedFeature: any = 'login';

  constructor(
  	private loginService: BuyingLoginService) {}

  ngOnInit() {
  }

  onNavigate(feature: Event) {
  	this.loadedFeature = feature;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
  
}