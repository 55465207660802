import { Injectable } from '@angular/core';

@Injectable()
export class SearchService{

	constructor() {}

	filterArray(array, args, subarr = null, subfield = null): any[] {
    const arr = array;
    for (var j = 0; j < args.length; j++) {
      var nextArg = true;
      for (var i = arr.length - 1; i >= 0; i--) {
        var keepRow = false;
        for (const [k, v] of Object.entries(arr[i])) {
          if (!keepRow && !Array.isArray(v)) {
            keepRow = keepRow || JSON.stringify(v).toLowerCase().includes(args[j].toLowerCase());
          }
        }
        if (!keepRow && subarr!=null) {
          for (const [k, v] of Object.entries(arr[i])) {
            if (k==subarr && Array.isArray(v)) {
              for (var l = v.length-1; l >=0; l--) {
                if (v[l]==null) {
                  arr.splice(i,1);
                } else {
                  for (const [vk, vv] of Object.entries(v[l])) {
                  	if (subfield==null || subfield==vk) {
	                    if (!JSON.stringify(vv).toLowerCase().includes(args[j].toLowerCase())) {
	                      if (v[l]!==null) {
	                        arr[i][k].splice(l, 1);
	                      }
	                    } else {
	                      keepRow = true;
	                    }
	                  }
                  }
                }
              }
            }
          }
        }
        if (!keepRow) {
          arr.splice(i, 1);
        }
      }
    }
    return arr;
  }
	

}