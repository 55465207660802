<div class="container">
	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="isAuthenticated() && (isAuthorised('general') || isAuthorised('accountant') || isAuthorised('booking'))">
		<br>
		<div class="row justify-content-start pl-2">
			<div class="col-xl-6 col-sm-12 justify-content-start pl-0">
				<h2 class="mb-4">Overview</h2>
				<h4 class="mb-4">Welcome back {{ this.lS.uname }}.</h4>
				
				<div class="row justify-content-start">		
					<h2 class="col">
						<div class="dropdown" dropdown>
			        <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light pl-0" mdbWavesEffect>
			        {{ this.lS.season }} Season<span class="caret"></span></a>
			        <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
			          <button 
			          	class="dropdown-item waves-light py-3" 
			          	mdbWavesEffect 
			          	(click)="onSeasonSelected(season)"
			          	*ngFor='let season of this.lS.seasons'>{{ season }} Season</button>
			        </ul>
			      </div>
					</h2>
				</div>
			</div>

			<div *ngIf="isAuthorised('general') && isAuthenticated('mod-currency') && (this.rS.overviewStore?.fx!==undefined || this.rS.overviewStore?.fx12month!==undefined)" class="col-xl-6 col-sm-12 row justify-content-start pr-0" style="text-align: right">

				<div *ngIf="this.rS.overviewStore?.fx!==undefined" class="col-6">
						<p class="mt-2 mb-0" style="font-weight: 600">Day Average</p><p class="mb-2 mt-0" style="font-weight: 600">ZAR/USD</p>
						<h2 class="mt-2 mb-2" style="font-weight: 500; color: green">{{ this.rS.overviewStore?.fx | number:'1.2-2' }}</h2>
						<p>{{ this.rS.overviewStore?.fxdate | date:'EEE, MMM d, yyyy' }}</p>
				</div>

				<div *ngIf="this.rS.overviewStore?.fx12month!==undefined" class="col-6">
						<p class="mt-2 mb-0" style="font-weight: 600">12 Month Avg</p><p class="mb-2 mt-0" style="font-weight: 600">ZAR/USD</p>
						<h2 class="mt-2 mb-2" style="font-weight: 500; color: blue">{{ this.rS.overviewStore?.fx12month | number:'1.2-2' }}</h2>
						<p>{{ this.rS.overviewStore?.fx12monthdate | date:'EEE, MMM d, yyyy' }}</p>
				</div>

				<div class="col">
					<p class="text-right">Source: Bloomberg</p>
				</div>

			</div>
		</div>
		<br>

		<ng-container *ngIf="isAuthorised('general') || isAuthorised('booking')">

			<ng-container *ngIf="this.lS.latestseason==this.lS.season && silosVisible">
				<app-silolevels></app-silolevels>
			</ng-container>

			<ng-container *ngIf="isAuthorised('general')">

				<app-delsummary></app-delsummary>

				<ng-container *ngIf="this.lS.latestseason==this.lS.season">
					<app-invsummary></app-invsummary>
				</ng-container>

			</ng-container>
			
		</ng-container>

		<ng-container *ngIf="isAuthorised('accountant')">
			<div class="col justify-content-start pl-0">		
				<h2>Accountant Profile</h2>
				<br>
				<h4 class="boldtype">All information contained on this portal is confidential and must not be shared with other parties unless prior authorisation has been given in writing from {{ this.pS.portalMod()['portal'] }}'s directors or financial controller.</h4>
			</div>
				
		</ng-container>

	</div>


	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="isAuthenticated('mod-siloselect') && isAuthenticated('mod-depot') && !isAuthorised('general') && isAuthorised('depot') && !isAuthorised('siloselector')">
		<br>
		<h2>Overview</h2>
		<br>
		<h4>Welcome back {{ this.lS.uname }}.</h4>
		<br>
		<h4>Depot Deliveries - Current</h4>
		<br>
		<table class="table col-sm">
			<tbody>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Deliveries received</td>
					<td>{{ this.dDS.overview['totaldeliveries'] | number:'1.0-0' }}</td>
				</tr>
				<tr>
					<td>Deliveries dehusked</td>
					<td>{{ this.dDS.overview['dehusking'] | number:'1.0-0' }}</td>
				</tr>
				<tr>
					<td>WNIS received</td>
					<td>{{ this.dDS.overview['depotwnis'] | number:'1.0-0' }} kg</td>
				</tr>
				<tr>
					<td>WNIS dispatched</td>
					<td>{{ this.dDS.overview['depotwnisd'] | number:'1.0-0' }} kg</td>
				</tr>
				<tr>
					<td>WNIS dehusked</td>
					<td>{{ this.dDS.overview['huskmass'] | number:'1.0-0' }} kg</td>
				</tr>
				<tr>
					<td>WNIS dehusking rejects</td>
					<td>{{ this.dDS.overview['rejecthuskmass'] | number:'1.0-0' }} kg</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
			</tbody>
		</table>
	</div>


	<div class="col pt-3" *ngIf="isAuthenticated('mod-inventory') && !isAuthorised('general') && isAuthorised('lab')">
		<br>
		<h2>Overview</h2>
		<br>
		<h4>Welcome back {{ this.lS.uname }}.</h4>
		<br>
		<app-add-pallet-sample></app-add-pallet-sample>
	</div>


	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="isAuthenticated('mod-inventory') && !isAuthorised('general') && !isAuthorised('depot') && isAuthorised('manageinventory')">
		<br>
		<h2>Overview</h2>
		<br>
		<h4>Welcome back {{ this.lS.uname }}.</h4>
		<br>
		<ng-container *ngIf="this.iS.NIScount>0">
			<br>
			<h4>Inventory Total - Nut-in-Shell</h4>	
			<table class="table col-sm">
				<tbody>	
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>TOTAL NIS</td>
						<td>{{ this.iS.totalNIS | number:'1.0-0' }} kg ({{ this.iS.NIScount | number:'1.0-0' }} bags)</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let inventory of this.iS.inventory">
						<tr *ngIf="inventory.grade=='NIS'">
							<td>NIS > {{ inventory.style.substr(1,2) + "mm" }}</td>
							<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-container>
		<ng-container *ngIf="this.iS.todayNIScount>0">
			<br>
			<h4>Inventory Today - Nut-in-Shell</h4>	
			<table class="table col-sm">
				<tbody>	
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>TOTAL NIS</td>
						<td>{{ this.iS.todaytotalNIS | number:'1.0-0' }} kg ({{ this.iS.todayNIScount | number:'1.0-0' }} bags)</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let inventory of this.iS.inventoryToday">
						<tr *ngIf="inventory.grade=='NIS'">
							<td>NIS > {{ inventory.style.substr(1,2) + "mm" }}</td>
							<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-container>
		
		<ng-container *ngIf="isAuthenticated('mod-qr') && this.iS.dispatchtodaytotalquantity>0">
			<br>
			<h4>Dispatched Today - Kernel</h4>	
			<table class="table col-sm">
				<tbody>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>TOTAL</td>
						<td>{{ this.iS.dispatchtodaytotalquantity | number:'1.0-0' }}</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let inventory of this.iS.dispatchToday">
						<tr *ngIf="inventory.grade=='Premium'">
							<td>{{ "Style " + inventory.style.slice(0, -1) }}</td>
							<td>{{ inventory.quantity | number:'1.0-0' }}</td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let inventory of this.iS.dispatchToday">
						<tr *ngIf="inventory.grade=='Commercial'">
							<td>{{ "Style " + inventory.style.slice(0, -1) }}</td>
							<td>{{ inventory.quantity | number:'1.0-0' }}</td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-container>
		<ng-container *ngIf="isAuthenticated('mod-qr') && this.iS.dispatchtodayNIScount>0">
			<br>
			<h4>Dispatched Today - Nut-in-Shell</h4>	
			<table class="table col-sm">
				<tbody>	
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>TOTAL NIS</td>
						<td>{{ this.iS.dispatchtodaytotalNIS | number:'1.0-0' }} kg ({{ this.iS.dispatchtodayNIScount | number:'1.0-0' }} bags)</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let inventory of this.iS.dispatchToday">
						<tr *ngIf="inventory.grade=='NIS'">
							<td>NIS > {{ inventory.style.substr(1,2) + "mm" }}</td>
							<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-container>
		<ng-container *ngIf="this.iS.dispatchRetailToday>0 && isAuthenticated('mod-qr')">
			<br>
			<h4>Dispatched Today - Retail</h4>	
			<table class="table col-sm">
				<tbody>
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngFor="let inventory of this.iS.dispatchToday">
						<tr *ngIf="inventory.grade=='Retail'">
							<td>{{ inventory.style.slice(0, -1) + " - " }}{{ inventory.batchdate | date:'d MMM, EEE' }}</td>
							<td>{{ inventory.quantity | number:'1.0-0' }} kg ({{ inventory.count | number:'1.0-0'}} bags)</td>
						</tr>
					</ng-container>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</ng-container>
	</div>
</div>



<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>

<br>
<br>