<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.addProcessingService.editmode">Log Processing</h2>
				<h2 *ngIf="this.addProcessingService.editmode">Edit Processing Log</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.addProcessingService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/overview/processing">View Log</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Log</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.addProcessingService.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/overview/processing">View Log</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Log Processing</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.addProcessingService.editmode">
    			<mat-label>Logged Process</mat-label>
			    <mat-select placeholder="Logged Process" [(ngModel)]="this.addProcessingService.idprocessing" (keydown.tab)="this.addProcessingService.loadForEdit()">
			      <mat-option *ngFor="let process of this.dropDownDataService.dropdown.processingList" #prod (click)="this.addProcessingService.loadForEdit()" value="{{ process.idprocessing }}">{{ process.date | date }}: {{ process.process | titlecase }} - {{ process.storagename }} - {{ process.quantity | number:'1.0-0' }} kg</mat-option>
			    </mat-select>
		  	</mat-form-field>

		  	<br *ngIf="this.addProcessingService.editmode && editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Date</mat-label>
			    <input matInput [matDatepicker]="date" (change)="selectNISweight()" [(ngModel)]="this.addProcessingService.date" (ngModelChange)="getPList()">
					<mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
			    <mat-datepicker #date></mat-datepicker>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Location</mat-label>
			    <mat-select placeholder="Location" [(ngModel)]="this.dropDownDataService.location['idlocation']">
			      <mat-option *ngFor="let location of this.dropDownDataService.dropdown.locationList" [value]="location.idlocation">{{ location.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field *ngIf="editSelected()">
    			<mat-label>Process</mat-label>
			    <mat-select placeholder="Process" [(ngModel)]="this.addProcessingService.process">
			      <mat-option value="cracking">Cracking</mat-option>
			      <mat-option (click)="setOutputs()" value="nis">Nut In Shell</mat-option>
			      <mat-option value="mix">Batch Mixing</mat-option>
			      <mat-option value="transfer">Batch Transfer</mat-option>
			    
			    </mat-select>
			  </mat-form-field>

			  <ng-container *ngIf="this.addProcessingService.process!=null">

				  <hr>

				  <ng-container *ngIf="this.addProcessingService.process!='mix'">

					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Source</mat-label>
					    <mat-select placeholder="Source" [(ngModel)]="this.addProcessingService.batch">
			      		<ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList?.sources">
					     	 <mat-option *ngIf="checkSource(storage)" [value]="storage">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
					     	</ng-container>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Quantity Processed (kg)</mat-label>
					    <input matInput placeholder="Quantity Processed (kg)" type="number" [(ngModel)]="this.addProcessingService.quantity">
					  </mat-form-field>

					  <hr *ngIf="editSelected()">

					</ng-container>

				  <ng-container *ngIf="this.addProcessingService.process=='mix'">

				  	<mat-form-field *ngIf="editSelected()">
    					<mat-label>Source A</mat-label>
					    <mat-select placeholder="Source A" [(ngModel)]="this.addProcessingService.batch">
			      		<ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList?.sources">
					     	 <mat-option *ngIf="checkSource(storage)" [value]="storage">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
					     	</ng-container>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field *ngIf="editSelected()">
    					<mat-label>Quantity - A (kg)</mat-label>
					    <input matInput placeholder="Quantity - A (kg)" type="number" [(ngModel)]="this.addProcessingService.quantity">
					  </mat-form-field>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.addProcessingService.batchcomplete" *ngIf="editSelected()">
				        Storage A emptied?
				      </mat-slide-toggle> 
					  
					  <hr *ngIf="editSelected()">

					  <ng-container *ngIf="this.addProcessingService.batch!=null">

					  	<mat-form-field *ngIf="editSelected()">
    						<mat-label>Source B</mat-label>
						    <mat-select placeholder="Source B" [(ngModel)]="this.addProcessingService.batch2">
						      <ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList?.sources">
						      	<mat-option *ngIf="checkBans(storage,'batch2')" [value]="storage">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field *ngIf="editSelected()">
    						<mat-label>Quantity - B (kg)</mat-label>
						    <input matInput placeholder="Quantity - B (kg)" type="number" [(ngModel)]="this.addProcessingService.quantity2">
						  </mat-form-field>

						  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.addProcessingService.batchcompleteB" *ngIf="editSelected()">
					        Storage B emptied?
				      </mat-slide-toggle> 

					  	<hr *ngIf="editSelected()">

					  	<mat-form-field *ngIf="editSelected()">
    						<mat-label>Destination</mat-label>
						    <mat-select placeholder="Destination" [(ngModel)]="this.addProcessingService.destination">
						    	<ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList?.destination">
						      	<mat-option *ngIf="checkBans(storage,'destination')" value="{{ +storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>

					  	<hr *ngIf="editSelected()">

					  </ng-container>

					</ng-container>

				  <ng-container *ngIf="this.addProcessingService.process=='nis'">

						<ng-container *ngIf="this.addProcessingService.batch!=null">

						  <mat-form-field *ngIf="editSelected()">
    						<mat-label>Destination</mat-label>
						    <mat-select placeholder="Destination" [(ngModel)]="this.addProcessingService.destination">
						    	<ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList['destination']">
						      	<mat-option *ngIf="checkBans(storage,'destination')" value="{{ +storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
						      </ng-container>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field *ngIf="editSelected()">
    						<mat-label>Nut-in-Shell Weight (kg)</mat-label>
						    <input matInput placeholder="Nut-in-Shell Weight (kg)" type="number" [(ngModel)]="this.addProcessingService.quantity2">
						  </mat-form-field>

						  <mat-slide-toggle class="slider-margin slider-label" [disabled]="canAutofill()" [(ngModel)]="this.addProcessingService.autonis" (change)="setAutoFill()" *ngIf="editSelected()">
				        Autofill NIS (Full day's production from Source)?
				      </mat-slide-toggle>  

						  <hr *ngIf="editSelected()">

						  <mat-form-field *ngIf="editSelected()">
    						<mat-label>Overfill (Rejects)</mat-label>
						    <mat-select placeholder="Overfill (Rejects)" [(ngModel)]="this.addProcessingService.overfill">
						      <ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList['overfill']">
					      		<mat-option *ngIf="checkBans(storage,'overfill')" value="{{ +storage.idstorage }}">{{ (storage.type == "cracking" ? "" : storage.type) | titlecase }} {{ storage.name }}</mat-option>
					      	</ng-container>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field *ngIf="editSelected()">
    						<mat-label>Rejected Weight (kg)</mat-label>
						    <input matInput placeholder="Rejected Weight (kg)" type="number" readonly [ngModel]="+this.addProcessingService.quantity - +this.addProcessingService.quantity2">
						  </mat-form-field>

						  <hr *ngIf="editSelected()">

						</ng-container>

					</ng-container>

				  <ng-container *ngIf="this.addProcessingService.process!='mix'">
					  
					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.addProcessingService.batchcomplete" *ngIf="editSelected()">
			        Source emptied?
			      </mat-slide-toggle>  

					  <hr *ngIf="editSelected()">

					</ng-container>

				  <ng-container *ngIf="this.addProcessingService.process=='transfer'">

				  	<mat-form-field *ngIf="editSelected()">
    					<mat-label>Destination</mat-label>
					    <mat-select placeholder="Destination" [(ngModel)]="this.addProcessingService.destination">
					    	<ng-container *ngFor="let storage of this.dropDownDataService.dropdown.processList['destination']">
					      	<mat-option *ngIf="checkBans(storage,'destination')" value="{{ +storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
					      </ng-container>
					    </mat-select>
					  </mat-form-field>

				  	<hr *ngIf="editSelected()">

					</ng-container>

				  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
				    <textarea matInput placeholder="Notes" [(ngModel)]="this.addProcessingService.note"></textarea>
				  </mat-form-field>
			
				</ng-container>
				  
			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.addProcessingService.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="onCalculate()">Save</button>

			<button *ngIf="this.addProcessingService.editmode && this.editSelected()" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="this.addProcessingService.saveChanges()">Update</button>

			<button *ngIf="this.addProcessingService.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>