<ng-container *ngIf="this.tS.tablename=='samples' || this.tS.tablename=='deliveries'">
	<div class="container" id="childelem">
		<h3 class="mb-3">{{ this.tS.delivery?.companyname }}</h3>
		<div class="row justify-content-between">
			<div class="col-xs-6 main-header">
				<h3>Delivery {{ this.tS.delivery?.iddelivery | delivery }}:{{ (this.tS.delivery?.status=='draft' ? ' Draft' : '') }}{{ (isAuthenticated('mod-selectouts') || isAuthenticated('mod-smalldelivery') ? (+this.tS.delivery?.selectout==1 ? ' (Select-Outs' : (+this.tS.delivery?.smalldel==1 ? ' (Smalls' : ' (Standard' )) + ')' : '') }}</h3>
			</div>
			<div class="mr-4 ml-3 col-auto">
				<button mdbBtn class="btn btn-info" (click)="editDelivery()">Edit Delivery</button>
				<button mdbBtn class="btn btn-secondary" *ngIf="sampleComplete()" (click)="editSample()">Edit Sample</button>
			</div>
		</div>
		<br>
		<table class="table col-sm">
			<tbody>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Producer</td>
					<td><a (click)="viewCompany(this.tS.delivery?.idproducer)">{{ this.tS.delivery?.companyname }}</a></td>
				</tr>
				<tr *ngIf="this.tS.delivery?.idproducer!=this.tS.delivery?.idparent">
					<td>Parent</td>
					<td><a (click)="viewCompany(this.tS.delivery?.idparent)">{{ this.tS.delivery?.parent }}</a></td>
				</tr>
				<tr *ngIf="this.tS.delivery?.iddeldepot!=null">
					<td>Depot Delivery Number</td>
					<td>{{ this.tS.delivery?.iddeldepot | delivery }}</td>
				</tr>
				<tr *ngIf="this.tS.delivery?.farmid!=null">
					<td>Farm ID</td>
					<td>{{ this.tS.delivery?.farmid }}</td>
				</tr>
				<tr *ngIf="this.tS.delivery?.iddelfarm!=null">
					<td>Farmer's Delivery Reference</td>
					<td>{{ this.tS.delivery?.iddelfarm }}</td>
				</tr>
				<ng-template [ngIf]="this.tS.delivery.paypercent?.length > 0 || this.tS.delivery?.model != null">
					<tr class="tableseperator"><td></td><td></td></tr>
					<ng-container *ngIf="this.tS.delivery?.model != null">
						<tr>
							<td>Price Model</td>
							<td>{{ this.tS.delivery?.model }}</td>
						</tr>
						<tr class="tableseperatorlight"><td></td><td></td></tr>
					</ng-container>
					<ng-container *ngFor="let payterm of this.tS.delivery?.epayday; let i = index;">
						<tr>
							<td>Estimate Payment No. {{ i + 1 }}</td>
							<td>{{ this.tS.delivery?.epaypercent[i] | percent }} of Estimate : {{ payterm }}</td>
						</tr>
					</ng-container>
					<tr *ngIf="this.tS.delivery?.epayday!==null" class="tableseperatorlight"><td></td><td></td></tr>
					<ng-container *ngFor="let payterm of this.tS.delivery?.payday; let i = index;">
						<tr>
							<td>Payment No. {{ i + 1 }}</td>
							<td>{{ this.tS.delivery?.paypercent[i] | percent }} of Total : {{ payterm }}</td>
						</tr>
					</ng-container>
				</ng-template>
				<tr class="tableseperator"><td></td><td></td></tr>
				<!-- WNIS -->
				<tr>
					<td>Total WNIS delivered</td>
					<td>{{ +this.tS.delivery?.wnis + +this.tS.delivery?.smallwnis | number:'1.2-2' }} kg</td>
				</tr>
				<tr *ngIf="+this.tS.delivery?.smallwnis > 0">
					<td class="pl-4">WNIS (ex. Smalls) delivered</td>
					<td class="pl-4">{{ this.tS.delivery?.wnis | number:'1.2-2' }} kg</td>
				</tr>
				<tr *ngIf="+this.tS.delivery?.smallwnis > 0">
					<td class="pl-4">WNIS Smalls delivered</td>
					<td class="pl-4">{{ this.tS.delivery?.smallwnis | number:'1.2-2' }} kg</td>
				</tr>
				<!-- DEPOT -->
				<tr class="tableseperator" *ngIf="+this.tS.delivery?.depotwnis > 0"><td></td><td></td></tr>
				<tr *ngIf="+this.tS.delivery?.depotwnis > 0">
					<td>Depot Received WNIS</td>
					<td>{{ this.tS.delivery?.depotwnis | number:'1.2-2' }} kg</td>
				</tr>
				<tr *ngIf="+this.tS.delivery?.depotwnisd > 0">
					<td>Depot Dispatched WNIS</td>
					<td>{{ this.tS.delivery?.depotwnisd | number:'1.2-2' }} kg</td>
				</tr>
				<!-- DEPOT WNIS BREAKDOWN -->
				<tr class="tableseperator" *ngIf="+this.tS.delivery?.depotwnis > 0"><td></td><td></td></tr>
				<tr *ngIf="+this.tS.delivery?.depotwnisd > 0">
					<td>Total Scale WNIS</td>
					<td>{{ this.tS.delivery?.scalewnis | number:'1.2-2' }} kg</td>
				</tr>
				<tr  *ngIf="+this.tS.delivery?.depotwnisd > 0 && +this.tS.delivery?.smallwnis > 0">
					<td class="pl-4">Scale WNIS (ex. Smalls)</td>
					<td class="pl-4">{{ +this.tS.delivery?.scalewnis - +this.tS.delivery?.smalls | number:'1.2-2' }} kg</td>
				</tr>
				<tr  *ngIf="+this.tS.delivery?.depotwnisd > 0 && +this.tS.delivery?.smallwnis > 0">
					<td class="pl-4">Scale WNIS Smalls</td>
					<td class="pl-4">{{ this.tS.delivery?.smalls | number:'1.2-2' }} kg</td>
				</tr>
				<!-- DNIS -->
				<ng-template [ngIf]="sampleComplete()">
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>Total DNIS delivered</td>
						<td>{{ this.portalService.dnis(+this.tS.delivery?.scalewnis, +this.tS.delivery?.moisture, +this.tS.delivery?.dryfloor, (+this.tS.delivery?.season > 2020 ? 'formula' : 'linear')) | number:'1.2-2' }} kg</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.smalls > 0">
						<td class="pl-4">DNIS (ex. Smalls) delivered</td>
						<td class="pl-4">{{ this.portalService.dnis(this.tS.delivery?.dwnis, +this.tS.delivery?.moisture, +this.tS.delivery?.dryfloor, (+this.tS.delivery?.season > 2020 ? 'formula' : 'linear')) | number:'1.2-2' }} kg</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.smalls > 0">
						<td class="pl-4">DNIS Smalls delivered</td>
						<td class="pl-4">{{ this.portalService.dnis(this.tS.delivery?.smalls, +this.tS.delivery?.moisture, +this.tS.delivery?.dryfloor, (+this.tS.delivery?.season > 2020 ? 'formula' : 'linear')) | number:'1.2-2' }} kg</td>
					</tr>
				</ng-template>
				
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Date Delivered</td>
					<td>{{ this.tS.delivery?.ddate | date:'MMM d, EEE, y' }}</td>
				</tr>
				<tr>
					<td>Cultivar</td>
					<td>{{ this.tS.delivery?.cultivar }}</td>
				</tr>
				<tr class="tableseperator" *ngIf="this.tS.delivery?.d1825>0"><td></td><td></td></tr>
				<tr *ngIf="this.tS.delivery?.d18P>0">
					<td>DNIS 18-20mm</td>
					<td>{{ this.tS.delivery?.d18P | percent:'1.2' }}</td>
				</tr>
				<tr *ngIf="this.tS.delivery?.d20P>0">
					<td>DNIS 20-22mm</td>
					<td>{{ this.tS.delivery?.d20P | percent:'1.2' }}</td>
				</tr>
				<tr *ngIf="this.tS.delivery?.d22P>0">
					<td>DNIS 22-25mm</td>
					<td>{{ this.tS.delivery?.d22P | percent:'1.2' }}</td>
				</tr>
				<tr *ngIf="this.tS.delivery?.d25P>0">
					<td>DNIS > 25mm</td>
					<td>{{ this.tS.delivery?.d25P | percent:'1.2' }}</td>
				</tr>
				<tr class="tableseperator" *ngIf="this.tS.delivery?.d1825>0"><td></td><td></td></tr>
				<tr *ngIf="this.tS.delivery?.portion==this.tS.delivery?.scalewnis">
					<td>Storage</td>
					<td>{{ this.tS.delivery?.storage }}</td>
				</tr>
				<ng-container *ngIf="this.tS.delivery?.portion!=this.tS.delivery?.scalewnis">
					<tr *ngFor="let storage of this.tS.delivery?.storages; let storeIndex=index;">
						<td [class.noline]="storeIndex>0">{{ (storeIndex==0 ? "Storage" : "" ) }}</td>
						<td [class.noline]="storeIndex>0">{{ storage.type | titlecase }} {{ storage.name }} ({{ storage.wnis | number:'1.2-2' }} kg)</td>
					</tr>
				</ng-container>
				<tr>
					<td>Collected</td>
					<td>{{ this.tS.delivery?.dcollected | yesno }}</td>
				</tr>
				<tr>
					<td>Paid Collection?</td>
					<td>{{ this.tS.delivery?.dpaidcollection | yesno }}</td>
				</tr>
				<tr>
					<td>Sample Status</td>
					<td>{{ ( this.tS.delivery?.status == null ? 'Not Complete' : this.tS.delivery?.samplestatus ) | titlecase }}</td>
				</tr>
				<tr>
					<td>Delivery Note</td>
					<td>{{ this.tS.delivery?.notes }}</td>
				</tr>
				<ng-template [ngIf]="sampleComplete()">
					<tr>
						<td>Sample Note</td>
						<td>{{ this.tS.delivery?.notes }}</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>Moisture - Whole Nut (%)</td>
						<td>{{ +this.tS.delivery?.moisture | percent:'1.2' }}</td>
					</tr>
					<tr>
						<td>Moisture - Kernel (%)</td>
						<td>{{ +this.tS.delivery?.wetkernel | percent:'1.2' }}</td>
					</tr>
					<tr class="tableseperatorlight"><td></td><td></td></tr>
					<tr>
						<td>Total Moisture</td>
						<td>{{ +this.tS.delivery?.moisture | percent:'1.2' }}</td>
					</tr>
					<tr>
						<td>Moisture removed (to {{ this.tS.delivery?.dryfloorlevel | percent:'1.1' }} kernel moisture)</td>
						<td>{{ +this.tS.delivery?.moisture - this.portalService.calMoisture(+this.tS.delivery?.dryfloor, 0) | percent:'1.2' }} &nbsp; &nbsp;
							({{ (+this.tS.delivery?.moisture - this.portalService.calMoisture(+this.tS.delivery?.dryfloor, 0)) * +this.tS.delivery?.swnis | number:'1.0-0' }}g)</td>
					</tr>
					<tr>
						<td>TKR</td>
						<td>
							{{ +this.tS.delivery?.skr + +this.tS.delivery?.uskr + +this.tS.delivery?.darkP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.sound + +this.tS.delivery?.unsound + +this.tS.delivery?.dark | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr>
						<td>SKR</td>
						<td>
							{{ this.tS.delivery?.skr | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.sound | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr>
						<td>Darks / Onion Ring</td>
						<td>
							{{ +this.tS.delivery?.darkP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.dark | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr>
						<td>Shells</td>
						<td>{{ +this.tS.delivery?.shellP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.shell | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr>
						<td>USKR</td>
						<td>{{ this.tS.delivery?.uskr | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.unsound | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.mould > 0">
						<td class="pl-4">Unsound - Old/Mould</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.mouldP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.mould | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.onion > 0">
						<td class="pl-4">Unsound - Onion</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.onionP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.onion | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.immature > 0">
						<td class="pl-4">Unsound - Immature</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.immatureP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.immature | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.spot > 0">
						<td class="pl-4">Unsound - 791 Spot</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.spot791P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.spot | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.earlystink > 0">
						<td class="pl-4">Unsound - Early Stinkbug</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.earlystinkP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.earlystink | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.latestink > 0">
						<td class="pl-4">Unsound - Late Stinkbug</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.latestinkP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.latestink | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.germination > 0">
						<td class="pl-4">Unsound - Germination</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.germinationP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.germination | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.nutborer > 0">
						<td class="pl-4">Unsound - Nut Borer</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.nutborerP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.nutborer | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.discolouration > 0">
						<td class="pl-4">Unsound - Discolouration</td>
						<td class="pl-4">
							{{ +this.tS.delivery?.discolourationP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.discolouration | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr *ngIf="+this.tS.delivery?.above18 > 0">
						<td>DNIS - Above 18mm</td>
						<td>{{ +this.tS.delivery?.a18P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.above18 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.above20 > 0">
						<td>DNIS - Above 20mm</td>
						<td>{{ +this.tS.delivery?.a20P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.above20 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.above22 > 0">
						<td>DNIS - Above 22mm</td>
						<td>{{ +this.tS.delivery?.a22P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.above22 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.above24 > 0">
						<td>DNIS - Above 24mm</td>
						<td>{{ +this.tS.delivery?.a24P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.above24 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.above25 > 0">
						<td>DNIS - Above 25mm</td>
						<td>{{ +this.tS.delivery?.a25P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.above25 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<tr *ngIf="+this.tS.delivery?.s0 > 0">
						<td>Style - 0</td>
						<td>{{ +this.tS.delivery?.s0P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.s0 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.s1 > 0">
						<td>Style - 1</td>
						<td>{{ +this.tS.delivery?.s1P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.s1 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.s1s > 0">
						<td>Style - 1s</td>
						<td>{{ +this.tS.delivery?.s1sP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.s1s | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.s2 > 0">
						<td>Style - 2</td>
						<td>{{ +this.tS.delivery?.s2P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.s2 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.s4l > 0">
						<td>Style - 4L</td>
						<td>{{ +this.tS.delivery?.s4lP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.s4l | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.delivery?.s4r > 0">
						<td>Style - 4R</td>
						<td>{{ +this.tS.delivery?.s4rP | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.delivery?.s4r | number:'1.0-0' }}g)
						</td>
					</tr>
					<ng-container *ngIf="this.dDDS.columns['fullstyling']">
						<tr *ngIf="+this.tS.delivery?.s5 > 0">
							<td>Style - 5</td>
							<td>{{ +this.tS.delivery?.s5P | percent:'1.2' }} &nbsp; &nbsp;
								({{ +this.tS.delivery?.s5 | number:'1.0-0' }}g)
							</td>
						</tr>
						<tr *ngIf="+this.tS.delivery?.s6 > 0">
							<td>Style - 6</td>
							<td>{{ +this.tS.delivery?.s6P | percent:'1.2' }} &nbsp; &nbsp;
								({{ +this.tS.delivery?.s6 | number:'1.0-0' }}g)
							</td>
						</tr>
						<tr *ngIf="+this.tS.delivery?.s7 > 0">
							<td>Style - 7</td>
							<td>{{ +this.tS.delivery?.s7P | percent:'1.2' }} &nbsp; &nbsp;
								({{ +this.tS.delivery?.s7 | number:'1.0-0' }}g)
							</td>
						</tr>
						<tr *ngIf="+this.tS.delivery?.s8 > 0">
							<td>Style - 8</td>
							<td>{{ +this.tS.delivery?.s8P | percent:'1.2' }} &nbsp; &nbsp;
								({{ +this.tS.delivery?.s8 | number:'1.0-0' }}g)
							</td>
						</tr>
					</ng-container>
					<ng-container *ngIf="!this.dDDS.columns['fullstyling']">
						<tr *ngIf="+this.tS.delivery?.s567 > 0">
							<td>Style - 5/6/7</td>
							<td>{{ +this.tS.delivery?.s567P | percent:'1.2' }} &nbsp; &nbsp;
								({{ +this.tS.delivery?.s567 | number:'1.0-0' }}g)
							</td>
						</tr>
					</ng-container>
				</ng-template>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr><td></td><td></td></tr>

			</tbody>
		</table>
	</div>
</ng-container>
<ng-container *ngIf="this.tS.tablename=='samplesunassigned'">
	<div class="container" id="childelem">
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3">
				<h3>Sample: {{ this.tS.sampleUnA?.companyname }}: [{{ this.tS.sampleUnA?.cultivar }}] {{ this.tS.sampleUnA?.sampledate | date:'dd-MM-yyyy' }} {{ this.tS.sampleUnA?.sampletime.substr(0,5) }}</h3>
			</div>
			<div class="mr-4 ml-3">
				<button mdbBtn class="btn btn-primary" (click)="editSampleU()">Edit Sample</button>
			</div>
		</div>
		<br>
		<table class="table col-sm">
			<tbody>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Producer</td>
					<td><a (click)="viewCompany(this.tS.sampleUnA?.idproducer)">{{ this.tS.sampleUnA?.companyname }}</a></td>
				</tr>
				<tr *ngIf="this.tS.sampleUnA?.idproducer!=this.tS.sampleUnA?.idparent">
					<td>Parent</td>
					<td><a (click)="viewCompany(this.tS.sampleUnA?.idparent)">{{ this.tS.sampleUnA?.parent }}</a></td>
				</tr>
				<tr *ngIf="this.tS.sampleUnA?.farmid!=null">
					<td>Farm ID</td>
					<td>{{ this.tS.sampleUnA?.farmid }}</td>
				</tr>				
				<tr>
					<td>Sample Note</td>
					<td>{{ this.tS.sampleUnA?.notes }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Sample Date</td>
					<td>{{ this.tS.sampleUnA?.sampledate | date:'MMM d, EEE, y' }}</td>
				</tr>
				<tr>
					<td>Sample Time</td>
					<td>{{ this.tS.sampleUnA?.sampletime }}</td>
				</tr>
				<tr>
					<td>Cultivar</td>
					<td>{{ this.tS.sampleUnA?.cultivar }}</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>Moisture - Whole Nut (%)</td>
					<td>{{ +this.tS.sampleUnA?.moisture | percent:'1.2' }}</td>
				</tr>
				<tr>
					<td>Moisture - Kernel (%)</td>
					<td>{{ +this.tS.sampleUnA?.wetkernel | percent:'1.2' }}</td>
				</tr>
				<tr class="tableseperatorlight"><td></td><td></td></tr>
				<tr>
					<td>TKR</td>
					<td>
						{{ +this.tS.sampleUnA?.skr + +this.tS.sampleUnA?.uskr + +this.tS.sampleUnA?.darkP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.sound + +this.tS.sampleUnA?.unsound + +this.tS.sampleUnA?.dark | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr>
					<td>SKR</td>
					<td>
						{{ this.tS.sampleUnA?.skr | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.sound | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr>
					<td>Darks / Onion Ring</td>
					<td>
						{{ +this.tS.sampleUnA?.darkP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.dark | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr>
					<td>Shells</td>
					<td>{{ +this.tS.sampleUnA?.shellP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.shell | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr>
					<td>USKR</td>
					<td>{{ this.tS.sampleUnA?.uskr | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.unsound | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.mould > 0">
					<td class="pl-4">Unsound - Old/Mould</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.mouldP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.mould | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.onion > 0">
					<td class="pl-4">Unsound - Onion</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.onionP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.onion | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.immature > 0">
					<td class="pl-4">Unsound - Immature</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.immatureP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.immature | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.spot > 0">
					<td class="pl-4">Unsound - 791 Spot</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.spot791P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.spot | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.earlystink > 0">
					<td class="pl-4">Unsound - Early Stinkbug</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.earlystinkP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.earlystink | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.latestink > 0">
					<td class="pl-4">Unsound - Late Stinkbug</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.latestinkP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.latestink | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.germination > 0">
					<td class="pl-4">Unsound - Germination</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.germinationP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.germination | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.nutborer > 0">
					<td class="pl-4">Unsound - Nut Borer</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.nutborerP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.nutborer | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.discolouration > 0">
					<td class="pl-4">Unsound - Discolouration</td>
					<td class="pl-4">
						{{ +this.tS.sampleUnA?.discolourationP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.discolouration | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr *ngIf="+this.tS.sampleUnA?.above18 > 0">
					<td>DNIS - Above 18mm</td>
					<td>{{ +this.tS.sampleUnA?.a18P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.above18 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.above20 > 0">
					<td>DNIS - Above 20mm</td>
					<td>{{ +this.tS.sampleUnA?.a20P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.above20 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.above22 > 0">
					<td>DNIS - Above 22mm</td>
					<td>{{ +this.tS.sampleUnA?.a22P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.above22 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.above24 > 0">
					<td>DNIS - Above 24mm</td>
					<td>{{ +this.tS.sampleUnA?.a24P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.above24 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr *ngIf="+this.tS.sampleUnA?.s0 > 0">
					<td>Style - 0</td>
					<td>{{ +this.tS.sampleUnA?.s0P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.s0 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.s1 > 0">
					<td>Style - 1</td>
					<td>{{ +this.tS.sampleUnA?.s1P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.s1 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.s1s > 0">
					<td>Style - 1s</td>
					<td>{{ +this.tS.sampleUnA?.s1sP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.s1s | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.s2 > 0">
					<td>Style - 2</td>
					<td>{{ +this.tS.sampleUnA?.s2P | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.s2 | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.s4l > 0">
					<td>Style - 4L</td>
					<td>{{ +this.tS.sampleUnA?.s4lP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.s4l | number:'1.0-0' }}g)
					</td>
				</tr>
				<tr *ngIf="+this.tS.sampleUnA?.s4r > 0">
					<td>Style - 4R</td>
					<td>{{ +this.tS.sampleUnA?.s4rP | percent:'1.2' }} &nbsp; &nbsp;
						({{ +this.tS.sampleUnA?.s4r | number:'1.0-0' }}g)
					</td>
				</tr>
				<ng-container *ngIf="this.dDDS.columns['fullstyling']">
					<tr *ngIf="+this.tS.sampleUnA?.s5 > 0">
						<td>Style - 5</td>
						<td>{{ +this.tS.sampleUnA?.s5P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.sampleUnA?.s5 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.sampleUnA?.s6 > 0">
						<td>Style - 5</td>
						<td>{{ +this.tS.sampleUnA?.s6P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.sampleUnA?.s6 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.sampleUnA?.s7 > 0">
						<td>Style - 5</td>
						<td>{{ +this.tS.sampleUnA?.s7P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.sampleUnA?.s7 | number:'1.0-0' }}g)
						</td>
					</tr>
					<tr *ngIf="+this.tS.sampleUnA?.s8 > 0">
						<td>Style - 5</td>
						<td>{{ +this.tS.sampleUnA?.s8P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.sampleUnA?.s8 | number:'1.0-0' }}g)
						</td>
					</tr>
				</ng-container>
				<ng-container *ngIf="!this.dDDS.columns['fullstyling']">
					<tr *ngIf="+this.tS.sampleUnA?.s567 > 0">
						<td>Style - 5/6/7</td>
						<td>{{ +this.tS.sampleUnA?.s567P | percent:'1.2' }} &nbsp; &nbsp;
							({{ +this.tS.sampleUnA?.s567 | number:'1.0-0' }}g)
						</td>
					</tr>
				</ng-container>
				<tr class="tableseperator"><td></td><td></td></tr>
				<tr><td></td><td></td></tr>

			</tbody>
		</table>
	</div>
</ng-container>