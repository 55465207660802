<div class="container">
  <div class="row justify-content-between">
    <div class="col-xs-6 main-header">
      <h3>Payment: Delivery {{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.iddelivery | delivery }}, {{ ( this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment == "a" ? "Advanced " : (this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment == "b" ? "Bonus " : "" ) ) }}Instalment{{ ( this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment !== "a" && this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment !== "b" ? " " + this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment : "" ) }}</h3>
    </div>
    <div class="mr-4 ml-3 col-auto">
      <button mdbBtn class="btn btn-info" *ngIf="this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment!=='b' && !isAuthorised('accountant')" (click)="editPayment()">Edit Payment</button>
    </div>
  </div>
  <br>
  <table class="table col-sm">
    <tbody>
      <tr class="tableseperator"><td></td><td></td></tr>
      <tr>
        <td>Producer</td>
        <td><a (click)="viewCompany(this.tableService['payments'][this.tableService.clickedIndex['payments']]?.idcompany)">{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.companyname }}</a></td>
      </tr>
      <tr *ngIf="this.tableService['payments'][this.tableService.clickedIndex['payments']]?.farmid!=null">
        <td>Billing Farm ID</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.farmid }}</td>
      </tr>
      <tr *ngIf="this.tableService['payments'][this.tableService.clickedIndex['payments']]?.idcompany!=this.tableService['payments'][this.tableService.clickedIndex['payments']]?.idparent">
        <td>Parent</td>
        <td><a (click)="viewCompany(this.tableService['payments'][this.tableService.clickedIndex['payments']]?.idparent)">{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.parent }}</a></td>
      </tr>
      <tr *ngIf="this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment!=='b'">
        <td>Delivery</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.iddelivery | delivery }}</td>
      </tr>
      <tr>
        <td>Instalment</td>
        <td>{{ ( this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment == "a" ? "Advanced" : (this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment == "b" ? "Bonus" : this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment ) ) }}</td>
      </tr>
      <tr>
        <td>{{ ( this.tableService['payments'][this.tableService.clickedIndex['payments']]?.instalment=='b' ? "Percentage of All Payments" : "Percentage of Total" )}}</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.paypercent | percent:'1.0-2' }}</td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
      <tr>
        <td>Due Date</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.paydate | date:'MMM d, EEE' }}</td>
      </tr>
      <tr>
        <td>Paid Date</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.paiddate | date:'MMM d, EEE' }}</td>
      </tr>
      <tr>
        <td>Payment Currency</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.paycurrency }}</td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
      <tr>
        <td>Amount Due (R)</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountdueZAR | currency:'ZAR':'symbol-narrow' }}</td>
      </tr>
      <tr>
        <td>Amount Paid (R)</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountpaid | currency:'ZAR':'symbol-narrow' }}</td>
      </tr>
      <tr>
        <td>Amount Outstanding (R)</td>
        <td>{{ +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountdueZAR - +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountpaid | currency:'ZAR':'symbol-narrow' }}</td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
      <tr>
        <td>Amount Due ($)</td>
        <td>{{ +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountdueZAR / +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.fxrate | currency:'USD':'symbol-narrow' }}</td>
      </tr>
      <tr>
        <td>Amount Paid ($)</td>
        <td>{{ +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountpaidUSD | currency:'USD':'symbol-narrow' }}</td>
      </tr>
      <tr>
        <td>Amount Outstanding ($)</td>
        <td>{{ +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountdueZAR / +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.fxrate - +this.tableService['payments'][this.tableService.clickedIndex['payments']]?.amountpaidUSD | currency:'USD':'symbol-narrow' }}</td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
      <tr>
        <td>FX Rate</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.fxrate | number:'1.3-3' }}</td>
      </tr>
      <tr>
        <td>FX Date</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.fxdate | date:'MMM d, EEE' }}</td>
      </tr>
      <tr>
        <td>FX Source</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.fxsource | titlecase }}</td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
      <tr>
        <td>EFT Number</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.eft }}</td>
      </tr>
      <tr>
        <td>Note</td>
        <td>{{ this.tableService['payments'][this.tableService.clickedIndex['payments']]?.notes }}</td>
      </tr>
      <tr class="tableseperator"><td></td><td></td></tr>
    </tbody>
  </table>
  <br><br>  
</div>