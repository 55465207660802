<div *ngIf="isAuthenticated()" class="container">
	<div class="row mt-3">
		<div class="col">
			<div class="my-4 col">
				<h2>Packing List</h2>
			</div>
			<br>
			<div class="row justify-content-md mx-1">	
				<div class="col">
					<div class="form-container">	
						<mat-form-field>
					    <mat-select placeholder="Select Order Reference" [(ngModel)]="this.packingService.orderref">
					      <mat-option (click)="loadForLogging()" value="inventory">* Current Inventory *</mat-option>
					      <mat-option *ngFor="let order of this.dropDownDataService.dropdown.traceOrderList" (click)="loadForLogging()" value="{{ order.orderref }}">{{ order.orderref }}</mat-option>
					    </mat-select>
					  </mat-form-field>
					</div>
					<br>
					<div class="col" [ngClass]="showTrace">
						<div>
							<div class="row justify-content-between">
								<div class="col main-header">
									<h2>Order Reference: {{ this.packingService.orderref }}</h2>
								</div>
								<div class="mr-4 ml-3 col-auto">
									<button mdbBtn class="btn btn-primary" (click)="csvDownload()">CSV</button>
								</div>
							</div>
						</div>
						<br>
						<mat-form-field class="filter-wide">
						  <input matInput type="text" (keyup)="applyFilter($event)" placeholder="Filter">
						</mat-form-field>
						<div class="example-container mat-elevation-z8">
							<div class="example-loading-shade"
						       *ngIf="isLoadingResults">
						    <mat-spinner *ngIf="isLoadingResults" color="accent"></mat-spinner>
						  </div>
							<div class="example-table-container">
							  <table mat-table matSort [dataSource]="datasrc" hover="true" class="example-table">
							  	<ng-container matColumnDef="packdate" sticky>
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Packing Date </th>
							      <td mat-cell *matCellDef="let element"> {{ element.packdate | date:'MMM d, EEE' }} </td>
							      <td mat-footer-cell *matFooterCellDef> Total </td>
							    </ng-container>
							  	<ng-container matColumnDef="style">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Style </th>
							      <td mat-cell *matCellDef="let element"> {{ element.style }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							    <ng-container matColumnDef="variety">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Variety </th>
							      <td mat-cell *matCellDef="let element"> {{ ( element.variety == 'I' ? 'Integ' : 'Hybrid' ) }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							   <ng-container matColumnDef="total">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ headerUnit() }} </th>
							      <td mat-cell *matCellDef="let element"> {{ element.total }}</td>
							      <td mat-footer-cell *matFooterCellDef> {{ calTotal('total') }} </td>
							    </ng-container>
							   <ng-container matColumnDef="source">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
							      <td mat-cell *matCellDef="let element"> {{ element.source }}</td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							   <ng-container matColumnDef="label">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Label Code </th>
							      <td mat-cell *matCellDef="let element"> {{ element.label }}</td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							   <ng-container matColumnDef="batch">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Batch </th>
							      <td mat-cell *matCellDef="let element"> {{ element.batch }}</td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							  	<ng-container matColumnDef="dispatchdate">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dispatched </th>
							      <td mat-cell *matCellDef="let element"> {{ element.dispatchdate | date:'MMM d, EEE' }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							  	<ng-container matColumnDef="intakestart">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Delivery </th>
							      <td mat-cell *matCellDef="let element"> {{ element.intakestart | date:'MMM d, EEE' }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							  	<ng-container matColumnDef="intakeend">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Delivery </th>
							      <td mat-cell *matCellDef="let element"> {{ element.intakeend | date:'MMM d, EEE' }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							    <ng-container matColumnDef="orderref">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Reference </th>
							      <td mat-cell *matCellDef="let element"> {{ element.orderref }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							    <ng-container matColumnDef="ordernumber">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Reference </th>
							      <td mat-cell *matCellDef="let element"> {{ element.ordernumber }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							    <ng-container matColumnDef="status">
							      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Status </th>
							      <td mat-cell *matCellDef="let element"> {{ element.status | titlecase }} </td>
							      <td mat-footer-cell *matFooterCellDef>  </td>
							    </ng-container>
							    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
							    <tr mat-row 
							    	*matRowDef="let row; let i = index; columns: displayedColumns;"
							    	style="cursor: pointer"
							    	class="example-element-row"></tr>
				 					<tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="example-first-footer-row"></tr>
							  </table>
							</div>
						</div>
						<br>
						<br>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-teamlogin></app-teamlogin>
</div>