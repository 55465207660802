import { Injectable } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as FileSaver from 'file-saver';
import * as xlsx from "xlsx";
import { PortalService } from '../auth/portal.service';
//import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Injectable()
export class CsvService{
  private nudgeKg: boolean;
  private nudgeCur: boolean;
  private shift: number;
  private negOffset: number;
  private nOffset: number;
  private rowCorr: number;
  private firstdatarow: number;
  private title: string;
  private exportData: any [] = [];
  private headers: any [] = [];
  private d: Date = new Date();
  private exportMonth = ("0" + (this.d.getMonth() + 1)).slice(-2);
  private exportDate = this.d.getFullYear() + '-' + this.exportMonth + '-' + ("0" + this.d.getDate()).slice(-2);

	constructor(
    public portalService: PortalService) {}

	csvDownload(datasrc, displayedColumns, title) {
    this.resetVars();
    var tempcell;
    for (var i = 0; i < datasrc.filteredData.length; i++) {
      this.exportData[i] = {};
    }
    for (var j = 0; j < displayedColumns.length; j++) {
      this.correctHeaders(document.getElementsByClassName("example-element-row")[0]['children'][j]['innerText'], j);

      for (var i = 0; i < datasrc.filteredData.length; i++) {
        if (i == 0) {
          this.logHeader(document.getElementsByClassName("mat-sort-header")[j]['innerText'], i, j);
        }
        this.logCell(document.getElementsByClassName("example-element-row")[i]['children'][j]['innerText'], i, j);
      }
      if (document.getElementsByClassName("example-first-footer-row")[0]!==undefined) {
        this.logCell(document.getElementsByClassName("example-first-footer-row")[0]['children'][j]['innerText'], datasrc.filteredData.length, j) 
      }
    }
    this.title = title;
    this.saveCSV();
  }

  csvSimple(table, title, maxcols = 0) {
    this.resetVars();
    var tbody = table.children[0];
    var rowtext = [];
    for (var j = 0; j < (maxcols==0 ? tbody.children[1].children.length : maxcols); j++) {
      this.firstdatarow = 0;
      for (var i = 0; i < tbody.children.length; i++) {
        if (this.firstdatarow<2 && tbody.children[i].children[0].innerText!=='') {
          if (this.firstdatarow==1) {
            this.correctHeaders(tbody.children[i].children[j].innerText, j);
            break;
          }
          this.firstdatarow += 1;
        }
      }
      this.rowCorr = 0;
      for (var i = 0; i < tbody.children.length; i++) {
        rowtext[i] = '';
        for(var k = 0; k < tbody.children[i].children.length; k++) {
          rowtext[i] = rowtext[i] + tbody.children[i].children[k].innerText
        }
        if (rowtext[i]=='') {
          this.rowCorr -= 1;
        } else {
          this.correctCells(tbody.children[1].children[j].innerText, tbody.children[i].children[j].innerText, i, j);
        }
      }
    }
    this.title = title;
    this.saveCSV();
  }

  correctHeaders(tempcell, j) {
    this.nudgeKg = false;
    this.nudgeCur = false;
    if (tempcell.substr(-3) == " kg") {
      this.shift = this.shift + 1;
      this.nudgeKg = true;
    }
    this.negOffset = (tempcell.substr(0,1) == '-' && tempcell.length > 1 ? 1 : 0);
    if (tempcell !== "-" && isNaN(tempcell.substr(0 + this.negOffset,1)) && !isNaN(tempcell.substr(1 + this.negOffset, tempcell.length - 1 - this.negOffset).replace(',','')) && !(!isNaN(tempcell.substr(0 + this.negOffset +1,1)) && (tempcell.substr(0 + this.negOffset,1)=='P' || tempcell.substr(0 + this.negOffset,1)=='S')) ) {
      this.shift = this.shift + 1;
      this.nudgeCur = true;
    }
  }

  correctCells(header,tempcell, i, j) {
    if (i==this.firstdatarow) {
      this.logHeader(header, i , j);
    }
    if (i > this.firstdatarow) {
      i -= 1;
      this.logCell(tempcell, i , j);
    }
  }

  logHeader(header, i , j) {
    if (this.nudgeCur) {
      if (header=='Cultivar') {
        this.shift = this.shift - 1;
        this.nudgeCur = false;
      } else {
        this.headers[j + this.shift - 1] = 'Currency:';
      }
      this.headers[j + this.shift] = header;
    } else {            
      if (this.nudgeKg) { 
        this.headers[j + this.shift] = ':Unit';
      }
      this.headers[j + this.shift - (this.nudgeKg ? 1 : 0)] = header;
    }
  }

  logCell(tempcell, i, j) {
    if (this.exportData[i + this.rowCorr]==undefined) {
      this.exportData[i + this.rowCorr] = {};
    }
    if (this.nudgeKg) {
      this.exportData[i + this.rowCorr][j + this.shift - 1] = (tempcell.length > tempcell.length - 3 ? tempcell.substr(0, tempcell.length - 3) : '');
      this.exportData[i + this.rowCorr][j + this.shift] = (tempcell.length > tempcell.length - 3 ? 'kg' : '');    
    } else if (this.nudgeCur) {
      this.nOffset = (tempcell.substr(0,1) == '-' ? 1 : 0);
      this.exportData[i + this.rowCorr][j + this.shift - 1] = (tempcell.length > 1 ? tempcell.substr(0 + this.nOffset, 1) : '');
      this.exportData[i + this.rowCorr][j + this.shift] = (this.nOffset == 1 ? '-' : '') + (tempcell.length - 1 - this.nOffset > 0 ? tempcell.substr(1 + this.nOffset, tempcell.length - 1 - this.nOffset) : '');
    } else {
      this.exportData[i + this.rowCorr][j + this.shift] = '' + tempcell;
    }
  }

  saveCSV(labels = true, bom = true) {
    const options = { 
     fieldSeparator: ',',
     quoteStrings: '"',
     decimalseparator: '.',
     showLabels: labels, 
     showTitle: labels,
     title: this.portalService.portalMod()['portal'] + ' - ' + this.title,
     useBom: bom,
     noDownload: false,
     headers: this.headers,
     nullToEmptyString: true,
    };
    new ngxCsv(this.exportData, this.portalService.portalMod()['portal'] + ' - ' + this.title + ' ' + this.exportDate + (labels ? '' : ' ' + this.d.toTimeString().substr(0, this.d.toTimeString().search(' '))), options);
    this.resetVars();
  }

  rawCSV(title,headers,data,basicmode = false) {
    this.title = title;
    this.headers = headers;
    this.exportData = data;
    this.saveCSV(!basicmode, false);
  }

  resetVars() {
    this.headers = [];
    this.exportData = [];
    this.nudgeKg = false;
    this.nudgeCur = false;
    this.shift = 0;
    this.negOffset = 0;
    this.nOffset = 0;
    this.rowCorr = 0;
    this.firstdatarow = 0;
  }

  saveAsExcelFile(buffer: any, filename: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }

  exportExcel(filename: string, data: any, labels = true) {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array', Props: {Author:this.portalService.portalMod()['portal'], Title:filename } });
      filename = this.portalService.portalMod()['portal'] + ' - ' + filename + ' ' + this.exportDate + (labels ? '' : ' ' + this.d.toTimeString().substr(0, this.d.toTimeString().search(' ')));
      this.saveAsExcelFile(excelBuffer, filename);
    });
  }


}