import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { Contact } from '../../../interfaces/contacts.interface';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class RemittanceRebuilderService{
	private postBody: {};
	httpRes: any;
	public createform: boolean;
	public editmode: boolean = false;
	public archived: boolean = false;
	public loading: boolean = false;
	public stopLoading: boolean = false;
	public stillLoading: boolean = true;
	public idcontact: number = 0;
	public loadedEntry: Contact;
  public season: any;
  public location: number;
  public producer: string = "0";
  public delstartdate: string = '';
  public pricemodel: string = "0";
  public newpricemodel: string = "0";
  public reassignPriceModel: boolean = false;
  public selectoutonly: boolean = false;
  public deliveries: boolean = false;
  public sendemail: boolean = false;
  public customfx: boolean = false;
  public part: number = 1;
  public unit: number = 10;
  public maxrebuilds: number = 50;
  public fxrate: number = 0;
	public items: any[] = [
  	{"iddelivery": ""}
	];

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
		private portalService: PortalService) {}
	
	addItem() {
		this.items.push({
			"iddelivery": ""
		 });
	}

	removeItem(index: number) {
		this.items.splice(index, 1);
	}

	rebuildRemittances() {
		this.loadData(this.part);
	}

	async loadData(part = 1) {
		if (this.maxrebuilds>Math.max(0,(part-1) * this.unit)) {
			this.loading = (part==1);
			this.stillLoading = true;
			var ids = (this.deliveries ? this.groupDels() : '');
			var p = {
				"location": +this.location,
				"season": this.season,
				"producer": +this.producer,
				"delstartdate": this.delstartdate,
				"pricemodel": +this.pricemodel,
				"newpricemodel": (this.reassignPriceModel ? +this.newpricemodel : 0),
				"ids": ids,
				"unit": this.unit,
				"fxrate": (this.customfx ? this.fxrate : 0),
				"selectoutonly": (this.selectoutonly ? 1 : 0),
				"sendemail": (this.sendemail ? 1 : 0),
				"part": part
			}
			const response = await this.loginService.loadData('rebuildRemittances', p);
			const httpRes = await this.storeResult(response, 'rebuildRemittances');
			return true;
		} else {
			return true;
		}
	}

	async storeResult(rawData, data: string) {
		if (rawData['total']<this.unit) {
	  	this.loginService.toast(rawData['message'],rawData['fullmessage'], 'success', 4000);
    	this.resetForm();
			this.loading = false;
			this.stopLoading = true;
			this.stillLoading = false;
		}
		if (rawData['part']!==undefined && !this.stopLoading) {
	  	this.loginService.toast(rawData['message'],rawData['fullmessage'] + ' : still loading...', 'success', 4000);
			if (rawData['remsChanged'].length>0) {
				this.loadData(+rawData['part'] + 1);
			}
		}
		if (rawData['part']==undefined) {
	    this.loginService.toast("Rebuild Unsuccessful",rawData['message'],'warn');
			this.loading = false;
			this.stopLoading = true;
			this.stillLoading = false;
		}
	}

	groupDels() {
		var ids = "";
		for (var i = 0; i < this.items.length; i++) {
			ids = ids + (ids.length>0 ? ',' : '') + this.items[i].iddelivery;
		}
		return ids;
	}

  resetForm() {
    window.scroll(0,0);
    this.deliveries = false;
    this.selectoutonly = false;
    this.sendemail = false;
  	this.customfx = false;
    this.producer = "0";
    this.delstartdate = '';
    this.pricemodel = "0";
    this.newpricemodel = "0";
    this.reassignPriceModel = false;
    this.unit = 10;
    this.part = 1;
    this.fxrate = 1;
    this.location = this.loginService.location;
    this.season = this.loginService.season;
    this.items = [
	  	{"iddelivery": ""}
		];
  }

  checkIdd(index,idd, id) {
  	var checkProducer = (+this.producer>0);
		var ans = true;
		if (checkProducer) {
			if (this.dropDownDataService.dropdown.sampleListComplete[id]['idproducer']!=this.producer) {
				ans = false;
			}			
		}
		if (ans) {
			for (var i = 0; i < this.items.length; i++) {
				if (this.items[i]['iddelivery']==idd && index!==i) {
					ans = false;
				}
			}
		}
		return ans;
	}

	fixDate(field) {
		if (this[field]!==null && this[field]!=='') {
	  	this[field] = moment(this[field]).format('YYYY-MM-DD');
	  }
	}

}