import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { AddProcessingService } from './add-processing.service';

@Component({
  selector: 'app-processing',
  templateUrl: './add-processing.component.html',
  styleUrls: ['./add-processing.component.scss']
})
export class AddProcessingComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	public loginService: TeamLoginService,
  	public dropDownDataService: DropDownDataService,
    public addProcessingService: AddProcessingService,
  	) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.addProcessingService.editmode = false;
    this.d.setDate(this.d.getDate() -1);
    this.addProcessingService.date = this.d.toISOString().substring(0,10);
    this.dropDownDataService.getDropdown("locationList");
    this.dropDownDataService.getDropdown("storageListWNIS");
    this.dropDownDataService.getDropdown("processingList");
    this.getPList();
    this.dropDownDataService.getDropdown("recentNISstock");
  }

  ngOnDestroy() {
    this.addProcessingService.resetForm();
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  canSave() {
    if (
        this.addProcessingService.date !== undefined &&
        this.addProcessingService.batch !== undefined &&
        +this.addProcessingService.quantity >= 0
      ) {
      if (
        this.addProcessingService.process == 'nis' && 
        (this.addProcessingService.quantity2 == undefined || 
        this.addProcessingService.destination == undefined || 
        this.addProcessingService.overfill == undefined)) {
        return false
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.addProcessingService.resetForm();
    this.addProcessingService.editmode = state;
  }

  editSelected() {
    return (this.addProcessingService.editmode == false || (this.addProcessingService.editmode == true && this.addProcessingService.idprocessing > 0));
  }

  onCalculate() {
    if (this.canSave()) {
      this.addProcessingService.addProcessing();
      window.scroll(0,0);
      this.addProcessingService.resetForm();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete processing entry for " + this.addProcessingService.process + " on " + this.addProcessingService.date + "?");
    if (res) {
      this.addProcessingService.deleteEntry();
      this.addProcessingService.resetForm();
    }
  }

  getPList() {
    this.dropDownDataService.getDropdown("processList", +this.dropDownDataService.location['idlocation'], this.addProcessingService.date);
    this.addProcessingService.process = 'cracking';
  }

  checkSource(storage) {
    if (typeof this.dropDownDataService.dropdown.processList['processes'][storage.idbatch]==undefined) {
      return true;
    }
    if (this.dropDownDataService.dropdown.processList['processes'].length==0) {
      return true;
    } else {

      if (this.dropDownDataService.dropdown.processList['processes'][storage.idbatch + '']==undefined) {
        return true;
      } else {
        return this.dropDownDataService.dropdown.processList['processes'][storage.idbatch + ''][this.addProcessingService.process];
      }
    }
  }

  checkBans(storage,type) {
    if (this.addProcessingService.batch!=null) {
      if (storage.idbatch!=undefined) {
        if (this.addProcessingService.batch['idbatch']==storage.idbatch) {
          return false;      
        }
      }
      if (storage.idstorage==this.addProcessingService.batch['idstorage']) {
        return false;
      }
      if (this.addProcessingService.process=='transfer') {
        return true;
      }
      if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']]!==undefined) {
        for (const p in this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']]) {

          if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['process']=='mix' && this.addProcessingService.process=='mix') {
            if (type=='batch2') {
              if (this.addProcessingService.destination!=null) {
                if (this.addProcessingService.destination==storage.idstorage) {
                  return false;
                }
                if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['destination']==this.addProcessingService.destination && this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['mix2']==storage.idbatch) {
                  return false;
                } else {
                  return true;
                }
              }
            }
            if (type=='destination') {
              if (this.addProcessingService.batch2!=null) {
                if (this.addProcessingService.batch2['idstorage']==storage.idstorage) {
                  return false;
                }
                if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['mix2']==this.addProcessingService.batch2['idbatch'] && this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['destination']==storage.idstorage) {
                  return false;
                } else { 
                  return true;
                }
              }
            }
          }

          if (this.addProcessingService.process=='nis' && this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p].length>0) {
            if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['process']==this.addProcessingService.process) {
              if (type=='destination' && this.addProcessingService.overfill!=null) {
                if (this.addProcessingService.overfill==storage.idstorage) {
                  return false;
                }
                if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['overfill']==this.addProcessingService.overfill && this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['destination']==storage.idstorage) {
                  return false;
                } else {
                  return true;
                }
              }
              if (type=='overfill' && this.addProcessingService.destination!=null) {
                if (this.addProcessingService.destination==storage.idstorage) {
                  return false;
                }
                if (this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['destination']==this.addProcessingService.destination && this.dropDownDataService.dropdown.processList['bans'][this.addProcessingService.batch['idbatch']][p]['overfill']==storage.idstorage) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  }

  setOutputs() {
    if (this.addProcessingService.process=='nis') {
      this.addProcessingService.destination = (this.dropDownDataService.dropdown.processList['destination'].length==1 ? this.dropDownDataService.dropdown.processList['destination'][0]['idstorage'] : '11');
      this.addProcessingService.overfill = (this.dropDownDataService.dropdown.processList['overfill'].length==1 ? this.dropDownDataService.dropdown.processList['overfill'][0]['idstorage'] : '27');
    }
  }

  selectNISweight() {
    this.addProcessingService.autonis = false;
    this.addProcessingService.quantity2 = null;
    this.addProcessingService.selectedNISweight = null;
    this.addProcessingService.selectedNIScount = null;
    for (var i = 0; i < this.dropDownDataService.dropdown["recentNISstock"].length; i++) {
      if (this.dropDownDataService.dropdown["recentNISstock"][i]['batchdate']==this.addProcessingService.date) {
        this.addProcessingService.selectedNISweight = this.dropDownDataService.dropdown["recentNISstock"][i]['total'];
        this.addProcessingService.selectedNIScount = this.dropDownDataService.dropdown["recentNISstock"][i]['count'];
      }
    }
  }

  setAutoFill() {
    if (this.addProcessingService.autonis && this.addProcessingService.selectedNISweight!=undefined) {
      this.addProcessingService.quantity2 = this.addProcessingService.selectedNISweight;
    } else {
      this.addProcessingService.quantity2 = null;
    }
  }

  canAutofill() {
    return this.addProcessingService.selectedNISweight==undefined;
  }

}