import { Component, OnInit } from '@angular/core';

import { LogisticsLoginService } from './auth/login/login.service';

@Component({
  selector: 'logisticsapp-root',
  templateUrl: './logisticsapp.component.html',
  styleUrls: ['./logisticsapp.component.scss'],
  providers: []
})
export class LogisticsAppComponent {
  loadedFeature: any = 'login';

  constructor(
  	private loginService: LogisticsLoginService) {}

  ngOnInit() {
  }

  onNavigate(feature: Event) {
  	this.loadedFeature = feature;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }
  
}