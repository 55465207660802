<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3">
				<h2>Scheduling</h2>
			</div>
		</div>
		<br>

		<div id="chartdiv" style="width: 100%; height: 500px"></div>

	</div>
</div>