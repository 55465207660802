import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../dropdown.service';
import { AddDeliveryService } from './add-delivery.service';
import { PortalService } from '../../../auth/portal.service';
import { TableDataService } from '../../overview/table/table.service';


@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss']
})
export class AddDeliveryComponent implements OnInit {
	d: Date = new Date();

  constructor(
  	private loginService: TeamLoginService,
  	public dDS: DropDownDataService,
    public tableService: TableDataService,
    public aDS: AddDeliveryService,
    public portalService: PortalService
  	) { }

  ngOnInit() {
    window.scroll(0,0);
    this.dDS.getDropdown("newDeliveryNumber");
    this.dDS.getDropdown("deliveryList");
    this.dDS.getDropdown("locationList");
    this.dDS.getDropdown("producerList");
    this.dDS.getDropdown("contactList");
    this.dDS.getDropdown("priceTermsList");
    this.dDS.getDropdown("priceModelList");
    this.dDS.getDropdown("cultivarProfileList");
    this.dDS.getDropdown("cultivarList");
    this.dDS.getDropdown("depotList");
    this.dDS.getDropdown("storageListWNIS");
    this.aDS.deliverydate = this.d.toISOString().substring(0,10);
    this.aDS.deliverytime = "12:00";
    this.aDS.loaddate = null;
    this.aDS.season = +this.loginService.season;
    this.aDS.pricemodel = "0";
    this.aDS.paypercent = "0";
    this.aDS.payday = "0";
    this.loadDefaultModel();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
   }

  ngOnDestroy() {
    this.aDS.resetForm();
    this.aDS.editmode = false;
    this.tableService.loadData('deliveries');
  }

  async loadDefaultModel() {
    await this.dDS.waitDD('priceModelList');
    this.dDS.dropdown['defaultPriceModel'] = await this.loginService.loadData('defaultPriceModel');
    await this.aDS.setDefaultMethod();
  }

  updateContacts(producer: string) {
    this.aDS.getAddMethod();
    this.dDS.setCompanyContacts(producer);
    this.aDS.thisCompanyContacts = this.dDS.thisCompanyContacts;
    this.aDS.thisCompanyContact = this.dDS.thisCompanyContacts[0]['idcontact'];
  }
 
  canSave() {
    if (
        this.aDS.season !== undefined &&
        this.dDS.location['idlocation'] !== undefined &&
        this.dDS.getNewDeliveryNumber !== undefined &&
        this.aDS.deliverydate !== undefined &&
        this.aDS.deliverytime !== undefined &&
        (this.aDS.loaddate==null ? true : this.aDS.deliverydate <= this.aDS.loaddate) &&
        this.aDS.producer !== undefined &&
        this.aDS.thisCompanyContacts !== undefined &&
        +this.aDS.wnis >= 0 &&
        +this.aDS.scalewnis > 0 &&
        +this.aDS.smalls >= 0 &&
        +this.aDS.cultivar > 0 &&
        (this.aDS.payday.split(";").length == this.aDS.paypercent.split(";").length)
      ) {
      if (this.aDS.checkTerms()==false) {return false;}
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    if (
        this.aDS.season !== undefined &&
        this.dDS.location['idlocation'] !== undefined &&
        this.aDS.iddelivery !== undefined &&
        this.aDS.deliverydate !== undefined &&
        this.aDS.deliverytime !== undefined &&
        (this.aDS.loaddate==null ? true : this.aDS.deliverydate <= this.aDS.loaddate) &&
        this.aDS.producer !== undefined &&
        this.aDS.thisCompanyContacts !== undefined &&
        +this.aDS.wnis >= 0 &&
        +this.aDS.scalewnis > 0 &&
        +this.aDS.smalls >= 0 &&
        +this.aDS.cultivar > 0 &&
        (this.aDS.paydayU == undefined && this.aDS.paypercentU == undefined ? true : this.aDS.paydayU.split(";").length == this.aDS.paypercentU.split(";").length)
      ) {
      if (this.aDS.checkTerms()==false) {return false;}
      return true;
    } else {
      return false;
    }
  }

  editModeOn(state) {
    this.aDS.iddelivery = 0;
    this.aDS.resetForm();
    this.dDS.getDropdown("deliveryList");
    this.dDS.getDropdown("priceTermsList");
    this.aDS.editmode = state;
  }

  editSelected() {
    return (this.aDS.editmode == false || (this.aDS.editmode == true && this.aDS.delivery > 0));
  }

  loadForEdit() {
    this.aDS.resetForm(true);
    this.aDS.loadForEdit();
  }

  saveDelivery() {
    if (this.canSave()) {
      this.aDS.location = this.dDS.location['idlocation'];
      this.aDS.delivery = this.dDS.getNewDeliveryNumber;
      this.aDS.addDelivery();
      window.scroll(0,0);
      this.aDS.resetForm();
    this.tableService.loadData('deliveries');
    }
  }

  saveChanges() {
    if (this.canUpdate()) {
      this.aDS.saveChanges();
      this.tableService.loadData('deliveries');
      this.editModeOn(false);
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete delivery number " + this.aDS.iddelivery + "?");
    if (res) {
      this.aDS.deleteEntry();
      this.tableService.loadData('deliveries');
      this.aDS.resetForm();
    }
  }

  clearFields() {
    this.aDS.iddepot = undefined;
    this.aDS.iddeldepot = undefined;
    this.aDS.depotdate = null;
    this.aDS.dehusking = null;
    this.aDS.huskmass = null;
    this.aDS.rejecthuskmass = null;
    this.aDS.depotsmalls = null;
    this.aDS.depotwnis = null;
    this.aDS.depotwnisd = null;
    this.dDS.tagserials = null;    
    this.dDS.tags = [{"serial": ""}];    
  }
  

}