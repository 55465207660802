import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../dropdown.service';
import { AddCompanyService } from './add-company.service';
import { CountryService } from '../../../services/countries.service';
import { TableDataService } from '../../overview/table/table.service';
//import { TeamCompanyService } from '../../overview/companies/teamcompanies.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
	d: Date = new Date();
  form: FormGroup;
  formFarmId: FormGroup;

  constructor(
    private fb: FormBuilder,
  	private loginService: TeamLoginService,
  	public dDDS: DropDownDataService,
    public tableService: TableDataService,
    public aCS: AddCompanyService,
    public countryService: CountryService,
    public pS: PortalService
  	) { }

  ngOnInit() {
    this.form = this.fb.group({
      companyname: ['', [Validators.required, this.forbiddenStringValidator.bind(this)]]
    });
    this.formFarmId = this.fb.group({
      farmid: ['', [Validators.required, this.forbiddenStringValidatorFarmId.bind(this)]]
    });
    window.scrollTo(0,0);
    this.aCS.resetForm();
    this.dDDS.getDropdown("companyList");
    this.dDDS.getDropdown("producerList");
    this.dDDS.getDropdown("mainContactList");
    this.dDDS.getDropdown("cultivarList");
    this.dDDS.getDropdown("priceModelList");
    this.dDDS.getDropdown("priceTermsList");
    this.aCS.companytype = "producer";
    this.aCS.parent = "";
    this.aCS.contact = "0";
    this.aCS.priceseason = this.loginService.season;
    this.aCS.pricemodel = "0";
    this.aCS.paypercent = "0";
    this.aCS.payday = "0";
    this.countryService.country = {"id": this.countryService.countries[159]['id'], "country": "South Africa"};
    this.countryService.setState(+this.countryService.country.id);
  }

  ngOnDestroy() {
    this.aCS.editmode=false;
    this.aCS.resetForm();
    this.tableService.loadData('companies');
  }

  forbiddenStringValidator(control) {
    if (control.value==undefined || control.value==null) {
      return null;
    }
    const forbiddenStrings = this.dDDS.dropdown.companyList;
    if (control.value.trim()==this.aCS.editcompanyname) {
      return null;
    }   
    const forbidden = forbiddenStrings.some(obj => (obj.companyname==null ? null : obj.companyname.trim().toLowerCase()) === (control.value==null ? null : control.value.trim().toLowerCase() ));    
    return forbidden ? { 'forbiddenString': true } : null;
  }

  forbiddenStringValidatorFarmId(control) {
    if (control.value==undefined || control.value==null) {
      return null;
    }
    const forbiddenStrings = this.dDDS.dropdown.companyList;
    const forbidden = forbiddenStrings.some(obj => (obj.farmid==null ? null : obj.farmid.trim()) === control.value.trim());
    return forbidden ? { 'forbiddenString': true } : null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  isAuthorised(permission: string) {
    return this.loginService.isAuthorised(permission);
  }
 
  canSave() {
    if (
        this.aCS.companytype !== undefined &&
        (this.aCS.companyname !== undefined ? this.aCS.companyname.replace(/ /g, "") !== "" : false) &&
        (this.aCS.firstname !== undefined ? this.aCS.firstname.replace(/ /g, "") !== "" : false) &&
        (this.aCS.lastname !== undefined ? this.aCS.lastname.replace(/ /g, "") !== "" : false) &&
        (this.aCS.tel !== undefined ? this.aCS.tel.replace(/ /g, "") !== "" : false) &&
        (this.aCS.email !== undefined ? this.aCS.email.replace(/ /g, "") !== "" : false) &&
        this.countryService.country.country !== undefined &&
        (this.form.status == "VALID") &&
        (((this.aCS.payday.split(";").length == this.aCS.paypercent.split(";").length) &&
        this.cultivarsPresent()) || this.aCS.companytype!='producer') &&
        this.aCS.checkFarmIdUnique()
      ) {
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    if (
        this.aCS.companytype !== undefined &&
        +this.aCS.contact > 0 &&
        (this.form.status == "VALID") && 
        (this.aCS.companyname !== undefined ? this.aCS.companyname.replace(/ /g, "") !== "" : false) &&
        (this.aCS.tel !== undefined ? this.aCS.tel.replace(/ /g, "") !== "" : false) &&
        (this.aCS.email !== undefined ? this.aCS.email.replace(/ /g, "") !== "" : false) &&
        this.countryService.country.country !== undefined &&
        (((this.aCS.payday.split(";").length == this.aCS.paypercent.split(";").length) &&
        this.cultivarsPresent()) || this.aCS.companytype!='producer') &&
        this.aCS.checkFarmIdUnique()
      ) {
      return true;
    } else {
      return false;
    }
  }

  cultivarsPresent() {
    if (this.aCS.companytype == 'producer') {
      for (var i = 0; i < this.aCS.cultivars.length; i++) {
        if (this.aCS.cultivars[i]['idcultivar'] == '') {
          return false;
        }
      }
    }
    return true;
  }

  editModeOn(state) {
    this.aCS.idcompany = 0;
    this.aCS.resetForm();
    this.aCS.editmode = state;
  }

  editSelected() {
    return (this.aCS.editmode == false || (this.aCS.editmode == true && this.aCS.idcompany > 0));
  }

  loadForEdit() {
    this.aCS.resetForm();
    this.aCS.loadForEdit();
  }

  async loadContactDetails() {
    if (this.aCS.editmode) {
      const res = await this.loginService.confirm("Update company's details with those of this contact?");
      if (res) {
        this.updateMainContact();
      }
    } else {
      this.updateMainContact();
    }
  }

  updateMainContact() {    
    var $contactIndex = this.dDDS.getContactIndex(this.aCS.contact, 'main');
    this.aCS.firstname = this.dDDS.dropdown.mainContactList[$contactIndex]['firstname'];
    this.aCS.lastname = this.dDDS.dropdown.mainContactList[$contactIndex]['lastname'];
    this.aCS.tel = this.dDDS.dropdown.mainContactList[$contactIndex]['telephone'];
    this.aCS.email = this.dDDS.dropdown.mainContactList[$contactIndex]['email'];
  }

  onCalculate() {
    if (this.canSave()) {
      this.aCS.modelset = +this.aCS.pricemodel > 0;
      this.aCS.pricemodel = (+this.aCS.pricemodel != 0 ? this.aCS.pricemodel : this.dDDS.pricemodel.modelno);
      this.aCS.paypercent = (+this.aCS.paypercent != 0 ? this.aCS.paypercent : this.dDDS.pricemodel.paypercent);
      this.aCS.payday = (+this.aCS.payday != 0 ? this.aCS.payday : this.dDDS.pricemodel.payday);
      this.aCS.country =  this.countryService.country.country;
      this.aCS.state = this.countryService.state;
      this.aCS.addCompany()
      window.scroll(0,0);
      this.dDDS.getDropdown("companyList");
      this.dDDS.getDropdown("mainContactList");
      this.aCS.resetForm();
    }
  }

  async onDelete(type = 'delete') {
    const res = await this.loginService.confirm("Are sure you want to " + type + " company '" + this.aCS.companyname + "'?");
    if (res) {
      this.aCS.deleteEntry(type);
      this.dDDS.getDropdown("companyList");
      this.dDDS.getDropdown("mainContactList");      
      this.aCS.resetForm();
    }
  }

  setPModelDefault() {
    if (this.aCS.editmode == true) {
      this.aCS.paypercentU = this.dDDS.pricemodel.paypercent;
      this.aCS.paydayU = this.dDDS.pricemodel.payday;
    } else {
      this.aCS.paypercent = "0";
      this.aCS.payday = "0";
    }
  }

  setPModel(modelno) {
    if (modelno == 0) {
      this.setPModelDefault();
    } else {
      for (var i = 0; i < Object.keys(this.dDDS.dropdown.priceTermsList).length; i++) {
        if (+this.dDDS.dropdown.priceTermsList[i].modelno == modelno)  {
          if (this.aCS.editmode == true) {
            this.aCS.paypercentU = this.dDDS.dropdown.priceTermsList[i].paypercent;
            this.aCS.paydayU = this.dDDS.dropdown.priceTermsList[i].payday;
            this.aCS.epaypercentU = this.dDDS.dropdown.priceTermsList[i].epaypercent;
            this.aCS.epaydayU = this.dDDS.dropdown.priceTermsList[i].epayday;
          } else {
            this.aCS.paypercent = this.dDDS.dropdown.priceTermsList[i].paypercent;
            this.aCS.payday = this.dDDS.dropdown.priceTermsList[i].payday;
            this.aCS.epaypercent = this.dDDS.dropdown.priceTermsList[i].epaypercent;
            this.aCS.epayday = this.dDDS.dropdown.priceTermsList[i].epayday;
          }
        }
      }
    }
  }


}