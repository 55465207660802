import { Component, VERSION, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { TableDataService } from '../../overview/table/table.service';
import { TraceabilityService } from '../traceability.service';
import { CsvService } from '../../../services/csv.service';


@Component({
  selector: 'app-tracefarm',
  templateUrl: './tracefarm.component.html',
  styleUrls: ['./tracefarm.component.scss']
})
export class TraceFarmComponent implements OnInit {
  public selectedOrder: string;
	
  constructor(
    public loginService: TeamLoginService,
    public traceabilityService: TraceabilityService,
    public csvService: CsvService,
    public tableService: TableDataService
    ) { }

  ngOnInit() {
    this.traceabilityService.reset();
    this.traceabilityService.getProducers();
    this.traceabilityService.fromdate = this.loginService.season + '-01-01';
    this.traceabilityService.todate = this.loginService.season + '-12-31';
  }

  ngOnDestroy() {
    this.traceabilityService.reset();
    this.tableService.clickedIndex['orders'] = null;
  }

  isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  refreshProducers() {
    this.traceabilityService.getProducers();
  }

  loadForLogging(index) {
    this.traceabilityService.producername = this.traceabilityService.producers[index]['companyname'];
    this.traceabilityService.reset(true);
    this.tableService.clickedIndex['orders'] = null;
    this.traceabilityService.traceFarmer();
  }

  orderSelected(order) {
    this.tableService.clickedIndex['orders'] = null;
    if (order.status!=='packed') {
      this.tableService.setDelivery(order.orderref, 'orders', order.season);
    }
    this.traceabilityService.orderref = (order.status=='packed' ? order.location : order.orderref);
    setTimeout(function() {
      window.scrollTo(0,1700);
    }, 100); 
  }

  deliverySelected(delivery) {
    this.tableService.clickedIndex['orders'] = null;
    this.traceabilityService.deliverySelected(delivery);
    setTimeout(function() {
      window.scrollTo(0,950);
    }, 100); 
  }

  csv(datatable) {
    var thistable=document.getElementById(datatable);
    var title = 'Traceability: ' + this.traceabilityService.producername;
    if (datatable=='ordertable') {
      title = title + ' (' + this.traceabilityService.selectedBatchName + ')';
    }
    this.csvService.csvSimple(thistable, title);
  }


}
