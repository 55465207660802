import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashblank'
})
export class DashblankPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == '0'|| value == "R0.00" || value == "$0.00" || value == '0.00%' || value == null || value == 0) {
    	return "-"
    }
    if (args) {
    	return value + args;
    }
    return value;
  }

}
