<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row">
			<div class="col-xs-6 ml-3">
				<h2>General Settings</h2>
			</div>
		</div>
		<br>

		<div class="col col-lg-9">
		 	
			<hr>

			<div class="form-container">

				<h4 class="py-3" *ngIf="this.settingsService.settings==undefined">No editable settings available.</h4>

				<ng-container *ngFor="let setting of this.settingsService.settings; let i=index;">

					<div class="row justify-content-between p-3" *ngIf="isAuthenticated(setting['value2'])">
						
						<p class="col mat-form-field my-auto main-header">{{ setting.setting }}</p>

						<div class="col-auto inline row mx-4" *ngIf="setting.value4!=null">

							<mat-form-field class="col">
    						<mat-label>{{ this.subsettingName(setting.setting) }}</mat-label>
						    <input matInput type="number" min="0" step="1" max="50" [placeholder]="(setting.setting=='Client Resample Request' ? 'Days Valid' : 'Value')" maxlength="45" [(ngModel)]="setting.value4">
						  </mat-form-field>
			
							<button mat-icon-button class="col-auto" 
							  *ngIf="!this.settingsService.saving"
							  (click)="this.settingsService.saveValue(i)"><i class="material-icons green-icon">done</i></button>

						  <button mat-icon-button class="col-auto" 
							  *ngIf="this.settingsService.index==i && this.settingsService.saving"
							  ><i class="inline spinner material-icons amber-icon">sync</i></button>

						</div>

						<mat-slide-toggle class="slider-margin slider-label col-auto" [(ngModel)]="setting['value']" (change)="this.settingsService.updateSetting(i)"></mat-slide-toggle>

					</div>

				</ng-container>

				<ng-container *ngIf="palletSettings()">

					<hr>
					
					<h4 class="py-3">Pallet Settings</h4>

					<div class="row justify-content-between p-3">
						
						<p class="col mat-form-field my-auto main-header">Pallet Review Trigger: USKR</p>

						<div class="col-6 inline row mx-4">

							<mat-form-field class="col">
    						<mat-label>USKR Review Trigger (%)</mat-label>
						    <input matInput type="number" min="0" step="0.01" max="50" [placeholder]="'USKR Trigger (%)'" maxlength="45" [(ngModel)]="this.settingsService.pallet['uskr']">
						  </mat-form-field>
			
							<button mat-icon-button class="col-auto" 
							  *ngIf="!this.settingsService.saving"
							  (click)="this.settingsService.savePalletValue('uskr')"><i class="material-icons green-icon">done</i></button>

						  <button mat-icon-button class="col-auto" 
							  *ngIf="this.settingsService.palletsave=='uskr' && this.settingsService.saving"
							  ><i class="inline spinner material-icons amber-icon">sync</i></button>

						</div>

					</div>

					<div class="row justify-content-between p-3">
						
						<p class="col mat-form-field my-auto main-header">Pallet Review Trigger: Moisture</p>

						<div class="col-6 inline row mx-4">

							<mat-form-field class="col">
    						<mat-label>Moisture Review Trigger (%)</mat-label>
						    <input matInput type="number" min="0" step="0.01" max="50" [placeholder]="'Moisture Trigger (%)'" maxlength="45" [(ngModel)]="this.settingsService.pallet['moisture']">
						  </mat-form-field>
			
							<button mat-icon-button class="col-auto" 
							  *ngIf="!this.settingsService.saving"
							  (click)="this.settingsService.savePalletValue('moisture')"><i class="material-icons green-icon">done</i></button>

						  <button mat-icon-button class="col-auto" 
							  *ngIf="this.settingsService.palletsave=='moisture' && this.settingsService.saving"
							  ><i class="inline spinner material-icons amber-icon">sync</i></button>

						</div>

					</div>

				</ng-container>
				
			</div>

			<hr>

			<div class="row justify-content-between p-3">
	    	
				<p class="col mat-form-field my-auto main-header">Company Stamp (.PNG)</p>

	    	<p-fileUpload class="col-auto" mode="basic" chooseLabel="Choose" name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" [maxFileSize]="1000000" (onUpload)="this.settingsService.onUploadStamp($event)"></p-fileUpload>

	    </div>

			<hr>

			<div class="row justify-content-between p-3">
	    	
				<p class="col mat-form-field my-auto main-header">Document Signature (.PNG)</p>

	    	<p-fileUpload class="col-auto" mode="basic" chooseLabel="Choose" name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" [maxFileSize]="1000000" (onUpload)="this.settingsService.onUploadSig($event)"></p-fileUpload>

	    </div>

			<hr>

			<div class="row justify-content-between p-3">
	    	
				<p class="col mat-form-field my-auto main-header">Fumigation Signature (.PNG)</p>

	    	<p-fileUpload class="col-auto" mode="basic" chooseLabel="Choose" name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php" accept="image/*" [maxFileSize]="1000000" (onUpload)="this.settingsService.onUploadFumSig($event)"></p-fileUpload>

	    </div>

			<hr>
			
			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>